import { IonImg } from "@ionic/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { PureComponent } from "react";

import { PrimaryButton } from "../../components/Button";
import { MPContent } from "../../components/MPContent";
import { FormattedInput } from "../../components/FormattedInput";
import { CompanyPicker } from "./CompanyPicker";
import ErrorField from "../ErrorField";
import { FileSelect } from "../FileSelect";

import { Profile as images } from "../../images";
import { Company } from "../../models";
import { isLoading, RemoteData } from "../../utils/remoteData";
import { capitalizeFirstLetter } from "../../utils/stringPreprocessing";
import { ValidationError } from "../../utils/validate";

import styles from "./styles.module.scss";

export type InputFields = "name" | "company" | "namecard";

export interface FormValues {
  name: string;
  company?: Company;
  namecard?: File;
}

interface Props {
  remoteSave: RemoteData<void>;
  error: ValidationError<InputFields>;
  focusField?: InputFields;
  onSubmit: (values: FormValues) => void;
}

interface State {
  values: FormValues;
  nextField?: InputFields;
}

export class AgentWelcomeView extends PureComponent<Props, State> {
  state: State = {
    values: { name: "" },
  };

  componentDidUpdate() {
    if (this.state.nextField) {
      this.setState({ nextField: undefined });
    }
  }

  focusCompanyField = () => {
    this.setState({ nextField: "company" });
  };

  render() {
    const { values, nextField } = this.state;
    const { remoteSave, error, focusField } = this.props;

    return (
      <MPContent class={styles.content} overflowScrolling="auto">
        <form noValidate={true} onSubmit={this.handleSubmit}>
          <IonImg class={styles.icon} src={images.icon} />
          <h1 className={styles.header}>
            <FormattedMessage id="welcome_agent.title" />
          </h1>
          <p className={styles.instruction}>
            <FormattedMessage id="welcome_agent.description" />
          </p>

          <FormattedInput
            className={styles.input}
            placeholderId="welcome_agent.name"
            autoFocus={true}
            value={values.name}
            maxLength={30}
            onChange={this.handleNameChange}
            isError={!!error["name"]}
            shouldFocus={focusField === "name"}
            autoSubmit={this.focusCompanyField}
          />
          <ErrorField messageId={error["name"]} />

          <div data-anchor="company">
            <p className={styles.companyDesc}>
              <FormattedMessage id="welcome_agent.company" />
            </p>
            <CompanyPicker
              value={values.company}
              onChange={this.handleCompanyChange}
              isError={!!error["company"]}
              shouldFocus={nextField === "company"}
              shouldScrollTo={focusField === "company"}
              scrollAnchor="company"
            />
            <div className={styles.companyNotFound}>
              <FormattedMessage
                id="welcome_agent.company_not_found"
                values={{
                  contactUs: (
                    <a
                      className={styles.link}
                      href="mailto:info@moneyplaza.com.hk"
                    >
                      <FormattedMessage id="welcome_agent.contact_us" />
                    </a>
                  ),
                }}
              />
            </div>
            <ErrorField messageId={error["company"]} />
          </div>

          <div data-anchor="namecard">
            <FileSelect
              labelId="welcome_agent.namecard"
              className={styles.fileSelect}
              onSelectFile={this.handleSelectFile}
            />
            <ErrorField messageId={error["namecard"]} />
          </div>

          <PrimaryButton
            class={styles.done}
            type="submit"
            expand="full"
            disabled={isLoading(remoteSave)}
          >
            <FormattedMessage id="welcome_agent.done" />
          </PrimaryButton>
        </form>
      </MPContent>
    );
  }

  handleNameChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const name = capitalizeFirstLetter(e.currentTarget.value || "");
    this.setState(({ values }) => {
      return {
        values: { ...values, name },
      };
    });
  };

  handleCompanyChange = (company: Company) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, company },
      };
    });
  };

  handleSelectFile = (namecard: File, _type: "image" | "document") => {
    this.setState(({ values }) => {
      return {
        values: { ...values, namecard },
      };
    });
  };

  handleSubmit: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    this.props.onSubmit(this.state.values);
  };
}
