import numeral from "numeral";
import React from "react";

import {
  ExchangeLoanRequest,
  MortgageRequest,
  PersonalLoanRequest,
  Request,
  RequestType,
} from "../../models";

import { FlatButton } from "../FlatButton";
import { MortgageLevelOptions } from "../SpecifyDropdown";

import styles from "./RequestItem.module.scss";
import { FormattedMessage } from "@oursky/react-messageformat";
import { formatToCurrencyAmount } from "../../utils/formatToCurrencyAmount";

export interface RequestItemProps {
  request: Request;
  onClickRequest: (request: Request) => void;
}

export const RequestItem: React.FC<RequestItemProps> = props => {
  const { onClickRequest, request } = props;

  const onClick = React.useCallback(() => {
    onClickRequest(request);
  }, [onClickRequest, request]);

  switch (request.type) {
    case RequestType.exchangeLoan:
      return <ExchangeLoanRequestItem request={request} onClick={onClick} />;
    case RequestType.mortgage:
      return <MortgageRequestItem request={request} onClick={onClick} />;
    case RequestType.personalLoan:
      return <PersonalLoanRequestItem request={request} onClick={onClick} />;
    default:
      throw new Error(`Unexpected request: ${request}`);
  }
};

interface ExchangeLoanRequestItemProps {
  onClick: () => void;
  request: ExchangeLoanRequest;
}

const ExchangeLoanRequestItem: React.FC<
  ExchangeLoanRequestItemProps
> = props => {
  const { onClick, request } = props;

  return (
    <div className={styles.container}>
      <div className={styles.contentItemContainer}>
        <div className={styles.contentItemTitle}>
          <h2>
            <FormattedMessage id="request.exchange_loan" />
          </h2>
        </div>
        <div className={styles.contentItem}>
          <div className={styles.money_container}>
            <div className={styles.currency_text}>
              <FormattedMessage
                id="request.exchange_loan.amount.title"
                values={{ currency: request.sellingCurrency.isoCode }}
              />
            </div>

            <div className={styles.currency}>
              {numeral(request.sellingAmount).format()}
            </div>
          </div>
        </div>
        <hr />

        <div className={styles.contentItem}>
          <h3>
            <FormattedMessage id="request.exchange_loan.from_currency.title" />
          </h3>
          <span>{request.sellingCurrency.isoCode.toUpperCase()}</span>
        </div>
        <div className={styles.contentItem}>
          <h3>
            <FormattedMessage id="request.exchange_loan.to_currency.title" />
          </h3>
          <span>{request.buyingCurrency.isoCode.toUpperCase()}</span>
        </div>
      </div>

      <p className={styles.requestId}>
        <FormattedMessage
          id="request.ref_num"
          values={{ refNum: request.refNum }}
        />
      </p>

      <div className={styles.quoteButtonContainer}>
        <FlatButton className={styles.quoteButton} onClick={onClick}>
          <FormattedMessage id="agent.request.offer" />
        </FlatButton>
      </div>
    </div>
  );
};

interface MortgageRequestItemProps {
  onClick: () => void;
  request: MortgageRequest;
}

const MortgageRequestItem: React.FC<MortgageRequestItemProps> = props => {
  const { onClick, request } = props;
  const mortgageLevel = MortgageLevelOptions.find(
    option => option.value === request.mortgageLevel
  );
  if (!mortgageLevel) {
    throw new Error(`Unexpected mortgage level = ${request.mortgageLevel}`);
  }

  return (
    <div className={styles.container}>
      <div className={styles.contentItemContainer}>
        <div className={styles.contentItemTitle}>
          <h2>
            <FormattedMessage id="request.mortgage" />
          </h2>
        </div>
        <div className={styles.contentItem}>
          <div className={styles.money_container}>
            <div className={styles.currency_text}>
              <FormattedMessage id="request.mortgage.loan_amount.title" />
            </div>
            <div className={styles.currency}>
              {formatToCurrencyAmount(request.loanAmount, true)}
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className={styles.contentItemContainer}>
        <div className={styles.contentItem}>
          <h3>
            <FormattedMessage id="request.mortgage.value.title" />
          </h3>
          <span>{formatToCurrencyAmount(request.buildingValue, true)}</span>
        </div>
        <div className={styles.contentItem}>
          <h3>
            <FormattedMessage id="request.mortgage.level.title" />
          </h3>
          <span>
            <FormattedMessage id={mortgageLevel.labelId} />
          </span>
        </div>
      </div>
      <div className={styles.contentItemContainer}>
        <div className={styles.contentItem}>
          <h3>
            <FormattedMessage id="request.mortgage.monthly_income.title" />
          </h3>
          <div>
            {request.monthlyIncome ? (
              <Money amount={request.monthlyIncome} />
            ) : (
              <span>NIL</span>
            )}
          </div>
        </div>
        <div className={styles.contentItem}>
          <h3>
            <FormattedMessage id="request.mortgage.current_loan_amount.title" />
          </h3>
          <span>
            {request.currentLoanAmount ? (
              <Money amount={request.currentLoanAmount} />
            ) : (
              <span>NIL</span>
            )}
          </span>
        </div>
      </div>
      <div className={styles.contentItemContainer}>
        <div className={styles.contentItem}>
          <h3>
            <FormattedMessage id="request.mortgage.market_value.title" />
          </h3>
          <div>
            <Money
              amount={request.buildingValue}
              representZeroWithDash={true}
            />
          </div>
        </div>
        <div className={styles.contentItem}>
          <h3>
            <FormattedMessage id="request.mortgage.tu_grade.title" />
          </h3>
          <span>
            {request.tuGrade ? (
              request.tuGrade.toUpperCase()
            ) : (
              <FormattedMessage id="option.tu_grade.no_tu" />
            )}
          </span>
        </div>
      </div>
      <p className={styles.requestId}>
        <FormattedMessage
          id="request.ref_num"
          values={{ refNum: request.refNum }}
        />
      </p>

      <div className={styles.quoteButtonContainer}>
        <FlatButton className={styles.quoteButton} onClick={onClick}>
          <FormattedMessage id="agent.request.offer" />
        </FlatButton>
      </div>
    </div>
  );
};

interface PersonalLoanRequestItem {
  onClick: () => void;
  request: PersonalLoanRequest;
}

const PersonalLoanRequestItem: React.FC<PersonalLoanRequestItem> = props => {
  const { onClick, request } = props;

  return (
    <div className={styles.container}>
      <div className={styles.contentItemContainer}>
        <div className={styles.contentItemTitle}>
          <h2>
            <FormattedMessage id="request.personal_loan" />
          </h2>
        </div>
        <div className={styles.contentItem}>
          <div className={styles.money_container}>
            <div className={styles.currency_text}>
              <FormattedMessage id="offering.quick_request.amount" />
            </div>
            <div className={styles.currency}>
              {formatToCurrencyAmount(request.amount, true)}
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div className={styles.contentItemContainer}>
        <div className={styles.contentItem}>
          <h3>
            <FormattedMessage id="request.personal_loan.period.title" />
          </h3>

          <span>
            <FormattedMessage
              id="request.personal_loan.period.value"
              values={{
                period: request.period,
                unit: request.period,
              }}
            />
          </span>
        </div>
        <div className={styles.contentItem}>
          <h3>
            <FormattedMessage id="request.mortgage.monthly_income.title" />
          </h3>
          <div>
            {request.monthlyIncome ? (
              <Money amount={request.monthlyIncome} />
            ) : (
              <span>NIL</span>
            )}
          </div>
        </div>
      </div>

      <div className={styles.contentItemContainer}>
        <div className={styles.contentItem}>
          <h3>
            <FormattedMessage id="request.mortgage.current_loan_amount.title" />
          </h3>
          <div>
            {request.currentLoanAmount ? (
              <Money amount={request.currentLoanAmount} />
            ) : (
              <span>NIL</span>
            )}
          </div>
        </div>
        <div className={styles.contentItem}>
          <h3>
            <FormattedMessage id="request.personal_loan.collateral.title" />
          </h3>
          <span>{request.collateral || "NIL"}</span>
        </div>
      </div>
      <div className={styles.contentItemContainer}>
        <div className={styles.contentItem}>
          <h3>
            <FormattedMessage id="request.personal_loan.collateral_value.title" />
          </h3>
          <div>
            {request.collateralValue ? (
              <Money amount={request.collateralValue} />
            ) : (
              <span>NIL</span>
            )}
          </div>
        </div>
        <div className={styles.contentItem}>
          <h3>
            <FormattedMessage id="request.mortgage.tu_grade.title" />
          </h3>
          <span>
            {request.tuGrade ? (
              request.tuGrade.toUpperCase()
            ) : (
              <FormattedMessage id="option.tu_grade.no_tu" />
            )}
          </span>
        </div>
      </div>
      <p className={styles.requestId}>
        <FormattedMessage
          id="request.ref_num"
          values={{ refNum: request.refNum }}
        />
      </p>

      <div className={styles.quoteButtonContainer}>
        <FlatButton className={styles.quoteButton} onClick={onClick}>
          <FormattedMessage id="agent.request.offer" />
        </FlatButton>
      </div>
    </div>
  );
};

interface MoneyProps {
  amount: number;
  representZeroWithDash?: boolean;
}

const Money: React.FC<MoneyProps> = props => {
  const { amount, representZeroWithDash = false } = props;

  return (
    <span className={styles.money}>
      {formatToCurrencyAmount(amount, representZeroWithDash)}
    </span>
  );
};
