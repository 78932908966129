import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "@oursky/react-messageformat";

import styles from "./styles.module.scss";
import { ButtonSelect, ButtonSelectItem } from "../ButtonSelect";

interface Props {
  value?: boolean;
  labelId: string;
  onClickOption: (value: boolean) => void;
  positiveLabelId?: string;
  negativeLabelId?: string;
  shouldFocus?: boolean;
  disabled?: boolean;
  labelClass?: string;
}

class YesNoPicker extends React.PureComponent<Props> {
  handleChange = (item: ButtonSelectItem<boolean>) => {
    this.props.onClickOption(item.value);
  };

  render() {
    const {
      value,
      labelId,
      positiveLabelId,
      negativeLabelId,
      shouldFocus,
      disabled,
      labelClass,
    } = this.props;
    return (
      <div
        className={classNames(styles.container, {
          [styles.disabled]: disabled,
        })}
      >
        <div className={classNames(styles.label, labelClass)}>
          <FormattedMessage id={labelId} />
        </div>
        <div className={styles.buttonContainer}>
          <ButtonSelect
            items={[
              { labelId: positiveLabelId || "common.yes", value: true },
              { labelId: negativeLabelId || "common.no", value: false },
            ]}
            value={value}
            onChange={this.handleChange}
            shouldFocus={shouldFocus}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}

export default YesNoPicker;
