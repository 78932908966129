import React from "react";

import {
  FormattedDropdown,
  FormattedDropdownProps,
  Option as FormattedOption,
} from "../FormattedDropdown";

import { Omit } from "../../utils/typeutils";
import { PremiseType } from "../../models";

export type PremiseTypeDropdownProps = Omit<FormattedDropdownProps, "options">;

export const PremiseTypeOptions: FormattedOption[] = [
  {
    key: PremiseType.privateEstate,
    value: PremiseType.privateEstate,
    labelId: "option.premiseType.privateEstate",
  },
  {
    key: PremiseType.publicHousing,
    value: PremiseType.publicHousing,
    labelId: "option.premiseType.publicHousing",
  },
  {
    key: PremiseType.homeOwnershipSchemeCourts,
    value: PremiseType.homeOwnershipSchemeCourts,
    labelId: "option.premiseType.homeOwnershipSchemeCourts",
  },
  {
    key: PremiseType.villageHouse,
    value: PremiseType.villageHouse,
    labelId: "option.premiseType.villageHouse",
  },
  {
    key: PremiseType.ntSmallHouse,
    value: PremiseType.ntSmallHouse,
    labelId: "option.premiseType.ntSmallHouse",
  },
];

export const PremiseTypeDropdown: React.FC<
  PremiseTypeDropdownProps
> = props => {
  return <FormattedDropdown options={PremiseTypeOptions} {...props as any} />;
};
