import { IonButton } from "@ionic/react";
import classNames from "classnames";
import React from "react";

import styles from "./styles.module.scss";

export type HollowButtonProps = React.ComponentPropsWithRef<typeof IonButton>;

export const HollowButton: React.FC<HollowButtonProps> = props => {
  const { class: className, ...rest } = props;

  return (
    <IonButton
      mode="ios"
      class={classNames(styles.hollowButton, className)}
      {...rest}
    />
  );
};
