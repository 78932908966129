import React, { useCallback, useState } from "react";
import { FormattedMessage } from "@oursky/react-messageformat";
import { IonPage, IonHeader, IonToolbar } from "@ionic/react";

import { BorrowerLoanInfo, ExistingLoan } from "../../models";
import { ValidationError } from "../../utils/validate";
import { isLoading, RemoteData } from "../../utils/remoteData";

import { BackButton } from "../BackButton";
import { PrimaryButton } from "../Button";
import {
  BorrowerLoanRecordForm,
  InputFields as RecordViewInputFields,
} from "../BorrowerLoanRecordForm";
import { MPContent } from "../MPContent";

import styles from "./styles.module.scss";
import SuggestionDialogue from "../SuggestionDialogue/SuggestionDialogue";

export type InputFields = RecordViewInputFields;

interface Props {
  remoteUpdate: RemoteData<void>;
  borrower: BorrowerLoanInfo;
  numberOfLoan: number | null;

  onSelectMortgageStatus: (isEmployed: boolean) => void;
  onSelectNumberOfLoan: (numberOfLoan: number) => void;
  onUpdateExistingLoans: (index: number, value: Partial<ExistingLoan>) => void;
  onSubmit: () => void;

  error: ValidationError<InputFields>;
  focusField?: InputFields;
  autoSubmit?: () => void;
  onSkipToContact: () => void;
}

export const BorrowerLoanRecordView: React.FC<Props> = props => {
  const {
    borrower,
    error,
    numberOfLoan,
    remoteUpdate,
    onSkipToContact,
  } = props;
  const [isSkipToContactViewShown, setIsSkipToContactViewShown] = useState(
    true
  );

  const hideSkipToContactView = useCallback(() => {
    setIsSkipToContactViewShown(false);
  }, []);

  const skipToContact = useCallback(() => {
    setIsSkipToContactViewShown(false);
    onSkipToContact();
  }, [onSkipToContact]);

  return (
    <IonPage className={styles.page}>
      <IonHeader mode="ios">
        <IonToolbar mode="ios">
          <div className={styles.toolbarContainer}>
            <div className={styles.back}>
              <BackButton />
            </div>
          </div>
        </IonToolbar>
      </IonHeader>
      <MPContent overflowScrolling="auto">
        <div className={styles.container}>
          <h1>
            <FormattedMessage id="borrower_loan_info.title" />
          </h1>
          <div className={styles.description}>
            <FormattedMessage id="borrower_loan_info.description" />
          </div>
          <BorrowerLoanRecordForm
            borrower={borrower}
            numberOfLoan={numberOfLoan}
            selectMortgageStatus={props.onSelectMortgageStatus}
            selectNumberOfLoan={props.onSelectNumberOfLoan}
            updateExistingLoans={props.onUpdateExistingLoans}
            error={error}
          />
          {isSkipToContactViewShown && (
            <div className={styles.dialogueWrapper}>
              <SuggestionDialogue
                titleId={"request.skip_to_contact.title"}
                confirmButtonTextId={"request.skip_to_contact.button.confirm"}
                cancelButtonTextId={"request.skip_to_contact.button.cancel"}
                onConfirm={skipToContact}
                onCancel={hideSkipToContactView}
              />
            </div>
          )}
          <div className={styles.buttonContainer}>
            <div className={styles.submitButtonContainer}>
              <PrimaryButton
                class={styles.submitButton}
                expand="full"
                onClick={props.onSubmit}
                disabled={isLoading(remoteUpdate)}
              >
                <FormattedMessage id="borrower_loan_info.button.complete" />
              </PrimaryButton>
            </div>
            <div className={styles.personalInfoPromise}>
              <FormattedMessage id="borrower_loan_info.personal_info" />
            </div>
          </div>
        </div>
      </MPContent>
    </IonPage>
  );
};
