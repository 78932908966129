import { BaseApiClient, ApiClientConstructor } from "./client";

import {
  paginatedChatsSchema,
  conversationResponseSchema,
  getChatResponseSchema,
} from "../types/response";

import {
  BaseChatWithBlockingInfo,
  Chat,
  Conversation,
  CreateForeignExchangeOffering,
  CreateMortgageOffering,
  CreatePersonalLoanOffering,
  ExchangeLoanChat,
  MortgageChat,
  PersonalLoanChat,
} from "../models";

import {
  exchangeLoanChatSchema,
  mortgageChatSchema,
  personalLoanChatSchema,
  chatSchema,
} from "../schemas/chat";

import { Page } from "../utils/pagination";

interface ConversationOptions {
  distinctByParticipants?: boolean;
  adminIDs?: string[];
}

export interface ChatApiClient {
  borrowerListChatByRequestId: (
    requestId: string,
    cursor?: string
  ) => Promise<Page<Chat>>;
  agentListChat: (isCompleted: boolean, cursor?: string) => Promise<Page<Chat>>;
  agentCreateFXChat: (
    formValues: CreateForeignExchangeOffering
  ) => Promise<ExchangeLoanChat>;
  agentCreateMortgageChat: (
    formValues: CreateMortgageOffering
  ) => Promise<MortgageChat>;
  agentCreatePersonalLoanChat: (
    formValues: CreatePersonalLoanOffering
  ) => Promise<PersonalLoanChat>;
  borrowerCreateChatWithOffering: (offeringId: string) => Promise<Chat>;
  borrowerCreateChatWithOfferingAndRequest: (
    offeringId: string,
    requestId: string
  ) => Promise<Chat>;
  setChatStatus: (
    chatId: string,
    isCompleted: boolean,
    isReferralRequested: boolean
  ) => Promise<void>;
  setReferralAgreement: (chatId: string, isAccepted: boolean) => Promise<void>;
  getChat: (chatId: string) => Promise<BaseChatWithBlockingInfo>;
  getChatDetail: (chatId: string) => Promise<Chat>;

  createConversation: (
    participants: string[],
    title: string,
    meta: any,
    options: ConversationOptions
  ) => Promise<Conversation>;
}

export function withChatApi<TBase extends ApiClientConstructor<BaseApiClient>>(
  Base: TBase
) {
  return class extends Base {
    borrowerListChatByRequestId(
      requestId: string,
      cursor?: string
    ): Promise<Page<Chat>> {
      const data: any = { request_id: requestId };
      if (cursor) {
        data.page_info = { cursor };
      }

      return this.requestLambda(
        "chat:borrower-list-by-request-id",
        data,
        paginatedChatsSchema
      );
    }

    agentListChat(isCompleted: boolean, cursor?: string): Promise<Page<Chat>> {
      const data: any = { is_completed: isCompleted };
      if (cursor) {
        data.page_info = { cursor };
      }

      return this.requestLambda("chat:agent-list", data, paginatedChatsSchema);
    }

    agentCreateFXChat(
      formValues: CreateForeignExchangeOffering
    ): Promise<ExchangeLoanChat> {
      return this.requestLambda(
        "chat:agent-create-foreign-exchange",
        {
          request_id: formValues.requestId,
          selling_currency_id: formValues.sellingCurrencyId,
          buying_currency_id: formValues.buyingCurrencyId,
          selling_amount: formValues.sellingAmount,
          buying_amount: formValues.buyingAmount,
          valid_days: formValues.validDays,
        },
        exchangeLoanChatSchema
      );
    }

    agentCreateMortgageChat(
      formValues: CreateMortgageOffering
    ): Promise<MortgageChat> {
      return this.requestLambda(
        "chat:agent-create-mortgage",
        {
          request_id: formValues.requestId,
          loan_amount: formValues.loanAmount,
          payment_period_max: formValues.paymentPeriodMax,
          interest_rate_min: formValues.interestRateMin,
          interest_rate_max: formValues.interestRateMax,
          is_required_employed: formValues.isRequiredEmployed,
          addition: formValues.addition,
          valid_days: formValues.validDays,
        },
        mortgageChatSchema
      );
    }

    agentCreatePersonalLoanChat(
      formValues: CreatePersonalLoanOffering
    ): Promise<PersonalLoanChat> {
      return this.requestLambda(
        "chat:agent-create-personal-loan",
        {
          request_id: formValues.requestId,
          amount_min: formValues.amountMin,
          amount_max: formValues.amountMax,
          interest_rate_min: formValues.interestRateMin,
          interest_rate_max: formValues.interestRateMax,
          period_min: formValues.periodMin,
          period_max: formValues.periodMax,
          is_required_employed: formValues.isRequiredEmployed,
          is_required_property: formValues.isRequiredProperty,
          addition: formValues.addition,
          valid_days: formValues.validDays,
        },
        personalLoanChatSchema
      );
    }

    borrowerCreateChatWithOffering(offeringId: string): Promise<Chat> {
      return this.requestLambda(
        "chat:borrower-create-with-offering",
        {
          offering_id: offeringId,
        },
        chatSchema
      );
    }

    borrowerCreateChatWithOfferingAndRequest(
      offeringId: string,
      requestId: string
    ): Promise<Chat> {
      return this.requestLambda(
        "chat:borrower-create-with-offering-and-request",
        {
          offering_id: offeringId,
          request_id: requestId,
        },
        chatSchema
      );
    }

    setChatStatus(
      chatId: string,
      isCompleted: boolean,
      isReferralRequested: boolean
    ): Promise<void> {
      return this.requestLambda("chat:set-status", {
        chat_id: chatId,
        is_completed: isCompleted,
        is_referral_requested: isReferralRequested,
      });
    }

    setReferralAgreement(chatId: string, isAccepted: boolean): Promise<void> {
      return this.requestLambda("chat:set-referral-agreement", {
        chat_id: chatId,
        is_accepted: isAccepted,
      });
    }

    getChat(chatId: string): Promise<BaseChatWithBlockingInfo> {
      return this.requestLambda(
        "chat:get",
        {
          chat_id: chatId,
        },
        getChatResponseSchema
      );
    }

    getChatDetail(chatId: string): Promise<Chat> {
      return this.requestLambda(
        "chat:get-detail",
        {
          chat_id: chatId,
        },
        chatSchema
      );
    }

    createConversation(
      participants: string[],
      title: string,
      meta: any,
      options: ConversationOptions
    ): Promise<Conversation> {
      return this.requestLambda(
        "chat:create_conversation",
        [participants, title, meta, options],
        conversationResponseSchema,
        true
      ).then(x => x.conversation);
    }
  };
}
