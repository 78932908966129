import React, { useContext } from "react";
import {
  BorrowerField,
  CreateRequestContext,
  CreateRequestRoute,
  DisplayMapByRequestType,
  ExistingLoanField as BorrowerExistingLoanField,
  FieldType,
  OtherIncomeField as BorrowerOtherIncomeField,
  PropertyInfoField as BorrowerPropertyInfoField,
} from "../../context/CreateRequestContext";

type NestedField =
  | BorrowerPropertyInfoField
  | BorrowerOtherIncomeField
  | BorrowerExistingLoanField;

type Field = BorrowerField | NestedField;

interface BaseProps {
  route: CreateRequestRoute;
  field: Field;
  formDisplayMap?: { [key in NestedField]?: FieldType };
}

const ConditionalDisplayField: React.FC<BaseProps> = props => {
  const { route, field, formDisplayMap, children } = props;
  const { pendingCreateRequest } = useContext(CreateRequestContext);

  const displayMapInUse =
    formDisplayMap !== undefined
      ? formDisplayMap
      : pendingCreateRequest !== null
      ? DisplayMapByRequestType[pendingCreateRequest.type][route]
      : null;

  // Note: show all the fields if displayMapInUse is null, e.g. upon editing profile
  const shouldDisplay =
    displayMapInUse === null ||
    (displayMapInUse !== null && field in displayMapInUse);

  return shouldDisplay ? <>{children}</> : null;
};

export const BasicInfoField: React.FC<{ field: Field }> = props => {
  const { children, ...restProps } = props;

  return (
    <ConditionalDisplayField
      route={CreateRequestRoute.BasicInfo}
      {...restProps}
    >
      {children}
    </ConditionalDisplayField>
  );
};

export const PropertyInfoField: React.FC<{
  field: BorrowerPropertyInfoField;
}> = props => {
  const { children, ...restProps } = props;
  const { pendingCreateRequest } = useContext(CreateRequestContext);

  const formDisplayMap =
    pendingCreateRequest !== null
      ? DisplayMapByRequestType[pendingCreateRequest.type][
          CreateRequestRoute.BasicInfo
        ]["propertyInfos"]
      : undefined;

  return (
    <ConditionalDisplayField
      route={CreateRequestRoute.BasicInfo}
      formDisplayMap={formDisplayMap}
      {...restProps}
    >
      {children}
    </ConditionalDisplayField>
  );
};

export const WorkInfoField: React.FC<{ field: Field }> = props => {
  const { children, ...restProps } = props;

  return (
    <ConditionalDisplayField route={CreateRequestRoute.WorkInfo} {...restProps}>
      {children}
    </ConditionalDisplayField>
  );
};

export const OtherIncomeField: React.FC<{
  field: BorrowerOtherIncomeField;
}> = props => {
  const { children, ...restProps } = props;
  const { pendingCreateRequest } = useContext(CreateRequestContext);

  const formDisplayMap =
    pendingCreateRequest !== null
      ? DisplayMapByRequestType[pendingCreateRequest.type][
          CreateRequestRoute.WorkInfo
        ]["otherIncomes"]
      : undefined;

  return (
    <ConditionalDisplayField
      route={CreateRequestRoute.WorkInfo}
      formDisplayMap={formDisplayMap}
      {...restProps}
    >
      {children}
    </ConditionalDisplayField>
  );
};

export const LoanRecordField: React.FC<{ field: Field }> = props => {
  const { children, ...restProps } = props;

  return (
    <ConditionalDisplayField
      route={CreateRequestRoute.LoanRecord}
      {...restProps}
    >
      {children}
    </ConditionalDisplayField>
  );
};

export const LoanInfoField: React.FC<{
  field: BorrowerExistingLoanField;
}> = props => {
  const { children, ...restProps } = props;
  const { pendingCreateRequest } = useContext(CreateRequestContext);

  const formDisplayMap =
    pendingCreateRequest !== null
      ? DisplayMapByRequestType[pendingCreateRequest.type][
          CreateRequestRoute.LoanRecord
        ]["existingLoans"]
      : undefined;

  return (
    <ConditionalDisplayField
      route={CreateRequestRoute.LoanRecord}
      formDisplayMap={formDisplayMap}
      {...restProps}
    >
      {children}
    </ConditionalDisplayField>
  );
};
