import React from "react";
import {
  Values as LocaleValues,
  Context as LocaleContext,
  FormattedMessage,
} from "@oursky/react-messageformat";
import { IonActionSheet } from "@ionic/react";
import classnames from "classnames";

import { isPlatform } from "../../utils/platform";

import { AttachmentType } from "../../types/misc";

import { Omit } from "../../utils/typeutils";

import styles from "./styles.module.scss";

interface Props {
  onSelectFile: (file: File, type: "image" | "document") => void;
  labelId: string;
  disabled?: boolean;
  className?: string;
  renderToString: (id: string, values?: LocaleValues) => string;
}

interface State {
  isActionSheetOpened: boolean;
  attachmentType?: AttachmentType;
  selectedFile?: File;
}

class FileSelectImpl extends React.PureComponent<Props, State> {
  state: State = {
    isActionSheetOpened: false,
  };

  inputRef = React.createRef<HTMLInputElement>();
  onButtonClick = () => {
    this.setState({
      isActionSheetOpened: true,
    });
  };

  onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { attachmentType } = this.state;
    if (e.currentTarget.files && attachmentType) {
      this.props.onSelectFile(e.currentTarget.files[0], attachmentType);
      this.setState({ selectedFile: e.currentTarget.files[0] });
    }
  };

  buildActionSheetItem = (
    messageId: string,
    handler?: () => void,
    role?: string
  ) => {
    return {
      text: this.props.renderToString(messageId),
      ...(handler ? { handler } : {}),
      ...(role ? { role } : {}),
    };
  };

  closeActionSheet = () => {
    this.setState({
      isActionSheetOpened: false,
    });
  };

  openCamera = () => {
    if (this.inputRef.current) {
      this.setState({ attachmentType: "image" });
      this.inputRef.current.accept = "image/*";
      this.inputRef.current.setAttribute("capture", "environment");
      this.inputRef.current.click();
    }
    this.closeActionSheet();
  };

  openPhotoAlbum = () => {
    if (this.inputRef.current) {
      this.setState({ attachmentType: "image" });
      this.inputRef.current.accept = "image/*";
      this.inputRef.current.removeAttribute("capture");
      this.inputRef.current.click();
    }
    this.closeActionSheet();
  };

  openDocument = () => {
    if (this.inputRef.current) {
      this.setState({ attachmentType: "document" });
      this.inputRef.current.accept = "application/pdf";
      this.inputRef.current.removeAttribute("capture");
      this.inputRef.current.click();
    }
    this.closeActionSheet();
  };

  buildActionSheet = () => {
    const buttons = [];

    if (isPlatform("android")) {
      buttons.push(this.buildActionSheetItem("common.camera", this.openCamera));
    }
    buttons.push(
      this.buildActionSheetItem("common.photo", this.openPhotoAlbum)
    );
    buttons.push(
      this.buildActionSheetItem("common.document", this.openDocument)
    );

    buttons.push(
      this.buildActionSheetItem("common.cancel", undefined, "cancel")
    );

    return buttons;
  };

  render() {
    const { isActionSheetOpened, selectedFile } = this.state;
    const { disabled, className, labelId } = this.props;

    return (
      <div className={styles.container}>
        <input
          ref={this.inputRef}
          className={styles.fileSelectButton}
          type="file"
          accept="image/*"
          onChange={this.onInputChange}
          disabled={disabled || false}
        />
        <div
          className={classnames(styles.fileSelectButton, className, {
            [styles.disabled]: disabled || false,
          })}
          onClick={this.onButtonClick}
        >
          <FormattedMessage id={labelId} />
        </div>
        <div className={styles.filename}>
          {selectedFile ? selectedFile.name : ""}
        </div>

        <IonActionSheet
          isOpen={isActionSheetOpened}
          onDidDismiss={this.closeActionSheet}
          buttons={this.buildActionSheet()}
          {...(isPlatform("ios")
            ? {
                className: styles.iosActionSheet,
              }
            : {})}
        />
      </div>
    );
  }
}

export const FileSelect: React.FunctionComponent<
  Omit<Props, "renderToString">
> = props => {
  return (
    <LocaleContext.Consumer>
      {({ renderToString }) => (
        <FileSelectImpl renderToString={renderToString} {...props} />
      )}
    </LocaleContext.Consumer>
  );
};
