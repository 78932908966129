import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router";

import {
  AgentWelcomeView,
  FormValues as AgentWelcomeFormValues,
  InputFields,
} from "../components/AgentWelcomeView";

import { UserContextProps, withUser } from "../context/UserContext";
import {
  withErrorAlert,
  ErrorAlertContextProps,
} from "../context/ErrorAlertContext";
import { withLoading, LoadingContextProps } from "../context/LoadingContext";

import { Initial, Loading, RemoteData, Success } from "../utils/remoteData";
import { validate, Validator, ValidationError } from "../utils/validate";

type Props = RouteComponentProps &
  UserContextProps &
  LoadingContextProps &
  ErrorAlertContextProps;

interface State {
  remoteSave: RemoteData<void>;
  error: ValidationError<InputFields>;
  focusField?: InputFields;
}

class AgentWelcomeScreen extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      remoteSave: Initial(),
      error: {},
    };
  }

  onSubmit = (values: AgentWelcomeFormValues) => {
    const { name, company, namecard } = values;
    const { history } = this.props;

    this.setState({ focusField: undefined }, () => {
      if (
        !this.validateForm(values) ||
        company === undefined ||
        namecard === undefined
      ) {
        return;
      }

      this.setState({ remoteSave: Loading() });
      this.props.loadingContext.show();
      this.props.userContext
        .createAgent(name, company, namecard)
        .then(() => this.props.userContext.autoLogin())
        .then(() => {
          this.setState({ remoteSave: Success(undefined) });
          this.props.userContext.registerPushNotification();
          history.push("/close");
        })
        .catch(error => {
          this.props.errorAlertContext.show(error);
          this.setState({ remoteSave: Initial() });
        })
        .finally(this.props.loadingContext.dismiss);
    });
  };

  validateForm(values: AgentWelcomeFormValues) {
    const { name, company, namecard } = values;

    const validators: Validator<InputFields>[] = [
      {
        field: "name",
        invalidCondition: name.trim().length === 0,
        errorMessageId: "welcome_agent.name.error.required",
      },
      {
        field: "company",
        invalidCondition: company === undefined,
        errorMessageId: "welcome_agent.company.error.required",
      },
      {
        field: "namecard",
        invalidCondition: namecard === undefined,
        errorMessageId: "welcome_agent.namecard.error.required",
      },
    ];

    const { focusField, error, isValid } = validate(validators);

    this.setState({ error, focusField });

    return isValid;
  }

  render() {
    const { remoteSave, error, focusField } = this.state;

    return (
      <AgentWelcomeView
        remoteSave={remoteSave}
        error={error}
        focusField={focusField}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default withLoading(withErrorAlert(withUser(AgentWelcomeScreen)));
