import * as pathToRegExp from "path-to-regexp";

interface RegisterOptions {
  paramWordsSeparator?: string;
  queryParams?: { queryParamName: string; renamedName?: string }[];
}

export interface DeepLinkParser<RouteKey> {
  register: (
    routeKey: RouteKey,
    path: string,
    options?: RegisterOptions
  ) => void;
  match: (url: string) => DeepLinkRouteResult<RouteKey> | null;
}

interface Route<RouteKey> {
  key: RouteKey;
  regexp: RegExp;
  pathToRegExpKeys: pathToRegExp.Key[];
  paramWordsSeparator: string;
  queryParams: { queryParamName: string; renamedName?: string }[];
}

export interface DeepLinkRouteResult<RouteKey> {
  routeKey: RouteKey;
  param: { [key in string]: string };
  queryParam: { [key in string]: string | null };
}

export class DeepLinkParser<RouteKey> implements DeepLinkParser<RouteKey> {
  private routes: Route<RouteKey>[] = [];

  register = (
    routeKey: RouteKey,
    path: string,
    { paramWordsSeparator = " ", queryParams = [] }: RegisterOptions = {}
  ) => {
    const pathToRegExpKeys: pathToRegExp.Key[] = [];
    const regexp = pathToRegExp.pathToRegexp(path, pathToRegExpKeys);
    this.routes.push({
      key: routeKey,
      regexp,
      pathToRegExpKeys,
      paramWordsSeparator,
      queryParams,
    });
  };

  match = (urlString: string): DeepLinkRouteResult<RouteKey> | null => {
    const url = new URL(urlString);
    const { pathname, host } = url;
    // Cater different behavior between ios and other browsers
    // ios: app://path => host = "path", pathname = ""
    // others: app://path => host: "", pathname = "//path"
    const path =
      host && pathname ? `//${host}${pathname}` : host ? `//${host}` : pathname;
    for (const route of this.routes) {
      const result = route.regexp.exec(path);
      if (result == null) {
        continue;
      }
      const {
        key: routeKey,
        pathToRegExpKeys,
        paramWordsSeparator,
        queryParams,
      } = route;
      const param: { [key in string]: string } = {};
      // Note:
      // result[0] is the matched url
      // param results are started at index 1

      const decodeParamValue = (rawValue: string): string => {
        return decodeURIComponent(rawValue).replace(
          new RegExp(paramWordsSeparator, "g"),
          " "
        );
      };

      for (let i = 1; i < result.length; ++i) {
        const paramName = pathToRegExpKeys[i - 1].name;
        const paramValue = decodeParamValue(result[i]);
        param[paramName] = paramValue;
      }

      const queryParam_: { [key in string]: string | null } = {};
      for (const queryParam of queryParams) {
        const value = url.searchParams.get(queryParam.queryParamName);
        const name =
          queryParam.renamedName != null
            ? queryParam.renamedName
            : queryParam.queryParamName;
        queryParam_[name] = value != null ? decodeParamValue(value) : null;
      }

      return {
        routeKey,
        param,
        queryParam: queryParam_,
      };
    }
    return null;
  };
}

export function makeDeepLinkParser<K>(
  keys: { key: K; route: string }[]
): DeepLinkParser<K> {
  const deepLinkParser = new DeepLinkParser<K>();
  for (const { key, route } of keys) {
    deepLinkParser.register(key, route);
  }
  return deepLinkParser;
}
