import React from "react";

import {
  FormattedDropdown,
  FormattedDropdownProps,
  Option as FormattedOption,
} from "../FormattedDropdown";

import { Omit } from "../../utils/typeutils";
import { Gender } from "../../models";

export type GenderDropdownProps = Omit<FormattedDropdownProps, "options">;

export const GenderOptions: FormattedOption[] = [
  {
    key: Gender.male,
    value: Gender.male,
    labelId: "gender.male",
  },
  {
    key: Gender.female,
    value: Gender.female,
    labelId: "gender.female",
  },
  {
    key: Gender.other,
    value: Gender.other,
    labelId: "gender.other",
  },
  {
    key: Gender.preferNotToSay,
    value: Gender.preferNotToSay,
    labelId: "gender.prefer_not_to_say",
  },
];

export const GenderDropdown: React.FC<GenderDropdownProps> = props => {
  return <FormattedDropdown options={GenderOptions} {...props as any} />;
};
