import React from "react";
import { OurTabs, OurRouterOutlet } from "../../navigation";

import { RouteConfig } from "../../types/routes";
import { renderRoutesFromConfigs } from "../../utils/routes";

interface Props {
  outletId?: string;
  routeConfigs: RouteConfig[];
}

export const BaseTabs: React.FC<Props> = props => {
  const { outletId, routeConfigs, children } = props;
  return (
    <OurTabs>
      <OurRouterOutlet id={outletId}>
        {renderRoutesFromConfigs(routeConfigs)}
      </OurRouterOutlet>
      {children}
    </OurTabs>
  );
};
