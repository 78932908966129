import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { Plugins } from "@capacitor/core";

import { UserContextProps, withUser } from "../context/UserContext";
import { LoadingContextProps, withLoading } from "../context/LoadingContext";
import AccountView from "../components/AccountView";

import { StaticPageType } from "../types/misc";
import { getStaticPageUrl } from "../utils/misc";
import { ensureBorrower } from "../utils/user";

const { Browser } = Plugins;

type Props = LoadingContextProps & UserContextProps & RouteComponentProps;

class AccountScreen extends PureComponent<Props> {
  onEditButtonClick = () => {
    const { history } = this.props;
    history.push("account/edit-data");
  };

  onLogOutButtonClick = () => {
    const { history } = this.props;

    this.props.userContext.logout().then(() => {
      history.push("/");
    });
  };

  openStaticPage = async (key: StaticPageType) => {
    this.props.loadingContext.show();
    await Browser.open({ url: getStaticPageUrl(key) });
    this.props.loadingContext.dismiss();
  };

  render() {
    const { user } = this.props.userContext;
    const borrower = ensureBorrower(user);

    return (
      <>
        {borrower && (
          <AccountView
            borrower={borrower}
            onEditButtonClick={this.onEditButtonClick}
            openStaticPage={this.openStaticPage}
            onLogOutButtonClick={this.onLogOutButtonClick}
          />
        )}
      </>
    );
  }
}

export default withLoading(withUser(AccountScreen));
