import { IonModal, IonRippleEffect } from "@ionic/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import React, { PureComponent } from "react";

import { withLocale, LocaleContextProps } from "../../context/LocaleContext";
import { withOptions, OptionsProps } from "../../context/OptionsContext";
import { Industry } from "../../models";
import { Locale } from "../../types/misc";
import { scrollToElement } from "../../utils/scroll";

import { IndustryListView } from "./IndustryListView";
import styles from "./styles.module.scss";

export interface OwnProps {
  containerClass?: string;
  isError?: boolean;
  disabled?: boolean;
  value?: Industry | null;
  onChange?: (industry: Industry) => void;
  shouldFocus?: boolean;
  shouldScrollTo?: boolean;
  scrollAnchor?: string;
}

export type IndustryPickerProps = OwnProps & OptionsProps & LocaleContextProps;

interface State {
  isOpen: boolean;
}

class IndustryPickerImpl extends PureComponent<IndustryPickerProps, State> {
  constructor(props: IndustryPickerProps) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  ref = React.createRef<HTMLInputElement>();

  componentDidUpdate(prevProps: IndustryPickerProps) {
    if (
      prevProps.shouldFocus !== this.props.shouldFocus &&
      this.props.shouldFocus
    ) {
      this.setState({ isOpen: true });
    }

    if (
      prevProps.shouldScrollTo !== this.props.shouldScrollTo &&
      this.props.shouldScrollTo
    ) {
      scrollToElement(this.ref.current, this.props.scrollAnchor);
    }
  }

  getDisplayNameKey() {
    const { localeContext } = this.props;
    switch (localeContext.locale) {
      case Locale.en:
        return "nameEn";
      case Locale.zhHant:
        return "nameZhHant";
      case Locale.zhHans:
        return "nameZhHans";
      default:
        return "nameEn";
    }
  }

  render() {
    const { isError, value, containerClass, disabled } = this.props;
    const { isOpen } = this.state;
    const displayNameKey = this.getDisplayNameKey();

    return (
      <>
        <div
          className={classNames(
            "ion-activatable",
            styles.button,
            containerClass,
            {
              [styles.disabled]: disabled,
              [styles.buttonInactive]: value == null,
              [styles.error]: isError,
            }
          )}
          onClick={this.handleClick}
          ref={this.ref}
        >
          {!disabled && <IonRippleEffect />}
          {value ? (
            value[displayNameKey]
          ) : (
            <FormattedMessage id="industry_picker.placeholder" />
          )}
        </div>
        <IonModal isOpen={isOpen} onDidDismiss={this.handleModalDismiss}>
          <IndustryListView
            displayNameKey={displayNameKey}
            options={this.props.options.industryOptions}
            onClose={this.handleModalClose}
            onChange={this.handleIndustryChange}
          />
        </IonModal>
      </>
    );
  }

  handleClick = () => {
    if (this.props.disabled) {
      return;
    }
    this.setState({ isOpen: true });
  };

  handleModalDismiss = () => {
    this.setState({ isOpen: false });
  };

  handleModalClose = () => {
    this.setState({ isOpen: false });
  };

  handleIndustryChange = (industry: Industry) => {
    const { onChange } = this.props;

    this.setState({ isOpen: false });

    if (onChange) {
      onChange(industry);
    }
  };
}

export const IndustryPicker = withLocale(withOptions(IndustryPickerImpl));
