import {
  IonContent,
  IonHeader,
  IonImg,
  IonToolbar,
  IonPage,
} from "@ionic/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { PureComponent } from "react";

import { common } from "../../images";
import { District } from "../../models";

import styles from "./DistrictListView.module.scss";

export interface DistrictListViewProps {
  options: District[];
  onClose?: () => void;
  onChange?: (district: District) => void;
}

export class DistrictListView extends PureComponent<DistrictListViewProps> {
  renderItem = (district: District, index: number) => {
    const onClick = () => {
      if (this.props.onChange) {
        this.props.onChange(district);
      }
    };
    return (
      <div key={index} className={styles.item} onClick={onClick}>
        <FormattedMessage id={district.nameId} />
      </div>
    );
  };

  render() {
    const { onClose, options } = this.props;

    return (
      <IonPage>
        <IonHeader mode="ios">
          <IonToolbar mode="ios">
            <div className={styles.closeButton} onClick={onClose}>
              <IonImg src={common.buttonCross} />
            </div>
            <div className={styles.title}>
              <FormattedMessage id="district_picker.title" />
            </div>
          </IonToolbar>
        </IonHeader>
        <IonContent>{options.map((d, i) => this.renderItem(d, i))}</IonContent>
      </IonPage>
    );
  }
}
