import * as yup from "yup";

import {
  User,
  UserData,
  UserType,
  BorrowerData,
  Education,
  BorrowerBriefData,
  BorrowerBriefDataWithExtraInfo,
  AgentData,
  AgentBriefData,
  Ownership,
  PremiseType,
  Industry,
  ExistingLoan,
  BorrowerPersonalInfo,
  PropertyInfo,
  Gender,
  PropertyStatus,
  PaymentMethod,
  OtherIncome,
} from "../models";
import { companySchema } from "./company";
import { DateTimeSchema } from "./luxon";
import { offeringFormSchema } from "./offering_form";

export const userTypeSchema: yup.Schema<UserType> = yup
  .string()
  .oneOf([UserType.agent, UserType.borrower]) as yup.Schema<UserType>;

export const userDataSchema: yup.Schema<UserData> = yup
  .object({
    type: userTypeSchema.required(),
    username: yup.string().required(),
    email: yup.string().nullable(),
  })
  .camelCase();

const agentBriefDataSchemaData = {
  id: yup.string().required(),
  userId: yup.string().required(),
  refNum: yup.number().required(),
  name: yup.string().required(),
  company: companySchema.required(),
};

export const agentBriefDataSchema: yup.Schema<AgentBriefData> = yup
  .object({
    ...agentBriefDataSchemaData,
  })
  .camelCase();

export const agentDataSchema: yup.Schema<AgentData> = yup
  .object({
    ...agentBriefDataSchemaData,
    company: companySchema.required(),
    isPremier: yup.bool().required(),
    isValidated: yup.bool().required(),
    offeringTemplate: yup.array(offeringFormSchema).default([]),
    isAdmin: yup.bool().required(),
  })
  .camelCase();

export const nullableGenderSchema: yup.Schema<Gender> = yup
  .string()
  .oneOf([
    Gender.male,
    Gender.female,
    Gender.other,
    Gender.preferNotToSay,
    null,
  ]) as yup.Schema<Gender>;

export const nullablePropertyStatusSchema: yup.Schema<
  PropertyStatus
> = yup
  .string()
  .oneOf([
    PropertyStatus.familyOwned,
    PropertyStatus.whollyOwned,
    PropertyStatus.jointlyOwned,
    PropertyStatus.leased,
    PropertyStatus.ownedByTheCompany,
    null,
  ]) as yup.Schema<PropertyStatus>;

export const nullableEducationSchema: yup.Schema<
  Education
> = yup
  .string()
  .oneOf([
    Education.bachelor,
    Education.diploma,
    Education.higherDiploma,
    Education.postGraduate,
    null,
  ]) as yup.Schema<Education>;

export const nullableOwnershipSchema: yup.Schema<
  Ownership
> = yup
  .string()
  .oneOf([
    Ownership.selfOwned,
    Ownership.familyOwned,
    Ownership.rent,
    null,
  ]) as yup.Schema<Ownership>;

export const nullablePremiseTypeSchema: yup.Schema<
  PremiseType
> = yup
  .string()
  .oneOf([
    PremiseType.privateEstate,
    PremiseType.publicHousing,
    PremiseType.homeOwnershipSchemeCourts,
    PremiseType.villageHouse,
    PremiseType.ntSmallHouse,
    null,
  ]) as yup.Schema<PremiseType>;

export const industrySchema: yup.Schema<Industry> = yup
  .object<Industry>({
    id: yup.string().required(),
    type: yup.string().required(),
    nameEn: yup.string().required(),
    nameZhHant: yup.string().required(),
    nameZhHans: yup.string().required(),
  })
  .camelCase();

export const nullablePaymentMethodSchema: yup.Schema<
  PaymentMethod
> = yup
  .string()
  .oneOf([
    PaymentMethod.automaticTransfer,
    PaymentMethod.cheque,
    PaymentMethod.cash,
    null,
  ]) as yup.Schema<PaymentMethod>;

export const paymentMethodSchema: yup.Schema<
  PaymentMethod
> = yup
  .string()
  .oneOf([
    PaymentMethod.automaticTransfer,
    PaymentMethod.cheque,
    PaymentMethod.cash,
  ]) as yup.Schema<PaymentMethod>;

const borrowerBriefDataSchemaData = {
  id: yup.string().required(),
  userId: yup.string().required(),
  refNum: yup.number().required(),
  name: yup.string().nullable(),
};

const borrowerExtraInfoDataSchema = {
  education: nullableEducationSchema.nullable(),
  heardFrom: yup.string().nullable(),
  district: yup.string().nullable(),
  ownership: nullableOwnershipSchema.nullable(),
  premiseType: nullablePremiseTypeSchema.nullable(),
  numOfOwner: yup.number().nullable(),
  isHired: yup.bool().nullable(),
  industry: industrySchema.default(null).nullable(),
  position: yup.string().nullable(),
  salary: yup.string().nullable(),
  employmentType: yup.string().nullable(),
  onBoardDate: yup.string().nullable(),
  incomeProofType: yup.string().nullable(),
  hasMortgage: yup.bool().nullable(),
  monthlyRepayment: yup.string().nullable(),
};

const exitingLoanSchema: yup.Schema<ExistingLoan> = yup
  .object<ExistingLoan>({
    id: yup.string().required(),
    lender: yup.string().nullable(),
    amount: yup.number().nullable(),
    tenor: yup.number().nullable(),
    remainingTenor: yup.number().nullable(),
    monthlyRepayment: yup.number().nullable(),
  })
  .camelCase();

const borrowerLoanInfoSchemaData = {
  hasMortgage: yup.bool().nullable(),
  existingLoans: yup.array(exitingLoanSchema).default([]),
};

const borrowerBasicInfoSchemaData = {
  name: yup.string().nullable(),
  surname: yup.string().nullable(),
  gender: nullableGenderSchema.nullable(),
  passportNumber: yup.string().nullable(),
  email: yup.string().nullable(),
  birthday: new DateTimeSchema().nullable(),
  education: nullableEducationSchema.nullable(),
  roomFloor: yup.string().nullable(),
  tower: yup.string().nullable(),
  nameOfBuilding: yup.string().nullable(),
  streetNameAndNumber: yup.string().nullable(),
  district: yup.string().nullable(),
  propertyStatus: nullablePropertyStatusSchema.nullable(),
  monthlyRent: yup.number().nullable(),
  heardFrom: yup.string().nullable(),
  premiseType: nullablePremiseTypeSchema.nullable(),
  numOfOwner: yup.number().nullable(),
};

const propertyInfoSchema: yup.Schema<PropertyInfo> = yup
  .object<PropertyInfo>({
    id: yup.string().required(),
    roomFloor: yup.string().nullable(),
    tower: yup.string().nullable(),
    nameOfBuilding: yup.string().nullable(),
    streetNameAndNumber: yup.string().nullable(),
    district: yup.string().nullable(),
  })
  .camelCase();

const borrowerPropertyInfoSchemaData = {
  numberOfProperties: yup.number().nullable(),
  propertyInfos: yup.array(propertyInfoSchema).default([]),
};

const borrowerWorkInfoSchemaData = {
  isHired: yup.bool().nullable(),
  industry: industrySchema.default(null).nullable(),
  workingCompanyNames: yup.string().nullable(),
  position: yup.string().nullable(),
  employmentType: yup.string().nullable(),
  onBoardDate: yup.string().nullable(),
  incomeProofType: yup.string().nullable(),
  paymentMethod: nullablePaymentMethodSchema.nullable(),
};

const otherIncomeSchema: yup.Schema<OtherIncome> = yup
  .object<OtherIncome>({
    id: yup.string().required(),
    workingCompanyNames: yup.string().nullable(),
    position: yup.string().nullable(),
    salary: yup.number().nullable(),
    onBoardDate: yup.string().nullable(),
    incomeProofType: yup.string().nullable(),
    paymentMethod: nullablePaymentMethodSchema.nullable(),
  })
  .camelCase();

const borrowerOtherIncomeInfoSchemaData = {
  numberOfOtherIncomes: yup.number().nullable(),
  otherIncomes: yup.array(otherIncomeSchema).default([]),
};

export const borrowerBriefDataSchema: yup.Schema<BorrowerBriefData> = yup
  .object({
    ...borrowerBriefDataSchemaData,
  })
  .camelCase();

export const borrowerBriefDataWithExtraInfoSchema: yup.Schema<
  BorrowerBriefDataWithExtraInfo
> = yup
  .object({
    ...borrowerBriefDataSchemaData,
    ...borrowerExtraInfoDataSchema,
    ...borrowerLoanInfoSchemaData,
  })
  .camelCase();

export const borrowerPersonalInfoSchema: yup.Schema<BorrowerPersonalInfo> = yup
  .object({
    ...borrowerBriefDataSchemaData,
    ...borrowerExtraInfoDataSchema,
    ...borrowerLoanInfoSchemaData,
    birthday: new DateTimeSchema().nullable(),
  })
  .camelCase();

//TODO(luihsuan): Remove `borrowerExtraInfoDataSchema` after the work-info introduced
export const borrowerDataSchema: yup.Schema<BorrowerData> = yup
  .object({
    ...borrowerBriefDataSchemaData,
    ...borrowerExtraInfoDataSchema,
    ...borrowerLoanInfoSchemaData,
    ...borrowerBasicInfoSchemaData,
    ...borrowerPropertyInfoSchemaData,
    ...borrowerWorkInfoSchemaData,
    ...borrowerOtherIncomeInfoSchemaData,
    email: yup.string().nullable(),
    birthday: new DateTimeSchema().nullable(),
  })
  .camelCase();

export function validateUser(data: any): User {
  const userData = userDataSchema.validateSync(data);

  switch (userData.type) {
    case UserType.agent:
      return {
        ...userData,
        ...agentDataSchema.validateSync(userData),
      };
    case UserType.borrower:
      return {
        ...userData,
        ...borrowerDataSchema.validateSync(userData),
      };
    default:
      throw new Error(`Unknowne UserType = ${userData.type}`);
  }
}
