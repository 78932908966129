import * as yup from "yup";

import { Company } from "../models";
import { templateSchema } from "./template";

export const companySchema: yup.Schema<Company> = yup
  .object({
    id: yup.string().required(),
    name: yup.string().required(),
    licenseNo: yup.string().nullable(),
    templates: yup.array(templateSchema).default([]),
  })
  .camelCase();
