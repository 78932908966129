import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import { DateTime } from "luxon";
import React, { PureComponent } from "react";

import { DatePicker } from "../DatePicker";
import { DistrictPicker } from "../DistrictPicker";
import ErrorField from "../ErrorField";
import { FormattedInput } from "../FormattedInput";
import { EducationDropdown, PremiseTypeDropdown } from "../SpecifyDropdown";

import {
  District,
  Education,
  Gender,
  PremiseType,
  PropertyStatus,
} from "../../models";
import { capitalizeFirstLetter } from "../../utils/stringPreprocessing";
import { isDesktopSafari } from "../../utils/platform";

import { GenderDropdown } from "../SpecifyDropdown/GenderDropdown";
import { PropertyStatusDropdown } from "../SpecifyDropdown/PropertyStatusDropdown";
import {
  BorrowerPropertyInfoView,
  InputFields as BorrowerPropertyInfoViewInputFields,
} from "../BorrowerPropertyInfoView";
import { StaticDistrictOption } from "../../context/OptionsContext";
import {
  currencyFormatToNumber,
  formatToCurrencyAmount,
} from "../../utils/formatToCurrencyAmount";

import styles from "./BorrowerBasicInfoForm.module.scss";
import { BorrowerBasicInfoProps, InputFields } from "./common";
import { BasicInfoField } from "../ConditionalDisplayField/ConditionalDisplayField";

interface State {
  nextField?: InputFields;
}

type Props = BorrowerBasicInfoProps;

export class BorrowerBasicInfoForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      nextField: undefined,
    };
  }

  componentDidUpdate() {
    if (this.state.nextField) {
      this.setState({ nextField: undefined });
    }
  }

  focusBirthdayField = () => {
    this.setState({ nextField: "birthday" });
  };

  focusDistrictField = () => {
    this.setState({ nextField: "district" });
  };

  render() {
    const { nextField } = this.state;
    const {
      borrowerBasicInfo,
      borrowerPropertyInfo,
      error,
      onUpdateExistingPropertyInfo,
      onUpdateHasProperty,
      onSelectNumberOfProperties,
      emailErrorId,
      focusField,
    } = this.props;
    const {
      name,
      surname,
      gender,
      passportNumber,
      email,
      birthday,
      education,
      heardFrom,
      roomFloor,
      tower,
      nameOfBuilding,
      streetNameAndNumber,
      district,
      premiseType,
      propertyStatus,
      numOfOwner,
      monthlyRent,
    } = borrowerBasicInfo;

    const selectedDistrict =
      StaticDistrictOption.find(d => d.value === district) || null;

    const birthdayPlaceholder = isDesktopSafari()
      ? "borrower_basic_info.birthday.safari"
      : "borrower_basic_info.birthday";

    const emailErrorMessage = error["email"] || emailErrorId;

    return (
      <>
        <div className={styles.container}>
          <BasicInfoField field="name">
            <div className={styles.basicField}>
              <FormattedInput
                className={styles.input}
                placeholderId="borrower_basic_info.name"
                maxLength={30}
                autoFocus={true}
                value={name || ""}
                onChange={this.handleNameChange}
                isError={!!error["name"]}
                shouldFocus={focusField === "name"}
                shouldScrollTo={focusField === "name"}
                scrollAnchor={"name"}
              />
              <ErrorField
                isShown={error["name"] !== undefined}
                hiddenType="gone"
                messageId={error["name"]}
              />
            </div>
          </BasicInfoField>

          <BasicInfoField field="surname">
            <div className={styles.basicField}>
              <FormattedInput
                className={styles.input}
                placeholderId="borrower_basic_info.surname"
                maxLength={30}
                value={surname || ""}
                onChange={this.handleSurnameChange}
                isError={!!error["surname"]}
                shouldFocus={focusField === "surname"}
                shouldScrollTo={focusField === "surname"}
                scrollAnchor={"surname"}
              />
              <ErrorField
                isShown={error["surname"] !== undefined}
                hiddenType="gone"
                messageId={error["surname"]}
              />
            </div>
          </BasicInfoField>

          <BasicInfoField field="gender">
            <div className={styles.basicField}>
              <GenderDropdown
                containerClass={styles.dropdownContainer}
                selectClass={styles.dropdownSelect}
                emptyClass={styles.dropdownEmpty}
                errorClass={styles.dropdownError}
                placeholderId="borrower_basic_info.gender"
                isError={!!error["gender"]}
                value={gender || ""}
                onValueChange={this.handleGenderChange}
                shouldFocus={focusField === "gender"}
                shouldScrollTo={focusField === "gender"}
                scrollAnchor={"gender"}
              />
              <ErrorField
                isShown={error["gender"] !== undefined}
                hiddenType="gone"
                messageId={error["gender"]}
              />
            </div>
          </BasicInfoField>

          <BasicInfoField field="passportNumber">
            <div className={styles.basicField}>
              <FormattedInput
                className={styles.input}
                placeholderId="borrower_basic_info.passportNumber"
                maxLength={30}
                value={passportNumber || ""}
                onChange={this.handlePassportNumberChange}
                isError={!!error["passportNumber"]}
                shouldFocus={focusField === "passportNumber"}
                shouldScrollTo={focusField === "passportNumber"}
                scrollAnchor={"passportNumber"}
              />
              <ErrorField
                isShown={error["passportNumber"] !== undefined}
                hiddenType="gone"
                messageId={error["passportNumber"]}
              />
            </div>
          </BasicInfoField>

          <BasicInfoField field="email">
            <div className={styles.basicField}>
              <FormattedInput
                type="email"
                autoComplete="email"
                placeholderId="borrower_basic_info.email"
                isError={!!emailErrorMessage}
                value={email || ""}
                onChange={this.handleEmailChange}
                // Note(jasonkit): https://github.com/facebook/react/issues/6368
                onKeyDown={this.handleEmailKeyDown}
                shouldFocus={focusField === "email"}
                shouldScrollTo={focusField === "email"}
                scrollAnchor={"email"}
                autoSubmit={this.focusBirthdayField}
              />
              <ErrorField
                isShown={emailErrorMessage !== undefined}
                hiddenType="gone"
                messageId={emailErrorMessage}
              />
            </div>
          </BasicInfoField>

          <BasicInfoField field="birthday">
            <div className={styles.basicField}>
              <DatePicker
                inputClass={classNames(styles.input, {
                  [styles.errored]: !!error["birthday"],
                })}
                placeholderId={birthdayPlaceholder}
                value={birthday || undefined}
                onChange={this.handleBirthdayChange}
                shouldFocus={nextField === "birthday"}
                shouldScrollTo={focusField === "birthday"}
                scrollAnchor={"birthday"}
              />
              <ErrorField
                isShown={error["birthday"] !== undefined}
                hiddenType="gone"
                messageId={error["birthday"]}
              />
            </div>
          </BasicInfoField>

          <BasicInfoField field="education">
            <div className={styles.basicField}>
              <EducationDropdown
                containerClass={styles.dropdownContainer}
                selectClass={styles.dropdownSelect}
                emptyClass={styles.dropdownEmpty}
                errorClass={styles.dropdownError}
                placeholderId="borrower_basic_info.education"
                isError={!!error["education"]}
                value={education || ""}
                onValueChange={this.handleEducationChange}
                shouldFocus={focusField === "education"}
                shouldScrollTo={focusField === "education"}
                scrollAnchor={"education"}
              />
              <ErrorField
                isShown={error["education"] !== undefined}
                hiddenType="gone"
                messageId={error["education"]}
              />
            </div>
          </BasicInfoField>

          <BasicInfoField field="heardFrom">
            <div className={styles.basicField}>
              <FormattedInput
                className={styles.input}
                placeholderId="borrower_basic_info.heardFrom"
                value={heardFrom || ""}
                onChange={this.handleHeardFromChange}
                isError={error.heardFrom !== undefined}
                shouldFocus={focusField === "heardFrom"}
                shouldScrollTo={focusField === "heardFrom"}
                scrollAnchor={"heardFrom"}
              />
              <ErrorField
                isShown={error["heardFrom"] !== undefined}
                hiddenType="gone"
                messageId={error["heardFrom"]}
              />
            </div>
          </BasicInfoField>

          <div className={styles.subtitleLabel}>
            <FormattedMessage id="borrower_basic_info.livingInfo" />
          </div>

          <BasicInfoField field="roomFloor">
            <div className={styles.basicField}>
              <FormattedInput
                className={styles.input}
                placeholderId="borrower_basic_info.roomFloor"
                maxLength={30}
                value={roomFloor || ""}
                onChange={this.handleRoomFloorChange}
                isError={!!error["roomFloor"]}
                shouldFocus={focusField === "roomFloor"}
                shouldScrollTo={focusField === "roomFloor"}
                scrollAnchor={"roomFloor"}
              />
              <ErrorField
                isShown={error["roomFloor"] !== undefined}
                hiddenType="gone"
                messageId={error["roomFloor"]}
              />
            </div>
          </BasicInfoField>

          <BasicInfoField field="tower">
            <div className={styles.basicField}>
              <FormattedInput
                className={styles.input}
                placeholderId="borrower_basic_info.tower"
                maxLength={30}
                value={tower || ""}
                onChange={this.handleTowerChange}
                isError={!!error["tower"]}
                shouldFocus={focusField === "tower"}
                shouldScrollTo={focusField === "tower"}
                scrollAnchor={"tower"}
              />
              <ErrorField
                isShown={error["tower"] !== undefined}
                hiddenType="gone"
                messageId={error["tower"]}
              />
            </div>
          </BasicInfoField>

          <BasicInfoField field="nameOfBuilding">
            <div className={styles.basicField}>
              <FormattedInput
                className={styles.input}
                placeholderId="borrower_basic_info.nameOfBuilding"
                maxLength={30}
                value={nameOfBuilding || ""}
                onChange={this.handleNameOfBuildingChange}
                isError={!!error["nameOfBuilding"]}
                shouldFocus={focusField === "nameOfBuilding"}
                shouldScrollTo={focusField === "nameOfBuilding"}
                scrollAnchor={"nameOfBuilding"}
              />
              <ErrorField
                isShown={error["nameOfBuilding"] !== undefined}
                hiddenType="gone"
                messageId={error["nameOfBuilding"]}
              />
            </div>
          </BasicInfoField>

          <BasicInfoField field="streetNameAndNumber">
            <div className={styles.basicField}>
              <FormattedInput
                className={styles.input}
                placeholderId="borrower_basic_info.streetNameAndNumber"
                maxLength={30}
                value={streetNameAndNumber || ""}
                onChange={this.handleStreetNameAndNumberChange}
                isError={!!error["streetNameAndNumber"]}
                shouldFocus={focusField === "streetNameAndNumber"}
                shouldScrollTo={focusField === "streetNameAndNumber"}
                scrollAnchor={"streetNameAndNumber"}
                autoSubmit={this.focusDistrictField}
              />
              <ErrorField
                isShown={error["streetNameAndNumber"] !== undefined}
                hiddenType="gone"
                messageId={error["streetNameAndNumber"]}
              />
            </div>
          </BasicInfoField>

          <BasicInfoField field="district">
            <div className={styles.districtField}>
              <DistrictPicker
                isError={!!error["district"]}
                value={selectedDistrict}
                onChange={this.handleDistrictChange}
                shouldFocus={nextField === "district"}
                shouldScrollTo={focusField === "district"}
                scrollAnchor={"district"}
              />
              <ErrorField
                isShown={error["district"] !== undefined}
                hiddenType="gone"
                messageId={error["district"]}
              />
            </div>
          </BasicInfoField>

          <BasicInfoField field="premiseType">
            <div className={styles.basicField}>
              <PremiseTypeDropdown
                containerClass={classNames(
                  styles.dropdownContainer,
                  styles.dropdownMargin
                )}
                selectClass={styles.dropdownSelect}
                emptyClass={styles.dropdownEmpty}
                errorClass={styles.dropdownError}
                placeholderId="borrower_basic_info.premiseType"
                isError={error.premiseType !== undefined}
                value={premiseType || ""}
                onValueChange={this.handlePremiseTypeChange}
                shouldFocus={focusField === "premiseType"}
                shouldScrollTo={focusField === "premiseType"}
                scrollAnchor={"premiseType"}
              />
              <ErrorField
                isShown={error["premiseType"] !== undefined}
                hiddenType="gone"
                messageId={error["premiseType"]}
              />
            </div>
          </BasicInfoField>

          <BasicInfoField field="propertyStatus">
            <div className={styles.basicField}>
              <PropertyStatusDropdown
                containerClass={styles.dropdownContainer}
                selectClass={styles.dropdownSelect}
                emptyClass={styles.dropdownEmpty}
                errorClass={styles.dropdownError}
                placeholderId="borrower_basic_info.propertyStatus"
                isError={!!error["propertyStatus"]}
                value={propertyStatus || ""}
                onValueChange={this.handlePropertyStatusChange}
                shouldFocus={focusField === "propertyStatus"}
                shouldScrollTo={focusField === "propertyStatus"}
                scrollAnchor={"propertyStatus"}
              />
              <ErrorField
                isShown={error["propertyStatus"] !== undefined}
                hiddenType="gone"
                messageId={error["propertyStatus"]}
              />
            </div>
          </BasicInfoField>

          <BasicInfoField field="numOfOwner">
            <div className={styles.basicField}>
              <FormattedInput
                className={classNames(styles.input, styles.numberOfOwner)}
                placeholderId="borrower_basic_info.numberOfOwner"
                type="tel"
                maxLength={4}
                value={`${numOfOwner || ""}`}
                onChange={this.handleNumOfOwnerChange}
                shouldFocus={focusField === "numOfOwner"}
                shouldScrollTo={focusField === "numOfOwner"}
                scrollAnchor={"numOfOwner"}
              />
            </div>
          </BasicInfoField>

          <BasicInfoField field="monthlyRent">
            <div className={styles.basicField}>
              <FormattedInput
                className={styles.input}
                placeholderId="borrower_basic_info.monthlyRent"
                type="tel"
                maxLength={15}
                value={formatToCurrencyAmount(monthlyRent) || ""}
                onChange={this.handleMonthlyRentChange}
                shouldFocus={focusField === "monthlyRent"}
                shouldScrollTo={focusField === "monthlyRent"}
                scrollAnchor={"monthlyRent"}
              />
              <ErrorField
                isShown={error["monthlyRent"] !== undefined}
                hiddenType="gone"
                messageId={error["monthlyRent"]}
              />
            </div>
          </BasicInfoField>

          <BasicInfoField field="numberOfProperties">
            <BorrowerPropertyInfoView
              borrowerPropertyInfo={borrowerPropertyInfo}
              selectNumberOfProperties={onSelectNumberOfProperties}
              updateHasProperty={onUpdateHasProperty}
              updateExistingPropertyInfo={onUpdateExistingPropertyInfo}
              error={error}
              focusField={focusField as BorrowerPropertyInfoViewInputFields}
            />
          </BasicInfoField>
        </div>
      </>
    );
  }

  handleNameChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const name = capitalizeFirstLetter(e.currentTarget.value || "");
    this.props.updateBorrowerBasicInfo({
      name,
    });
  };

  handleSurnameChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const surname = capitalizeFirstLetter(e.currentTarget.value || "");
    this.props.updateBorrowerBasicInfo({
      surname,
    });
  };

  handleGenderChange = (gender: string) => {
    this.props.updateBorrowerBasicInfo({
      gender: gender as Gender,
    });
  };

  handlePassportNumberChange: React.ChangeEventHandler<
    HTMLInputElement
  > = e => {
    const passportNumber = e.currentTarget.value || "";
    this.props.updateBorrowerBasicInfo({
      passportNumber,
    });
  };

  handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const email = e.currentTarget.value || "";
    this.props.updateBorrowerBasicInfo({
      email,
    });
  };

  handleEmailKeyDown: React.KeyboardEventHandler = e => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  handleBirthdayChange = (birthday?: DateTime) => {
    this.props.updateBorrowerBasicInfo({
      birthday,
    });
  };

  handleEducationChange = (education: string) => {
    this.props.updateBorrowerBasicInfo({
      education: education as Education,
    });
  };

  handleRoomFloorChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const roomFloor = e.currentTarget.value || "";
    this.props.updateBorrowerBasicInfo({
      roomFloor,
    });
  };

  handleTowerChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const tower = e.currentTarget.value || "";
    this.props.updateBorrowerBasicInfo({
      tower,
    });
  };

  handleNameOfBuildingChange: React.ChangeEventHandler<
    HTMLInputElement
  > = e => {
    const nameOfBuilding = e.currentTarget.value || "";
    this.props.updateBorrowerBasicInfo({
      nameOfBuilding,
    });
  };

  handleStreetNameAndNumberChange: React.ChangeEventHandler<
    HTMLInputElement
  > = e => {
    const streetNameAndNumber = e.currentTarget.value || "";
    this.props.updateBorrowerBasicInfo({
      streetNameAndNumber,
    });
  };

  handlePropertyStatusChange = (propertyStatus: string) => {
    this.props.updateBorrowerBasicInfo({
      propertyStatus: propertyStatus as PropertyStatus,
      ...(propertyStatus === PropertyStatus.whollyOwned &&
        !this.props.borrowerBasicInfo.numOfOwner && {
          numOfOwner: 1,
        }),
    });
  };

  handleDistrictChange = (district: District) => {
    this.props.updateBorrowerBasicInfo({
      district: district.value,
    });
  };

  handleHeardFromChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const heardFrom = e.currentTarget.value || "";
    this.props.updateBorrowerBasicInfo({
      heardFrom,
    });
  };

  handlePremiseTypeChange = (premiseType: string) => {
    this.props.updateBorrowerBasicInfo({
      premiseType: premiseType as PremiseType,
    });
  };

  handleNumOfOwnerChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const numOfOwnerStr = e.currentTarget.value || "";
    const numOfOwner = parseInt(numOfOwnerStr, 10);
    this.props.updateBorrowerBasicInfo({
      numOfOwner: isNaN(numOfOwner) ? null : numOfOwner,
    });
  };

  handleMonthlyRentChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const monthlyRent = currencyFormatToNumber(
      e.currentTarget.value || "",
      false
    );
    this.props.updateBorrowerBasicInfo({
      monthlyRent,
    });
  };
}
