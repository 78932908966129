import * as yup from "yup";

import { Page } from "../utils/pagination";

export function makePageSchema<T>(schema: yup.Schema<T>): yup.Schema<Page<T>> {
  return yup
    .object({
      nextCursor: yup.string().nullable(),
      results: yup.array(schema).default([]),
    })
    .camelCase();
}
