import { Plugins } from "@capacitor/core";
import {
  AgentImpersonationState,
  defaultAgentImpersonationState,
} from "../types/auth";
import { WalkthroughStage } from "../types/walkthrough";
import { FormValues as RequestMortgageFormValues } from "../components/RequestMortgageForm";
import { FormValues as RequestPersonalLoanFormValues } from "../components/RequestPersonalLoanForm";
import { RequestFormValues } from "../components/CreateRequestView/types";

export enum StorageKey {
  Walkthrough = "walkthrough",
  AgentImpersonationState = "agent-impersonation-state",
  RequestPersonalLoanForm = "request-personal-load-form",
  RequestMortgageForm = "request-mortgage-form",
}

export enum AndroidPermissionKey {
  Camera = "android-permission-camera",
  PhtoAlbum = "android-permission-photo-album",
}

export interface AndroidPermissionOptions {
  skipped: boolean;
  prompted: boolean;
}

const defaultAndroidPermissionOptions: AndroidPermissionOptions = {
  skipped: false,
  prompted: false,
};

const { Storage } = Plugins;

export const getStorageItem = (
  key: string
): Promise<{ value: string | null }> => {
  return Storage.get({ key: key });
};

export const setStorageItem = (key: string, value: string): Promise<void> => {
  return Storage.set({
    key: key,
    value: value,
  });
};

export const removeStorageItem = (key: string): Promise<void> => {
  return Storage.remove({
    key: key,
  });
};

export const getWalkthroughValue = async (): Promise<WalkthroughStage> => {
  const promise = await getStorageItem(StorageKey.Walkthrough);
  return promise.value === WalkthroughStage.Done
    ? WalkthroughStage.Done
    : WalkthroughStage.Unseen;
};

export const setWalkthroughDone = (): Promise<void> => {
  return setStorageItem(StorageKey.Walkthrough, WalkthroughStage.Done);
};

export const getAgentImpersonationState = async (): Promise<
  AgentImpersonationState
> => {
  const result = await getStorageItem(StorageKey.AgentImpersonationState);
  if (!result.value) {
    return defaultAgentImpersonationState;
  }
  try {
    const obj = JSON.parse(result.value);
    return {
      agentImpersonatorAccessToken: obj.agentImpersonatorAccessToken || null,
      isImpersonatingAgent: obj.isImpersonatingAgent || false,
      currentUserCustomToken: obj.currentUserCustomToken || null,
    };
  } catch {
    return defaultAgentImpersonationState;
  }
};

export const setAgentImpersationState = (
  agentImpersonationState: AgentImpersonationState
) => {
  return setStorageItem(
    StorageKey.AgentImpersonationState,
    JSON.stringify(agentImpersonationState)
  );
};

export const setLatestRequestFormValues = (
  key: "request-personal-load-form" | "request-mortgage-form",
  values: RequestFormValues
) => {
  return setStorageItem(key, JSON.stringify(values));
};

export const getRequestPersonalLoanForm = async (): Promise<
  RequestPersonalLoanFormValues | undefined
> => {
  const promise = await getStorageItem(StorageKey.RequestPersonalLoanForm);
  if (!promise.value) {
    return undefined;
  }
  try {
    const result: RequestPersonalLoanFormValues = JSON.parse(promise.value);
    return result;
  } catch {
    return undefined;
  }
};

export const getRequestMortgageForm = async (): Promise<
  RequestMortgageFormValues | undefined
> => {
  const promise = await getStorageItem(StorageKey.RequestMortgageForm);
  if (!promise.value) {
    return undefined;
  }
  try {
    const result: RequestMortgageFormValues = JSON.parse(promise.value);
    return result;
  } catch {
    return undefined;
  }
};

export const setAndroidPermissionPrompted = async (
  key: "android-permission-camera" | "android-permission-photo-album"
) => {
  const promise = await getStorageItem(key);
  return setStorageItem(
    key,
    JSON.stringify({
      ...(promise.value ? JSON.parse(promise.value) : {}),
      prompted: true,
    })
  );
};

export const setAndroidPermissionSkipped = async (
  key: "android-permission-camera" | "android-permission-photo-album"
) => {
  const promise = await getStorageItem(key);
  return setStorageItem(
    key,
    JSON.stringify({
      ...(promise.value ? JSON.parse(promise.value) : {}),
      skipped: true,
    })
  );
};

export const getAndroidCameraPermissionOptions = async (): Promise<
  AndroidPermissionOptions
> => {
  const promise = await getStorageItem(AndroidPermissionKey.Camera);
  if (!promise.value) {
    return Promise.resolve(defaultAndroidPermissionOptions);
  }
  try {
    const result: AndroidPermissionOptions = JSON.parse(promise.value);
    return result;
  } catch {
    return defaultAndroidPermissionOptions;
  }
};

export const getAndroidPhotoPermissionOptions = async (): Promise<
  AndroidPermissionOptions
> => {
  const promise = await getStorageItem(AndroidPermissionKey.PhtoAlbum);
  if (!promise.value) {
    return Promise.resolve(defaultAndroidPermissionOptions);
  }
  try {
    const result: AndroidPermissionOptions = JSON.parse(promise.value);
    return result;
  } catch {
    return defaultAndroidPermissionOptions;
  }
};
