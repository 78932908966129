import React from "react";

import { TemplateApiClient } from "../apiClient/template";
import { LocalizedTemplates, Offering, Template } from "../models";
import { Locale } from "../types/misc";
import { Omit } from "../utils/typeutils";

import { withLocale, LocaleContextProps } from "./LocaleContext";

export interface TemplateContextValue {
  defaultLocalizedTemplates: LocalizedTemplates;
  fetch: () => Promise<void>;
  getLocalizedTemplates: (
    templates?: Template[],
    locale?: Locale
  ) => LocalizedTemplates;
  getLicenseMessage: (
    offering: Offering,
    localizedTemplates: LocalizedTemplates
  ) => string;
  getReferenceOnlyMessage: (
    localizedTemplates: LocalizedTemplates,
    offering: Offering
  ) => string;
  getChatroomPopupMessage: (
    localizedTemplates: LocalizedTemplates,
    offering: Offering
  ) => string;
  getDirectApplyPopupMessage: (
    localizedTemplates: LocalizedTemplates,
    offering: Offering
  ) => string;
  getChatroomHeaderMessage: (
    localizedTemplates: LocalizedTemplates,
    offering: Offering
  ) => string;
}

const TemplateContext = React.createContext(null as any);
export { TemplateContext };

export interface TemplateContextProps {
  templateContext: TemplateContextValue;
}

interface Props extends LocaleContextProps {
  apiClient: TemplateApiClient;
}

interface State {
  localizedTemplatesByLocale: { [key in Locale]: LocalizedTemplates };
}

class TemplateContextProviderImpl extends React.PureComponent<Props, State> {
  state: State = {
    localizedTemplatesByLocale: {
      [Locale.en]: {},
      [Locale.zhHant]: {},
      [Locale.zhHans]: {},
    },
  };

  defaultLocalizedTemplates = () => {
    const { localizedTemplatesByLocale } = this.state;
    const { localeContext } = this.props;
    return localizedTemplatesByLocale[localeContext.locale];
  };

  fetch = async (): Promise<void> => {
    const { apiClient } = this.props;
    const defaultTemplate = await apiClient.fetchDefaultTemplate();
    const localizedTemplatesByLocale = {
      [Locale.en]: this.getLocalizedTemplates(defaultTemplate, Locale.en),
      [Locale.zhHant]: this.getLocalizedTemplates(
        defaultTemplate,
        Locale.zhHant
      ),
      [Locale.zhHans]: this.getLocalizedTemplates(
        defaultTemplate,
        Locale.zhHans
      ),
    };
    this.setState({
      localizedTemplatesByLocale,
    });
  };

  getLocalizedTemplates = (
    templates?: Template[],
    locale?: Locale
  ): LocalizedTemplates => {
    if (!templates) {
      return this.defaultLocalizedTemplates();
    }

    const selectedLocale = locale || this.props.localeContext.locale;
    let keyOfName: "contentEn" | "contentZhHant" | "contentZhHans" =
      "contentEn";
    switch (selectedLocale) {
      case Locale.en:
        keyOfName = "contentEn";
        break;
      case Locale.zhHant:
        keyOfName = "contentZhHant";
        break;
      case Locale.zhHans:
        keyOfName = "contentZhHans";
        break;
      default:
        throw new Error(`Unexpected locale: ${selectedLocale}`);
    }

    const companyLocalizedTemplates = templates.reduce<LocalizedTemplates>(
      (acc, cur) => {
        acc[cur.type] = cur[keyOfName];
        return acc;
      },
      {}
    );

    return {
      ...this.defaultLocalizedTemplates(),
      ...companyLocalizedTemplates,
    };
  };

  getLicenseMessage = (
    offering: Offering,
    localizedTemplates: LocalizedTemplates
  ): string => {
    const licenseTemplate = localizedTemplates.licence_info;
    if (!licenseTemplate) {
      return "";
    }
    return this.replaceOfferingTemplateVariables(licenseTemplate, offering);
  };

  getReferenceOnlyMessage = (
    localizedTemplates: LocalizedTemplates,
    offering: Offering
  ): string => {
    const referenceOnlyTemplate = localizedTemplates.reference_only;
    if (!referenceOnlyTemplate) {
      return "";
    }
    return this.replaceOfferingTemplateVariables(
      referenceOnlyTemplate,
      offering
    );
  };

  getChatroomPopupMessage = (
    localizedTemplates: LocalizedTemplates,
    offering: Offering
  ): string => {
    const popupTemplate = localizedTemplates.chatroom_popup;
    if (!popupTemplate) {
      return "";
    }
    return this.replaceOfferingTemplateVariables(popupTemplate, offering);
  };

  getDirectApplyPopupMessage = (
    localizedTemplates: LocalizedTemplates,
    offering: Offering
  ): string => {
    const popupTemplate = localizedTemplates.direct_apply_popup;
    if (!popupTemplate) {
      return "";
    }
    return this.replaceOfferingTemplateVariables(popupTemplate, offering);
  };

  getChatroomHeaderMessage = (
    localizedTemplates: LocalizedTemplates,
    offering: Offering
  ): string => {
    const headerTemplate = localizedTemplates.chatroom_header;
    if (!headerTemplate) {
      return "";
    }
    return this.replaceOfferingTemplateVariables(headerTemplate, offering);
  };

  private replaceOfferingTemplateVariables(
    template: string,
    offering: Offering
  ) {
    const companyName = offering.agent.company.name;
    const expiredDate = offering.expiredAt
      ? offering.expiredAt.toFormat("d/M/yy")
      : "-";
    const licenseNo = offering.agent.company.licenseNo
      ? `#${offering.agent.company.licenseNo}`
      : "-";
    const directApplyLink = offering.directApplyLink || "";
    return template
      .replace("%company%", companyName)
      .replace("%date%", expiredDate)
      .replace("%license%", licenseNo)
      .replace("%direct_apply_link%", directApplyLink);
  }

  render() {
    return (
      <TemplateContext.Provider
        value={{
          defaultLocalizedTemplates: this.defaultLocalizedTemplates(),
          fetch: this.fetch,
          getLocalizedTemplates: this.getLocalizedTemplates,
          getLicenseMessage: this.getLicenseMessage,
          getReferenceOnlyMessage: this.getReferenceOnlyMessage,
          getChatroomPopupMessage: this.getChatroomPopupMessage,
          getDirectApplyPopupMessage: this.getDirectApplyPopupMessage,
          getChatroomHeaderMessage: this.getChatroomHeaderMessage,
        }}
      >
        {this.props.children}
      </TemplateContext.Provider>
    );
  }
}

export const TemplateContextProvider = withLocale(TemplateContextProviderImpl);

export function withTemplate<P extends TemplateContextProps>(
  Component: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof TemplateContextProps>> {
  const Wrapped: React.FC<Omit<P, keyof TemplateContextProps>> = (
    props: Omit<P, keyof TemplateContextProps>
  ) => (
    // Cast ...props as any due to typescript bug.
    // See https://github.com/microsoft/TypeScript/issues/28938
    <TemplateContext.Consumer>
      {templateContext => (
        <Component {...props as any} templateContext={templateContext} />
      )}
    </TemplateContext.Consumer>
  );

  return Wrapped;
}
