import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router";

import { withPresentation, PresentationContextProps } from "../navigation";
import { withRequest, RequestContextProps } from "../context/RequestContext";
import { withOffering, OfferingContextProps } from "../context/OfferingContext";
import { withChat, ChatContextProps } from "../context/ChatContext";
import { withLoading, LoadingContextProps } from "../context/LoadingContext";
import {
  withErrorAlert,
  ErrorAlertContextProps,
} from "../context/ErrorAlertContext";
import { withUser, UserContextProps } from "../context/UserContext";

import { ListType } from "../types/misc";
import {
  Chat,
  ExchangeLoanOffering,
  Offering,
  OfferingType,
  Request,
} from "../models";

import BorrowerOfferingListView from "../components/BorrowerOfferingListView";
import { GetOfferingLocationState } from "./CreateRequestScreen";
import {
  CreateForeignExchangeRequest,
  CreateMortgageRequest,
  CreatePersonalLoanRequest,
  CreateRequest,
  QuickRequestData,
  RequestType,
} from "../types/request";
import {
  CreateRequestContextProps,
  withCreateRequest,
} from "../context/CreateRequestContext";
import { ensureBorrower } from "../utils/user";
import {
  getRequestMortgageForm,
  getRequestPersonalLoanForm,
} from "../utils/storage";
import { RequestFormValues } from "../components/CreateRequestView/types";
import { defaultState as defaultRequestMortgageFormValues } from "../components/RequestMortgageForm";
import { defaultState as defaultRequestPersonalLoanFormValues } from "../components/RequestPersonalLoanForm";

export enum Mode {
  PersonalLoan = "personal_loan",
  Mortgage = "mortgage",
}

export const buildEmptyCreateForeignExchangeRequest = (
  offering: ExchangeLoanOffering
): CreateForeignExchangeRequest => ({
  type: RequestType.Exchange,
  sellingCurrencyId: offering.buyingCurrency.id,
  buyingCurrencyId: offering.sellingAmount.toFixed(0),
  sellingAmount: 0,
});

export const buildEmptyCreateMortgageRequest = (): CreateMortgageRequest => ({
  type: RequestType.Mortgage,
  ...defaultRequestMortgageFormValues.values,
  mortgageLevel: "1",
  loanAmount: 0,
  marketValue: 0,
  monthlyIncome: 0,
  currentLoanAmount: 0,
  period: 0,
});

export const buildEmptyCreatePersonalLoanRequest = (): CreatePersonalLoanRequest => ({
  type: RequestType.PersonalLoan,
  ...defaultRequestPersonalLoanFormValues.values,
  amount: 0,
  period: 0,
  monthlyIncome: 0,
  currentLoanAmount: 0,
});

type Props = RouteComponentProps &
  RequestContextProps &
  OfferingContextProps &
  ChatContextProps &
  LoadingContextProps &
  ErrorAlertContextProps &
  UserContextProps &
  CreateRequestContextProps &
  PresentationContextProps;

interface State {
  modeSelected: Mode;
  targetedOffering: Offering | null;
}

class BorrowerOfferingListScreen extends PureComponent<Props, State> {
  private isCallingAPI = false;
  constructor(props: Props) {
    super(props);

    this.state = {
      modeSelected: Mode.PersonalLoan,
      targetedOffering: null,
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.userContext.user &&
      this.props.userContext.user !== prevProps.userContext.user
    ) {
      this.props.offeringContext.refreshOfferingList();
    }
  }

  handleModeChange = (btn: Mode) => {
    this.setState({
      modeSelected: btn,
    });
  };

  getTypeId = () => {
    switch (this.state.modeSelected) {
      case Mode.PersonalLoan:
        return "offering.latest_offering.loan";
      case Mode.Mortgage:
        return "offering.latest_offering.mortgage";
      default:
        throw new Error(`Unexpected mode = ${this.state.modeSelected}`);
    }
  };

  fetchOfferingsPage = (listType: ListType, cursor?: string) => {
    if (!this.props.userContext.user) {
      return this.props.offeringContext.listAllFeaturedOfferingsForGuest(
        listType,
        cursor
      );
    }
    return this.props.offeringContext.listAllFeaturedOfferingsForBorrower(
      listType,
      cursor
    );
  };

  fetchPersonalLoansPage = (cursor?: string) => {
    return this.fetchOfferingsPage(ListType.PersonalLoan, cursor);
  };

  fetchMortgagesPage = (cursor?: string) => {
    return this.fetchOfferingsPage(ListType.Mortgage, cursor);
  };

  enterConversation = async (chat: Chat) => {
    const chatWithConversation = await this.props.chatContext.enterConversation(
      chat
    );
    this.props.history.push("/offering/chatroom", {
      conversationId: chatWithConversation.conversationId,
    });
    setTimeout(() => {
      if (this) {
        this.props.offeringContext.refreshOfferingList();
      }
    }, 500);
  };

  submitRequest = async (request: CreateRequest, offering: Offering) => {
    let createRequest: (request: any) => Promise<Request>;
    switch (request.type) {
      case RequestType.PersonalLoan:
        createRequest = this.props.requestContext.createPersonalLoanRequest;
        break;
      case RequestType.Mortgage:
        createRequest = this.props.requestContext.createMortgageRequest;
        break;
      case RequestType.Exchange:
        createRequest = this.props.requestContext.createForeignExchangeRequest;
        break;
      default:
        throw new Error("Unexpected request type");
    }
    await this.doSubmit(createRequest(request), offering);
  };

  onCreateRequestFlowDismiss = async () => {
    const { user } = this.props.userContext;
    const { targetedOffering } = this.state;
    const borrower = ensureBorrower(user);
    if (borrower === undefined) {
      throw new Error("Unexpected borrower is undefined");
    }
    const {
      pendingCreateRequest,
      getNextCreateRequestRoute,
    } = this.props.createRequestContext;

    if (targetedOffering === null || pendingCreateRequest === null) {
      return;
    }
    if (
      targetedOffering.directApplyLink === null &&
      getNextCreateRequestRoute(
        borrower,
        pendingCreateRequest.type,
        pendingCreateRequest.skipProfileFilling
      ) !== undefined
    ) {
      return;
    }

    await this.submitRequest(pendingCreateRequest, targetedOffering);
  };

  private doSubmit = async (
    createRequestLambdaCall: Promise<Request>,
    offering: Offering
  ): Promise<void> => {
    if (this.isCallingAPI) {
      return;
    }
    this.props.loadingContext.show();

    this.isCallingAPI = true;
    try {
      const request = await createRequestLambdaCall;
      const chat = await this.props.chatContext.borrowerCreateChatWithOfferingAndRequest(
        offering.id,
        request.id
      );
      await this.enterConversation(chat);
    } catch (error) {
      this.props.errorAlertContext.show(error);
    } finally {
      this.props.loadingContext.dismiss();
      this.props.createRequestContext.setPendingCreateRequest(null);
      this.isCallingAPI = false;
    }
  };

  getRequestData = (offering: Offering): QuickRequestData | undefined => {
    switch (offering.type) {
      case OfferingType.personalLoan:
        return {
          type: RequestType.PersonalLoan,
        };
      case OfferingType.mortgage:
        return {
          type: RequestType.Mortgage,
        };
      case OfferingType.exchangeLoan:
        return {
          type: RequestType.Exchange,
          buyingCurrencyId: offering.buyingCurrency.id,
          sellingAmount: offering.sellingAmount.toFixed(0),
        };
      default:
        return undefined;
    }
  };

  getLatestRequestFormValues = (
    offering: Offering
  ): Promise<RequestFormValues | undefined> => {
    switch (offering.type) {
      case OfferingType.personalLoan:
        return getRequestPersonalLoanForm();
      case OfferingType.mortgage:
        return getRequestMortgageForm();
      case OfferingType.exchangeLoan:
      default:
        return Promise.resolve(undefined);
    }
  };

  handleDirectApplyOfferingAppliance = async (offering: Offering) => {
    let request: CreateRequest | undefined;
    switch (offering.type) {
      case OfferingType.personalLoan:
        request = buildEmptyCreatePersonalLoanRequest();
        break;
      case OfferingType.mortgage:
        request = buildEmptyCreateMortgageRequest();
        break;
      case OfferingType.exchangeLoan:
        request = buildEmptyCreateForeignExchangeRequest(offering);
        break;
      default:
        throw new Error(
          "Unexpected request is undefined on creating request with direct apply offering"
        );
    }
    await this.submitRequest(request, offering);
  };

  onApply = async (offering: Offering) => {
    if (this.isCallingAPI) {
      return;
    }
    if (!this.props.userContext.user) {
      this.props.presentationContext.present("/login");
      return;
    }
    if (offering.chatId != null) {
      this.props.loadingContext.show();
      try {
        const chat = await this.props.chatContext.getChatDetail(
          offering.chatId
        );
        await this.enterConversation(chat);
      } catch (error) {
        this.props.errorAlertContext.show(error);
      } finally {
        this.props.loadingContext.dismiss();
      }
      return;
    }
    if (offering.directApplyLink) {
      await this.handleDirectApplyOfferingAppliance(offering);
      return;
    }
    this.setState({
      targetedOffering: offering,
    });
    const requestData = this.getRequestData(offering);
    const latestRequestFormValues = await this.getLatestRequestFormValues(
      offering
    );
    const locationState: GetOfferingLocationState = {
      requestData,
      latestRequestFormValues,
      directApplyLink: offering.directApplyLink || undefined,
    };
    this.props.presentationContext.present(
      "/profile/create-request",
      locationState,
      this.onCreateRequestFlowDismiss
    );
  };

  render() {
    const { offeringListVersion } = this.props.offeringContext;
    return (
      <BorrowerOfferingListView
        offeringListVersion={offeringListVersion}
        typeId={this.getTypeId()}
        modeSelected={this.state.modeSelected}
        handleModeChange={this.handleModeChange}
        fetchPersonalLoansPage={this.fetchPersonalLoansPage}
        fetchMortgagesPage={this.fetchMortgagesPage}
        onApply={this.onApply}
      />
    );
  }
}

export default withPresentation(
  withErrorAlert(
    withLoading(
      withChat(
        withRequest(
          withCreateRequest(withOffering(withUser(BorrowerOfferingListScreen)))
        )
      )
    )
  )
);
