import React from "react";
import classNames from "classnames";
import numeral from "numeral";
import { FormattedMessage } from "@oursky/react-messageformat";

import styles from "./styles.module.scss";

import { renderMortgageLevel } from "../PublicOfferListAndOfferRequestsCardView/PublicOfferListCardView";

import {
  Chat,
  ChatType,
  PersonalLoanChat,
  MortgageChat,
  ExchangeLoanChat,
} from "../../models";
import { Mode } from "../../screens/AgentChatScreen";
import { getChatroomStatusMessageId } from "../../utils/chat";
import { formatToCurrencyAmount } from "../../utils/formatToCurrencyAmount";

interface AgentChatListExchangeCardViewProps {
  chat: ExchangeLoanChat;
  mode: Mode;
  openChatroom: (chat: Chat) => void;
}

const AgentChatListExchangeCardView: React.FC<
  AgentChatListExchangeCardViewProps
> = props => {
  const { chat, mode, openChatroom } = props;

  const onCardClick = React.useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      openChatroom(chat);
    },
    [chat, openChatroom]
  );

  const images = {
    replyIcon: require("../../images/icon-agent-reply.svg"),
  };

  const progressFormattedMessageId = getChatroomStatusMessageId(
    !!chat.completedAt,
    chat.referralAgreementStatus
  );

  return (
    <button className={styles.cardContainer} onClick={onCardClick}>
      {!!chat.unreadCount && <div className={styles.unreadIndicator} />}
      <div className={styles.containerFlexRow}>
        <div className={styles.transactionIdContainer}>
          <FormattedMessage id="agent.chat.transactionId.title" />
          <div className={styles.transactionId}>{`#${chat.refNum}`}</div>
        </div>
        <div className={styles.borrowerIdContainer}>
          <FormattedMessage id="agent.chat.borrowerId.title" />
          <div className={styles.borrowerId}>
            {`#${chat.request.borrower.refNum}`}
          </div>
        </div>
        <img src={images.replyIcon} alt="reply" className={styles.replyBtn} />
      </div>

      <div className={styles.type}>
        <FormattedMessage id="agent.chat.exchange" />
      </div>

      <div className={styles.containerFlexRow}>
        <div className={styles.amountContainer}>
          <FormattedMessage
            id="agent.chat.amount.label"
            values={{ currency: chat.offering.sellingCurrency.isoCode }}
          />
          <div className={styles.figures}>
            {numeral(chat.offering.sellingAmount).format()}
          </div>
        </div>
        <div className={styles.currencyContainer}>
          <FormattedMessage id="agent.chat.currency.label" />
          <div className={styles.figures}>
            <FormattedMessage
              id="agent.chat.currency"
              values={{
                fromCurrency: chat.request.sellingCurrency.isoCode,
                toCurrency: chat.request.buyingCurrency.isoCode,
              }}
            />
          </div>
        </div>
        <div
          className={classNames(
            styles.progress,
            { [styles.inProgress]: mode === Mode.inProgress },
            { [styles.completed]: mode === Mode.completed }
          )}
        >
          <FormattedMessage id={progressFormattedMessageId} />
        </div>
      </div>
    </button>
  );
};

interface AgentChatListMortgageCardViewProps {
  chat: MortgageChat;
  mode: Mode;
  openChatroom: (chat: Chat) => void;
}

const AgentChatListMortgageCardView: React.FC<
  AgentChatListMortgageCardViewProps
> = props => {
  const { chat, mode, openChatroom } = props;

  const onCardClick = React.useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      openChatroom(chat);
    },
    [chat, openChatroom]
  );

  const images = {
    replyIcon: require("../../images/icon-agent-reply.svg"),
  };

  const progressFormattedMessageId = getChatroomStatusMessageId(
    !!chat.completedAt,
    chat.referralAgreementStatus
  );

  return (
    <button className={styles.cardContainer} onClick={onCardClick}>
      {!!chat.unreadCount && <div className={styles.unreadIndicator} />}
      <div className={styles.containerFlexRow}>
        <div className={styles.transactionIdContainer}>
          <FormattedMessage id="agent.chat.transactionId.title" />
          <div className={styles.transactionId}>{`#${chat.refNum}`}</div>
        </div>
        <div className={styles.borrowerIdContainer}>
          <FormattedMessage id="agent.chat.borrowerId.title" />
          <div className={styles.borrowerId}>
            {`#${chat.request.borrower.refNum}`}
          </div>
        </div>
        <img src={images.replyIcon} alt="reply" className={styles.replyBtn} />
      </div>

      <div className={styles.type}>
        <FormattedMessage id="agent.chat.mortgage" />
      </div>

      <div className={styles.containerFlexRow}>
        <div className={styles.amountContainer}>
          <FormattedMessage id="agent.chat.loan_amount.label" />
          <div className={styles.figures}>
            {formatToCurrencyAmount(chat.request.loanAmount, true)}
          </div>
        </div>
        <div className={styles.repaymentPeriodContainer}>
          <FormattedMessage id="agent.chat.mortgage_level.label" />
          <div className={styles.figures}>
            {renderMortgageLevel(chat.request.mortgageLevel)}
          </div>
        </div>
        <div
          className={classNames(
            styles.progress,
            { [styles.inProgress]: mode === Mode.inProgress },
            { [styles.completed]: mode === Mode.completed }
          )}
        >
          <FormattedMessage id={progressFormattedMessageId} />
        </div>
      </div>

      <div className={styles.marketValue}>
        <FormattedMessage
          id="agent.chat.mortgage_market_value.label"
          values={{ currency: "HKD" }}
        />
        <div className={styles.figures}>
          {formatToCurrencyAmount(chat.request.buildingValue, true)}
        </div>
      </div>
    </button>
  );
};

interface AgentChatListLoanCardViewProps {
  chat: PersonalLoanChat;
  mode: Mode;
  openChatroom: (chat: Chat) => void;
}

const AgentChatListLoanCardView: React.FC<
  AgentChatListLoanCardViewProps
> = props => {
  const { chat, mode, openChatroom } = props;

  const onCardClick = React.useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      openChatroom(chat);
    },
    [chat, openChatroom]
  );

  const images = {
    replyIcon: require("../../images/icon-agent-reply.svg"),
  };

  const progressFormattedMessageId = getChatroomStatusMessageId(
    !!chat.completedAt,
    chat.referralAgreementStatus
  );

  return (
    <button className={styles.cardContainer} onClick={onCardClick}>
      {!!chat.unreadCount && <div className={styles.unreadIndicator} />}
      <div className={styles.containerFlexRow}>
        <div className={styles.transactionIdContainer}>
          <FormattedMessage id="agent.chat.transactionId.title" />
          <div className={styles.transactionId}>{`#${chat.refNum}`}</div>
        </div>
        <div className={styles.borrowerIdContainer}>
          <FormattedMessage id="agent.chat.borrowerId.title" />
          <div className={styles.borrowerId}>
            {`#${chat.request.borrower.refNum}`}
          </div>
        </div>
        <img src={images.replyIcon} alt="reply" className={styles.replyBtn} />
      </div>

      <div className={styles.type}>
        <FormattedMessage id="agent.chat.loan" />
      </div>

      <div className={styles.containerFlexRow}>
        <div className={styles.amountContainer}>
          <FormattedMessage
            id="agent.chat.amount.label"
            values={{ currency: "HKD" }}
          />
          <div className={styles.figures}>
            {formatToCurrencyAmount(chat.request.amount, true)}
          </div>
        </div>
        <div className={styles.repaymentPeriodContainer}>
          <FormattedMessage id="agent.chat.repayment_period.label" />
          <div className={styles.figures}>
            <FormattedMessage
              id="agent.chat.repayment_period"
              values={{
                repaymentPeriod: chat.request.period,
                unit: chat.request.period,
              }}
            />
          </div>
        </div>
        <div
          className={classNames(
            styles.progress,
            { [styles.inProgress]: mode === Mode.inProgress },
            { [styles.completed]: mode === Mode.completed }
          )}
        >
          <FormattedMessage id={progressFormattedMessageId} />
        </div>
      </div>
    </button>
  );
};

export interface AgentChatListCardViewProps {
  chat: Chat;
  mode: Mode;
  openChatroom: (chat: Chat) => void;
}

export const AgentChatListCardView: React.FC<
  AgentChatListCardViewProps
> = props => {
  const { chat, mode, openChatroom } = props;
  switch (chat.type) {
    case ChatType.exchangeLoan:
      return (
        <AgentChatListExchangeCardView
          chat={chat}
          mode={mode}
          openChatroom={openChatroom}
        />
      );
    case ChatType.mortgage:
      return (
        <AgentChatListMortgageCardView
          chat={chat}
          mode={mode}
          openChatroom={openChatroom}
        />
      );
    case ChatType.personalLoan:
      return (
        <AgentChatListLoanCardView
          chat={chat}
          mode={mode}
          openChatroom={openChatroom}
        />
      );
    default:
      throw new Error("Unexpected request type in chat");
  }
};
