import React from "react";

import {
  FormattedDropdown,
  FormattedDropdownProps,
  Option as FormattedOption,
} from "../FormattedDropdown";

import { Omit } from "../../utils/typeutils";
import { EmploymentType } from "../../models";

export type EmploymentTypeDropdownProps = Omit<
  FormattedDropdownProps,
  "options"
>;

export const EmploymentTypeOptions: FormattedOption[] = [
  {
    key: EmploymentType.fullTime,
    value: EmploymentType.fullTime,
    labelId: "option.employmentType.fullTime",
  },
  {
    key: EmploymentType.partTime,
    value: EmploymentType.partTime,
    labelId: "option.employmentType.partTime",
  },
];

export const EmploymentTypeDropdown: React.FC<
  EmploymentTypeDropdownProps
> = props => {
  return (
    <FormattedDropdown options={EmploymentTypeOptions} {...props as any} />
  );
};
