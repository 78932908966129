import {
  FormattedMessage,
  Context as LocaleContext,
} from "@oursky/react-messageformat";
import React, { useCallback } from "react";
import { DateTime } from "luxon";

import { OtherIncome, PaymentMethod } from "../../models";
import {
  currencyFormatToNumber,
  formatToCurrencyAmount,
} from "../../utils/formatToCurrencyAmount";
import {
  ValidationError,
  ValidationResult,
  Validator,
  validate,
  filterValidatorByDisplayMap,
  ignoreIndexFieldMapper,
} from "../../utils/validate";

import ErrorField from "../ErrorField";
import { ClearableInput } from "../input";

import styles from "./OtherIncomeForm.module.scss";
import YesNoPicker from "../YesNoPicker";
import { DatePicker } from "../DatePicker";
import classNames from "classnames";
import { IncomeProofTypeDropdown } from "../SpecifyDropdown";
import { PaymentMethodDropdown } from "../SpecifyDropdown/PaymentMethodDropdown";
import { OtherIncomeField } from "../ConditionalDisplayField/ConditionalDisplayField";
import {
  FieldType,
  OtherIncomeField as BorrowerOtherIncomeField,
} from "../../context/CreateRequestContext";

export type FormInputFields =
  | WorkingCompanyNamesFields
  | PositionFields
  | SalaryFields
  | OnBoardDateFields
  | IncomeProofTypeFields
  | PaymentMethodFields;

export const defaultOtherIncomeValues: OtherIncome = {
  id: null,
  workingCompanyNames: null,
  position: null,
  salary: null,
  onBoardDate: null,
  incomeProofType: null,
  paymentMethod: null,
};

interface OtherIncomeFormProps {
  index: number;
  onChange: (index: number, value: Partial<OtherIncome>) => void;
  otherIncome: OtherIncome;
  error: ValidationError<FormInputFields>;
  focusField?: FormInputFields;
  onRemove?: (index: number) => void;
}

export const OtherIncomeForm: React.FC<OtherIncomeFormProps> = props => {
  const { otherIncome, index, onChange, onRemove, error, focusField } = props;
  const {
    workingCompanyNames,
    position,
    salary,
    onBoardDate,
    incomeProofType,
    paymentMethod,
  } = otherIncome;
  const [nextField, setNextField] = React.useState<FormInputFields>();

  const [hasIncomeProof, setHasIncomeProof] = React.useState(
    otherIncome.incomeProofType !== null
  );

  React.useEffect(() => {
    if (nextField) {
      setNextField(undefined);
    }
  }, [nextField]);

  const focusOnBoardDateField = useCallback(() => {
    setNextField(onBoardDateFields[index]);
  }, [index]);

  const handleWorkingCompanyNamesChange = useCallback(
    (workingCompanyNames: string) => onChange(index, { workingCompanyNames }),
    [index, onChange]
  );

  const handlePositionChange = useCallback(
    (position: string) => onChange(index, { position }),
    [index, onChange]
  );

  const handleSalaryChange = useCallback(
    (v: string) =>
      onChange(index, { salary: currencyFormatToNumber(v) || null }),
    [index, onChange]
  );

  const handleOnBoardDateChange = useCallback(
    (dateTime?: DateTime) => {
      onChange(index, {
        onBoardDate: dateTime ? dateTime.toFormat("yyyy-MM-dd") : null,
      });
    },
    [index, onChange]
  );

  const handleHasIncomeProofChange = useCallback(
    (hasIncomeProof: boolean) => {
      setHasIncomeProof(hasIncomeProof);
      onChange(index, {
        incomeProofType: hasIncomeProof ? incomeProofType || "mpf" : null,
      });
    },
    [index, onChange, incomeProofType]
  );

  const handleIncomeProofTypeChange = useCallback(
    (incomeProofType: string) => onChange(index, { incomeProofType }),
    [index, onChange]
  );

  const handlePaymentMethodChange = useCallback(
    (paymentMethod: string) =>
      onChange(index, { paymentMethod: paymentMethod as PaymentMethod }),
    [index, onChange]
  );

  const onClickRemove = useCallback(() => {
    if (onRemove) {
      onRemove(index);
    }
  }, [index, onRemove]);

  return (
    <LocaleContext.Consumer>
      {({ renderToString }) => (
        <div>
          <div className={styles.formHeader}>
            <div className={styles.title}>
              <FormattedMessage
                id="borrower_other_income_form.otherIncomeNumber"
                values={{ number: index + 1 }}
              />
            </div>
            {onRemove && (
              <div onClick={onClickRemove} className={styles.removeButton}>
                <FormattedMessage id="borrower_other_income_form.remove" />
              </div>
            )}
          </div>

          <OtherIncomeField field="workingCompanyNames">
            <div
              className={styles.fieldContainer}
              data-anchor={workingCompanyNamesFields[index]}
            >
              <div className={styles.fieldTitle}>
                <FormattedMessage id="borrower_other_income_form.workingCompanyNames" />
              </div>
              <ClearableInput
                isError={error[workingCompanyNamesFields[index]] !== undefined}
                placeholder={renderToString(
                  "borrower_other_income_form.pleaseFillIn"
                )}
                value={workingCompanyNames || ""}
                onChange={handleWorkingCompanyNamesChange}
                scrollAnchor={workingCompanyNamesFields[index]}
                shouldFocus={focusField === workingCompanyNamesFields[index]}
                shouldScrollTo={focusField === workingCompanyNamesFields[index]}
              />
              <ErrorField
                isShown={error[workingCompanyNamesFields[index]] !== undefined}
                hiddenType="gone"
                messageId={error[workingCompanyNamesFields[index]]}
              />
            </div>
          </OtherIncomeField>

          <OtherIncomeField field="position">
            <div
              className={styles.fieldContainer}
              data-anchor={positionFields[index]}
            >
              <div className={styles.fieldTitle}>
                <FormattedMessage id="borrower_other_income_form.position" />
              </div>
              <ClearableInput
                isError={error[positionFields[index]] !== undefined}
                placeholder={renderToString(
                  "borrower_other_income_form.pleaseFillIn"
                )}
                value={position || ""}
                onChange={handlePositionChange}
                scrollAnchor={positionFields[index]}
                shouldFocus={focusField === positionFields[index]}
                shouldScrollTo={focusField === positionFields[index]}
              />
              <ErrorField
                isShown={error[positionFields[index]] !== undefined}
                hiddenType="gone"
                messageId={error[positionFields[index]]}
              />
            </div>
          </OtherIncomeField>

          <OtherIncomeField field="salary">
            <div
              className={styles.fieldContainer}
              data-anchor={salaryFields[index]}
            >
              <div className={styles.fieldTitle}>
                <FormattedMessage id="borrower_other_income_form.salary" />
              </div>
              <ClearableInput
                type="tel"
                isError={error[salaryFields[index]] !== undefined}
                placeholder={renderToString(
                  "borrower_other_income_form.pleaseFillIn"
                )}
                value={(salary && formatToCurrencyAmount(salary)) || ""}
                onChange={handleSalaryChange}
                maxLength={15}
                scrollAnchor={salaryFields[index]}
                shouldFocus={focusField === salaryFields[index]}
                shouldScrollTo={focusField === salaryFields[index]}
                autoSubmit={focusOnBoardDateField}
              />
              <ErrorField
                isShown={error[salaryFields[index]] !== undefined}
                hiddenType="gone"
                messageId={error[salaryFields[index]]}
              />
            </div>
          </OtherIncomeField>

          <OtherIncomeField field="onBoardDate">
            <div
              className={styles.fieldContainer}
              data-anchor={onBoardDateFields[index]}
            >
              <div className={styles.fieldTitle}>
                <FormattedMessage id="borrower_other_income_form.onBoardDate" />
              </div>
              <DatePicker
                inputClass={classNames(styles.input, {
                  [styles.errored]: !!error[onBoardDateFields[index]],
                })}
                placeholderId={"borrower_other_income_form.pleaseFillIn"}
                value={
                  onBoardDate != null &&
                  DateTime.fromFormat(onBoardDate, "yyyy-MM-dd").isValid
                    ? DateTime.fromFormat(onBoardDate, "yyyy-MM-dd")
                    : undefined
                }
                onChange={handleOnBoardDateChange}
                shouldFocus={nextField === onBoardDateFields[index]}
                shouldScrollTo={focusField === onBoardDateFields[index]}
                scrollAnchor={onBoardDateFields[index]}
              />
              <ErrorField
                isShown={error[onBoardDateFields[index]] !== undefined}
                hiddenType="gone"
                messageId={error[onBoardDateFields[index]]}
              />
            </div>
          </OtherIncomeField>

          <OtherIncomeField field="incomeProofType">
            <div className={styles.fieldContainer}>
              <YesNoPicker
                labelId="borrower_other_income_form.hasIncomeProof"
                onClickOption={handleHasIncomeProofChange}
                value={hasIncomeProof == null ? undefined : hasIncomeProof}
              />
            </div>
          </OtherIncomeField>

          <OtherIncomeField field="incomeProofType">
            <div
              className={styles.fieldContainer}
              data-anchor={incomeProofTypeFields[index]}
            >
              <div
                className={classNames(styles.fieldTitle, {
                  [styles.disabled]: !hasIncomeProof,
                })}
              >
                <FormattedMessage id="borrower_other_income_form.incomeProofType" />
              </div>
              <IncomeProofTypeDropdown
                containerClass={styles.dropdownContainer}
                selectClass={styles.dropdownSelect}
                errorClass={styles.dropdownError}
                emptyClass={styles.dropdownEmpty}
                isError={error[incomeProofTypeFields[index]] !== undefined}
                value={incomeProofType || ""}
                onValueChange={handleIncomeProofTypeChange}
                shouldFocus={focusField === incomeProofTypeFields[index]}
                shouldScrollTo={focusField === incomeProofTypeFields[index]}
                scrollAnchor={incomeProofTypeFields[index]}
                placeholderId="option.incomeProofType.placeholder"
                disabled={hasIncomeProof === false}
              />
              <ErrorField
                isShown={error[incomeProofTypeFields[index]] !== undefined}
                hiddenType="gone"
                messageId={error[incomeProofTypeFields[index]]}
              />
            </div>
          </OtherIncomeField>

          <OtherIncomeField field="paymentMethod">
            <div
              className={styles.fieldContainer}
              data-anchor={paymentMethodFields[index]}
            >
              <div className={styles.fieldTitle}>
                <FormattedMessage id="borrower_other_income_form.paymentMethod" />
              </div>
              <PaymentMethodDropdown
                containerClass={styles.dropdownContainer}
                selectClass={styles.dropdownSelect}
                errorClass={styles.dropdownError}
                emptyClass={styles.dropdownEmpty}
                isError={error[paymentMethodFields[index]] !== undefined}
                placeholderId="paymentMethod.placeholder"
                value={paymentMethod || ""}
                onValueChange={handlePaymentMethodChange}
                shouldFocus={focusField === paymentMethodFields[index]}
                shouldScrollTo={focusField === paymentMethodFields[index]}
                scrollAnchor={paymentMethodFields[index]}
              />
              <ErrorField
                isShown={error[paymentMethodFields[index]] !== undefined}
                hiddenType="gone"
                messageId={error[paymentMethodFields[index]]}
              />
            </div>
          </OtherIncomeField>
        </div>
      )}
    </LocaleContext.Consumer>
  );
};

type ValidationFields = BorrowerOtherIncomeField;
export function validateOtherIncomeForm(
  otherIncomes: OtherIncome[],
  formDisplayMap?: { [key in ValidationFields]?: FieldType },
  skipProfileFilling: boolean = false
): ValidationResult<FormInputFields> {
  return otherIncomes.reduce<ValidationResult<FormInputFields>>(
    (
      prevResult: ValidationResult<FormInputFields>,
      cur: OtherIncome,
      index: number
    ) => {
      const {
        workingCompanyNames,
        position,
        salary,
        onBoardDate,
        paymentMethod,
      } = cur;
      let validators: Validator<
        FormInputFields
      >[] = filterValidatorByDisplayMap(
        [
          {
            field: workingCompanyNamesFields[index],
            invalidCondition:
              workingCompanyNames === null ||
              workingCompanyNames.trim().length === 0,
            errorMessageId:
              "borrower_other_income_form.workingCompanyNames.error.required",
            isExistenceCheck: true,
          },
          {
            field: positionFields[index],
            invalidCondition: position === null || position.trim().length === 0,
            errorMessageId:
              "borrower_other_income_form.position.error.required",
            isExistenceCheck: true,
          },
          {
            field: salaryFields[index],
            invalidCondition: salary === null,
            errorMessageId: "borrower_other_income_form.salary.error.required",
            isExistenceCheck: true,
          },
          {
            field: salaryFields[index],
            invalidCondition: salary !== null && salary <= 0,
            errorMessageId:
              "borrower_other_income_form.salary.error.negative_or_zero",
          },
          {
            field: onBoardDateFields[index],
            invalidCondition: onBoardDate === null,
            errorMessageId:
              "borrower_other_income_form.onBoardDate.error.required",
            isExistenceCheck: true,
          },
          {
            field: onBoardDateFields[index],
            invalidCondition:
              onBoardDate !== null &&
              !DateTime.fromFormat(onBoardDate, "yyyy-MM-dd").isValid,
            errorMessageId:
              "borrower_other_income_form.onBoardDate.error.incorrect",
          },
          {
            field: paymentMethodFields[index],
            invalidCondition: paymentMethod === null,
            errorMessageId:
              "borrower_other_income_form.paymentMethod.error.required",
            isExistenceCheck: true,
          },
        ],
        formDisplayMap,
        ignoreIndexFieldMapper
      );
      if (skipProfileFilling) {
        validators = validators.filter(it => !it.isExistenceCheck);
      }
      const validateResult = validate(validators);
      if (validateResult.isValid) {
        return prevResult;
      }
      return {
        focusField: prevResult.focusField || validateResult.focusField,
        error: {
          ...prevResult.error,
          ...validateResult.error,
        },
        isValid: false,
      };
    },
    {
      focusField: undefined,
      error: {},
      isValid: true,
    }
  );
}

const workingCompanyNamesFields = [
  "workingCompanyNames0",
  "workingCompanyNames1",
  "workingCompanyNames2",
  "workingCompanyNames3",
  "workingCompanyNames4",
  "workingCompanyNames5",
  "workingCompanyNames6",
  "workingCompanyNames7",
  "workingCompanyNames8",
  "workingCompanyNames9",
  "workingCompanyNames10",
  "workingCompanyNames11",
  "workingCompanyNames12",
  "workingCompanyNames13",
  "workingCompanyNames14",
  "workingCompanyNames15",
  "workingCompanyNames16",
  "workingCompanyNames17",
  "workingCompanyNames18",
  "workingCompanyNames19",
] as const;
type WorkingCompanyNamesFields = typeof workingCompanyNamesFields[number];

const positionFields = [
  "position0",
  "position1",
  "position2",
  "position3",
  "position4",
  "position5",
  "position6",
  "position7",
  "position8",
  "position9",
  "position10",
  "position11",
  "position12",
  "position13",
  "position14",
  "position15",
  "position16",
  "position17",
  "position18",
  "position19",
] as const;
type PositionFields = typeof positionFields[number];

const salaryFields = [
  "salary0",
  "salary1",
  "salary2",
  "salary3",
  "salary4",
  "salary5",
  "salary6",
  "salary7",
  "salary8",
  "salary9",
  "salary10",
  "salary11",
  "salary12",
  "salary13",
  "salary14",
  "salary15",
  "salary16",
  "salary17",
  "salary18",
  "salary19",
] as const;
type SalaryFields = typeof salaryFields[number];

const onBoardDateFields = [
  "onBoardDate0",
  "onBoardDate1",
  "onBoardDate2",
  "onBoardDate3",
  "onBoardDate4",
  "onBoardDate5",
  "onBoardDate6",
  "onBoardDate7",
  "onBoardDate8",
  "onBoardDate9",
  "onBoardDate10",
  "onBoardDate11",
  "onBoardDate12",
  "onBoardDate13",
  "onBoardDate14",
  "onBoardDate15",
  "onBoardDate16",
  "onBoardDate17",
  "onBoardDate18",
  "onBoardDate19",
] as const;
type OnBoardDateFields = typeof onBoardDateFields[number];

const incomeProofTypeFields = [
  "incomeProofType0",
  "incomeProofType1",
  "incomeProofType2",
  "incomeProofType3",
  "incomeProofType4",
  "incomeProofType5",
  "incomeProofType6",
  "incomeProofType7",
  "incomeProofType8",
  "incomeProofType9",
  "incomeProofType10",
  "incomeProofType11",
  "incomeProofType12",
  "incomeProofType13",
  "incomeProofType14",
  "incomeProofType15",
  "incomeProofType16",
  "incomeProofType17",
  "incomeProofType18",
  "incomeProofType19",
] as const;
type IncomeProofTypeFields = typeof incomeProofTypeFields[number];

const paymentMethodFields = [
  "paymentMethod0",
  "paymentMethod1",
  "paymentMethod2",
  "paymentMethod3",
  "paymentMethod4",
  "paymentMethod5",
  "paymentMethod6",
  "paymentMethod7",
  "paymentMethod8",
  "paymentMethod9",
  "paymentMethod10",
  "paymentMethod11",
  "paymentMethod12",
  "paymentMethod13",
  "paymentMethod14",
  "paymentMethod15",
  "paymentMethod16",
  "paymentMethod17",
  "paymentMethod18",
  "paymentMethod19",
] as const;
type PaymentMethodFields = typeof paymentMethodFields[number];
