import React, { PureComponent } from "react";
import { FormattedMessage } from "@oursky/react-messageformat";

import { PopOver } from "../PopOver";
import { AgentQuickOfferItem } from "../AgentQuickOfferItem";

import { withUser, UserContextProps } from "../../context/UserContext";
import {
  Agent,
  OfferingForm,
  OfferingType,
  Request,
  RequestType,
  UserType,
} from "../../models";

import styles from "./styles.module.scss";

interface OwnProps {
  isShowPopover: boolean;
  request?: Request;

  onClose: () => void;
  onCreateQuickOffer: (request: Request, template: OfferingForm) => void;
  onCreateOfferManually: (request: Request) => void;
}

type Props = OwnProps & UserContextProps;

class AgentQuickOfferPopupView extends PureComponent<Props> {
  getQuickOffer(agent: Agent, request: Request): OfferingForm[] {
    const offeringType = this.getOfferingType(request);
    return agent.offeringTemplate.filter(offer => offer.type === offeringType);
  }

  getOfferingType(request: Request): OfferingType {
    switch (request.type) {
      case RequestType.exchangeLoan:
        return OfferingType.exchangeLoan;
      case RequestType.mortgage:
        return OfferingType.mortgage;
      case RequestType.personalLoan:
        return OfferingType.personalLoan;
      default:
        throw new Error(`Unexpected request type: ${request}`);
    }
  }

  onClickManual = () => {
    const { request, onCreateOfferManually } = this.props;

    if (!request) {
      return;
    }
    onCreateOfferManually(request);
  };

  renderOfferingTemplateList() {
    const {
      userContext: { user },
      request,
      onCreateQuickOffer,
    } = this.props;

    if (!request) {
      return null;
    }

    if (!user || user.type !== UserType.agent) {
      throw new Error(`Unexpected user type: ${user}`);
    }

    const offeringTemplates = this.getQuickOffer(user as Agent, request);

    return offeringTemplates.map((offering, index) => {
      return (
        <QuickOfferCard
          key={index}
          request={request}
          offering={offering}
          onCreateQuickOffer={onCreateQuickOffer}
        />
      );
    });
  }

  renderPopoverContent = () => {
    return (
      <div className={styles.quickOfferlist}>
        <div className="title">
          <FormattedMessage id="quick_offer.from_quick_offer.title" />
        </div>
        <div className={styles.templateList}>
          {this.renderOfferingTemplateList()}
        </div>
        <div className={styles.buttonContainer}>
          <button className={styles.manual} onClick={this.onClickManual}>
            <FormattedMessage id="quick_offer.from_quick_offer.manual" />
          </button>
          <button className={styles.cancel} onClick={this.props.onClose}>
            <FormattedMessage id="quick_offer.from_quick_offer.cancel" />
          </button>
        </div>
      </div>
    );
  };

  render() {
    const { isShowPopover, onClose, request } = this.props;

    return (
      <PopOver isOpen={isShowPopover} onBackdropClick={onClose}>
        <div className={styles.popover}>
          <div className={styles.popoverInner}>
            {request && this.renderPopoverContent()}
          </div>
        </div>
      </PopOver>
    );
  }
}

interface QuickOfferCardProps {
  offering: OfferingForm;
  request: Request;

  onCreateQuickOffer: (request: Request, template: OfferingForm) => void;
}

const QuickOfferCard: React.FC<QuickOfferCardProps> = props => {
  const { onCreateQuickOffer, offering, request } = props;
  const onClick = React.useCallback(() => {
    onCreateQuickOffer(request, offering);
  }, [onCreateQuickOffer, offering, request]);

  return (
    <div className={styles.card}>
      <AgentQuickOfferItem offering={props.offering} />
      <button className={styles.offerButton} onClick={onClick}>
        <FormattedMessage id="quick_offer.from_quick_offer.create_offer" />
      </button>
    </div>
  );
};

export default withUser(AgentQuickOfferPopupView);
