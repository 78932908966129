import { BaseApiClient, ApiClientConstructor } from "./client";

import {
  CreateForeignExchangeRequest,
  CreateMortgageRequest,
  CreatePersonalLoanRequest,
} from "../types/request";
import { listBorrowerRequestSchema } from "../types/response";
import { ListType } from "../types/misc";
import {
  ExchangeLoanRequest,
  MortgageRequest,
  PersonalLoanRequest,
  Request,
} from "../models";
import { Page } from "../utils/pagination";
import {
  exchangeLoanRequestSchema,
  mortgageRequestSchema,
  personalLoanRequestSchema,
} from "../schemas/request";

export interface RequestApiClient {
  createForeignExchangeRequest: (
    formValues: CreateForeignExchangeRequest
  ) => Promise<ExchangeLoanRequest>;
  createMortgageRequest: (
    formValues: CreateMortgageRequest
  ) => Promise<MortgageRequest>;
  createPersonalLoanRequest: (
    formValues: CreatePersonalLoanRequest
  ) => Promise<PersonalLoanRequest>;
  listBorrowerRequestsForAgent: (cursor?: string) => Promise<Page<Request>>;
  listBorrowerRequests: (
    cursor?: string,
    type?: ListType
  ) => Promise<Page<Request>>;
  cancelBorrowerRequest: (requestId: string) => Promise<void>;
}

export function withRequestApi<
  TBase extends ApiClientConstructor<BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    createForeignExchangeRequest(
      formValues: CreateForeignExchangeRequest
    ): Promise<ExchangeLoanRequest> {
      return this.requestLambda(
        "request:borrower-create-foreign-exchange",
        {
          selling_currency_id: formValues.sellingCurrencyId,
          buying_currency_id: formValues.buyingCurrencyId,
          selling_amount: formValues.sellingAmount,
        },
        exchangeLoanRequestSchema
      );
    }

    createMortgageRequest(
      formValues: CreateMortgageRequest
    ): Promise<MortgageRequest> {
      return this.requestLambda(
        "request:borrower-create-mortgage",
        {
          flat: formValues.flat,
          floor: formValues.floor,
          loan_amount: formValues.loanAmount,
          mortgage_level: formValues.mortgageLevel,
          building_value: formValues.marketValue,
          monthly_income: formValues.monthlyIncome || null,
          current_loan_amount: formValues.currentLoanAmount || null,
          purposing_of_mortgage: formValues.purposingOfMortgage,
          name_of_building: formValues.nameOfBuilding,
          street_name_and_number: formValues.streetNameAndNumber,
          district: formValues.district,
          period: formValues.period,
          block: formValues.block,
          premise_type: formValues.premiseType,
          property_status: formValues.propertyStatus,
          num_of_owner: formValues.numOfOwner,
          rent_income: formValues.rentIncome,
          num_of_resident: formValues.numOfResident,
          filed_for_bankruptcy: formValues.filedForBankruptcy,
          applied_for_iva: formValues.appliedForIva,
          num_personal_loan_for_past_two_weeks:
            formValues.numPersonalLoanForPastTwoWeeks,
          num_of_loan_enquiries_in_one_month:
            formValues.numOfLoanEnquiriesInOneMonth,
          has_late_payment: formValues.hasLatePayment,
          is_tax_return_form_available: formValues.isTaxReturnFormAvailable,
          tu_grade: formValues.tuGrade || null,
          allow_tu_check: formValues.allowTUCheck,
          skip_profile_filling: formValues.skipProfileFilling || false,
        },
        mortgageRequestSchema
      );
    }

    createPersonalLoanRequest(
      formValues: CreatePersonalLoanRequest
    ): Promise<PersonalLoanRequest> {
      return this.requestLambda(
        "request:borrower-create-personal-loan",
        {
          amount: formValues.amount,
          period: formValues.period,
          monthly_income: formValues.monthlyIncome || null,
          current_loan_amount: formValues.currentLoanAmount || null,
          purposing_of_financing: formValues.purposingOfFinancing,
          filed_for_bankruptcy: formValues.filedForBankruptcy,
          applied_for_iva: formValues.appliedForIva,
          num_personal_loan_for_past_two_weeks:
            formValues.numPersonalLoanForPastTwoWeeks,
          num_of_loan_enquiries_in_one_month:
            formValues.numOfLoanEnquiriesInOneMonth,
          has_late_payment: formValues.hasLatePayment,
          is_tax_return_form_available: formValues.isTaxReturnFormAvailable,
          tu_grade: formValues.tuGrade || null,
          allow_tu_check: formValues.allowTUCheck,
          skip_profile_filling: formValues.skipProfileFilling || false,
        },
        personalLoanRequestSchema
      );
    }

    listBorrowerRequestsForAgent(cursor?: string): Promise<Page<Request>> {
      const data: any = {};
      if (cursor) {
        data.page_info = { cursor };
      }

      return this.requestLambda(
        "request:agent-list-all",
        data,
        listBorrowerRequestSchema
      );
    }

    listBorrowerRequests(
      cursor?: string,
      type?: ListType
    ): Promise<Page<Request>> {
      const data: any = {};
      if (cursor) {
        data.page_info = { cursor };
      }
      if (type) {
        data["request_type"] = type;
      }

      return this.requestLambda(
        "request:borrower-list",
        data,
        listBorrowerRequestSchema
      );
    }

    cancelBorrowerRequest(requestId: string): Promise<void> {
      const data: any = { request_id: requestId };
      return this.requestLambda("request:borrower-cancel", data);
    }
  };
}
