import React from "react";
import classNames from "classnames";
import { FormattedMessage } from "@oursky/react-messageformat";

import styles from "./styles.module.scss";

interface OwnProps {
  isShown?: boolean;
  messageId?: string;
  hiddenType?: "hidden" | "gone";
}

type Props = OwnProps;

const ErrorField: React.FC<Props> = (props: Props) => {
  const { isShown, messageId, hiddenType } = props;
  const shouldShow = isShown !== undefined ? isShown : messageId !== undefined;

  if (!shouldShow && hiddenType === "gone") {
    return null;
  }

  return (
    <div
      className={classNames(styles.errorMessage, {
        [styles.hidden]: !shouldShow,
      })}
    >
      {messageId ? <FormattedMessage id={messageId} /> : <span>&nbsp;</span>}
    </div>
  );
};

export default ErrorField;
