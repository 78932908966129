import React from "react";
import {
  IonButton,
  IonButtons,
  IonHeader,
  IonImg,
  IonPage,
  IonToolbar,
} from "@ionic/react";
import { FormattedMessage } from "@oursky/react-messageformat";

import styles from "./styles.module.scss";
import Cross from "../../images/icon-cross.svg";

import { Option as DPOption } from "../DropdownPicker";
import { FormattedDropdown, Option } from "../FormattedDropdown";
import { MPContent } from "../MPContent";
import RequestForeignExchangeForm from "../RequestForeignExchangeForm";
import RequestMortgageForm, {
  FormValues as RequestMortgageFormValues,
} from "../RequestMortgageForm";
import RequestPersonalLoanForm, {
  FormValues as RequestPersonalLoanFormValues,
} from "../RequestPersonalLoanForm";
import {
  CreateForeignExchangeRequest,
  CreateMortgageRequest,
  CreatePersonalLoanRequest,
  QuickRequestData,
  RequestType,
} from "../../types/request";

import { User } from "../../models";
import { ensureBorrower } from "../../utils/user";
import {
  RequestFormValues,
  RequestMorgageFormValuesToPresist,
  RequestPersonalLoanFormValuesToPresist,
} from "./types";
import SuggestionDialogue from "../SuggestionDialogue/SuggestionDialogue";

interface OwnProps {
  user?: User;
  selectedRequestType: RequestType;
  currencyOptions: DPOption[];
  quickRequestData?: QuickRequestData;
  latestRequestFormValues?: RequestFormValues;

  onRequestTypeChange: (type: string) => void;
  onForeignRequestSubmit: (values: CreateForeignExchangeRequest) => void;
  onMortgageSubmit: (
    values: CreateMortgageRequest,
    formValuesToPersist: RequestMorgageFormValuesToPresist
  ) => void;
  onPersonalLoanSubmit: (
    values: CreatePersonalLoanRequest,
    formValuesToPersist: RequestPersonalLoanFormValuesToPresist
  ) => void;
  onCloseClick: () => void;
}

type Props = OwnProps;

interface State {
  isPrefillSuggestionViewShown: boolean;
  latestFormValues?: RequestFormValues;
}

const RequestOptions: Option[] = [
  {
    key: RequestType.PersonalLoan,
    value: RequestType.PersonalLoan,
    labelId: "request_offer.type.loan",
  },
  {
    key: RequestType.Mortgage,
    value: RequestType.Mortgage,
    labelId: "request_offer.type.mortgage",
  },
  {
    key: RequestType.Exchange,
    value: RequestType.Exchange,
    labelId: "request_offer.type.exchange",
  },
];

class CreateRequestView extends React.PureComponent<Props, State> {
  state: State = {
    isPrefillSuggestionViewShown: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isPrefillSuggestionViewShown: props.latestRequestFormValues !== undefined,
    };
  }

  onCloseButtonClick = () => {
    this.props.onCloseClick();
  };

  hidePrefillSuggestionView = () => {
    this.setState({ isPrefillSuggestionViewShown: false });
  };

  prefillLatestFormValues = () => {
    if (!this.props.latestRequestFormValues) {
      return;
    }
    this.setState({
      latestFormValues: this.props.latestRequestFormValues,
      isPrefillSuggestionViewShown: false,
    });
  };

  renderForm = () => {
    const { latestFormValues } = this.state;
    const {
      user,

      currencyOptions,
      quickRequestData,
      selectedRequestType,

      onForeignRequestSubmit,
      onMortgageSubmit,
      onPersonalLoanSubmit,
    } = this.props;

    switch (selectedRequestType) {
      case RequestType.Exchange:
        return (
          <RequestForeignExchangeForm
            currencyOptions={currencyOptions}
            quickRequestData={quickRequestData}
            onSubmit={onForeignRequestSubmit}
          />
        );
      case RequestType.Mortgage:
        return (
          <RequestMortgageForm
            borrower={ensureBorrower(user)}
            quickRequestData={quickRequestData}
            latestFormValues={latestFormValues as RequestMortgageFormValues}
            onSubmit={onMortgageSubmit}
          />
        );
      case RequestType.PersonalLoan:
        return (
          <RequestPersonalLoanForm
            borrower={ensureBorrower(user)}
            quickRequestData={quickRequestData}
            latestFormValues={latestFormValues as RequestPersonalLoanFormValues}
            onSubmit={onPersonalLoanSubmit}
          />
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <IonPage className={styles.container}>
        <IonHeader mode="ios">
          <IonToolbar mode="ios">
            <div className={styles.back}>
              <IonButtons slot="start">
                <IonButton onClick={this.onCloseButtonClick}>
                  <IonImg src={Cross} />
                </IonButton>
              </IonButtons>
            </div>
            <div className={styles.title}>
              <FormattedMessage id="request_offer.title" />
            </div>
          </IonToolbar>
        </IonHeader>
        <MPContent class={styles.content} overflowScrolling="auto">
          <div className={styles.formContainer}>
            {this.state.isPrefillSuggestionViewShown && (
              <SuggestionDialogue
                titleId={"request_offer.refill_dialogue.title"}
                confirmButtonTextId={"request_offer.refill_dialogue.confirm"}
                cancelButtonTextId={"request_offer.refill_dialogue.cancel"}
                onConfirm={this.prefillLatestFormValues}
                onCancel={this.hidePrefillSuggestionView}
              />
            )}
            <div className={styles.typeDropdown}>
              <label className={styles.primaryLabelText}>
                <FormattedMessage id="request_offer.section.select_type" />
              </label>
              <FormattedDropdown
                containerClass={styles.dropdownContainer}
                selectClass={styles.dropdownSelect}
                errorClass={styles.dropdownError}
                emptyClass={styles.dropdownEmpty}
                value={this.props.selectedRequestType}
                isError={false}
                options={RequestOptions}
                onValueChange={this.props.onRequestTypeChange}
                disabled={true}
              />
            </div>
            {this.renderForm()}
          </div>
        </MPContent>
      </IonPage>
    );
  }
}

export default CreateRequestView;
