import React, { PureComponent } from "react";
import { Omit } from "../utils/typeutils";
import { IonAlert } from "@ionic/react";
import { Context as LocaleContext } from "@oursky/react-messageformat";
import {
  APIError,
  getAPIErrorMessageId,
  getErrorMessageIdFromUnknwonError,
} from "../error";

export interface ErrorAlertContextValue {
  messageId?: string;
  show: (error: any) => void;
}

interface ErrorAlertContextState {
  messageId?: string;
  showAlert: boolean;
}

export const ErrorAlertContext = React.createContext<ErrorAlertContextValue>(
  null as any
);

interface ProviderProps {
  children?: React.ReactNode;
}

type ProviderState = ErrorAlertContextState;

export class ErrorAlertProvider extends PureComponent<
  ProviderProps,
  ProviderState
> {
  state: ProviderState = {
    showAlert: false,
  };

  show = (error: any) => {
    this.setState({
      messageId:
        typeof error === "string"
          ? error
          : error instanceof APIError
          ? getAPIErrorMessageId(error)
          : getErrorMessageIdFromUnknwonError(error),
      showAlert: true,
    });
  };

  close = () => {
    this.setState({ showAlert: false });
  };

  render() {
    const { children } = this.props;
    const { messageId, showAlert } = this.state;
    return (
      <ErrorAlertContext.Provider
        value={{
          messageId: messageId,
          show: this.show,
        }}
      >
        <LocaleContext.Consumer>
          {({ renderToString }) => (
            <IonAlert
              isOpen={showAlert}
              onDidDismiss={this.close}
              message={messageId ? renderToString(messageId) : ""}
              buttons={[renderToString("error_alert.ok")]}
            />
          )}
        </LocaleContext.Consumer>
        {children}
      </ErrorAlertContext.Provider>
    );
  }
}

export interface ErrorAlertContextProps {
  errorAlertContext: ErrorAlertContextValue;
}

export function withErrorAlert<P extends ErrorAlertContextProps>(
  Component: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof ErrorAlertContextProps>> {
  const Wrapped: React.FC<Omit<P, keyof ErrorAlertContextProps>> = (
    props: Omit<P, keyof ErrorAlertContextProps>
  ) => (
    <>
      <ErrorAlertContext.Consumer>
        {context => <Component {...props as any} errorAlertContext={context} />}
      </ErrorAlertContext.Consumer>
    </>
  );
  return Wrapped;
}
