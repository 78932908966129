import React, { FormEventHandler } from "react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classnames from "classnames";

import styles from "./styles.module.scss";

import { PrimaryButton } from "../Button";
import { DropdownPicker, Option as DPOption } from "../DropdownPicker";
import ErrorField from "../ErrorField";
import { MPInput } from "../input";

import {
  CreateForeignExchangeRequest,
  QuickRequestData,
  RequestType,
} from "../../types/request";
import { FormErrors as IFormErrors } from "../../utils/form";
import {
  currencyFormatToNumber,
  formatToCurrencyAmount,
} from "../../utils/formatToCurrencyAmount";
import { validateFormattedAmount } from "../../utils/validation";
import { ValidationError, Validator, validate } from "../../utils/validate";

export type InputFields = "sellingAmount" | "buyingCurrencyId";

interface Props {
  currencyOptions: DPOption[];
  quickRequestData?: QuickRequestData;
  onSubmit: (values: CreateForeignExchangeRequest) => void;
}

interface FormValues {
  sellingCurrencyId: string;
  buyingCurrencyId: string;
  sellingAmount: string;
}
type FormErrors = IFormErrors<FormValues>;

interface State {
  values: FormValues;
  error: ValidationError<InputFields>;
  focusField?: InputFields;
}

class RequestForeignExchangeForm extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const defaultHKD = props.currencyOptions.find(c => c.display === "HKD");
    let prefillBuyingCurrencyId: string | null = null;
    let prefillsellingAmount: string | null = null;
    if (
      props.quickRequestData &&
      props.quickRequestData.type === RequestType.Exchange
    ) {
      prefillBuyingCurrencyId = props.quickRequestData.buyingCurrencyId;
      prefillsellingAmount = props.quickRequestData.sellingAmount;
    }
    this.state = {
      values: {
        sellingCurrencyId:
          (defaultHKD && defaultHKD.value) || props.currencyOptions[0].value,
        buyingCurrencyId:
          prefillBuyingCurrencyId || props.currencyOptions[0].value,
        sellingAmount: prefillsellingAmount || "",
      },
      error: {},
    };
  }

  componentDidUpdate(prevProps: Props) {
    this.fillinQuickRequestDataIfChanged(prevProps);
  }

  fillinQuickRequestDataIfChanged = (prevProps: Props) => {
    const { quickRequestData, currencyOptions } = this.props;
    if (
      !prevProps.quickRequestData &&
      quickRequestData &&
      quickRequestData.type === RequestType.Exchange
    ) {
      const defaultHKD = currencyOptions.find(c => c.display === "HKD");
      this.setState(({ values }) => {
        return {
          values: {
            ...values,
            sellingCurrencyId:
              (defaultHKD && defaultHKD.value) || values.sellingCurrencyId,
            buyingCurrencyId:
              quickRequestData.buyingCurrencyId || values.buyingCurrencyId,
            sellingAmount:
              quickRequestData.sellingAmount || values.sellingAmount,
          },
        };
      });
    }
  };

  renderCurrencySection = () => {
    const { error, values, focusField } = this.state;
    return (
      <div className={styles.row}>
        <div className={styles.col}>
          <label className={styles.sectionLabel}>
            <FormattedMessage id="request_offer.exchange.currency_from.label" />
          </label>
          <DropdownPicker
            containerClass={styles.dropdownContainer}
            selectClass={styles.dropdownSelect}
            errorClass={styles.dropdownError}
            emptyClass={styles.dropdownEmpty}
            isError={false}
            value={values.sellingCurrencyId}
            options={this.props.currencyOptions}
            onValueChange={this.handleSellingCurrencyChange}
          />
        </div>
        <div className={styles.colRight} data-anchor="buyingCurrencyId">
          <label className={styles.sectionLabel}>
            <FormattedMessage id="request_offer.exchange.currency_to.label" />
          </label>
          <DropdownPicker
            containerClass={styles.dropdownContainer}
            selectClass={styles.dropdownSelect}
            errorClass={styles.dropdownError}
            emptyClass={styles.dropdownEmpty}
            isError={focusField === "buyingCurrencyId"}
            value={values.buyingCurrencyId}
            options={this.props.currencyOptions}
            onValueChange={this.handleBuyingCurrencyChange}
            shouldScrollTo={focusField === "buyingCurrencyId"}
            scrollAnchor="buyingCurrencyId"
          />
          <ErrorField messageId={error.buyingCurrencyId} />
        </div>
      </div>
    );
  };

  renderAmountSection = () => {
    const { error, values, focusField } = this.state;
    const sellingCurrency = this.getSellingCurrency(values.sellingCurrencyId);

    return (
      <div className={styles.col}>
        <div className={styles.sectionLabel}>
          <FormattedMessage id="request_offer.exchange.offer_info.label" />
        </div>
        <label
          className={classnames(styles.sectionLabel, styles.amountSectionLabel)}
        >
          <FormattedMessage id="request_offer.exchange.amount.label" />
        </label>
        <MPInput
          className={styles.input}
          value={values.sellingAmount}
          onChange={this.handleSellingAmountChange}
          placeholder={sellingCurrency.display}
          maxLength={11}
          inputMode="tel"
          type="tel"
          isError={error.sellingAmount !== undefined}
          shouldFocus={focusField === "sellingAmount"}
          autoSubmit={this.doSubmit}
        />
        <ErrorField messageId={error.sellingAmount} />
      </div>
    );
  };

  render() {
    return (
      <form noValidate={true} onSubmit={this.handleSubmit}>
        <div className={styles.formContainer}>
          {this.renderCurrencySection()}
          {this.renderAmountSection()}
        </div>

        <PrimaryButton class={styles.submitButton} type="submit" expand="full">
          <FormattedMessage id="request_offer.submit" />
        </PrimaryButton>
      </form>
    );
  }

  handleSellingAmountChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const sellingAmount = e.currentTarget.value || "";
    const formattedAmount = formatToCurrencyAmount(sellingAmount);

    if (formattedAmount !== null && validateFormattedAmount(formattedAmount)) {
      this.setState(({ values }) => {
        return {
          values: { ...values, sellingAmount: formattedAmount },
        };
      });
    }
  };

  handleBuyingCurrencyChange = (buyingCurrencyId: string) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, buyingCurrencyId },
      };
    });
  };

  handleSellingCurrencyChange = (sellingCurrencyId: string) => {
    this.setState(({ values }) => {
      const formattedAmount = formatToCurrencyAmount(values.sellingAmount);

      return {
        values: {
          ...values,
          sellingCurrencyId,
          sellingAmount: formattedAmount || "",
        },
      };
    });
  };

  handleSubmit: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    this.doSubmit();
  };

  doSubmit = () => {
    this.setState({ focusField: undefined }, () => {
      const { values } = this.state;

      if (!this.validateRequestData(values)) return;

      const formattedValues: CreateForeignExchangeRequest = {
        type: RequestType.Exchange,
        sellingCurrencyId: values.sellingCurrencyId,
        buyingCurrencyId: values.buyingCurrencyId,
        sellingAmount: currencyFormatToNumber(values.sellingAmount),
      };

      this.props.onSubmit(formattedValues);
    });
  };

  getSellingCurrency = (sellingCurrencyId: string): DPOption => {
    const sellingCurrency = this.props.currencyOptions.find(
      c => c.value === sellingCurrencyId
    );

    if (!sellingCurrency) {
      throw new Error(`Unexpected currency id: ${sellingCurrencyId}`);
    }
    return sellingCurrency;
  };

  resetState() {
    const { currencyOptions } = this.props;
    this.setState({
      values: {
        sellingCurrencyId: currencyOptions[0].value,
        buyingCurrencyId: currencyOptions[0].value,
        sellingAmount: "",
      },
      error: {},
    });
  }

  validateRequestData(values: FormValues) {
    const { sellingCurrencyId, buyingCurrencyId, sellingAmount } = values;

    const validators: Validator<InputFields>[] = [
      {
        field: "buyingCurrencyId",
        invalidCondition: sellingCurrencyId === buyingCurrencyId,
        errorMessageId: "request_offer.exchange.error.same_currency",
      },
      {
        field: "sellingAmount",
        invalidCondition: !sellingAmount,
        errorMessageId: "request_offer.exchange.error.required.amount",
      },
      {
        field: "sellingAmount",
        invalidCondition:
          sellingAmount !== "" && currencyFormatToNumber(sellingAmount) <= 0,
        errorMessageId: "request_offer.exchange.error.negative_or_error.amount",
      },
    ];

    const { focusField, error, isValid } = validate(validators);

    this.setState({ error, focusField });

    return isValid;
  }
}

export default RequestForeignExchangeForm;
