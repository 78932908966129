import React from "react";
import classNames from "classnames";
import {
  IonImg,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonPage,
} from "@ionic/react";
import { FormattedMessage } from "@oursky/react-messageformat";

import { BackButton } from "../BackButton";
import VerificationCodeInput from "./VerificationCodeInput";
import styles from "./styles.module.scss";

import { SMSVerificationContext } from "../../screens/SMSVerificationScreen";
import { SMSVerification as images } from "../../images";
import { isFailure } from "../../utils/remoteData";

import { MPContent } from "../MPContent";

export interface SMSVerificationViewProps {
  countryCode: string;
  phoneNumber: string;
  onResendVerificationCode: () => void;
  verificationCodeId: string;
}

export class SMSVerificationView extends React.PureComponent<
  SMSVerificationViewProps
> {
  get maskedPhoneNumber() {
    return Array.from(this.props.phoneNumber)
      .map((c, i) => {
        if (i >= 1 && i <= 3) {
          return "*";
        }
        return c;
      })
      .join("");
  }

  onResendClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onResendVerificationCode();
  };

  render() {
    const { countryCode } = this.props;
    const phoneNumber = this.maskedPhoneNumber;

    return (
      <SMSVerificationContext.Consumer>
        {({ resendCountdown, ...context }) => (
          <IonPage className={styles.page}>
            <IonHeader mode="ios">
              <IonToolbar mode="ios">
                <IonButtons slot="start">
                  <IonButton>
                    <BackButton />
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <MPContent>
              <div className={styles.container}>
                <IonImg class={styles.icon} src={images.icon} />
                <h1>
                  <FormattedMessage id="sms_verification.title" />
                </h1>

                <div className={styles.description}>
                  <FormattedMessage
                    id="sms_verification.we_have_sent_sms"
                    values={{ countryCode, phoneNumber }}
                  />
                  <br />
                  <br />
                  <FormattedMessage id="sms_verification.enter_4_digit" />
                </div>
                <VerificationCodeInput
                  digits={context.digits}
                  isError={isFailure(context.remoteVerify)}
                  onDigitChange={context.updateDigits}
                />
                <div className={styles.resendButtonContainer}>
                  <button
                    className={classNames(styles.resendButton, {
                      [styles.disabled]: resendCountdown > 0,
                    })}
                    disabled={resendCountdown > 0}
                    onClick={this.onResendClick}
                  >
                    <FormattedMessage
                      id="sms_verification.resend"
                      values={{
                        countdown:
                          resendCountdown <= 0 ? "" : `(${resendCountdown}s)`,
                      }}
                    />
                  </button>
                </div>
              </div>
            </MPContent>
          </IonPage>
        )}
      </SMSVerificationContext.Consumer>
    );
  }
}
