import { BaseApiClient, ApiClientConstructor } from "./client";

import { minAppVersionResponseSchema } from "../types/response";

export interface AppConfigApiClient {
  fetchMinAppVersion: () => Promise<string>;
}

export function withAppConfigApi<
  TBase extends ApiClientConstructor<BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async fetchMinAppVersion(): Promise<string> {
      const resp = await this.requestLambda(
        "app-config:fetch-min-app-version",
        {},
        minAppVersionResponseSchema
      );

      return resp.minAppVersion;
    }
  };
}
