import React from "react";
import { AgentApiClient } from "../apiClient";
import { AgentBriefData } from "../models";
import { Page } from "../utils/pagination";
import { Omit } from "../utils/typeutils";

interface AgentContextValue {
  listAgentsForAdminAgent: (
    cursor?: string,
    impersonatorCustomToken?: string
  ) => Promise<Page<AgentBriefData>>;
  agentListForAdminAgentVersion: number;
}

const AgentContext = React.createContext<AgentContextValue>(null as any);

interface Props {
  apiClient: AgentApiClient;
}

interface State {
  agentListForAdminAgentVersion: number;
}

export class AgentContextProvider extends React.PureComponent<Props> {
  state: State = {
    agentListForAdminAgentVersion: 0,
  };

  refreshAgentListForAdmin = () => {
    this.setState(
      (prevState: State): State => {
        return {
          ...prevState,
          agentListForAdminAgentVersion:
            prevState.agentListForAdminAgentVersion + 1,
        };
      }
    );
  };

  render() {
    const { apiClient, children } = this.props;
    return (
      <AgentContext.Provider
        value={{
          agentListForAdminAgentVersion: this.state
            .agentListForAdminAgentVersion,
          listAgentsForAdminAgent: apiClient.listAgentsForAdminAgent.bind(
            apiClient
          ),
        }}
      >
        {children}
      </AgentContext.Provider>
    );
  }
}

export default AgentContext;

export interface AgentContextProps {
  agentContext: AgentContextValue;
}

export function withAgent<P extends AgentContextProps>(
  Component: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof AgentContextProps>> {
  const Wrapped: React.FC<Omit<P, keyof AgentContextProps>> = (
    props: Omit<P, keyof AgentContextProps>
  ) => (
    <AgentContext.Consumer>
      {agentContext => (
        <Component {...props as any} agentContext={agentContext} />
      )}
    </AgentContext.Consumer>
  );

  return Wrapped;
}
