import React from "react";
import {
  IonButton,
  IonButtons,
  IonImg,
  IonHeader,
  IonText,
  IonToolbar,
  IonPage,
} from "@ionic/react";
import { FormattedMessage } from "@oursky/react-messageformat";

import styles from "./styles.module.scss";

import Cross from "../../images/icon-cross.svg";
import { RemoteData, isLoading } from "../../utils/remoteData";

import { PrimaryButton } from "../Button";
import ErrorField from "../ErrorField";
import {
  PhoneNumberInput,
  PhoneNumberInputValue,
  InputFields,
} from "../PhoneNumberInput";
import { MPContent } from "../MPContent";
import { ValidationError } from "../../utils/validate";

interface Props {
  remoteSendSms: RemoteData<string>;
  phoneNumberValue: PhoneNumberInputValue;
  phoneNumberErrorId?: string;
  error: ValidationError<InputFields>;
  focusField?: InputFields;

  onCloseClick: () => void;
  onLoginClick: () => void;
  onSignupClick: () => void;
  onPhoneNumberValueChange: (value: PhoneNumberInputValue) => void;
}

class LoginView extends React.PureComponent<Props> {
  onCloseButtonClick = () => {
    this.props.onCloseClick();
  };

  onLoginButtonClick = () => {
    this.props.onLoginClick();
  };

  onSignupButtonClick = (e: React.MouseEvent<unknown>) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onSignupClick();
  };

  onPhoneNumberValueChange = (value: PhoneNumberInputValue) => {
    this.props.onPhoneNumberValueChange(value);
  };

  render() {
    const {
      remoteSendSms,
      phoneNumberValue,
      phoneNumberErrorId,
      focusField,
      error,
      onLoginClick,
    } = this.props;

    return (
      <IonPage className={styles.page}>
        <IonHeader mode="ios">
          <IonToolbar mode="ios">
            <IonButtons slot="start">
              <IonButton onClick={this.onCloseButtonClick}>
                <IonImg src={Cross} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <MPContent overflowScrolling="auto">
          <form className={styles.loginFormContainer}>
            <IonText class={styles.title}>
              <FormattedMessage id="login.title" />
            </IonText>
            <PhoneNumberInput
              className={styles.phoneNumberInput}
              value={phoneNumberValue}
              isCountryCodeError={!!error["countryCode"]}
              isPhoneNumberError={
                !!(error["phoneNumber"] || phoneNumberErrorId)
              }
              onChange={this.onPhoneNumberValueChange}
              autoFocus={true}
              focusField={
                focusField || (phoneNumberErrorId ? "phoneNumber" : undefined)
              }
              autoSubmit={onLoginClick}
            />
            <ErrorField
              messageId={
                error["phoneNumber"] ||
                error["countryCode"] ||
                phoneNumberErrorId
              }
            />

            <PrimaryButton
              class={styles.loginButton}
              expand="full"
              disabled={isLoading(remoteSendSms)}
              onClick={this.onLoginButtonClick}
            >
              <FormattedMessage id="login.button" />
            </PrimaryButton>
            <div className={styles.signupButtonContainer}>
              <button
                className={styles.signupButton}
                onClick={this.onSignupButtonClick}
              >
                <FormattedMessage id="login.new_user_signup" />
                <span className={styles.signupText}>
                  <FormattedMessage id="login.signup" />
                </span>
              </button>
            </div>
          </form>
        </MPContent>
      </IonPage>
    );
  }
}

export default LoginView;
