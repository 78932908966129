import { IonRippleEffect } from "@ionic/react";
import classNames from "classnames";
import React from "react";

import styles from "./styles.module.scss";

export type FlatButtonProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  disabledClassName?: string;
  disabled?: boolean;
};

export const FlatButton: React.FC<FlatButtonProps> = props => {
  const { className, disabledClassName, disabled, children, ...rest } = props;

  return (
    <div
      className={classNames("ion-activatable", styles.button, className, {
        [disabledClassName || ""]: disabled,
      })}
      {...rest}
    >
      {children}
      <IonRippleEffect />
    </div>
  );
};
