import * as yup from "yup";
import { BlockEvent, EventType } from "../models";

function eventTypeSchema(requestType: EventType) {
  return yup.mixed().oneOf([requestType], "Unexpected Event Type");
}

export const blockEventSchema = yup
  .object<BlockEvent>({
    event: eventTypeSchema("block").required(),
    userId: yup.string().required(),
    targetUserId: yup.string().required(),
  })
  .camelCase();

export const unblockEventSchema = yup
  .object<BlockEvent>({
    event: eventTypeSchema("unblock").required(),
    userId: yup.string().required(),
    targetUserId: yup.string().required(),
  })
  .camelCase();
