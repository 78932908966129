import * as yup from "yup";

import {
  ExchangeLoanOffering,
  MortgageOffering,
  PersonalLoanOffering,
  OfferingType,
  Offering,
  OfferingForm,
} from "../models";

import { currencySchema } from "../schemas/request";

import { DateTimeSchema } from "./luxon";

import { offeringFormSchema } from "./offering_form";
import { agentBriefDataSchema } from "./user";

export const baseOfferingSchema = {
  id: yup.string().required(),
  agent: agentBriefDataSchema.required(),
  cancelledAt: new DateTimeSchema().nullable(),
  createdAt: new DateTimeSchema().required(),
  expiredAt: new DateTimeSchema().nullable(),
  isFeatured: yup.boolean().required(),
  priority: yup.number().nullable(),
  chatId: yup.string().nullable(),
  directApplyLink: yup.string().nullable(),
};

const unexpectedType = "Unexpected Offering Type";

function offeringTypeSchema(offeringType: OfferingType) {
  return yup.mixed().oneOf([offeringType], unexpectedType);
}

export const exchangeLoanOfferingSchema: yup.ObjectSchema<
  ExchangeLoanOffering
> = yup
  .object({
    ...baseOfferingSchema,
    buyingCurrency: currencySchema.required(),
    buyingAmount: yup.number().required(),
    sellingAmount: yup.number().required(),
    sellingCurrency: currencySchema.required(),
    type: offeringTypeSchema(OfferingType.exchangeLoan),
  })
  .camelCase();

export const personalLoanOfferingSchema: yup.ObjectSchema<
  PersonalLoanOffering
> = yup
  .object({
    ...baseOfferingSchema,
    addition: yup.string().nullable(),
    amountMax: yup.number().required(),
    amountMin: yup.number().required(),
    periodMax: yup.number().required(),
    periodMin: yup.number().required(),
    interestRateMax: yup.string().required(),
    interestRateMin: yup.string().required(),
    isRequiredEmployed: yup.boolean().required(),
    isRequiredProperty: yup.boolean().required(),
    type: offeringTypeSchema(OfferingType.personalLoan),
  })
  .camelCase();

export const mortgageOfferingSchema: yup.ObjectSchema<MortgageOffering> = yup
  .object({
    ...baseOfferingSchema,
    addition: yup.string().nullable(),
    paymentPeriodMax: yup.number().required(),
    loanAmount: yup.number().required(),
    interestRateMax: yup.number().required(),
    interestRateMin: yup.number().required(),
    isRequiredEmployed: yup.boolean().required(),
    mortgageLevel: yup.string().required(),
    buildingValue: yup.number().required(),
    type: offeringTypeSchema(OfferingType.mortgage),
  })
  .camelCase();

export const offeringSchema = yup
  .object<Offering>()
  .when(".type", (type: any) => {
    switch (type) {
      case OfferingType.exchangeLoan:
        return exchangeLoanOfferingSchema;
      case OfferingType.mortgage:
        return mortgageOfferingSchema;
      case OfferingType.personalLoan:
        return personalLoanOfferingSchema;
      default:
        throw new Error(`Unexpected type = ${type}`);
    }
  });

export const offeringListSchema: yup.Schema<Offering[]> = yup.array(
  offeringSchema
);

export const offeringTemplateSchema: yup.Schema<OfferingForm[]> = yup
  .array(offeringFormSchema)
  .default([]);
