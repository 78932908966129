import * as yup from "yup";

import {
  Company,
  Request,
  Offering,
  OfferingForm,
  Template,
  BaseChatWithBlockingInfo,
  Chat,
  Conversation,
  Industry,
  AgentBriefData,
} from "../models";
import { companySchema } from "../schemas/company";
import { offeringTemplateSchema, offeringSchema } from "../schemas/offering";
import { Page } from "../utils/pagination";
import { makePageSchema } from "../schemas/pagination";
import { requestSchema } from "../schemas/request";
import { templateSchema } from "../schemas/template";
import {
  chatSchema,
  conversationSchema,
  baseChatSchema,
} from "../schemas/chat";
import { agentBriefDataSchema, industrySchema } from "../schemas/user";

import { CurrencyOption, SalaryOption } from "./option";

export interface SendSmsResponse {
  verificationCodeId: string;
}

export const sendSmsResponseSchema = yup
  .object<SendSmsResponse>({
    verificationCodeId: yup.string().required(),
  })
  .camelCase();

export interface VerifyCodeResponse {
  token: string;
}

export const verifyCodeResponseSchema = yup
  .object<VerifyCodeResponse>({
    token: yup.string().required(),
  })
  .camelCase();

export interface AgentImpersonateResponse {
  token: string;
}

export const agentImpersonateResponseSchema = yup
  .object<AgentImpersonateResponse>({
    token: yup.string().required(),
  })
  .camelCase();

export interface CurrencyOptionResponse {
  options: CurrencyOption[];
}

export const currencyOptionSchema: yup.Schema<CurrencyOption> = yup
  .object<CurrencyOption>()
  .camelCase()
  .shape({
    id: yup.string().required(),
    isoCode: yup.string().required(),
  });

export const currencyOptionResponseSchema = yup.object({
  options: yup
    .array()
    .of(currencyOptionSchema)
    .default([]),
});

export interface SalaryOptionResponse {
  options: SalaryOption[];
}

export const salaryOptionSchema: yup.Schema<SalaryOption> = yup
  .object<SalaryOption>({
    id: yup.string().required(),
    amount: yup.string().required(),
  })
  .camelCase();

export const salaryOptionResponseSchema = yup.object<SalaryOptionResponse>({
  options: yup
    .array()
    .of(salaryOptionSchema)
    .default([]),
});

export interface IndustryResponse {
  options: Industry[];
}

export const industryOptionResponseSchema = yup.object<IndustryResponse>({
  options: yup
    .array()
    .of(industrySchema)
    .default([]),
});

export const searchCompanyResponseSchema: yup.Schema<
  Page<Company>
> = makePageSchema<Company>(companySchema);

export const listBorrowerRequestSchema: yup.Schema<
  Page<Request>
> = makePageSchema<Request>(requestSchema);

export const listAllOfferingsForBorrowerRequestSchema: yup.Schema<
  Page<Offering>
> = makePageSchema<Offering>(offeringSchema);

export const listAllOfferingsForGuestRequestSchema: yup.Schema<
  Page<Offering>
> = makePageSchema<Offering>(offeringSchema);

export const paginatedChatsSchema: yup.Schema<Page<Chat>> = makePageSchema<
  Chat
>(chatSchema);

export interface UpdateOfferingTemplateResponse {
  offeringTemplate: OfferingForm[];
}

export const updateOfferingTemplateResponseSchema: yup.Schema<
  UpdateOfferingTemplateResponse
> = yup.object({ offeringTemplate: offeringTemplateSchema }).camelCase();

export interface ConversationResponse {
  conversation: Conversation;
}

export const conversationResponseSchema: yup.Schema<ConversationResponse> = yup
  .object({
    conversation: conversationSchema.required(),
  })
  .camelCase();

export const getChatResponseSchema: yup.Schema<BaseChatWithBlockingInfo> = yup
  .object({
    ...baseChatSchema,
    isAgentBlocked: yup.boolean(),
  })
  .camelCase();

export interface TemplateResponse {
  templates: Template[];
}

export const templateResponseSchema = yup.object<TemplateResponse>({
  templates: yup
    .array()
    .of(templateSchema)
    .default([]),
});

export const listAgentsResponseSchema: yup.Schema<
  Page<AgentBriefData>
> = makePageSchema<AgentBriefData>(agentBriefDataSchema);

export interface MinAppVersionResponse {
  minAppVersion: string;
}

export const minAppVersionResponseSchema: yup.Schema<
  MinAppVersionResponse
> = yup
  .object({
    minAppVersion: yup.string().required(),
  })
  .camelCase();
