import React from "react";
import classnames from "classnames";
import { FormattedMessage } from "@oursky/react-messageformat";

import { ReferralAgreementStatus } from "../../models";
import { getChatroomStatusMessageId } from "../../utils/chat";

import styles from "./styles.module.scss";

interface Props {
  isCompleted: boolean;
  referralAgreement: ReferralAgreementStatus | null;
  onClick?: () => void;
}

export const StatusLightBox: React.FC<Props> = props => {
  const { isCompleted, referralAgreement } = props;
  const messageId = getChatroomStatusMessageId(isCompleted, referralAgreement);

  return (
    <div
      className={classnames(styles.statusLightBox, {
        [styles.completed]: isCompleted,
      })}
      onClick={props.onClick}
    >
      <FormattedMessage id={messageId} />
    </div>
  );
};
