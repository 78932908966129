import React, { useCallback } from "react";
import { AgentBriefData } from "../../models";
import { Page } from "../../utils/pagination";
import { InfiniteScrollView } from "../InfiniteListView";
import { MPContent } from "../MPContent";
import AgentItem from "./AgentItem";

interface Props {
  fetchPage: (cursor?: string) => Promise<Page<AgentBriefData>>;
  onClickAgent: (agentData: AgentBriefData) => void;
  listVersion: number;
}

export const AgentListView: React.FC<Props> = props => {
  const { fetchPage, listVersion, onClickAgent } = props;

  const renderItem = useCallback(
    (agentData: AgentBriefData) => {
      return (
        <AgentItem
          key={agentData.id}
          agentData={agentData}
          onClickAgent={onClickAgent}
        />
      );
    },
    [onClickAgent]
  );

  return (
    <MPContent>
      <InfiniteScrollView
        key={listVersion}
        fetchPage={fetchPage}
        renderItem={renderItem}
        threshold={500}
      />
    </MPContent>
  );
};
