import classnames from "classnames";
import React from "react";
import useHandleLinkOnHTMLContent from "../../utils/handleLinkOnHTMLContent";
import { useRefToRefObject } from "../../utils/Ref";

import styles from "./styles.module.scss";

interface Props {
  text: string;
  className?: string;
}

const RichTextView: React.RefForwardingComponent<HTMLDivElement, Props> = (
  props,
  forwardedRef
) => {
  const { className, text } = props;

  const ref = useRefToRefObject(forwardedRef);

  useHandleLinkOnHTMLContent(ref, [text]);

  return (
    <div
      ref={ref}
      className={classnames(className, styles.reset)}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

export default React.forwardRef<HTMLDivElement, Props>(RichTextView);
