import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import numeral from "numeral";
import React, { PureComponent } from "react";

import { withOptions, OptionsProps } from "../../context/OptionsContext";
import { CreateForeignExchangeForm } from "../../models";

import { renderExpiredDate } from "./index";
import styles from "./styles.module.scss";

interface ForeignExchangeQuickOfferItemProps {
  offering: CreateForeignExchangeForm;
}

type Props = OptionsProps & ForeignExchangeQuickOfferItemProps;

class ForeignExchangeQuickOfferItem extends PureComponent<Props> {
  getCurrencyCodeById = (currencyId: string) => {
    const { options } = this.props;

    const currency = options.currencyOptions.find(
      currency => currency.id === currencyId
    );
    if (!currency) {
      throw new Error(`Unknown currency id: ${currencyId}`);
    }
    return currency.isoCode;
  };

  render() {
    const { offering } = this.props;
    const sellingCurrencyCode = this.getCurrencyCodeById(
      offering.sellingCurrencyId
    );
    const buyingCurrencyCode = this.getCurrencyCodeById(
      offering.buyingCurrencyId
    );

    return (
      <div>
        <div className={styles.title}>
          <FormattedMessage id="quick_offer.template_card.foreign_exchange.title" />
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.label}>
              <FormattedMessage
                id="quick_offer.template_card.foreign_exchange.amount"
                values={{ currency: sellingCurrencyCode }}
              />
            </div>
            <div className={styles.value}>
              {numeral(offering.sellingAmount).format()}
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.label}>
              <FormattedMessage id="quick_offer.template_card.foreign_exchange.currency" />
            </div>
            <div className={styles.value}>
              <FormattedMessage
                id="quick_offer.template_card.foreign_exchange.currency.value"
                values={{
                  to_currency: sellingCurrencyCode,
                  from_currency: buyingCurrencyCode,
                }}
              />
            </div>
          </div>
        </div>

        <div className={classNames(styles.row, styles.col)}>
          <div className={styles.label}>
            <FormattedMessage id="quick_offer.template_card.foreign_exchange.expired_at" />
          </div>
          <div className={styles.value}>
            {renderExpiredDate(offering.validDays)}
          </div>
        </div>
      </div>
    );
  }
}

export default withOptions(ForeignExchangeQuickOfferItem);
