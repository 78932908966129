import React from "react";
import classNames from "classnames";
import numeral from "numeral";
import { DateTime } from "luxon";
import { FormattedMessage } from "@oursky/react-messageformat";

import styles from "./styles.module.scss";

import {
  ExchangeLoanChat,
  MortgageChat,
  PersonalLoanChat,
  Chat,
  ChatType,
} from "../../models";
import { formatToCurrencyAmount } from "../../utils/formatToCurrencyAmount";

const renderExpiredDate = (expiredAt: DateTime | null) => {
  if (expiredAt) {
    const dateNow = DateTime.utc();
    const diff = expiredAt.diff(dateNow).toObject().milliseconds;
    return (
      <div
        className={classNames({
          [styles.expired]: diff && diff < 0 ? true : false,
        })}
      >
        <FormattedMessage
          id="offering.offers_and_offer_requests.expired_at"
          values={{ expiredAt: expiredAt.toISODate() }}
        />
      </div>
    );
  }
  return null;
};

const renderMortgageLevel = (mortgageLevel: string) => {
  switch (mortgageLevel) {
    case "1":
      return (
        <FormattedMessage id="offering.offers_and_offer_requests.mortgage_level_one" />
      );
    case "2":
      return (
        <FormattedMessage id="offering.offers_and_offer_requests.mortgage_level_two" />
      );
    case "3":
      return (
        <FormattedMessage id="offering.offers_and_offer_requests.mortgage_level_three" />
      );
    case "4+":
      return (
        <FormattedMessage id="offering.offers_and_offer_requests.mortgage_level_four" />
      );
    default:
      throw new Error(`Unexpected Mortgage level ${mortgageLevel}`);
  }
};

const renderSpecialOffer = (addition: string | null) => {
  if (addition) {
    return addition;
  }
  return "NIL";
};

interface CompnayInfoViewProps {
  name: string;
  icon: string | null;
}

const CompanyInfoView: React.FC<CompnayInfoViewProps> = props => {
  return (
    <div className={styles.companyInfo}>
      <div className={styles.companyName}>{props.name}</div>
      <div className={styles.companyIcon}>
        {props.icon && <img src={props.icon} alt={props.name} />}
      </div>
    </div>
  );
};

interface OfferingListExchangeCardViewProps {
  chat: ExchangeLoanChat;
  openChatroom: (chat: Chat) => void;
}

const OfferingListExchangeCardView: React.FC<
  OfferingListExchangeCardViewProps
> = props => {
  const { chat, openChatroom } = props;
  const { offering } = props.chat;

  const onMessageClick = React.useCallback(() => {
    openChatroom(chat);
  }, [chat, openChatroom]);

  return (
    <div className={styles.cardContainer}>
      <div className={styles.containerFlexRow}>
        <CompanyInfoView name={offering.agent.company.name} icon={null} />
      </div>

      <div className={styles.containerFlexRow}>
        <div className={styles.amountContainer}>
          <FormattedMessage
            id="offering.offers_and_offer_requests.amount"
            values={{ toCurrency: offering.sellingCurrency.isoCode }}
          />
          <div className={styles.figures}>
            {numeral(offering.sellingAmount).format()}
          </div>
        </div>
        <div className={styles.currencyContainer}>
          <FormattedMessage id="offering.offers_and_offer_requests.currency" />
          <div className={styles.figures}>{`${
            offering.buyingCurrency.isoCode
          } - ${offering.sellingCurrency.isoCode}`}</div>
        </div>
      </div>

      <div className={styles.line} />

      <div className={styles.containerFlexRow}>
        <div
          className={classNames(
            styles.containerFlexCol,
            styles.extraInfoContainer
          )}
        >
          <div className={styles.effectiveDate}>
            {renderExpiredDate(offering.expiredAt)}
          </div>
          <div className={styles.licenseNo}>
            <FormattedMessage
              id="offering.offers_and_offer_requests.license_no"
              values={{ licenseNo: offering.agent.company.licenseNo || "-" }}
            />
          </div>
        </div>
        <div className={styles.containerFlexCol}>
          <button className={styles.messageButton} onClick={onMessageClick}>
            <div className={styles.messageTitle}>
              <FormattedMessage id="offering.offers_and_offer_requests.message" />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

interface OfferingListMortgageCardProps {
  chat: MortgageChat;
  openChatroom: (chat: Chat) => void;
}

const OfferingListMortgageCardView: React.FC<
  OfferingListMortgageCardProps
> = props => {
  const { chat, openChatroom } = props;
  const { offering } = props.chat;

  const onMessageClick = React.useCallback(() => {
    openChatroom(chat);
  }, [chat, openChatroom]);

  return (
    <div className={styles.cardContainer}>
      <div className={styles.containerFlexRow}>
        <CompanyInfoView name={offering.agent.company.name} icon={null} />
      </div>

      <div className={styles.containerFlexRow}>
        <div className={styles.loanAmountContainer}>
          <FormattedMessage id="offering.offers_and_offer_requests.mortgage.loan_amount" />
          <div className={styles.figures}>
            {numeral(offering.loanAmount).format()}
          </div>
        </div>
        <div className={styles.levelContainer}>
          <FormattedMessage id="offering.offers_and_offer_requests.mortgage.level" />
          <div className={styles.figures}>
            {renderMortgageLevel(offering.mortgageLevel)}
          </div>
        </div>
      </div>
      <div className={styles.marketValueContainer}>
        <FormattedMessage
          id="offering.offers_and_offer_requests.mortgage.market_value"
          values={{ currency: "HKD" }}
        />
        <div className={styles.figures}>
          {formatToCurrencyAmount(offering.buildingValue, true)}
        </div>
      </div>

      <div className={styles.line} />

      <div className={styles.containerFlexRow}>
        <div
          className={classNames(
            styles.containerFlexCol,
            styles.extraInfoContainer
          )}
        >
          <div className={styles.effectiveDate}>
            {renderExpiredDate(offering.expiredAt)}
          </div>
          <div className={styles.licenseNo}>
            <FormattedMessage
              id="offering.offers_and_offer_requests.license_no"
              values={{ licenseNo: offering.agent.company.licenseNo || "-" }}
            />
          </div>
        </div>
        <div className={styles.containerFlexCol}>
          <button className={styles.messageButton} onClick={onMessageClick}>
            <div className={styles.messageTitle}>
              <FormattedMessage id="offering.offers_and_offer_requests.message" />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

interface OfferingListLoanCardViewProps {
  chat: PersonalLoanChat;
  openChatroom: (chat: Chat) => void;
}

const OfferingListLoanCardView: React.FC<
  OfferingListLoanCardViewProps
> = props => {
  const { chat, openChatroom } = props;
  const { offering } = props.chat;

  const onMessageClick = React.useCallback(() => {
    openChatroom(chat);
  }, [chat, openChatroom]);

  return (
    <div className={styles.cardContainer}>
      <div className={styles.containerFlexRow}>
        <CompanyInfoView name={offering.agent.company.name} icon={null} />
      </div>

      <div className={styles.containerFlexRow}>
        <div className={styles.amountContainer}>
          <FormattedMessage
            id="offering.offers_and_offer_requests.amount"
            values={{ toCurrency: "HKD" }}
          />
          <div className={styles.figures}>
            {`${numeral(offering.amountMin).format()} - ${numeral(
              offering.amountMax
            ).format()}`}
          </div>
        </div>
        <div className={styles.repaymentPeriodContainer}>
          <FormattedMessage id="offering.offers_and_offer_requests.repayment_period" />
          <div className={styles.figures}>
            <FormattedMessage
              id="offering.offers_and_offer_requests.months"
              values={{
                minMonth: numeral(offering.periodMin).format(),
                maxMonth: numeral(offering.periodMax).format(),
                unit: offering.periodMax,
              }}
            />
          </div>
        </div>
      </div>

      <div className={styles.line} />

      <div className={styles.containerFlexRow}>
        <div
          className={classNames(
            styles.containerFlexCol,
            styles.extraInfoContainer
          )}
        >
          <div className={styles.interestRate}>
            <FormattedMessage
              id="offering.offers_and_offer_requests.interest_rate"
              values={{
                minInterest: offering.interestRateMin,
                maxInterest: offering.interestRateMax,
              }}
            />
          </div>
          <div className={styles.effectiveDate}>
            {renderExpiredDate(offering.expiredAt)}
          </div>
          <div className={styles.licenseNo}>
            <FormattedMessage
              id="offering.offers_and_offer_requests.license_no"
              values={{ licenseNo: offering.agent.company.licenseNo || "-" }}
            />
          </div>
        </div>
        <div className={styles.containerFlexCol}>
          <button className={styles.messageButton} onClick={onMessageClick}>
            <div className={styles.messageTitle}>
              <FormattedMessage id="offering.offers_and_offer_requests.message" />
            </div>
          </button>
        </div>
      </div>
      <div
        className={classNames(
          styles.containerFlexCol,
          styles.specialOfferContainer
        )}
      >
        <div className={styles.specialOffer}>
          <FormattedMessage id="offering.offers_and_offer_requests.special_offer" />
        </div>
        <div className={styles.specialOfferContent}>
          {renderSpecialOffer(offering.addition)}
        </div>
      </div>
    </div>
  );
};

export interface OfferingListCardViewProps {
  chat: Chat;
  openChatroom: (chat: Chat) => void;
}

export const OfferingListCardView: React.FC<
  OfferingListCardViewProps
> = props => {
  const { chat, openChatroom } = props;
  switch (chat.type) {
    case ChatType.exchangeLoan:
      return (
        <OfferingListExchangeCardView chat={chat} openChatroom={openChatroom} />
      );
    case ChatType.mortgage:
      return (
        <OfferingListMortgageCardView chat={chat} openChatroom={openChatroom} />
      );
    case ChatType.personalLoan:
      return (
        <OfferingListLoanCardView chat={chat} openChatroom={openChatroom} />
      );
    default:
      throw new Error(`Unexpected chat type: ${ChatType}`);
  }
};
