import React from "react";
import { RouteComponentProps } from "react-router";

import {
  BorrowerLoanRecordView,
  InputFields,
} from "../components/BorrowerLoanRecordView";
import {
  defaultLoanInfoFormValues,
  FormInputFields,
  validatLoanInfoForm,
} from "../components/BorrowerLoanRecordForm/LoanInfoForm";
import {
  withErrorAlert,
  ErrorAlertContextProps,
} from "../context/ErrorAlertContext";
import { withLoading, LoadingContextProps } from "../context/LoadingContext";
import { withUser, UserContextProps } from "../context/UserContext";
import { BorrowerLoanInfo, ExistingLoan } from "../models";
import { ValidationError, validate } from "../utils/validate";
import { ensureBorrower } from "../utils/user";
import { Initial, RemoteData, Success } from "../utils/remoteData";
import { getBorrowerLoanRecordFormValidators } from "../components/BorrowerLoanRecordForm";
import {
  withCreateRequest,
  CreateRequestContextProps,
  DisplayMapByRequestType,
  CreateRequestRoute,
} from "../context/CreateRequestContext";

type Props = ErrorAlertContextProps &
  CreateRequestContextProps &
  LoadingContextProps &
  UserContextProps &
  RouteComponentProps;

interface State {
  remoteUpdate: RemoteData<void>;
  borrower: BorrowerLoanInfo;
  numberOfLoan: number | null;
  error: ValidationError<InputFields | FormInputFields>;
  focusField?: InputFields | FormInputFields;
}

class BorrowerLoanRecordScreenImpl extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const borrower = ensureBorrower(props.userContext.user);
    if (borrower === undefined) {
      throw new Error("Unexpected borrower is undefined");
    }

    this.state = {
      remoteUpdate: Initial(),
      borrower: {
        hasMortgage: borrower.hasMortgage,
        existingLoans: borrower.existingLoans || [],
      },
      numberOfLoan: (borrower.existingLoans || []).length,
      error: {},
    };
  }

  setBorrowerData = (data: Partial<BorrowerLoanInfo>) => {
    this.setState(prevState => ({
      ...prevState,
      borrower: {
        ...prevState.borrower,
        ...data,
      },
    }));
  };

  setErrorState = (data: Partial<ValidationError<InputFields>>) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        ...data,
      },
    }));
  };

  onSelectMortgageStatus = (hasMortgage: boolean) => {
    if (!hasMortgage) {
      this.setBorrowerData({ hasMortgage: false, existingLoans: [] });
      this.setState({ numberOfLoan: null });
    } else {
      this.setBorrowerData({
        hasMortgage: true,
        existingLoans: [
          {
            id: null,
            lender: null,
            amount: null,
            tenor: null,
            remainingTenor: null,
            monthlyRepayment: null,
          },
        ],
      });
      this.setState({ numberOfLoan: 1 });
    }
    this.setErrorState({ hasMortgage: undefined, numberOfLoan: undefined });
  };

  onSelectNumberOfLoan = (numberOfLoan: number) => {
    this.setState(prevState => ({
      ...prevState,
      borrower: {
        ...prevState.borrower,
        existingLoans:
          prevState.borrower.existingLoans.length >= numberOfLoan
            ? prevState.borrower.existingLoans.slice(0, numberOfLoan)
            : prevState.borrower.existingLoans.concat(
                Array(
                  numberOfLoan - prevState.borrower.existingLoans.length
                ).fill(defaultLoanInfoFormValues)
              ),
      },
      numberOfLoan: numberOfLoan,
    }));
    this.setErrorState({ numberOfLoan: undefined });
  };

  onUpdateExistingLoans = (index: number, value: Partial<ExistingLoan>) => {
    this.setState(prevState => ({
      ...prevState,
      borrower: {
        ...prevState.borrower,
        existingLoans: prevState.borrower.existingLoans.map((v, i) =>
          i === index ? { ...v, ...value } : v
        ),
      },
    }));
  };

  formIsValid(skipProfileFilling: boolean = false) {
    const { borrower, numberOfLoan } = this.state;
    const { existingLoans } = borrower;
    const { pendingCreateRequest } = this.props.createRequestContext;
    if (pendingCreateRequest === null) {
      throw new Error("Unexpected pending request is null");
    }
    const formDisplayMap =
      DisplayMapByRequestType[pendingCreateRequest.type][
        CreateRequestRoute.LoanRecord
      ];
    let validators = getBorrowerLoanRecordFormValidators(
      borrower,
      numberOfLoan,
      formDisplayMap
    );
    if (skipProfileFilling) {
      validators = validators.filter(it => !it.isExistenceCheck);
    }
    const { focusField, error, isValid } = validate(validators);
    const formValidateResult = validatLoanInfoForm(
      existingLoans,
      formDisplayMap["existingLoans"],
      skipProfileFilling
    );
    this.setState({
      error: {
        ...error,
        ...formValidateResult.error,
      },
      focusField: focusField || formValidateResult.focusField,
    });

    return isValid && formValidateResult.isValid;
  }

  submitForm = async () => {
    const { borrower } = this.state;
    this.props.loadingContext.show();
    try {
      await this.props.userContext.updateBorrower({ ...borrower });
      await this.props.userContext.fetchUserData();

      this.setState({ remoteUpdate: Success(undefined) });
      this.props.history.push("/close");
    } catch (error) {
      this.props.errorAlertContext.show(error);
      this.setState({ remoteUpdate: Initial() });
    } finally {
      this.props.loadingContext.dismiss();
    }
  };

  onSubmit = async () => {
    this.setState({ focusField: undefined });
    if (!this.formIsValid()) {
      return;
    }
    await this.submitForm();
  };

  onSkipToContact = async () => {
    this.setState({ focusField: undefined });
    if (!this.formIsValid(true)) {
      return;
    }
    await this.submitForm();
    const { setPendingCreateRequest } = this.props.createRequestContext;
    setPendingCreateRequest(prev => {
      if (prev) {
        return {
          ...prev,
          skipProfileFilling: true,
        };
      }
      return prev;
    });
  };

  render() {
    const { borrower, numberOfLoan, error, remoteUpdate } = this.state;

    return (
      <BorrowerLoanRecordView
        remoteUpdate={remoteUpdate}
        borrower={borrower}
        numberOfLoan={numberOfLoan}
        onSelectMortgageStatus={this.onSelectMortgageStatus}
        onSelectNumberOfLoan={this.onSelectNumberOfLoan}
        onUpdateExistingLoans={this.onUpdateExistingLoans}
        error={error}
        onSubmit={this.onSubmit}
        onSkipToContact={this.onSkipToContact}
      />
    );
  }
}

export const BorrowerLoanRecordScreen = withLoading(
  withErrorAlert(withCreateRequest(withUser(BorrowerLoanRecordScreenImpl)))
);
