import { IonButton } from "@ionic/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";
import { PopOver } from "../PopOver";
import RichTextView from "../RichTextView";

import styles from "./styles.module.scss";

interface BaseProps {
  title: string;
  isOpen: boolean;
  onClose?: () => void;
}

interface ConfirmReferralPopoverProps extends BaseProps {
  renderToString: (id: string) => string;
  onReferralStatusChange: (isAccepted: boolean, message: string) => void;
}

export const ConfirmReferralPopover: React.FC<
  ConfirmReferralPopoverProps
> = props => {
  const { title, isOpen, renderToString, onReferralStatusChange } = props;

  const acceptReferralRequest = React.useCallback(() => {
    const agreeText = renderToString("chatroom.borrower.agree");
    onReferralStatusChange(true, agreeText);
  }, [onReferralStatusChange, renderToString]);

  const rejectReferralRequest = React.useCallback(() => {
    const disagreeText = renderToString("chatroom.borrower.disagree");
    onReferralStatusChange(false, disagreeText);
  }, [onReferralStatusChange, renderToString]);

  const onBackdropClick = React.useCallback(() => {}, []);

  return (
    <PopOver isOpen={isOpen} onBackdropClick={onBackdropClick}>
      <div className={styles.popover}>
        <div className={styles.popoverInner}>
          <RichTextView className={styles.confirmMessage} text={title} />
          <IonButton
            mode="ios"
            class={styles.blueButton}
            expand="full"
            onClick={acceptReferralRequest}
          >
            <FormattedMessage id="chatroom.borrower.agree" />
          </IonButton>
          <IonButton
            mode="ios"
            class={styles.transparentButton}
            expand="full"
            onClick={rejectReferralRequest}
          >
            <FormattedMessage id="chatroom.borrower.disagree" />
          </IonButton>
        </div>
      </div>
    </PopOver>
  );
};

interface DirectApplyPopoverProps extends BaseProps {
  onPress: () => void;
}

export const DirectApplyPopover: React.FC<DirectApplyPopoverProps> = props => {
  const { title, isOpen, onClose, onPress } = props;

  const onBackdropClick = React.useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  return (
    <PopOver isOpen={isOpen} onBackdropClick={onBackdropClick}>
      <div className={styles.popover}>
        <div className={styles.popoverInner}>
          <RichTextView className={styles.confirmMessage} text={title} />
          <IonButton
            mode="ios"
            class={styles.blueButton}
            expand="full"
            onClick={onPress}
          >
            <FormattedMessage id="chatroom.direct_apply_popup.open" />
          </IonButton>
        </div>
      </div>
    </PopOver>
  );
};
