import { Chat, Request } from "../models";
import { SimpleChatEvent } from "../context/ChatContext";

export function updateItemListForNewMessage<
  ItemType extends { unreadCount: number | null }
>(e: SimpleChatEvent, picker: (e: SimpleChatEvent, item: ItemType) => boolean) {
  return (items: ItemType[]) => {
    const itemIndexToUpdate = items.findIndex(item => picker(e, item));

    if (itemIndexToUpdate < 0) {
      return items;
    }

    const updatedItems = [...items];
    const [itemToUpdate] = updatedItems.splice(itemIndexToUpdate, 1);

    updatedItems.unshift({
      ...itemToUpdate,
      unreadCount: (itemToUpdate.unreadCount || 0) + 1,
    });

    return updatedItems;
  };
}

export const updateChatListForNewMessage = (e: SimpleChatEvent) =>
  updateItemListForNewMessage<Chat>(
    e,
    (e, item) => e.message.conversationId === item.conversationId
  );

export const updateRequestListForNewMessage = (e: SimpleChatEvent) =>
  updateItemListForNewMessage<Request>(
    e,
    (e, item) => item.conversationIds.indexOf(e.message.conversationId) > -1
  );
