import { BaseApiClient, ApiClientConstructor } from "./client";

import {
  listAgentsResponseSchema,
  UpdateOfferingTemplateResponse,
  updateOfferingTemplateResponseSchema,
} from "../types/response";
import { Company, AgentData, OfferingForm, AgentBriefData } from "../models";
import { agentDataSchema } from "../schemas/user";
import { Page } from "../utils/pagination";

export interface AgentApiClient {
  createAgent: (
    name: string,
    company: Company,
    namecard: File
  ) => Promise<AgentData>;
  updateOfferingTemplate: (
    template: OfferingForm[]
  ) => Promise<UpdateOfferingTemplateResponse>;
  listAgentsForAdminAgent: (
    cursor?: string,
    impersonatorCustomToken?: string
  ) => Promise<Page<AgentBriefData>>;
}

export function withAgentApi<TBase extends ApiClientConstructor<BaseApiClient>>(
  Base: TBase
) {
  return class extends Base {
    createAgent(
      name: string,
      company: Company,
      namecard: File
    ): Promise<AgentData> {
      return this.uploadAsset(namecard).then(namecardId => {
        return this.requestLambda(
          "agent:create",
          {
            name,
            company_id: company.id,
            namecard_id: namecardId,
          },
          agentDataSchema
        );
      });
    }

    updateOfferingTemplate(
      template: OfferingForm[]
    ): Promise<UpdateOfferingTemplateResponse> {
      return this.requestLambda(
        "agent:update-offering-template",
        { offering_template: template },
        updateOfferingTemplateResponseSchema
      );
    }

    listAgentsForAdminAgent(
      cursor?: string,
      impersonatorCustomToken?: string
    ): Promise<Page<AgentBriefData>> {
      const data: any = {};
      if (impersonatorCustomToken) {
        data.impersonator_custom_token = impersonatorCustomToken;
      }
      if (cursor) {
        data.page_info = { cursor };
      }
      return this.requestLambda("agent:list", data, listAgentsResponseSchema);
    }
  };
}
