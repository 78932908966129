import React from "react";
import { IonHeader, IonToolbar, IonPage } from "@ionic/react";
import { FormattedMessage } from "@oursky/react-messageformat";

import styles from "./styles.module.scss";
import { ListView } from "./ListView";

import { MPContent } from "../MPContent";
import { BackButton } from "../BackButton";
import { ListRefreshControl } from "../ListRefreshControl";

import { Chat } from "../../models";
import { Page } from "../../utils/pagination";

interface Props {
  listVersion: number;
  fetchPage: (cursor?: string) => Promise<Page<Chat>>;
  openChatroom: (chat: Chat) => void;
}

export const OfferingListView: React.FC<Props> = props => {
  const { listVersion, fetchPage, openChatroom } = props;
  return (
    <IonPage className={styles.outerContainer}>
      <IonHeader mode="ios" className={styles.header}>
        <IonToolbar mode="ios">
          <div className={styles.back}>
            <BackButton />
          </div>
          <div className={styles.title}>
            <FormattedMessage id="offering.offers_and_offer_requests.viewQuotes" />
          </div>
        </IonToolbar>
      </IonHeader>
      <MPContent>
        <ListRefreshControl listVersion={listVersion} routePrefix="/offering">
          <ListView fetchPage={fetchPage} openChatroom={openChatroom} />
        </ListRefreshControl>
      </MPContent>
    </IonPage>
  );
};
