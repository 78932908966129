import React from "react";
import { IonSpinner } from "@ionic/react";
import { FormattedMessage } from "@oursky/react-messageformat";

import styles from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  messageId?: string;
}

export default class LoadingOverlay extends React.PureComponent<Props> {
  render() {
    const { messageId } = this.props;
    return (
      this.props.isOpen && (
        <div className={styles.overlay}>
          <div className={styles.container}>
            <IonSpinner name="crescent" />
            {messageId && (
              <div>
                <FormattedMessage id={messageId} />
              </div>
            )}
          </div>
        </div>
      )
    );
  }
}
