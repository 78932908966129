import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import React from "react";
import {
  BorrowerListField,
  BorrowerLoanRecordField,
  FieldType,
} from "../../context/CreateRequestContext";

import { BorrowerLoanInfo, ExistingLoan } from "../../models";
import { ValidationError, Validator } from "../../utils/validate";

import { Option } from "../DropdownPicker";
import { FormattedDropdown } from "../FormattedDropdown";
import ErrorField from "../ErrorField";
import YesNoPicker from "../YesNoPicker";
import { FormInputFields, LoanInfoForm } from "./LoanInfoForm";

import styles from "./styles.module.scss";

export type InputFields = "hasMortgage" | "numberOfLoan" | FormInputFields;

interface Props {
  borrower: BorrowerLoanInfo;
  numberOfLoan: number | null;

  selectMortgageStatus: (isEmployed: boolean) => void;
  selectNumberOfLoan?: (loanNumber: number) => void;
  updateExistingLoans: (index: number, value: Partial<ExistingLoan>) => void;
  removeExistingLoan?: (index: number) => void;
  addExistingLoan?: () => void;

  error: ValidationError<InputFields>;
  focusField?: InputFields;
  autoSubmit?: () => void;
}

export class BorrowerLoanRecordForm extends React.PureComponent<Props> {
  loanNumberOptions: Option[] = Array(20)
    .fill(0)
    .map((_, i) => {
      const value = (i + 1).toString();
      return {
        key: value,
        value: value,
        display: value,
      };
    });

  onSelectLoanNumber = (value: string) => {
    if (this.props.selectNumberOfLoan) {
      this.props.selectNumberOfLoan(parseInt(value, 10));
    }
  };

  render() {
    const { focusField, error, numberOfLoan, addExistingLoan } = this.props;
    const { hasMortgage, existingLoans } = this.props.borrower;
    const showAddButton =
      addExistingLoan && hasMortgage && existingLoans.length < 20;

    const loanInfoDisabled = !hasMortgage;

    return (
      <div className={styles.container}>
        <div className={styles.mortgageStatusContainer}>
          <YesNoPicker
            labelId="borrower_loan_info.has_mortgage"
            onClickOption={this.props.selectMortgageStatus}
            value={hasMortgage === null ? undefined : hasMortgage}
            positiveLabelId="borrower_loan_info.has_mortgage.yes"
            negativeLabelId="borrower_loan_info.has_mortgage.no"
            shouldFocus={focusField === "hasMortgage"}
          />
          <ErrorField
            isShown={error["hasMortgage"] !== undefined}
            hiddenType="gone"
            messageId={error["hasMortgage"]}
          />
        </div>

        {this.props.selectNumberOfLoan && (
          <div
            className={classNames(
              styles.fieldContainer,
              styles.numberOfLoanContainer
            )}
            data-anchor="numberOfLoan"
          >
            <div
              className={classNames(styles.fieldTitle, {
                [styles.disabled]: loanInfoDisabled,
              })}
            >
              <FormattedMessage id="borrower_loan_info.current_loan" />
            </div>
            <FormattedDropdown
              disabled={loanInfoDisabled}
              containerClass={styles.dropdownContainer}
              selectClass={styles.dropdownSelect}
              errorClass={styles.dropdownError}
              emptyClass={styles.dropdownEmpty}
              isError={error["numberOfLoan"] !== undefined}
              options={this.loanNumberOptions}
              value={numberOfLoan || ""}
              onValueChange={this.onSelectLoanNumber}
              shouldScrollTo={focusField === "numberOfLoan"}
              scrollAnchor="numberOfLoan"
              placeholderId={"borrower_loan_info.current_loan.placeholder"}
            />
            <ErrorField
              isShown={error["numberOfLoan"] !== undefined}
              hiddenType="gone"
              messageId={error["numberOfLoan"]}
            />
          </div>
        )}
        {existingLoans.map((loan, index) => (
          <LoanInfoForm
            key={index}
            index={index}
            onChange={this.props.updateExistingLoans}
            onRemove={this.props.removeExistingLoan}
            loan={loan}
            error={error}
            focusField={
              (focusField !== "hasMortgage" &&
                focusField !== "numberOfLoan" &&
                focusField) ||
              undefined
            }
          />
        ))}
        {showAddButton && (
          <div className={styles.addButtonContainer}>
            <div onClick={addExistingLoan} className={styles.addButton}>
              <FormattedMessage id="borrower_loan_info_form.add" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

type ValidationFields = Exclude<BorrowerLoanRecordField, BorrowerListField>;
export function getBorrowerLoanRecordFormValidators(
  borrowerLoanInfo: BorrowerLoanInfo,
  numberOfLoan: number | null,
  formDisplayMap?: { [key in ValidationFields]?: FieldType }
): Validator<InputFields>[] {
  const { hasMortgage } = borrowerLoanInfo;
  const validators: Validator<InputFields>[] = [
    {
      field: "hasMortgage",
      invalidCondition: hasMortgage === null,
      errorMessageId: "borrower_loan_info.error.please_select",
      isExistenceCheck: true,
    },
    {
      field: "numberOfLoan",
      invalidCondition: hasMortgage && !numberOfLoan,
      errorMessageId: "borrower_loan_info.error.please_select",
      isExistenceCheck: true,
    },
  ];
  if (formDisplayMap === undefined) {
    return validators;
  }
  const conditionalValidators = validators.reduce<Validator<InputFields>[]>(
    (acc, it) => {
      if (it.field in formDisplayMap) {
        if (it.isExistenceCheck) {
          if (formDisplayMap[it.field as ValidationFields] === "required") {
            acc.push(it);
          }
        } else {
          acc.push(it);
        }
      }
      if (
        it.field === "numberOfLoan" &&
        formDisplayMap["hasMortgage"] === "required"
      ) {
        acc.push(it);
      }
      return acc;
    },
    []
  );
  return conditionalValidators;
}
