import { Plugins, FilesystemDirectory, AppState } from "@capacitor/core";
import { Media } from "capacitor-media";
import { Downloader } from "capacitor-downloader";

import { isPlatform } from "../utils/platform";

const { App, Filesystem } = Plugins;

let appStateChangeHandler: ((state: AppState) => void) | null = null;

App.addListener("appStateChange", (state: AppState) => {
  if (appStateChangeHandler) {
    appStateChangeHandler(state);
  }
});

export async function downloadImageToAlbum(url: string) {
  const downloader = new Downloader();
  const media = new Media();
  const downloadedFileName = "download.png";
  try {
    const destinationURI = await Filesystem.getUri({
      directory: FilesystemDirectory.Data,
      path: downloadedFileName,
    });
    const downloadSession = await downloader.createDownload({
      url,
      path: destinationURI.uri.substring(
        0,
        destinationURI.uri.length - downloadedFileName.length
      ),
      fileName: downloadedFileName,
    });

    await downloader.start({ id: downloadSession.value }, () => {});

    if (isPlatform("ios")) {
      await media.savePhoto({ path: destinationURI.uri });
    } else {
      const saveMediaPromise = createSaveMediaPromise(
        media,
        destinationURI.uri
      );
      const saveResult = await saveMediaPromise;
      if (!saveResult) {
        return await createSaveMediaPromise(media, destinationURI.uri);
      }
    }
  } catch (e) {
    return false;
  }

  return true;
}

function createSaveMediaPromise(media: Media, destination: string) {
  return Promise.race([
    media.savePhoto({ path: destination }).then(() => true),
    new Promise((resolve, _reject) => {
      setAppStateChangeHandler(() => {
        resolve(false);
      });
    }).finally(() => {
      setAppStateChangeHandler(null);
    }),
  ]);
}

function setAppStateChangeHandler(handler: ((state: AppState) => void) | null) {
  appStateChangeHandler = handler;
}

export function downloadImage(url: string) {
  const image = document.createElement("a");
  image.href = url;
  image.download = "";
  image.target = "_blank";
  image.click();
}
