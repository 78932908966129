import React, { FormEventHandler } from "react";
import classNames from "classnames";
import { FormattedMessage } from "@oursky/react-messageformat";

import styles from "./styles.module.scss";

import { PrimaryButton } from "../Button";
import ErrorField from "../ErrorField";
import { MPInput } from "../input";
import {
  MortgageLevelDropdown,
  PremiseTypeDropdown,
  TUGradeDropdown,
  TUOptions,
  YearPeriodDropdown,
} from "../SpecifyDropdown";

import {
  CreateMortgageRequest,
  QuickRequestData,
  RequestType,
} from "../../types/request";
import {
  currencyFormatToNumber,
  formatToCurrencyAmount,
} from "../../utils/formatToCurrencyAmount";
import { validateFormattedAmount } from "../../utils/validation";
import { Validator, validate, ValidationError } from "../../utils/validate";

import { Borrower, District, PremiseType, PropertyStatus } from "../../models";
import { DistrictPicker } from "../DistrictPicker";
import { StaticDistrictOption } from "../../context/OptionsContext";
import { PropertyStatusDropdown } from "../SpecifyDropdown/PropertyStatusDropdown";
import { Option } from "../DropdownPicker";
import { FormattedInput } from "../FormattedInput";
import { FormattedDropdown } from "../FormattedDropdown";
import YesNoPicker from "../YesNoPicker";
import { isStringNullOrEmpty } from "../../utils/string";
import { StorageKey } from "../../utils/storage";
import { RequestMorgageFormValuesToPresist } from "../CreateRequestView/types";

export type InputFields =
  | "flat"
  | "floor"
  | "loanAmount"
  | "mortgageLevel"
  | "marketValue"
  | "monthlyIncome"
  | "currentLoanAmount"
  | "purposingOfMortgage"
  | "nameOfBuilding"
  | "streetNameAndNumber"
  | "district"
  | "period"
  | "block"
  | "premiseType"
  | "propertyStatus"
  | "numOfOwner"
  | "rentIncome"
  | "numOfResident"
  | "filedForBankruptcy"
  | "appliedForIva"
  | "numPersonalLoanForPastTwoWeeks"
  | "numOfLoanEnquiriesInOneMonth"
  | "hasLatePayment"
  | "isTaxReturnFormAvailable";

interface Props {
  borrower?: Borrower;
  quickRequestData?: QuickRequestData;
  latestFormValues?: FormValues;
  defaultFormValues?: FormValues;
  onSubmit: (
    values: CreateMortgageRequest,
    formValuesToPersist: RequestMorgageFormValuesToPresist
  ) => void;
}

export interface FormValues {
  flat: string;
  floor: string;
  loanAmount: string;
  mortgageLevel: string;
  marketValue: string;
  monthlyIncome: string;
  currentLoanAmount: string;
  purposingOfMortgage: string;
  nameOfBuilding: string;
  streetNameAndNumber: string;
  district: string;
  period: string;
  block: string;
  premiseType: PremiseType | null;
  propertyStatus: PropertyStatus | null;
  numOfOwner: number | null;
  rentIncome: number | null;
  numOfResident: number | null;
  filedForBankruptcy: boolean | null;
  appliedForIva: boolean | null;
  numPersonalLoanForPastTwoWeeks: number | null;
  numOfLoanEnquiriesInOneMonth: number | null;
  hasLatePayment: boolean | null;
  isTaxReturnFormAvailable: boolean | null;
  tuGrade: string;
  allowTUCheck: boolean;
}

interface State {
  values: FormValues;
  error: ValidationError<InputFields>;
  focusField?: InputFields;
  nextField?: InputFields;
}

export const defaultState = {
  values: {
    flat: "",
    floor: "",
    block: "",
    loanAmount: "",
    mortgageLevel: "",
    marketValue: "",
    monthlyIncome: "",
    currentLoanAmount: "",
    purposingOfMortgage: "",
    nameOfBuilding: "",
    streetNameAndNumber: "",
    district: "",
    period: "",
    premiseType: null,
    propertyStatus: null,
    numOfOwner: null,
    rentIncome: null,
    numOfResident: null,
    filedForBankruptcy: null,
    appliedForIva: null,
    numPersonalLoanForPastTwoWeeks: null,
    numOfLoanEnquiriesInOneMonth: null,
    hasLatePayment: null,
    isTaxReturnFormAvailable: null,
    tuGrade: TUOptions[0].value,
    allowTUCheck: false,
  },
  error: {},
  nextField: undefined,
};

class RequestMortgageForm extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = this.prefilledDefaultState;
  }

  get prefilledMonthlyIncome() {
    return this.props.borrower && this.props.borrower.salary
      ? this.props.borrower.salary.toString()
      : "";
  }

  get prefilledCurrentLoanAmount() {
    if (!this.props.borrower) {
      return "";
    }
    const amount = this.props.borrower.existingLoans.reduce(
      (prev, loan) => (loan.amount ? prev + loan.amount : prev),
      0
    );
    if (amount) {
      return formatToCurrencyAmount(amount) || "";
    }
    return "";
  }

  get prefilledDefaultState(): State {
    return {
      values: {
        ...defaultState.values,
        monthlyIncome: this.prefilledMonthlyIncome,
        currentLoanAmount: this.prefilledCurrentLoanAmount,
      },
      error: defaultState.error,
    };
  }

  numOfOwnerOptions: Option[] = Array(20)
    .fill(0)
    .map((_, i) => {
      const value = (i + 1).toString();
      return {
        key: value,
        value: value,
        display: value,
      };
    });

  numericOptions: Option[] = Array(21)
    .fill(0)
    .map((_, i) => {
      const value = i.toString();
      return {
        key: value,
        value: value,
        display: value,
      };
    });

  componentDidUpdate(prevProps: Props) {
    this.fillinLatestFormValuesIfChanged(prevProps);
    if (this.state.nextField) {
      this.setState({ nextField: undefined });
    }
  }

  focusDistrictField = () => {
    this.setState({ nextField: "district" });
  };

  fillinLatestFormValuesIfChanged = (prevProps: Props) => {
    const { latestFormValues } = this.props;
    if (!prevProps.latestFormValues && latestFormValues) {
      this.setState({ values: latestFormValues });
    }
  };

  renderAddressInfoSection = () => {
    const { error, values, focusField, nextField } = this.state;

    const selectedDistrict =
      StaticDistrictOption.find(d => d.value === values.district) || null;

    return (
      <>
        <label className={styles.sectionText}>
          <FormattedMessage id="request_offer.mortgage.address_info.label" />
        </label>
        <div className={styles.col}>
          <label className={styles.labelText}>
            <FormattedMessage id="request_offer.mortgage.flat.label" />
          </label>
          <FormattedInput
            className={styles.input}
            value={values.flat}
            onChange={this.handleFlatValueChange}
            isError={error.flat !== undefined}
            autoFocus={true}
            shouldFocus={focusField === "flat"}
            shouldScrollTo={focusField === "flat"}
            scrollAnchor={"flat"}
            placeholderId={"request_offer.mortgage.pleaseFillIn"}
          />
          <ErrorField messageId={error.flat} />

          <label className={styles.labelText}>
            <FormattedMessage id="request_offer.mortgage.block.label" />
          </label>
          <FormattedInput
            className={styles.input}
            value={values.block}
            onChange={this.handleBlockValueChange}
            isError={error.block !== undefined}
            shouldFocus={focusField === "block"}
            shouldScrollTo={focusField === "block"}
            scrollAnchor={"block"}
            placeholderId={"request_offer.mortgage.pleaseFillIn"}
          />
          <ErrorField messageId={error.block} />

          <label className={styles.labelText}>
            <FormattedMessage id="request_offer.mortgage.floor.label" />
          </label>
          <FormattedInput
            className={styles.input}
            value={values.floor}
            onChange={this.handleFloorValueChange}
            isError={error.floor !== undefined}
            shouldFocus={focusField === "floor"}
            shouldScrollTo={focusField === "floor"}
            scrollAnchor={"floor"}
            placeholderId={"request_offer.mortgage.pleaseFillIn"}
          />
          <ErrorField messageId={error.floor} />

          <label className={styles.labelText}>
            <FormattedMessage id="request_offer.mortgage.nameOfBuilding.label" />
          </label>
          <FormattedInput
            className={styles.input}
            value={values.nameOfBuilding}
            onChange={this.handleNameOfBuildingChange}
            isError={error.nameOfBuilding !== undefined}
            shouldFocus={focusField === "nameOfBuilding"}
            shouldScrollTo={focusField === "nameOfBuilding"}
            scrollAnchor={"nameOfBuilding"}
            placeholderId={"request_offer.mortgage.pleaseFillIn"}
          />
          <ErrorField messageId={error.nameOfBuilding} />

          <label className={styles.labelText}>
            <FormattedMessage id="request_offer.mortgage.streetNameAndNumber.label" />
          </label>
          <FormattedInput
            className={styles.input}
            value={values.streetNameAndNumber}
            onChange={this.handleStreetNameAndNumberChange}
            isError={error.streetNameAndNumber !== undefined}
            shouldFocus={focusField === "streetNameAndNumber"}
            shouldScrollTo={focusField === "streetNameAndNumber"}
            scrollAnchor={"streetNameAndNumber"}
            placeholderId={"request_offer.mortgage.pleaseFillIn"}
            autoSubmit={this.focusDistrictField}
          />
          <ErrorField messageId={error.streetNameAndNumber} />

          <label className={styles.labelText}>
            <FormattedMessage id="request_offer.mortgage.district.label" />
          </label>
          <DistrictPicker
            isError={error.district !== undefined}
            value={selectedDistrict}
            onChange={this.handleDistrictChange}
            shouldFocus={focusField === "district" || nextField === "district"}
            shouldScrollTo={focusField === "district"}
            scrollAnchor={"district"}
          />
          <ErrorField messageId={error.district} />

          <label className={styles.labelText}>
            <FormattedMessage id="request_offer.mortgage.premiseType.label" />
          </label>
          <PremiseTypeDropdown
            containerClass={styles.dropdownContainer}
            selectClass={styles.dropdownSelect}
            emptyClass={styles.dropdownEmpty}
            errorClass={styles.dropdownError}
            placeholderId="request_offer.mortgage.pleaseFillIn"
            isError={error.premiseType !== undefined}
            value={values.premiseType || ""}
            onValueChange={this.handlePremiseTypeChange}
            shouldFocus={focusField === "premiseType"}
            shouldScrollTo={focusField === "premiseType"}
            scrollAnchor={"premiseType"}
          />
          <ErrorField messageId={error.premiseType} />

          <label className={styles.labelText}>
            <FormattedMessage id="request_offer.mortgage.propertyStatus.label" />
          </label>
          <PropertyStatusDropdown
            containerClass={styles.dropdownContainer}
            selectClass={styles.dropdownSelect}
            emptyClass={styles.dropdownEmpty}
            errorClass={styles.dropdownError}
            placeholderId="request_offer.mortgage.pleaseFillIn"
            isError={error.propertyStatus !== undefined}
            value={values.propertyStatus || ""}
            onValueChange={this.handlePropertyStatusChange}
            shouldFocus={focusField === "propertyStatus"}
            shouldScrollTo={focusField === "propertyStatus"}
            scrollAnchor={"propertyStatus"}
          />
          <ErrorField messageId={error.propertyStatus} />

          <label className={styles.labelText}>
            <FormattedMessage id="request_offer.mortgage.numOfOwner.label" />
          </label>
          <FormattedDropdown
            containerClass={styles.dropdownContainer}
            selectClass={styles.dropdownSelect}
            errorClass={styles.dropdownError}
            emptyClass={styles.dropdownEmpty}
            isError={error.numOfOwner !== undefined}
            options={this.numOfOwnerOptions}
            value={values.numOfOwner || ""}
            onValueChange={this.handleNumOfOwnerChange}
            shouldFocus={focusField === "numOfOwner"}
            shouldScrollTo={focusField === "numOfOwner"}
            scrollAnchor="numOfOwner"
            placeholderId={"request_offer.mortgage.pleaseFillIn"}
          />
          <ErrorField messageId={error.numOfOwner} />

          <label className={styles.labelText}>
            <FormattedMessage id="request_offer.mortgage.rentIncome.label" />
          </label>
          <FormattedInput
            className={styles.input}
            type="tel"
            maxLength={15}
            value={formatToCurrencyAmount(values.rentIncome) || ""}
            onChange={this.handleRentIncomeChange}
            isError={error.rentIncome !== undefined}
            shouldFocus={focusField === "rentIncome"}
            shouldScrollTo={focusField === "rentIncome"}
            scrollAnchor={"rentIncome"}
            placeholderId={"request_offer.mortgage.pleaseFillIn"}
          />
          <ErrorField messageId={error.rentIncome} />

          <label className={styles.labelText}>
            <FormattedMessage id="request_offer.mortgage.numOfResident.label" />
          </label>
          <FormattedDropdown
            containerClass={styles.dropdownContainer}
            selectClass={styles.dropdownSelect}
            errorClass={styles.dropdownError}
            emptyClass={styles.dropdownEmpty}
            isError={error.numOfResident !== undefined}
            options={this.numericOptions}
            value={values.numOfResident !== null ? values.numOfResident : ""}
            onValueChange={this.handleNumOfResidentChange}
            shouldFocus={focusField === "numOfResident"}
            shouldScrollTo={focusField === "numOfResident"}
            scrollAnchor="numOfResident"
            placeholderId={"request_offer.mortgage.pleaseFillIn"}
          />
          <ErrorField messageId={error.numOfResident} />

          <label className={styles.labelText}>
            <FormattedMessage id="request_offer.mortgage.market_value.label" />
          </label>
          <MPInput
            className={styles.input}
            value={values.marketValue}
            onChange={this.handleMarketValueChange}
            placeholder="HKD"
            inputMode="tel"
            type="tel"
            isError={error.marketValue !== undefined}
            shouldFocus={focusField === "marketValue"}
            shouldScrollTo={focusField === "marketValue"}
            scrollAnchor={"marketValue"}
          />
          <ErrorField messageId={error.marketValue} />
        </div>
      </>
    );
  };

  renderOfferInfoSection = () => {
    const { error, values, focusField } = this.state;
    return (
      <>
        <label className={styles.sectionText}>
          <FormattedMessage id="request_offer.mortgage.offer_info.label" />
        </label>
        <div className={styles.col}>
          <label className={styles.labelText}>
            <FormattedMessage id="request_offer.mortgage.purposingOfMortgage.label" />
          </label>
          <FormattedInput
            className={styles.input}
            value={values.purposingOfMortgage}
            onChange={this.handlePurposingOfMortgageChange}
            isError={error.purposingOfMortgage !== undefined}
            shouldFocus={focusField === "purposingOfMortgage"}
            shouldScrollTo={focusField === "purposingOfMortgage"}
            scrollAnchor={"purposingOfMortgage"}
            placeholderId={"request_offer.mortgage.pleaseFillIn"}
          />
          <ErrorField messageId={error.purposingOfMortgage} />
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <label className={styles.labelText}>
              <FormattedMessage id="request_offer.mortgage.loan_amount.label" />
            </label>
            <div className={styles.loanAmount}>
              <MPInput
                className={styles.input}
                value={values.loanAmount}
                onChange={this.handleLoanAmountChange}
                placeholder="HKD"
                inputMode="tel"
                type="tel"
                isError={error.loanAmount !== undefined}
                shouldFocus={focusField === "loanAmount"}
              />
            </div>
            <ErrorField messageId={error.loanAmount} />
          </div>
          <div className={styles.colRight}>
            <label className={styles.labelText}>
              <FormattedMessage id="request_offer.mortgage.mortgage_level.label" />
            </label>
            <MortgageLevelDropdown
              containerClass={styles.dropdownContainer}
              selectClass={styles.dropdownSelect}
              errorClass={styles.dropdownError}
              emptyClass={styles.dropdownEmpty}
              value={values.mortgageLevel}
              isError={error.mortgageLevel !== undefined}
              onValueChange={this.handleMortgageLevelChange}
            />
            <ErrorField messageId={error.mortgageLevel} />
          </div>
        </div>
        <div className={styles.col}>
          <label className={styles.labelText} htmlFor="period">
            <FormattedMessage id="request_offer.mortgage.period.label" />
          </label>
          <YearPeriodDropdown
            id="period"
            containerClass={styles.dropdownContainer}
            selectClass={styles.dropdownSelect}
            errorClass={styles.dropdownError}
            emptyClass={styles.dropdownEmpty}
            placeholderId="request_offer.mortgage.pleaseFillIn"
            value={values.period || ""}
            isError={error.period !== undefined}
            onValueChange={this.handlePeriodChange}
            shouldFocus={focusField === "period"}
            shouldScrollTo={focusField === "period"}
            scrollAnchor="period"
          />
          <ErrorField messageId={error.period} />
        </div>
      </>
    );
  };

  renderFinancialSection = () => {
    const { values, error, focusField } = this.state;
    return (
      <>
        <label className={styles.sectionText}>
          <FormattedMessage id="request_offer.mortgage.financial_position.label" />
        </label>
        <div className={styles.col}>
          <label className={styles.labelText}>
            <FormattedMessage id="request_offer.mortgage.monthly_income.label" />
          </label>
          <MPInput
            className={styles.input}
            value={values.monthlyIncome}
            onChange={this.handleMonthlyIncomeChange}
            placeholder="HKD"
            inputMode="tel"
            type="tel"
            shouldFocus={focusField === "monthlyIncome"}
          />
          <ErrorField messageId={error.monthlyIncome} />

          <div className={styles.yesNoPickerContainer}>
            <YesNoPicker
              labelId="request_offer.mortgage.filedForBankruptcy.label"
              labelClass={styles.yesNoPickerLabelText}
              onClickOption={this.handleFiledForBankruptcyChange}
              value={
                values.filedForBankruptcy === null
                  ? undefined
                  : values.filedForBankruptcy
              }
              shouldFocus={focusField === "filedForBankruptcy"}
            />
          </div>
          <ErrorField messageId={error.filedForBankruptcy} />

          <div className={styles.yesNoPickerContainer}>
            <YesNoPicker
              labelId="request_offer.mortgage.appliedForIva.label"
              labelClass={styles.yesNoPickerLabelText}
              onClickOption={this.handleAppliedForIvaChange}
              value={
                values.appliedForIva === null ? undefined : values.appliedForIva
              }
              shouldFocus={focusField === "appliedForIva"}
            />
          </div>
          <ErrorField messageId={error.appliedForIva} />

          <label className={styles.labelText}>
            <FormattedMessage id="request_offer.mortgage.numPersonalLoanForPastTwoWeeks.label" />
          </label>
          <FormattedDropdown
            containerClass={styles.dropdownContainer}
            selectClass={styles.dropdownSelect}
            errorClass={styles.dropdownError}
            emptyClass={styles.dropdownEmpty}
            isError={error.numPersonalLoanForPastTwoWeeks !== undefined}
            options={this.numericOptions}
            value={
              values.numPersonalLoanForPastTwoWeeks !== null
                ? values.numPersonalLoanForPastTwoWeeks
                : ""
            }
            onValueChange={this.handleNumPersonalLoanForPastTwoWeeksChange}
            shouldFocus={focusField === "numPersonalLoanForPastTwoWeeks"}
            shouldScrollTo={focusField === "numPersonalLoanForPastTwoWeeks"}
            scrollAnchor={"numPersonalLoanForPastTwoWeeks"}
            placeholderId={"request_offer.mortgage.pleaseFillIn"}
          />
          <ErrorField messageId={error.numPersonalLoanForPastTwoWeeks} />

          <label className={styles.labelText}>
            <FormattedMessage id="request_offer.mortgage.numOfLoanEnquiriesInOneMonth.label" />
          </label>
          <FormattedDropdown
            containerClass={styles.dropdownContainer}
            selectClass={styles.dropdownSelect}
            errorClass={styles.dropdownError}
            emptyClass={styles.dropdownEmpty}
            isError={error.numOfLoanEnquiriesInOneMonth !== undefined}
            options={this.numericOptions}
            value={
              values.numOfLoanEnquiriesInOneMonth !== null
                ? values.numOfLoanEnquiriesInOneMonth
                : ""
            }
            onValueChange={this.handleNumOfLoanEnquiriesInOneMonthChange}
            shouldFocus={focusField === "numOfLoanEnquiriesInOneMonth"}
            shouldScrollTo={focusField === "numOfLoanEnquiriesInOneMonth"}
            scrollAnchor={"numOfLoanEnquiriesInOneMonth"}
            placeholderId={"request_offer.mortgage.pleaseFillIn"}
          />
          <ErrorField messageId={error.numOfLoanEnquiriesInOneMonth} />

          <div className={styles.yesNoPickerContainer}>
            <YesNoPicker
              labelId="request_offer.mortgage.hasLatePayment.label"
              labelClass={styles.yesNoPickerLabelText}
              onClickOption={this.handleHasLatePaymentChange}
              value={
                values.hasLatePayment === null
                  ? undefined
                  : values.hasLatePayment
              }
              shouldFocus={focusField === "hasLatePayment"}
            />
          </div>
          <ErrorField messageId={error.hasLatePayment} />

          <label className={styles.labelText}>
            <FormattedMessage id="request_offer.mortgage.outstanding_loan_amount.label" />
          </label>
          <MPInput
            className={styles.input}
            value={values.currentLoanAmount}
            onChange={this.handleCurrentLoanAmountChange}
            placeholder="HKD"
            inputMode="tel"
            type="tel"
            shouldFocus={focusField === "currentLoanAmount"}
            shouldScrollTo={focusField === "currentLoanAmount"}
            scrollAnchor={"currentLoanAmount"}
          />
          <ErrorField messageId={error.currentLoanAmount} />

          <div className={styles.yesNoPickerContainer}>
            <YesNoPicker
              labelId="request_offer.mortgage.isTaxReturnFormAvailable.label"
              labelClass={styles.yesNoPickerLabelText}
              onClickOption={this.handleIsTaxReturnFormAvailableChange}
              value={
                values.isTaxReturnFormAvailable === null
                  ? undefined
                  : values.isTaxReturnFormAvailable
              }
              shouldFocus={focusField === "isTaxReturnFormAvailable"}
            />
          </div>
          <ErrorField messageId={error.isTaxReturnFormAvailable} />

          <label className={classNames(styles.sectionText, styles.tuSection)}>
            <FormattedMessage id="request_offer.mortgage.has_tu_grade.label" />
          </label>
          <TUGradeDropdown
            containerClass={styles.dropdownContainer}
            selectClass={styles.dropdownSelect}
            errorClass={styles.dropdownError}
            emptyClass={styles.dropdownEmpty}
            value={values.tuGrade}
            isError={false}
            onValueChange={this.handleTUGradeChange}
          />

          <div
            className={styles.checkboxContainer}
            onClick={this.toggleAllowTUCheck}
          >
            <div
              className={classNames(styles.checkbox, {
                [styles.checked]: values.allowTUCheck,
              })}
            />
            <div>
              <FormattedMessage id="request_offer.allow_tu_check" />
            </div>
          </div>
        </div>
      </>
    );
  };

  render() {
    return (
      <form noValidate={true} onSubmit={this.handleSubmit}>
        <div className={styles.formContainer}>
          {this.renderAddressInfoSection()}
          {this.renderOfferInfoSection()}
          {this.renderFinancialSection()}
        </div>

        <PrimaryButton class={styles.submitButton} type="submit" expand="full">
          <FormattedMessage id="request_offer.submit" />
        </PrimaryButton>
      </form>
    );
  }

  handleCurrentLoanAmountChange: React.ChangeEventHandler<
    HTMLInputElement
  > = e => {
    const currentLoanAmount = e.currentTarget.value || "";
    const formattedAmount = formatToCurrencyAmount(currentLoanAmount);

    if (formattedAmount !== null && validateFormattedAmount(formattedAmount)) {
      this.setState(({ values }) => {
        return {
          values: { ...values, currentLoanAmount: formattedAmount },
        };
      });
    }
  };

  handleFlatValueChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const value = e.currentTarget.value || "";

    this.setState(({ values }) => {
      return {
        values: { ...values, flat: value },
      };
    });
  };

  handleBlockValueChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const value = e.currentTarget.value || "";

    this.setState(({ values }) => {
      return {
        values: { ...values, block: value },
      };
    });
  };

  handleFloorValueChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const value = e.currentTarget.value || "";

    this.setState(({ values }) => {
      return {
        values: { ...values, floor: value },
      };
    });
  };

  handleNameOfBuildingChange: React.ChangeEventHandler<
    HTMLInputElement
  > = e => {
    const nameOfBuilding = e.currentTarget.value || "";

    this.setState(({ values }) => {
      return {
        values: { ...values, nameOfBuilding },
      };
    });
  };

  handleStreetNameAndNumberChange: React.ChangeEventHandler<
    HTMLInputElement
  > = e => {
    const streetNameAndNumber = e.currentTarget.value || "";

    this.setState(({ values }) => {
      return {
        values: { ...values, streetNameAndNumber },
      };
    });
  };

  handleDistrictChange = (district: District) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, district: district.value },
      };
    });
  };

  handlePremiseTypeChange = (premiseType: string) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, premiseType: premiseType as PremiseType },
      };
    });
  };

  handlePropertyStatusChange = (propertyStatus: string) => {
    this.setState(({ values }) => {
      return {
        values: {
          ...values,
          propertyStatus: propertyStatus as PropertyStatus,
          ...(propertyStatus === PropertyStatus.whollyOwned &&
            !values.numOfOwner && {
              numOfOwner: 1,
            }),
        },
      };
    });
  };

  handleNumOfOwnerChange = (value: string) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, numOfOwner: parseInt(value, 10) },
      };
    });
  };

  handleRentIncomeChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const rentIncome = currencyFormatToNumber(e.currentTarget.value || "");
    this.setState(({ values }) => {
      return {
        values: {
          ...values,
          rentIncome,
        },
      };
    });
  };

  handleNumOfResidentChange = (value: string) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, numOfResident: parseInt(value, 10) },
      };
    });
  };

  handleMarketValueChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const marketValue = e.currentTarget.value || "";
    const formattedAmount = formatToCurrencyAmount(marketValue);

    if (formattedAmount !== null && validateFormattedAmount(formattedAmount)) {
      this.setState(({ values }) => {
        return {
          values: { ...values, marketValue: formattedAmount },
        };
      });
    }
  };

  handlePurposingOfMortgageChange: React.ChangeEventHandler<
    HTMLInputElement
  > = e => {
    const purposingOfMortgage = e.currentTarget.value || "";

    this.setState(({ values }) => {
      return {
        values: { ...values, purposingOfMortgage },
      };
    });
  };

  handleMonthlyIncomeChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const monthlyIncome = e.currentTarget.value || "";
    const formattedAmount = formatToCurrencyAmount(monthlyIncome);

    if (formattedAmount !== null && validateFormattedAmount(formattedAmount)) {
      this.setState(({ values }) => {
        return {
          values: { ...values, monthlyIncome: formattedAmount },
        };
      });
    }
  };

  handleMortgageLevelChange = (level: string) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, mortgageLevel: level },
      };
    });
  };

  handleLoanAmountChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const amount = e.currentTarget.value || "";
    const formattedAmount = formatToCurrencyAmount(amount);

    if (formattedAmount !== null && validateFormattedAmount(formattedAmount)) {
      this.setState(({ values }) => {
        return {
          values: { ...values, loanAmount: formattedAmount },
        };
      });
    }
  };

  handlePeriodChange = (period: string) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, period },
      };
    });
  };

  handleFiledForBankruptcyChange = (filedForBankruptcy: boolean) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, filedForBankruptcy },
      };
    });
  };

  handleAppliedForIvaChange = (appliedForIva: boolean) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, appliedForIva },
      };
    });
  };

  handleNumPersonalLoanForPastTwoWeeksChange = (value: string) => {
    this.setState(({ values }) => {
      return {
        values: {
          ...values,
          numPersonalLoanForPastTwoWeeks: parseInt(value, 10),
        },
      };
    });
  };

  handleNumOfLoanEnquiriesInOneMonthChange = (value: string) => {
    this.setState(({ values }) => {
      return {
        values: {
          ...values,
          numOfLoanEnquiriesInOneMonth: parseInt(value, 10),
        },
      };
    });
  };

  handleHasLatePaymentChange = (hasLatePayment: boolean) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, hasLatePayment },
      };
    });
  };

  handleIsTaxReturnFormAvailableChange = (
    isTaxReturnFormAvailable: boolean
  ) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, isTaxReturnFormAvailable },
      };
    });
  };

  handleSubmit: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();

    this.setState({ focusField: undefined }, () => {
      const { values } = this.state;

      if (!this.validateRequestData(values)) return;

      const formattedValues: CreateMortgageRequest = {
        type: RequestType.Mortgage,
        flat: values.flat,
        floor: values.floor,
        loanAmount: currencyFormatToNumber(values.loanAmount),
        mortgageLevel: values.mortgageLevel,
        marketValue: currencyFormatToNumber(values.marketValue),
        monthlyIncome: currencyFormatToNumber(values.monthlyIncome),
        currentLoanAmount: currencyFormatToNumber(values.currentLoanAmount),
        purposingOfMortgage: values.purposingOfMortgage,
        nameOfBuilding: values.nameOfBuilding,
        streetNameAndNumber: values.streetNameAndNumber,
        district: values.district,
        period: parseInt(values.period, 10),
        block: values.block,
        premiseType: values.premiseType,
        propertyStatus: values.propertyStatus,
        numOfOwner: values.numOfOwner,
        rentIncome: values.rentIncome,
        numOfResident: values.numOfResident,
        filedForBankruptcy: values.filedForBankruptcy,
        appliedForIva: values.appliedForIva,
        numPersonalLoanForPastTwoWeeks: values.numPersonalLoanForPastTwoWeeks,
        numOfLoanEnquiriesInOneMonth: values.numOfLoanEnquiriesInOneMonth,
        hasLatePayment: values.hasLatePayment,
        isTaxReturnFormAvailable: values.isTaxReturnFormAvailable,
        tuGrade: values.tuGrade || null,
        allowTUCheck: values.allowTUCheck,
      };

      this.props.onSubmit(formattedValues, {
        storageKey: StorageKey.RequestMortgageForm,
        formValues: values,
      });
    });
  };

  handleTUGradeChange = (tu: string) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, tuGrade: tu },
      };
    });
  };

  toggleAllowTUCheck = () => {
    this.setState(({ values }) => ({
      values: {
        ...values,
        allowTUCheck: !values.allowTUCheck,
      },
    }));
  };

  validateRequestData(values: FormValues) {
    const {
      flat,
      floor,
      loanAmount,
      mortgageLevel,
      marketValue,
      monthlyIncome,
      currentLoanAmount,
      purposingOfMortgage,
      nameOfBuilding,
      streetNameAndNumber,
      district,
      period,
      block,
      premiseType,
      propertyStatus,
      numOfOwner,
      rentIncome,
      numOfResident,
      filedForBankruptcy,
      appliedForIva,
      numPersonalLoanForPastTwoWeeks,
      numOfLoanEnquiriesInOneMonth,
      hasLatePayment,
      isTaxReturnFormAvailable,
    } = values;

    const validators: Validator<InputFields>[] = [
      {
        field: "flat",
        invalidCondition: isStringNullOrEmpty(flat),
        errorMessageId: "request_offer.mortgage.flat.error.required",
      },
      {
        field: "block",
        invalidCondition: isStringNullOrEmpty(block),
        errorMessageId: "request_offer.mortgage.block.error.required",
      },
      {
        field: "floor",
        invalidCondition: isStringNullOrEmpty(floor),
        errorMessageId: "request_offer.mortgage.floor.error.required",
      },
      {
        field: "nameOfBuilding",
        invalidCondition: isStringNullOrEmpty(nameOfBuilding),
        errorMessageId: "request_offer.mortgage.nameOfBuilding.error.required",
      },
      {
        field: "streetNameAndNumber",
        invalidCondition: isStringNullOrEmpty(streetNameAndNumber),
        errorMessageId:
          "request_offer.mortgage.streetNameAndNumber.error.required",
      },
      {
        field: "district",
        invalidCondition: isStringNullOrEmpty(district),
        errorMessageId: "request_offer.mortgage.district.error.required",
      },
      {
        field: "premiseType",
        invalidCondition: premiseType === null,
        errorMessageId: "request_offer.mortgage.premiseType.error.required",
      },
      {
        field: "propertyStatus",
        invalidCondition: propertyStatus === null,
        errorMessageId: "request_offer.mortgage.propertyStatus.error.required",
      },
      {
        field: "numOfOwner",
        invalidCondition:
          propertyStatus !== null &&
          propertyStatus === PropertyStatus.jointlyOwned &&
          numOfOwner === null,
        errorMessageId: "request_offer.mortgage.numOfOwner.error.required",
      },
      {
        field: "rentIncome",
        invalidCondition:
          propertyStatus !== null &&
          propertyStatus === PropertyStatus.leased &&
          rentIncome === null,
        errorMessageId: "request_offer.mortgage.rentIncome.error.required",
      },
      {
        field: "rentIncome",
        invalidCondition:
          propertyStatus === PropertyStatus.leased &&
          rentIncome !== null &&
          rentIncome <= 0,
        errorMessageId:
          "request_offer.mortgage.rentIncome.error.negative_or_zero",
      },
      {
        field: "numOfResident",
        invalidCondition: numOfResident === null,
        errorMessageId: "request_offer.mortgage.numOfResident.error.required",
      },
      {
        field: "marketValue",
        invalidCondition: !marketValue,
        errorMessageId: "request_offer.mortgage.market_value.error.required",
      },
      {
        field: "marketValue",
        invalidCondition:
          marketValue !== "" && currencyFormatToNumber(marketValue) <= 0,
        errorMessageId:
          "request_offer.mortgage.market_value.error.negative_or_zero",
      },
      {
        field: "purposingOfMortgage",
        invalidCondition: isStringNullOrEmpty(purposingOfMortgage),
        errorMessageId:
          "request_offer.mortgage.purposingOfMortgage.error.required",
      },
      {
        field: "loanAmount",
        invalidCondition: !loanAmount,
        errorMessageId: "request_offer.mortgage.error.required.loan_amount",
      },
      {
        field: "loanAmount",
        invalidCondition:
          loanAmount !== "" && currencyFormatToNumber(loanAmount) <= 0,
        errorMessageId:
          "request_offer.mortgage.error.negative_or_error.loan_amount",
      },
      {
        field: "mortgageLevel",
        invalidCondition: mortgageLevel === "",
        errorMessageId: "request_offer.mortgage.error.required.mortgage_level",
      },
      {
        field: "period",
        invalidCondition: isStringNullOrEmpty(period),
        errorMessageId: "request_offer.mortgage.period.error.required",
      },
      {
        field: "monthlyIncome",
        invalidCondition:
          monthlyIncome !== "" && currencyFormatToNumber(monthlyIncome) <= 0,
        errorMessageId: "request_offer.monthlyIncome.error.negative_or_zero",
      },
      {
        field: "filedForBankruptcy",
        invalidCondition: filedForBankruptcy === null,
        errorMessageId:
          "request_offer.mortgage.filedForBankruptcy.error.required",
      },
      {
        field: "appliedForIva",
        invalidCondition: appliedForIva === null,
        errorMessageId: "request_offer.mortgage.appliedForIva.error.required",
      },
      {
        field: "numPersonalLoanForPastTwoWeeks",
        invalidCondition: numPersonalLoanForPastTwoWeeks === null,
        errorMessageId:
          "request_offer.mortgage.numPersonalLoanForPastTwoWeeks.error.required",
      },
      {
        field: "numOfLoanEnquiriesInOneMonth",
        invalidCondition: numOfLoanEnquiriesInOneMonth === null,
        errorMessageId:
          "request_offer.mortgage.numOfLoanEnquiriesInOneMonth.error.required",
      },
      {
        field: "hasLatePayment",
        invalidCondition: hasLatePayment === null,
        errorMessageId: "request_offer.mortgage.hasLatePayment.error.required",
      },
      {
        field: "currentLoanAmount",
        invalidCondition:
          currentLoanAmount !== "" &&
          currencyFormatToNumber(currentLoanAmount) <= 0,
        errorMessageId:
          "request_offer.currentLoanAmount.error.negative_or_zero",
      },
      {
        field: "isTaxReturnFormAvailable",
        invalidCondition: isTaxReturnFormAvailable === null,
        errorMessageId:
          "request_offer.mortgage.isTaxReturnFormAvailable.error.required",
      },
    ];

    const { focusField, error, isValid } = validate(validators);

    this.setState({ error, focusField });

    return isValid;
  }
}

export default RequestMortgageForm;
