export function RateToNumber(value: string): number {
  if (value === "") {
    return 0;
  }
  let amount = Number(value);
  amount /= 100;
  if (isNaN(amount)) {
    return 0;
  }
  return amount;
}
