import skygear from "skygear";
import * as yup from "yup";

import { parseError, APIError, APIErrorCode } from "../error";

export class BaseApiClient {
  requestLambda<R>(
    action: string,
    data: any,
    respSchema: yup.Schema<R> = yup.mixed(),
    ignoreStatus = false
  ): Promise<R> {
    const args: any[] = data instanceof Array ? data : [data ? data : {}];
    return skygear.lambda(action, args).then(
      resp => {
        return this.parseResp(respSchema, resp, ignoreStatus);
      },
      e => {
        throw parseError(e);
      }
    );
  }

  parseResp<R>(
    respSchema: yup.Schema<R>,
    raw: any,
    ignoreStatus: boolean
  ): Promise<R> {
    const status: any = raw.status;

    if (ignoreStatus) {
      return respSchema.validate(raw);
    }

    if (status === "ok") {
      return respSchema.validate(raw);
    }

    if (status === "failed") {
      return Promise.reject(parseError(raw.error));
    }

    return Promise.reject(
      new APIError(
        APIErrorCode.unexpectedLambdaResponse,
        "Unexpected lambda response",
        raw
      )
    );
  }

  uploadAsset(file: File): Promise<string> {
    const asset = new skygear.Asset({
      name: file.name,
      file: file,
    });
    return skygear.publicDB.uploadAsset(asset).then(asset => {
      return asset.name;
    });
  }
}

export type ApiClientConstructor<T extends BaseApiClient> = new (
  ...args: any[]
) => T;
