import React from "react";

import {
  FormattedDropdown,
  FormattedDropdownProps,
  Option as FormattedDPOption,
} from "../FormattedDropdown";

import { RequestType } from "../../models";
import { Omit } from "../../utils/typeutils";

export type RequestTypeDropdownProps = Omit<FormattedDropdownProps, "options">;

const RequestOptions: FormattedDPOption[] = [
  {
    key: RequestType.personalLoan,
    value: RequestType.personalLoan,
    labelId: "request_offer.type.loan",
  },
  {
    key: RequestType.mortgage,
    value: RequestType.mortgage,
    labelId: "request_offer.type.mortgage",
  },
  {
    key: RequestType.exchangeLoan,
    value: RequestType.exchangeLoan,
    labelId: "request_offer.type.exchange",
  },
];

export const RequestTypeDropdown: React.FC<
  RequestTypeDropdownProps
> = props => {
  return <FormattedDropdown options={RequestOptions} {...props as any} />;
};
