import { DateTime } from "luxon";
import { SKYGEAR_ENDPOINT } from "../config";
import { StaticPageType, StaticPageOption } from "../types/misc";

export const getComputedStyleValue = (
  element: HTMLElement,
  property: string
): number => {
  return parseInt(
    window.getComputedStyle(element).getPropertyValue(property),
    10
  );
};

export const getAbsoluteTop = (e: HTMLElement): number => {
  if (e.offsetParent !== null) {
    return e.offsetTop + getAbsoluteTop(e.offsetParent as HTMLElement);
  }
  return e.offsetTop;
};

export const getOuterHeight = (element: HTMLElement) => {
  return (
    element.offsetHeight +
    getComputedStyleValue(element, "margin-top") +
    getComputedStyleValue(element, "margin-bottom")
  );
};

export function getAge(birthday: DateTime): number {
  const diff = DateTime.local().diff(birthday, ["years"]);

  return diff.years;
}

export function getStaticPageUrl(key: StaticPageType) {
  let endPoint = SKYGEAR_ENDPOINT;
  if (endPoint[endPoint.length - 1] !== "/") {
    endPoint += "/";
  }

  return `${endPoint}static-page/${key}`;
}

export function getStaticPageOptions(): StaticPageOption[] {
  return [
    {
      labelId: "account.option.terms_and_conditions",
      type: "terms_and_conditions",
    },
    {
      labelId: "account.option.instruction",
      type: "support",
    },
    {
      labelId: "account.option.about",
      type: "about",
    },
    {
      labelId: "account.option.feedback",
      type: "feedback",
    },
  ];
}

export async function spendAtLeast<T>(
  task: () => Promise<T>,
  atLeast: number
): Promise<T> {
  const startTime = new Date();
  const result = await task();

  const elapse: number = (new Date() as any) - (startTime as any);
  if (elapse > atLeast) {
    return result;
  }

  await new Promise((resolve, _) => {
    setTimeout(resolve, atLeast - elapse);
  });

  return result;
}
