export interface AgentImpersonationState {
  currentUserCustomToken: string | null;
  isImpersonatingAgent: boolean;
  agentImpersonatorAccessToken: string | null;
}

export const defaultAgentImpersonationState: Readonly<
  AgentImpersonationState
> = {
  currentUserCustomToken: null,
  isImpersonatingAgent: false,
  agentImpersonatorAccessToken: null,
};
