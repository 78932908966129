import classNames from "classnames";
import React, { ChangeEvent } from "react";

import { Omit } from "../../utils/typeutils";

import styles from "./ClearableInput.module.scss";
import { Input, InputProps } from "./Input";

interface OwnProps {
  shouldFocus?: boolean;
  autoSubmit?: () => void;
  isError?: boolean;
  filter?: RegExp;
  onChange?: (value: string) => void;
}

export type ClearableInputProps = Omit<InputProps, "onChange"> & OwnProps;

export class ClearableInput extends React.PureComponent<ClearableInputProps> {
  onClickCleanButton = () => {
    if (this.props.onChange) {
      this.props.onChange("");
    }
  };

  onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { filter } = this.props;

    const value = filter
      ? e.target.value.replace(filter, "") || ""
      : e.target.value || "";

    if (this.props.onChange) {
      this.props.onChange(value);
    }
  };

  renderCleanButton = () => {
    if (this.props.value === "" || this.props.disabled) {
      return null;
    }

    return (
      <div
        className={styles.cleanButtonContainer}
        onClick={this.onClickCleanButton}
      />
    );
  };

  render() {
    const { isError, className, ...props } = this.props;
    return (
      <div
        className={classNames(styles.container, className, {
          [styles.disabled]: props.disabled,
          [styles.errored]: isError && !props.disabled,
        })}
      >
        <Input
          className={styles.input}
          {...props}
          onChange={this.onInputChange}
        />
        {this.renderCleanButton()}
      </div>
    );
  }
}
