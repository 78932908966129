import React from "react";

import {
  FormattedDropdown,
  FormattedDropdownProps,
  Option as FormattedOption,
} from "../FormattedDropdown";

import { Omit } from "../../utils/typeutils";
import { PropertyStatus } from "../../models";

export type PropertyStatusDropdownProps = Omit<
  FormattedDropdownProps,
  "options"
>;

export const PropertyStatusOptions: FormattedOption[] = [
  {
    key: PropertyStatus.familyOwned,
    value: PropertyStatus.familyOwned,
    labelId: "propertyStatus.family_owned",
  },
  {
    key: PropertyStatus.whollyOwned,
    value: PropertyStatus.whollyOwned,
    labelId: "propertyStatus.wholly_owned",
  },
  {
    key: PropertyStatus.jointlyOwned,
    value: PropertyStatus.jointlyOwned,
    labelId: "propertyStatus.jointly_owned",
  },
  {
    key: PropertyStatus.leased,
    value: PropertyStatus.leased,
    labelId: "propertyStatus.leased",
  },
  {
    key: PropertyStatus.ownedByTheCompany,
    value: PropertyStatus.ownedByTheCompany,
    labelId: "propertyStatus.owned_by_the_company",
  },
];

export const PropertyStatusDropdown: React.FC<
  PropertyStatusDropdownProps
> = props => {
  return (
    <FormattedDropdown options={PropertyStatusOptions} {...props as any} />
  );
};
