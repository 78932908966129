import { RefresherEventDetail } from "@ionic/core";
import {
  IonList,
  IonListHeader,
  IonSpinner,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react";
import {
  FormattedMessage,
  FormattedMessageProps,
  Values,
} from "@oursky/react-messageformat";
import React, { PureComponent } from "react";

import { InfiniteScroll } from "./InfiniteScroll";
import { parseError, APIError, getAPIErrorMessageId } from "../../error";
import { Page } from "../../utils/pagination";
import {
  Failure,
  Initial,
  isSuccess,
  RemoteData,
  RemoteDataType,
  Success,
} from "../../utils/remoteData";

import styles from "./styles.module.scss";

export interface InfiniteScrollViewProps<T> {
  fetchPage: (cursor?: string) => Promise<Page<T>>;
  renderItem: (item: T, index: number) => React.ReactNode;
  listClassName?: string;
  emptyMessageId?: string;
  emptyMessageValues?: Values;
  threshold: number;
  disabled?: boolean;

  setRefresh?: (refresh: () => void) => void;
}

interface State<T> {
  // existing items
  items: T[];
  // hold the last remote page we have
  remotePage: RemoteData<Page<T>>;
}

export class InfiniteScrollView<T> extends PureComponent<
  InfiniteScrollViewProps<T>,
  State<T>
> {
  state: State<T> = {
    items: [],
    remotePage: Initial(),
  };

  componentDidMount() {
    const { fetchPage, setRefresh } = this.props;

    if (setRefresh) {
      setRefresh(this.refresh);
    }

    fetchPage().then(
      page => {
        this.setState(state => {
          return {
            items: [...state.items, ...page.results],
            remotePage: Success(page),
          };
        });
      },
      error => {
        this.setState({ remotePage: Failure(parseError(error)) });
      }
    );
  }

  render() {
    const {
      renderItem,
      listClassName,
      emptyMessageId,
      emptyMessageValues,
      threshold,
      disabled,
    } = this.props;
    const { items, remotePage } = this.state;

    const hasNextPage = isSuccess(remotePage)
      ? remotePage.value.nextCursor != null
      : false;

    return (
      <>
        {!disabled && (
          <IonRefresher slot="fixed" onIonRefresh={this.handleRefresh}>
            <IonRefresherContent />
          </IonRefresher>
        )}

        <RemoteItemList
          items={items}
          remotePage={remotePage}
          renderItem={renderItem}
          className={listClassName}
          emptyMessageId={emptyMessageId}
          emptyMessageValues={emptyMessageValues}
        />

        <InfiniteScroll
          threshold={threshold}
          fetch={this.handleInfinteScroll}
          disabled={!hasNextPage || !!disabled}
        >
          <div className={styles.loadingContainer}>
            <IonSpinner />
          </div>
        </InfiniteScroll>
      </>
    );
  }

  refresh = () => {
    const { fetchPage } = this.props;
    fetchPage().then(
      page => {
        this.setState({
          items: page.results,
          remotePage: Success(page),
        });
      },
      error => {
        this.setState({
          items: [],
          remotePage: Failure(parseError(error)),
        });
      }
    );
  };

  handleRefresh = (e: CustomEvent<RefresherEventDetail>) => {
    const { fetchPage } = this.props;

    fetchPage().then(
      page => {
        this.setState({
          items: page.results,
          remotePage: Success(page),
        });

        e.detail.complete();
      },
      error => {
        this.setState({
          items: [],
          remotePage: Failure(parseError(error)),
        });

        e.detail.complete();
      }
    );
  };

  handleInfinteScroll = (complete: () => void) => {
    const { remotePage } = this.state;
    if (remotePage.type !== RemoteDataType.success) {
      throw new Error("remotePage not being success on infinite scroll");
    }

    const { fetchPage } = this.props;
    fetchPage(remotePage.value.nextCursor || undefined).then(
      page => {
        this.setState(state => {
          return {
            items: [...state.items, ...page.results],
            remotePage: Success(page),
          };
        });

        complete();
      },
      error => {
        this.setState({ remotePage: Failure(parseError(error)) });

        complete();
      }
    );
  };

  updateItems = (updater: (items: T[]) => T[]) => {
    this.setState(prevState => ({ items: updater(prevState.items) }));
  };
}

interface RemoteItemListProps<T> {
  items: T[];
  remotePage: RemoteData<Page<T>>;
  renderItem: (item: T, index: number) => React.ReactNode;
  className?: string;
  emptyMessageId?: string;
  emptyMessageValues?: Values;
}

// eslint-disable-next-line react/prefer-stateless-function
class RemoteItemList<T> extends PureComponent<RemoteItemListProps<T>> {
  formattedErrorMessageProps = (error: Error): FormattedMessageProps => {
    if (error instanceof APIError) {
      return {
        id: getAPIErrorMessageId(error),
      };
    }

    return {
      id: "error.unknown",
      values: { message: error.message },
    };
  };

  render() {
    const { items, remotePage, className } = this.props;

    switch (remotePage.type) {
      case RemoteDataType.initial:
        return (
          <IonList class={className}>
            <IonListHeader>
              <div className={styles.loadingContainer}>
                <IonSpinner />
              </div>
            </IonListHeader>
          </IonList>
        );
      case RemoteDataType.loading:
        // NOTE(limouren): not using loading state as it is already handled
        // in refresher and infinite-scroll
        throw new Error("Unexpected loading state");
      case RemoteDataType.success:
        return this.renderList(items, className);
      case RemoteDataType.failure:
        if (items.length > 0) {
          // discard error sliently
          return this.renderList(items, className);
        }

        return (
          <IonList class={className}>
            <IonListHeader>
              <div className={styles.errorMessage}>
                <FormattedMessage
                  {...this.formattedErrorMessageProps(remotePage.error)}
                />
              </div>
            </IonListHeader>
          </IonList>
        );
      default:
        throw new Error(`Unexpected remoteRequests = ${remotePage}`);
    }
  }

  renderList = (items: T[], className?: string) => {
    const { renderItem } = this.props;

    return (
      <IonList class={className}>
        {items.length !== 0
          ? items.map((item, i) => {
              return renderItem(item, i);
            })
          : this.renderEmpty()}
      </IonList>
    );
  };

  renderEmpty = () => {
    const { emptyMessageId, emptyMessageValues } = this.props;

    return (
      <div className={styles.empty}>
        {emptyMessageId ? (
          <FormattedMessage id={emptyMessageId} values={emptyMessageValues} />
        ) : (
          <FormattedMessage id="common.empty_list" />
        )}
      </div>
    );
  };
}
