import { IonButton, IonPage } from "@ionic/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { useCallback } from "react";
import { AgentContextProps, withAgent } from "../../context/AgentContext";
import { UserContextProps, withUser } from "../../context/UserContext";
import { AgentBriefData } from "../../models";
import { AgentHeader } from "../AgentHeader";
import { AgentListView } from "../AgentListView";

import styles from "./styles.module.scss";

type Props = AgentContextProps &
  UserContextProps & {
    onClickAgent: (agent: AgentBriefData) => void;
    onClickCancel: () => void;
  };

const AdminImpersonateAgentSelectionView: React.FC<Props> = props => {
  const { userContext, agentContext, onClickAgent, onClickCancel } = props;

  const fetchPage = useCallback(
    (cursor?: string) => {
      return agentContext.listAgentsForAdminAgent(
        cursor,
        userContext.agentImpersonatorAccessToken || undefined
      );
    },
    [agentContext, userContext]
  );

  const handleCancelClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onClickCancel();
    },
    [onClickCancel]
  );

  return (
    <IonPage className={styles.page}>
      <AgentHeader
        buttons={
          <IonButton onClick={handleCancelClick}>
            <FormattedMessage id="agent_impersonation.cancel" />
          </IonButton>
        }
      >
        <h2 className={styles.title}>
          <FormattedMessage id="agent_impersonation.title" />
        </h2>
      </AgentHeader>
      <AgentListView
        listVersion={agentContext.agentListForAdminAgentVersion}
        fetchPage={fetchPage}
        onClickAgent={onClickAgent}
      />
    </IonPage>
  );
};

export default withUser(withAgent(AdminImpersonateAgentSelectionView));
