import { FieldType } from "../context/CreateRequestContext";

export interface Validator<T extends string> {
  field: T;
  invalidCondition: boolean | null | undefined;
  errorMessageId: string;
  isExistenceCheck?: boolean;
  shouldDoExistenceOnOptionalField?: boolean;
}

export type ValidationError<T extends string> = { [key in T]?: string };
export interface ValidationResult<T extends string> {
  focusField?: T;
  error: ValidationError<T>;
  isValid: boolean;
}

export function validate<T extends string>(
  validators: Validator<T>[]
): ValidationResult<T> {
  return validators.reduce(
    (prevResult: ValidationResult<T>, validator: Validator<T>) => {
      if (validator.invalidCondition) {
        return {
          focusField: prevResult.focusField || validator.field,
          error: {
            ...prevResult.error,
            [validator.field]: validator.errorMessageId,
          },
          isValid: false,
        };
      }
      return prevResult;
    },
    {
      focusField: undefined,
      error: {},
      isValid: true,
    }
  );
}

export function filterValidatorByDisplayMap<T extends string, F extends string>(
  validators: Validator<T>[],
  displayMap?: { [key in F]?: FieldType },
  fieldMapper: { [key in T]?: F } | ((x: T) => F) = {}
): Validator<T>[] {
  if (displayMap === undefined) {
    return validators;
  }

  const _fieldMapper =
    typeof fieldMapper === "function"
      ? fieldMapper
      : (x: T) => (fieldMapper[x] || x) as F;

  return validators.filter(
    validator =>
      _fieldMapper(validator.field) in displayMap &&
      (validator.isExistenceCheck
        ? validator.shouldDoExistenceOnOptionalField ||
          displayMap[_fieldMapper(validator.field)] === "required"
        : true)
  );
}

export function ignoreIndexFieldMapper<T extends string, F extends string>(
  x: T
): F {
  return x.replace(/[0-9]+$/, "") as F;
}
