import classNames from "classnames";
import React, { PureComponent } from "react";

import { Input, InputProps } from "./Input";

import styles from "./MPInput.module.scss";

export type MPInputProps = InputProps & {
  isError?: boolean;
};

// eslint-disable-next-line react/prefer-stateless-function
export class MPInput extends PureComponent<MPInputProps> {
  render() {
    const { className, isError, ...rest } = this.props;

    return (
      <Input
        className={classNames(styles.input, className, {
          [styles.error]: isError,
        })}
        {...rest}
      />
    );
  }
}
