import { snakeCase } from "lodash";
import { v4 } from "uuid";

import { BaseApiClient, ApiClientConstructor } from "./client";

import {
  BorrowerData,
  ExistingLoan,
  BorrowerPersonalInfo,
  PropertyInfo,
  OtherIncome,
} from "../models";
import {
  borrowerDataSchema,
  borrowerPersonalInfoSchema,
} from "../schemas/user";

export interface BorrowerApiClient {
  createBorrower: () => Promise<BorrowerData>;
  updateBorrower: (borrower: Partial<BorrowerData>) => Promise<BorrowerData>;
  getBorrowerExtraInfoWithChatId: (
    chatId: string
  ) => Promise<BorrowerPersonalInfo>;
  getBorrowerByChatId: (chatId: string) => Promise<BorrowerData>;
}

export function withBorrowerApi<
  TBase extends ApiClientConstructor<BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    createBorrower(): Promise<BorrowerData> {
      return this.requestLambda("borrower:create", {}, borrowerDataSchema);
    }

    updateBorrower(borrower: Partial<BorrowerData>): Promise<BorrowerData> {
      const args: any = {
        api_version: "2",
      };

      for (const [key, value] of Object.entries(borrower)) {
        args[snakeCase(key)] = value;
      }

      if (args["email"] === "") {
        args["email"] = null;
      }

      if (Object.prototype.hasOwnProperty.call(args, "industry")) {
        args["industry_id"] = (args["industry"] && args["industry"].id) || null;
        delete args["industry"];
      }

      if (Object.prototype.hasOwnProperty.call(args, "existing_loans")) {
        args["existing_loans"] = (args["existing_loans"] as ExistingLoan[]).map(
          e => ({
            id: e.id || v4(),
            lender: e.lender,
            amount: e.amount,
            tenor: e.tenor,
            remaining_tenor: e.remainingTenor,
            monthly_repayment: e.monthlyRepayment,
          })
        );
      }

      if (Object.prototype.hasOwnProperty.call(args, "property_infos")) {
        args["property_infos"] = (args["property_infos"] as PropertyInfo[]).map(
          e => ({
            id: e.id || v4(),
            room_floor: e.roomFloor,
            tower: e.tower,
            name_of_building: e.nameOfBuilding,
            street_name_and_number: e.streetNameAndNumber,
            district: e.district,
          })
        );
      }

      if (Object.prototype.hasOwnProperty.call(args, "other_incomes")) {
        args["other_incomes"] = (args["other_incomes"] as OtherIncome[]).map(
          e => ({
            id: e.id || v4(),
            working_company_names: e.workingCompanyNames,
            position: e.position,
            salary: e.salary,
            on_board_date: e.onBoardDate,
            income_proof_type: e.incomeProofType,
            payment_method: e.paymentMethod,
          })
        );
      }

      return this.requestLambda("borrower:update", args, borrowerDataSchema);
    }

    getBorrowerExtraInfoWithChatId(
      chatId: string
    ): Promise<BorrowerPersonalInfo> {
      return this.requestLambda(
        "borrower:get-extra-info",
        {
          api_version: "2",
          chat_id: chatId,
        },
        borrowerPersonalInfoSchema
      );
    }

    getBorrowerByChatId(chatId: string): Promise<BorrowerData> {
      return this.requestLambda(
        "borrower:get-by-chat-id",
        {
          api_version: "2",
          chat_id: chatId,
        },
        borrowerDataSchema
      );
    }
  };
}
