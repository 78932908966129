import { FormattedMessage } from "@oursky/react-messageformat";
import React, { PureComponent } from "react";
import { formatNumber, parseNumber } from "libphonenumber-js";

import { MPContent } from "../MPContent";

import styles from "./styles.module.scss";
import PersonalInfoItem from "./PersonalInfoItem";
import AccountItem from "./AccountItem";
import { Borrower } from "../../models";
import { StaticPageType } from "../../types/misc";
import { getStaticPageOptions } from "../../utils/misc";
import { IonPage, IonHeader, IonToolbar } from "@ionic/react";

export type Props = AccountViewProps;

interface AccountViewProps {
  borrower: Borrower;
  onEditButtonClick: () => void;
  onLogOutButtonClick: () => void;
  openStaticPage: (key: StaticPageType) => void;
}

const images = {
  checkIcon: require("../../images/icon-account-check.png"),
  workDataIcon: require("../../images/icon-account-workData.png"),
  homeDataIcon: require("../../images/icon-account-homeData.png"),
  background: require("../../images/background-empty.png"),
};

class AccountView extends PureComponent<Props> {
  renderOptions = () => {
    const options = getStaticPageOptions();

    return (
      <div className={styles.options}>
        {options.map((option, index) => {
          const className =
            index === 0 ? styles.firstOption : styles.otherOptions;

          const onClick = () => {
            this.props.openStaticPage(option.type);
          };

          return (
            <button className={className} key={index} onClick={onClick}>
              <FormattedMessage id={option.labelId} />
            </button>
          );
        })}
        <button
          className={styles.logoutOption}
          onClick={this.props.onLogOutButtonClick}
        >
          <FormattedMessage id="account.option.logout" />
        </button>
      </div>
    );
  };

  renderPersonalInfo = (
    isHired: boolean | null,
    hasMortage: boolean | null
  ) => {
    return (
      <div className={styles.personalInfoContainer}>
        <div className={styles.personalInfoBlock}>
          <PersonalInfoItem
            checked={isHired === true}
            icon={images.workDataIcon}
            checkIcon={images.checkIcon}
            labelId="account.personalInfo.workData"
            alt="is_hired"
          />
        </div>
        <div className={styles.personalInfoBlock}>
          <PersonalInfoItem
            checked={hasMortage === true}
            icon={images.homeDataIcon}
            checkIcon={images.checkIcon}
            labelId="account.personalInfo.loanData"
            alt="has_mortage"
          />
        </div>
      </div>
    );
  };

  renderContent(borrower: Borrower) {
    return (
      <div className={styles.accountContainer}>
        <div className={styles.userInfoContainer}>
          <AccountItem
            labelId="account.userInfo.mobile"
            value={this.getPhoneNumber(borrower)}
          />
          <AccountItem
            labelId="account.userInfo.email"
            value={borrower.email ? `${borrower.email}` : ""}
          />
          <AccountItem
            labelId="account.userInfo.birthday"
            value={
              borrower.birthday ? borrower.birthday.toFormat("dd/MM/yyyy") : ""
            }
          />
          <div className={styles.editButtonPosition}>
            <button
              className={styles.editButton}
              onClick={this.props.onEditButtonClick}
            >
              <FormattedMessage id="account.userInfo.editButtonText" />
            </button>
          </div>
        </div>
        <div className={styles.personalInfoTitle}>
          <FormattedMessage id="account.personalInfo.title" />
        </div>
        {this.renderPersonalInfo(borrower.isHired, borrower.hasMortgage)}
        {this.renderOptions()}
      </div>
    );
  }

  getPhoneNumber(borrower: Borrower) {
    return formatNumber(parseNumber(borrower.username, "HK"), "INTERNATIONAL");
  }

  render() {
    const { borrower } = this.props;
    return (
      <IonPage className={styles.page}>
        <IonHeader mode="ios">
          <IonToolbar mode="ios">
            <div className={styles.accountTitle}>
              <FormattedMessage id="account.title" />
            </div>
          </IonToolbar>
        </IonHeader>
        <MPContent forceOverscroll={false}>
          {this.renderContent(borrower)}
        </MPContent>
      </IonPage>
    );
  }
}

export default AccountView;
