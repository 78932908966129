import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router";

import {
  withErrorAlert,
  ErrorAlertContextProps,
} from "../context/ErrorAlertContext";
import { withUser, UserContextProps } from "../context/UserContext";
import { withLoading, LoadingContextProps } from "../context/LoadingContext";
import { APIErrorCode, getAPIErrorMessageId } from "../error";
import { Initial, Loading, RemoteData, Success } from "../utils/remoteData";
import { validate, ValidationError } from "../utils/validate";
import { BorrowerBasicInfoView } from "../components/BorrowerBasicInfoView";
import {
  getBorrowerBasicInfoFormValidators,
  InputFields,
} from "../components/BorrowerBasicInfoForm/common";
import { BorrowerBasicInfo, BorrowerPropertyInfo } from "../models";
import {
  defaultPropertyInfoValues,
  FormValues as PropertyInfoFormValues,
  validatePropertyInfoForm,
} from "../components/BorrowerPropertyInfoView/PropertyInfoForm";
import { ensureBorrower } from "../utils/user";
import {
  withCreateRequest,
  CreateRequestContextProps,
  DisplayMapByRequestType,
  CreateRequestRoute,
} from "../context/CreateRequestContext";
import { RequestType } from "../types/request";

type Props = LoadingContextProps &
  ErrorAlertContextProps &
  CreateRequestContextProps &
  RouteComponentProps<{}, {}, { requestType?: RequestType } | undefined> &
  UserContextProps;

interface State {
  remoteSave: RemoteData<void>;
  error: ValidationError<InputFields>;
  borrowerBasicInfo: BorrowerBasicInfo;
  borrowerPropertyInfo: BorrowerPropertyInfo;
  focusField?: InputFields;
  emailErrorId?: string;
  requestType?: RequestType;
}

class BorrowerBasicInfoScreenImpl extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const borrower = ensureBorrower(props.userContext.user);
    if (borrower === undefined) {
      throw new Error("Unexpected borrower is undefined");
    }
    const {
      location: { state },
    } = this.props;
    this.state = {
      remoteSave: Initial(),
      error: {},
      borrowerBasicInfo: {
        name: borrower.name === "-" ? null : borrower.name,
        surname: borrower.surname,
        gender: borrower.gender,
        passportNumber: borrower.passportNumber,
        email: borrower.email,
        birthday: borrower.birthday,
        education: borrower.education,
        heardFrom: borrower.heardFrom,
        roomFloor: borrower.roomFloor,
        tower: borrower.tower,
        nameOfBuilding: borrower.nameOfBuilding,
        streetNameAndNumber: borrower.streetNameAndNumber,
        district: borrower.district,
        premiseType: borrower.premiseType,
        propertyStatus: borrower.propertyStatus,
        numOfOwner: borrower.numOfOwner,
        monthlyRent: borrower.monthlyRent,
      },
      borrowerPropertyInfo: {
        propertyInfos: borrower.propertyInfos || [],
        numberOfProperties:
          borrower.numberOfProperties === null
            ? borrower.propertyInfos.length || null
            : borrower.propertyInfos.length,
      },
      requestType: state ? state.requestType : undefined,
    };
  }

  setErrorState = (data: Partial<ValidationError<InputFields>>) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        ...data,
      },
    }));
  };

  navigate = (skipProfileFilling: boolean = false) => {
    const { getNextCreateRequestRoute } = this.props.createRequestContext;
    const { user } = this.props.userContext;
    const borrower = ensureBorrower(user);
    if (borrower === undefined) {
      throw new Error("Unexpected borrower is undefined");
    }
    const requestType = this.state.requestType;
    if (requestType === undefined) {
      throw new Error("Unexpected request type is undefined");
    }
    const nextRoute = getNextCreateRequestRoute(
      borrower,
      requestType,
      skipProfileFilling
    );
    if (nextRoute === undefined) {
      this.props.history.push("/close");
    } else {
      this.props.history.push(`${nextRoute}`, {
        requestType: this.state.requestType,
      });
    }
  };

  submitForm = async () => {
    const { borrowerBasicInfo, borrowerPropertyInfo } = this.state;
    this.setState({ remoteSave: Loading() });
    this.props.loadingContext.show();
    try {
      await this.props.userContext.updateBorrower({
        ...borrowerBasicInfo,
        ...borrowerPropertyInfo,
      });
      this.setState({
        remoteSave: Success(undefined),
      });
    } catch (error) {
      switch (error.code) {
        case APIErrorCode.emailTaken:
          this.setState({
            emailErrorId: getAPIErrorMessageId(error),
            remoteSave: Initial(),
          });
          break;
        default:
          this.props.errorAlertContext.show(error);
          this.setState({ remoteSave: Initial() });
      }
    } finally {
      this.props.loadingContext.dismiss();
    }
  };

  onSubmit = async () => {
    this.setState({ focusField: undefined });
    if (!this.validateForm()) {
      return;
    }
    await this.submitForm();
    this.navigate();
  };

  onSkipToContact = async () => {
    this.setState({ focusField: undefined });
    if (!this.validateForm(true)) {
      return;
    }
    await this.submitForm();
    const { setPendingCreateRequest } = this.props.createRequestContext;
    setPendingCreateRequest(prev => {
      if (prev) {
        return {
          ...prev,
          skipProfileFilling: true,
        };
      }
      return prev;
    });
    this.navigate(true);
  };

  onUpdateBorrowerBasicInfo = (value: Partial<BorrowerBasicInfo>) => {
    this.setState(prevState => ({
      ...prevState,
      borrowerBasicInfo: {
        ...prevState.borrowerBasicInfo,
        ...value,
      },
    }));
  };

  onUpdateHasProperty = (hasProperty: boolean) => {
    if (!hasProperty) {
      this.setState({
        borrowerPropertyInfo: { propertyInfos: [], numberOfProperties: 0 },
      });
    } else {
      this.setState({
        borrowerPropertyInfo: {
          propertyInfos: [
            {
              id: null,
              roomFloor: null,
              tower: null,
              nameOfBuilding: null,
              streetNameAndNumber: null,
              district: null,
            },
          ],
          numberOfProperties: 1,
        },
      });
    }
    this.setErrorState({
      hasProperty: undefined,
      numberOfProperties: undefined,
    });
  };

  onSelectNumberOfProperties = (numberOfProperties: number) => {
    this.setState(prevState => ({
      ...prevState,
      borrowerPropertyInfo: {
        ...prevState.borrowerPropertyInfo,
        propertyInfos:
          prevState.borrowerPropertyInfo.propertyInfos.length >=
          numberOfProperties
            ? prevState.borrowerPropertyInfo.propertyInfos.slice(
                0,
                numberOfProperties
              )
            : prevState.borrowerPropertyInfo.propertyInfos.concat(
                Array(
                  numberOfProperties -
                    prevState.borrowerPropertyInfo.propertyInfos.length
                ).fill(defaultPropertyInfoValues)
              ),
        numberOfProperties: numberOfProperties,
      },
    }));
    this.setErrorState({ numberOfProperties: undefined });
  };

  onUpdateExistingPropertyInfo = (
    index: number,
    value: Partial<PropertyInfoFormValues>
  ) => {
    this.setState(prevState => ({
      ...prevState,
      borrowerPropertyInfo: {
        ...prevState.borrowerPropertyInfo,
        propertyInfos: prevState.borrowerPropertyInfo.propertyInfos.map(
          (v, i) =>
            i === index
              ? {
                  ...v,
                  ...{
                    ...value,
                    district:
                      value.district != null
                        ? value.district.value
                        : v.district,
                  },
                }
              : v
        ),
      },
    }));
  };

  validateForm(skipProfileFilling: boolean = false) {
    const { borrowerBasicInfo, borrowerPropertyInfo } = this.state;
    const { pendingCreateRequest } = this.props.createRequestContext;
    if (pendingCreateRequest === null) {
      throw new Error("Unexpected pending request is null");
    }
    const formDisplayMap =
      DisplayMapByRequestType[pendingCreateRequest.type][
        CreateRequestRoute.BasicInfo
      ];
    let validators = getBorrowerBasicInfoFormValidators(
      borrowerBasicInfo,
      borrowerPropertyInfo,
      formDisplayMap
    );
    if (skipProfileFilling) {
      validators = validators.filter(it => !it.isExistenceCheck);
    }
    const { focusField, error, isValid } = validate(validators);
    const formValidateResult = validatePropertyInfoForm(
      borrowerPropertyInfo.propertyInfos,
      formDisplayMap["propertyInfos"],
      skipProfileFilling
    );
    this.setState(() => ({
      error: {
        ...error,
        ...formValidateResult.error,
      },
      focusField: focusField || formValidateResult.focusField,
    }));

    return isValid && formValidateResult.isValid;
  }

  render() {
    const {
      remoteSave,
      error,
      emailErrorId,
      focusField,
      borrowerBasicInfo,
      borrowerPropertyInfo,
    } = this.state;

    return (
      <BorrowerBasicInfoView
        remoteSave={remoteSave}
        error={error}
        borrowerBasicInfo={borrowerBasicInfo}
        updateBorrowerBasicInfo={this.onUpdateBorrowerBasicInfo}
        borrowerPropertyInfo={borrowerPropertyInfo}
        onUpdateExistingPropertyInfo={this.onUpdateExistingPropertyInfo}
        onUpdateHasProperty={this.onUpdateHasProperty}
        onSelectNumberOfProperties={this.onSelectNumberOfProperties}
        emailErrorId={emailErrorId}
        focusField={focusField}
        onSubmit={this.onSubmit}
        onSkipToContact={this.onSkipToContact}
      />
    );
  }
}

export const BorrowerBasicInfoScreen = withLoading(
  withErrorAlert(withCreateRequest(withUser(BorrowerBasicInfoScreenImpl)))
);
