import React from "react";
import classNames from "classnames";
import { focusElement } from "../../../utils/focus";
import { FormattedMessage } from "@oursky/react-messageformat";
import styles from "./styles.module.scss";

const Digit: React.FC<{
  digit: string;
  isError: boolean;
  isFocused: boolean;
}> = props => {
  return (
    <div
      className={classNames(styles.digit, {
        [styles.errored]: props.isError,
        [styles.focused]: !props.isError && props.isFocused,
      })}
    >
      {props.digit}
    </div>
  );
};

interface Props {
  digits: string;
  isError: boolean;

  onDigitChange: (digits: string) => void;
}

export default class VerificationCodeInput extends React.PureComponent<Props> {
  inputRef = React.createRef<HTMLInputElement>();

  hasKeyEvent = false;

  private getDigit = (at: number) => {
    if (at >= this.props.digits.length) {
      return "";
    }
    return this.props.digits[at];
  };

  private onClickDigit: React.MouseEventHandler<HTMLDivElement> = _e => {
    if (this.inputRef.current !== null) {
      this.inputRef.current.focus();
    }
  };

  // We need <input> for showing soft-keyboard and scroll the digits view to visible
  // area, but we don't want to see the <input>, however setting <input> opacity to 0
  // or setting font-size to 0 will result in no change/input input event on android,
  // lucky soft-keyboard can still be shown and keydown event is still there.
  // So the workaround is to update the input field value using keydown event handler.
  keyDownHandler: React.KeyboardEventHandler = e => {
    if (/^[0-9]$/.test(e.key)) {
      this.props.onDigitChange(this.props.digits + e.key);
      this.hasKeyEvent = true;
    } else if (e.key === "Backspace") {
      this.props.onDigitChange(
        this.props.digits.substring(0, this.props.digits.length - 1)
      );
    } else if (e.key === "Enter") {
      if (this.inputRef.current !== null) {
        this.inputRef.current.blur();
      }
    }
    e.preventDefault();
  };

  // We still have this to handle iOS security code autofill
  private onInputChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    if (!this.hasKeyEvent) {
      this.props.onDigitChange(e.target.value);
    } else {
      this.hasKeyEvent = false;
    }
  };

  componentDidMount() {
    if (this.inputRef.current !== null) {
      this.inputRef.current.autocomplete = "one-time-code";
    }
    setTimeout(() => {
      focusElement(this.inputRef.current);
    }, 500);
  }

  render() {
    const { digits, isError } = this.props;
    return (
      <div className={styles.verificationCodeInput}>
        <input
          className={styles.hiddenInput}
          type="tel"
          ref={this.inputRef}
          value={digits}
          onChange={this.onInputChange}
          onKeyDown={this.keyDownHandler}
        />
        <div className={styles.digitContainer} onClick={this.onClickDigit}>
          {Array.from(Array(4).keys()).map(i => (
            <Digit
              key={i}
              digit={this.getDigit(i)}
              isError={isError}
              isFocused={digits.length === i}
            />
          ))}
        </div>
        {isError && (
          <div className={styles.errorMessage}>
            <FormattedMessage id="sms_verification.error.wrong_verification_code" />
          </div>
        )}
      </div>
    );
  }
}
