import { parsePhoneNumber } from "libphonenumber-js";

export interface e164PhoneNumber {
  value: string;
}

export function e164PhoneNumber(
  countryCallingCode: string,
  nationalNumber: string
): e164PhoneNumber {
  const value = `+${countryCallingCode}${nationalNumber}`.trim();
  return { value };
}

export function isPhoneNumberFormatValid(
  countryCallingCode: string,
  nationalNumber: string
): boolean {
  const phoneNumber: e164PhoneNumber = e164PhoneNumber(
    countryCallingCode,
    nationalNumber
  );
  let isFormatValid = false;
  try {
    isFormatValid = parsePhoneNumber(phoneNumber.value).isValid();
  } catch (e) {}
  return isFormatValid;
}
