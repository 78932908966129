import React, { FormEventHandler } from "react";
import classNames from "classnames";
import { FormattedMessage } from "@oursky/react-messageformat";

import styles from "./styles.module.scss";

import { PrimaryButton } from "../Button";
import ErrorField from "../ErrorField";
import { FormattedInput } from "../FormattedInput";
import { MPInput } from "../input";
import { MonthPeriodDropdown, TUGradeDropdown } from "../SpecifyDropdown";
import YesNoPicker from "../YesNoPicker";
import { Option } from "../DropdownPicker";
import { FormattedDropdown } from "../FormattedDropdown";

import {
  formatToCurrencyAmount,
  currencyFormatToNumber,
} from "../../utils/formatToCurrencyAmount";
import {
  CreatePersonalLoanRequest,
  QuickRequestData,
  RequestType,
} from "../../types/request";
import { validateFormattedAmount } from "../../utils/validation";
import { Validator, validate, ValidationError } from "../../utils/validate";

import { Borrower } from "../../models";
import { StorageKey } from "../../utils/storage";
import { RequestPersonalLoanFormValuesToPresist } from "../CreateRequestView/types";

export type InputFields =
  | "amount"
  | "period"
  | "monthlyIncome"
  | "currentLoanAmount"
  | "purposingOfFinancing"
  | "filedForBankruptcy"
  | "appliedForIva"
  | "numPersonalLoanForPastTwoWeeks"
  | "numOfLoanEnquiriesInOneMonth"
  | "hasLatePayment"
  | "isTaxReturnFormAvailable";

interface Props {
  borrower?: Borrower;
  quickRequestData?: QuickRequestData;
  latestFormValues?: FormValues;
  onSubmit: (
    values: CreatePersonalLoanRequest,
    formValuesToPersist: RequestPersonalLoanFormValuesToPresist
  ) => void;
}

export interface FormValues {
  amount: string;
  period: string;
  monthlyIncome: string;
  currentLoanAmount: string;
  purposingOfFinancing: string;
  filedForBankruptcy: boolean | null;
  appliedForIva: boolean | null;
  numPersonalLoanForPastTwoWeeks: number | null;
  numOfLoanEnquiriesInOneMonth: number | null;
  hasLatePayment: boolean | null;
  isTaxReturnFormAvailable: boolean | null;
  tuGrade: string;
  allowTUCheck: boolean;
}

interface State {
  values: FormValues;
  error: ValidationError<InputFields>;
  focusField?: InputFields;
  nextField?: InputFields;
}

export const defaultState = {
  values: {
    amount: "",
    period: "",
    monthlyIncome: "",
    currentLoanAmount: "",
    purposingOfFinancing: "",
    filedForBankruptcy: null,
    appliedForIva: null,
    numPersonalLoanForPastTwoWeeks: null,
    numOfLoanEnquiriesInOneMonth: null,
    hasLatePayment: null,
    isTaxReturnFormAvailable: null,
    tuGrade: "",
    allowTUCheck: false,
  },
  error: {},
};

export type RequestPersonalLoanFormProps = Props;

class RequestPersonalLoanForm extends React.PureComponent<
  RequestPersonalLoanFormProps,
  State
> {
  constructor(props: RequestPersonalLoanFormProps) {
    super(props);

    this.state = this.prefilledDefaultState;
  }

  get prefilledMonthlyIncome() {
    return this.props.borrower && this.props.borrower.salary
      ? this.props.borrower.salary.toString()
      : "";
  }

  get prefilledCurrentLoanAmount() {
    if (!this.props.borrower) {
      return "";
    }
    const amount = this.props.borrower.existingLoans.reduce(
      (prev, loan) => (loan.amount ? prev + loan.amount : prev),
      0
    );
    if (amount) {
      return formatToCurrencyAmount(amount) || "";
    }
    return "";
  }

  get prefilledDefaultState(): State {
    return {
      values: {
        ...defaultState.values,
        monthlyIncome: this.prefilledMonthlyIncome,
        currentLoanAmount: this.prefilledCurrentLoanAmount,
      },
      error: defaultState.error,
    };
  }

  fillinLatestFormValuesIfChanged = (prevProps: Props) => {
    const { latestFormValues } = this.props;
    if (!prevProps.latestFormValues && latestFormValues) {
      this.setState({ values: latestFormValues });
    }
  };

  componentDidUpdate(prevProps: Props) {
    this.fillinLatestFormValuesIfChanged(prevProps);
    this.resetNextField();
  }

  private resetNextField() {
    if (this.state.nextField) {
      this.setState({ nextField: undefined });
    }
  }

  numericOptions: Option[] = Array(21)
    .fill(0)
    .map((_, i) => {
      const value = i.toString();
      return {
        key: value,
        value: value,
        display: value,
      };
    });

  renderOfferSection = () => {
    const { error, values, focusField } = this.state;
    return (
      <>
        <label className={styles.sectionText}>
          <FormattedMessage id="request_offer.section.offer_info" />
        </label>
        <div className={styles.row}>
          <div className={styles.col}>
            <label className={styles.labelText}>
              <FormattedMessage id="request_offer.label.amount" />
            </label>
            <MPInput
              className={styles.input}
              value={values.amount}
              onChange={this.handleAmountChange}
              placeholder="HKD"
              inputMode="tel"
              type="tel"
              isError={error.amount !== undefined}
              shouldFocus={focusField === "amount"}
              shouldScrollTo={focusField === "amount"}
              scrollAnchor={"amount"}
            />
            <ErrorField messageId={error.amount} />
          </div>
          <div className={styles.colRight}>
            <label className={styles.labelText}>
              <FormattedMessage id="request_offer.label.period" />
            </label>
            <MonthPeriodDropdown
              containerClass={styles.dropdownContainer}
              selectClass={styles.dropdownSelect}
              errorClass={styles.dropdownError}
              emptyClass={styles.dropdownEmpty}
              value={values.period}
              isError={error.period !== undefined}
              onValueChange={this.handlePeriodChange}
            />
            <ErrorField messageId={error.period} />
          </div>
        </div>
      </>
    );
  };

  renderFinancialSection = () => {
    const { values, error, focusField } = this.state;
    return (
      <div className={styles.col}>
        <label className={styles.sectionText}>
          <FormattedMessage id="request_offer.section.financial_situation" />
        </label>
        <label className={styles.labelText}>
          <FormattedMessage id="request_offer.label.monthly_income" />
        </label>
        <MPInput
          className={styles.input}
          value={values.monthlyIncome}
          onChange={this.handleMonthlyIncomeChange}
          placeholder="HKD"
          inputMode="tel"
          type="tel"
          shouldFocus={focusField === "monthlyIncome"}
          shouldScrollTo={focusField === "monthlyIncome"}
          scrollAnchor={"monthlyIncome"}
        />
        <ErrorField messageId={error.monthlyIncome} />

        <label className={styles.labelText}>
          <FormattedMessage id="request_offer.label.loan_amount" />
        </label>
        <MPInput
          className={styles.input}
          value={values.currentLoanAmount}
          onChange={this.handleCurrentLoanAmountChange}
          placeholder="HKD"
          inputMode="tel"
          type="tel"
          shouldFocus={focusField === "currentLoanAmount"}
          shouldScrollTo={focusField === "currentLoanAmount"}
          scrollAnchor={"currentLoanAmount"}
        />
        <ErrorField messageId={error.currentLoanAmount} />

        <label className={styles.labelText}>
          <FormattedMessage id="request_offer.loan.purposingOfFinancing.label" />
        </label>
        <FormattedInput
          className={styles.input}
          value={values.purposingOfFinancing}
          onChange={this.handlePurposingOfFinancingChange}
          isError={error.purposingOfFinancing !== undefined}
          shouldFocus={focusField === "purposingOfFinancing"}
          shouldScrollTo={focusField === "purposingOfFinancing"}
          scrollAnchor={"purposingOfFinancing"}
          placeholderId={"request_offer.loan.pleaseFillIn"}
        />
        <ErrorField messageId={error.purposingOfFinancing} />

        <div className={styles.yesNoPickerContainer}>
          <YesNoPicker
            labelId="request_offer.loan.filedForBankruptcy.label"
            labelClass={styles.yesNoPickerLabelText}
            onClickOption={this.handleFiledForBankruptcyChange}
            value={
              values.filedForBankruptcy === null
                ? undefined
                : values.filedForBankruptcy
            }
            shouldFocus={focusField === "filedForBankruptcy"}
          />
        </div>
        <ErrorField messageId={error.filedForBankruptcy} />

        <div className={styles.yesNoPickerContainer}>
          <YesNoPicker
            labelId="request_offer.loan.appliedForIva.label"
            labelClass={styles.yesNoPickerLabelText}
            onClickOption={this.handleAppliedForIvaChange}
            value={
              values.appliedForIva === null ? undefined : values.appliedForIva
            }
            shouldFocus={focusField === "appliedForIva"}
          />
        </div>
        <ErrorField messageId={error.appliedForIva} />

        <label className={styles.labelText}>
          <FormattedMessage id="request_offer.loan.numPersonalLoanForPastTwoWeeks.label" />
        </label>
        <FormattedDropdown
          containerClass={styles.dropdownContainer}
          selectClass={styles.dropdownSelect}
          errorClass={styles.dropdownError}
          emptyClass={styles.dropdownEmpty}
          isError={error.numPersonalLoanForPastTwoWeeks !== undefined}
          options={this.numericOptions}
          value={
            values.numPersonalLoanForPastTwoWeeks !== null
              ? values.numPersonalLoanForPastTwoWeeks
              : ""
          }
          onValueChange={this.handleNumPersonalLoanForPastTwoWeeksChange}
          shouldFocus={focusField === "numPersonalLoanForPastTwoWeeks"}
          shouldScrollTo={focusField === "numPersonalLoanForPastTwoWeeks"}
          scrollAnchor={"numPersonalLoanForPastTwoWeeks"}
          placeholderId={"request_offer.mortgage.pleaseFillIn"}
        />
        <ErrorField messageId={error.numPersonalLoanForPastTwoWeeks} />

        <label className={styles.labelText}>
          <FormattedMessage id="request_offer.loan.numOfLoanEnquiriesInOneMonth.label" />
        </label>
        <FormattedDropdown
          containerClass={styles.dropdownContainer}
          selectClass={styles.dropdownSelect}
          errorClass={styles.dropdownError}
          emptyClass={styles.dropdownEmpty}
          isError={error.numOfLoanEnquiriesInOneMonth !== undefined}
          options={this.numericOptions}
          value={
            values.numOfLoanEnquiriesInOneMonth !== null
              ? values.numOfLoanEnquiriesInOneMonth
              : ""
          }
          onValueChange={this.handleNumOfLoanEnquiriesInOneMonthChange}
          shouldFocus={focusField === "numOfLoanEnquiriesInOneMonth"}
          shouldScrollTo={focusField === "numOfLoanEnquiriesInOneMonth"}
          scrollAnchor={"numOfLoanEnquiriesInOneMonth"}
          placeholderId={"request_offer.mortgage.pleaseFillIn"}
        />
        <ErrorField messageId={error.numOfLoanEnquiriesInOneMonth} />

        <div className={styles.yesNoPickerContainer}>
          <YesNoPicker
            labelId="request_offer.loan.hasLatePayment.label"
            labelClass={styles.yesNoPickerLabelText}
            onClickOption={this.handleHasLatePaymentChange}
            value={
              values.hasLatePayment === null ? undefined : values.hasLatePayment
            }
            shouldFocus={focusField === "hasLatePayment"}
          />
        </div>
        <ErrorField messageId={error.hasLatePayment} />

        <div className={styles.yesNoPickerContainer}>
          <YesNoPicker
            labelId="request_offer.loan.isTaxReturnFormAvailable.label"
            labelClass={styles.yesNoPickerLabelText}
            onClickOption={this.handleIsTaxReturnFormAvailableChange}
            value={
              values.isTaxReturnFormAvailable === null
                ? undefined
                : values.isTaxReturnFormAvailable
            }
            shouldFocus={focusField === "isTaxReturnFormAvailable"}
          />
        </div>
        <ErrorField messageId={error.isTaxReturnFormAvailable} />

        <label className={classNames(styles.sectionText, styles.tuSection)}>
          <FormattedMessage id="request_offer.section.has_tu_grade" />
        </label>
        <TUGradeDropdown
          containerClass={styles.dropdownContainer}
          selectClass={styles.dropdownSelect}
          errorClass={styles.dropdownError}
          emptyClass={styles.dropdownEmpty}
          value={values.tuGrade}
          isError={false}
          onValueChange={this.handleTUGradeChange}
        />

        <div
          className={styles.checkboxContainer}
          onClick={this.toggleAllowTUCheck}
        >
          <div
            className={classNames(styles.checkbox, {
              [styles.checked]: values.allowTUCheck,
            })}
          />
          <div>
            <FormattedMessage id="request_offer.allow_tu_check" />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <form noValidate={true} onSubmit={this.handleSubmit}>
        <div className={styles.formContainer}>
          {this.renderOfferSection()}
          {this.renderFinancialSection()}
        </div>

        <PrimaryButton class={styles.submitButton} type="submit" expand="full">
          <FormattedMessage id="request_offer.submit" />
        </PrimaryButton>
      </form>
    );
  }

  handleAmountChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const amount = e.currentTarget.value || "";
    const formattedAmount = formatToCurrencyAmount(amount);

    if (formattedAmount !== null && validateFormattedAmount(formattedAmount)) {
      this.setState(({ values }) => {
        return {
          values: { ...values, amount: formattedAmount },
        };
      });
    }
  };

  handleCurrentLoanAmountChange: React.ChangeEventHandler<
    HTMLInputElement
  > = e => {
    const currentLoanAmount = e.currentTarget.value || "";
    const formattedAmount = formatToCurrencyAmount(currentLoanAmount);

    if (formattedAmount !== null && validateFormattedAmount(formattedAmount)) {
      this.setState(({ values }) => {
        return {
          values: { ...values, currentLoanAmount: formattedAmount },
        };
      });
    }
  };

  handleMonthlyIncomeChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const monthlyIncome = e.currentTarget.value || "";
    const formattedAmount = formatToCurrencyAmount(monthlyIncome);

    if (formattedAmount !== null && validateFormattedAmount(formattedAmount)) {
      this.setState(({ values }) => {
        return {
          values: { ...values, monthlyIncome: formattedAmount },
        };
      });
    }
  };

  handlePeriodChange = (period: string) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, period },
      };
    });
  };

  handleSubmit: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();

    this.setState({ focusField: undefined }, () => {
      const { values } = this.state;

      if (!this.validateRequestData(values)) return;

      const formattedValues: CreatePersonalLoanRequest = {
        type: RequestType.PersonalLoan,
        amount: currencyFormatToNumber(values.amount),
        period: parseInt(values.period, 10),
        monthlyIncome: currencyFormatToNumber(values.monthlyIncome),
        currentLoanAmount: currencyFormatToNumber(values.currentLoanAmount),
        purposingOfFinancing: values.purposingOfFinancing,
        filedForBankruptcy: values.filedForBankruptcy,
        appliedForIva: values.appliedForIva,
        numPersonalLoanForPastTwoWeeks: values.numPersonalLoanForPastTwoWeeks,
        numOfLoanEnquiriesInOneMonth: values.numOfLoanEnquiriesInOneMonth,
        hasLatePayment: values.hasLatePayment,
        isTaxReturnFormAvailable: values.isTaxReturnFormAvailable,
        tuGrade: values.tuGrade || null,
        allowTUCheck: values.allowTUCheck,
      };

      this.props.onSubmit(formattedValues, {
        storageKey: StorageKey.RequestPersonalLoanForm,
        formValues: values,
      });
    });
  };

  handlePurposingOfFinancingChange: React.ChangeEventHandler<
    HTMLInputElement
  > = e => {
    const purposingOfFinancing = e.currentTarget.value || "";
    this.setState(({ values }) => {
      return {
        values: { ...values, purposingOfFinancing },
      };
    });
  };

  handleFiledForBankruptcyChange = (filedForBankruptcy: boolean) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, filedForBankruptcy },
      };
    });
  };

  handleAppliedForIvaChange = (appliedForIva: boolean) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, appliedForIva },
      };
    });
  };

  handleNumPersonalLoanForPastTwoWeeksChange = (value: string) => {
    this.setState(({ values }) => {
      return {
        values: {
          ...values,
          numPersonalLoanForPastTwoWeeks: parseInt(value, 10),
        },
      };
    });
  };

  handleNumOfLoanEnquiriesInOneMonthChange = (value: string) => {
    this.setState(({ values }) => {
      return {
        values: {
          ...values,
          numOfLoanEnquiriesInOneMonth: parseInt(value, 10),
        },
      };
    });
  };

  handleHasLatePaymentChange = (hasLatePayment: boolean) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, hasLatePayment },
      };
    });
  };

  handleIsTaxReturnFormAvailableChange = (
    isTaxReturnFormAvailable: boolean
  ) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, isTaxReturnFormAvailable },
      };
    });
  };

  handleTUGradeChange = (tu: string) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, tuGrade: tu },
      };
    });
  };

  toggleAllowTUCheck = () => {
    this.setState(({ values }) => ({
      values: {
        ...values,
        allowTUCheck: !values.allowTUCheck,
      },
    }));
  };

  validateRequestData(values: FormValues) {
    const {
      amount,
      period,
      monthlyIncome,
      currentLoanAmount,
      purposingOfFinancing,
      filedForBankruptcy,
      appliedForIva,
      numPersonalLoanForPastTwoWeeks,
      numOfLoanEnquiriesInOneMonth,
      hasLatePayment,
      isTaxReturnFormAvailable,
    } = values;

    const validators: Validator<InputFields>[] = [
      {
        field: "amount",
        invalidCondition: !amount,
        errorMessageId: "request_offer.amount.error.required",
      },
      {
        field: "amount",
        invalidCondition: amount ? currencyFormatToNumber(amount) <= 0 : false,
        errorMessageId: "request_offer.amount.error.negative_or_zero",
      },
      {
        field: "period",
        invalidCondition: period === "",
        errorMessageId: "request_offer.period.error.required",
      },
      {
        field: "monthlyIncome",
        invalidCondition:
          monthlyIncome !== "" && currencyFormatToNumber(monthlyIncome) <= 0,
        errorMessageId: "request_offer.monthlyIncome.error.negative_or_zero",
      },
      {
        field: "currentLoanAmount",
        invalidCondition:
          currentLoanAmount !== "" &&
          currencyFormatToNumber(currentLoanAmount) <= 0,
        errorMessageId:
          "request_offer.currentLoanAmount.error.negative_or_zero",
      },
      {
        field: "purposingOfFinancing",
        invalidCondition: purposingOfFinancing.trim() === "",
        errorMessageId:
          "request_offer.loan.purposingOfFinancing.error.required",
      },
      {
        field: "filedForBankruptcy",
        invalidCondition: filedForBankruptcy === null,
        errorMessageId: "request_offer.loan.filedForBankruptcy.error.required",
      },
      {
        field: "appliedForIva",
        invalidCondition: appliedForIva === null,
        errorMessageId: "request_offer.loan.appliedForIva.error.required",
      },
      {
        field: "numPersonalLoanForPastTwoWeeks",
        invalidCondition: numPersonalLoanForPastTwoWeeks === null,
        errorMessageId:
          "request_offer.loan.numPersonalLoanForPastTwoWeeks.error.required",
      },
      {
        field: "numOfLoanEnquiriesInOneMonth",
        invalidCondition: numOfLoanEnquiriesInOneMonth === null,
        errorMessageId:
          "request_offer.loan.numOfLoanEnquiriesInOneMonth.error.required",
      },
      {
        field: "hasLatePayment",
        invalidCondition: hasLatePayment === null,
        errorMessageId: "request_offer.loan.hasLatePayment.error.required",
      },
      {
        field: "isTaxReturnFormAvailable",
        invalidCondition: isTaxReturnFormAvailable === null,
        errorMessageId:
          "request_offer.loan.isTaxReturnFormAvailable.error.required",
      },
    ];

    const { focusField, error, isValid } = validate(validators);

    this.setState({ error, focusField });

    return isValid;
  }
}

export default RequestPersonalLoanForm;
