import React from "react";

import {
  FormattedDropdown,
  FormattedDropdownProps,
  Option as FormattedOption,
} from "../FormattedDropdown";

import { Omit } from "../../utils/typeutils";
import { IncomeProofType } from "../../models";

export type IncomeProofTypeDropdownProps = Omit<
  FormattedDropdownProps,
  "options"
>;

export const IncomeProofTypeOptions: FormattedOption[] = [
  {
    key: IncomeProofType.mpf,
    value: IncomeProofType.mpf,
    labelId: "option.incomeProofType.mpf",
  },
  {
    key: IncomeProofType.bankStatement,
    value: IncomeProofType.bankStatement,
    labelId: "option.incomeProofType.bankStatement",
  },
  {
    key: IncomeProofType.letter,
    value: IncomeProofType.letter,
    labelId: "option.incomeProofType.letter",
  },
];

export const IncomeProofTypeDropdown: React.FC<
  IncomeProofTypeDropdownProps
> = props => {
  return (
    <FormattedDropdown options={IncomeProofTypeOptions} {...props as any} />
  );
};
