import React from "react";

import AgentCalculatorView from "../components/AgentCalculatorView";
import { CalculatorType, AgentCalculatorParams } from "../types/calculator";
import { RateToNumber } from "../utils/formatRate";
import {
  currencyFormatToNumber,
  formatToCurrencyAmount,
} from "../utils/formatToCurrencyAmount";
import {
  getCalculatedLoan,
  getCalculatedInterestRate,
  getCalculatedPeriod,
  getCalculatedRepayment,
  getTotalRepayment,
  getTotalCommission,
  calculatorLimit,
} from "../utils/calculator";

class AgentCalculatorScreen extends React.PureComponent {
  state = {
    loan: formatToCurrencyAmount("100000") || "",
    interestRate: "5.15",
    period: "24",
    repayment: formatToCurrencyAmount("4394") || "",
    commissionRate: "5",
    totalRepayment: "105456",
    totalCommission: "5273",
    lockedCalculator: CalculatorType.InterestRate,
  };

  calculateLoan = (
    interestRate: number,
    period: number,
    repayment: number,
    commissionRate: number
  ) => {
    const loan = getCalculatedLoan(interestRate, period, repayment);
    if (isNaN(loan)) {
      this.setState({
        loan: "",
        totalRepayment: "",
        totalCommission: "",
      });
    } else {
      const amount = formatToCurrencyAmount(loan.toString());
      this.setState({
        loan: amount,
        totalRepayment: getTotalRepayment(period, repayment),
        totalCommission: getTotalCommission(period * repayment, commissionRate),
      });
    }
  };

  calculateInterestRate = (
    loan: number,
    period: number,
    repayment: number,
    commissionRate: number
  ) => {
    const interestRate = getCalculatedInterestRate(loan, period, repayment);
    if (isNaN(interestRate)) {
      this.setState({
        interestRate: "",
        totalRepayment: "",
        totalCommission: "",
      });
    } else {
      this.setState({
        interestRate: interestRate.toString(10),
        totalRepayment: getTotalRepayment(period, repayment),
        totalCommission: getTotalCommission(period * repayment, commissionRate),
      });
    }
  };

  calculatePeriod = (
    loan: number,
    interestRate: number,
    repayment: number,
    commissionRate: number
  ) => {
    const calculatorData = getCalculatedPeriod(loan, interestRate, repayment);
    if (calculatorData === null) {
      this.setState({
        period: "",
        totalRepayment: "",
        totalCommission: "",
      });
    } else {
      const { period, totalRepayment } = calculatorData;
      this.setState({
        period: period,
        totalRepayment: formatToCurrencyAmount(
          Math.round(totalRepayment).toString()
        ),
        totalCommission: getTotalCommission(totalRepayment, commissionRate),
      });
    }
  };

  calculateRepayment = (
    loan: number,
    interestRate: number,
    period: number,
    commissionRate: number
  ) => {
    const repayment = getCalculatedRepayment(loan, interestRate, period);
    if (isNaN(repayment)) {
      this.setState({
        repayment: "",
        totalRepayment: "",
        totalCommission: "",
      });
    } else {
      const amount = formatToCurrencyAmount(repayment.toString());
      this.setState({
        repayment: amount,
        totalRepayment: getTotalRepayment(period, repayment),
        totalCommission: getTotalCommission(period * repayment, commissionRate),
      });
    }
  };

  valueChangeHandler(
    calculatorParams: AgentCalculatorParams,
    changedType: CalculatorType
  ) {
    let loan = currencyFormatToNumber(this.state.loan);
    let interestRate = RateToNumber(this.state.interestRate);
    let period = parseInt(this.state.period, 10);
    let repayment = currencyFormatToNumber(this.state.repayment);
    let commissionRate = RateToNumber(this.state.commissionRate);
    switch (changedType) {
      case CalculatorType.Loan:
        loan = NaN;
        if (calculatorParams.loan !== undefined && calculatorParams.loan >= 0) {
          loan = calculatorParams.loan;
        }
        break;
      case CalculatorType.InterestRate:
        interestRate = NaN;
        if (
          calculatorParams.interestRate !== undefined &&
          calculatorParams.interestRate >= 0
        ) {
          interestRate = calculatorParams.interestRate;
        }
        break;
      case CalculatorType.Period:
        period = NaN;
        if (
          calculatorParams.period !== undefined &&
          calculatorParams.period >= 0
        ) {
          period = calculatorParams.period;
        }
        break;
      case CalculatorType.Repayment:
        repayment = NaN;
        if (
          calculatorParams.repayment !== undefined &&
          calculatorParams.repayment >= 0
        ) {
          repayment = calculatorParams.repayment;
        }
        break;
      case CalculatorType.CommissionRate:
        commissionRate = calculatorParams.commissionRate || 0;
        break;
      default:
        break;
    }

    switch (this.state.lockedCalculator) {
      case CalculatorType.Loan:
        this.calculateLoan(interestRate, period, repayment, commissionRate);
        break;
      case CalculatorType.InterestRate:
        this.calculateInterestRate(loan, period, repayment, commissionRate);
        break;
      case CalculatorType.Period:
        this.calculatePeriod(loan, interestRate, repayment, commissionRate);
        break;
      case CalculatorType.Repayment:
        this.calculateRepayment(loan, interestRate, period, commissionRate);
        break;
      default:
        break;
    }
  }

  onLoanChanged = (loan: string) => {
    const amount = currencyFormatToNumber(loan);

    if (amount <= calculatorLimit.maxAmount) {
      this.setState({ loan });
      this.valueChangeHandler({ loan: amount }, CalculatorType.Loan);
    }
  };

  onInterestRateChanged = (interestRate: string) => {
    const rate = RateToNumber(interestRate);
    if (rate <= calculatorLimit.maxInterestRate) {
      this.setState({ interestRate });
      this.valueChangeHandler(
        { interestRate: rate },
        CalculatorType.InterestRate
      );
    }
  };

  onPeriodChanged = (period: string) => {
    const monthPeriod = parseInt(period, 10);
    if (monthPeriod <= calculatorLimit.maxPeriod || isNaN(monthPeriod)) {
      this.setState({ period });
      this.valueChangeHandler({ period: monthPeriod }, CalculatorType.Period);
    }
  };

  onRepaymentChanged = (repayment: string) => {
    const amount = currencyFormatToNumber(repayment);
    if (amount <= calculatorLimit.maxAmount) {
      this.setState({ repayment });
      this.valueChangeHandler({ repayment: amount }, CalculatorType.Repayment);
    }
  };

  onCommissionRateChanged = (commissionRate: string) => {
    const rate = RateToNumber(commissionRate);
    if (rate <= calculatorLimit.maxCommissionRate) {
      this.setState({ commissionRate: commissionRate });
      this.valueChangeHandler(
        { commissionRate: rate },
        CalculatorType.CommissionRate
      );
    }
  };

  lockClicked = (calculatorType: CalculatorType) => {
    this.setState({ lockedCalculator: calculatorType });
  };

  render() {
    const {
      loan,
      interestRate,
      period,
      repayment,
      commissionRate,
      lockedCalculator,
      totalRepayment,
      totalCommission,
    } = this.state;
    return (
      <AgentCalculatorView
        loan={loan}
        interestRate={interestRate}
        period={period}
        repayment={repayment}
        commissionRate={commissionRate}
        lockedCalculator={lockedCalculator}
        totalRepayment={totalRepayment}
        totalCommission={totalCommission}
        onLoanChanged={this.onLoanChanged}
        onInterestRateChanged={this.onInterestRateChanged}
        onPeriodChanged={this.onPeriodChanged}
        onRepaymentChanged={this.onRepaymentChanged}
        onCommissionRateChanged={this.onCommissionRateChanged}
        lockClicked={this.lockClicked}
      />
    );
  }
}

export default AgentCalculatorScreen;
