import { RouteComponentProps, withRouter } from "react-router";
import React, { PureComponent } from "react";

import {
  withErrorAlert,
  ErrorAlertContextProps,
} from "../context/ErrorAlertContext";
import { withLoading, LoadingContextProps } from "../context/LoadingContext";
import { withUser, UserContextProps } from "../context/UserContext";
import AgentQuickOfferView from "../components/AgentQuickOfferView";
import { Agent } from "../models";

type Props = ErrorAlertContextProps &
  LoadingContextProps &
  RouteComponentProps &
  UserContextProps;

class AgentQuickOfferScreen extends PureComponent<Props> {
  onClickCreateOffer = () => {
    this.props.history.push("manage/create-template");
  };

  onClickDeleteOffer = (target: number) => {
    const { userContext } = this.props;

    const agent = userContext.user as Agent;
    const newOfferingTemplate = agent.offeringTemplate.filter(
      (_, index) => target !== index
    );

    this.props.loadingContext.show();

    this.props.userContext
      .updateOfferingTemplate(newOfferingTemplate)
      .catch(error => {
        this.props.errorAlertContext.show(error);
      })
      .finally(this.props.loadingContext.dismiss);
  };

  render() {
    return (
      <AgentQuickOfferView
        onClickCreateOffer={this.onClickCreateOffer}
        onClickDeleteOffer={this.onClickDeleteOffer}
      />
    );
  }
}

export default withLoading(
  withErrorAlert(withRouter(withUser(AgentQuickOfferScreen)))
);
