import React from "react";

import {
  FormattedDropdown,
  FormattedDropdownProps,
  Option as FormattedOption,
} from "../FormattedDropdown";

import { Omit } from "../../utils/typeutils";
import { Education } from "../../models";

export type EducationDropdownProps = Omit<FormattedDropdownProps, "options">;

export const EducationOptions: FormattedOption[] = [
  {
    key: Education.diploma,
    value: Education.diploma,
    labelId: "education.diploma",
  },
  {
    key: Education.higherDiploma,
    value: Education.higherDiploma,
    labelId: "education.higher_diploma",
  },
  {
    key: Education.bachelor,
    value: Education.bachelor,
    labelId: "education.bachelor",
  },
  {
    key: Education.postGraduate,
    value: Education.postGraduate,
    labelId: "education.post_graduate",
  },
];

export const EducationDropdown: React.FC<EducationDropdownProps> = props => {
  return <FormattedDropdown options={EducationOptions} {...props as any} />;
};
