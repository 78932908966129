import React from "react";
import {
  FormattedMessage,
  Context as LocaleContext,
} from "@oursky/react-messageformat";
import { IonHeader, IonToolbar, IonPage, IonAlert } from "@ionic/react";

import styles from "./styles.module.scss";
import { ListView } from "./ListView";

import { MPContent } from "../MPContent";
import { BackButton } from "../BackButton";
import { ListRefreshControl } from "../ListRefreshControl";
import { InfiniteScrollView } from "../InfiniteListView";

import { Page } from "../../utils/pagination";
import { Chat } from "../../models";

interface Props {
  listVersion: number;
  requestRefNum: number;
  fetchPage: (cursor?: string) => Promise<Page<Chat>>;
  onCancelRequest: () => void;
  openChatroom: (chat: Chat) => void;
  infiniteScrollViewRef: React.RefObject<InfiniteScrollView<Chat>>;
}

interface State {
  showAlert: boolean;
}

export class ChatOfferingListView extends React.PureComponent<Props, State> {
  state: State = {
    showAlert: false,
  };

  closeAlert = () => {
    this.setState({ showAlert: false });
  };

  openAlert = () => {
    this.setState({ showAlert: true });
  };

  onConfirmCancel = () => {
    this.closeAlert();
    this.props.onCancelRequest();
  };

  render() {
    const {
      requestRefNum,
      fetchPage,
      openChatroom,
      listVersion,
      infiniteScrollViewRef,
    } = this.props;
    const { showAlert } = this.state;

    return (
      <IonPage className={styles.outerContainer}>
        <IonHeader mode="ios" className={styles.header}>
          <IonToolbar mode="ios">
            <div className={styles.back}>
              <BackButton />
            </div>
            <div className={styles.title}>
              <FormattedMessage id="chat.request.title" />
            </div>
            <div className={styles.refNum}>{`#${requestRefNum}`}</div>
            <button className={styles.cancelRequest} onClick={this.openAlert}>
              <FormattedMessage id="chat.request.cancel_request" />
            </button>
          </IonToolbar>
        </IonHeader>
        <LocaleContext.Consumer>
          {({ renderToString }) => (
            <MPContent className={styles.list}>
              <ListRefreshControl listVersion={listVersion} routePrefix="/chat">
                <ListView
                  fetchPage={fetchPage}
                  openChatroom={openChatroom}
                  infiniteScrollViewRef={infiniteScrollViewRef}
                />
              </ListRefreshControl>
              <IonAlert
                isOpen={showAlert}
                onDidDismiss={this.closeAlert}
                message={renderToString("chat.request.alert.cancel_message")}
                buttons={[
                  {
                    text: renderToString("chat.request.alert.back"),
                    handler: this.closeAlert,
                  },
                  {
                    text: renderToString("chat.request.alert.confirm"),
                    handler: this.onConfirmCancel,
                  },
                ]}
              />
            </MPContent>
          )}
        </LocaleContext.Consumer>
      </IonPage>
    );
  }
}

export default ChatOfferingListView;
