import React from "react";
import classNames from "classnames";
import numeral from "numeral";
import { FormattedMessage } from "@oursky/react-messageformat";

import styles from "./styles.module.scss";

import { renderMortgageLevel } from "../PublicOfferListAndOfferRequestsCardView/PublicOfferListCardView";

import {
  Chat,
  ChatType,
  ExchangeLoanChat,
  MortgageChat,
  PersonalLoanChat,
} from "../../models";
import { getChatroomStatusMessageId } from "../../utils/chat";

interface ChatOfferingListExchangeCardViewProps {
  chat: ExchangeLoanChat;
  openChatroom: (chat: Chat) => void;
}

const ChatOfferingListExchangeCardView: React.FC<
  ChatOfferingListExchangeCardViewProps
> = props => {
  const { chat, openChatroom } = props;

  const onMessageClick = React.useCallback(() => {
    openChatroom(chat);
  }, [chat, openChatroom]);

  const progressFormattedMessageId = getChatroomStatusMessageId(
    !!chat.completedAt,
    chat.referralAgreementStatus
  );

  return (
    <button className={styles.cardContainer} onClick={onMessageClick}>
      {!!chat.unreadCount && <div className={styles.unreadIndicator} />}
      <div className={styles.type}>
        <FormattedMessage id={"chat.request.exchange"} />
      </div>

      <div className={styles.agentContainer}>
        <div>{chat.offering.agent.company.name}</div>
      </div>

      <div className={styles.containerFlexRow}>
        <div className={styles.amountContainer}>
          <FormattedMessage
            id="chat.request.amount.title"
            values={{ toCurrency: chat.offering.sellingCurrency.isoCode }}
          />
          <div className={styles.figures}>
            {numeral(chat.offering.sellingAmount).format()}
          </div>
        </div>
        <div className={styles.currencyContainer}>
          <FormattedMessage id="chat.request.currency" />
          <div className={styles.figures}>
            {`${chat.offering.buyingCurrency.isoCode} - ${
              chat.offering.sellingCurrency.isoCode
            }`}
          </div>
        </div>
        <div
          className={classNames(
            styles.progress,
            { [styles.inProgress]: !chat.completedAt },
            { [styles.completed]: chat.completedAt }
          )}
        >
          <FormattedMessage id={progressFormattedMessageId} />
        </div>
      </div>
      <div className={styles.line} />
      <div className={styles.reply}>
        <FormattedMessage id="chat.request.reply" />
      </div>
    </button>
  );
};

interface ChatOfferingListMortgageCardViewProps {
  chat: MortgageChat;
  openChatroom: (chat: Chat) => void;
}

const ChatOfferingListMortgageCardView: React.FC<
  ChatOfferingListMortgageCardViewProps
> = props => {
  const { chat, openChatroom } = props;

  const onMessageClick = React.useCallback(() => {
    openChatroom(chat);
  }, [chat, openChatroom]);

  const progressFormattedMessageId = getChatroomStatusMessageId(
    !!chat.completedAt,
    chat.referralAgreementStatus
  );

  return (
    <button className={styles.cardContainer} onClick={onMessageClick}>
      {!!chat.unreadCount && <div className={styles.unreadIndicator} />}
      <div className={styles.type}>
        <FormattedMessage id={"chat.request.mortgage"} />
      </div>

      <div className={styles.agentContainer}>
        <div>{chat.offering.agent.company.name}</div>
      </div>
      <div
        className={classNames(
          styles.progress,
          { [styles.inProgress]: !chat.completedAt },
          { [styles.completed]: chat.completedAt }
        )}
      >
        <FormattedMessage id={progressFormattedMessageId} />
      </div>

      <div className={styles.containerFlexRow}>
        <div className={styles.ratioContainer}>
          <FormattedMessage id="chat.request.loan_amount.title" />
          <div className={styles.figures}>{`${numeral(
            chat.offering.loanAmount
          ).format()}`}</div>
        </div>
        <div className={styles.levelContainer}>
          <FormattedMessage id="chat.request.mortgage.mortgage_level.title" />
          <div className={styles.figures}>
            {renderMortgageLevel(chat.request.mortgageLevel)}
          </div>
        </div>
      </div>
      <div className={styles.containerFlexRow}>
        <div className={styles.interestContainer}>
          <FormattedMessage id="chat.request.interest.title" />
          <div className={styles.figures}>
            <FormattedMessage
              id="chat.request.interest"
              values={{
                minInterest: chat.offering.interestRateMin,
                maxInterest: chat.offering.interestRateMax,
              }}
            />
          </div>
        </div>
        <div className={styles.repaymentPeriodContainer}>
          <FormattedMessage id="chat.request.repayment_period.title" />
          <div className={styles.figures}>
            <FormattedMessage
              id="chat.request.max_repayment_period"
              values={{
                maxMonth: chat.offering.paymentPeriodMax,
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.line} />
      <div className={styles.reply}>
        <FormattedMessage id="chat.request.reply" />
      </div>
    </button>
  );
};

interface ChatOfferingListLoanCardViewProps {
  chat: PersonalLoanChat;
  openChatroom: (chat: Chat) => void;
}

const ChatOfferingListLoanCardView: React.FC<
  ChatOfferingListLoanCardViewProps
> = props => {
  const { chat, openChatroom } = props;

  const onMessageClick = React.useCallback(() => {
    openChatroom(chat);
  }, [chat, openChatroom]);

  const progressFormattedMessageId = getChatroomStatusMessageId(
    !!chat.completedAt,
    chat.referralAgreementStatus
  );

  return (
    <button className={styles.cardContainer} onClick={onMessageClick}>
      {!!chat.unreadCount && <div className={styles.unreadIndicator} />}
      <div className={styles.type}>
        <FormattedMessage id={"chat.request.loan"} />
      </div>

      <div className={styles.agentContainer}>
        <div>{chat.offering.agent.company.name}</div>
      </div>

      <div className={styles.containerFlexRow}>
        <div className={styles.amountContainer}>
          <FormattedMessage
            id="chat.request.amount.title"
            values={{ toCurrency: "HKD" }}
          />
          <div className={styles.figures}>
            {`${numeral(chat.offering.amountMin).format()} - 
              ${numeral(chat.offering.amountMax).format()}`}
          </div>
        </div>
        <div className={styles.interestContainer}>
          <FormattedMessage id="chat.request.interest.title" />
          <div className={styles.figures}>
            <FormattedMessage
              id="chat.request.interest"
              values={{
                minInterest: chat.offering.interestRateMin,
                maxInterest: chat.offering.interestRateMax,
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.containerFlexRow}>
        <div className={styles.repaymentPeriodContainer}>
          <FormattedMessage id="chat.request.repayment_period.title" />
          <div className={styles.figures}>
            <FormattedMessage
              id="chat.request.repayment_period"
              values={{
                minMonth: chat.offering.periodMin,
                maxMonth: chat.offering.periodMax,
              }}
            />
          </div>
        </div>
        <div
          className={classNames(
            styles.progress,
            { [styles.inProgress]: !chat.completedAt },
            { [styles.completed]: chat.completedAt }
          )}
        >
          <FormattedMessage id={progressFormattedMessageId} />
        </div>
      </div>
      <div className={styles.line} />
      <div className={styles.reply}>
        <FormattedMessage id="chat.request.reply" />
      </div>
    </button>
  );
};

export interface ChatOfferingListCardViewProps {
  chat: Chat;
  openChatroom: (chat: Chat) => void;
}

export const ChatOfferingListCardView: React.FC<
  ChatOfferingListCardViewProps
> = props => {
  const { chat, openChatroom } = props;
  switch (chat.type) {
    case ChatType.exchangeLoan:
      return (
        <ChatOfferingListExchangeCardView
          chat={chat}
          openChatroom={openChatroom}
        />
      );
    case ChatType.mortgage:
      return (
        <ChatOfferingListMortgageCardView
          chat={chat}
          openChatroom={openChatroom}
        />
      );
    case ChatType.personalLoan:
      return (
        <ChatOfferingListLoanCardView chat={chat} openChatroom={openChatroom} />
      );
    default:
      throw new Error(`Unexpected offering type in borrower chat screen`);
  }
};
