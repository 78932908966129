import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import React from "react";

import { BorrowerPropertyInfo } from "../../models";
import { ValidationError } from "../../utils/validate";

import { Option } from "../DropdownPicker";
import { FormattedDropdown } from "../FormattedDropdown";
import ErrorField from "../ErrorField";
import YesNoPicker from "../YesNoPicker";
import {
  FormInputFields,
  PropertyInfoForm,
  FormValues as PropertyInfoFormValues,
  covertPropertyInfoToFormValues,
} from "./PropertyInfoForm";

import styles from "./styles.module.scss";

export type InputFields =
  | "hasProperty"
  | "numberOfProperties"
  | FormInputFields;

interface Props {
  error: ValidationError<InputFields>;
  borrowerPropertyInfo: BorrowerPropertyInfo;
  updateExistingPropertyInfo: (
    index: number,
    value: Partial<PropertyInfoFormValues>
  ) => void;
  updateHasProperty: (hasProperty: boolean) => void;
  selectNumberOfProperties?: (numberOfProperties: number) => void;
  removeExistingProperty?: (index: number) => void;
  addExistingProperty?: () => void;
  focusField?: InputFields;
}

interface State {
  hasProperty: boolean | null;
}

export class BorrowerPropertyInfoView extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasProperty:
        props.borrowerPropertyInfo.numberOfProperties !== null
          ? props.borrowerPropertyInfo.numberOfProperties > 0
          : null,
    };
  }
  propertyNumberOptions: Option[] = Array(20)
    .fill(0)
    .map((_, i) => {
      const value = (i + 1).toString();
      return {
        key: value,
        value: value,
        display: value,
      };
    });

  onSelectPropertyNumber = (value: string) => {
    if (this.props.selectNumberOfProperties) {
      this.props.selectNumberOfProperties(parseInt(value, 10));
    }
  };

  handleUpdateHasProperty = (value: boolean) => {
    this.setState({
      hasProperty: value,
    });
    this.props.updateHasProperty(value);
  };

  render() {
    const { focusField, error, addExistingProperty } = this.props;
    const { hasProperty } = this.state;
    const {
      propertyInfos,
      numberOfProperties,
    } = this.props.borrowerPropertyInfo;
    const showAddButton =
      addExistingProperty &&
      this.state.hasProperty &&
      propertyInfos.length < 20;

    const propertyInfoDisabled = !this.state.hasProperty;

    return (
      <div className={styles.container}>
        <div className={styles.hasPropertyContainer}>
          <YesNoPicker
            labelId="borrower_property_info.has_property"
            onClickOption={this.handleUpdateHasProperty}
            value={hasProperty === null ? undefined : hasProperty}
            positiveLabelId="borrower_property_info.has_property.yes"
            negativeLabelId="borrower_property_info.has_property.no"
            shouldFocus={focusField === "hasProperty"}
          />
          <ErrorField
            isShown={error["hasProperty"] !== undefined}
            hiddenType="gone"
            messageId={error["hasProperty"]}
          />
        </div>

        {this.props.selectNumberOfProperties && (
          <div
            className={classNames(
              styles.fieldContainer,
              styles.numberOfPropertiesContainer
            )}
            data-anchor="numberOfProperties"
          >
            <div
              className={classNames(styles.fieldTitle, {
                [styles.disabled]: propertyInfoDisabled,
              })}
            >
              <FormattedMessage id="borrower_property_info.numberOfProperties" />
            </div>
            <FormattedDropdown
              disabled={propertyInfoDisabled}
              containerClass={styles.dropdownContainer}
              selectClass={styles.dropdownSelect}
              errorClass={styles.dropdownError}
              emptyClass={styles.dropdownEmpty}
              isError={error["numberOfProperties"] !== undefined}
              options={this.propertyNumberOptions}
              value={numberOfProperties || ""}
              onValueChange={this.onSelectPropertyNumber}
              shouldScrollTo={focusField === "numberOfProperties"}
              scrollAnchor="numberOfProperties"
              placeholderId={
                "borrower_property_info.numberOfProperties.placeholder"
              }
            />
            <ErrorField
              isShown={error["numberOfProperties"] !== undefined}
              hiddenType="gone"
              messageId={error["numberOfProperties"]}
            />
          </div>
        )}
        {propertyInfos.map((propertyInfo, index) => (
          <PropertyInfoForm
            key={index}
            index={index}
            onChange={this.props.updateExistingPropertyInfo}
            onRemove={this.props.removeExistingProperty}
            propertyInfo={covertPropertyInfoToFormValues(propertyInfo)}
            error={error}
            focusField={
              (focusField !== "hasProperty" &&
                focusField !== "numberOfProperties" &&
                focusField) ||
              undefined
            }
          />
        ))}
        {showAddButton && (
          <div className={styles.addButtonContainer}>
            <div onClick={addExistingProperty} className={styles.addButton}>
              <FormattedMessage id="borrower_property_info_form.add" />
            </div>
          </div>
        )}
      </div>
    );
  }
}
