import skygear, { Record } from "skygear";
import * as yup from "yup";

import { BaseApiClient, ApiClientConstructor } from "./client";

import { UserType } from "../models";

import {
  agentImpersonateResponseSchema,
  sendSmsResponseSchema,
  verifyCodeResponseSchema,
} from "../types/response";

import {
  userDataSchema,
  agentDataSchema,
  borrowerDataSchema,
} from "../schemas/user";

import {
  WhoamiResponseUser,
  Registered,
  NotComplete,
  UserNotFound,
} from "../utils/whoamiResponseUser";

import { parseError, APIError, APIErrorCode } from "../error";

export interface UserApiClient {
  sendSignupSms: (
    phoneNumber: string,
    type: UserType,
    isAcceptedContact: boolean,
    email?: string
  ) => Promise<string>;
  sendLoginSms: (phoneNumber: string) => Promise<string>;
  verifyCode: (verificationCodeId: string, code: string) => Promise<string>;
  loginWithToken: (token: string) => Promise<Record>;
  logout: () => Promise<void>;
  whoami: () => Promise<WhoamiResponseUser>;
  block: (userId: string) => Promise<void>;
  unblock: (userId: string) => Promise<void>;
  report: (userId: string, chatId: string) => Promise<void>;
  impersonateAgent: (
    userId: string,
    impersonatorCustomToken?: string
  ) => Promise<string>;
}

export function withUserApi<TBase extends ApiClientConstructor<BaseApiClient>>(
  Base: TBase
) {
  return class extends Base {
    // return verificationCodeId
    async sendSignupSms(
      phoneNumber: string,
      type: UserType,
      isAcceptedContact: boolean,
      email?: string
    ): Promise<string> {
      const data: any = { type, is_accepted_contact: isAcceptedContact };
      if (email) {
        data.email = email;
      }

      const resp = await this.requestLambda(
        "user:send-sms",
        {
          phone_number: phoneNumber,
          is_signup: true,
          data,
        },
        sendSmsResponseSchema
      );

      return resp.verificationCodeId;
    }

    async sendLoginSms(phoneNumber: string): Promise<string> {
      const resp = await this.requestLambda(
        "user:send-sms",
        {
          phone_number: phoneNumber,
          is_signup: false,
        },
        sendSmsResponseSchema
      );

      return resp.verificationCodeId;
    }

    async verifyCode(
      verificationCodeId: string,
      code: string
    ): Promise<string> {
      const resp = await this.requestLambda(
        "user:verify-code",
        {
          verification_code_id: verificationCodeId,
          code,
        },
        verifyCodeResponseSchema
      );

      return resp.token;
    }

    loginWithToken(token: string): Promise<Record> {
      return skygear.auth.loginWithCustomToken(token);
    }

    async logout(): Promise<void> {
      try {
        await skygear.auth.logout();
      } catch (e) {
        // ignore error sliently, failure to logout doesn't affect logic

        // eslint-disable-next-line no-console
        console.warn("Failed to logout", e);
      }
    }

    whoami(): Promise<WhoamiResponseUser> {
      return this.requestLambda("user:me", {}, yup.mixed()).then(
        data => {
          const userData = userDataSchema.validateSync(data);

          try {
            switch (userData.type) {
              case UserType.agent:
                return Registered({
                  ...userData,
                  ...agentDataSchema.validateSync(userData),
                });
              case UserType.borrower:
                return Registered({
                  ...userData,
                  ...borrowerDataSchema.validateSync(userData),
                });
              default:
                throw new Error(`Unknowne UserType = ${userData.type}`);
            }
          } catch {
            return NotComplete(userData);
          }
        },
        e => {
          const error = parseError(e);
          if (
            error instanceof APIError &&
            error.code === APIErrorCode.userNotFound
          ) {
            return UserNotFound();
          }

          throw error;
        }
      );
    }

    block(userId: string): Promise<void> {
      return this.requestLambda("user:block", {
        user_id: userId,
      });
    }

    unblock(userId: string): Promise<void> {
      return this.requestLambda("user:unblock", {
        user_id: userId,
      });
    }

    report(userId: string, chatId: string): Promise<void> {
      return this.requestLambda("user:report", {
        user_id: userId,
        chat_id: chatId,
      });
    }

    async impersonateAgent(
      userId: string,
      impersonatorCustomToken?: string
    ): Promise<string> {
      const data: any = {
        user_id: userId,
      };
      if (impersonatorCustomToken) {
        data.impersonator_custom_token = impersonatorCustomToken;
      }
      const resp = await this.requestLambda(
        "user:impersonate-agent",
        data,
        agentImpersonateResponseSchema
      );

      return resp.token;
    }
  };
}
