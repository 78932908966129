import React from "react";
import classNames from "classnames";
import { IonButtons, IonHeader, IonToolbar } from "@ionic/react";

import styles from "./styles.module.scss";

interface Props {
  buttons?: React.ReactNode;
  logoClass?: string;
}

export const AgentHeader: React.FC<Props> = props => {
  const { buttons, logoClass } = props;
  return (
    <IonHeader mode="ios">
      <IonToolbar mode="ios">
        <div className={classNames(styles.logoContainer, logoClass)} />
        {buttons ? (
          <IonButtons slot="end" className={styles.headerButtons}>
            {buttons}
          </IonButtons>
        ) : null}
      </IonToolbar>
      {props.children}
    </IonHeader>
  );
};
