import * as yup from "yup";

import {
  personalLoanOfferingSchema,
  mortgageOfferingSchema,
  exchangeLoanOfferingSchema,
} from "./offering";
import {
  personalLoanRequestSchema,
  mortgageRequestSchema,
  exchangeLoanRequestSchema,
} from "./request";
import { DateTimeSchema } from "./luxon";
import {
  ChatType,
  Chat,
  ExchangeLoanChat,
  MortgageChat,
  PersonalLoanChat,
  Conversation,
  ReferralAgreementStatus,
} from "../models/chat";

export const baseChatSchema = {
  id: yup.string().required(),
  refNum: yup.number().required(),
  conversationId: yup.string().nullable(),
  completedAt: new DateTimeSchema().nullable(),
  unreadCount: yup.number().nullable(),
  referralAgreementStatus: yup
    .string()
    .oneOf([
      ReferralAgreementStatus.requested,
      ReferralAgreementStatus.accepted,
      null,
    ])
    .nullable() as yup.Schema<ReferralAgreementStatus>,
};

function chatTypeSchema(chatType: ChatType) {
  return yup.mixed().oneOf([chatType], "Unexpected Chat Type");
}

export const personalLoanChatSchema: yup.ObjectSchema<PersonalLoanChat> = yup
  .object({
    type: chatTypeSchema(ChatType.personalLoan),
    request: personalLoanRequestSchema.required(),
    offering: personalLoanOfferingSchema.required(),
  })
  .shape(baseChatSchema)
  .camelCase();

export const mortgageChatSchema: yup.ObjectSchema<MortgageChat> = yup
  .object({
    type: chatTypeSchema(ChatType.mortgage),
    request: mortgageRequestSchema.required(),
    offering: mortgageOfferingSchema.required(),
  })
  .shape(baseChatSchema)
  .camelCase();

export const exchangeLoanChatSchema: yup.ObjectSchema<ExchangeLoanChat> = yup
  .object({
    type: chatTypeSchema(ChatType.exchangeLoan),
    request: exchangeLoanRequestSchema.required(),
    offering: exchangeLoanOfferingSchema.required(),
  })
  .shape(baseChatSchema)
  .camelCase();

export const chatSchema = yup.object<Chat>().when(".type", (type: any) => {
  switch (type) {
    case ChatType.exchangeLoan:
      return exchangeLoanChatSchema;
    case ChatType.mortgage:
      return mortgageChatSchema;
    case ChatType.personalLoan:
      return personalLoanChatSchema;
    default:
      throw new Error(`Unexpected type = ${type}`);
  }
});

export const conversationSchema = yup
  .object<Conversation>({
    id: yup.string().required(),
    title: yup.string().required(),
    distinctByParticipants: yup.boolean().required(),
    metadata: yup.mixed(),
  })
  .from("_id", "id", true)
  .camelCase();
