export type RemoteData<T> = Initial | Loading | Success<T> | Failure;

export enum RemoteDataType {
  initial = "initial",
  loading = "loading",
  success = "success",
  failure = "failure",
}

export interface Initial {
  type: RemoteDataType.initial;
}

export function Initial(): Initial {
  return { type: RemoteDataType.initial };
}

export interface Loading {
  type: RemoteDataType.loading;
}

export function Loading(): Loading {
  return { type: RemoteDataType.loading };
}

export function isLoading<T>(remote: RemoteData<T>): remote is Loading {
  return remote.type === RemoteDataType.loading;
}

export interface Success<T> {
  type: RemoteDataType.success;
  value: T;
}

export function Success<T>(v: T): Success<T> {
  return { type: RemoteDataType.success, value: v };
}

export function isSuccess<T>(remote: RemoteData<T>): remote is Success<T> {
  return remote.type === RemoteDataType.success;
}

export interface Failure {
  type: RemoteDataType.failure;
  error: Error;
}

export function Failure(error: Error): Failure {
  return { type: RemoteDataType.failure, error };
}

export function isFailure<T>(remote: RemoteData<T>): remote is Failure {
  return remote.type === RemoteDataType.failure;
}

export function error<T>(remote: RemoteData<T>): Error | undefined {
  if (remote.type !== RemoteDataType.failure) {
    return undefined;
  }

  return remote.error;
}

export function value<T>(remote: RemoteData<T>): T | undefined {
  if (remote.type !== RemoteDataType.success) {
    return undefined;
  }

  return remote.value;
}
