import React from "react";
import PinchZoom from "pinch-zoom-js";
import classnames from "classnames";

import { ValueWatcher } from "../ValueWatcher";

import styles from "./styles.module.scss";

interface Props {
  url?: string;
  onClose: () => void;
  onDownload: () => void;
}

export class ImageModal extends React.PureComponent<Props> {
  imageRef = React.createRef<HTMLImageElement>();
  pinZoom?: PinchZoom;

  onImageChange = (_url: string) => {
    if (this.imageRef.current) {
      this.pinZoom = new PinchZoom(this.imageRef.current, {
        draggableUnzoomed: false,
        lockDragAxis: true,
        use2d: false,
      });
      this.pinZoom.enable();
    }
  };

  render() {
    const { url } = this.props;

    return url ? (
      <div className={styles.imageModal}>
        <ValueWatcher value={url} trigger={this.onImageChange} />
        <div className={styles.buttons}>
          <div
            className={classnames(styles.button, styles.downloadButton)}
            onClick={this.props.onDownload}
          />
          <div
            className={classnames(styles.button, styles.closeButton)}
            onClick={this.props.onClose}
          />
        </div>
        <img ref={this.imageRef} src={url} alt="attachment" />
      </div>
    ) : null;
  }
}
