import React, { PureComponent } from "react";

import { focusElement } from "../../utils/focus";
import { Omit } from "../../utils/typeutils";
import { scrollToElement } from "../../utils/scroll";

export interface InputProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "ref"
  > {
  shouldFocus?: boolean;
  shouldScrollTo?: boolean;
  scrollAnchor?: string;
  autoSubmit?: () => void;
}

// eslint-disable-next-line react/prefer-stateless-function
export class Input extends PureComponent<InputProps> {
  inputRef = React.createRef<HTMLInputElement>();
  hasKeyPressEventListener = false;

  componentDidMount() {
    const { autoFocus } = this.props;

    if (autoFocus) {
      setTimeout(() => {
        focusElement(this.inputRef.current);
      }, 500);
    }

    if (this.props.autoSubmit) {
      this.registerKeyPressHandler();
    }

    if (this.props.type === "date") {
      if (this.inputRef.current && this.props.value) {
        this.inputRef.current.value = this.props.value.toString();
      }
    }
  }

  private registerKeyPressHandler() {
    if (this.inputRef.current && !this.hasKeyPressEventListener) {
      this.hasKeyPressEventListener = true;
      this.inputRef.current.addEventListener("keypress", this.onKeyPress);
    }
  }

  private unregisterKeyPressHandler() {
    if (this.inputRef.current && this.hasKeyPressEventListener) {
      this.inputRef.current.removeEventListener("keypress", this.onKeyPress);
      this.hasKeyPressEventListener = false;
    }
  }

  componentDidUpdate(prevProps: InputProps) {
    if (
      prevProps.shouldFocus !== this.props.shouldFocus &&
      this.props.shouldFocus
    ) {
      focusElement(this.inputRef.current);
    }

    if (this.props.autoSubmit) {
      this.registerKeyPressHandler();
    } else {
      this.unregisterKeyPressHandler();
    }

    if (
      prevProps.shouldScrollTo !== this.props.shouldScrollTo &&
      this.props.shouldScrollTo
    ) {
      scrollToElement(this.inputRef.current, this.props.scrollAnchor);
    }
  }

  componentWillUnmount() {
    this.unregisterKeyPressHandler();
  }

  onKeyPress = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (this.props.autoSubmit) {
        this.props.autoSubmit();
      }
    }
  };

  render() {
    const {
      autoFocus: _autoFocus,
      shouldFocus: _shouldFocus,
      shouldScrollTo: _shouldScrollTo,
      scrollAnchor: _scrollAnchor,
      autoSubmit: _autoSubmit,
      value: _value,
      ...rest
    } = this.props;

    if (this.props.type === "date")
      return <input ref={this.inputRef} {...rest} />;
    return <input ref={this.inputRef} value={this.props.value} {...rest} />;
  }
}
