import { IonModal, IonRippleEffect } from "@ionic/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import React, { PureComponent } from "react";
import { OptionsProps, withOptions } from "../../context/OptionsContext";

import { District } from "../../models";
import { scrollToElement } from "../../utils/scroll";

import { DistrictListView } from "./DistrictListView";
import styles from "./styles.module.scss";

export interface DistrictPickerProps {
  className?: string;
  arrowClass?: string;
  options: District[];
  value?: District | null;
  onChange?: (district: District) => void;
  isError?: boolean;
  shouldFocus?: boolean;
  shouldScrollTo?: boolean;
  scrollAnchor?: string;
  placeholderId?: string;
}

type Props = OptionsProps & DistrictPickerProps;

interface State {
  isOpen: boolean;
}

export class DistrictPickerImpl extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  ref = React.createRef<HTMLInputElement>();

  componentDidUpdate(prevProps: DistrictPickerProps) {
    if (
      prevProps.shouldFocus !== this.props.shouldFocus &&
      this.props.shouldFocus
    ) {
      this.setState({ isOpen: true });
    }

    if (
      prevProps.shouldScrollTo !== this.props.shouldScrollTo &&
      this.props.shouldScrollTo
    ) {
      scrollToElement(this.ref.current, this.props.scrollAnchor);
    }
  }

  render() {
    const {
      isError,
      value,
      options,
      className,
      arrowClass,
      placeholderId,
    } = this.props;
    const { isOpen } = this.state;

    return (
      <>
        <div
          className={classNames("ion-activatable", className, styles.button, {
            [styles.buttonInactive]: value == null,
            [styles.error]: isError,
          })}
          onClick={this.handleClick}
          ref={this.ref}
        >
          <IonRippleEffect />
          {value ? (
            <FormattedMessage id={value.nameId} />
          ) : (
            <FormattedMessage
              id={placeholderId || "district_picker.placeholder"}
            />
          )}
          <div className={classNames(styles.rightArrow, arrowClass)} />
        </div>
        <IonModal isOpen={isOpen} onDidDismiss={this.handleModalDismiss}>
          <DistrictListView
            onClose={this.handleModalClose}
            onChange={this.handleDistrictChange}
            options={options.districtOptions}
          />
        </IonModal>
      </>
    );
  }

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleModalDismiss = () => {
    this.setState({ isOpen: false });
  };

  handleModalClose = () => {
    this.setState({ isOpen: false });
  };

  handleDistrictChange = (district: District) => {
    const { onChange } = this.props;

    this.setState({ isOpen: false });

    if (onChange) {
      onChange(district);
    }
  };
}

export const DistrictPicker = withOptions(DistrictPickerImpl);
