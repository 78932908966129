import { IonItem } from "@ionic/react";
import React, { useCallback } from "react";
import { AgentBriefData } from "../../models";
import AgentInfoRow from "../AgentInfoRow";

import styles from "./AgentItem.module.scss";

interface Props {
  agentData: AgentBriefData;
  onClickAgent: (agentData: AgentBriefData) => void;
}

const AgentItem: React.FC<Props> = props => {
  const { agentData, onClickAgent } = props;

  const handleClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      onClickAgent(agentData);
    },
    [agentData, onClickAgent]
  );

  return (
    <IonItem onClick={handleClick} className={styles.item}>
      <div className={styles.agentInfoRowContainer}>
        <AgentInfoRow
          name={agentData.name}
          company={agentData.company.name}
          logo={agentData.company.logo}
        />
      </div>
    </IonItem>
  );
};

export default AgentItem;
