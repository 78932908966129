import React from "react";
import { getScrollElement } from "../../utils/ionic-patch";

interface Props {
  position?: "bottom" | "top";
  threshold: number;
  disabled: boolean;
  fetch: (complete: () => void) => void;
}

export class InfiniteScroll extends React.PureComponent<Props> {
  container = React.createRef<HTMLDivElement>();
  scrollElement?: HTMLElement;
  isScrollHandlerRegistered = false;
  isBusy = false;
  isLoading = false;
  didFire = false;

  async componentDidMount() {
    await this.setupScrollElement();
  }

  private async setupScrollElement() {
    const contentElement = this.container.current
      ? this.container.current.closest("ion-content")
      : undefined;

    if (!contentElement) {
      return;
    }

    this.scrollElement = await getScrollElement(contentElement);
    if (!this.props.disabled) {
      this.registerScrollHandler();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.disabled !== this.props.disabled) {
      if (this.props.disabled) {
        this.unregisterScrollHandler();
      } else {
        this.registerScrollHandler();
      }
    }
  }

  componentWillUnmount() {
    this.unregisterScrollHandler();
  }

  onScroll = () => {
    if (!this.scrollElement || !this.canStart() || !this.container.current) {
      return;
    }

    const infiniteHeight = this.container.current.offsetHeight;
    if (infiniteHeight === 0) {
      // if there is no height of this element then do nothing
      return;
    }
    const scrollTop = this.scrollElement.scrollTop;
    const scrollHeight = this.scrollElement.scrollHeight;
    const height = this.scrollElement.offsetHeight;
    const threshold = this.props.threshold;

    const distanceFromInfinite =
      this.props.position === "top"
        ? scrollTop - infiniteHeight - threshold
        : scrollHeight - infiniteHeight - scrollTop - threshold - height;

    if (distanceFromInfinite < 0) {
      if (!this.didFire) {
        this.isLoading = true;
        this.didFire = true;
        this.props.fetch(this.complete);
      }
    } else {
      this.didFire = false;
    }
  };

  complete = () => {
    if (!this.isLoading || !this.scrollElement) {
      return;
    }

    this.isLoading = false;

    if (this.props.position === "top") {
      /**
       * New content is being added at the top, but the scrollTop position stays the same,
       * which causes a scroll jump visually. This algorithm makes sure to prevent this.
       * (Frame 1)
       *    - complete() is called, but the UI hasn't had time to update yet.
       *    - Save the current content dimensions.
       *    - Wait for the next frame using _dom.read, so the UI will be updated.
       * (Frame 2)
       *    - Read the new content dimensions.
       *    - Calculate the height difference and the new scroll position.
       *    - Delay the scroll position change until other possible dom reads are done using _dom.write to be performant.
       * (Still frame 2, if I'm correct)
       *    - Change the scroll position (= visually maintain the scroll position).
       *    - Change the state to re-enable the InfiniteScroll.
       *    - This should be after changing the scroll position, or it could
       *    cause the InfiniteScroll to be triggered again immediately.
       * (Frame 3)
       *    Done.
       */
      this.isBusy = true;
      // ******** DOM READ ****************
      // Save the current content dimensions before the UI updates
      const prev =
        this.scrollElement.scrollHeight - this.scrollElement.scrollTop;

      // ******** DOM READ ****************
      requestAnimationFrame(() => {
        if (!this.scrollElement) {
          return;
        }
        // UI has updated, save the new content dimensions
        const scrollHeight = this.scrollElement.scrollHeight;
        // New content was added on top, so the scroll position should be changed immediately to prevent it from jumping around
        const newScrollTop = scrollHeight - prev;

        // ******** DOM WRITE ****************
        requestAnimationFrame(() => {
          if (!this.scrollElement) {
            return;
          }
          this.scrollElement.scrollTop = newScrollTop;
          this.isBusy = false;
        });
      });
    }
  };

  private canStart() {
    return (
      !this.props.disabled &&
      !this.isBusy &&
      !!this.scrollElement &&
      !this.isLoading
    );
  }

  private registerScrollHandler() {
    if (this.scrollElement && !this.isScrollHandlerRegistered) {
      this.scrollElement.addEventListener("scroll", this.onScroll);
      this.isScrollHandlerRegistered = true;
    }
  }
  private unregisterScrollHandler() {
    if (this.isScrollHandlerRegistered && this.scrollElement) {
      this.scrollElement.removeEventListener("scroll", this.onScroll);
      this.isScrollHandlerRegistered = false;
      this.didFire = false;
    }
  }

  render() {
    return (
      <div ref={this.container}>
        {!this.props.disabled && this.props.children}
      </div>
    );
  }
}
