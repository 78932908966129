function isValidRate(rateString: string): boolean {
  const regex = new RegExp("^(?![0][0-9])[0-9]+([.][0-9]{0,2})?$");
  if (!regex.test(rateString)) {
    return false;
  }

  const rate = parseFloat(rateString);
  if (isNaN(rate) || rate > 100 || rate < 0) {
    return false;
  }
  return true;
}

export function formattedRate(value: string): string | null {
  if (value === "") {
    return "";
  }

  if (!isValidRate(value)) {
    return null;
  }

  return value;
}

export function parseMortgageRatio(ratio: string): string | null {
  if (ratio === "") {
    return "";
  }

  const value = parseInt(ratio, 10);
  if (isNaN(value) || value > 100 || value < 0) {
    return null;
  }

  return value.toString();
}
