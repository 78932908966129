import React from "react";

import {
  FormattedDropdown,
  FormattedDropdownProps,
  Option as FormattedOption,
} from "../FormattedDropdown";

import { Omit } from "../../utils/typeutils";
import { Ownership } from "../../models";

export type OwnershipDropdownProps = Omit<FormattedDropdownProps, "options">;

export const OwnershipOptions: FormattedOption[] = [
  {
    key: Ownership.selfOwned,
    value: Ownership.selfOwned,
    labelId: "option.ownership.selfOwned",
  },
  {
    key: Ownership.familyOwned,
    value: Ownership.familyOwned,
    labelId: "option.ownership.familyOwned",
  },
  {
    key: Ownership.rent,
    value: Ownership.rent,
    labelId: "option.ownership.rent",
  },
];

export const OwnershipDropdown: React.FC<OwnershipDropdownProps> = props => {
  return <FormattedDropdown options={OwnershipOptions} {...props as any} />;
};
