import React from "react";
import { RouteComponentProps, StaticContext } from "react-router";

import { SMSVerificationView } from "../components/SMSVerificationView";
import {
  RemoteData,
  Initial,
  Loading,
  Success,
  Failure,
  isSuccess,
} from "../utils/remoteData";
import { isRegistered, WhoamiResponseUser } from "../utils/whoamiResponseUser";
import { parseError } from "../error";
import { UserType } from "../models";
import { e164PhoneNumber } from "../utils/e164PhoneNumber";
import { UserContext } from "../context/UserContext";
import { Omit } from "../utils/typeutils";

export interface SMSVerificationLocationState {
  authData?: AuthData;
}

export type AuthData = SignupAuthData | LoginAuthData;

export interface SignupAuthData {
  type: AuthDataType.signup;
  countryCode: string;
  phoneNumber: string;
  verificationCodeId: string;
  userType: UserType;
  isAcceptedContact: boolean;
  email?: string;
}

export interface LoginAuthData {
  type: AuthDataType.login;
  countryCode: string;
  phoneNumber: string;
  verificationCodeId: string;
}

export enum AuthDataType {
  signup,
  login,
}

interface State {
  remoteVerify: RemoteData<void>;
  remoteResend: RemoteData<string>;
  digits: string;
  resendCountdown: number;
  authData?: AuthData;
}

interface SMSVerificationContextValue extends Omit<State, "authData"> {
  updateDigits: (digits: string) => Promise<void>;
}

export const SMSVerificationContext = React.createContext<
  SMSVerificationContextValue
>(null as any);

export default class SMSVerificationScreen extends React.PureComponent<
  RouteComponentProps<
    {},
    StaticContext,
    SMSVerificationLocationState | undefined
  >,
  State
> {
  state: State = {
    remoteVerify: Initial(),
    remoteResend: Initial(),
    digits: "",
    resendCountdown: 60,
  };
  countdownTimer?: number;
  timestamp = 0;

  getAuthData = (): AuthData => {
    const { authData } = this.state;
    if (!authData) {
      throw new Error("authData is empty");
    }
    return authData;
  };

  submitVerificationCode = async (code: string) => {
    const authData = this.getAuthData();
    const { history } = this.props;

    this.setState({ remoteVerify: Loading() });
    try {
      const user: WhoamiResponseUser = await this.context.loginWithVerificationCode(
        authData.verificationCodeId,
        code
      );
      if (authData.type === AuthDataType.signup) {
        //Note: signup flow
        switch (authData.userType) {
          case UserType.agent:
            history.push("/signup/welcome/agent");
            break;
          case UserType.borrower:
            await this.context.createBorrower();
            await this.context.registerPushNotification();
            history.push("/close");
            break;
          default:
            throw new Error("Unexpected user type");
        }
      } else {
        //Note: login flow
        if (isRegistered(user)) {
          await this.context.registerPushNotification();
          history.push("/close");
        } else if (user.value) {
          switch (user.value.type) {
            case UserType.agent:
              history.push("/login/welcome/agent");
              break;
            case UserType.borrower:
              throw new Error("Unexpected borrower marked as un-registered");
            default:
              throw new Error("Unexpected user type");
          }
        }
      }
      this.setState({
        remoteVerify: Success(null as any),
      });
    } catch (error) {
      this.setState({
        remoteVerify: Failure(parseError(error)),
        digits: "",
      });
    }
  };

  resendVerificationCode = () => {
    const authData = this.getAuthData();

    if (this.state.resendCountdown > 0) return;

    this.setState({ remoteResend: Loading(), resendCountdown: 60 });
    this.setCountdownTimer();
    this.resendSms(authData).then(
      verificationCodeId => {
        this.setState(prevState => {
          return {
            ...prevState,
            remoteResend: Success(verificationCodeId),
            authData: prevState.authData
              ? {
                  ...prevState.authData,
                  verificationCodeId: verificationCodeId,
                }
              : undefined,
          };
        });
      },
      error => {
        this.setState({ remoteResend: Failure(parseError(error)) });
      }
    );
  };

  updateDigits = async (digits: string) => {
    const validPattern = /^[0-9]{0,4}$/;
    if (validPattern.test(digits)) {
      this.setState({
        digits,
        remoteVerify: Initial(),
      });

      if (digits.length === 4) {
        await this.submitVerificationCode(digits);
      }
    }
  };

  setCountdownTimer() {
    this.timestamp = Math.floor(Date.now() / 1000);
    this.countdownTimer = window.setInterval(() => {
      const now = Math.floor(Date.now() / 1000);
      const timeDelta = now - this.timestamp;

      if (this.state.resendCountdown - timeDelta <= 0) {
        this.setState({ resendCountdown: 0 });
        clearInterval(this.countdownTimer);
        this.countdownTimer = undefined;
      } else if (timeDelta > 0) {
        this.setState(prevState => ({
          resendCountdown: prevState.resendCountdown - timeDelta,
        }));
        this.timestamp = now;
      }
    }, 1000);
  }

  componentDidMount() {
    const locationState = this.props.location.state;
    const authData = locationState && locationState.authData;
    if (authData) {
      this.setState({ authData });
    } else {
      this.props.history.push("/signup");
    }

    this.setCountdownTimer();
  }

  componentWillUnmount() {
    if (this.countdownTimer) {
      clearInterval(this.countdownTimer);
    }
  }

  resendSms(authData: AuthData): Promise<string> {
    const { countryCode, phoneNumber } = authData;
    const finalPhoneNumber = e164PhoneNumber(countryCode, phoneNumber).value;

    if (authData.type === AuthDataType.login) {
      return this.context.sendLoginSms(finalPhoneNumber);
    }

    if (authData.type === AuthDataType.signup) {
      const { userType, email, isAcceptedContact } = authData;
      return this.context.sendSignupSms(
        finalPhoneNumber,
        userType,
        isAcceptedContact,
        email
      );
    }

    throw new Error("Unreachable");
  }

  render() {
    const { authData } = this.state;

    if (!authData) {
      return null;
    }

    const { verificationCodeId, ...restAuthData } = authData;
    const { remoteResend } = this.state;

    return (
      <SMSVerificationContext.Provider
        value={{
          ...this.state,
          updateDigits: this.updateDigits,
        }}
      >
        <SMSVerificationView
          verificationCodeId={
            isSuccess(remoteResend) ? remoteResend.value : verificationCodeId
          }
          {...restAuthData}
          onResendVerificationCode={this.resendVerificationCode}
        />
      </SMSVerificationContext.Provider>
    );
  }
}

SMSVerificationScreen.contextType = UserContext;
