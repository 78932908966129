import * as yup from "yup";

import { Template, TemplateType } from "../models";

export const templateSchema = yup
  .object<Template>({
    id: yup.string().required(),
    type: yup
      .mixed()
      .oneOf([
        TemplateType.chatroomHeader,
        TemplateType.chatroomPopup,
        TemplateType.directApplyPopup,
        TemplateType.licenceInfo,
        TemplateType.referenceOnly,
      ])
      .required(),
    contentEn: yup.string().required(),
    contentZhHant: yup.string().required(),
    contentZhHans: yup.string().required(),
    companyId: yup.string().nullable(),
  })
  .camelCase();
