import React, { PureComponent } from "react";

import { Chat } from "../models";

import AgentChatView from "../components/AgentChatView";
import { InfiniteScrollView } from "../components/InfiniteListView";
import { withChat, ChatContextProps } from "../context/ChatContext";
import { withNav, OurNavContextProps } from "../navigation";
import { withLoading, LoadingContextProps } from "../context/LoadingContext";
import {
  withErrorAlert,
  ErrorAlertContextProps,
} from "../context/ErrorAlertContext";

import { updateChatListForNewMessage } from "../utils/updateItems";

export enum Mode {
  inProgress = "inProgress",
  completed = "completed",
}

type Props = OurNavContextProps &
  ChatContextProps &
  ErrorAlertContextProps &
  LoadingContextProps;

interface State {
  mode: Mode;
}

class AgentChatScreenImpl extends PureComponent<Props, State> {
  private isCallingAPI = false;

  private completedInfiniteScrollViewRef = React.createRef<
    InfiniteScrollView<Chat>
  >();
  private inProgressInfiniteScrollViewRef = React.createRef<
    InfiniteScrollView<Chat>
  >();

  private subscribedChatEventListener: { remove: () => void } | null = null;
  private subscribedConversationEventListener: {
    remove: () => void;
  } | null = null;

  state: State = {
    mode: Mode.inProgress,
  };

  componentDidMount() {
    this.subscribedChatEventListener = this.props.chatContext.subscribeEventEmitter(
      e => {
        if (e.type === "create") {
          if (this.completedInfiniteScrollViewRef.current) {
            this.completedInfiniteScrollViewRef.current.updateItems(
              updateChatListForNewMessage(e)
            );
          }
          if (this.inProgressInfiniteScrollViewRef.current) {
            this.inProgressInfiniteScrollViewRef.current.updateItems(
              updateChatListForNewMessage(e)
            );
          }
        }
      }
    );

    this.subscribedConversationEventListener = this.props.chatContext.subscribeConversationEventEmitter(
      e => {
        if (e.type === "delete") {
          this.props.chatContext.updateChatListVersion(() => {});
        }
      }
    );
  }

  componentWillUnmount() {
    if (this.subscribedChatEventListener) {
      this.subscribedChatEventListener.remove();
    }

    if (this.subscribedConversationEventListener) {
      this.subscribedConversationEventListener.remove();
    }
  }

  handleModeChange = (btn: Mode) => {
    this.setState({
      mode: btn,
    });
  };

  fetchInProgressPage = (cursor?: string) => {
    const isCompleted = false;
    return this.props.chatContext.agentListChat(isCompleted, cursor);
  };

  fetchCompletedPage = (cursor?: string) => {
    const isCompleted = true;
    return this.props.chatContext.agentListChat(isCompleted, cursor);
  };

  openChatroom = (chat: Chat) => {
    if (this.isCallingAPI) {
      return;
    }

    this.isCallingAPI = true;
    this.props.loadingContext.show();
    this.props.chatContext
      .enterConversation(chat)
      .then(chat => {
        this.props.navContext.navigate("/chat/chatroom", {
          conversationId: chat.conversationId,
        });
      })
      .catch(this.props.errorAlertContext.show)
      .finally(() => {
        this.props.loadingContext.dismiss();
        this.isCallingAPI = false;
      });
  };

  render() {
    const { mode } = this.state;
    return (
      <AgentChatView
        listVersion={this.props.chatContext.chatListVersion}
        mode={mode}
        handleModeChange={this.handleModeChange}
        fetchCompletedPage={this.fetchCompletedPage}
        fetchInProgressPage={this.fetchInProgressPage}
        openChatroom={this.openChatroom}
        completedInfiniteScrollViewRef={this.completedInfiniteScrollViewRef}
        inProgressInfiniteScrollViewRef={this.inProgressInfiniteScrollViewRef}
      />
    );
  }
}

export const AgentChatScreen = withLoading(
  withErrorAlert(withNav(withChat(AgentChatScreenImpl)))
);
export default AgentChatScreen;
