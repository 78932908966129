export enum CalculatorType {
  Loan = "loan",
  InterestRate = "interest_rate",
  Period = "period",
  Repayment = "repayment",
  CommissionRate = "commission_rate",
}

export interface CalculatorParams {
  loan?: number;
  interestRate?: number;
  period?: number;
  repayment?: number;
}

export interface AgentCalculatorParams extends CalculatorParams {
  commissionRate?: number;
}
