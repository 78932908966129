import {
  IonContent,
  IonHeader,
  IonImg,
  IonToolbar,
  IonPage,
} from "@ionic/react";
import {
  FormattedMessage,
  Context as LocaleContext,
} from "@oursky/react-messageformat";
import { debounce } from "lodash";
import React, { PureComponent } from "react";

import { CompanyContext } from "../../context/CompanyContext";
import { ClearableInput } from "../input";
import { InfiniteScrollView } from "../InfiniteListView";

import { common } from "../../images";
import { Company } from "../../models";

import styles from "./SearchCompanyView.module.scss";

export interface SearchCompanyViewProps {
  onClose?: () => void;
  onChange?: (company: Company) => void;
}

interface State {
  text: string;
}

export class SearchCompanyView extends PureComponent<
  SearchCompanyViewProps,
  State
> {
  state: State = {
    text: "",
  };

  searchCompany?: () => void;

  renderItem = (company: Company, index: number) => {
    const onClick = () => {
      if (this.props.onChange) {
        this.props.onChange(company);
      }
    };
    return (
      <div key={index} className={styles.item} onClick={onClick}>
        {company.name}
      </div>
    );
  };

  render() {
    const { onClose } = this.props;
    const { text } = this.state;

    return (
      <LocaleContext.Consumer>
        {({ renderToString }) => (
          <IonPage>
            <IonHeader mode="ios">
              <IonToolbar mode="ios">
                <div className={styles.closeButton} onClick={onClose}>
                  <IonImg src={common.buttonCross} />
                </div>
                <div className={styles.title}>
                  <FormattedMessage id="company_picker.title" />
                </div>
              </IonToolbar>
              <IonToolbar>
                <ClearableInput
                  placeholder={renderToString("company_picker.placeholder")}
                  className={styles.searchBar}
                  autoFocus={true}
                  value={text}
                  onChange={this.handleInputChange}
                />
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <InfiniteScrollView
                listClassName={styles.list}
                emptyMessageId="company_picker.not_found"
                emptyMessageValues={{ name: text.trim() }}
                fetchPage={this.fetchCompanies}
                renderItem={this.renderItem}
                threshold={400}
                setRefresh={this.setSearchCompany}
              />
            </IonContent>
          </IonPage>
        )}
      </LocaleContext.Consumer>
    );
  }

  handleInputChange = (text: string) => {
    this.setState({ text }, () => {
      if (this.searchCompany) {
        this.searchCompany();
      }
    });
  };

  fetchCompanies = (cursor?: string) => {
    return this.context.searchCompany(this.state.text.trim(), cursor);
  };

  setSearchCompany = (searchCompany: () => void) => {
    this.searchCompany = debounce(searchCompany, 400);
  };
}

SearchCompanyView.contextType = CompanyContext;
