import { BaseApiClient, ApiClientConstructor } from "./client";

import {
  listAllOfferingsForBorrowerRequestSchema,
  listAllOfferingsForGuestRequestSchema,
} from "../types/response";
import { ListType } from "../types/misc";

import { Offering } from "../models";

import { Page } from "../utils/pagination";

export interface OfferingApiClient {
  listAllFeaturedOfferingsForBorrower: (
    type: ListType,
    cursor?: string
  ) => Promise<Page<Offering>>;
  listAllFeaturedOfferingsForGuest: (
    type: ListType,
    cursor?: string
  ) => Promise<Page<Offering>>;
}

export function withOfferingApi<
  TBase extends ApiClientConstructor<BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    listAllFeaturedOfferingsForBorrower(
      type: ListType,
      cursor?: string
    ): Promise<Page<Offering>> {
      const data: any = { offer_type: type };
      if (cursor) {
        data.page_info = { cursor };
      }

      return this.requestLambda(
        "offering:borrower-list-all-featured",
        data,
        listAllOfferingsForBorrowerRequestSchema
      );
    }

    listAllFeaturedOfferingsForGuest(
      type: ListType,
      cursor?: string
    ): Promise<Page<Offering>> {
      const data: any = { offer_type: type };
      if (cursor) {
        data.page_info = { cursor };
      }

      return this.requestLambda(
        "offering:guest-list-all-featured",
        data,
        listAllOfferingsForGuestRequestSchema
      );
    }
  };
}
