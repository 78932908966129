import {
  FormattedMessage,
  Context as LocaleContext,
} from "@oursky/react-messageformat";
import React, { useCallback } from "react";
import {
  FieldType,
  PropertyInfoField as BorrowerPropertyInfoField,
} from "../../context/CreateRequestContext";
import { StaticDistrictOption } from "../../context/OptionsContext";

import { District, PropertyInfo } from "../../models";
import {
  ValidationError,
  ValidationResult,
  Validator,
  validate,
  filterValidatorByDisplayMap,
  ignoreIndexFieldMapper,
} from "../../utils/validate";
import { PropertyInfoField } from "../ConditionalDisplayField/ConditionalDisplayField";
import { DistrictPicker } from "../DistrictPicker";

import ErrorField from "../ErrorField";
import { ClearableInput } from "../input";

import styles from "./PropertyInfoForm.module.scss";

export type FormInputFields =
  | RoomFloorFields
  | TowerFields
  | NameOfBuildingFields
  | StreetNameAndNumberFields
  | DistrictFields;

export const defaultPropertyInfoValues: PropertyInfo = {
  id: null,
  roomFloor: null,
  tower: null,
  nameOfBuilding: null,
  streetNameAndNumber: null,
  district: null,
};

export function covertPropertyInfoToFormValues(
  propertyInfo: PropertyInfo
): FormValues {
  return {
    ...propertyInfo,
    district:
      StaticDistrictOption.find(d => d.value === propertyInfo.district) || null,
  };
}

export interface FormValues {
  id: string | null;
  roomFloor: string | null;
  tower: string | null;
  nameOfBuilding: string | null;
  streetNameAndNumber: string | null;
  district: District | null;
}

export const defaultFormValues: FormValues = {
  id: null,
  roomFloor: null,
  tower: null,
  nameOfBuilding: null,
  streetNameAndNumber: null,
  district: null,
};

interface PropertyInfoFormProps {
  index: number;
  onChange: (index: number, value: Partial<FormValues>) => void;
  propertyInfo: FormValues;
  error: ValidationError<FormInputFields>;
  focusField?: FormInputFields;
  onRemove?: (index: number) => void;
}

export const PropertyInfoForm: React.FC<PropertyInfoFormProps> = props => {
  const { propertyInfo, index, onChange, onRemove, error, focusField } = props;
  const {
    roomFloor,
    tower,
    nameOfBuilding,
    streetNameAndNumber,
    district,
  } = propertyInfo;
  const [nextField, setNextField] = React.useState<FormInputFields>();
  React.useEffect(() => {
    if (nextField) {
      setNextField(undefined);
    }
  }, [nextField]);

  const focusDistrictField = useCallback(() => {
    setNextField(districtFields[index]);
  }, [index]);

  const handleRoomFloorChange = useCallback(
    (roomFloor: string) => onChange(index, { roomFloor }),
    [index, onChange]
  );

  const handleTowerChange = useCallback(
    (tower: string) => onChange(index, { tower }),
    [index, onChange]
  );

  const handleNameOfBuildingChange = useCallback(
    (nameOfBuilding: string) => onChange(index, { nameOfBuilding }),
    [index, onChange]
  );

  const handleStreetNameAndNumberChange = useCallback(
    (streetNameAndNumber: string) => onChange(index, { streetNameAndNumber }),
    [index, onChange]
  );

  const handleDistrictChange = useCallback(
    (district: District) => onChange(index, { district }),
    [index, onChange]
  );

  const onClickRemove = useCallback(() => {
    if (onRemove) {
      onRemove(index);
    }
  }, [index, onRemove]);

  return (
    <LocaleContext.Consumer>
      {({ renderToString }) => (
        <div>
          <div className={styles.formHeader}>
            <div className={styles.title}>
              <FormattedMessage
                id="borrower_property_info_form.property_number"
                values={{ number: index + 1 }}
              />
            </div>
            {onRemove && (
              <div onClick={onClickRemove} className={styles.removeButton}>
                <FormattedMessage id="borrower_property_info_form.remove" />
              </div>
            )}
          </div>

          <PropertyInfoField field="roomFloor">
            <div
              className={styles.fieldContainer}
              data-anchor={roomFloorFields[index]}
            >
              <div className={styles.fieldTitle}>
                <FormattedMessage id="borrower_property_info_form.roomFloor" />
              </div>
              <ClearableInput
                isError={error[roomFloorFields[index]] !== undefined}
                placeholder={renderToString(
                  "borrower_property_info_form.roomFloor"
                )}
                value={roomFloor || ""}
                onChange={handleRoomFloorChange}
                scrollAnchor={roomFloorFields[index]}
                shouldFocus={focusField === roomFloorFields[index]}
                shouldScrollTo={focusField === roomFloorFields[index]}
              />
              <ErrorField
                isShown={error[roomFloorFields[index]] !== undefined}
                hiddenType="gone"
                messageId={error[roomFloorFields[index]]}
              />
            </div>
          </PropertyInfoField>

          <PropertyInfoField field="tower">
            <div
              className={styles.fieldContainer}
              data-anchor={towerFields[index]}
            >
              <div className={styles.fieldTitle}>
                <FormattedMessage id="borrower_property_info_form.tower" />
              </div>
              <ClearableInput
                isError={error[towerFields[index]] !== undefined}
                placeholder={renderToString(
                  "borrower_property_info_form.tower"
                )}
                value={tower || ""}
                onChange={handleTowerChange}
                scrollAnchor={towerFields[index]}
                shouldFocus={focusField === towerFields[index]}
                shouldScrollTo={focusField === towerFields[index]}
              />
              <ErrorField
                isShown={error[towerFields[index]] !== undefined}
                hiddenType="gone"
                messageId={error[towerFields[index]]}
              />
            </div>
          </PropertyInfoField>

          <PropertyInfoField field="nameOfBuilding">
            <div
              className={styles.fieldContainer}
              data-anchor={nameOfBuildingFields[index]}
            >
              <div className={styles.fieldTitle}>
                <FormattedMessage id="borrower_property_info_form.nameOfBuilding" />
              </div>
              <ClearableInput
                isError={error[nameOfBuildingFields[index]] !== undefined}
                placeholder={renderToString(
                  "borrower_property_info_form.nameOfBuilding"
                )}
                value={nameOfBuilding || ""}
                onChange={handleNameOfBuildingChange}
                scrollAnchor={nameOfBuildingFields[index]}
                shouldFocus={focusField === nameOfBuildingFields[index]}
                shouldScrollTo={focusField === nameOfBuildingFields[index]}
              />
              <ErrorField
                isShown={error[nameOfBuildingFields[index]] !== undefined}
                hiddenType="gone"
                messageId={error[nameOfBuildingFields[index]]}
              />
            </div>
          </PropertyInfoField>

          <PropertyInfoField field="streetNameAndNumber">
            <div
              className={styles.fieldContainer}
              data-anchor={streetNameAndNumberFields[index]}
            >
              <div className={styles.fieldTitle}>
                <FormattedMessage id="borrower_property_info_form.streetNameAndNumber" />
              </div>
              <ClearableInput
                isError={error[streetNameAndNumberFields[index]] !== undefined}
                placeholder={renderToString(
                  "borrower_property_info_form.streetNameAndNumber"
                )}
                value={streetNameAndNumber || ""}
                onChange={handleStreetNameAndNumberChange}
                scrollAnchor={streetNameAndNumberFields[index]}
                shouldFocus={focusField === streetNameAndNumberFields[index]}
                shouldScrollTo={focusField === streetNameAndNumberFields[index]}
                autoSubmit={focusDistrictField}
              />
              <ErrorField
                isShown={error[streetNameAndNumberFields[index]] !== undefined}
                hiddenType="gone"
                messageId={error[streetNameAndNumberFields[index]]}
              />
            </div>
          </PropertyInfoField>

          <PropertyInfoField field="district">
            <div
              className={styles.fieldContainer}
              data-anchor={districtFields[index]}
            >
              <div className={styles.fieldTitle}>
                <FormattedMessage id="borrower_property_info_form.district" />
              </div>
              <DistrictPicker
                isError={error[districtFields[index]] !== undefined}
                value={district}
                onChange={handleDistrictChange}
                scrollAnchor={districtFields[index]}
                shouldFocus={nextField === districtFields[index]}
                shouldScrollTo={focusField === districtFields[index]}
              />
              <ErrorField
                isShown={error[districtFields[index]] !== undefined}
                hiddenType="gone"
                messageId={error[districtFields[index]]}
              />
            </div>
          </PropertyInfoField>
        </div>
      )}
    </LocaleContext.Consumer>
  );
};

type ValidationFields = BorrowerPropertyInfoField;
export function validatePropertyInfoForm(
  propertyInfos: PropertyInfo[],
  formDisplayMap?: { [key in ValidationFields]?: FieldType },
  skipProfileFilling: boolean = false
): ValidationResult<FormInputFields> {
  return propertyInfos.reduce<ValidationResult<FormInputFields>>(
    (
      prevResult: ValidationResult<FormInputFields>,
      cur: PropertyInfo,
      index: number
    ) => {
      const {
        roomFloor,
        tower,
        nameOfBuilding,
        streetNameAndNumber,
        district,
      } = cur;

      let validators: Validator<
        FormInputFields
      >[] = filterValidatorByDisplayMap(
        [
          {
            field: roomFloorFields[index],
            invalidCondition:
              roomFloor === null || roomFloor.trim().length === 0,
            errorMessageId:
              "borrower_property_info_form.roomFloor.error.required",
            isExistenceCheck: true,
          },
          {
            field: towerFields[index],
            invalidCondition: tower === null || tower.trim().length === 0,
            errorMessageId: "borrower_property_info_form.tower.error.required",
            isExistenceCheck: true,
          },
          {
            field: nameOfBuildingFields[index],
            invalidCondition:
              nameOfBuilding === null || nameOfBuilding.trim().length === 0,
            errorMessageId:
              "borrower_property_info_form.nameOfBuilding.error.required",
            isExistenceCheck: true,
          },
          {
            field: streetNameAndNumberFields[index],
            invalidCondition:
              streetNameAndNumber === null ||
              streetNameAndNumber.trim().length === 0,
            errorMessageId:
              "borrower_property_info_form.streetNameAndNumber.error.required",
            isExistenceCheck: true,
          },
          {
            field: districtFields[index],
            invalidCondition: district === null,
            errorMessageId:
              "borrower_property_info_form.district.error.required",
            isExistenceCheck: true,
          },
        ],
        formDisplayMap,
        ignoreIndexFieldMapper
      );
      if (skipProfileFilling) {
        validators = validators.filter(it => !it.isExistenceCheck);
      }
      const validateResult = validate(validators);
      if (validateResult.isValid) {
        return prevResult;
      }
      return {
        focusField: prevResult.focusField || validateResult.focusField,
        error: {
          ...prevResult.error,
          ...validateResult.error,
        },
        isValid: false,
      };
    },
    {
      focusField: undefined,
      error: {},
      isValid: true,
    }
  );
}

const roomFloorFields = [
  "roomFloor0",
  "roomFloor1",
  "roomFloor2",
  "roomFloor3",
  "roomFloor4",
  "roomFloor5",
  "roomFloor6",
  "roomFloor7",
  "roomFloor8",
  "roomFloor9",
  "roomFloor10",
  "roomFloor11",
  "roomFloor12",
  "roomFloor13",
  "roomFloor14",
  "roomFloor15",
  "roomFloor16",
  "roomFloor17",
  "roomFloor18",
  "roomFloor19",
] as const;
type RoomFloorFields = typeof roomFloorFields[number];

const towerFields = [
  "tower0",
  "tower1",
  "tower2",
  "tower3",
  "tower4",
  "tower5",
  "tower6",
  "tower7",
  "tower8",
  "tower9",
  "tower10",
  "tower11",
  "tower12",
  "tower13",
  "tower14",
  "tower15",
  "tower16",
  "tower17",
  "tower18",
  "tower19",
] as const;
type TowerFields = typeof towerFields[number];

const nameOfBuildingFields = [
  "nameOfBuilding0",
  "nameOfBuilding1",
  "nameOfBuilding2",
  "nameOfBuilding3",
  "nameOfBuilding4",
  "nameOfBuilding5",
  "nameOfBuilding6",
  "nameOfBuilding7",
  "nameOfBuilding8",
  "nameOfBuilding9",
  "nameOfBuilding10",
  "nameOfBuilding11",
  "nameOfBuilding12",
  "nameOfBuilding13",
  "nameOfBuilding14",
  "nameOfBuilding15",
  "nameOfBuilding16",
  "nameOfBuilding17",
  "nameOfBuilding18",
  "nameOfBuilding19",
] as const;
type NameOfBuildingFields = typeof nameOfBuildingFields[number];

const streetNameAndNumberFields = [
  "streetNameAndNumber0",
  "streetNameAndNumber1",
  "streetNameAndNumber2",
  "streetNameAndNumber3",
  "streetNameAndNumber4",
  "streetNameAndNumber5",
  "streetNameAndNumber6",
  "streetNameAndNumber7",
  "streetNameAndNumber8",
  "streetNameAndNumber9",
  "streetNameAndNumber10",
  "streetNameAndNumber11",
  "streetNameAndNumber12",
  "streetNameAndNumber13",
  "streetNameAndNumber14",
  "streetNameAndNumber15",
  "streetNameAndNumber16",
  "streetNameAndNumber17",
  "streetNameAndNumber18",
  "streetNameAndNumber19",
] as const;
type StreetNameAndNumberFields = typeof streetNameAndNumberFields[number];

const districtFields = [
  "district0",
  "district1",
  "district2",
  "district3",
  "district4",
  "district5",
  "district6",
  "district7",
  "district8",
  "district9",
  "district10",
  "district11",
  "district12",
  "district13",
  "district14",
  "district15",
  "district16",
  "district17",
  "district18",
  "district19",
] as const;
type DistrictFields = typeof districtFields[number];
