import { IonHeader, IonPage, IonToolbar } from "@ionic/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { PureComponent } from "react";

import {
  CreateForeignExchangeForm,
  CreateMortgageForm,
  CreatePersonalLoanForm,
  OfferingFromMode,
  Request,
  RequestType,
} from "../../models";

import { RemoteData } from "../../utils/remoteData";

import AgentOfferForeignExchangeForm from "../AgentOfferForeignExchangeForm";
import AgentOfferMortgageForm from "../AgentOfferMortgageForm";
import AgentOfferPersonalLoanForm from "../AgentOfferPersonalLoanForm";
import { BackButton } from "../BackButton";
import { Option as DPOption } from "../DropdownPicker";
import { MPContent } from "../MPContent";
import { RequestTypeDropdown } from "../SpecifyDropdown";

import styles from "./styles.module.scss";

interface OwnProps {
  mode: OfferingFromMode;
  currencyOptions: DPOption[];
  remoteCreate: RemoteData<void>;
  selectedRequest?: Request;
  selectedRequestType: RequestType;
  onRequestTypeChange: (type: string) => void;
  onSubmitForeignExchange: (
    values: CreateForeignExchangeForm
  ) => Promise<boolean>;
  onSubmitMortgage: (values: CreateMortgageForm) => Promise<boolean>;
  onSubmitPersonalLoan: (values: CreatePersonalLoanForm) => Promise<boolean>;
}

type Props = OwnProps;

export class AgentOfferingCreateView extends PureComponent<Props> {
  renderForeignExchangeForm = () => {
    const { currencyOptions, mode, remoteCreate, selectedRequest } = this.props;

    if (mode === OfferingFromMode.template) {
      return (
        <AgentOfferForeignExchangeForm
          mode={mode}
          currencyOptions={currencyOptions}
          remoteCreate={remoteCreate}
          onSubmit={this.props.onSubmitForeignExchange}
        />
      );
    }

    if (selectedRequest && selectedRequest.type === RequestType.exchangeLoan) {
      return (
        <AgentOfferForeignExchangeForm
          mode={mode}
          currencyOptions={currencyOptions}
          remoteCreate={remoteCreate}
          selectedRequest={selectedRequest}
          onSubmit={this.props.onSubmitForeignExchange}
        />
      );
    }

    throw new Error(`Unexpected request, incorrect type or no selected`);
  };

  renderForm() {
    const { remoteCreate, selectedRequestType } = this.props;

    switch (selectedRequestType) {
      case RequestType.exchangeLoan:
        return this.renderForeignExchangeForm();
      case RequestType.mortgage:
        return (
          <AgentOfferMortgageForm
            remoteCreate={remoteCreate}
            onSubmit={this.props.onSubmitMortgage}
          />
        );
      case RequestType.personalLoan:
        return (
          <AgentOfferPersonalLoanForm
            remoteCreate={remoteCreate}
            onSubmit={this.props.onSubmitPersonalLoan}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const { mode, onRequestTypeChange, selectedRequestType } = this.props;

    return (
      <IonPage className={styles.outerContainer}>
        <IonHeader mode="ios">
          <IonToolbar mode="ios">
            <div className={styles.back}>
              <BackButton />
            </div>
            <div className={styles.title}>
              <FormattedMessage id="create_offering.title" />
            </div>
          </IonToolbar>
        </IonHeader>
        <MPContent class={styles.content} overflowScrolling="auto">
          <div className={styles.formContainer}>
            <h2>
              <FormattedMessage id="create_offering.type.label" />
            </h2>
            <RequestTypeDropdown
              disabled={mode === OfferingFromMode.newOffering}
              containerClass={styles.dropdownContainer}
              selectClass={styles.dropdownSelect}
              errorClass={styles.dropdownError}
              emptyClass={styles.dropdownEmpty}
              value={selectedRequestType}
              isError={false}
              onValueChange={onRequestTypeChange}
            />
            {this.renderForm()}
          </div>
        </MPContent>
      </IonPage>
    );
  }
}

export default AgentOfferingCreateView;
