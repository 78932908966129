import { waitShadowRoot } from "./shadowRoot";

export const getScrollElement = async (element: HTMLIonContentElement) => {
  const shadowRoot = await waitShadowRoot(element);
  return shadowRoot.querySelector(".inner-scroll") as HTMLElement;
};

export const setContentBackground = async (
  element: HTMLIonContentElement,
  image: string,
  config: string = "no-repeat center center / cover"
) => {
  const innerScroll = await getScrollElement(element);
  if (innerScroll !== null) {
    innerScroll.style.setProperty("background", `url(${image}) ${config}`);
  }
};

export const setOverflowScrolling = async (
  element: HTMLIonContentElement,
  value: string
) => {
  const innerScroll = await getScrollElement(element);
  if (innerScroll !== null) {
    innerScroll.style.setProperty("-webkit-overflow-scrolling", value);
  }
};
