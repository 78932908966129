import { Context, Values } from "@oursky/react-messageformat";
import memoize from "memoize-one";
import React, { PureComponent } from "react";

import { Omit } from "../../utils/typeutils";
import {
  DropdownPickerProps,
  DropdownPicker,
  Option as DPOption,
} from "../DropdownPicker";

export type Option = Omit<DPOption, "display"> & {
  labelId: string;
  labelValues?: Values;
};

export type FormattedDropdownProps = Omit<
  DropdownPickerProps,
  "placeholder" | "options"
> & {
  placeholderId?: string;
  options: (Option | DPOption)[];
};

export class FormattedDropdown extends PureComponent<FormattedDropdownProps> {
  formatOptions = memoize(formatOptions);

  render() {
    const { placeholderId, options, ...rest } = this.props;

    return (
      <Context.Consumer>
        {({ renderToString }) => (
          <DropdownPicker
            placeholder={placeholderId && renderToString(placeholderId)}
            options={this.formatOptions(renderToString, options)}
            {...rest as any}
          />
        )}
      </Context.Consumer>
    );
  }
}

function formatOptions(
  renderToString: (id: string) => string,
  options: (Option | DPOption)[]
): DPOption[] {
  return options.map(option => formatOption(renderToString, option));
}

function formatOption(
  renderToString: (id: string, values?: Values) => string,
  option: Option | DPOption
): DPOption {
  if ("display" in option) {
    return option;
  }

  const { labelId, labelValues, ...rest } = option;

  return {
    ...rest,
    display: renderToString(labelId, labelValues),
  };
}
