import React from "react";

import { CompanyApiClient } from "../apiClient";
import { Company } from "../models";
import { Page } from "../utils/pagination";
import { Omit } from "../utils/typeutils";

export interface CompanyContextValue {
  searchCompany: (text: string, cursor?: string) => Promise<Page<Company>>;
}

const CompanyContext = React.createContext(null as any);
export { CompanyContext };

export interface CompanyContextProps {
  companyContext: CompanyContextValue;
}

interface Props {
  apiClient: CompanyApiClient;
}

export const CompanyContextProvider: React.FC<Props> = props => {
  const { apiClient, children } = props;
  return (
    <CompanyContext.Provider
      value={{
        searchCompany: apiClient.searchCompany.bind(apiClient),
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export function withCompany<P extends CompanyContextProps>(
  Component: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof CompanyContextProps>> {
  const Wrapped: React.FC<Omit<P, keyof CompanyContextProps>> = (
    props: Omit<P, keyof CompanyContextProps>
  ) => (
    <CompanyContext.Consumer>
      {companyContext => (
        <Component {...props as any} companyContext={companyContext} />
      )}
    </CompanyContext.Consumer>
  );

  return Wrapped;
}
