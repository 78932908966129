import {
  IonContent,
  IonHeader,
  IonImg,
  IonToolbar,
  IonPage,
} from "@ionic/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { PureComponent } from "react";

import { common } from "../../images";
import { Industry } from "../../models";

import styles from "./IndustryListView.module.scss";

interface IndustryListViewProps {
  options: Industry[];
  displayNameKey: "nameEn" | "nameZhHant" | "nameZhHans";
  onClose?: () => void;
  onChange?: (industry: Industry) => void;
}

export class IndustryListView extends PureComponent<IndustryListViewProps> {
  renderItem = (industry: Industry, index: number) => {
    const { displayNameKey } = this.props;
    const onClick = () => {
      if (this.props.onChange) {
        this.props.onChange(industry);
      }
    };
    return (
      <div key={index} className={styles.item} onClick={onClick}>
        {industry[displayNameKey]}
      </div>
    );
  };

  render() {
    const { onClose, options } = this.props;

    return (
      <IonPage>
        <IonHeader mode="ios">
          <IonToolbar mode="ios">
            <div className={styles.closeButton} onClick={onClose}>
              <IonImg src={common.buttonCross} />
            </div>
            <div className={styles.title}>
              <FormattedMessage id="industry_picker.title" />
            </div>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {options.map((item, i) => this.renderItem(item, i))}
        </IonContent>
      </IonPage>
    );
  }
}
