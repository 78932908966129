export const AppConfig = {
  ...(window as any).appConfig,
  ...(window as any).appVersion,
};

export const BUILD: string = AppConfig.build;
export const SKYGEAR_API_KEY: string = AppConfig.skygearApiKey;
export const SKYGEAR_ENDPOINT: string = AppConfig.skygearEndpoint;
export const SENTRY_DSN: string = AppConfig.sentryDsn;
export const BASENAME: string = AppConfig.basename;
export const ROUTER_IMPL: string = AppConfig.routerImpl;
export const APP_VERSION: string = AppConfig.appVersion;
export const IOS_NATIVE_MARKET_DEEP_LINK: string =
  AppConfig.iOSNativeMarketDeepLink;
export const ANDROID_NATIVE_MARKET_DEEP_LINK: string =
  AppConfig.androidNativeMarketDeepLink;

export default {
  BUILD,
  SKYGEAR_API_KEY,
  SKYGEAR_ENDPOINT,
  SENTRY_DSN,
  BASENAME,
  ROUTER_IMPL,
  APP_VERSION,
  IOS_NATIVE_MARKET_DEEP_LINK,
  ANDROID_NATIVE_MARKET_DEEP_LINK,
};
