import React from "react";
import { IonHeader, IonToolbar, IonPage } from "@ionic/react";
import { MPContent } from "../MPContent";
import { FormattedMessage } from "@oursky/react-messageformat";
import styles from "./styles.module.scss";
import { CalculatorType } from "../../types/calculator";
import { formatToCurrencyAmount } from "../../utils/formatToCurrencyAmount";
import CalculatorItem from "../CalculatorItem";

interface Props {
  loan: string;
  interestRate: string;
  period: string;
  repayment: string;
  totalInterest: string;
  totalRepayment: string;
  lockedCalculator: CalculatorType;
  onLoanChanged: (loan: string) => void;
  onInterestRateChanged: (interestRate: string) => void;
  onPeriodChanged: (period: string) => void;
  onRepaymentChanged: (repayment: string) => void;
  lockClicked: (calculatorType: CalculatorType) => void;
}

class CalculatorView extends React.PureComponent<Props> {
  lockClicked = (calculator: CalculatorType) => {
    return () => {
      this.props.lockClicked(calculator);
    };
  };

  onLoanChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount: string = e.target.value || "";
    const formattedAmount = formatToCurrencyAmount(amount) || "";
    this.props.onLoanChanged(formattedAmount);
  };

  onInterestRateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rate: string = e.target.value || "";
    const reg = /^(\d{1,2})(\.(\d{1,2})?)?$/;
    if (reg.test(rate) || rate === "") {
      this.props.onInterestRateChanged(rate);
    }
  };

  onPeriodChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const period: string = e.target.value || "";
    this.props.onPeriodChanged(period);
  };

  onRepaymentChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount: string = e.target.value || "";
    const formattedAmount = formatToCurrencyAmount(amount) || "";
    this.props.onRepaymentChanged(formattedAmount);
  };

  render() {
    const { totalInterest, totalRepayment } = this.props;
    return (
      <IonPage className={styles.page}>
        <IonHeader mode="ios">
          <IonToolbar mode="ios">
            <div className={styles.calculatorTitle}>
              <FormattedMessage id="calculator.title" />
            </div>
          </IonToolbar>
        </IonHeader>
        <MPContent forceOverscroll={false} overflowScrolling="auto">
          <div className={styles.calculatorContainer}>
            <CalculatorItem
              value={this.props.loan}
              isLockedCalculator={
                this.props.lockedCalculator === CalculatorType.Loan
              }
              calculatorType={CalculatorType.Loan}
              onValueChanged={this.onLoanChanged}
              lockClicked={this.lockClicked(CalculatorType.Loan)}
            />

            <CalculatorItem
              value={this.props.interestRate}
              isLockedCalculator={
                this.props.lockedCalculator === CalculatorType.InterestRate
              }
              calculatorType={CalculatorType.InterestRate}
              onValueChanged={this.onInterestRateChanged}
              lockClicked={this.lockClicked(CalculatorType.InterestRate)}
            />

            <CalculatorItem
              value={this.props.period}
              isLockedCalculator={
                this.props.lockedCalculator === CalculatorType.Period
              }
              calculatorType={CalculatorType.Period}
              onValueChanged={this.onPeriodChanged}
              lockClicked={this.lockClicked(CalculatorType.Period)}
            />

            <CalculatorItem
              value={this.props.repayment}
              isLockedCalculator={
                this.props.lockedCalculator === CalculatorType.Repayment
              }
              calculatorType={CalculatorType.Repayment}
              onValueChanged={this.onRepaymentChanged}
              lockClicked={this.lockClicked(CalculatorType.Repayment)}
            />
            <div className={styles.calculatorTotalInformation}>
              <div className={styles.calculatorTotalContent}>
                <div className={styles.calculatorTotalLabel}>
                  <FormattedMessage id="calculator.total_interest" />
                </div>
                <div className={styles.calculatorTotalResult}>
                  {totalInterest !== ""
                    ? `HKD ${formatToCurrencyAmount(totalInterest)}`
                    : "-"}
                </div>
              </div>
              <div className={styles.calculatorTotalContent}>
                <div className={styles.calculatorTotalLabel}>
                  <FormattedMessage id="calculator.total_repayment" />
                </div>
                <div className={styles.calculatorTotalResult}>
                  {totalRepayment !== ""
                    ? `HKD ${formatToCurrencyAmount(totalRepayment)}`
                    : "-"}
                </div>
              </div>
            </div>
          </div>
        </MPContent>
      </IonPage>
    );
  }
}

export default CalculatorView;
