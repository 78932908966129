import React from "react";
import { PresentationContextProvider, OurNavManager } from "../navigation";
import { OptionsContextProvider } from "./OptionsContext";
import LocaleContextProvider from "./LocaleContext";
import { WalkthroughContextProvider } from "./WalkthroughContext";
import { LoadingContextProvider } from "./LoadingContext";
import { UserContextProvider } from "./UserContext";
import { ErrorAlertProvider } from "./ErrorAlertContext";
import { RequestContextProvider } from "./RequestContext";
import { TemplateContextProvider } from "./TemplateContext";
import { OfferingContextProvider } from "./OfferingContext";
import { ChatContextProvider } from "./ChatContext";
import { CompanyContextProvider } from "./CompanyContext";
import { DeepLinkHandlerProvider } from "./DeepLinkHandlerContext";
import { AgentContextProvider } from "./AgentContext";
import { CheckAppVersionContextProvider } from "./CheckAppVersionContext";

import { Locale } from "../types/misc";
import { ApiClient } from "../apiClient";
import { ChatService } from "../services/chat";
import { PubSubService } from "../services/pubsub";
import { CreateRequestContextProvider } from "./CreateRequestContext";

interface Props {
  locale: Locale;
  apiClient: ApiClient;
  chatService: ChatService;
  pubsubService: PubSubService;
}

export const ContextProviders: React.FC<Props> = props => {
  const { locale, apiClient, chatService, pubsubService } = props;

  return (
    <PresentationContextProvider>
      <OurNavManager>
        <LocaleContextProvider locale={locale}>
          <ErrorAlertProvider>
            <UserContextProvider
              apiClient={apiClient}
              chatService={chatService}
              pubsubService={pubsubService}
            >
              <RequestContextProvider
                apiClient={apiClient}
                pubsubService={pubsubService}
              >
                <OfferingContextProvider
                  apiClient={apiClient}
                  pubsubService={pubsubService}
                >
                  <ChatContextProvider
                    apiClient={apiClient}
                    chatService={chatService}
                    pubsubService={pubsubService}
                  >
                    <CompanyContextProvider apiClient={apiClient}>
                      <OptionsContextProvider apiClient={apiClient}>
                        <TemplateContextProvider apiClient={apiClient}>
                          <AgentContextProvider apiClient={apiClient}>
                            <CheckAppVersionContextProvider
                              apiClient={apiClient}
                            >
                              <WalkthroughContextProvider>
                                <LoadingContextProvider>
                                  <DeepLinkHandlerProvider>
                                    <CreateRequestContextProvider>
                                      {props.children}
                                    </CreateRequestContextProvider>
                                  </DeepLinkHandlerProvider>
                                </LoadingContextProvider>
                              </WalkthroughContextProvider>
                            </CheckAppVersionContextProvider>
                          </AgentContextProvider>
                        </TemplateContextProvider>
                      </OptionsContextProvider>
                    </CompanyContextProvider>
                  </ChatContextProvider>
                </OfferingContextProvider>
              </RequestContextProvider>
            </UserContextProvider>
          </ErrorAlertProvider>
        </LocaleContextProvider>
      </OurNavManager>
    </PresentationContextProvider>
  );
};
