import React, { PureComponent } from "react";
import { Plugins } from "@capacitor/core";

import {
  User,
  UserData,
  UserType,
  BorrowerData,
  Company,
  OfferingForm,
  BorrowerPersonalInfo,
  isAgent,
} from "../models";
import { Omit } from "../utils/typeutils";
import {
  WhoamiResponseUser,
  isNotComplete,
  isRegistered,
  UserNotFound,
  Registered,
} from "../utils/whoamiResponseUser";
import { hybridOnly } from "../utils/platform";

import { UserApiClient, AgentApiClient, BorrowerApiClient } from "../apiClient";
import { RouteComponentProps, withRouter } from "react-router";

import { ChatService } from "../services/chat";
import { PubSubService, ChannelUnsubscriber } from "../services/pubsub";
import {
  AgentImpersonationState,
  defaultAgentImpersonationState,
} from "../types/auth";
import {
  getAgentImpersonationState,
  removeStorageItem,
  setAgentImpersationState,
  StorageKey,
} from "../utils/storage";

const { PushNotifications } = Plugins;

export type UserContextValue = {
  load: () => Promise<void>;
  user?: User;
  incompleteUserData?: UserData;
  autoLogin: () => Promise<WhoamiResponseUser>;
  registerPushNotification: () => void;
  fetchUserData: () => Promise<WhoamiResponseUser>;
  sendSignupSms: (
    phoneNumber: string,
    type: UserType,
    isAcceptedContact: boolean,
    email?: string
  ) => Promise<string>;
  sendLoginSms: (phoneNumber: string) => Promise<string>;
  loginWithVerificationCode: (
    verificationCodeId: string,
    code: string
  ) => Promise<WhoamiResponseUser>;
  logout: () => Promise<void>;
  createBorrower: (borrower: Partial<BorrowerData>) => Promise<void>;
  updateBorrower: (borrower: Partial<BorrowerData>) => Promise<void>;
  createAgent: (
    name: string,
    company: Company,
    namecard: File
  ) => Promise<void>;
  updateOfferingTemplate: (template: OfferingForm[]) => Promise<void>;
  getBorrowerExtraInfoWithChatId: (
    chatId: string
  ) => Promise<BorrowerPersonalInfo>;
  getBorrowerByChatId: (chatId: string) => Promise<BorrowerData>;
  block: (userId: string) => Promise<void>;
  unblock: (userId: string) => Promise<void>;
  report: (userId: string, chatId: string) => Promise<void>;

  canImpersonateAgent: boolean;
  impersonateAgent: (userId: string) => Promise<WhoamiResponseUser>;
} & AgentImpersonationState;

export interface UserContextProps {
  userContext: UserContextValue;
  children?: React.ReactNode;
}

export const UserContext = React.createContext<UserContextValue>(null as any);

interface Props extends RouteComponentProps {
  apiClient: UserApiClient & AgentApiClient & BorrowerApiClient;
  chatService: ChatService;
  pubsubService: PubSubService;
}

type State = {
  user?: WhoamiResponseUser;
} & AgentImpersonationState;

class UserContextProviderImpl extends PureComponent<Props, State> {
  private channelUnsubscriber?: ChannelUnsubscriber;

  state: State = {
    user: undefined,
    ...defaultAgentImpersonationState,
  };

  public load = async () => {
    this.setState({
      ...(await getAgentImpersonationState()),
    });
  };

  private onLogin(user: Registered) {
    this.props.chatService.subscribe();
    this.channelUnsubscriber = this.props.pubsubService.subscribe(
      user.value.userId
    );
  }

  registerPushNotification() {
    hybridOnly(() => {
      PushNotifications.requestPermission().then(({ granted }) => {
        if (granted) {
          PushNotifications.register();
        }
      });
    });
  }

  private get user() {
    const { user } = this.state;
    return user && isRegistered(user) ? user.value : undefined;
  }

  private get incompleteUserData() {
    const { user } = this.state;
    return user && isNotComplete(user) ? user.value : undefined;
  }

  private get canImpersonateAgent() {
    if (
      this.user &&
      isAgent(this.user) &&
      this.user.isAdmin &&
      this.state.currentUserCustomToken
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { children, apiClient } = this.props;

    return (
      <UserContext.Provider
        value={{
          load: this.load,
          user: this.user,
          incompleteUserData: this.incompleteUserData,
          autoLogin: this.autoLogin,
          registerPushNotification: this.registerPushNotification,
          fetchUserData: this.fetchUserData,
          loginWithVerificationCode: this.loginWithVerificationCode,
          logout: this.logout,

          sendSignupSms: apiClient.sendSignupSms.bind(apiClient),
          sendLoginSms: apiClient.sendLoginSms.bind(apiClient),

          createBorrower: this.createBorrower,
          updateBorrower: this.updateBorrower,

          createAgent: this.createAgent,
          updateOfferingTemplate: this.updateOfferingTemplate,

          block: apiClient.block.bind(apiClient),
          unblock: apiClient.unblock.bind(apiClient),
          report: apiClient.report.bind(apiClient),

          getBorrowerExtraInfoWithChatId: apiClient.getBorrowerExtraInfoWithChatId.bind(
            apiClient
          ),
          getBorrowerByChatId: apiClient.getBorrowerByChatId.bind(apiClient),

          currentUserCustomToken: this.state.currentUserCustomToken,
          canImpersonateAgent: this.canImpersonateAgent,
          impersonateAgent: this.impersonateAgent,
          isImpersonatingAgent: this.state.isImpersonatingAgent,
          agentImpersonatorAccessToken: this.state.agentImpersonatorAccessToken,
        }}
      >
        {children}
      </UserContext.Provider>
    );
  }

  autoLogin = async (): Promise<WhoamiResponseUser> => {
    let user: WhoamiResponseUser;
    try {
      user = await this.props.apiClient.whoami();
    } catch (e) {
      user = UserNotFound();
    }
    this.setState({ user });

    if (isRegistered(user)) {
      this.onLogin(user);
    }

    return user;
  };

  private async logoutAndUnsubscribeChannels() {
    const { apiClient, chatService } = this.props;
    await apiClient.logout();
    chatService.unsubscribe();
    if (this.channelUnsubscriber) {
      this.channelUnsubscriber();
    }
  }

  logout = async () => {
    await Promise.all([
      removeStorageItem(StorageKey.RequestPersonalLoanForm),
      removeStorageItem(StorageKey.RequestMortgageForm),
    ]);
    await this.logoutAndUnsubscribeChannels();
    this.setState({ user: undefined, ...defaultAgentImpersonationState });
    await setAgentImpersationState(defaultAgentImpersonationState);
  };

  fetchUserData = async (): Promise<WhoamiResponseUser> => {
    const user = await this.props.apiClient.whoami();
    this.setState({ user });
    return user;
  };

  createBorrower = async () => {
    const { apiClient } = this.props;
    await apiClient.createBorrower();
    await this.fetchUserData();
  };

  updateBorrower = async (borrower: Partial<BorrowerData>) => {
    const { apiClient } = this.props;
    const borrowerData = await apiClient.updateBorrower(borrower);
    this.setState(prevState => {
      if (
        prevState.user &&
        isRegistered(prevState.user) &&
        prevState.user.value.type === UserType.borrower
      ) {
        return {
          ...prevState,
          user: {
            ...prevState.user,
            value: {
              ...prevState.user.value,
              ...borrowerData,
            },
          },
        };
      }
      return prevState;
    });
  };

  createAgent = async (name: string, company: Company, namecard: File) => {
    const { apiClient } = this.props;
    await apiClient.createAgent(name, company, namecard);
  };

  loginWithVerificationCode = async (
    verificationCodeId: string,
    code: string
  ) => {
    const { apiClient } = this.props;
    const token = await apiClient.verifyCode(verificationCodeId, code);
    await apiClient.loginWithToken(token);

    const agentImpersonationState: AgentImpersonationState = {
      currentUserCustomToken: token,
      isImpersonatingAgent: false,
      agentImpersonatorAccessToken: null,
    };
    this.setState(agentImpersonationState);
    await setAgentImpersationState(agentImpersonationState);

    return this.autoLogin();
  };

  impersonateAgent = async (userId: string) => {
    const { currentUserCustomToken, agentImpersonatorAccessToken } = this.state;
    if (!currentUserCustomToken) {
      throw new Error("Not logged in");
    }
    const { apiClient } = this.props;
    const token = await apiClient.impersonateAgent(
      userId,
      agentImpersonatorAccessToken || undefined
    );
    await this.logoutAndUnsubscribeChannels();
    await new Promise<void>(resolve => {
      this.setState({ user: undefined }, () => {
        resolve();
      });
    });
    await apiClient.loginWithToken(token);
    this.registerPushNotification();

    const agentImpersonationState: AgentImpersonationState = {
      currentUserCustomToken: token,
      isImpersonatingAgent: true,
      agentImpersonatorAccessToken:
        agentImpersonatorAccessToken || currentUserCustomToken,
    };
    this.setState(agentImpersonationState);
    await setAgentImpersationState(agentImpersonationState);

    return this.autoLogin();
  };

  updateOfferingTemplate = async (template: OfferingForm[]) => {
    const { apiClient } = this.props;
    const { offeringTemplate } = await apiClient.updateOfferingTemplate(
      template
    );
    this.setState(prevState => {
      if (
        prevState.user &&
        isRegistered(prevState.user) &&
        prevState.user.value.type === UserType.agent
      ) {
        return {
          ...prevState,
          user: {
            ...prevState.user,
            value: {
              ...prevState.user.value,
              offeringTemplate,
            },
          },
        };
      }
      return prevState;
    });
  };
}

export const UserContextProvider = withRouter(UserContextProviderImpl);

export function withUser<P extends UserContextProps>(
  Component: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof UserContextProps>> {
  const Wrapped: React.FC<Omit<P, keyof UserContextProps>> = (
    props: Omit<P, keyof UserContextProps>
  ) => (
    // Cast ...props as any due to typescript bug.
    // See https://github.com/microsoft/TypeScript/issues/28938
    <UserContext.Consumer>
      {context => <Component {...props as any} userContext={context} />}
    </UserContext.Consumer>
  );

  return Wrapped;
}
