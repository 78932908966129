import React from "react";

import {
  FormattedDropdown,
  FormattedDropdownProps,
  Option as FormattedOption,
} from "../FormattedDropdown";

import { Omit } from "../../utils/typeutils";

export type ValidDaysDropdownProps = Omit<FormattedDropdownProps, "options">;

export const ValidDaysOptions: FormattedOption[] = [
  {
    key: "7",
    value: "7",
    labelId: "option.valid_days.one_week",
  },
  {
    key: "30",
    value: "30",
    labelId: "option.valid_days.one_month",
  },
  {
    key: "90",
    value: "90",
    labelId: "option.valid_days.three_months",
  },
];

export const ValidDaysDropdown: React.FC<ValidDaysDropdownProps> = props => {
  return <FormattedDropdown options={ValidDaysOptions} {...props as any} />;
};
