import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { Plugins } from "@capacitor/core";

import {
  SMSVerificationLocationState,
  AuthDataType,
} from "./SMSVerificationScreen";

import {
  withErrorAlert,
  ErrorAlertContextProps,
} from "../context/ErrorAlertContext";

import { withUser, UserContextProps } from "../context/UserContext";
import { withLoading, LoadingContextProps } from "../context/LoadingContext";

import {
  FormValues as SignupFormValues,
  SignupView,
} from "../components/SignupView";
import { APIError, APIErrorCode, getAPIErrorMessageId } from "../error";
import { e164PhoneNumber } from "../utils/e164PhoneNumber";
import { RemoteData, Initial, Loading, Success } from "../utils/remoteData";
import { getStaticPageUrl } from "../utils/misc";
import { StaticPageType } from "../types/misc";

const { Browser } = Plugins;

type Props = LoadingContextProps &
  ErrorAlertContextProps &
  RouteComponentProps &
  UserContextProps;

interface State {
  phoneNumberErrorId?: string;
  emailErrorId?: string;
  remoteSignup: RemoteData<void>;
}

export class SignupScreenImpl extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      remoteSignup: Initial(),
    };
  }

  onCloseClick = () => {
    const { history } = this.props;

    history.push("/close");
  };

  onSkipClick = () => {
    const { history } = this.props;

    history.push("/close");
  };

  onLoginClick = () => {
    const { history } = this.props;

    history.push("/login");
  };

  onSignup = (values: SignupFormValues) => {
    const { history } = this.props;
    const {
      userType,
      isAcceptedContact,
      phoneNumberValue: { countryCode, phoneNumber },
      email,
    } = values;

    const finalPhoneNumber = e164PhoneNumber(countryCode, phoneNumber).value;

    this.setState({ remoteSignup: Loading(), phoneNumberErrorId: undefined });
    this.props.loadingContext.show();
    this.props.userContext
      .sendSignupSms(finalPhoneNumber, userType, isAcceptedContact, email)
      .then(verificationCodeId => {
        this.setState({ remoteSignup: Success(undefined) });

        const locationState: SMSVerificationLocationState = {
          authData: {
            type: AuthDataType.signup,
            countryCode,
            phoneNumber,
            verificationCodeId,
            userType,
            isAcceptedContact,
          },
        };
        history.push("/signup/sms-verification", locationState);
      })
      .catch(error => {
        if (error instanceof APIError) {
          switch (error.code) {
            case APIErrorCode.phoneNumberTaken:
              this.setState({
                phoneNumberErrorId: getAPIErrorMessageId(error),
                remoteSignup: Initial(),
              });
              break;
            case APIErrorCode.emailTaken:
              this.setState({
                emailErrorId: getAPIErrorMessageId(error),
                remoteSignup: Initial(),
              });
              break;
            default:
              this.setState({ remoteSignup: Initial() });
              this.props.errorAlertContext.show(error);
          }
        }
      })
      .finally(this.props.loadingContext.dismiss);
  };

  openStaticPage = async (key: StaticPageType) => {
    this.props.loadingContext.show();
    await Browser.open({ url: getStaticPageUrl(key) });
    this.props.loadingContext.dismiss();
  };

  render() {
    const { phoneNumberErrorId, emailErrorId, remoteSignup } = this.state;
    return (
      <SignupView
        {...{
          phoneNumberErrorId,
          emailErrorId,
          remoteSignup,
        }}
        onSignup={this.onSignup}
        onCloseClick={this.onCloseClick}
        onLoginClick={this.onLoginClick}
        onSkipClick={this.onSkipClick}
        openStaticPage={this.openStaticPage}
      />
    );
  }
}

export const SignupScreen = withLoading(
  withErrorAlert(withUser(SignupScreenImpl))
);
