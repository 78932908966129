import React from "react";
import { FormattedMessage } from "@oursky/react-messageformat";

import styles from "./styles.module.scss";
import { isLoading, RemoteData } from "../../utils/remoteData";
import {
  BorrowerOtherIncomeInfo,
  BorrowerWorkInfo,
  OtherIncome,
} from "../../models";
import { ValidationError } from "../../utils/validate";

import { IonHeader, IonToolbar } from "@ionic/react";
import { MPContent } from "../MPContent";
import { BackButton } from "../BackButton";
import { PrimaryButton } from "../Button";
import BorrowerWorkInfoForm, { InputFields } from "./BorrowerWorkInfoForm";
import SuggestionDialogue from "../SuggestionDialogue/SuggestionDialogue";

interface Props {
  remoteUpdate: RemoteData<void>;
  borrowerWorkInfo: BorrowerWorkInfo;
  updateBorrowerWorkInfo: (value: Partial<BorrowerWorkInfo>) => void;
  hasIncomeProof: boolean | null;
  updateHasIncomeProof: (hasIncomeProof: boolean) => void;
  borrowerOtherIncomeInfo: BorrowerOtherIncomeInfo;
  onUpdateExistingOtherIncome: (
    index: number,
    value: Partial<OtherIncome>
  ) => void;
  onSelectNumberOfOtherIncomes: (numberOfOtherIncomes: number) => void;
  onUpdateHasOtherIncome: (hasOtherIncome: boolean) => void;
  error: ValidationError<InputFields>;
  focusField?: InputFields;
  onSubmit?: () => void;
  onSkipToContact: () => void;
}

interface State {
  isSkipToContactViewShown: boolean;
}
class BorrowerWorkInfoView extends React.PureComponent<Props, State> {
  state: State = {
    isSkipToContactViewShown: true,
  };

  hideSkipToContactView = () => {
    this.setState({ isSkipToContactViewShown: false });
  };

  skipToContact = () => {
    this.setState({ isSkipToContactViewShown: false });
    this.props.onSkipToContact();
  };

  render() {
    const {
      remoteUpdate,
      borrowerWorkInfo,
      updateBorrowerWorkInfo,
      hasIncomeProof,
      updateHasIncomeProof,
      borrowerOtherIncomeInfo,
      onUpdateExistingOtherIncome,
      onSelectNumberOfOtherIncomes,
      onUpdateHasOtherIncome,
      error,
      focusField,
      onSubmit,
    } = this.props;
    return (
      <>
        <IonHeader class={styles.toolbarContainer} mode="ios">
          <IonToolbar mode="ios">
            <div className={styles.back}>
              <BackButton />
            </div>
          </IonToolbar>
        </IonHeader>
        <MPContent class={styles.content} overflowScrolling="auto">
          <h1>
            <FormattedMessage id="borrower_work_info.title" />
          </h1>
          <div className={styles.description}>
            <FormattedMessage id="borrower_work_info.description" />
          </div>
          <BorrowerWorkInfoForm
            borrowerWorkInfo={borrowerWorkInfo}
            updateBorrowerWorkInfo={updateBorrowerWorkInfo}
            hasIncomeProof={hasIncomeProof}
            updateHasIncomeProof={updateHasIncomeProof}
            borrowerOtherIncomeInfo={borrowerOtherIncomeInfo}
            onUpdateExistingOtherIncome={onUpdateExistingOtherIncome}
            onSelectNumberOfOtherIncomes={onSelectNumberOfOtherIncomes}
            onUpdateHasOtherIncome={onUpdateHasOtherIncome}
            error={error}
            focusField={focusField}
          />
          {this.state.isSkipToContactViewShown && (
            <div className={styles.dialogueWrapper}>
              <SuggestionDialogue
                titleId={"request.skip_to_contact.title"}
                confirmButtonTextId={"request.skip_to_contact.button.confirm"}
                cancelButtonTextId={"request.skip_to_contact.button.cancel"}
                onConfirm={this.skipToContact}
                onCancel={this.hideSkipToContactView}
              />
            </div>
          )}
          <PrimaryButton
            class={styles.submitButton}
            expand="full"
            onClick={onSubmit}
            disabled={isLoading(remoteUpdate)}
          >
            <FormattedMessage id={"borrower_work_info.next"} />
          </PrimaryButton>
        </MPContent>
      </>
    );
  }
}

export default BorrowerWorkInfoView;
