import React from "react";
import ReactDOM from "react-dom";
import "./styles/global.scss";
import App from "./App";

import * as serviceWorker from "./serviceWorker";

import * as SentryCordova from "sentry-cordova";
import * as SentryBrowser from "@sentry/browser";
import { SENTRY_DSN } from "./config";

import { hybridOnly, browserOnly } from "./utils/platform";

function setViewportHeight() {
  const root = document.documentElement;
  root.style.setProperty("--viewport-height", `${window.innerHeight}px`);
}

setViewportHeight();
window.addEventListener("resize", setViewportHeight);

browserOnly(() => {
  SentryBrowser.init({ dsn: SENTRY_DSN });
});
hybridOnly(() => {
  document.addEventListener(
    "deviceready",
    () => {
      SentryCordova.init({ dsn: SENTRY_DSN });
    },
    false
  );
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
