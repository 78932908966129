import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonImg,
} from "@ionic/react";
import { PrimaryButton } from "../Button";
import LoadingOverlay from "../LoadingOverlay";
import { FormattedMessage } from "@oursky/react-messageformat";
import { common } from "../../images";
import { SKYGEAR_ENDPOINT } from "../../config";

import styles from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onAccept: () => void;
}

interface State {
  isLoading: boolean;
  hasScrollToBottom: boolean;
}

export class TermAndConditionView extends React.PureComponent<Props, State> {
  state = {
    isLoading: true,
    hasScrollToBottom: false,
  };

  componentDidMount() {
    window.addEventListener("message", this.handleMessage);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleMessage);
  }

  handleMessage = (e: MessageEvent) => {
    if (e.data === "ReachBottom") {
      this.setState({ hasScrollToBottom: true });
    }
  };

  onIFrameLoaded = () => {
    this.setState({ isLoading: false });
  };

  render() {
    return (
      this.props.isOpen && (
        <div className={styles.container}>
          <IonHeader mode="ios">
            <IonToolbar mode="ios">
              <IonButtons slot="start">
                <IonButton onClick={this.props.onCancel}>
                  <IonImg src={common.buttonCross} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <iframe
            src={`${SKYGEAR_ENDPOINT}static/tc.html`}
            title="Terms and Conditions"
            onLoad={this.onIFrameLoaded}
          />
          <div className={styles.bottomBox}>
            <PrimaryButton
              expand="full"
              onClick={this.props.onAccept}
              disabled={!this.state.hasScrollToBottom}
            >
              <FormattedMessage id="signup.read_and_accept_t_and_c" />
            </PrimaryButton>
          </div>
          <LoadingOverlay isOpen={this.state.isLoading} />
        </div>
      )
    );
  }
}
