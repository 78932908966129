import React from "react";

import {
  FormattedDropdown,
  FormattedDropdownProps,
  Option as FormattedDPOption,
} from "../FormattedDropdown";

import { Omit } from "../../utils/typeutils";

export type TUGradeDropdownProps = Omit<FormattedDropdownProps, "options">;

export const TUOptions: FormattedDPOption[] = [
  { key: "null", value: "", labelId: "option.tu_grade.no_tu" },
  {
    key: "A",
    value: "A",
    labelId: "option.tu_grade.level",
    labelValues: { level: "A" },
  },
  {
    key: "B",
    value: "B",
    labelId: "option.tu_grade.level",
    labelValues: { level: "B" },
  },
  {
    key: "C",
    value: "C",
    labelId: "option.tu_grade.level",
    labelValues: { level: "C" },
  },
  {
    key: "D",
    value: "D",
    labelId: "option.tu_grade.level",
    labelValues: { level: "D" },
  },
  {
    key: "E",
    value: "E",
    labelId: "option.tu_grade.level",
    labelValues: { level: "E" },
  },
  {
    key: "F",
    value: "F",
    labelId: "option.tu_grade.level",
    labelValues: { level: "F" },
  },
  {
    key: "G",
    value: "G",
    labelId: "option.tu_grade.level",
    labelValues: { level: "G" },
  },
  {
    key: "H",
    value: "H",
    labelId: "option.tu_grade.level",
    labelValues: { level: "H" },
  },
  {
    key: "I",
    value: "I",
    labelId: "option.tu_grade.level",
    labelValues: { level: "I" },
  },
  {
    key: "J",
    value: "J",
    labelId: "option.tu_grade.level",
    labelValues: { level: "J" },
  },
];

export const TUGradeDropdown: React.FC<TUGradeDropdownProps> = props => {
  return <FormattedDropdown options={TUOptions} {...props as any} />;
};
