import React from "react";
import { FormattedMessage } from "@oursky/react-messageformat";
import styles from "./styles.module.scss";
import { CalculatorType } from "../../types/calculator";
import { formatToCurrencyAmount } from "../../utils/formatToCurrencyAmount";
import CalculatorItem from "../CalculatorItem";
import { handleNumberKeyDown } from "../../utils/keyboard";

interface Props {
  loan: string;
  interestRate: string;
  period: string;
  repayment: string;
  commissionRate: string;
  totalRepayment: string;
  totalCommission: string;
  lockedCalculator: CalculatorType;
  onLoanChanged: (loan: string) => void;
  onInterestRateChanged: (interestRate: string) => void;
  onPeriodChanged: (period: string) => void;
  onRepaymentChanged: (repayment: string) => void;
  onCommissionRateChanged: (commissionRate: string) => void;
  lockClicked: (calculatorType: CalculatorType) => void;
}

class AgentCalculatorView extends React.PureComponent<Props> {
  lockClicked = (calculator: CalculatorType) => {
    return () => {
      this.props.lockClicked(calculator);
    };
  };

  onLoanChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount: string = e.target.value || "";
    const formattedAmount = formatToCurrencyAmount(amount) || "";
    this.props.onLoanChanged(formattedAmount);
  };

  onInterestRateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rate: string = e.target.value || "";
    const reg = /^(\d{1,2})(\.(\d{1,2})?)?$/;
    if (reg.test(rate) || rate === "") {
      this.props.onInterestRateChanged(rate);
    }
  };

  onPeriodChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const period: string = e.target.value || "";
    this.props.onPeriodChanged(period);
  };

  onRepaymentChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount: string = e.target.value || "";
    const formattedAmount = formatToCurrencyAmount(amount) || "";
    this.props.onRepaymentChanged(formattedAmount);
  };

  onCommissionRateChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rate: string = e.target.value || "";
    const reg = /^(\d{1,2})(\.(\d{1,2})?)?$/;
    if (reg.test(rate) || rate === "") {
      this.props.onCommissionRateChanged(rate);
    }
  };

  render() {
    const { totalRepayment, totalCommission } = this.props;
    return (
      <div className={styles.calculatorContainer}>
        <CalculatorItem
          value={this.props.loan}
          isLockedCalculator={
            this.props.lockedCalculator === CalculatorType.Loan
          }
          calculatorType={CalculatorType.Loan}
          onValueChanged={this.onLoanChanged}
          lockClicked={this.lockClicked(CalculatorType.Loan)}
        />

        <CalculatorItem
          value={this.props.interestRate}
          isLockedCalculator={
            this.props.lockedCalculator === CalculatorType.InterestRate
          }
          calculatorType={CalculatorType.InterestRate}
          onValueChanged={this.onInterestRateChanged}
          lockClicked={this.lockClicked(CalculatorType.InterestRate)}
        />

        <CalculatorItem
          value={this.props.period}
          isLockedCalculator={
            this.props.lockedCalculator === CalculatorType.Period
          }
          calculatorType={CalculatorType.Period}
          onValueChanged={this.onPeriodChanged}
          lockClicked={this.lockClicked(CalculatorType.Period)}
        />

        <CalculatorItem
          value={this.props.repayment}
          isLockedCalculator={
            this.props.lockedCalculator === CalculatorType.Repayment
          }
          calculatorType={CalculatorType.Repayment}
          onValueChanged={this.onRepaymentChanged}
          lockClicked={this.lockClicked(CalculatorType.Repayment)}
        />
        <div className={styles.calculatorContent}>
          <div className={styles.calculatorLabel}>
            <FormattedMessage id="calculator.commission" />
          </div>
          <div className={styles.calculatorInputField}>
            <input
              className={styles.calculatorInputUnit}
              onKeyDown={handleNumberKeyDown}
              onChange={this.onCommissionRateChanged}
              type="number"
              value={this.props.commissionRate}
            />
            <div className={styles.calculatorInputLabel}>%</div>
          </div>
        </div>
        <div className={styles.calculatorTotalInformation}>
          <div className={styles.calculatorTotalContent}>
            <span className={styles.calculatorTotalLabel}>
              <FormattedMessage id="calculator.total_repayment" />
            </span>
            <span className={styles.calculatorTotalResult}>
              {totalRepayment !== ""
                ? `HKD ${formatToCurrencyAmount(totalRepayment)}`
                : "-"}
            </span>
          </div>
          <div className={styles.calculatorTotalContent}>
            <span className={styles.calculatorTotalLabel}>
              <FormattedMessage id="calculator.total_commission" />
            </span>
            <span className={styles.calculatorTotalResult}>
              {totalCommission !== ""
                ? `HKD ${formatToCurrencyAmount(totalCommission)}`
                : "-"}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default AgentCalculatorView;
