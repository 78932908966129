import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { IonLabel, IonTabBar, IonTabButton } from "@ionic/react";
import {
  withPresentation,
  withNav,
  PresentationContextProps,
  OurNavContextProps,
} from "../../navigation";
import { withUser, UserContextProps } from "../../context/UserContext";
import { withChat, ChatContextProps } from "../../context/ChatContext";
import { RouteConfig, Icon } from "../../types/routes";
import { FormattedMessage } from "@oursky/react-messageformat";
import styles from "./styles.module.scss";

import { ValueWatcher } from "../ValueWatcher";

interface Props
  extends PresentationContextProps,
    OurNavContextProps,
    UserContextProps,
    RouteComponentProps,
    ChatContextProps {
  routeConfigs: RouteConfig[];
}

interface State {
  unreadCount: number;
}

class TabBarImpl extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { unreadCount: 0 };
    if (props.userContext.user) {
      props.chatContext.getTotalUnreadCount().then(unreadCount => {
        this.setState({ unreadCount });
      });
    }
  }

  presentLogin = () => {
    this.props.presentationContext.present("/login");
  };

  // NOTE:(jasonkit)
  // IonTabButton's href attribute did not change after render even if its
  // driving value is changed. Do due with this, we don't set href and use
  // onClick to do navigation.
  // As there is a <a> under <ion-tab-button>, url change will take effect
  // which will push an entry to browser history. To make back button
  // work properly for tab switching, here we will use replace instead of
  // push for navigation.
  navigate = (e: React.MouseEvent<HTMLIonTabButtonElement>) => {
    const tab = (e.currentTarget && e.currentTarget.getAttribute("tab")) || "";
    const { getActiveTab, getActivePathForTab } = this.props.navContext;
    const path =
      (getActiveTab() !== tab && getActivePathForTab(tab)) || `/${tab}`;
    this.props.history.replace(path);
  };

  renderTabButton(
    tabName: string,
    icon: Icon,
    labelId: string,
    callback: (e: any) => void
  ) {
    const { pathname } = this.props.location;
    const tabPath = `/${tabName}`;
    return (
      <IonTabButton
        key={tabName}
        tab={tabName}
        class={styles.tabButton}
        onClick={callback}
        mode="ios"
      >
        <div className={styles.iconContainer}>
          {tabName === "chat" && this.state.unreadCount > 0 && (
            <div className={styles.unreadIndicator} />
          )}
          <img
            src={pathname.startsWith(tabPath) ? icon.active : icon.inactive}
            alt={tabName}
          />
        </div>
        <IonLabel mode="ios" class={styles.tabText}>
          <FormattedMessage id={labelId} />
        </IonLabel>
      </IonTabButton>
    );
  }

  onChatListVersionChanged = async (_listVersion: number) => {
    if (this.props.userContext.user) {
      this.setState({
        unreadCount: await this.props.chatContext.getTotalUnreadCount(),
      });
    } else {
      this.setState({
        unreadCount: 0,
      });
    }
  };

  render() {
    const { user } = this.props.userContext;
    const { chatListVersion } = this.props.chatContext;
    const { routeConfigs } = this.props;
    return (
      <>
        <ValueWatcher
          value={chatListVersion}
          trigger={this.onChatListVersionChanged}
        />
        <ValueWatcher value={!!user} trigger={this.onChatListVersionChanged} />
        <IonTabBar class={styles.tabBar}>
          {routeConfigs.map(route => {
            if (!route.tab) {
              return null;
            }

            return this.renderTabButton(
              route.path,
              route.tab.icon,
              `main.tab.${route.path}`,
              !route.isPublic && !user ? this.presentLogin : this.navigate
            );
          })}
        </IonTabBar>
      </>
    );
  }
}

export const TabBar = withRouter(
  withNav(withUser(withChat(withPresentation(TabBarImpl))))
);
