import React from "react";
import Swiper from "swiper";

import { IonSlides, IonSlide, IonButton } from "@ionic/react";
import { RouteComponentProps, withRouter } from "react-router";
import { FormattedMessage } from "@oursky/react-messageformat";

import { WalkthroughContext } from "../../context/WalkthroughContext";

import styles from "./styles.module.scss";
import logo from "../../images/logo-with-name.svg";
import { walkthrough as images } from "../../images";
import { MPContent } from "../MPContent";

import { BorrowerRouteDefaultPath } from "../../routes/BorrowerRoutes";

const startButton = "startButton";
const walkthroughHeader = "walkthroughHeader";

const slideOpts = {
  mode: "ios",
  speed: 400,
  zoom: false,
  pagination: {
    el: `.${styles.swiper_pagination}`,
    clickable: true,
    type: "custom",
    renderCustom: function(_swiper: any, current: number, total: number) {
      let text = "<span class=" + styles.swiper + ">";
      for (let i = 1; i <= total; i++) {
        if (current === i) {
          text += `<span class=${styles.swiperPaginationIndex} + ></span>`;
        } else {
          text += `<span class=${styles.swiperPagination}></span > `;
        }
      }

      text += "</span>";
      return text;
    },
  },
  on: {
    slideChange(this: Swiper) {
      const btn = document.getElementById(startButton);
      const header = document.getElementById(walkthroughHeader);

      if (btn !== null && this.activeIndex === 3) {
        btn.style.visibility = "visible";
      } else if (btn !== null) {
        btn.style.visibility = "hidden";
      }

      if (header !== null) {
        if (this.activeIndex <= 2) {
          header.style.visibility = "visible";
        } else {
          header.style.visibility = "hidden";
        }
      }
    },
  },
};

type WalkthroughViewProps = RouteComponentProps<any>;

const WalkthroughView: React.FC<WalkthroughViewProps> = props => {
  const { history } = props;
  const { markAsDone } = React.useContext(WalkthroughContext);
  const onClick = React.useCallback(() => {
    markAsDone().then(() => {
      history.push(BorrowerRouteDefaultPath);
    });
  }, [history, markAsDone]);

  return (
    <MPContent forceOverscroll={false}>
      <div id={walkthroughHeader} className={styles.walkthroughHeader}>
        <img src={logo} alt="logo" />
      </div>
      <IonSlides pager={false} options={slideOpts}>
        <IonSlide>
          <div className={styles.walkthroughContainer}>
            <div className={styles.walkthroughSlider}>
              <img src={images.walkthrough1} alt="slider1" />
            </div>
            <div className={styles.walkthroughContent}>
              <FormattedMessage id="walkthrough.direct_contact" />
            </div>
          </div>
        </IonSlide>
        <IonSlide>
          <div className={styles.walkthroughContainer}>
            <div className={styles.walkthroughSlider}>
              <img src={images.walkthrough2} alt="slider2" />
            </div>
            <div className={styles.walkthroughContent}>
              <FormattedMessage id="walkthrough.save_time" />
            </div>
          </div>
        </IonSlide>
        <IonSlide>
          <div className={styles.walkthroughContainer}>
            <div className={styles.walkthroughSlider}>
              <img src={images.walkthrough3} alt="slider3" />
            </div>
            <div className={styles.walkthroughContent}>
              <FormattedMessage id="walkthrough.best_plan" />
            </div>
          </div>
        </IonSlide>
        <IonSlide>
          <div className={styles.walkthroughExtendedContainer}>
            <div className={styles.walkthroughContent}>
              <FormattedMessage id="walkthrough.borrower_intro" />
            </div>
            <div className={styles.walkthroughSlider}>
              <img src={images.walkthrough4} alt="slider4" />
            </div>
            <div className={styles.walkthroughStart}>
              <IonButton
                id={startButton}
                fill="clear"
                class={styles.walkthroughBtn}
                onClick={onClick}
              >
                <FormattedMessage id="walkthrough.start" />
              </IonButton>
            </div>
          </div>
        </IonSlide>
      </IonSlides>
      <div className={styles.swiper_pagination} />
    </MPContent>
  );
};

export default withRouter(WalkthroughView);
