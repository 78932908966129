import React from "react";
import styles from "./styles.module.scss";
import { FormattedMessage } from "@oursky/react-messageformat";

interface Props {
  labelId: string;
  value?: string;
}

const AccountItem: React.FC<Props> = props => {
  return (
    <div className={styles.accountItem}>
      <div className={styles.accountItemLabel}>
        <FormattedMessage id={props.labelId} />
      </div>
      <div className={styles.accountItemContent}>
        <label>{props.value}</label>
      </div>
    </div>
  );
};

export default AccountItem;
