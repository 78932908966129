import React from "react";

import styles from "./styles.module.scss";

interface Props {
  isOpen: boolean;
  onBackdropClick: () => void;
}

export const PopOver: React.FC<Props> = props => {
  const backdrop = React.useRef<HTMLDivElement>(null);

  const onPopOverClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
    },
    []
  );

  return props.isOpen ? (
    <div
      ref={backdrop}
      className={styles.backdrop}
      onClick={props.onBackdropClick}
    >
      <div onClick={onPopOverClick}>{props.children}</div>
    </div>
  ) : null;
};
