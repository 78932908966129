import { Record, Asset } from "skygear";
import skygearchat, { SkygearChatSubscribeHandlerData } from "skygear-chat";
import { DateTime } from "luxon";

import { Conversation, Message } from "../models";
import { BUILD, SKYGEAR_ENDPOINT } from "../config";

enum MessageOrder {
  byCreate = "_created_at",
  byEdit = "edited_at",
}

export interface ChatService {
  subscribe: () => void;
  unsubscribe: () => void;
  getConversation: (
    conversationId: string,
    includeLastMessage?: boolean
  ) => Promise<Record>;
  createMessage: (
    conversation: Record,
    body: string,
    metadata?: any,
    asset?: Asset
  ) => Promise<Message>;
  onMessageCreated: (
    conversationId: string,
    handler: (message: Message) => void
  ) => void;
  subscribeMessageEvent: (
    handler: (message: Message, event: string) => void
  ) => void;
  unsubscribeMessageCreated: (conversationId: string) => void;
  onConversationDeleted: (
    conversationId: string,
    handler: (conversation: Conversation) => void
  ) => void;
  unsubscribeConversationDeleted: (conversationId: string) => void;
  getMessages: (
    conversation: Record,
    beforeTime?: DateTime,
    limit?: number,
    order?: MessageOrder
  ) => Promise<Message[]>;

  getTotalUnreadCount: () => Promise<number>;
}

export class SkygearChatService implements ChatService {
  messageCreateHandlers: { [key: string]: (message: Message) => void } = {};
  messageEventHandler?: (message: Message, event: string) => void;
  conversationDeleteHandlers: {
    [key: string]: (conversation: Conversation) => void;
  } = {};

  subscribe() {
    skygearchat.subscribe(this.handleSkygearChatData);
  }

  unsubscribe() {
    skygearchat.unsubscribe(this.handleSkygearChatData);
  }

  handleSkygearChatData = (data: SkygearChatSubscribeHandlerData) => {
    if (data.record_type === "message") {
      const message = this.makeMessage(data.record);

      if (this.messageEventHandler) {
        this.messageEventHandler(message, data.event_type);
      }

      if (
        data.event_type === "create" &&
        message.conversationId in this.messageCreateHandlers
      ) {
        skygearchat.markAsRead([data.record]);
        this.messageCreateHandlers[message.conversationId](message);
      }
    }

    if (data.record_type === "conversation") {
      const conversation = this.makeConversation(data.record);

      if (
        data.event_type === "delete" &&
        conversation.id in this.conversationDeleteHandlers
      ) {
        this.conversationDeleteHandlers[conversation.id](conversation);
      }
    }
  };

  convertUrl = (url: string) => {
    if (BUILD === "dev") {
      const endpoint =
        SKYGEAR_ENDPOINT[SKYGEAR_ENDPOINT.length - 1] === "/"
          ? SKYGEAR_ENDPOINT.substring(0, SKYGEAR_ENDPOINT.length - 1)
          : SKYGEAR_ENDPOINT;
      return url.replace("http://skygear-server:3000", endpoint);
    }
    return url;
  };

  private makeMessage = (record: Record): Message => {
    return {
      id: record._id,
      userId: record.createdBy,
      userType: record.metadata.userType,
      isAutoMessage: record.metadata.autoMessage || false,
      isRichText: record.metadata.isRichText || false,
      conversationId: record.conversation._id.split("/")[1],
      body: record.body,
      timestamp: DateTime.fromJSDate(record.createdAt),
      attachmentUrl: record.attachment
        ? this.convertUrl(record.attachment.url)
        : undefined,
      attachmentType: record.metadata.attachmentType,
      isCompleted:
        "isCompleted" in record.metadata
          ? record.metadata.isCompleted
          : undefined,
      referralAgreementStatus:
        "referralAgreementStatus" in record.metadata
          ? record.metadata.referralAgreementStatus
          : undefined,
    };
  };

  private makeConversation = (record: Record): Conversation => {
    return {
      id: record._id,
      title: record.title,
      distinctByParticipants: record.distinctByParticipants,
      metadata: record.metadata,
    };
  };

  getConversation(
    conversationId: string,
    includeLastMessage?: boolean
  ): Promise<Record> {
    return skygearchat.getConversation(conversationId, includeLastMessage);
  }

  createMessage(
    conversation: Record,
    body: string,
    metadata?: any,
    asset?: Asset
  ): Promise<Message> {
    return skygearchat
      .createMessage(conversation, body, metadata, asset)
      .then(this.makeMessage);
  }

  onMessageCreated(
    conversationId: string,
    handler: (message: Message) => void
  ) {
    this.messageCreateHandlers[conversationId] = handler;
  }

  onConversationDeleted(
    conversationId: string,
    handler: (conversation: Conversation) => void
  ) {
    this.conversationDeleteHandlers[conversationId] = handler;
  }

  subscribeMessageEvent(handler: (message: Message, event: string) => void) {
    this.messageEventHandler = handler;
  }

  unsubscribeMessageCreated(conversationId: string) {
    delete this.messageCreateHandlers[conversationId];
  }

  unsubscribeConversationDeleted(conversationId: string) {
    delete this.conversationDeleteHandlers[conversationId];
  }

  getMessages(
    conversation: Record,
    beforeTime?: DateTime,
    limit = 30,
    order = MessageOrder.byCreate
  ): Promise<Message[]> {
    return skygearchat
      .getMessages(
        conversation,
        limit,
        beforeTime ? beforeTime.toJSDate() : new Date(),
        order
      )
      .then(async records => {
        await skygearchat.markAsRead(records);
        return records;
      })
      .then(records =>
        records
          .map(this.makeMessage)
          .sort((a, b) => a.timestamp.toMillis() - b.timestamp.toMillis())
      );
  }

  getTotalUnreadCount(): Promise<number> {
    return skygearchat.getUnreadCount().then(x => x.message);
  }
}

export function makeSkygearChatService() {
  return new SkygearChatService();
}
