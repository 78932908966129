import { IonContent } from "@ionic/react";
import React from "react";

import { KeyboardScrollHandler } from "../../utils/keyboard";
import {
  setContentBackground,
  setOverflowScrolling,
} from "../../utils/ionic-patch";
import { Omit } from "../../utils/typeutils";

export type MPContentProps = Omit<
  React.ComponentProps<typeof IonContent>,
  "ref"
> & {
  backgroundImage?: string;
  backgroundConfig?: string;
  overflowScrolling?: "auto" | "touch";
  setRef?: (element: HTMLIonContentElement) => void;
  noKeyboardScrollHandler?: boolean;
};

export class MPContent extends React.PureComponent<MPContentProps> {
  contentRef?: HTMLIonContentElement;
  keyboardScrollHandler?: KeyboardScrollHandler;

  setContentRef = (ref: HTMLIonContentElement) => {
    this.contentRef = ref;

    const { backgroundImage, backgroundConfig, overflowScrolling } = this.props;
    if (backgroundImage) {
      setContentBackground(ref, backgroundImage, backgroundConfig);
    }

    if (overflowScrolling) {
      setOverflowScrolling(ref, overflowScrolling);
    }

    if (this.props.setRef) {
      this.props.setRef(ref);
    }
  };

  componentDidMount() {
    if (this.contentRef && this.props.noKeyboardScrollHandler !== true) {
      this.keyboardScrollHandler = new KeyboardScrollHandler(this.contentRef);
    }
  }

  componentWillUnmount() {
    if (this.keyboardScrollHandler) {
      this.keyboardScrollHandler.destroy();
      this.keyboardScrollHandler = undefined;
    }
  }

  render() {
    const {
      backgroundImage,
      backgroundConfig,
      overflowScrolling,
      setRef,
      noKeyboardScrollHandler,
      ...rest
    } = this.props;
    return <IonContent {...rest} ref={this.setContentRef} />;
  }
}
