import { BaseApiClient, ApiClientConstructor } from "./client";

import { Template } from "../models";
import { templateResponseSchema } from "../types/response";

export interface TemplateApiClient {
  fetchDefaultTemplate: () => Promise<Template[]>;
}

export function withTemplateApi<
  TBase extends ApiClientConstructor<BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async fetchDefaultTemplate(): Promise<Template[]> {
      const resp = await this.requestLambda(
        "template:fetch-default",
        {
          api_version: "2",
        },
        templateResponseSchema
      );

      return resp.templates;
    }
  };
}
