import React from "react";

import styles from "./styles.module.scss";

interface Props {
  name: string;
  company: string;
  logo?: string | null;
}

const AgentInfoRow: React.FC<Props> = props => {
  return (
    <div className={styles.agentInfoRow}>
      <div className={styles.nameAndCompany}>
        <div className={styles.name}>{props.name}</div>
        <div className={styles.company}>{props.company}</div>
      </div>
      <div
        className={styles.logo}
        style={{
          backgroundImage: props.logo ? `url(${props.logo})` : undefined,
        }}
      />
    </div>
  );
};

export default AgentInfoRow;
