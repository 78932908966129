import { FormattedMessage } from "@oursky/react-messageformat";
import React from "react";

import { MortgageLevelOptions } from "../SpecifyDropdown";

import {
  ExchangeLoanRequest,
  MortgageRequest,
  PersonalLoanRequest,
  Request,
  RequestType,
} from "../../models";

import { formatToCurrencyAmount } from "../../utils/formatToCurrencyAmount";

import styles from "./RequestItem.module.scss";

export interface RequestItemProps {
  request: Request;
  onClickOfferCount: (requestId: string, requestRefNum: number) => void;
}

export const RequestItem: React.FC<RequestItemProps> = props => {
  const { request, onClickOfferCount } = props;

  const onClick = React.useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      if (request.numOffering !== null && request.numOffering > 0) {
        onClickOfferCount(request.id, request.refNum);
      }
    },
    [request, onClickOfferCount]
  );

  return (
    <button className={styles.item} onClick={onClick}>
      <div className={styles.container}>
        {!!request.unreadCount && <div className={styles.unreadIndicator} />}
        <div className={styles.contentContainer}>
          <RequestItemContent request={request} />
          <p className={styles.requestId}>
            <FormattedMessage
              id="borrower.requests.ref_num"
              values={{ refNum: request.refNum }}
            />
          </p>
        </div>
        <OfferCountView count={request.numOffering} />
      </div>
    </button>
  );
};

interface RequestItemContentProps {
  request: Request;
}

const RequestItemContent: React.FC<RequestItemContentProps> = props => {
  const { request } = props;

  switch (request.type) {
    case RequestType.exchangeLoan:
      return <ExchangeLoanRequestItemContent request={request} />;
    case RequestType.mortgage:
      return <MortgageRequestItemContent request={request} />;
    case RequestType.personalLoan:
      return <PersonalLoadRequestItemContent request={request} />;
    default:
      throw new Error(`Unexpected request = ${request}`);
  }
};

interface ExchangeLoanRequestItemContentProps {
  request: ExchangeLoanRequest;
}

const ExchangeLoanRequestItemContent: React.FC<
  ExchangeLoanRequestItemContentProps
> = props => {
  const { request } = props;

  return (
    <>
      <h2 className={styles.contentHeader}>
        <FormattedMessage id="request.exchange_loan" />
      </h2>
      <div className={styles.contentItemContainer}>
        <div className={styles.contentItem}>
          <h3>
            <FormattedMessage
              id="request.exchange_loan.amount.title"
              values={{ currency: request.sellingCurrency.isoCode }}
            />
          </h3>
          <p>{formatToCurrencyAmount(request.sellingAmount)}</p>
        </div>
        <div className={styles.contentItem}>
          <h3>
            <FormattedMessage id="request.exchange_loan.to_currency.title" />
          </h3>
          <p>{request.buyingCurrency.isoCode}</p>
        </div>
      </div>
    </>
  );
};

interface MortgageRequestItemContentProps {
  request: MortgageRequest;
}

const MortgageRequestItemContent: React.FC<
  MortgageRequestItemContentProps
> = props => {
  const { request } = props;

  const mortgageLevel = MortgageLevelOptions.find(
    option => option.value === request.mortgageLevel
  );
  if (!mortgageLevel) {
    throw new Error(`Unexpected mortgage level = ${request.mortgageLevel}`);
  }

  return (
    <>
      <h2 className={styles.contentHeader}>
        <FormattedMessage id="request.mortgage" />
      </h2>
      <div className={styles.contentItemContainer}>
        <div className={styles.contentItem}>
          <h3>
            <FormattedMessage id="request.mortgage.loan_amount.title" />
          </h3>
          <p>{formatToCurrencyAmount(request.loanAmount, true)}</p>
        </div>
        <div className={styles.contentItem}>
          <h3>
            <FormattedMessage id="request.mortgage.level.title" />
          </h3>
          <p>
            <FormattedMessage id={mortgageLevel.labelId} />
          </p>
        </div>
      </div>
      <div className={styles.contentItemContainer}>
        <div className={styles.contentItem}>
          <h3>
            <FormattedMessage id="request.mortgage.value.title" />
          </h3>
          <p>{formatToCurrencyAmount(request.buildingValue, true)}</p>
        </div>
      </div>
    </>
  );
};

interface PersonalLoadRequestItemContentProps {
  request: PersonalLoanRequest;
}

const PersonalLoadRequestItemContent: React.FC<
  PersonalLoadRequestItemContentProps
> = props => {
  const { request } = props;

  return (
    <>
      <h2 className={styles.contentHeader}>
        <FormattedMessage id="request.personal_loan" />
      </h2>
      <div className={styles.contentItemContainer}>
        <div className={styles.contentItem}>
          <h3>
            <FormattedMessage id="request.personal_loan.amount.title" />
          </h3>
          <p>{formatToCurrencyAmount(request.amount, true)}</p>
        </div>
        <div className={styles.contentItem}>
          <h3>
            <FormattedMessage id="request.personal_loan.period.title" />
          </h3>
          <p>
            {request.period > 0 ? (
              <FormattedMessage
                id="request.personal_loan.period.value"
                values={{ period: request.period, unit: request.period }}
              />
            ) : (
              "-"
            )}
          </p>
        </div>
      </div>
    </>
  );
};

interface OfferCountViewProps {
  count: number | null;
}
const OfferCountView: React.FC<OfferCountViewProps> = props => {
  const { count } = props;
  return (
    <div className={styles.offerCount}>
      <div className={styles.offerCountCount}>{count ? count : 0}</div>
      <div className={styles.offerCountUnit}>
        <FormattedMessage
          id="borrower.requests.offer_unit"
          values={{ unit: count ? count : 0 }}
        />
      </div>
    </div>
  );
};
