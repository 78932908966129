import React from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

import { AgentChatListCardView } from "../AgentChatListCardView";
import { InfiniteScrollView } from "../InfiniteListView";

import { Page } from "../../utils/pagination";
import { Mode } from "../../screens/AgentChatScreen";
import { Chat } from "../../models";
import { ChatContextProps, withChat } from "../../context/ChatContext";

type Props = {
  fetchInProgressPage: (cursor?: string) => Promise<Page<Chat>>;
  fetchCompletedPage: (cursor?: string) => Promise<Page<Chat>>;
  mode: Mode;
  openChatroom: (chat: Chat) => void;

  completedInfiniteScrollViewRef: React.RefObject<InfiniteScrollView<Chat>>;
  inProgressInfiniteScrollViewRef: React.RefObject<InfiniteScrollView<Chat>>;
} & ChatContextProps;

class ListViewImpl extends React.PureComponent<Props> {
  renderItem = (chat: Chat, index: number) => {
    const { openChatroom, mode } = this.props;
    return (
      <AgentChatListCardView
        key={index}
        chat={chat}
        mode={mode}
        openChatroom={openChatroom}
      />
    );
  };

  render() {
    const {
      fetchInProgressPage,
      fetchCompletedPage,
      mode,
      completedInfiniteScrollViewRef,
      inProgressInfiniteScrollViewRef,
    } = this.props;
    const shouldShowInProgressList = mode === Mode.inProgress;
    const shouldShowCompletedList = mode === Mode.completed;
    return (
      <>
        <InfiniteScrollView
          key={0}
          ref={completedInfiniteScrollViewRef}
          listClassName={classNames(styles.list, {
            [styles.hide]: !shouldShowCompletedList,
          })}
          emptyMessageId="common.empty_list"
          fetchPage={fetchCompletedPage}
          renderItem={this.renderItem}
          threshold={400}
          disabled={!shouldShowCompletedList}
        />
        <InfiniteScrollView
          key={1}
          ref={inProgressInfiniteScrollViewRef}
          listClassName={classNames(styles.list, {
            [styles.hide]: !shouldShowInProgressList,
          })}
          emptyMessageId="common.empty_list"
          fetchPage={fetchInProgressPage}
          renderItem={this.renderItem}
          threshold={400}
          disabled={!shouldShowInProgressList}
        />
      </>
    );
  }
}

export const ListView = withChat(ListViewImpl);
