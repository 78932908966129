import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { Plugins } from "@capacitor/core";

import { LoadingContextProps, withLoading } from "../context/LoadingContext";
import { UserContextProps, withUser } from "../context/UserContext";
import AgentAccountView from "../components/AgentAccountView";

import { Agent, Borrower, UserData, UserType } from "../models";
import { StaticPageType } from "../types/misc";
import { getStaticPageUrl } from "../utils/misc";
import { PresentationContextProps, withPresentation } from "../navigation";

const { Browser } = Plugins;

type Props = UserContextProps &
  LoadingContextProps &
  RouteComponentProps &
  PresentationContextProps;

class AgentAccountScreen extends PureComponent<Props> {
  onLogOutButtonClick = () => {
    const { history } = this.props;

    this.props.userContext.logout().then(() => {
      history.push("/");
    });
  };

  onSwitchAgentClick = () => {
    this.props.presentationContext.present("/impersonate-agent");
  };

  checkIfAgent(user: UserData | Agent | Borrower | undefined) {
    return user && user.type === UserType.agent ? (user as Agent) : undefined;
  }

  openStaticPage = async (key: StaticPageType) => {
    this.props.loadingContext.show();
    await Browser.open({ url: getStaticPageUrl(key) });
    this.props.loadingContext.dismiss();
  };

  render() {
    const { user } = this.props.userContext;
    const agent = this.checkIfAgent(user);

    return (
      agent && (
        <AgentAccountView
          agent={agent}
          openStaticPage={this.openStaticPage}
          onSwitchAgentClick={this.onSwitchAgentClick}
          onLogOutButtonClick={this.onLogOutButtonClick}
        />
      )
    );
  }
}

export default withPresentation(withLoading(withUser(AgentAccountScreen)));
