import React from "react";
import styles from "./styles.module.scss";
import { FormattedMessage } from "@oursky/react-messageformat";

import classnames from "classnames";

interface Props {
  checked: boolean;
  isYellow: boolean;
  value: string;
}

const images = {
  checkedIcon: require("../../images/icon-account-check.png"),
  uncheckedIcon: require("../../images/icon-account-uncheck.png"),
};

const CheckedOptionItem: React.FC<Props> = props => {
  const { checked, isYellow } = props;
  return (
    <div className={styles.checkedOptionContainer}>
      <button
        className={classnames(styles.checkedOption, {
          [styles.checkedYellowOption]: props.isYellow,
        })}
      >
        <FormattedMessage id={props.value} />
      </button>
      <div className={styles.checkContainer}>
        <div className={styles.check}>
          <img
            src={props.checked ? images.checkedIcon : images.uncheckedIcon}
            alt={"checkedImage"}
          />
        </div>
        <div className={styles.checkLabel}>
          <FormattedMessage
            id={
              checked && !isYellow
                ? "account.option.validate.positive"
                : !checked && !isYellow
                ? "account.option.validate.negative"
                : checked && isYellow
                ? "account.option.premier.positive"
                : "account.option.premier.negative"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CheckedOptionItem;
