import { DateTime } from "luxon";
import { Currency } from "./request";
import { AgentBriefData } from "./agent";

export interface CreateForeignExchangeOffering
  extends CreateForeignExchangeForm {
  requestId: string;
  buyingAmount: number;
}

export interface CreateForeignExchangeForm {
  type: OfferingType.exchangeLoan;
  sellingCurrencyId: string;
  buyingCurrencyId: string;
  sellingAmount: number;
  validDays: number;
}

export interface CreateMortgageOffering extends CreateMortgageForm {
  requestId: string;
}

export interface CreateMortgageForm {
  type: OfferingType.mortgage;
  loanAmount: number;
  paymentPeriodMax: number;
  interestRateMin: string;
  interestRateMax: string;
  isRequiredEmployed: boolean;
  addition: string;
  validDays: number;
}

export interface CreatePersonalLoanOffering extends CreatePersonalLoanForm {
  requestId: string;
}

export interface CreatePersonalLoanForm {
  type: OfferingType.personalLoan;
  amountMin: number;
  amountMax: number;
  interestRateMin: string;
  interestRateMax: string;
  periodMin: number;
  periodMax: number;
  isRequiredEmployed: boolean;
  isRequiredProperty: boolean;
  addition: string;
  validDays: number;
}

export type OfferingForm =
  | CreateForeignExchangeForm
  | CreateMortgageForm
  | CreatePersonalLoanForm;

export enum BooleanString {
  true = "true",
  false = "false",
}

export enum OfferingFromMode {
  newOffering = "newOffering",
  template = "template",
}

export type Offering =
  | ExchangeLoanOffering
  | MortgageOffering
  | PersonalLoanOffering;

export enum OfferingType {
  exchangeLoan = "exchange_loan",
  mortgage = "mortgage",
  personalLoan = "personal_loan",
}

export interface BaseOffering {
  id: string;
  agent: AgentBriefData;
  cancelledAt: DateTime | null;
  createdAt: DateTime;
  expiredAt: DateTime | null;
  isFeatured: boolean;
  priority: number | null;
  chatId: string | null;
  directApplyLink: string | null;
}

export interface ExchangeLoanOffering extends BaseOffering {
  buyingCurrency: Currency;
  buyingAmount: number;
  sellingAmount: number;
  sellingCurrency: Currency;
  type: OfferingType.exchangeLoan;
}

export interface MortgageOffering extends BaseOffering {
  addition: string | null;
  loanAmount: number;
  paymentPeriodMax: number;
  interestRateMax: number;
  interestRateMin: number;
  isRequiredEmployed: boolean;
  mortgageLevel: string;
  buildingValue: number;
  type: OfferingType.mortgage;
}

export interface PersonalLoanOffering extends BaseOffering {
  addition: string | null;
  amountMax: number;
  amountMin: number;
  periodMax: number;
  periodMin: number;
  interestRateMax: string;
  interestRateMin: string;
  isRequiredEmployed: boolean;
  isRequiredProperty: boolean;
  type: OfferingType.personalLoan;
}
