import React from "react";
import classnames from "classnames";
import { FormattedMessage } from "@oursky/react-messageformat";

import styles from "./styles.module.scss";

interface State {
  isFadingOut: boolean;
  isHidden: boolean;
}

export class TIPSBox extends React.PureComponent<{}, State> {
  state: State = {
    isFadingOut: false,
    isHidden: true,
  };

  fadeInTimer?: number;
  fadeOutTimer?: number;
  hiddenTimer?: number;

  componentDidMount() {
    this.fadeInTimer = window.setTimeout(() => {
      this.setState({ isHidden: false });
    }, 500);

    this.fadeOutTimer = window.setTimeout(() => {
      this.fadeOutTimer = undefined;
      this.setState({ isFadingOut: true }, () => {
        this.hiddenTimer = window.setTimeout(() => {
          this.hiddenTimer = undefined;
          this.setState({ isFadingOut: false, isHidden: true });
        }, 1000);
      });
    }, 3500);
  }

  componentWillUnmount() {
    if (this.fadeInTimer) {
      window.clearTimeout(this.fadeInTimer);
    }

    if (this.fadeOutTimer) {
      window.clearTimeout(this.fadeOutTimer);
    }

    if (this.hiddenTimer) {
      window.clearTimeout(this.hiddenTimer);
    }
  }

  render() {
    const { isFadingOut, isHidden } = this.state;
    return (
      !isHidden && (
        <div
          className={classnames(styles.tipsBox, {
            [styles.fadeOut]: isFadingOut,
          })}
        >
          <div>
            <span className={styles.tips}>TIPS</span>
            <span>
              <FormattedMessage id="chatroom.message_list.tips" />
            </span>
          </div>
        </div>
      )
    );
  }
}
