import React, { PureComponent } from "react";
import classNames from "classnames";
import { IonPage } from "@ionic/react";
import { FormattedMessage } from "@oursky/react-messageformat";

import styles from "./styles.module.scss";
import { ListView } from "./ListView";

import { MPContent } from "../MPContent";
import { AgentHeader } from "../AgentHeader";
import { ListRefreshControl } from "../ListRefreshControl";
import { InfiniteScrollView } from "../InfiniteListView";

import { Mode } from "../../screens/AgentChatScreen";
import { Chat } from "../../models";
import { Page } from "../../utils/pagination";

interface Props {
  listVersion: number;
  mode: Mode;
  handleModeChange: (btn: Mode) => void;
  fetchInProgressPage: (cursor?: string) => Promise<Page<Chat>>;
  fetchCompletedPage: (cursor?: string) => Promise<Page<Chat>>;
  openChatroom: (chat: Chat) => void;
  completedInfiniteScrollViewRef: React.RefObject<InfiniteScrollView<Chat>>;
  inProgressInfiniteScrollViewRef: React.RefObject<InfiniteScrollView<Chat>>;
}

class AgentChatView extends PureComponent<Props> {
  handleInProgressClicked = () => {
    this.props.handleModeChange(Mode.inProgress);
  };

  handleCompletedClicked = () => {
    this.props.handleModeChange(Mode.completed);
  };

  render() {
    const {
      mode,
      fetchCompletedPage,
      fetchInProgressPage,
      openChatroom,
      listVersion,
      completedInfiniteScrollViewRef,
      inProgressInfiniteScrollViewRef,
    } = this.props;
    return (
      <IonPage>
        <AgentHeader logoClass={styles.logoContainer}>
          <div className={styles.buttonContainer}>
            <button
              className={classNames(styles.button, {
                [styles.buttonActive]: mode === Mode.inProgress,
              })}
              onClick={this.handleInProgressClicked}
            >
              <FormattedMessage id="agent.chat.in_progress" />
            </button>
            <button
              className={classNames(styles.button, {
                [styles.buttonActive]: mode === Mode.completed,
              })}
              onClick={this.handleCompletedClicked}
            >
              <FormattedMessage id="agent.chat.completed" />
            </button>
          </div>
        </AgentHeader>
        <MPContent forceOverscroll={false}>
          <ListRefreshControl listVersion={listVersion} routePrefix="/chat">
            <ListView
              mode={mode}
              fetchInProgressPage={fetchInProgressPage}
              fetchCompletedPage={fetchCompletedPage}
              openChatroom={openChatroom}
              completedInfiniteScrollViewRef={completedInfiniteScrollViewRef}
              inProgressInfiniteScrollViewRef={inProgressInfiniteScrollViewRef}
            />
          </ListRefreshControl>
        </MPContent>
      </IonPage>
    );
  }
}

export default AgentChatView;
