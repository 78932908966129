import React from "react";
import { DateTime } from "luxon";

import { ChatApiClient } from "../apiClient";
import { ChatService } from "../services/chat";
import {
  PubSubService,
  EventHandlerUnsubscriber,
  Payload,
} from "../services/pubsub";
import {
  Chat,
  ChatWithConversation,
  CreateForeignExchangeOffering,
  CreateMortgageOffering,
  CreatePersonalLoanOffering,
  ExchangeLoanChat,
  MortgageChat,
  PersonalLoanChat,
  Message,
  ConversationHandle,
  UserType,
  ReferralAgreementStatus,
  Conversation,
} from "../models";
import { blockEventSchema, unblockEventSchema } from "../schemas/event";
import { AttachmentType } from "../types/misc";
import { Page } from "../utils/pagination";
import { Omit } from "../utils/typeutils";
import SimpleEventEmitter, {
  SimpleEventEmitterSubscriber,
  SimpleEventEmitterListener,
} from "../utils/simpleEventEmitter";
import { Cancelable, debounce } from "lodash";

export interface SimpleChatEventCreate {
  type: "create";
  message: Message;
}
export interface SimpleChatEventUpdate {
  type: "update";
  message: Message;
}

export interface SimpleConversationEventDelete {
  type: "delete";
  conversation: Conversation;
}

export type SimpleChatEvent = SimpleChatEventCreate | SimpleChatEventUpdate;
export type SimpleConversationEvent = SimpleConversationEventDelete;

export interface ChatContextValue {
  chatMessagesByConversation: {
    [key: string]: ChatMessages;
  };
  chatListVersion: number;
  currentChatId?: string;

  isShowingDirectApplyPopover?: boolean;
  hideDirectApplyPopover: () => void;

  borrowerListChatByRequestId: (
    requestId: string,
    cursor?: string
  ) => Promise<Page<Chat>>;
  agentListChat: (isCompleted: boolean, cursor?: string) => Promise<Page<Chat>>;
  agentCreateFXChat: (
    formValues: CreateForeignExchangeOffering
  ) => Promise<ExchangeLoanChat>;
  agentCreateMortgageChat: (
    formValues: CreateMortgageOffering
  ) => Promise<MortgageChat>;
  agentCreatePersonalLoanChat: (
    formValues: CreatePersonalLoanOffering
  ) => Promise<PersonalLoanChat>;
  borrowerCreateChatWithOffering: (offeringId: string) => Promise<Chat>;
  borrowerCreateChatWithOfferingAndRequest: (
    offeringId: string,
    requestId: string
  ) => Promise<Chat>;
  getChatDetail: (chatId: string) => Promise<Chat>;

  enterConversation: (chat: Chat) => Promise<ChatWithConversation>;
  leaveConversation: (conversationId: string) => void;
  sendTextMessage: (
    conversationId: string,
    body: string,
    userType: UserType
  ) => Promise<void>;
  sendFile: (
    conversationId: string,
    file: File,
    userType: UserType,
    attachmentType: AttachmentType
  ) => Promise<void>;
  changeChatStatus: (
    conversationId: string,
    isCompleted: boolean,
    isReferralRequested: boolean,
    changeStatusMessage: string | undefined,
    isRichText: boolean
  ) => Promise<void>;
  changeReferralStatus: (
    conversationId: string,
    isAccepted: boolean,
    userType: UserType,
    messageBody: string
  ) => Promise<void>;
  fetchOldMessages: (conversationId: string) => Promise<void>;
  getTotalUnreadCount: () => Promise<number>;

  updateChatListVersion: <T>(result: T) => Promise<T>;
  subscribeMessageEvent: () => void;

  subscribeEventEmitter: SimpleEventEmitterSubscriber<SimpleChatEvent>;
  subscribeConversationEventEmitter: SimpleEventEmitterSubscriber<
    SimpleConversationEvent
  >;
}

const ChatContext = React.createContext(null as any);
export { ChatContext };

export interface ChatContextProps {
  chatContext: ChatContextValue;
}

interface ChatMessages {
  chat: ChatWithConversation;
  conversation: ConversationHandle;
  messages: Message[];
  hasMore: boolean;
}

interface MessageEnqueueCall {
  conversationId: string;
  messages: Message[];
  isPrepend: boolean;
}

interface Props {
  apiClient: ChatApiClient;
  chatService: ChatService;
  pubsubService: PubSubService;
}

interface State {
  chatMessagesByConversation: {
    [key: string]: ChatMessages;
  };
  chatListVersion: number;
  currentChatId?: string;
  isShowingDirectApplyPopover?: boolean;
}

const MESSAGE_FETCH_SIZE = 30;

export class ChatContextProvider extends React.PureComponent<Props, State> {
  private pendingEnqueueMessagesCalls: MessageEnqueueCall[] = [];
  private isEnqueueMessages = false;
  private chatMessagesByConversation: {
    [key: string]: ChatMessages;
  } = {};
  private eventHandlerUnsubscribers: EventHandlerUnsubscriber[] = [];
  private simpleChatEventEmitter: SimpleEventEmitter<
    SimpleChatEvent
  > = new SimpleEventEmitter<SimpleChatEvent>();
  private simpleConversationEventEmitter: SimpleEventEmitter<
    SimpleConversationEvent
  > = new SimpleEventEmitter<SimpleConversationEvent>();

  state: State = {
    chatMessagesByConversation: {},
    chatListVersion: 0,
  };

  private debouncedUpdateChatListVersion: (<T extends {}>(
    result: T
  ) => Promise<T>) &
    Cancelable;

  constructor(props: Props) {
    super(props);
    this.debouncedUpdateChatListVersion = debounce(
      this.updateChatListVersion,
      1000
    );
  }

  componentDidMount() {
    this.eventHandlerUnsubscribers = [
      this.props.pubsubService.registerEventHandler(
        "block",
        this.handleBlockEvent
      ),
      this.props.pubsubService.registerEventHandler(
        "unblock",
        this.handleUnblockEvent
      ),
    ];
  }

  componentWillUnmount() {
    for (const unsubscriber of this.eventHandlerUnsubscribers) {
      unsubscriber();
    }
  }

  render() {
    const { apiClient, children } = this.props;
    return (
      <ChatContext.Provider
        value={{
          chatMessagesByConversation: this.state.chatMessagesByConversation,
          chatListVersion: this.state.chatListVersion,
          currentChatId: this.state.currentChatId,
          isShowingDirectApplyPopover: this.state.isShowingDirectApplyPopover,
          hideDirectApplyPopover: this.hideDirectApplyPopover,

          borrowerListChatByRequestId: apiClient.borrowerListChatByRequestId.bind(
            apiClient
          ),
          borrowerCreateChatWithOffering: this.borrowerCreateChatWithOffering,
          borrowerCreateChatWithOfferingAndRequest: this
            .borrowerCreateChatWithOfferingAndRequest,
          agentListChat: apiClient.agentListChat.bind(apiClient),
          agentCreateFXChat: this.agentCreateFXChat,
          agentCreateMortgageChat: this.agentCreateMortgageChat,
          agentCreatePersonalLoanChat: this.agentCreatePersonalLoanChat,
          getChatDetail: apiClient.getChatDetail.bind(apiClient),
          enterConversation: this.enterConversation,
          leaveConversation: this.leaveConversation,
          sendTextMessage: this.sendTextMessage,
          sendFile: this.sendFile,
          changeChatStatus: this.changeChatStatus,
          changeReferralStatus: this.changeReferralStatus,
          fetchOldMessages: this.fetchOldMessages,
          getTotalUnreadCount: this.getTotalUnreadCount,

          updateChatListVersion: this.updateChatListVersion,
          subscribeMessageEvent: this.subscribeMessageEvent,

          subscribeEventEmitter: this.subscribeEventEmitter,
          subscribeConversationEventEmitter: this
            .subscribeConversationEventEmitter,
        }}
      >
        {children}
      </ChatContext.Provider>
    );
  }

  hideDirectApplyPopover = () => {
    this.setState({
      isShowingDirectApplyPopover: false,
    });
  };

  updateChatListVersion = <T extends {}>(result: T): Promise<T> => {
    this.setState(
      (prevState: State): State => {
        return {
          ...prevState,
          chatListVersion: prevState.chatListVersion + 1,
        };
      }
    );
    return Promise.resolve(result);
  };

  borrowerCreateChatWithOffering = (offeringId: string) => {
    const { apiClient } = this.props;
    return apiClient
      .borrowerCreateChatWithOffering(offeringId)
      .then(this.updateChatListVersion);
  };

  borrowerCreateChatWithOfferingAndRequest = (
    offeringId: string,
    requestId: string
  ) => {
    const { apiClient } = this.props;
    return apiClient
      .borrowerCreateChatWithOfferingAndRequest(offeringId, requestId)
      .then(this.updateChatListVersion);
  };

  agentCreateFXChat = (formValues: CreateForeignExchangeOffering) => {
    const { apiClient } = this.props;
    return apiClient
      .agentCreateFXChat(formValues)
      .then(this.updateChatListVersion);
  };

  agentCreateMortgageChat = (formValues: CreateMortgageOffering) => {
    const { apiClient } = this.props;
    return apiClient
      .agentCreateMortgageChat(formValues)
      .then(this.updateChatListVersion);
  };

  agentCreatePersonalLoanChat = (formValues: CreatePersonalLoanOffering) => {
    const { apiClient } = this.props;
    return apiClient
      .agentCreatePersonalLoanChat(formValues)
      .then(this.updateChatListVersion);
  };

  getLastStatusChangeMessage = (messages: Message[]) => {
    const statusChangeMessages = messages.filter(
      x =>
        x.isCompleted !== undefined || x.referralAgreementStatus !== undefined
    );

    if (statusChangeMessages.length > 0) {
      return statusChangeMessages[statusChangeMessages.length - 1];
    }
    return null;
  };

  enqueueMessages = (
    conversationId: string,
    messages: Message[],
    isPrepend = false
  ) => {
    const chatMessages = this.chatMessagesByConversation[conversationId];

    const filteredMessages = messages.filter(message => {
      return chatMessages.messages.find(x => x.id === message.id) === undefined;
    });

    if (!chatMessages || filteredMessages.length === 0) {
      return;
    }
    chatMessages.messages = isPrepend
      ? filteredMessages.concat(chatMessages.messages)
      : chatMessages.messages.concat(filteredMessages);

    chatMessages.hasMore = isPrepend
      ? messages.length >= MESSAGE_FETCH_SIZE
      : chatMessages.hasMore;

    const lastStatusChangeMessage = this.getLastStatusChangeMessage(messages);
    const isChatUpdated = lastStatusChangeMessage !== null;

    if (lastStatusChangeMessage) {
      chatMessages.chat.completedAt = lastStatusChangeMessage.isCompleted
        ? lastStatusChangeMessage.timestamp
        : null;
      chatMessages.chat.referralAgreementStatus = lastStatusChangeMessage.referralAgreementStatus
        ? lastStatusChangeMessage.referralAgreementStatus
        : null;
    }

    this.setState(
      (prevState: State): State => {
        const oldChatMessages =
          prevState.chatMessagesByConversation[conversationId];

        return {
          ...prevState,
          chatMessagesByConversation: {
            ...prevState.chatMessagesByConversation,
            [conversationId]: {
              ...oldChatMessages,
              messages: [...chatMessages.messages],
              hasMore: chatMessages.hasMore,
              chat: isChatUpdated
                ? {
                    ...chatMessages.chat,
                  }
                : chatMessages.chat,
            },
          },
          chatListVersion: isChatUpdated
            ? prevState.chatListVersion + 1
            : prevState.chatListVersion,
        };
      }
    );
  };

  enterConversation = async (chat: Chat) => {
    const chatWithConversation = await this.setupChat(chat);

    const conversation = await this.props.chatService.getConversation(
      chatWithConversation.conversationId
    );

    const preloadedMessages = chatWithConversation.isAgentBlocked
      ? []
      : await this.props.chatService.getMessages(
          conversation,
          DateTime.local(),
          MESSAGE_FETCH_SIZE
        );

    this.chatMessagesByConversation[chatWithConversation.conversationId] = {
      messages: preloadedMessages,
      conversation: conversation,
      chat: chatWithConversation,
      hasMore: chatWithConversation.isAgentBlocked
        ? false
        : preloadedMessages.length === MESSAGE_FETCH_SIZE,
    };

    const { directApplyLink } = chat.offering;
    const isShowingDirectApplyPopover =
      directApplyLink !== null && directApplyLink.trim() !== ""
        ? preloadedMessages.every(it => it.isAutoMessage)
        : undefined;

    return new Promise(resolve => {
      this.setState(
        (prevState: State): State => {
          return {
            ...prevState,
            chatMessagesByConversation: {
              ...prevState.chatMessagesByConversation,
              [chatWithConversation.conversationId]: {
                ...this.chatMessagesByConversation[
                  chatWithConversation.conversationId
                ],
              },
            },
            currentChatId: chatWithConversation.id,
            isShowingDirectApplyPopover,
          };
        },
        () => {
          this.props.chatService.onMessageCreated(
            chatWithConversation.conversationId,
            message => {
              this.enqueueMessages(chatWithConversation.conversationId, [
                message,
              ]);
            }
          );

          this.props.chatService.onConversationDeleted(
            chatWithConversation.conversationId,
            conversation => {
              this.simpleConversationEventEmitter.publish({
                type: "delete",
                conversation,
              });
            }
          );

          resolve(chatWithConversation);
        }
      );
    });
  };

  leaveConversation = (conversationId: string) => {
    this.props.chatService.unsubscribeMessageCreated(conversationId);
    delete this.chatMessagesByConversation[conversationId];

    this.setState(
      (prevState: State): State => {
        const draft = { ...prevState.chatMessagesByConversation };
        if (conversationId in draft) {
          delete draft[conversationId];
          return {
            ...prevState,
            chatMessagesByConversation: draft,
            currentChatId: undefined,
          };
        }
        return prevState;
      }
    );
  };

  sendTextMessage = (
    conversationId: string,
    body: string,
    userType: UserType
  ) => {
    const chatMessages = this.state.chatMessagesByConversation[conversationId];

    if (!chatMessages) {
      return Promise.resolve(null);
    }

    return this.props.chatService
      .createMessage(chatMessages.conversation, body, {
        userType,
      })
      .then(message => {
        this.enqueueMessages(conversationId, [message]);
      });
  };

  sendFile = (
    conversationId: string,
    file: File,
    userType: UserType,
    attachmentType: AttachmentType
  ) => {
    const chatMessages = this.state.chatMessagesByConversation[conversationId];

    if (!chatMessages) {
      return Promise.resolve(null);
    }

    return this.props.chatService
      .createMessage(
        chatMessages.conversation,
        "",
        {
          userType,
          attachmentType,
        },
        file as any
      )
      .then(message => {
        this.enqueueMessages(conversationId, [message]);
      });
  };

  changeChatStatus = (
    conversationId: string,
    isCompleted: boolean,
    isReferralRequested: boolean,
    changeStatusMessage: string | undefined,
    isRichText: boolean
  ) => {
    const chatMessages = this.state.chatMessagesByConversation[conversationId];

    if (!chatMessages) {
      return Promise.resolve(null);
    }

    const messageBody = changeStatusMessage || "";
    const messageMetadata = isReferralRequested
      ? {
          userType: UserType.agent,
          referralAgreementStatus: ReferralAgreementStatus.requested,
          isRichText,
        }
      : { isCompleted: isCompleted, isRichText };

    return Promise.all([
      this.props.chatService
        .createMessage(chatMessages.conversation, messageBody, messageMetadata)
        .then(message => {
          this.enqueueMessages(conversationId, [message]);
        }),
      this.props.apiClient.setChatStatus(
        chatMessages.chat.id,
        isCompleted,
        isReferralRequested
      ),
    ]).then(() => {
      this.setState(
        (prevState: State): State => {
          return {
            ...prevState,
            chatListVersion: prevState.chatListVersion + 1,
          };
        }
      );
    });
  };

  changeReferralStatus = (
    conversationId: string,
    isAccepted: boolean,
    userType: UserType,
    messageBody: string
  ) => {
    const chatMessages = this.state.chatMessagesByConversation[conversationId];

    if (!chatMessages) {
      return Promise.resolve(null);
    }

    return Promise.all([
      this.props.chatService
        .createMessage(chatMessages.conversation, messageBody, {
          userType,
          referralAgreementStatus: isAccepted
            ? ReferralAgreementStatus.accepted
            : ReferralAgreementStatus.rejected,
        })
        .then(message => {
          this.enqueueMessages(conversationId, [message]);
        }),
      this.props.apiClient.setReferralAgreement(
        chatMessages.chat.id,
        isAccepted
      ),
    ]).then(() => {
      this.setState(
        (prevState: State): State => {
          return {
            ...prevState,
            chatListVersion: prevState.chatListVersion + 1,
          };
        }
      );
    });
  };

  fetchOldMessages = (conversationId: string) => {
    const { chatService } = this.props;

    const chatMessages = this.state.chatMessagesByConversation[conversationId];
    if (!chatMessages) {
      return Promise.resolve(null);
    }

    return chatService
      .getMessages(
        chatMessages.conversation,
        chatMessages.messages.length > 0
          ? chatMessages.messages[0].timestamp
          : undefined,
        MESSAGE_FETCH_SIZE
      )
      .then(messages => {
        this.enqueueMessages(conversationId, messages, true);
      });
  };

  createConversation = async (chat: Chat): Promise<string> => {
    const { apiClient } = this.props;
    const userIds = [chat.request.borrower.userId, chat.offering.agent.userId];
    const conversationId = (await apiClient.createConversation(
      userIds,
      `${chat.type} - ${chat.refNum}`,
      { ref_num: chat.refNum },
      { adminIDs: userIds }
    )).id.split("/")[1];

    return conversationId;
  };

  setupChat = async (chat: Chat): Promise<ChatWithConversation> => {
    const { apiClient } = this.props;
    const latestChatInfo = await apiClient.getChat(chat.id);
    const conversationId =
      latestChatInfo.conversationId || (await this.createConversation(chat));

    return {
      ...chat,
      conversationId: conversationId,
      isAgentBlocked: latestChatInfo.isAgentBlocked,
      completedAt: latestChatInfo.completedAt,
    };
  };

  subscribeMessageEvent = () => {
    this.props.chatService.subscribeMessageEvent(this.handleMessageEvent);
  };

  handleMessageEvent = async (_message: Message, event: string) => {
    if (event === "create" || event === "update") {
      await this.debouncedUpdateChatListVersion({});
    }
    if (event === "create") {
      this.simpleChatEventEmitter.publish({
        type: "create",
        message: _message,
      });
    }
    if (event === "update") {
      this.simpleChatEventEmitter.publish({
        type: "update",
        message: _message,
      });
    }
  };

  getTotalUnreadCount = () => {
    return this.props.chatService.getTotalUnreadCount();
  };

  handleBlockEvent = (payload: Payload) => {
    const event = blockEventSchema.validateSync(payload);
    const chatMessagesToUpdate: ChatMessages[] = [];

    for (const conversationId of Object.keys(
      this.state.chatMessagesByConversation
    )) {
      const chatMessages = this.chatMessagesByConversation[conversationId];
      const { chat } = chatMessages;
      if (
        chat.offering.agent.userId === event.targetUserId &&
        chat.request.borrower.userId === event.userId &&
        !chat.isAgentBlocked
      ) {
        chat.isAgentBlocked = true;
        chatMessages.messages = [];
        chatMessages.hasMore = false;
        chatMessagesToUpdate.push(chatMessages);
      }
    }

    this.updateChatMessagesInState(chatMessagesToUpdate);
  };

  handleUnblockEvent = (payload: Payload) => {
    const event = unblockEventSchema.validateSync(payload);
    const chatMessagesToUpdate: ChatMessages[] = [];

    for (const conversationId of Object.keys(
      this.state.chatMessagesByConversation
    )) {
      const chatMessages = this.chatMessagesByConversation[conversationId];
      const { chat } = chatMessages;
      if (
        chat.offering.agent.userId === event.targetUserId &&
        chat.request.borrower.userId === event.userId &&
        chat.isAgentBlocked
      ) {
        chatMessagesToUpdate.push(chatMessages);
      }
    }

    Promise.all(
      chatMessagesToUpdate.map(x =>
        this.props.chatService.getMessages(
          x.conversation,
          DateTime.local(),
          MESSAGE_FETCH_SIZE
        )
      )
    ).then((messagesList: Message[][]) => {
      for (let i = 0; i < messagesList.length; i++) {
        const chatMessages = chatMessagesToUpdate[i];
        chatMessages.chat.isAgentBlocked = false;
        chatMessages.messages = messagesList[i];
        chatMessages.hasMore = messagesList[i].length === MESSAGE_FETCH_SIZE;
      }

      this.updateChatMessagesInState(chatMessagesToUpdate);
    });
  };

  private updateChatMessagesInState(chatMessagesToUpdate: ChatMessages[]) {
    this.setState((prev: State) => {
      const updatedChatMessagesByConversation = {
        ...prev.chatMessagesByConversation,
      };

      for (const chatMessages of chatMessagesToUpdate) {
        const conversationId = chatMessages.chat.conversationId;
        const prevChatMessages =
          updatedChatMessagesByConversation[conversationId];
        updatedChatMessagesByConversation[conversationId] = {
          ...prevChatMessages,
          chat: {
            ...chatMessages.chat,
          },
          messages: [...chatMessages.messages],
          hasMore: chatMessages.hasMore,
        };
      }

      return {
        ...prev,
        chatMessagesByConversation: updatedChatMessagesByConversation,
      };
    });
  }

  subscribeEventEmitter = (
    handler: SimpleEventEmitterListener<SimpleChatEvent>
  ) => {
    return this.simpleChatEventEmitter.subscribe(handler);
  };

  subscribeConversationEventEmitter = (
    handler: SimpleEventEmitterListener<SimpleConversationEvent>
  ) => {
    return this.simpleConversationEventEmitter.subscribe(handler);
  };
}

export function withChat<P extends ChatContextProps>(
  Component: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof ChatContextProps>> {
  const Wrapped: React.FC<Omit<P, keyof ChatContextProps>> = (
    props: Omit<P, keyof ChatContextProps>
  ) => (
    <ChatContext.Consumer>
      {chatContext => <Component {...props as any} chatContext={chatContext} />}
    </ChatContext.Consumer>
  );

  return Wrapped;
}
