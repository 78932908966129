import React from "react";
import {
  IonActionSheet,
  IonAlert,
  IonHeader,
  IonToolbar,
  IonFooter,
} from "@ionic/react";
import classnames from "classnames";
import {
  FormattedMessage,
  Context as LocaleContext,
  ContextValue as LocaleContextValue,
} from "@oursky/react-messageformat";
import { Plugins } from "@capacitor/core";

import {
  ChatWithConversation,
  UserType,
  Message,
  ReferralAgreementStatus,
} from "../../models";
import { TemplateContextValue } from "../../context/TemplateContext";
import { ChatContextValue } from "../../context/ChatContext";

import { HideTabBar } from "../../navigation/OurTabs";
import { MPContent } from "../MPContent";
import { BackButton } from "../BackButton";

import { OfferingInfoView } from "./OfferingInfoView";
import { InputBox } from "./InputBox";
import { MessageList } from "./MessageList";
import { StatusSelector } from "./StatusSelector";
import StatusRow from "./StatusRow";
import { TIPSBox } from "./TIPSBox";
import { ImageModal } from "./ImageModal";
import { ConfirmReferralPopover, DirectApplyPopover } from "./ChatroomPopover";

import { AttachmentType } from "../../types/misc";

import { isPlatform } from "../../utils/platform";

import styles from "./styles.module.scss";

interface Props {
  chat: ChatWithConversation;
  userType: UserType;
  messages: Message[];
  hasMoreMessage: boolean;
  onSendFile: (file: File, type: AttachmentType) => void;
  onSendMessage: (message: string) => Promise<void>;
  onStatusChange: (isCompleted: boolean, isReferralRequested: boolean) => void;
  setContentRef: (element: HTMLIonContentElement) => void;
  fetchOldMessages: () => Promise<void>;
  downloadImage: (url: string) => void;
  openDocument: (url: string) => void;
  onReportUser: (userId: string, chatId: string) => Promise<boolean>;
  onBlockUser: (userId: string) => Promise<void>;
  onUnblockUser: (userId: string) => Promise<void>;
  onClickExtraInfo?: () => void;
  onReferralStatusChange: (isAccepted: boolean, message: string) => void;
  openDirectApplyLink: (url: string) => void;
  hasPermission: (feature: "camera" | "photoAlbum") => Promise<boolean>;
  openSettingPage: () => Promise<void>;
  chatContext: ChatContextValue;
  templateContext: TemplateContextValue;
}

interface State {
  imageUrl?: string;
  isOptionActionSheetOpened: boolean;
  isShowingBlockConfirm: boolean;
  isShowingReportConfirm: boolean;
  isShowingReportDone: boolean;
  textMessageActionSheetContext: {
    message: string;
  } | null;
}

export class ChatroomView extends React.PureComponent<Props, State> {
  state: State = {
    isOptionActionSheetOpened: false,
    isShowingBlockConfirm: false,
    isShowingReportConfirm: false,
    isShowingReportDone: false,
    textMessageActionSheetContext: null,
  };

  onOpenImage = (url: string) => {
    this.setState({ imageUrl: url });
  };

  onCloseImage = () => {
    this.setState({ imageUrl: undefined });
  };

  onDownloadImage = () => {
    if (this.state.imageUrl) {
      this.props.downloadImage(this.state.imageUrl);
    }
  };

  openOptionsActionSheet = (e: React.MouseEvent<unknown>) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState(prev => ({
      ...prev,
      isOptionActionSheetOpened: true,
    }));
  };

  closeOptionsActionSheet = () => {
    this.setState(prev => ({
      ...prev,
      isOptionActionSheetOpened: false,
    }));
  };

  buildActionSheetItem = (
    message: string,
    handler?: () => void,
    role?: string
  ) => {
    return {
      text: message,
      ...(handler ? { handler } : {}),
      ...(role ? { role } : {}),
    };
  };

  onBlockUser = () => {
    const { chat, onBlockUser } = this.props;
    this.hideBlockConfirm();
    onBlockUser(chat.offering.agent.userId);
  };

  onReportUser = async () => {
    const { chat, onReportUser } = this.props;
    this.hideReportConfirm();
    const result = await onReportUser(chat.offering.agent.userId, chat.id);
    if (result) {
      this.showReportDone();
    }
  };

  showReportConfirm = () => {
    this.setState(prev => ({
      ...prev,
      isShowingReportConfirm: true,
    }));
  };

  hideReportConfirm = () => {
    this.setState(prev => ({
      ...prev,
      isShowingReportConfirm: false,
    }));
  };

  showReportDone = () => {
    this.setState(prev => ({
      ...prev,
      isShowingReportDone: true,
    }));
  };

  hideReportDone = () => {
    this.setState(prev => ({
      ...prev,
      isShowingReportDone: false,
    }));
  };

  showBlockConfirm = () => {
    this.setState(prev => ({
      ...prev,
      isShowingBlockConfirm: true,
    }));
  };

  hideBlockConfirm = () => {
    this.setState(prev => ({
      ...prev,
      isShowingBlockConfirm: false,
    }));
  };

  openDirectApplyLink = () => {
    const { chat, openDirectApplyLink } = this.props;
    const { directApplyLink } = chat.offering;
    if (directApplyLink) {
      openDirectApplyLink(directApplyLink);
    }
  };

  buildOptionsActionSheet = (
    renderToString: LocaleContextValue["renderToString"]
  ) => {
    const buttons = [];
    const { chat, onUnblockUser } = this.props;

    if (chat.isAgentBlocked) {
      buttons.push(
        this.buildActionSheetItem(
          renderToString("chatroom.options.unblock"),
          () => onUnblockUser(chat.offering.agent.userId)
        )
      );
    } else {
      buttons.push(
        this.buildActionSheetItem(
          renderToString("chatroom.options.report"),
          this.showReportConfirm
        )
      );
      buttons.push(
        this.buildActionSheetItem(
          renderToString("chatroom.options.block"),
          this.showBlockConfirm
        )
      );
    }

    buttons.push(
      this.buildActionSheetItem(
        renderToString("common.cancel"),
        undefined,
        "cancel"
      )
    );

    return buttons;
  };

  renderBlockedMessage() {
    const message =
      this.props.userType === UserType.agent
        ? "chatroom.you_are_blocked"
        : "chatroom.user_is_blocked";
    return this.props.chat.isAgentBlocked ? (
      <div className={styles.blockedMessage}>
        <div>
          <FormattedMessage id={message} />
        </div>
      </div>
    ) : null;
  }

  getConfirmDialogTitle() {
    const { chat, templateContext } = this.props;
    const localizedTemplates = templateContext.getLocalizedTemplates(
      chat.offering.agent.company.templates
    );
    return templateContext.getChatroomPopupMessage(
      localizedTemplates,
      chat.offering
    );
  }

  getDirectApplyPopoverTitle() {
    const { chat, templateContext } = this.props;
    const localizedTemplates = templateContext.getLocalizedTemplates(
      chat.offering.agent.company.templates
    );
    return templateContext.getDirectApplyPopupMessage(
      localizedTemplates,
      chat.offering
    );
  }

  getChatroomHeader() {
    const { chat, templateContext } = this.props;
    const localizedTemplates = templateContext.getLocalizedTemplates(
      chat.offering.agent.company.templates
    );
    return templateContext.getChatroomHeaderMessage(
      localizedTemplates,
      chat.offering
    );
  }

  handleTextMessageContextMenu = (message: string) => {
    if (
      document.activeElement &&
      document.activeElement instanceof HTMLElement
    ) {
      document.activeElement.blur();
    }
    this.setState({
      textMessageActionSheetContext: {
        message,
      },
    });
  };

  handleDismissTextMessageContextMenu = () => {
    this.setState({
      textMessageActionSheetContext: null,
    });
  };

  buildTextMessageActionSheet = (
    renderToString: LocaleContextValue["renderToString"]
  ) => {
    const buttons = [
      this.buildActionSheetItem(
        renderToString("chatroom.message_list.context_menu.copy"),
        () => {
          const { textMessageActionSheetContext } = this.state;
          if (textMessageActionSheetContext) {
            Plugins.Clipboard.write({
              string: textMessageActionSheetContext.message,
            });
          }
        }
      ),
      this.buildActionSheetItem(
        renderToString("common.cancel"),
        undefined,
        "cancel"
      ),
    ];
    return buttons;
  };

  render() {
    const {
      chat,
      messages,
      hasMoreMessage,
      fetchOldMessages,
      onSendFile,
      onSendMessage,
      onStatusChange,
      setContentRef,
      userType,
      openDocument,
      onClickExtraInfo,
      onReferralStatusChange,
      chatContext,
    } = this.props;

    const {
      imageUrl,
      isOptionActionSheetOpened,
      isShowingReportConfirm,
      isShowingReportDone,
      isShowingBlockConfirm,
      textMessageActionSheetContext,
    } = this.state;

    const { isShowingDirectApplyPopover, hideDirectApplyPopover } = chatContext;

    const shouldShowConfirmReferralPopover =
      userType === UserType.borrower &&
      chat.referralAgreementStatus === ReferralAgreementStatus.requested &&
      !chat.completedAt;

    return (
      <HideTabBar
        className={classnames({
          [styles.borrowerOuterContainer]: userType === UserType.borrower,
          [styles.agentOuterContainer]: userType === UserType.agent,
        })}
      >
        <IonHeader mode="ios" className={styles.header}>
          <IonToolbar mode="ios">
            <div className={styles.back}>
              <BackButton />
            </div>
            <div className={styles.title}>
              {chat.offering.agent.company.name}
            </div>
            <div className={styles.refNum}>{`#${chat.refNum}`}</div>
            {userType === UserType.borrower && (
              <button
                className={styles.optionButton}
                onClick={this.openOptionsActionSheet}
              />
            )}
          </IonToolbar>
          <OfferingInfoView
            chat={chat}
            userType={userType}
            onClickExtraInfo={onClickExtraInfo}
          />
        </IonHeader>
        <MPContent
          setRef={setContentRef}
          className={styles.list}
          noKeyboardScrollHandler={true}
        >
          {this.renderBlockedMessage()}
          <MessageList
            messages={messages}
            userType={userType}
            hasMoreMessage={hasMoreMessage}
            fetchOldMessages={fetchOldMessages}
            onOpenImage={this.onOpenImage}
            onOpenDocument={openDocument}
            chatroomHeaderMessage={this.getChatroomHeader()}
            onTextMessageContextMenu={this.handleTextMessageContextMenu}
          />
        </MPContent>
        <IonFooter>
          {userType === UserType.agent ? (
            <StatusSelector
              disabled={chat.isAgentBlocked}
              isCompleted={!!chat.completedAt}
              referralAgreement={chat.referralAgreementStatus}
              onStatusChange={onStatusChange}
            />
          ) : (
            <>
              <TIPSBox />
              <StatusRow
                isCompleted={!!chat.completedAt}
                referralAgreement={chat.referralAgreementStatus}
              />
            </>
          )}
          <InputBox
            onSendFile={onSendFile}
            onSendMessage={onSendMessage}
            disabled={!!chat.completedAt || chat.isAgentBlocked}
            isCompleted={!!chat.completedAt}
            hasPermission={this.props.hasPermission}
            openSettingPage={this.props.openSettingPage}
          />
        </IonFooter>
        <ImageModal
          url={imageUrl}
          onClose={this.onCloseImage}
          onDownload={this.onDownloadImage}
        />
        <LocaleContext.Consumer>
          {({ renderToString }) => (
            <>
              <IonActionSheet
                isOpen={isOptionActionSheetOpened}
                onDidDismiss={this.closeOptionsActionSheet}
                buttons={this.buildOptionsActionSheet(renderToString)}
                {...(isPlatform("ios")
                  ? {
                      className: styles.iosActionSheet,
                    }
                  : {})}
              />
              <IonActionSheet
                isOpen={textMessageActionSheetContext != null}
                onDidDismiss={this.handleDismissTextMessageContextMenu}
                buttons={this.buildTextMessageActionSheet(renderToString)}
                {...(isPlatform("ios")
                  ? {
                      className: styles.iosActionSheet,
                    }
                  : {})}
              />
              <IonAlert
                isOpen={isShowingBlockConfirm}
                onDidDismiss={this.hideBlockConfirm}
                message={renderToString("chatroom.block.confirm")}
                buttons={[
                  {
                    text: renderToString("common.cancel"),
                    handler: this.hideBlockConfirm,
                  },
                  {
                    text: renderToString("common.ok"),
                    handler: this.onBlockUser,
                  },
                ]}
              />
              <IonAlert
                isOpen={isShowingReportConfirm}
                onDidDismiss={this.hideReportConfirm}
                message={renderToString("chatroom.report.confirm")}
                buttons={[
                  {
                    text: renderToString("common.cancel"),
                    handler: this.hideReportConfirm,
                  },
                  {
                    text: renderToString("common.ok"),
                    handler: this.onReportUser,
                  },
                ]}
              />
              <IonAlert
                isOpen={isShowingReportDone}
                onDidDismiss={this.hideReportDone}
                message={renderToString("chatroom.report.done")}
                buttons={[
                  {
                    text: renderToString("common.ok"),
                    handler: this.hideReportDone,
                  },
                ]}
              />
              <ConfirmReferralPopover
                title={this.getConfirmDialogTitle()}
                isOpen={shouldShowConfirmReferralPopover}
                renderToString={renderToString}
                onReferralStatusChange={onReferralStatusChange}
              />
              {this.props.userType === UserType.borrower &&
                isShowingDirectApplyPopover !== undefined && (
                  <DirectApplyPopover
                    title={this.getDirectApplyPopoverTitle()}
                    isOpen={isShowingDirectApplyPopover}
                    onClose={hideDirectApplyPopover}
                    onPress={this.openDirectApplyLink}
                  />
                )}
            </>
          )}
        </LocaleContext.Consumer>
      </HideTabBar>
    );
  }
}
