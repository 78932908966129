import { BorrowerData } from "./borrower";
import { AgentData } from "./agent";

export type User = Agent | Borrower;

export type Agent = UserData & AgentData;

export type Borrower = UserData & BorrowerData;

export interface UserData {
  type: UserType;
  username: string; // mobile number
  email: string | null;
}

export enum UserType {
  borrower = "Borrower",
  agent = "Agent",
}

export function isAgent(user: User): user is Agent {
  return user.type === UserType.agent;
}
