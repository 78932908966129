import React from "react";

import { match, RouteComponentProps, withRouter } from "react-router";
import { History, Location } from "history";
import { OurNavContext } from "./OurNavContext";
import { Omit } from "./utils";

interface RouterData {
  history: History;
  location: Location;
  match: match<any>;
}

export interface PresentationContextValue {
  config: (
    history: History,
    location: Location,
    match: match<any>,
    closePath: string,
    goBack: () => void
  ) => void;
  present: (path: string, state?: any, completion?: () => void) => void;
  getPresentationPath: () => string | undefined;
  isPresenting: boolean;
}

export interface PresentationContextProps {
  presentationContext: PresentationContextValue;
  children?: React.ReactNode;
}
export const PresentationContext = React.createContext<
  PresentationContextValue
>(null as any);

interface State {
  isPresenting: boolean;
}

class PresentationContextProviderImpl extends React.PureComponent<
  RouteComponentProps,
  State
> {
  router?: RouterData;
  closePath = "/close";
  goBack?: () => void;
  completion?: () => void;

  savedPresent?: {
    path: string;
    state?: any;
    completion?: () => void;
  };

  state: State = {
    isPresenting: false,
  };

  componentDidMount() {
    window.addEventListener("popstate", this.onPopState);
  }

  componentWillUnmount() {
    window.removeEventListener("popstate", this.onPopState);
  }

  onPopState = () => {
    if (this.goBack) {
      this.goBack();
      if (this.state.isPresenting) {
        this.props.history.push(this.props.location.pathname);
      }
    }
  };

  onDismiss = () => {
    this.props.history.goBack();
    setTimeout(() => {
      if (this.completion) {
        this.completion();
        this.completion = undefined;
      }
    }, 200); // matching dismiss animation duration
  };

  config = (
    history: History,
    location: Location,
    match: match<any>,
    closePath: string,
    goBack: () => void
  ) => {
    const isPresenting = location.pathname !== this.closePath;
    this.setState({ isPresenting });

    if (
      !isPresenting &&
      this.router &&
      this.router.location.pathname !== this.closePath
    ) {
      this.onDismiss();
    }

    this.router = { history, location, match };
    this.goBack = goBack;
    this.closePath = closePath;

    if (this.savedPresent !== undefined) {
      this.present(
        this.savedPresent.path,
        this.savedPresent.state,
        this.savedPresent.completion
      );
      this.savedPresent = undefined;
    }
  };

  present = (path: string, state?: any, completion?: () => void) => {
    if (this.router) {
      this.router.history.push(path, state);
      this.props.history.push(this.props.location.pathname);
      this.completion = completion;
    } else {
      this.savedPresent = {
        path,
        state,
        completion,
      };
    }
  };

  getPresentationPath = () => {
    return this.router && this.router.location.pathname;
  };

  render() {
    const { children } = this.props;
    const { isPresenting } = this.state;
    return (
      <>
        <PresentationContext.Provider
          value={{
            config: this.config,
            isPresenting: isPresenting,
            present: this.present,
            getPresentationPath: this.getPresentationPath,
          }}
        >
          {children}
        </PresentationContext.Provider>
      </>
    );
  }
}

export const PresentationContextProvider = withRouter(
  PresentationContextProviderImpl
);

export function withPresentation<P extends PresentationContextProps>(
  Component: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof PresentationContextProps>> {
  const Wrapped: React.FC<Omit<P, keyof PresentationContextProps>> = (
    props: Omit<P, keyof PresentationContextProps>
  ) => (
    <PresentationContext.Consumer>
      {context => <Component {...props as any} presentationContext={context} />}
    </PresentationContext.Consumer>
  );

  return Wrapped;
}

interface PresentationBridgeProps extends RouteComponentProps {
  closePath?: string;
}

const PresentationBridgeImpl: React.FC<PresentationBridgeProps> = props => {
  const bridgeContext = React.useContext(PresentationContext);
  const navContext = React.useContext(OurNavContext);
  const { history, location, match } = props;

  React.useEffect(() => {
    bridgeContext.config(
      history,
      location,
      match,
      props.closePath || "/close",
      navContext.goBack
    );
  });
  return null;
};

export const PresentationBridge = withRouter(PresentationBridgeImpl);
