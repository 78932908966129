import React from "react";
import AgentInfoRow from "../AgentInfoRow";
import styles from "./styles.module.scss";

interface Props {
  name: string;
  company: string;
  logo?: string | null;
}

const ProfileItem: React.FC<Props> = props => {
  return (
    <div className={styles.profileContainer}>
      <AgentInfoRow
        name={props.name}
        company={props.company}
        logo={props.logo}
      />
    </div>
  );
};

export default ProfileItem;
