import { Context as LocaleContext } from "@oursky/react-messageformat";
import classNames from "classnames";
import { parsePhoneNumber } from "libphonenumber-js";
import React, { PureComponent } from "react";

import { isPhoneNumberFormatValid } from "../../utils/e164PhoneNumber";

import { MPInput } from "../input";

import styles from "./styles.module.scss";

export type InputFields = "countryCode" | "phoneNumber";

export interface PhoneNumberInputValue {
  countryCode: string;
  phoneNumber: string;
}

export function initialPhoneNumberInputValue(): PhoneNumberInputValue {
  return {
    countryCode: "852",
    phoneNumber: "",
  };
}

export function parseStringToPhoneNumberInputValue(
  phoneNumber?: string
): PhoneNumberInputValue | null {
  if (!phoneNumber) {
    return null;
  }

  const result = parsePhoneNumber(phoneNumber);
  return {
    countryCode: result.countryCallingCode.toString(),
    phoneNumber: result.nationalNumber.toString(),
  };
}

export interface PhoneNumberInputProps {
  className?: string;
  autoFocus?: boolean;
  isCountryCodeError?: boolean;
  isPhoneNumberError?: boolean;
  value: PhoneNumberInputValue;
  onChange?: (value: PhoneNumberInputValue) => void;
  focusField?: InputFields;
  autoSubmit?: () => void;
}

export class PhoneNumberInput extends PureComponent<PhoneNumberInputProps> {
  render() {
    const {
      className,
      autoFocus,
      isCountryCodeError,
      isPhoneNumberError,
      value: { countryCode, phoneNumber },
      focusField,
      autoSubmit,
    } = this.props;

    return (
      <div className={classNames(styles.phoneContainer, className)}>
        <div className={styles.countryCodeField}>
          <label>+</label>
          <MPInput
            className={styles.countryCodeInput}
            isError={isCountryCodeError}
            value={countryCode}
            onChange={this.handleCountryCodeChange}
            type="tel"
            inputMode="tel"
            placeholder="852"
            maxLength={3}
            shouldFocus={focusField === "countryCode"}
          />
        </div>
        <LocaleContext.Consumer>
          {({ renderToString }) => (
            <MPInput
              className={styles.phoneInputField}
              isError={isPhoneNumberError}
              autoFocus={autoFocus}
              value={phoneNumber}
              onChange={this.handlePhoneNumberChange}
              type="tel"
              inputMode="tel"
              autoComplete="tel"
              placeholder={renderToString("login.phone_placeholder")}
              shouldFocus={focusField === "phoneNumber"}
              autoSubmit={autoSubmit}
            />
          )}
        </LocaleContext.Consumer>
      </div>
    );
  }

  handleCountryCodeChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const countryCode = e.currentTarget.value || "";

    const { value, onChange } = this.props;
    if (value.countryCode === countryCode) {
      return;
    }

    if (!onChange) {
      return;
    }

    onChange({
      ...value,
      countryCode,
    });
  };

  handlePhoneNumberChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const phoneNumber = e.currentTarget.value || "";

    const { value, onChange } = this.props;
    if (value.phoneNumber === phoneNumber) {
      return;
    }

    if (!onChange) {
      return;
    }

    onChange({
      ...value,
      phoneNumber,
    });
  };
}

// return errorId if specified countryCode & phoneNumber is invalid, else
// undefined
export function validatePhoneNumber(
  value: PhoneNumberInputValue
): string | undefined {
  const { countryCode, phoneNumber } = value;

  if (countryCode.length === 0 || phoneNumber.length === 0) {
    return "login.phone.error.required";
  }

  const isFormatValid = isPhoneNumberFormatValid(countryCode, phoneNumber);
  if (!isFormatValid) {
    return "login.phone.error.incorrect_format";
  }

  return undefined;
}
