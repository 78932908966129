import React from "react";

import CalculatorView from "../components/CalculatorView";
import { CalculatorType, CalculatorParams } from "../types/calculator";
import { RateToNumber } from "../utils/formatRate";
import {
  currencyFormatToNumber,
  formatToCurrencyAmount,
} from "../utils/formatToCurrencyAmount";
import {
  getCalculatedLoan,
  getCalculatedInterestRate,
  getCalculatedPeriod,
  getCalculatedRepayment,
  getTotalInterest,
  getTotalRepayment,
  calculatorLimit,
} from "../utils/calculator";

class CalculatorScreen extends React.PureComponent {
  state = {
    loan: formatToCurrencyAmount("100000") || "",
    interestRate: "5.15",
    period: "24",
    repayment: formatToCurrencyAmount("4394") || "",
    totalInterest: "5456",
    totalRepayment: "105456",
    lockedCalculator: CalculatorType.InterestRate,
  };

  calculateLoan = (interestRate: number, period: number, repayment: number) => {
    const loan = getCalculatedLoan(interestRate, period, repayment);
    if (isNaN(loan)) {
      this.setState({
        loan: "",
        totalInterest: "",
        totalRepayment: "",
      });
    } else {
      const amount = formatToCurrencyAmount(loan.toString());
      this.setState({
        loan: amount,
        totalInterest: getTotalInterest(loan, period, repayment),
        totalRepayment: getTotalRepayment(period, repayment),
      });
    }
  };

  calculateInterestRate = (loan: number, period: number, repayment: number) => {
    const interestRate = getCalculatedInterestRate(loan, period, repayment);
    if (isNaN(interestRate)) {
      this.setState({
        interestRate: "",
        totalInterest: "",
        totalRepayment: "",
      });
    } else {
      this.setState({
        interestRate: interestRate.toString(10),
        totalInterest: getTotalInterest(loan, period, repayment),
        totalRepayment: getTotalRepayment(period, repayment),
      });
    }
  };

  calculatePeriod = (loan: number, interestRate: number, repayment: number) => {
    const calculatorData = getCalculatedPeriod(loan, interestRate, repayment);
    if (calculatorData === null) {
      this.setState({
        period: "",
        totalInterest: "",
        totalRepayment: "",
      });
    } else {
      const { period, totalInterest, totalRepayment } = calculatorData;
      this.setState({
        period: period,
        totalInterest: formatToCurrencyAmount(
          Math.round(totalInterest).toString()
        ),
        totalRepayment: formatToCurrencyAmount(
          Math.round(totalRepayment).toString()
        ),
      });
    }
  };

  calculateRepayment = (loan: number, interestRate: number, period: number) => {
    const repayment = getCalculatedRepayment(loan, interestRate, period);
    if (isNaN(repayment)) {
      this.setState({
        repayment: "",
        totalInterest: "",
        totalRepayment: "",
      });
    } else {
      const amount = formatToCurrencyAmount(repayment.toString());
      this.setState({
        repayment: amount,
        totalInterest: getTotalInterest(loan, period, repayment),
        totalRepayment: getTotalRepayment(period, repayment),
      });
    }
  };

  valueChangeHandler(
    calculatorParams: CalculatorParams,
    changedType: CalculatorType
  ) {
    let loan = currencyFormatToNumber(this.state.loan);
    let interestRate = RateToNumber(this.state.interestRate);
    let period = parseInt(this.state.period, 10);
    let repayment = currencyFormatToNumber(this.state.repayment);
    switch (changedType) {
      case CalculatorType.Loan:
        loan = NaN;
        if (calculatorParams.loan !== undefined && calculatorParams.loan >= 0) {
          loan = calculatorParams.loan;
        }
        break;
      case CalculatorType.InterestRate:
        interestRate = NaN;
        if (
          calculatorParams.interestRate !== undefined &&
          calculatorParams.interestRate >= 0
        ) {
          interestRate = calculatorParams.interestRate;
        }
        break;
      case CalculatorType.Period:
        period = NaN;
        if (
          calculatorParams.period !== undefined &&
          calculatorParams.period >= 0
        ) {
          period = calculatorParams.period;
        }
        break;
      case CalculatorType.Repayment:
        repayment = NaN;
        if (
          calculatorParams.repayment !== undefined &&
          calculatorParams.repayment >= 0
        ) {
          repayment = calculatorParams.repayment;
        }
        break;
      default:
        break;
    }

    switch (this.state.lockedCalculator) {
      case CalculatorType.Loan:
        this.calculateLoan(interestRate, period, repayment);
        break;
      case CalculatorType.InterestRate:
        this.calculateInterestRate(loan, period, repayment);
        break;
      case CalculatorType.Period:
        this.calculatePeriod(loan, interestRate, repayment);
        break;
      case CalculatorType.Repayment:
        this.calculateRepayment(loan, interestRate, period);
        break;
      default:
        break;
    }
  }

  onLoanChanged = (loan: string) => {
    const amount = currencyFormatToNumber(loan);

    if (amount <= calculatorLimit.maxAmount) {
      this.setState({ loan });
      this.valueChangeHandler({ loan: amount }, CalculatorType.Loan);
    }
  };

  onInterestRateChanged = (interestRate: string) => {
    const rate = RateToNumber(interestRate);
    if (rate <= calculatorLimit.maxInterestRate) {
      this.setState({ interestRate });
      this.valueChangeHandler(
        { interestRate: rate },
        CalculatorType.InterestRate
      );
    }
  };

  onPeriodChanged = (period: string) => {
    const monthPeriod = parseInt(period, 10);
    if (monthPeriod <= calculatorLimit.maxPeriod || isNaN(monthPeriod)) {
      this.setState({ period });
      this.valueChangeHandler({ period: monthPeriod }, CalculatorType.Period);
    }
  };

  onRepaymentChanged = (repayment: string) => {
    const amount = currencyFormatToNumber(repayment);
    if (amount <= calculatorLimit.maxAmount) {
      this.setState({ repayment });
      this.valueChangeHandler({ repayment: amount }, CalculatorType.Repayment);
    }
  };

  lockClicked = (calculatorType: CalculatorType) => {
    this.setState({ lockedCalculator: calculatorType });
  };

  render() {
    const {
      loan,
      interestRate,
      period,
      repayment,
      lockedCalculator,
      totalInterest,
      totalRepayment,
    } = this.state;
    return (
      <CalculatorView
        loan={loan}
        interestRate={interestRate}
        period={period}
        repayment={repayment}
        lockedCalculator={lockedCalculator}
        totalInterest={totalInterest}
        totalRepayment={totalRepayment}
        onLoanChanged={this.onLoanChanged}
        onInterestRateChanged={this.onInterestRateChanged}
        onPeriodChanged={this.onPeriodChanged}
        onRepaymentChanged={this.onRepaymentChanged}
        lockClicked={this.lockClicked}
      />
    );
  }
}

export default CalculatorScreen;
