import React from "react";

import {
  FormattedDropdown,
  FormattedDropdownProps,
  Option as FormattedOption,
} from "../FormattedDropdown";

import { Omit } from "../../utils/typeutils";
import { PaymentMethod } from "../../models";

export type PaymentMethodDropdownProps = Omit<
  FormattedDropdownProps,
  "options"
>;

export const PaymentMethodOptions: FormattedOption[] = [
  {
    key: PaymentMethod.automaticTransfer,
    value: PaymentMethod.automaticTransfer,
    labelId: "paymentMethod.automaticTransfer",
  },
  {
    key: PaymentMethod.cheque,
    value: PaymentMethod.cheque,
    labelId: "paymentMethod.cheque",
  },
  {
    key: PaymentMethod.cash,
    value: PaymentMethod.cash,
    labelId: "paymentMethod.cash",
  },
];

export const PaymentMethodDropdown: React.FC<
  PaymentMethodDropdownProps
> = props => {
  return <FormattedDropdown options={PaymentMethodOptions} {...props as any} />;
};
