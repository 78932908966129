import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import numeral from "numeral";
import React from "react";

import { CreatePersonalLoanForm } from "../../models";

import { renderExpiredDate } from "./index";
import styles from "./styles.module.scss";

interface PersonalLoanQuickOfferItemProps {
  offering: CreatePersonalLoanForm;
}

export const PersonalLoanQuickOfferItem: React.FC<
  PersonalLoanQuickOfferItemProps
> = props => {
  const { offering } = props;

  return (
    <div>
      <div className={styles.title}>
        <FormattedMessage id="quick_offer.template_card.personal_loan.title" />
      </div>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.label}>
            <FormattedMessage id="quick_offer.template_card.personal_loan.amount" />
          </div>
          <div className={styles.value}>
            {`${numeral(offering.amountMin).format()} - ${numeral(
              offering.amountMax
            ).format()}`}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.label}>
            <FormattedMessage id="quick_offer.template_card.personal_loan.period" />
          </div>
          <div className={styles.value}>
            <FormattedMessage
              id="quick_offer.template_card.personal_loan.period.months"
              values={{
                minMonths: offering.periodMin,
                maxMonths: offering.periodMax,
              }}
            />
          </div>
        </div>
      </div>

      <div className={classNames(styles.row, styles.col)}>
        <div className={styles.label}>
          <FormattedMessage id="quick_offer.template_card.personal_loan.expired_at" />
        </div>
        <div className={styles.value}>
          {renderExpiredDate(offering.validDays)}
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.label}>
            <FormattedMessage id="quick_offer.template_card.personal_loan.requirement" />
          </div>
          {offering.isRequiredEmployed && (
            <div className={styles.text}>
              <FormattedMessage id="quick_offer.template_card.personal_loan.requirement.required_employed" />
            </div>
          )}
          {offering.isRequiredProperty && (
            <div className={styles.text}>
              <FormattedMessage id="quick_offer.template_card.personal_loan.requirement.required_property" />
            </div>
          )}
          {!offering.isRequiredEmployed && !offering.isRequiredProperty && (
            <div className={styles.text}>NIL</div>
          )}
        </div>
        <div className={styles.col}>
          <div className={styles.label}>
            <FormattedMessage id="quick_offer.template_card.personal_loan.interest_rate" />
          </div>
          <div className={styles.value}>
            {`${offering.interestRateMin}% - ${offering.interestRateMax}%`}
          </div>
        </div>
      </div>

      <div className={classNames(styles.row, styles.col)}>
        <div className={styles.label}>
          <FormattedMessage id="quick_offer.template_card.personal_loan.addition" />
        </div>
        <div className={styles.text}>{offering.addition.trim() || "NIL"}</div>
      </div>
    </div>
  );
};
