import { formatToCurrencyAmount } from "./formatToCurrencyAmount";

const EPSILON = 1e-16;

const getAverageRate = (monthRate: number, period: number): number => {
  // Average Rate of monthly principal and interest = {[(1+Monthly Rate)^Months]×Monthly Rate} ÷ {[(1+Monthly Rate)^Months]-1}
  let alpha = Math.pow(1 + monthRate, period) - 1;

  if (alpha < EPSILON) {
    alpha = EPSILON;
  }

  return (monthRate * Math.pow(1 + monthRate, period)) / alpha;
};

const _computePeriod = (
  loan: number,
  monthlyRate: number,
  repayment: number
): number => {
  let alpha = repayment - loan * monthlyRate;
  if (alpha < EPSILON) {
    alpha = EPSILON;
  }

  return Math.log(repayment / alpha) / Math.log(monthlyRate + 1);
};

const getInterestRate = (
  loan: number,
  period: number,
  repayment: number
): number => {
  if (repayment >= loan) {
    return NaN;
  }

  // Use two point method to get interest rate
  const minMonthlyRateLimit = 1e-4 / 12.0;
  const epsilon = 0.000001;
  // When the rate higher than maxMonthlyRateLimit, it means interest rate is higher than 100%
  const maxMonthlyRateLimit = 0.09;

  let minMonthlyRate = minMonthlyRateLimit;
  let maxMonthlyRate = maxMonthlyRateLimit;
  let monthlyRate = Math.max(
    Math.min(repayment / loan, maxMonthlyRateLimit),
    minMonthlyRateLimit
  );

  let deviation = _computePeriod(loan, monthlyRate, repayment) - period;
  while (
    Math.abs(deviation) > 0.0001 &&
    maxMonthlyRate - minMonthlyRate >= epsilon
  ) {
    if (deviation > 0) {
      maxMonthlyRate = monthlyRate;
    } else {
      minMonthlyRate = monthlyRate;
    }
    monthlyRate = (minMonthlyRate + maxMonthlyRate) / 2;
    deviation = _computePeriod(loan, monthlyRate, repayment) - period;

    // Error calculation eg. the rate is too higher or too low.
    if (
      monthlyRate < minMonthlyRateLimit + epsilon ||
      monthlyRate > maxMonthlyRateLimit - 0.01
    ) {
      return NaN;
    }
  }

  if (isNaN(deviation)) {
    return NaN;
  }
  return Math.round(monthlyRate * 12 * 10000) / 100;
};

export const getCalculatedLoan = (
  interestRate: number,
  period: number,
  repayment: number
): number => {
  // Principal = Repayment / Average Rate of monthly principal and interest
  if (interestRate > 0 && period > 0 && repayment > 0) {
    const monthRate = interestRate / 12;
    const loan = repayment / getAverageRate(monthRate, period);
    if (loan > 0) {
      return loan;
    }
  }
  return NaN;
};

export const getCalculatedInterestRate = (
  loan: number,
  period: number,
  repayment: number
): number => {
  if (loan > 0 && period > 0 && repayment > 0) {
    const interestRate = getInterestRate(loan, period, repayment);
    if (interestRate > 0 && Math.floor(repayment * period) - loan > 0) {
      return interestRate;
    }
  }
  return NaN;
};

interface CalculatePeriodData {
  period: number;
  totalInterest: number;
  totalRepayment: number;
}

export const getCalculatedPeriod = (
  loan: number,
  interestRate: number,
  repayment: number
): CalculatePeriodData | null => {
  if (
    [
      loan <= 0,
      interestRate < 0,
      repayment <= 0,
      isNaN(loan),
      isNaN(interestRate),
      isNaN(repayment),
    ].some(x => x)
  ) {
    return null;
  }

  // NOTE: Ensure monthly repayment can cover period interest
  const firstPeriodInterest = (loan * interestRate) / 12;
  if (firstPeriodInterest >= repayment) {
    return null;
  }

  let remainingAmount = loan;
  let totalInterest = 0;
  let totalRepayment = 0;
  let period = 0;

  while (remainingAmount > 0) {
    const periodInterest = (remainingAmount * interestRate) / 12;

    remainingAmount += periodInterest;
    if (repayment < remainingAmount) {
      remainingAmount -= repayment;
      totalRepayment += repayment;
    } else {
      totalRepayment += remainingAmount;
      remainingAmount = 0;
    }
    period++;

    totalInterest += periodInterest;
  }

  return {
    period: period,
    totalInterest: totalInterest,
    totalRepayment: totalRepayment,
  };
};

export const getCalculatedRepayment = (
  loan: number,
  interestRate: number,
  period: number
): number => {
  // Repayment = Principal × Average Rate of monthly principal and interest
  if (loan > 0 && interestRate > 0 && period > 0) {
    const monthRate = interestRate / 12;
    const repayment = loan * getAverageRate(monthRate, period);
    if (repayment > 0) {
      return repayment;
    }
  }
  return NaN;
};

export const getTotalInterest = (
  loan: number,
  period: number,
  repayment: number
): string | null => {
  const totalInterest = Math.round(repayment * period) - loan;
  return formatToCurrencyAmount(totalInterest.toString());
};

export const getTotalRepayment = (
  period: number,
  repayment: number
): string | null => {
  const totalRepayment = Math.round(repayment * period);
  return formatToCurrencyAmount(totalRepayment.toString());
};

export const getTotalCommission = (
  totalRepayment: number,
  commissionRate: number
) => {
  const totalCommission = Math.round(totalRepayment * commissionRate);
  return formatToCurrencyAmount(totalCommission.toString());
};

export const calculatorLimit = {
  maxAmount: 999999999,
  maxPeriod: 1200,
  maxInterestRate: 0.9999,
  maxCommissionRate: 0.9999,
};
