import React from "react";

import {
  FormattedDropdown,
  FormattedDropdownProps,
  Option as FormattedOption,
} from "../FormattedDropdown";

import { Omit } from "../../utils/typeutils";

export interface MonthPeriodDropdownProps
  extends Omit<FormattedDropdownProps, "options"> {
  abbreviated?: boolean;
}

function buildMonthPeriodOptions(isAbbreviated: boolean) {
  return [6, 12, 16, 18, 24, 36, 48, 60, 72, 84, 96, 108, 120]
    .map(period => ({
      key: period.toString(),
      value: period.toString(),
      labelId: isAbbreviated
        ? "option.period.unit.months.abbr"
        : "option.period.unit.months",
      labelValues: { months: period },
    }))
    .concat([
      {
        key: "121",
        value: "121",
        labelId: isAbbreviated
          ? "option.period.unit.120_months_plus.abbr"
          : "option.period.unit.120_months_plus",
        labelValues: { months: 121 },
      },
    ]);
}

export const MonthPeriodOptions: FormattedOption[] = buildMonthPeriodOptions(
  false
);
export const AbbreviatedMonthPeriodOptions: FormattedOption[] = buildMonthPeriodOptions(
  true
);

export const MonthPeriodDropdown: React.FC<
  MonthPeriodDropdownProps
> = props => {
  const { abbreviated, ...rest } = props;
  return abbreviated ? (
    <FormattedDropdown
      options={AbbreviatedMonthPeriodOptions}
      placeholderId="common.dropdown.placeholder"
      selectClass="arrowTouchEdge"
      {...rest as any}
    />
  ) : (
    <FormattedDropdown
      options={MonthPeriodOptions}
      placeholderId="common.dropdown.placeholder"
      {...rest as any}
    />
  );
};
