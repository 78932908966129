import React from "react";
import { RouteComponentProps } from "react-router";

import { withOptions, OptionsProps } from "../context/OptionsContext";
import { Option } from "../components/DropdownPicker";
import CreateRequestView from "../components/CreateRequestView";
import {
  CreateForeignExchangeRequest,
  CreateMortgageRequest,
  CreatePersonalLoanRequest,
  CreateRequest,
  QuickRequestData,
  RequestType,
} from "../types/request";

import {
  withErrorAlert,
  ErrorAlertContextProps,
} from "../context/ErrorAlertContext";
import { withLoading, LoadingContextProps } from "../context/LoadingContext";
import { withUser, UserContextProps } from "../context/UserContext";
import {
  withCreateRequest,
  CreateRequestContextProps,
} from "../context/CreateRequestContext";
import { ensureBorrower } from "../utils/user";
import {
  RequestFormValues,
  RequestFormValuesToPresist,
  RequestMorgageFormValuesToPresist,
  RequestPersonalLoanFormValuesToPresist,
} from "../components/CreateRequestView/types";
import { setLatestRequestFormValues } from "../utils/storage";

export interface GetOfferingLocationState {
  requestData?: QuickRequestData;
  latestRequestFormValues?: RequestFormValues;
  directApplyLink?: string;
}

type Props = LoadingContextProps &
  OptionsProps &
  ErrorAlertContextProps &
  UserContextProps &
  CreateRequestContextProps &
  RouteComponentProps<{}, {}, GetOfferingLocationState | undefined>;

interface State {
  selectedRequestType?: RequestType;
}

class CreateRequestScreen extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const requestData =
      props.location.state && props.location.state.requestData;
    this.state = {
      selectedRequestType: requestData ? requestData.type : undefined,
    };
  }

  componentDidUpdate(prevProps: Props) {
    this.updateRequestTypeIfRequestChanged(prevProps);
  }

  updateRequestTypeIfRequestChanged = (prevProps: Props) => {
    const { location } = this.props;
    const prevRequesData =
      prevProps.location.state && prevProps.location.state.requestData;
    if (!prevRequesData && location.state && location.state.requestData) {
      this.setState({ selectedRequestType: location.state.requestData.type });
    }
  };

  getCurrencyOptions = (): Option[] => {
    return this.props.options.currencyOptions.map(currency => {
      return {
        key: currency.id,
        value: currency.id,
        display: currency.isoCode,
      };
    });
  };

  onRequestTypeChange = (type: string) => {
    this.setState({ selectedRequestType: type as RequestType });
  };

  navigate = () => {
    const { getNextCreateRequestRoute } = this.props.createRequestContext;
    const { user } = this.props.userContext;
    const { state } = this.props.location;
    const requestType = this.state.selectedRequestType;

    const borrower = ensureBorrower(user);
    if (borrower === undefined) {
      throw new Error("Unexpected undefined borrower");
    }

    if (requestType === undefined) {
      throw new Error("Unexpected undefined requestType");
    }

    const nextRoute = getNextCreateRequestRoute(borrower, requestType);
    if ((state && state.directApplyLink) || nextRoute === undefined) {
      this.props.history.push("/close");
    } else {
      this.props.history.push(`${nextRoute}`, { requestType });
    }
  };

  updatePendingCreateRequest = (values: CreateRequest) => {
    const { setPendingCreateRequest } = this.props.createRequestContext;
    setPendingCreateRequest(values);
  };

  persistLatestFormValues = async (
    formValuesToPersist: RequestFormValuesToPresist
  ) => {
    const { storageKey, formValues } = formValuesToPersist;
    await setLatestRequestFormValues(storageKey, formValues);
  };

  onForeignRequestSubmit = (values: CreateForeignExchangeRequest) => {
    this.updatePendingCreateRequest(values);
    this.navigate();
  };

  onMortgageSubmit = async (
    values: CreateMortgageRequest,
    formValuesToPersist: RequestMorgageFormValuesToPresist
  ) => {
    await this.persistLatestFormValues(formValuesToPersist);
    this.updatePendingCreateRequest(values);
    this.navigate();
  };

  onPersonalLoanSubmit = async (
    values: CreatePersonalLoanRequest,
    formValuesToPersist: RequestPersonalLoanFormValuesToPresist
  ) => {
    await this.persistLatestFormValues(formValuesToPersist);
    this.updatePendingCreateRequest(values);
    this.navigate();
  };

  onCloseButtonClick = () => {
    this.props.history.push("/close");
  };

  render() {
    const locationState = this.props.location.state;
    const { selectedRequestType } = this.state;
    return (
      selectedRequestType && (
        <CreateRequestView
          user={this.props.userContext.user}
          selectedRequestType={selectedRequestType}
          currencyOptions={this.getCurrencyOptions()}
          quickRequestData={locationState && locationState.requestData}
          latestRequestFormValues={
            locationState && locationState.latestRequestFormValues
          }
          onRequestTypeChange={this.onRequestTypeChange}
          onForeignRequestSubmit={this.onForeignRequestSubmit}
          onMortgageSubmit={this.onMortgageSubmit}
          onPersonalLoanSubmit={this.onPersonalLoanSubmit}
          onCloseClick={this.onCloseButtonClick}
        />
      )
    );
  }
}

export default withLoading(
  withUser(withErrorAlert(withOptions(withCreateRequest(CreateRequestScreen))))
);
