import * as React from "react";
import { LocaleProvider } from "@oursky/react-messageformat";
import { Locale } from "../types/misc";
import { Omit } from "../utils/typeutils";

export const LocaleContext = React.createContext(null as any);

export interface LocaleContextValue {
  locale: Locale;
}

export interface LocaleContextProps {
  localeContext: LocaleContextValue;
}

interface Props {
  locale: Locale;
  children?: React.ReactNode;
}

const messagesByLocale: { [key: string]: { [key: string]: string } } = {
  [Locale.en]: require("../locale-data/en.json"),
  [Locale.zhHans]: require("../locale-data/zh-hans.json"),
  [Locale.zhHant]: require("../locale-data/zh-hant.json"),
};

export default function LocaleContextProvider(props: Props) {
  const { locale } = props;
  const messageByID = messagesByLocale[locale];
  return (
    <LocaleContext.Provider value={{ locale }}>
      <LocaleProvider locale={locale} messageByID={messageByID}>
        {props.children}
      </LocaleProvider>
    </LocaleContext.Provider>
  );
}

export function withLocale<P extends LocaleContextProps>(
  Component: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof LocaleContextProps>> {
  const Wrapped: React.FC<Omit<P, keyof LocaleContextProps>> = (
    props: Omit<P, keyof LocaleContextProps>
  ) => (
    // Cast ...props as any due to typescript bug.
    // See https://github.com/microsoft/TypeScript/issues/28938
    <LocaleContext.Consumer>
      {localeContext => (
        <Component {...props as any} localeContext={localeContext} />
      )}
    </LocaleContext.Consumer>
  );

  return Wrapped;
}
