import { renderRoutesFromConfigs } from "../utils/routes";

import LoginScreen from "../screens/LoginScreen";
import { SignupScreen } from "../screens/SignupScreen";
import SMSVerificationScreen from "../screens/SMSVerificationScreen";
import AgentWelcomeScreen from "../screens/AgentWelcomeScreen";
import { BorrowerLoanRecordScreen } from "../screens/BorrowerLoanRecordScreen";
import AdminImpersonateAgentSelectionScreen from "../screens/AdminImpersonateAgentSelectionScreen";
import CreateRequestScreen from "../screens/CreateRequestScreen";
import { BorrowerBasicInfoScreen } from "../screens/BorrowerBasicInfoScreen";
import BorrowerWorkInfoScreen from "../screens/BorrowerWorkInfoScreen";

function buildAuthRoutes(tab: "signup" | "login") {
  return renderRoutesFromConfigs([
    {
      path: tab,
      component: tab === "signup" ? SignupScreen : LoginScreen,
    },
    {
      path: `${tab}/sms-verification`,
      component: SMSVerificationScreen,
    },
    {
      path: `${tab}/welcome/agent`,
      component: AgentWelcomeScreen,
    },
    ...buildProfileRoutes(tab),
  ]);
}

function buildProfileRoutes(tab: "signup" | "login" | "profile") {
  return [
    {
      path: `${tab}/create-request`,
      component: CreateRequestScreen,
    },
    {
      path: `${tab}/basic-info`,
      component: BorrowerBasicInfoScreen,
    },
    {
      path: `${tab}/work-info`,
      component: BorrowerWorkInfoScreen,
    },
    {
      path: `${tab}/loan-record`,
      component: BorrowerLoanRecordScreen,
    },
  ];
}

function buildAdminImpersonateAgentRoutes() {
  return renderRoutesFromConfigs([
    {
      path: "impersonate-agent",
      component: AdminImpersonateAgentSelectionScreen,
    },
  ]);
}

export const LoginRoutes = buildAuthRoutes("login");
export const SignUpRoutes = buildAuthRoutes("signup");
export const ProfileRoutes = renderRoutesFromConfigs(
  buildProfileRoutes("profile")
);
export const AdminImpersonateAgentRoutes = buildAdminImpersonateAgentRoutes();
