import React, { PureComponent } from "react";
import styles from "./styles.module.scss";
import { FormattedMessage } from "@oursky/react-messageformat";
import { MPContent } from "../../components/MPContent";
import { RouteComponentProps } from "react-router";

const images = {
  image_404: require("../../images/404.svg"),
  background: require("../../images/background-empty.png"),
};

export class NotFoundView extends PureComponent<RouteComponentProps> {
  backHome = () => {
    const { history } = this.props;
    history.push("/");
  };

  render() {
    return (
      <MPContent backgroundImage={images.background}>
        <img src={images.image_404} alt="404" className={styles.image_404} />
        <h3 className={styles.title}>
          <FormattedMessage id="notScreenFound.title" />
        </h3>
        <button className={styles.btn} onClick={this.backHome}>
          <FormattedMessage id="notScreenFound.btn" />
        </button>
      </MPContent>
    );
  }
}
