import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import React from "react";
import numeral from "numeral";

import { CreateMortgageForm } from "../../models";

import { renderExpiredDate } from "./index";
import styles from "./styles.module.scss";

interface MortgageQuickOfferItemProps {
  offering: CreateMortgageForm;
}

export const MortgageQuickOfferItem: React.FC<
  MortgageQuickOfferItemProps
> = props => {
  const { offering } = props;

  return (
    <div>
      <div className={styles.title}>
        <FormattedMessage id="quick_offer.template_card.mortgage.title" />
      </div>

      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.label}>
            <FormattedMessage id="quick_offer.template_card.mortgage.loan_amount" />
          </div>
          <div className={styles.value}>
            {`${numeral(offering.loanAmount).format()}`}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.label}>
            <FormattedMessage id="quick_offer.template_card.mortgage.max_period" />
          </div>
          <div className={styles.value}>
            <FormattedMessage
              id="quick_offer.template_card.mortgage.max_period.years"
              values={{
                years: offering.paymentPeriodMax,
              }}
            />
          </div>
        </div>
      </div>

      <div className={classNames(styles.row, styles.col)}>
        <div className={styles.label}>
          <FormattedMessage id="quick_offer.template_card.mortgage.expired_at" />
        </div>
        <div className={styles.value}>
          {renderExpiredDate(offering.validDays)}
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.label}>
            <FormattedMessage id="quick_offer.template_card.mortgage.requirement" />
          </div>
          <div className={styles.text}>
            {offering.isRequiredEmployed ? (
              <FormattedMessage id="quick_offer.template_card.mortgage.requirement.required_income" />
            ) : (
              "NIL"
            )}
          </div>
        </div>
        <div className={styles.col}>
          <div className={styles.label}>
            <FormattedMessage id="quick_offer.template_card.mortgage.interest_rate" />
          </div>
          <div className={styles.value}>
            {`${offering.interestRateMin}% - ${offering.interestRateMax}%`}
          </div>
        </div>
      </div>

      <div className={classNames(styles.row, styles.col)}>
        <div className={styles.label}>
          <FormattedMessage id="quick_offer.template_card.mortgage.addition" />
        </div>
        <div className={styles.text}>{offering.addition.trim() || "NIL"}</div>
      </div>
    </div>
  );
};
