export enum Locale {
  en = "en",
  zhHant = "zh-hant",
  zhHans = "zh-hans",
}

export type StaticPageType =
  | "feedback"
  | "about"
  | "support"
  | "terms_and_conditions"
  | "privacy";

export interface StaticPageOption {
  labelId: string;
  type: StaticPageType;
}

export enum ListType {
  PersonalLoan = "personal_loan",
  Mortgage = "mortgage",
  Exchange = "exchange_loan",
}

export type AttachmentType = "image" | "document";
