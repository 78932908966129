export type SimpleEventEmitterListener<EventType> = (e: EventType) => void;
export type SimpleEventEmitterSubscriber<EventType> = (
  listener: SimpleEventEmitterListener<EventType>
) => { remove: () => void };

class SimpleEventEmitter<EventType> {
  private listeners: (SimpleEventEmitterListener<EventType>)[] = [];

  subscribe: SimpleEventEmitterSubscriber<EventType> = listener => {
    this.listeners.push(listener);
    return {
      remove: () => {
        this.listeners = this.listeners.filter(l => l !== listener);
      },
    };
  };

  publish: SimpleEventEmitterListener<EventType> = event => {
    this.listeners.forEach(l => l(event));
  };
}

export default SimpleEventEmitter;
