import React, { PureComponent } from "react";
import { FormattedMessage } from "@oursky/react-messageformat";

import styles from "./styles.module.scss";

import { AgentQuickOfferItem } from "../AgentQuickOfferItem";

import { withUser, UserContextProps } from "../../context/UserContext";
import { Agent, OfferingForm, UserType } from "../../models";

const images = {
  plusIcon: require("../../images/icon-create-offer.svg"),
};

interface OwnProps {
  onClickCreateOffer: () => void;
  onClickDeleteOffer: (index: number) => void;
}

type Props = OwnProps & UserContextProps;

class AgentQuickOfferView extends PureComponent<Props> {
  renderEmptyView = () => {
    return (
      <div className={styles.empty}>
        <FormattedMessage id="quick_offer.empty" />
      </div>
    );
  };

  renderQuickOfferList = (offeringTemplates: OfferingForm[]) => {
    const { onClickDeleteOffer } = this.props;
    return offeringTemplates.map((offering, index) => {
      return (
        <QuickOfferCard
          key={index}
          offering={offering}
          onClickDeleteOffer={onClickDeleteOffer}
          index={index}
        />
      );
    });
  };

  render() {
    const {
      userContext: { user },
    } = this.props;

    if (!user || user.type !== UserType.agent) {
      throw new Error(`Unexpected user type: ${user}`);
    }
    const offeringTemplates = (user as Agent).offeringTemplate;
    const numOfTemplate = offeringTemplates.length;

    return (
      <div className={styles.container}>
        <div className={styles.title}>
          <FormattedMessage id="quick_offer.title" />
        </div>
        <button
          disabled={numOfTemplate >= 3 || !(user as Agent).isValidated}
          className={styles.createOfferButton}
          onClick={this.props.onClickCreateOffer}
        >
          <div className={styles.plusContainer}>
            <img src={images.plusIcon} alt="create_offer" />
          </div>
          <div className={styles.textContainer}>
            <FormattedMessage id="quick_offer.create_offer.button" />
          </div>
        </button>
        <div className={styles.listContainer}>
          {numOfTemplate > 0
            ? this.renderQuickOfferList(offeringTemplates)
            : this.renderEmptyView()}
        </div>
      </div>
    );
  }
}

interface QuickOfferCardProps {
  offering: OfferingForm;
  onClickDeleteOffer: (index: number) => void;
  index: number;
}

const QuickOfferCard: React.FC<QuickOfferCardProps> = props => {
  const { index, onClickDeleteOffer, offering } = props;

  const onClick = React.useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onClickDeleteOffer(index);
    },
    [onClickDeleteOffer, index]
  );

  return (
    <div className={styles.card}>
      <AgentQuickOfferItem offering={offering} />
      <button className={styles.delete} onClick={onClick}>
        <FormattedMessage id="quick_offer.remove" />
      </button>
    </div>
  );
};

export default withUser(AgentQuickOfferView);
