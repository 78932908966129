import { Locale } from "../types/misc";
import { Globalization } from "@ionic-native/globalization";

export const getLocale = async (): Promise<Locale> => {
  const locale: string = (await Globalization.getPreferredLanguage()
    .then(x => x.value)
    .catch(_ => navigator.language)).toLowerCase();

  if (locale.startsWith("zh-")) {
    const hansPattern = /(?=cn|hans|sg)/;
    if (hansPattern.test(locale)) {
      return Locale.zhHans;
    }
    return Locale.zhHant;
  }

  return Locale.en;
};
