import {
  FormattedMessage,
  Context as LocaleContext,
} from "@oursky/react-messageformat";
import React, { useCallback } from "react";
import {
  ExistingLoanField,
  FieldType,
} from "../../context/CreateRequestContext";

import { ExistingLoan } from "../../models";
import {
  currencyFormatToNumber,
  formatToCurrencyAmount,
} from "../../utils/formatToCurrencyAmount";
import {
  ValidationError,
  ValidationResult,
  Validator,
  validate,
  filterValidatorByDisplayMap,
  ignoreIndexFieldMapper,
} from "../../utils/validate";
import { LoanInfoField } from "../ConditionalDisplayField/ConditionalDisplayField";

import ErrorField from "../ErrorField";
import { ClearableInput } from "../input";

import styles from "./LoanInfoForm.module.scss";

export type FormInputFields =
  | LenderFields
  | AmountFields
  | TenorFields
  | RemainingTenorFields
  | MonthlyRepaymentFields;

export const defaultLoanInfoFormValues: ExistingLoan = {
  id: null,
  lender: null,
  amount: null,
  tenor: null,
  remainingTenor: null,
  monthlyRepayment: null,
};

interface LoanInfoFormProps {
  index: number;
  onChange: (index: number, value: Partial<ExistingLoan>) => void;
  loan: ExistingLoan;
  error: ValidationError<FormInputFields>;
  focusField?: FormInputFields;
  onRemove?: (index: number) => void;
}

export const LoanInfoForm: React.FC<LoanInfoFormProps> = props => {
  const { loan, index, onChange, onRemove, error, focusField } = props;
  const { lender, amount, tenor, remainingTenor, monthlyRepayment } = loan;

  const onLenderChange = useCallback(
    (v: string) => onChange(index, { lender: v }),
    [index, onChange]
  );

  const onAmountChange = useCallback(
    (v: string) =>
      onChange(index, { amount: v ? currencyFormatToNumber(v) : null }),
    [index, onChange]
  );

  const onTenorChange = useCallback(
    (v: string) => onChange(index, { tenor: v ? parseInt(v, 10) : null }),
    [index, onChange]
  );

  const onRemainingTenorChange = useCallback(
    (v: string) =>
      onChange(index, { remainingTenor: v ? parseInt(v, 10) : null }),
    [index, onChange]
  );

  const onMonthlyRepaymentChange = useCallback(
    (v: string) =>
      onChange(index, {
        monthlyRepayment: v ? currencyFormatToNumber(v) : null,
      }),
    [index, onChange]
  );

  const onClickRemove = useCallback(() => {
    if (onRemove) {
      onRemove(index);
    }
  }, [index, onRemove]);

  return (
    <LocaleContext.Consumer>
      {({ renderToString }) => (
        <div>
          <div className={styles.formHeader}>
            <div className={styles.title}>
              <FormattedMessage
                id="borrower_loan_info_form.loan_number"
                values={{ number: index + 1 }}
              />
            </div>
            {onRemove && (
              <div onClick={onClickRemove} className={styles.removeButton}>
                <FormattedMessage id="borrower_loan_info_form.remove" />
              </div>
            )}
          </div>

          <LoanInfoField field="lender">
            <div
              className={styles.fieldContainer}
              data-anchor={lenderFields[index]}
            >
              <div className={styles.fieldTitle}>
                <FormattedMessage id="borrower_loan_info_form.lender.label" />
              </div>
              <ClearableInput
                isError={error[lenderFields[index]] !== undefined}
                placeholder={renderToString(
                  "borrower_loan_info_form.placeholder"
                )}
                value={lender || ""}
                onChange={onLenderChange}
                scrollAnchor={lenderFields[index]}
                shouldScrollTo={focusField === lenderFields[index]}
              />
              <ErrorField
                isShown={error[lenderFields[index]] !== undefined}
                hiddenType="gone"
                messageId={error[lenderFields[index]]}
              />
            </div>
          </LoanInfoField>

          <LoanInfoField field="amount">
            <div
              className={styles.fieldContainer}
              data-anchor={amountFields[index]}
            >
              <div className={styles.fieldTitle}>
                <FormattedMessage id="borrower_loan_info_form.amount.label" />
              </div>
              <ClearableInput
                type="tel"
                isError={error[amountFields[index]] !== undefined}
                placeholder={renderToString(
                  "borrower_loan_info_form.placeholder"
                )}
                value={(amount && formatToCurrencyAmount(amount)) || ""}
                onChange={onAmountChange}
                maxLength={15}
                scrollAnchor={amountFields[index]}
                shouldScrollTo={focusField === amountFields[index]}
              />
              <ErrorField
                isShown={error[amountFields[index]] !== undefined}
                hiddenType="gone"
                messageId={error[amountFields[index]]}
              />
            </div>
          </LoanInfoField>

          <LoanInfoField field="tenor">
            <div
              className={styles.fieldContainer}
              data-anchor={tenorFields[index]}
            >
              <div className={styles.fieldTitle}>
                <FormattedMessage id="borrower_loan_info_form.tenor.label" />
              </div>
              <ClearableInput
                type="tel"
                isError={error[tenorFields[index]] !== undefined}
                placeholder={renderToString(
                  "borrower_loan_info_form.tenor.placeholder"
                )}
                value={tenor || ""}
                onChange={onTenorChange}
                maxLength={4}
                scrollAnchor={tenorFields[index]}
                shouldScrollTo={focusField === tenorFields[index]}
              />
              <ErrorField
                isShown={error[tenorFields[index]] !== undefined}
                hiddenType="gone"
                messageId={error[tenorFields[index]]}
              />
            </div>
          </LoanInfoField>

          <LoanInfoField field="remainingTenor">
            <div
              className={styles.fieldContainer}
              data-anchor={remainingTenorFields[index]}
            >
              <div className={styles.fieldTitle}>
                <FormattedMessage id="borrower_loan_info_form.remaining_tenor.label" />
              </div>
              <ClearableInput
                type="tel"
                isError={error[remainingTenorFields[index]] !== undefined}
                placeholder={renderToString(
                  "borrower_loan_info_form.tenor.placeholder"
                )}
                value={remainingTenor || ""}
                onChange={onRemainingTenorChange}
                maxLength={4}
                scrollAnchor={remainingTenorFields[index]}
                shouldScrollTo={focusField === remainingTenorFields[index]}
              />
              <ErrorField
                isShown={error[remainingTenorFields[index]] !== undefined}
                hiddenType="gone"
                messageId={error[remainingTenorFields[index]]}
              />
            </div>
          </LoanInfoField>

          <LoanInfoField field="monthlyRepayment">
            <div
              className={styles.fieldContainer}
              data-anchor={monthlyRepaymentFields[index]}
            >
              <div className={styles.fieldTitle}>
                <FormattedMessage id="borrower_loan_info_form.monthly_repayment.label" />
              </div>
              <ClearableInput
                type="tel"
                isError={error[monthlyRepaymentFields[index]] !== undefined}
                placeholder={renderToString(
                  "borrower_loan_info_form.placeholder"
                )}
                value={
                  (monthlyRepayment &&
                    formatToCurrencyAmount(monthlyRepayment)) ||
                  ""
                }
                onChange={onMonthlyRepaymentChange}
                maxLength={15}
                scrollAnchor={monthlyRepaymentFields[index]}
                shouldScrollTo={focusField === monthlyRepaymentFields[index]}
              />
              <ErrorField
                isShown={error[monthlyRepaymentFields[index]] !== undefined}
                hiddenType="gone"
                messageId={error[monthlyRepaymentFields[index]]}
              />
            </div>
          </LoanInfoField>
        </div>
      )}
    </LocaleContext.Consumer>
  );
};

type ValidationFields = ExistingLoanField;
export function validatLoanInfoForm(
  existingLoans: ExistingLoan[],
  formDisplayMap?: { [key in ValidationFields]?: FieldType },
  skipProfileFilling: boolean = false
): ValidationResult<FormInputFields> {
  return existingLoans.reduce<ValidationResult<FormInputFields>>(
    (
      prevResult: ValidationResult<FormInputFields>,
      cur: ExistingLoan,
      index: number
    ) => {
      const { lender, amount, tenor, remainingTenor, monthlyRepayment } = cur;

      let validators: Validator<
        FormInputFields
      >[] = filterValidatorByDisplayMap(
        [
          {
            field: lenderFields[index],
            invalidCondition: lender === null,
            errorMessageId: "borrower_loan_info_form.placeholder",
            isExistenceCheck: true,
          },
          {
            field: amountFields[index],
            invalidCondition: amount === null,
            errorMessageId: "borrower_loan_info_form.placeholder",
            isExistenceCheck: true,
          },
          {
            field: amountFields[index],
            invalidCondition: amount !== null && amount <= 0,
            errorMessageId: "borrower_loan_info_form.error.negative_or_zero",
          },
          {
            field: tenorFields[index],
            invalidCondition: tenor === null,
            errorMessageId: "borrower_loan_info_form.placeholder",
            isExistenceCheck: true,
          },
          {
            field: tenorFields[index],
            invalidCondition: tenor !== null && tenor <= 0,
            errorMessageId: "borrower_loan_info_form.error.negative_or_zero",
          },
          {
            field: remainingTenorFields[index],
            invalidCondition: remainingTenor === null,
            errorMessageId: "borrower_loan_info_form.placeholder",
            isExistenceCheck: true,
          },
          {
            field: remainingTenorFields[index],
            invalidCondition: remainingTenor !== null && remainingTenor <= 0,
            errorMessageId: "borrower_loan_info_form.error.negative_or_zero",
          },
          {
            field: remainingTenorFields[index],
            invalidCondition:
              remainingTenor !== null &&
              tenor !== null &&
              remainingTenor > tenor,
            errorMessageId:
              "borrower_loan_info_form.error.remaining_tenor_too_many",
          },
          {
            field: monthlyRepaymentFields[index],
            invalidCondition: !monthlyRepayment,
            errorMessageId: "borrower_loan_info_form.placeholder",
            isExistenceCheck: true,
          },
          {
            field: monthlyRepaymentFields[index],
            invalidCondition:
              monthlyRepayment !== null && monthlyRepayment <= 0,
            errorMessageId: "borrower_loan_info_form.error.negative_or_zero",
          },
        ],
        formDisplayMap,
        ignoreIndexFieldMapper
      );
      if (skipProfileFilling) {
        validators = validators.filter(it => !it.isExistenceCheck);
      }
      const validateResult = validate(validators);
      if (validateResult.isValid) {
        return prevResult;
      }
      return {
        focusField: prevResult.focusField || validateResult.focusField,
        error: {
          ...prevResult.error,
          ...validateResult.error,
        },
        isValid: false,
      };
    },
    {
      focusField: undefined,
      error: {},
      isValid: true,
    }
  );
}

type LenderFields =
  | "lender0"
  | "lender1"
  | "lender2"
  | "lender3"
  | "lender4"
  | "lender5"
  | "lender6"
  | "lender7"
  | "lender8"
  | "lender9"
  | "lender10"
  | "lender11"
  | "lender12"
  | "lender13"
  | "lender14"
  | "lender15"
  | "lender16"
  | "lender17"
  | "lender18"
  | "lender19";
const lenderFields: LenderFields[] = [
  "lender0",
  "lender1",
  "lender2",
  "lender3",
  "lender4",
  "lender5",
  "lender6",
  "lender7",
  "lender8",
  "lender9",
  "lender10",
  "lender11",
  "lender12",
  "lender13",
  "lender14",
  "lender15",
  "lender16",
  "lender17",
  "lender18",
  "lender19",
];
type AmountFields =
  | "amount0"
  | "amount1"
  | "amount2"
  | "amount3"
  | "amount4"
  | "amount5"
  | "amount6"
  | "amount7"
  | "amount8"
  | "amount9"
  | "amount10"
  | "amount11"
  | "amount12"
  | "amount13"
  | "amount14"
  | "amount15"
  | "amount16"
  | "amount17"
  | "amount18"
  | "amount19";
const amountFields: AmountFields[] = [
  "amount0",
  "amount1",
  "amount2",
  "amount3",
  "amount4",
  "amount5",
  "amount6",
  "amount7",
  "amount8",
  "amount9",
  "amount10",
  "amount11",
  "amount12",
  "amount13",
  "amount14",
  "amount15",
  "amount16",
  "amount17",
  "amount18",
  "amount19",
];
type TenorFields =
  | "tenor0"
  | "tenor1"
  | "tenor2"
  | "tenor3"
  | "tenor4"
  | "tenor5"
  | "tenor6"
  | "tenor7"
  | "tenor8"
  | "tenor9"
  | "tenor10"
  | "tenor11"
  | "tenor12"
  | "tenor13"
  | "tenor14"
  | "tenor15"
  | "tenor16"
  | "tenor17"
  | "tenor18"
  | "tenor19";
const tenorFields: TenorFields[] = [
  "tenor0",
  "tenor1",
  "tenor2",
  "tenor3",
  "tenor4",
  "tenor5",
  "tenor6",
  "tenor7",
  "tenor8",
  "tenor9",
  "tenor10",
  "tenor11",
  "tenor12",
  "tenor13",
  "tenor14",
  "tenor15",
  "tenor16",
  "tenor17",
  "tenor18",
  "tenor19",
];
type RemainingTenorFields =
  | "remainingTenor0"
  | "remainingTenor1"
  | "remainingTenor2"
  | "remainingTenor3"
  | "remainingTenor4"
  | "remainingTenor5"
  | "remainingTenor6"
  | "remainingTenor7"
  | "remainingTenor8"
  | "remainingTenor9"
  | "remainingTenor10"
  | "remainingTenor11"
  | "remainingTenor12"
  | "remainingTenor13"
  | "remainingTenor14"
  | "remainingTenor15"
  | "remainingTenor16"
  | "remainingTenor17"
  | "remainingTenor18"
  | "remainingTenor19";
const remainingTenorFields: RemainingTenorFields[] = [
  "remainingTenor0",
  "remainingTenor1",
  "remainingTenor2",
  "remainingTenor3",
  "remainingTenor4",
  "remainingTenor5",
  "remainingTenor6",
  "remainingTenor7",
  "remainingTenor8",
  "remainingTenor9",
  "remainingTenor10",
  "remainingTenor11",
  "remainingTenor12",
  "remainingTenor13",
  "remainingTenor14",
  "remainingTenor15",
  "remainingTenor16",
  "remainingTenor17",
  "remainingTenor18",
  "remainingTenor19",
];
type MonthlyRepaymentFields =
  | "monthlyRepayment0"
  | "monthlyRepayment1"
  | "monthlyRepayment2"
  | "monthlyRepayment3"
  | "monthlyRepayment4"
  | "monthlyRepayment5"
  | "monthlyRepayment6"
  | "monthlyRepayment7"
  | "monthlyRepayment8"
  | "monthlyRepayment9"
  | "monthlyRepayment10"
  | "monthlyRepayment11"
  | "monthlyRepayment12"
  | "monthlyRepayment13"
  | "monthlyRepayment14"
  | "monthlyRepayment15"
  | "monthlyRepayment16"
  | "monthlyRepayment17"
  | "monthlyRepayment18"
  | "monthlyRepayment19";
const monthlyRepaymentFields: MonthlyRepaymentFields[] = [
  "monthlyRepayment0",
  "monthlyRepayment1",
  "monthlyRepayment2",
  "monthlyRepayment3",
  "monthlyRepayment4",
  "monthlyRepayment5",
  "monthlyRepayment6",
  "monthlyRepayment7",
  "monthlyRepayment8",
  "monthlyRepayment9",
  "monthlyRepayment10",
  "monthlyRepayment11",
  "monthlyRepayment12",
  "monthlyRepayment13",
  "monthlyRepayment14",
  "monthlyRepayment15",
  "monthlyRepayment16",
  "monthlyRepayment17",
  "monthlyRepayment18",
  "monthlyRepayment19",
];
