import React from "react";
import { RouteComponentProps, withRouter } from "react-router";

interface Props extends RouteComponentProps {
  onEnter?: (previousPathname: string) => void;
  onLeave?: (nextPathname: string) => void;
  includeFirstEntrance?: boolean;
}

const RouteTriggerImpl: React.FC<Props> = props => {
  const currentPathname = props.location.pathname;
  const { onEnter, onLeave } = props;
  const [originalPathname] = React.useState(currentPathname);
  const [pathname, setPathname] = React.useState(
    props.includeFirstEntrance ? "" : currentPathname
  );

  React.useEffect(() => {
    const previousPathName = pathname;
    if (currentPathname !== pathname) {
      setPathname(currentPathname);
      if (currentPathname === originalPathname) {
        if (onEnter) {
          onEnter(previousPathName);
        }
      }

      if (currentPathname !== originalPathname) {
        if (onLeave) {
          onLeave(currentPathname);
        }
      }
    }
  }, [onEnter, onLeave, currentPathname, pathname, originalPathname]);

  return null;
};

export const RouteTrigger = withRouter(RouteTriggerImpl);
