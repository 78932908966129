import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router";

import { withOptions, OptionsProps } from "../context/OptionsContext";
import { withUser, UserContextProps } from "../context/UserContext";
import AgentOfferingCreateView from "../components/AgentOfferingCreateView";
import { Option as DPOption } from "../components/DropdownPicker";
import { parseError } from "../error";
import { Agent, OfferingForm, OfferingFromMode, RequestType } from "../models";
import {
  Failure,
  Initial,
  Loading,
  RemoteData,
  Success,
} from "../utils/remoteData";

type Props = OptionsProps & RouteComponentProps & UserContextProps;

interface State {
  remoteCreate: RemoteData<void>;
  selectedRequestType: RequestType;
}

class AgentOfferingTemplateCreateScreen extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      remoteCreate: Initial(),
      selectedRequestType: RequestType.personalLoan,
    };
  }

  private isCallingAPI = false;

  updateOfferingTemplateToRemote = (
    template: OfferingForm
  ): Promise<boolean> => {
    if (this.isCallingAPI) return Promise.resolve(false);

    const { userContext } = this.props;
    const existingTemplate = (userContext.user as Agent).offeringTemplate;

    if (existingTemplate.length > 3) {
      this.setState({
        remoteCreate: Failure(new Error("Unexpected too much template")),
      });
      return Promise.resolve(false);
    }

    existingTemplate.push(template);

    this.setState({ remoteCreate: Loading() });
    this.isCallingAPI = true;
    return this.props.userContext
      .updateOfferingTemplate(existingTemplate)
      .then(() => {
        this.setState({ remoteCreate: Success(undefined) }, () => {
          this.props.history.push("/manage");
        });
        return true;
      })
      .catch(error => {
        this.setState({ remoteCreate: Failure(parseError(error)) });
        return false;
      })
      .finally(() => {
        this.isCallingAPI = false;
      });
  };

  getCurrencyOptions = (): DPOption[] => {
    return this.props.options.currencyOptions.map(currency => {
      return {
        key: currency.id,
        value: currency.id,
        display: currency.isoCode,
      };
    });
  };

  onRequestTypeChange = (type: string) => {
    this.setState({ selectedRequestType: type as RequestType });
  };

  render() {
    const { remoteCreate, selectedRequestType } = this.state;
    return (
      <AgentOfferingCreateView
        mode={OfferingFromMode.template}
        currencyOptions={this.getCurrencyOptions()}
        remoteCreate={remoteCreate}
        selectedRequestType={selectedRequestType}
        onRequestTypeChange={this.onRequestTypeChange}
        onSubmitForeignExchange={this.updateOfferingTemplateToRemote}
        onSubmitMortgage={this.updateOfferingTemplateToRemote}
        onSubmitPersonalLoan={this.updateOfferingTemplateToRemote}
      />
    );
  }
}

export default withUser(withOptions(AgentOfferingTemplateCreateScreen));
