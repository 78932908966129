import React, { PureComponent } from "react";

import styles from "./styles.module.scss";

import { InfiniteScrollView } from "../InfiniteListView";
import { OfferingListCardView } from "../OfferingListCardView";

import { Page } from "../../utils/pagination";
import { Chat } from "../../models";

interface Props {
  fetchPage: (cursor?: string) => Promise<Page<Chat>>;
  openChatroom: (chat: Chat) => void;
}

export class ListView extends PureComponent<Props> {
  render() {
    const { fetchPage } = this.props;
    return (
      <InfiniteScrollView
        listClassName={styles.list}
        emptyMessageId="common.empty_list"
        fetchPage={fetchPage}
        renderItem={this.renderItem}
        threshold={400}
      />
    );
  }

  renderItem = (chat: Chat, index: number) => {
    const { openChatroom } = this.props;
    return (
      <OfferingListCardView
        key={index}
        chat={chat}
        openChatroom={openChatroom}
      />
    );
  };
}
