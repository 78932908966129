import { Plugins } from "@capacitor/core";
import { RefObject, useCallback, useContext, useEffect } from "react";
import { DeepLinkHandlerContext } from "../context/DeepLinkHandlerContext";
import { MAILTO_REGEX } from "./validation";

const { App } = Plugins;

export default function(
  htmlContentContainerRef: RefObject<HTMLElement>,
  changes: any[],
  onLinkClick?: (url: string) => void
) {
  const deepLinkHandlerContext = useContext(DeepLinkHandlerContext);

  const handleLinkClick = useCallback(
    (url: string) => {
      if (onLinkClick) {
        onLinkClick(url);
        return;
      }
      if (url !== "") {
        if (MAILTO_REGEX.exec(url)) {
          App.openUrl({ url });
        } else {
          deepLinkHandlerContext.handleUrl(url);
        }
      }
    },
    [onLinkClick, deepLinkHandlerContext]
  );

  const onClickAnchor = useCallback(
    (e: MouseEvent) => {
      const a = e.currentTarget as HTMLAnchorElement | null;
      if (!a) {
        return;
      }
      const url = a.getAttribute("href");
      if (url == null) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      handleLinkClick(url);
    },
    [handleLinkClick]
  );

  useEffect(() => {
    if (htmlContentContainerRef.current == null) {
      return;
    }
    const anchors = htmlContentContainerRef.current.querySelectorAll("a");
    anchors.forEach(a => {
      a.addEventListener("click", onClickAnchor);
    });
    return () => {
      anchors.forEach(a => a.removeEventListener("click", onClickAnchor));
    };
  }, [htmlContentContainerRef, onClickAnchor, ...changes]); // eslint-disable-line react-hooks/exhaustive-deps
}
