import { getPlatforms as _getPlatforms, Platforms } from "@ionic/core";
import { UAParser } from "ua-parser-js";

export const getPlatforms = () => _getPlatforms(window);

export const isPlatform = (platform: Platforms) =>
  getPlatforms().includes(platform);

export const hybridOnly = (fn: () => void) => {
  if (isPlatform("hybrid")) {
    fn();
  }
};

export const browserOnly = (fn: () => void) => {
  if (!isPlatform("hybrid")) {
    fn();
  }
};

export const iOSHybridOnly = (fn: () => void) => {
  if (isPlatform("ios") && isPlatform("hybrid")) {
    fn();
  }
};

export function isDesktopSafari() {
  if (!isPlatform("desktop")) {
    return false;
  }

  const userAgent = new UAParser().getResult();
  return userAgent.browser.name === "Safari";
}
