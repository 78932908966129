import * as React from "react";
import { Omit } from "../utils/typeutils";
import { getWalkthroughValue, setWalkthroughDone } from "../utils/storage";
import { WalkthroughStage } from "../types/walkthrough";

export interface WalkthroughContextValue {
  walkthroughStage: WalkthroughStage;
  load: () => Promise<void>;
  markAsDone: () => Promise<void>;
}

export interface WalkthroughContextProps {
  walkthroughContext: WalkthroughContextValue;
}

type ProviderState = Omit<WalkthroughContextValue, "load" | "markAsDone">;

export const WalkthroughContext = React.createContext<WalkthroughContextValue>(
  null as any
);

export class WalkthroughContextProvider extends React.PureComponent<
  {},
  ProviderState
> {
  state: ProviderState = {
    walkthroughStage: WalkthroughStage.Unseen,
  };

  load = async (): Promise<void> => {
    this.setState({
      walkthroughStage: await getWalkthroughValue(),
    });
  };

  markAsDone = (): Promise<void> => {
    this.setState({
      walkthroughStage: WalkthroughStage.Done,
    });
    return setWalkthroughDone();
  };

  render() {
    return (
      <WalkthroughContext.Provider
        value={{
          walkthroughStage: this.state.walkthroughStage,
          load: this.load,
          markAsDone: this.markAsDone,
        }}
      >
        {this.props.children}
      </WalkthroughContext.Provider>
    );
  }
}

export function withWalkthrough<P extends WalkthroughContextProps>(
  Component: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof WalkthroughContextProps>> {
  const Wrapped: React.FC<Omit<P, keyof WalkthroughContextProps>> = (
    props: Omit<P, keyof WalkthroughContextProps>
  ) => (
    <WalkthroughContext.Consumer>
      {value => <Component {...props as any} walkthroughContext={value} />}
    </WalkthroughContext.Consumer>
  );

  return Wrapped;
}
