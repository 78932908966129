import React from "react";
import { Values as LocaleValues } from "@oursky/react-messageformat";
import { IonActionSheet, IonAlert } from "@ionic/react";
import classnames from "classnames";

import { isPlatform } from "../../utils/platform";

import { AttachmentType } from "../../types/misc";

import styles from "./styles.module.scss";
import {
  AndroidPermissionKey,
  getAndroidCameraPermissionOptions,
  setAndroidPermissionPrompted,
  setAndroidPermissionSkipped,
} from "../../utils/storage";

interface Props {
  onSendFile: (file: File, type: "image" | "document") => void;
  disabled: boolean;
  renderToString: (id: string, values?: LocaleValues) => string;
  hasPermission: (feature: "camera" | "photoAlbum") => Promise<boolean>;
  openSettingPage: () => Promise<void>;
}

interface State {
  isActionSheetOpened: boolean;
  isCameraPermissionDeniedAlertOpen: boolean;
  attachmentType?: AttachmentType;
}

export class AttachmentButton extends React.PureComponent<Props, State> {
  state: State = {
    isActionSheetOpened: false,
    isCameraPermissionDeniedAlertOpen: false,
  };

  inputRef = React.createRef<HTMLInputElement>();
  onButtonClick = (e: React.MouseEvent<unknown>) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      isActionSheetOpened: true,
    });
  };

  onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { attachmentType } = this.state;
    if (e.currentTarget.files && attachmentType) {
      this.props.onSendFile(e.currentTarget.files[0], attachmentType);
    }
  };

  buildActionSheetItem = (
    messageId: string,
    handler?: () => void,
    role?: string
  ) => {
    return {
      text: this.props.renderToString(messageId),
      ...(handler ? { handler } : {}),
      ...(role ? { role } : {}),
    };
  };

  closeActionSheet = () => {
    this.setState({
      isActionSheetOpened: false,
    });
  };

  checkCameraPermission = async () => {
    const permission = await this.props.hasPermission("camera");
    if (!permission) {
      this.setState({ isCameraPermissionDeniedAlertOpen: true });
    }
    return permission;
  };

  openCamera = async () => {
    if (isPlatform("android")) {
      const options = await getAndroidCameraPermissionOptions();
      if (options.prompted && !options.skipped) {
        const permission = await this.checkCameraPermission();
        if (!permission) {
          return;
        }
      }
      await setAndroidPermissionPrompted(AndroidPermissionKey.Camera);
    }
    if (this.inputRef.current) {
      this.setState({ attachmentType: "image" });
      this.inputRef.current.accept = "image/*";
      this.inputRef.current.setAttribute("capture", "environment");
      this.inputRef.current.click();
    }
    this.closeActionSheet();
  };

  openPhotoAlbum = async () => {
    if (isPlatform("ios")) {
      //Note: the iOS devices access the camera on pressing the photo album
      const permission = await this.checkCameraPermission();
      if (!permission) {
        return;
      }
    }
    if (this.inputRef.current) {
      this.setState({ attachmentType: "image" });
      this.inputRef.current.accept = "image/*";
      this.inputRef.current.removeAttribute("capture");
      this.inputRef.current.click();
    }
    this.closeActionSheet();
  };

  openDocument = () => {
    if (this.inputRef.current) {
      this.setState({ attachmentType: "document" });
      this.inputRef.current.accept = "application/pdf";
      this.inputRef.current.removeAttribute("capture");
      this.inputRef.current.click();
    }
    this.closeActionSheet();
  };

  buildActionSheet = () => {
    const buttons = [];

    if (isPlatform("android")) {
      buttons.push(this.buildActionSheetItem("common.camera", this.openCamera));
    }
    buttons.push(
      this.buildActionSheetItem("common.photo", this.openPhotoAlbum)
    );
    buttons.push(
      this.buildActionSheetItem("common.document", this.openDocument)
    );

    buttons.push(
      this.buildActionSheetItem("common.cancel", undefined, "cancel")
    );

    return buttons;
  };

  hideCameraPermissionDeniedAlert = () => {
    this.setState({ isCameraPermissionDeniedAlertOpen: false });
  };

  cameraNavigateToSettings = async () => {
    await this.props.openSettingPage();
    this.hideCameraPermissionDeniedAlert();
  };

  androidSkipCameraPermission = async () => {
    await setAndroidPermissionSkipped(AndroidPermissionKey.Camera);
  };

  render() {
    const { isActionSheetOpened } = this.state;

    return (
      <>
        <input
          ref={this.inputRef}
          className={styles.attachmentButton}
          type="file"
          accept="image/*"
          onChange={this.onInputChange}
          disabled={this.props.disabled}
        />
        <button
          className={classnames(styles.attachmentButton, {
            [styles.disabled]: this.props.disabled,
          })}
          onClick={this.onButtonClick}
        />

        <IonActionSheet
          isOpen={isActionSheetOpened}
          onDidDismiss={this.closeActionSheet}
          buttons={this.buildActionSheet()}
          {...(isPlatform("ios")
            ? {
                className: styles.iosActionSheet,
              }
            : {})}
        />
        <IonAlert
          isOpen={this.state.isCameraPermissionDeniedAlertOpen}
          onDidDismiss={this.hideCameraPermissionDeniedAlert}
          header={this.props.renderToString(
            "attachment.permission.denied.title"
          )}
          message={this.props.renderToString(
            "attachment.permission.camera.denied.message"
          )}
          buttons={[
            {
              text: this.props.renderToString("attachment.permission.navigate"),
              handler: this.cameraNavigateToSettings,
            },
            ...(isPlatform("android")
              ? [
                  {
                    text: this.props.renderToString(
                      "attachment.permission.android.dont_show_again"
                    ),
                    handler: this.androidSkipCameraPermission,
                  },
                ]
              : []),
          ]}
        />
      </>
    );
  }
}
