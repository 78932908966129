import React from "react";

import {
  FormattedDropdown,
  FormattedDropdownProps,
  Option as FormattedDPOption,
} from "../FormattedDropdown";

import { Omit } from "../../utils/typeutils";

export interface MortgageLevelDropdownProps
  extends Omit<FormattedDropdownProps, "options"> {
  abbreviated?: boolean;
}

export const MortgageLevelOptions: FormattedDPOption[] = [
  {
    key: "1",
    value: "1",
    labelId: "option.mortgage_level.level_1",
  },
  {
    key: "2",
    value: "2",
    labelId: "option.mortgage_level.level_2",
  },
  {
    key: "3",
    value: "3",
    labelId: "option.mortgage_level.level_3",
  },
  {
    key: "4+",
    value: "4+",
    labelId: "option.mortgage_level.level_4_or_more",
  },
];

export const AbbreviatedMortgageLevelOptions: FormattedDPOption[] = [
  {
    key: "1",
    value: "1",
    labelId: "option.mortgage_level.level_1.abbr",
  },
  {
    key: "2",
    value: "2",
    labelId: "option.mortgage_level.level_2.abbr",
  },
  {
    key: "3",
    value: "3",
    labelId: "option.mortgage_level.level_3.abbr",
  },
  {
    key: "4+",
    value: "4+",
    labelId: "option.mortgage_level.level_4_or_more.abbr",
  },
];

export const MortgageLevelDropdown: React.FC<
  MortgageLevelDropdownProps
> = props => {
  const { abbreviated, ...rest } = props;
  return abbreviated ? (
    <FormattedDropdown
      options={AbbreviatedMortgageLevelOptions}
      selectClass="arrowTouchEdge"
      placeholderId="common.dropdown.placeholder"
      {...rest as any}
    />
  ) : (
    <FormattedDropdown
      options={MortgageLevelOptions}
      placeholderId="common.dropdown.placeholder"
      {...rest as any}
    />
  );
};
