import React from "react";
import styles from "./styles.module.scss";
import { FormattedMessage } from "@oursky/react-messageformat";

interface Props {
  icon: string;
  checkIcon: string;
  labelId: string;
  alt: string;
  checked: boolean | null;
}

const PersonalInfoItem: React.FC<Props> = props => {
  return (
    <div>
      <div className={styles.buttonContainer}>
        <div className={styles.iconContainer}>
          <img src={props.icon} alt={props.alt} />
          <div className={styles.checkIcon}>
            {props.checked && <img src={props.checkIcon} alt={props.alt} />}
          </div>
        </div>
        <div className={styles.buttonText}>
          <FormattedMessage id={props.labelId} />
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoItem;
