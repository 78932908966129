import React from "react";
import { RouteComponentProps } from "react-router";

import BorrowerWorkInfoView from "../components/BorrowerWorkInfoView";
import {
  getBorrowerWorkInfoFormValidators,
  InputFields,
} from "../components/BorrowerWorkInfoView/BorrowerWorkInfoForm";
import { withOptions, OptionsProps } from "../context/OptionsContext";

import {
  withErrorAlert,
  ErrorAlertContextProps,
} from "../context/ErrorAlertContext";
import { withLoading, LoadingContextProps } from "../context/LoadingContext";
import { withUser, UserContextProps } from "../context/UserContext";

import { Initial, Loading, RemoteData, Success } from "../utils/remoteData";
import { ensureBorrower } from "../utils/user";
import { validate, ValidationError } from "../utils/validate";

import {
  BorrowerOtherIncomeInfo,
  BorrowerWorkInfo,
  OtherIncome,
} from "../models";
import {
  defaultOtherIncomeValues,
  validateOtherIncomeForm,
} from "../components/BorrowerOtherIncomeView/OtherIncomeForm";
import {
  withCreateRequest,
  CreateRequestContextProps,
  DisplayMapByRequestType,
  CreateRequestRoute,
} from "../context/CreateRequestContext";
import { RequestType } from "../types/request";

type Props = LoadingContextProps &
  ErrorAlertContextProps &
  CreateRequestContextProps &
  UserContextProps &
  OptionsProps &
  RouteComponentProps<{}, {}, { requestType?: RequestType } | undefined>;

interface State {
  remoteUpdate: RemoteData<void>;
  borrowerWorkInfo: BorrowerWorkInfo;
  borrowerOtherIncomeInfo: BorrowerOtherIncomeInfo;
  hasIncomeProof: boolean | null;
  error: ValidationError<InputFields>;
  focusField?: InputFields;
  requestType?: RequestType;
}

class BorrowerWorkInfoScreen extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const borrower = ensureBorrower(props.userContext.user);
    if (borrower === undefined) {
      throw new Error("Unexpected borrower is undefined");
    }
    const {
      location: { state },
    } = this.props;
    this.state = {
      remoteUpdate: Initial(),
      borrowerWorkInfo: {
        isHired: borrower.isHired,
        industry: borrower.industry,
        workingCompanyNames: borrower.workingCompanyNames,
        position: borrower.position,
        employmentType: borrower.employmentType,
        salary: borrower.salary,
        onBoardDate: borrower.onBoardDate,
        incomeProofType: borrower.incomeProofType,
        paymentMethod: borrower.paymentMethod,
      },
      borrowerOtherIncomeInfo: {
        numberOfOtherIncomes:
          borrower.numberOfOtherIncomes === null
            ? borrower.otherIncomes.length || null
            : borrower.otherIncomes.length,
        otherIncomes: borrower.otherIncomes || [],
      },
      hasIncomeProof: null,
      error: {},
      requestType: state ? state.requestType : undefined,
    };
  }

  componentDidUpdate(_prevProps: Props, prevState: State) {
    if (
      prevState.borrowerWorkInfo.isHired !== this.state.borrowerWorkInfo.isHired
    ) {
      this.onUpdateIsHired(this.state.borrowerWorkInfo.isHired);
    }
  }

  setErrorState = (data: Partial<ValidationError<InputFields>>) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        ...data,
      },
    }));
  };

  onUpdateIsHired = (isHired: boolean | null) => {
    if (!isHired) {
      this.setState({
        borrowerWorkInfo: {
          isHired: isHired,
          industry: null,
          workingCompanyNames: null,
          position: null,
          employmentType: null,
          salary: null,
          onBoardDate: null,
          incomeProofType: null,
          paymentMethod: null,
        },
        hasIncomeProof: false,
      });
    }
    this.setErrorState({
      isHired: undefined,
      industry: undefined,
      workingCompanyNames: undefined,
      position: undefined,
      salary: undefined,
      employmentType: undefined,
      onBoardDate: undefined,
      hasIncomeProof: undefined,
      incomeProofType: undefined,
      paymentMethod: undefined,
    });
  };

  onUpdateHasIncomeProof = (hasIncomeProof: boolean) => {
    this.setState({ hasIncomeProof });
    if (!hasIncomeProof) {
      this.setState(prevState => ({
        ...prevState,
        borrowerWorkInfo: {
          ...prevState.borrowerWorkInfo,
          incomeProofType: null,
        },
      }));
    }
    this.setErrorState({
      hasIncomeProof: undefined,
      ...(!hasIncomeProof ? { incomeProofType: undefined } : {}),
    });
  };

  onUpdateBorrowerWorkInfo = (value: Partial<BorrowerWorkInfo>) => {
    this.setState(prevState => ({
      ...prevState,
      borrowerWorkInfo: {
        ...prevState.borrowerWorkInfo,
        ...value,
      },
    }));
    this.setErrorState(
      Object.keys(value).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );
  };

  onUpdateHasOtherIncome = (hasOtherIncome: boolean) => {
    if (!hasOtherIncome) {
      this.setState({
        borrowerOtherIncomeInfo: {
          otherIncomes: [],
          numberOfOtherIncomes: 0,
        },
      });
    } else {
      this.setState({
        borrowerOtherIncomeInfo: {
          otherIncomes: [
            {
              id: null,
              workingCompanyNames: null,
              position: null,
              salary: null,
              onBoardDate: null,
              incomeProofType: null,
              paymentMethod: null,
            },
          ],
          numberOfOtherIncomes: 1,
        },
      });
    }
    this.setErrorState({
      hasOtherIncome: undefined,
      numberOfOtherIncomes: undefined,
    });
  };

  onSelectNumberOfOtherIncomes = (numberOfOtherIncomes: number) => {
    this.setState(prevState => ({
      ...prevState,
      borrowerOtherIncomeInfo: {
        ...prevState.borrowerOtherIncomeInfo,
        otherIncomes:
          prevState.borrowerOtherIncomeInfo.otherIncomes.length >=
          numberOfOtherIncomes
            ? prevState.borrowerOtherIncomeInfo.otherIncomes.slice(
                0,
                numberOfOtherIncomes
              )
            : prevState.borrowerOtherIncomeInfo.otherIncomes.concat(
                Array(
                  numberOfOtherIncomes -
                    prevState.borrowerOtherIncomeInfo.otherIncomes.length
                ).fill(defaultOtherIncomeValues)
              ),
        numberOfOtherIncomes,
      },
    }));
    this.setErrorState({ numberOfOtherIncomes: undefined });
  };

  onUpdateExistingOtherIncome = (
    index: number,
    value: Partial<OtherIncome>
  ) => {
    this.setState(prevState => ({
      ...prevState,
      borrowerOtherIncomeInfo: {
        ...prevState.borrowerOtherIncomeInfo,
        otherIncomes: prevState.borrowerOtherIncomeInfo.otherIncomes.map(
          (v, i) =>
            i === index
              ? {
                  ...v,
                  ...value,
                }
              : v
        ),
      },
    }));
    this.setErrorState(
      Object.keys(value).reduce(
        (acc, cur) => ({ ...acc, [`${cur}${index}`]: undefined }),
        {}
      )
    );
  };

  navigate = (skipProfileFilling: boolean = false) => {
    const { getNextCreateRequestRoute } = this.props.createRequestContext;
    const { user } = this.props.userContext;
    const borrower = ensureBorrower(user);
    if (borrower === undefined) {
      throw new Error("Unexpected borrower is undefined");
    }
    const requestType = this.state.requestType;
    if (requestType === undefined) {
      throw new Error("Unexpected request type is undefined");
    }
    const nextRoute = getNextCreateRequestRoute(
      borrower,
      requestType,
      skipProfileFilling
    );
    if (nextRoute === undefined) {
      this.props.history.push("/close");
    } else {
      this.props.history.push(`${nextRoute}`, {
        requestType: this.state.requestType,
      });
    }
  };

  validateForm(skipProfileFilling: boolean = false) {
    const {
      borrowerWorkInfo,
      borrowerOtherIncomeInfo,
      hasIncomeProof,
    } = this.state;
    const { pendingCreateRequest } = this.props.createRequestContext;
    if (pendingCreateRequest === null) {
      throw new Error("Unexpected pending request is null");
    }
    const formDisplayMap =
      DisplayMapByRequestType[pendingCreateRequest.type][
        CreateRequestRoute.WorkInfo
      ];
    let validators = getBorrowerWorkInfoFormValidators(
      borrowerWorkInfo,
      borrowerOtherIncomeInfo,
      hasIncomeProof,
      formDisplayMap
    );
    if (skipProfileFilling) {
      validators = validators.filter(it => !it.isExistenceCheck);
    }
    const { focusField, error, isValid } = validate(validators);
    const formValidateResult = validateOtherIncomeForm(
      this.state.borrowerOtherIncomeInfo.otherIncomes,
      formDisplayMap["otherIncomes"],
      skipProfileFilling
    );
    this.setState(() => ({
      error: {
        ...error,
        ...formValidateResult.error,
      },
      focusField: focusField || formValidateResult.focusField,
    }));

    return isValid && formValidateResult.isValid;
  }

  submitForm = async () => {
    const { borrowerWorkInfo, borrowerOtherIncomeInfo } = this.state;
    this.setState({ remoteUpdate: Loading() });
    this.props.loadingContext.show();
    try {
      await this.props.userContext.updateBorrower({
        ...borrowerWorkInfo,
        ...borrowerOtherIncomeInfo,
      });
      this.setState({ remoteUpdate: Success(undefined) });
    } catch (error) {
      this.props.errorAlertContext.show(error);
      this.setState({ remoteUpdate: Initial() });
    } finally {
      this.props.loadingContext.dismiss();
    }
  };

  onSubmit = async () => {
    this.setState({ focusField: undefined });
    if (!this.validateForm()) {
      return;
    }
    await this.submitForm();
    this.navigate();
  };

  onSkipToContact = async () => {
    this.setState({ focusField: undefined });
    if (!this.validateForm(true)) {
      return;
    }
    await this.submitForm();
    const { setPendingCreateRequest } = this.props.createRequestContext;
    setPendingCreateRequest(prev => {
      if (prev) {
        return {
          ...prev,
          skipProfileFilling: true,
        };
      }
      return prev;
    });
    this.navigate(true);
  };

  render() {
    const {
      remoteUpdate,
      borrowerWorkInfo,
      borrowerOtherIncomeInfo,
      hasIncomeProof,
      error,
      focusField,
    } = this.state;

    return (
      <BorrowerWorkInfoView
        remoteUpdate={remoteUpdate}
        borrowerWorkInfo={borrowerWorkInfo}
        hasIncomeProof={hasIncomeProof}
        updateHasIncomeProof={this.onUpdateHasIncomeProof}
        updateBorrowerWorkInfo={this.onUpdateBorrowerWorkInfo}
        borrowerOtherIncomeInfo={borrowerOtherIncomeInfo}
        onUpdateExistingOtherIncome={this.onUpdateExistingOtherIncome}
        onUpdateHasOtherIncome={this.onUpdateHasOtherIncome}
        onSelectNumberOfOtherIncomes={this.onSelectNumberOfOtherIncomes}
        error={error}
        focusField={focusField}
        onSubmit={this.onSubmit}
        onSkipToContact={this.onSkipToContact}
      />
    );
  }
}

export default withLoading(
  withErrorAlert(
    withCreateRequest(withOptions(withUser(BorrowerWorkInfoScreen)))
  )
);
