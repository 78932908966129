import React from "react";
import { Route, Redirect, Switch, RouteComponentProps } from "react-router";

import AgentAccountScreen from "../screens/AgentAccountScreen";
import AgentOfferingCreateScreen from "../screens/AgentOfferingCreateScreen";
import AgentOfferingScreen from "../screens/AgentOfferingScreen";
import AgentOfferingTemplateCreateScreen from "../screens/AgentOfferingTemplateCreateScreen";
import AgentManageScreen from "../screens/AgentManageScreen";
import AgentChatScreen from "../screens/AgentChatScreen";
import { BorrowerPersonalInfoScreen } from "../screens/BorrowerPersonalInfoScreen";
import { ChatroomScreen } from "../screens/ChatroomScreen";
import { NotFoundView } from "../components/NotFoundView";

import { BaseTabs } from "../components/BaseTabs";
import { TabBar } from "../components/TabBar";

import { UserType } from "../models";
import { UserContext } from "../context/UserContext";
import { RouteConfig } from "../types/routes";

import { getValidPathPattern } from "../utils/routes";

import { BorrowerRouteDefaultPath } from "./BorrowerRoutes";

const Images = {
  accountIcon: {
    active: require("../images/icon-account-active.svg"),
    inactive: require("../images/icon-account-inactive.svg"),
  },
  chatIcon: {
    active: require("../images/icon-chat-active.svg"),
    inactive: require("../images/icon-chat-inactive.svg"),
  },
  manageIcon: {
    active: require("../images/icon-manage-active.svg"),
    inactive: require("../images/icon-manage-inactive.svg"),
  },
  offeringIcon: {
    active: require("../images/icon-offering-active.svg"),
    inactive: require("../images/icon-offering-inactive.svg"),
  },
};

export const AgentRouteDefaultPath = "/offering";

const RedirectToAgentDefaultPath: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const { history } = props;
  React.useEffect(() => {
    setTimeout(() => {
      history.replace(AgentRouteDefaultPath);
    }, 200);
  }, [history]);
  return null;
};

const RoutesConfigs: RouteConfig[] = [
  {
    path: "offering",
    component: AgentOfferingScreen,
    tab: {
      icon: Images.offeringIcon,
    },
  },
  {
    path: "offering/create",
    component: AgentOfferingCreateScreen,
  },
  {
    path: "offering/request-and-offering-list",
    component: RedirectToAgentDefaultPath,
  },
  {
    path: "chat",
    component: AgentChatScreen,
    tab: {
      icon: Images.chatIcon,
    },
  },
  {
    path: "chat/chatroom",
    component: ChatroomScreen,
  },
  {
    path: "chat/personal-info",
    component: BorrowerPersonalInfoScreen,
  },
  {
    path: "manage",
    component: AgentManageScreen,
    tab: {
      icon: Images.manageIcon,
    },
  },
  {
    path: "manage/create-template",
    component: AgentOfferingTemplateCreateScreen,
  },
  {
    path: "account",
    component: AgentAccountScreen,
    tab: {
      icon: Images.accountIcon,
    },
  },
];

export const AgentRoutes: React.FC = () => {
  const { user } = React.useContext(UserContext);
  if (user && user.type === UserType.borrower) {
    return <Redirect to={BorrowerRouteDefaultPath} />;
  }

  return (
    <Switch>
      <Route
        path={getValidPathPattern(RoutesConfigs)}
        exact={true}
        component={Tabs}
      />
      <Route component={NotFoundView} />
    </Switch>
  );
};

const Tabs: React.FC = () => {
  return (
    <BaseTabs outletId="agent-tabs" routeConfigs={RoutesConfigs}>
      <TabBar routeConfigs={RoutesConfigs} />
    </BaseTabs>
  );
};
