import { BaseApiClient, ApiClientConstructor } from "./client";

import { searchCompanyResponseSchema } from "../types/response";

import { Company } from "../models";

import { Page } from "../utils/pagination";

export interface CompanyApiClient {
  searchCompany: (text: string, cursor?: string) => Promise<Page<Company>>;
}

export function withCompanyApi<
  TBase extends ApiClientConstructor<BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    searchCompany(text: string, cursor?: string): Promise<Page<Company>> {
      const data: any = { name: text };
      if (cursor) {
        data.page_info = { cursor };
      }

      return this.requestLambda(
        "company:search",
        data,
        searchCompanyResponseSchema
      );
    }
  };
}
