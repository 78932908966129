import React, { useState } from "react";
import { Borrower } from "../models/user";
import { CreateRequest, RequestType } from "../types/request";
import { Omit } from "../utils/typeutils";
import {
  BorrowerBasicInfo,
  BorrowerLoanInfo,
  BorrowerOtherIncomeInfo,
  BorrowerPropertyInfo,
  BorrowerWorkInfo,
  ExistingLoan,
  OtherIncome,
  PropertyInfo,
  PropertyStatus,
} from "../models";

export enum CreateRequestRoute {
  BasicInfo = "basic-info",
  WorkInfo = "work-info",
  LoanRecord = "loan-record",
}

const RoutesInDisplayOrder = [
  CreateRequestRoute.BasicInfo,
  CreateRequestRoute.WorkInfo,
  CreateRequestRoute.LoanRecord,
];

export type FieldType = "required" | "optional";
export type BorrowerBasicInfoField =
  | keyof BorrowerBasicInfo
  | keyof BorrowerPropertyInfo;
export type BorrowerWorkInfoField =
  | keyof BorrowerWorkInfo
  | keyof BorrowerOtherIncomeInfo;
export type BorrowerLoanRecordField = keyof BorrowerLoanInfo;
export type BorrowerField =
  | BorrowerBasicInfoField
  | BorrowerWorkInfoField
  | BorrowerLoanRecordField;

export type PropertyInfoField = keyof PropertyInfo;
export type OtherIncomeField = keyof OtherIncome;
export type ExistingLoanField = keyof ExistingLoan;

type BorrowerListEntity = PropertyInfo | OtherIncome | ExistingLoan;
export type BorrowerListField = keyof Pick<
  Borrower,
  "propertyInfos" | "otherIncomes" | "existingLoans"
>;

interface BorrowerFormDisplayMap {
  [CreateRequestRoute.BasicInfo]: {
    [key in Exclude<BorrowerBasicInfoField, BorrowerListField>]?: FieldType
  } & { propertyInfos?: { [key in PropertyInfoField]?: FieldType } };
  [CreateRequestRoute.WorkInfo]: {
    [key in Exclude<BorrowerWorkInfoField, BorrowerListField>]?: FieldType
  } & { otherIncomes?: { [key in OtherIncomeField]?: FieldType } };
  [CreateRequestRoute.LoanRecord]: {
    [key in Exclude<BorrowerLoanRecordField, BorrowerListField>]?: FieldType
  } & { existingLoans?: { [key in ExistingLoanField]?: FieldType } };
}

export const DisplayMapByRequestType: {
  [key in RequestType]: BorrowerFormDisplayMap
} = {
  [RequestType.PersonalLoan]: {
    [CreateRequestRoute.BasicInfo]: {
      name: "required",
      surname: "required",
      gender: "optional",
      passportNumber: "optional",
      email: "optional",
      birthday: "required",
      education: "required",
      roomFloor: "optional",
      tower: "optional",
      nameOfBuilding: "optional",
      streetNameAndNumber: "optional",
      district: "required",
      propertyStatus: "required",
      heardFrom: "required",
      premiseType: "required",
      numOfOwner: "optional",
      monthlyRent: "optional",
      numberOfProperties: "required",
      propertyInfos: {
        id: "required",
        roomFloor: "required",
        tower: "required",
        nameOfBuilding: "required",
        streetNameAndNumber: "required",
        district: "required",
      },
    },
    [CreateRequestRoute.WorkInfo]: {
      isHired: "required",
      industry: "required",
      workingCompanyNames: "optional",
      position: "required",
      employmentType: "required",
      salary: "required",
      onBoardDate: "required",
      incomeProofType: "optional",
      paymentMethod: "required",
      numberOfOtherIncomes: "required",
      otherIncomes: {
        id: "required",
        workingCompanyNames: "required",
        position: "required",
        salary: "required",
        onBoardDate: "required",
        incomeProofType: "optional",
        paymentMethod: "required",
      },
    },
    [CreateRequestRoute.LoanRecord]: {
      hasMortgage: "required",
      existingLoans: {
        id: "required",
        lender: "required",
        amount: "required",
        tenor: "required",
        remainingTenor: "required",
        monthlyRepayment: "required",
      },
    },
  },
  [RequestType.Mortgage]: {
    [CreateRequestRoute.BasicInfo]: {
      name: "required",
      surname: "required",
      gender: "optional",
      passportNumber: "optional",
      email: "optional",
      birthday: "required",
      education: "required",
      roomFloor: "optional",
      tower: "optional",
      nameOfBuilding: "optional",
      streetNameAndNumber: "optional",
      district: "required",
      propertyStatus: "required",
      heardFrom: "required",
      premiseType: "required",
      numOfOwner: "optional",
      monthlyRent: "optional",
      numberOfProperties: "required",
      propertyInfos: {
        id: "required",
        roomFloor: "required",
        tower: "required",
        nameOfBuilding: "required",
        streetNameAndNumber: "required",
        district: "required",
      },
    },
    [CreateRequestRoute.WorkInfo]: {
      isHired: "required",
      industry: "required",
      workingCompanyNames: "optional",
      position: "required",
      employmentType: "required",
      salary: "required",
      onBoardDate: "required",
      incomeProofType: "optional",
      paymentMethod: "required",
      numberOfOtherIncomes: "required",
      otherIncomes: {
        id: "required",
        workingCompanyNames: "required",
        position: "required",
        salary: "required",
        onBoardDate: "required",
        incomeProofType: "optional",
        paymentMethod: "required",
      },
    },
    [CreateRequestRoute.LoanRecord]: {
      hasMortgage: "required",
      existingLoans: {
        id: "required",
        lender: "required",
        amount: "required",
        tenor: "required",
        remainingTenor: "required",
        monthlyRepayment: "required",
      },
    },
  },
  [RequestType.Exchange]: {
    [CreateRequestRoute.BasicInfo]: {},
    [CreateRequestRoute.WorkInfo]: {},
    [CreateRequestRoute.LoanRecord]: {},
  },
};

function isRequiredField(
  route: CreateRequestRoute,
  requestType: RequestType,
  field: BorrowerField
): boolean {
  return (
    ((DisplayMapByRequestType[requestType][route] as any)[
      field
    ] as FieldType) === "required"
  );
}

type FieldChecker = (
  borrower: Borrower,
  route: CreateRequestRoute,
  requestType: RequestType
) => boolean;

function isEntityFieldFilled<E, F extends Partial<keyof E>>(
  entity: E,
  field: F
) {
  const fieldValue = entity[field];
  return fieldValue !== undefined && fieldValue !== null;
}

function isBorroweFieldFilled(borrower: Borrower, field: BorrowerField) {
  return isEntityFieldFilled<Borrower, BorrowerField>(borrower, field);
}

function buildBorrowerWorkingInfoFieldChecker(
  field: BorrowerField
): FieldChecker {
  return (
    borrower: Borrower,
    route: CreateRequestRoute,
    requestType: RequestType
  ) =>
    borrower.isHired === false ||
    (isRequiredField(route, requestType, field)
      ? isBorroweFieldFilled(borrower, field)
      : true);
}

function buildBorrowerListEntitiesChecker<
  E extends BorrowerListEntity,
  F extends Partial<keyof E>
>(field: BorrowerListField, numberField?: BorrowerField): FieldChecker {
  return (
    borrower: Borrower,
    route: CreateRequestRoute,
    requestType: RequestType
  ) => {
    try {
      const entities = borrower[field];
      const displayMap = (DisplayMapByRequestType[requestType][route] as any)[
        field
      ];

      if (
        numberField !== undefined &&
        borrower[numberField] !== null &&
        borrower[numberField] !== entities.length
      ) {
        return false;
      }

      return entities.every((entity: any) =>
        Object.entries(displayMap).every(([entityField, fieldType]) =>
          (fieldType as FieldType) === "required"
            ? isEntityFieldFilled<E, F>(entity, entityField as F)
            : true
        )
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return false;
    }
  };
}

const _CustomFieldCheckerMap: { [key in BorrowerField]?: FieldChecker } = {
  industry: buildBorrowerWorkingInfoFieldChecker("industry"),
  workingCompanyNames: buildBorrowerWorkingInfoFieldChecker(
    "workingCompanyNames"
  ),
  position: buildBorrowerWorkingInfoFieldChecker("position"),
  employmentType: buildBorrowerWorkingInfoFieldChecker("employmentType"),
  salary: buildBorrowerWorkingInfoFieldChecker("salary"),
  onBoardDate: buildBorrowerWorkingInfoFieldChecker("onBoardDate"),
  incomeProofType: buildBorrowerWorkingInfoFieldChecker("incomeProofType"),
  paymentMethod: buildBorrowerWorkingInfoFieldChecker("paymentMethod"),
  numberOfOtherIncomes: buildBorrowerWorkingInfoFieldChecker(
    "numberOfOtherIncomes"
  ),
  numOfOwner: (
    borrower: Borrower,
    route: CreateRequestRoute,
    requestType: RequestType
  ) =>
    borrower.propertyStatus !== PropertyStatus.jointlyOwned ||
    (isRequiredField(route, requestType, "numOfOwner")
      ? isBorroweFieldFilled(borrower, "numOfOwner")
      : true),
  propertyInfos: buildBorrowerListEntitiesChecker<
    PropertyInfo,
    PropertyInfoField
  >("propertyInfos", "numberOfProperties"),
  otherIncomes: buildBorrowerListEntitiesChecker<OtherIncome, OtherIncomeField>(
    "otherIncomes",
    "numberOfOtherIncomes"
  ),
  existingLoans: buildBorrowerListEntitiesChecker<
    ExistingLoan,
    ExistingLoanField
  >("existingLoans", undefined),
};

const CustomFieldCheckerMap: Map<string, FieldChecker | undefined> = new Map(
  Object.entries(_CustomFieldCheckerMap)
);

function shouldPresentRoute(
  borrower: Borrower,
  route: CreateRequestRoute,
  requestType: RequestType
): boolean {
  try {
    const displayMap = DisplayMapByRequestType[requestType][route];

    return Object.entries(displayMap).some(
      ([field, fieldType]: [string, FieldType]) => {
        const checker = CustomFieldCheckerMap.get(field);
        return !(checker !== undefined
          ? checker(borrower, route, requestType)
          : fieldType === "required"
          ? isBorroweFieldFilled(borrower, field as BorrowerField)
          : true);
      }
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return true;
  }
}

function getNextCreateRequestRoute(
  borrower: Borrower,
  requestType: RequestType,
  skipProfileFilling: boolean = false
): CreateRequestRoute | undefined {
  if (skipProfileFilling) {
    return undefined;
  }
  return RoutesInDisplayOrder.find(route =>
    shouldPresentRoute(borrower, route, requestType)
  );
}

export interface CreateRequestContextValue {
  getNextCreateRequestRoute: (
    borrower: Borrower,
    requestType: RequestType,
    skipProfileFilling?: boolean
  ) => CreateRequestRoute | undefined;
  pendingCreateRequest: CreateRequest | null;
  setPendingCreateRequest: React.Dispatch<
    React.SetStateAction<CreateRequest | null>
  >;
}

const CreateRequestContext = React.createContext<CreateRequestContextValue>(
  null as any
);
export { CreateRequestContext };

export interface CreateRequestContextProps {
  createRequestContext: CreateRequestContextValue;
  children?: React.ReactNode;
}

export const CreateRequestContextProvider: React.FC = props => {
  const { children } = props;
  const [
    pendingCreateRequest,
    setPendingCreateRequest,
  ] = useState<CreateRequest | null>(null);

  return (
    <CreateRequestContext.Provider
      value={{
        getNextCreateRequestRoute,
        pendingCreateRequest,
        setPendingCreateRequest,
      }}
    >
      {children}
    </CreateRequestContext.Provider>
  );
};

export function withCreateRequest<P extends CreateRequestContextProps>(
  Component: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof CreateRequestContextProps>> {
  const Wrapped: React.FC<Omit<P, keyof CreateRequestContextProps>> = (
    props: Omit<P, keyof CreateRequestContextProps>
  ) => (
    <CreateRequestContext.Consumer>
      {createRequestContext => (
        <Component
          {...props as any}
          createRequestContext={createRequestContext}
        />
      )}
    </CreateRequestContext.Consumer>
  );

  return Wrapped;
}
