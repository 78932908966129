import { currencyFormatToNumber } from "./formatToCurrencyAmount";

export const EMAIL_REGEX = /^(.+)@(.+)$/;

export const MAILTO_REGEX = /^mailto:/;

export const valueLimit = {
  maxAmount: 999999999,
};

export function validateFormattedAmount(value: string): boolean {
  if (currencyFormatToNumber(value) > valueLimit.maxAmount) {
    return false;
  }
  return true;
}
