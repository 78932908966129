import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import React from "react";
import { HollowButton, PrimaryButton } from "../Button";
import styles from "./styles.module.scss";

interface Props {
  titleId: string;
  confirmButtonTextId: string;
  cancelButtonTextId: string;
  onConfirm: () => void;
  onCancel: () => void;
}
const SuggestionDialogue: React.FC<Props> = props => {
  const {
    titleId,
    confirmButtonTextId,
    cancelButtonTextId,
    onConfirm,
    onCancel,
  } = props;

  return (
    <div className={styles.dialogue}>
      <div className={styles.dialogueTitle}>
        <FormattedMessage id={titleId} />
      </div>
      <div className={styles.buttonContainer}>
        <HollowButton
          className={classNames(styles.dialogueButton, styles.cancelButton)}
          type="button"
          onClick={onCancel}
        >
          <FormattedMessage id={cancelButtonTextId} />
        </HollowButton>
        <PrimaryButton
          className={styles.dialogueButton}
          type="button"
          onClick={onConfirm}
        >
          <FormattedMessage id={confirmButtonTextId} />
        </PrimaryButton>
      </div>
    </div>
  );
};

export default SuggestionDialogue;
