export const SMSVerification = {
  icon: require("./sms_verification.icon.svg"),
};

export const Profile = {
  icon: require("./borrower_welcome.icon.svg"),
};

export const common = {
  buttonCross: require("./common.button_cross.svg"),
  backButton: require("./common.back_button.svg"),
};

export const walkthrough = {
  walkthrough1: require("./walkthrough-1.svg"),
  walkthrough2: require("./walkthrough-2.svg"),
  walkthrough3: require("./walkthrough-3.svg"),
  walkthrough4: require("./walkthrough-4.png"),
  walkthrough5: require("./walkthrough-5.png"),
};
