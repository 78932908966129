import { IonModal, IonRippleEffect } from "@ionic/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import React, { PureComponent } from "react";

import { Company } from "../../models";
import { scrollToElement } from "../../utils/scroll";

import { SearchCompanyView } from "./SearchCompanyView";

import styles from "./CompanyPicker.module.scss";

export interface CompanyPickerProps {
  isError?: boolean;
  value?: Company;
  onChange?: (company: Company) => void;
  shouldFocus?: boolean;
  shouldScrollTo?: boolean;
  scrollAnchor?: string;
}

interface State {
  isOpen: boolean;
}

export class CompanyPicker extends PureComponent<CompanyPickerProps, State> {
  constructor(props: CompanyPickerProps) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  ref = React.createRef<HTMLInputElement>();

  componentDidUpdate(prevProps: CompanyPickerProps) {
    if (
      prevProps.shouldFocus !== this.props.shouldFocus &&
      this.props.shouldFocus
    ) {
      this.setState({ isOpen: true });
    }

    if (
      prevProps.shouldScrollTo !== this.props.shouldScrollTo &&
      this.props.shouldScrollTo
    ) {
      scrollToElement(this.ref.current, this.props.scrollAnchor);
    }
  }

  render() {
    const { isError, value } = this.props;
    const { isOpen } = this.state;

    return (
      <>
        <div
          className={classNames("ion-activatable", styles.button, {
            [styles.buttonInactive]: value == null,
            [styles.error]: isError,
          })}
          onClick={this.handleClick}
          ref={this.ref}
        >
          <IonRippleEffect />
          {value ? (
            value.name
          ) : (
            <FormattedMessage id="company_picker.placeholder" />
          )}
        </div>
        <IonModal isOpen={isOpen} onDidDismiss={this.handleModalDismiss}>
          <SearchCompanyView
            onClose={this.handleModalClose}
            onChange={this.handleCompanyChange}
          />
        </IonModal>
      </>
    );
  }

  handleClick = () => {
    this.setState({ isOpen: true });
  };

  handleModalDismiss = () => {
    this.setState({ isOpen: false });
  };

  handleModalClose = () => {
    this.setState({ isOpen: false });
  };

  handleCompanyChange = (company: Company) => {
    const { onChange } = this.props;

    this.setState({ isOpen: false });

    if (onChange) {
      onChange(company);
    }
  };
}
