import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router";

import { Chat } from "../models";

import { withRequest, RequestContextProps } from "../context/RequestContext";
import { withChat, ChatContextProps } from "../context/ChatContext";
import { withLoading, LoadingContextProps } from "../context/LoadingContext";
import {
  withErrorAlert,
  ErrorAlertContextProps,
} from "../context/ErrorAlertContext";

import ChatOfferingListView from "../components/ChatOfferingListView";
import { InfiniteScrollView } from "../components/InfiniteListView";
import { updateChatListForNewMessage } from "../utils/updateItems";

export interface ChatOfferingListScreenLocationState {
  requestId?: string;
  requestRefNum?: number;
}

type Props = RouteComponentProps<
  {},
  {},
  ChatOfferingListScreenLocationState | undefined
> &
  RequestContextProps &
  ChatContextProps &
  ErrorAlertContextProps &
  LoadingContextProps;

interface State {
  requestId?: string;
  requestRefNum?: number;
}

class ChatOfferingListScreenImpl extends PureComponent<Props, State> {
  private isCallingAPI = false;

  private infiniteScrollViewRef = React.createRef<InfiniteScrollView<Chat>>();
  private subscribedChatEventListener: { remove: () => void } | null = null;

  constructor(props: Props) {
    super(props);

    const stateFromLocationState = this.getStateFromeLocationState();
    if (stateFromLocationState) {
      this.state = {
        ...stateFromLocationState,
      };
    } else {
      this.state = {};
      props.history.push("/chat");
    }
  }

  componentDidMount() {
    this.subscribedChatEventListener = this.props.chatContext.subscribeEventEmitter(
      e => {
        if (e.type === "create") {
          if (this.infiniteScrollViewRef.current) {
            this.infiniteScrollViewRef.current.updateItems(
              updateChatListForNewMessage(e)
            );
          }
        }
      }
    );
  }

  componentWillUnmount() {
    if (this.subscribedChatEventListener) {
      this.subscribedChatEventListener.remove();
    }
  }

  getStateFromeLocationState = () => {
    const { location } = this.props;
    if (
      location.state &&
      location.state.requestId &&
      location.state.requestRefNum
    ) {
      return {
        requestId: location.state.requestId,
        requestRefNum: location.state.requestRefNum,
      };
    }
    return undefined;
  };

  fetchPage = (cursor?: string) => {
    if (this.state.requestId === undefined) {
      return Promise.resolve({
        nextCursor: null,
        results: [],
      });
    }
    return this.props.chatContext.borrowerListChatByRequestId(
      this.state.requestId,
      cursor
    );
  };

  onCancelRequest = () => {
    if (this.state.requestId === undefined) {
      return;
    }

    const { history } = this.props;
    this.props.requestContext
      .cancelBorrowerRequest(this.state.requestId)
      .then(this.props.chatContext.updateChatListVersion)
      .then(this.props.requestContext.refreshBorrowerRequestList)
      .then(() => {
        history.push("/chat");
      });
  };

  openChatroom = (chat: Chat) => {
    const { history } = this.props;

    if (this.isCallingAPI) {
      return;
    }

    this.isCallingAPI = true;
    this.props.loadingContext.show();
    this.props.chatContext
      .enterConversation(chat)
      .then(chatWithConversation => {
        history.push("chatroom", {
          conversationId: chatWithConversation.conversationId,
        });
      })
      .catch(this.props.errorAlertContext.show)
      .finally(() => {
        this.props.loadingContext.dismiss();
        this.isCallingAPI = false;
      });
  };

  render() {
    const { requestRefNum } = this.state;
    return (
      <ChatOfferingListView
        listVersion={this.props.chatContext.chatListVersion}
        requestRefNum={requestRefNum || 0}
        fetchPage={this.fetchPage}
        onCancelRequest={this.onCancelRequest}
        openChatroom={this.openChatroom}
        infiniteScrollViewRef={this.infiniteScrollViewRef}
      />
    );
  }
}

export const ChatOfferingListScreen = withLoading(
  withErrorAlert(withRequest(withChat(ChatOfferingListScreenImpl)))
);

export default ChatOfferingListScreen;
