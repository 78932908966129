import { IonHeader, IonPage, IonToolbar } from "@ionic/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { PureComponent } from "react";

import { Request } from "../../models";

import { InfiniteScrollView } from "../InfiniteListView";
import { ListRefreshControl } from "../ListRefreshControl";
import { RequestItem } from "./RequestItem";

import { Page } from "../../utils/pagination";
import styles from "./styles.module.scss";
import { MPContent } from "../MPContent";
import { ChatContextProps, withChat } from "../../context/ChatContext";

type Props = {
  listVersion: number;
  onClickOfferCount: (requestId: string, requestRefNum: number) => void;
  fetchRequests: (cursor?: string) => Promise<Page<Request>>;
  infiniteScrollViewRef: React.RefObject<InfiniteScrollView<Request>>;
} & ChatContextProps;

class ChatViewImpl extends PureComponent<Props> {
  render() {
    const { listVersion, fetchRequests, infiniteScrollViewRef } = this.props;
    return (
      <IonPage className={styles.container}>
        <IonHeader mode="ios">
          <IonToolbar class={styles.toolbar} mode="ios">
            <div className={styles.title}>
              <FormattedMessage id="borrower.requests.title" />
            </div>
          </IonToolbar>
        </IonHeader>
        <MPContent class={styles.content}>
          <ListRefreshControl listVersion={listVersion} routePrefix="/chat">
            <InfiniteScrollView
              ref={infiniteScrollViewRef}
              fetchPage={fetchRequests}
              renderItem={this.renderItem}
              listClassName={styles.list}
              threshold={400}
            />
          </ListRefreshControl>
        </MPContent>
      </IonPage>
    );
  }

  renderItem = (request: Request) => {
    return (
      <RequestItem
        key={request.id}
        request={request}
        onClickOfferCount={this.props.onClickOfferCount}
      />
    );
  };
}

export const ChatView = withChat(ChatViewImpl);
