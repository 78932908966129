import { withUserApi, UserApiClient } from "./user";
import { withAgentApi, AgentApiClient } from "./agent";
import { withBorrowerApi, BorrowerApiClient } from "./borrower";
import { withOptionApi, OptionApiClient } from "./option";
import { withTemplateApi, TemplateApiClient } from "./template";
import { withCompanyApi, CompanyApiClient } from "./company";
import { withRequestApi, RequestApiClient } from "./request";
import { withOfferingApi, OfferingApiClient } from "./offering";
import { withChatApi, ChatApiClient } from "./chat";
import { BaseApiClient } from "./client";
import { AppConfigApiClient, withAppConfigApi } from "./appConfig";

export * from "./agent";
export * from "./borrower";
export * from "./company";
export * from "./offering";
export * from "./request";
export * from "./user";
export * from "./chat";
export * from "./appConfig";

export type ApiClient = BaseApiClient &
  UserApiClient &
  AgentApiClient &
  BorrowerApiClient &
  OptionApiClient &
  CompanyApiClient &
  RequestApiClient &
  OfferingApiClient &
  ChatApiClient &
  TemplateApiClient &
  AppConfigApiClient;

export function makeApiClient() {
  return new (withChatApi(
    withOfferingApi(
      withRequestApi(
        withCompanyApi(
          withOptionApi(
            withTemplateApi(
              withBorrowerApi(
                withAgentApi(withUserApi(withAppConfigApi(BaseApiClient)))
              )
            )
          )
        )
      )
    )
  ))();
}
