import { Context, Values } from "@oursky/react-messageformat";
import React from "react";

import { Omit } from "../../utils/typeutils";

import { MPInput, MPInputProps } from "../input";

import styles from "./styles.module.scss";

export type FormattedInputProps = Omit<MPInputProps, "placeholder"> & {
  placeholderId?: string;
  palceholderValues?: Values;
};

export const FormattedInput: React.FC<FormattedInputProps> = props => {
  const { placeholderId, palceholderValues, ...rest } = props;

  return (
    <Context.Consumer>
      {({ renderToString }) => (
        <MPInput
          placeholder={
            placeholderId && renderToString(placeholderId, palceholderValues)
          }
          className={styles.placeholder}
          {...rest}
        />
      )}
    </Context.Consumer>
  );
};
