import { Plugins } from "@capacitor/core";
import { iOSHybridOnly, hybridOnly } from "../utils/platform";
import { getScrollElement } from "../utils/ionic-patch";
import { getAbsoluteTop, getOuterHeight } from "../utils/misc";
const { Keyboard } = Plugins;

export class KeyboardScrollHandler {
  contentElement: HTMLElement;
  scrollElement?: HTMLElement;

  constructor(contentElement: HTMLElement) {
    this.contentElement = contentElement;

    if (contentElement.tagName === "ION-CONTENT") {
      getScrollElement(contentElement as HTMLIonContentElement).then(
        scrollElement => {
          this.scrollElement = scrollElement;
          scrollElement.style.setProperty(
            "padding-bottom",
            `calc(var(--padding-bottom) + var(--offset-bottom) + var(--keyboard-scroll-handler-offset))`
          );
        }
      );
    }

    hybridOnly(() => {
      Keyboard.setAccessoryBarVisible({ isVisible: true }).catch(() => {});
    });

    iOSHybridOnly(() => {
      window.addEventListener("keyboardWillShow", this.keyboardWillShow);
      window.addEventListener("keyboardWillHide", this.keyboardWillHide);
    });
  }

  destroy() {
    iOSHybridOnly(() => {
      window.removeEventListener("keyboardWillShow", this.keyboardWillShow);
      window.removeEventListener("keyboardWillHide", this.keyboardWillHide);
    });
  }

  keyboardWillShow = (e: any) => {
    this.contentElement.style.setProperty(
      "--keyboard-scroll-handler-offset",
      `${e.keyboardHeight}px`
    );
    this.contentElement.style.setProperty("--keyboard-scroll-handler-on", "1");

    if (this.contentElement.shadowRoot !== null && this.scrollElement) {
      const contentElement = this.contentElement as HTMLIonContentElement;
      if (document.activeElement !== null) {
        let target = document.activeElement as HTMLElement;
        if (
          target.parentElement &&
          target.parentElement.tagName === "ION-INPUT"
        ) {
          target = target.parentElement;
        }

        const topMargin = 40;
        const bottomMargin = 20;
        const headerHeight = 100;

        const targetTop = getAbsoluteTop(target);
        const targetBottom = targetTop + getOuterHeight(target);

        const screenHeight =
          this.contentElement.offsetTop + this.contentElement.offsetHeight;

        const topAdjustment =
          this.scrollElement.scrollTop + headerHeight - (targetTop - topMargin);

        const bottomAdjustment =
          targetBottom +
          bottomMargin -
          this.scrollElement.scrollTop -
          (screenHeight - e.keyboardHeight);

        if (bottomAdjustment > 0) {
          contentElement.scrollByPoint(0, bottomAdjustment, 0);
        } else if (topAdjustment > 0) {
          contentElement.scrollByPoint(0, -topAdjustment, 0);
        }
      }
    }
  };

  keyboardWillHide = () => {
    this.contentElement.style.setProperty("--keyboard-scroll-handler-on", "0");
    this.contentElement.style.setProperty(
      "--keyboard-scroll-handler-offset",
      "0px"
    );
  };
}

export function handleNumberKeyDown(e: React.KeyboardEvent) {
  const rate: string = (e.target as HTMLInputElement).value;
  const allowNumberInputRegex = /[\d.]/;
  if (!allowNumberInputRegex.test(e.key) && e.key !== "Backspace") {
    e.preventDefault();
  }
  // target.value cannot detect users input `.`
  if (e.key === "." && (rate === "" || rate.includes("."))) {
    e.preventDefault();
  }
}
