import * as React from "react";
import LoadingOverlay from "../components/LoadingOverlay";
import { Omit } from "../utils/typeutils";

export interface LoadingContextValue {
  show: (messageId?: string, noDelay?: boolean) => void;
  dismiss: () => void;
}

export interface LoadingContextProps {
  loadingContext: LoadingContextValue;
}

export const LoadingContext = React.createContext<LoadingContextValue>(
  null as any
);

interface ProviderState {
  isOpen: boolean;
  messageId?: string;
}

export class LoadingContextProvider extends React.PureComponent<
  {},
  ProviderState
> {
  state: ProviderState = {
    isOpen: false,
  };

  displayTimer?: ReturnType<typeof setTimeout>;

  show = (messageId?: string, noDelay?: boolean): void => {
    this.displayTimer = setTimeout(
      () => {
        this.setState({
          messageId,
          isOpen: true,
        });
      },
      noDelay ? 0 : 500
    );
  };

  dismiss = (): void => {
    if (this.displayTimer) {
      clearTimeout(this.displayTimer);
    }
    this.displayTimer = undefined;
    this.setState({
      isOpen: false,
    });
  };

  render() {
    return (
      <LoadingContext.Provider
        value={{
          show: this.show,
          dismiss: this.dismiss,
        }}
      >
        <LoadingOverlay
          isOpen={this.state.isOpen}
          messageId={this.state.messageId}
        />
        {this.props.children}
      </LoadingContext.Provider>
    );
  }
}

export function withLoading<P extends LoadingContextProps>(
  Component: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof LoadingContextProps>> {
  const Wrapped: React.FC<Omit<P, keyof LoadingContextProps>> = (
    props: Omit<P, keyof LoadingContextProps>
  ) => (
    <LoadingContext.Consumer>
      {value => <Component {...props as any} loadingContext={value} />}
    </LoadingContext.Consumer>
  );

  return Wrapped;
}
