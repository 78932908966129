import React from "react";
import * as Sentry from "sentry-cordova";

import { IonApp, IonPage } from "@ionic/react";
import { FormattedMessage } from "@oursky/react-messageformat";

import styles from "./styles.module.scss";
import { MPContent } from "../MPContent";

interface Props {
  children?: React.ReactNode;
}
interface State {
  error?: Error;
}

export default class SentryBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: { [key: string]: any }) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.error) {
      return (
        <IonApp>
          <IonPage>
            <MPContent scrollY={false}>
              <div className={styles.errorMessageContainer}>
                <div className={styles.errorMessage}>
                  <h1>
                    <FormattedMessage id="sentry_boundary.error_message" />
                  </h1>
                </div>
              </div>
            </MPContent>
          </IonPage>
        </IonApp>
      );
    }

    return this.props.children;
  }
}
