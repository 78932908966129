import React, { HTMLProps, ChangeEvent } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

import { focusElement } from "../../utils/focus";
import { scrollToElement } from "../../utils/scroll";

export interface Option {
  key: string;
  value: string;
  display: string;
}

interface OwnProps {
  containerClass?: string;
  selectClass?: string;
  emptyClass?: string;
  errorClass?: string;
  isError: boolean;
  disabled?: boolean;
  shouldFocus?: boolean;
  shouldScrollTo?: boolean;
  scrollAnchor?: string;
  options: Option[];
  onValueChange: (value: string) => void;
}

export type DropdownPickerProps = OwnProps & HTMLProps<HTMLSelectElement>;

export class DropdownPicker extends React.PureComponent<DropdownPickerProps> {
  selectRef = React.createRef<HTMLSelectElement>();

  componentDidUpdate(prevProps: DropdownPickerProps) {
    if (
      prevProps.shouldFocus !== this.props.shouldFocus &&
      this.props.shouldFocus
    ) {
      focusElement(this.selectRef.current);
    }

    if (
      prevProps.shouldScrollTo !== this.props.shouldScrollTo &&
      this.props.shouldScrollTo
    ) {
      scrollToElement(this.selectRef.current, this.props.scrollAnchor);
    }
  }

  onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value || "";

    if (this.props.onValueChange) {
      this.props.onValueChange(value);
    }
  };

  renderPlaceholder = () => {
    const { placeholder } = this.props;
    if (!placeholder) {
      return null;
    }

    return (
      <option value="" disabled={true}>
        {placeholder}
      </option>
    );
  };

  renderOptions = () => {
    const { options } = this.props;
    return options.map(option => {
      return (
        <option key={option.key} value={option.value}>
          {option.display}
        </option>
      );
    });
  };

  render() {
    const {
      containerClass,
      selectClass,
      emptyClass,
      errorClass,
      isError,
      value,
      disabled,
    } = this.props;
    return (
      <div
        className={classNames(containerClass, {
          [styles.disabled]: disabled,
          [errorClass || ""]: isError && !disabled,
          [emptyClass || ""]: value === "",
        })}
      >
        <select
          ref={this.selectRef}
          className={selectClass}
          onChange={this.onChange}
          value={value}
          disabled={disabled}
        >
          {this.renderPlaceholder()}
          {this.renderOptions()}
        </select>
      </div>
    );
  }
}
