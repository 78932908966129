import React from "react";

interface Props {
  value: any;
  trigger: (value: any) => void;
}

export const ValueWatcher: React.FC<Props> = props => {
  const { value, trigger } = props;
  React.useEffect(() => {
    trigger(value);
  }, [value, trigger]);
  return null;
};
