import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonImg,
  IonPage,
} from "@ionic/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import React, { PureComponent, FormEventHandler } from "react";
import classnames from "classnames";

import { PrimaryButton, TertiaryButton } from "../../components/Button";
import { ButtonSelect, ButtonSelectItem } from "../../components/ButtonSelect";
import ErrorField from "../ErrorField";
import { FormattedInput } from "../../components/FormattedInput";
import {
  PhoneNumberInputValue,
  initialPhoneNumberInputValue,
  PhoneNumberInput,
  InputFields as PhoneNumberInputFields,
} from "../../components/PhoneNumberInput";
import { TermAndConditionView } from "../TermAndConditionView";

import { common } from "../../images";
import { UserType } from "../../models";
import { RemoteData, isLoading } from "../../utils/remoteData";

import styles from "./styles.module.scss";
import { MPContent } from "../../components/MPContent";
import { Validator, validate, ValidationError } from "../../utils/validate";
import { isPhoneNumberFormatValid } from "../../utils/e164PhoneNumber";
import { StaticPageType } from "../../types/misc";
import { EMAIL_REGEX } from "../../utils/validation";

export type InputFields = "email" | PhoneNumberInputFields;

const UserTypeItems: ButtonSelectItem<UserType>[] = [
  { labelId: "user_type.borrower", value: UserType.borrower },
  { labelId: "user_type.agent", value: UserType.agent },
];

interface OwnProps {
  phoneNumberErrorId?: string;
  emailErrorId?: string;
  remoteSignup: RemoteData<void>;

  onSignup: (values: FormValues) => void;
  onCloseClick: () => void;
  onLoginClick: () => void;
  onSkipClick: () => void;
  openStaticPage: (key: StaticPageType) => void;
}

export type SignupViewProps = OwnProps;

interface State {
  values: FormValues;
  error: ValidationError<InputFields>;
  focusField?: InputFields;
  isTermAndConditionAccepted: boolean;
  isTermAndConditionOpened: boolean;
}

export interface FormValues {
  userType: UserType;
  phoneNumberValue: PhoneNumberInputValue;
  email: string;
  isAcceptedContact: boolean;
}

export class SignupView extends PureComponent<SignupViewProps, State> {
  constructor(props: SignupViewProps) {
    super(props);

    this.state = {
      values: {
        userType: UserTypeItems[0].value,
        phoneNumberValue: initialPhoneNumberInputValue(),
        email: "",
        isAcceptedContact: false,
      },
      isTermAndConditionAccepted: false,
      isTermAndConditionOpened: false,
      error: {},
    };
  }

  toPhoneNumberInputFields(
    focusField: InputFields | undefined
  ): PhoneNumberInputFields | undefined {
    switch (focusField) {
      case "email":
      case undefined:
        return undefined;
      default:
        return focusField;
    }
  }

  onAcceptingClick = () => {
    this.setState(({ values }) => {
      return {
        values: { ...values, isAcceptedContact: !values.isAcceptedContact },
      };
    });
  };

  render() {
    const { values, error, focusField, isTermAndConditionOpened } = this.state;
    const { remoteSignup, phoneNumberErrorId, emailErrorId } = this.props;

    const phoneNumberErrorMessage =
      error["countryCode"] || error["phoneNumber"] || phoneNumberErrorId;
    const emailErrorMessage = error["email"] || emailErrorId;

    return (
      <IonPage className={styles.page}>
        <IonHeader mode="ios">
          <IonToolbar mode="ios">
            <IonButtons slot="start">
              <IonButton onClick={this.props.onCloseClick}>
                <IonImg src={common.buttonCross} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <MPContent class={styles.content} overflowScrolling="auto">
          <div className="container">
            <form noValidate onSubmit={this.handleSubmit}>
              <h1 className={styles.header}>
                <FormattedMessage id="signup.title" />
              </h1>
              <p className={styles.iam}>
                <FormattedMessage id="signup.iam" />
              </p>
              <ButtonSelect
                items={UserTypeItems}
                value={values.userType}
                onChange={this.handleUserTypeChange}
              />
              <PhoneNumberInput
                className={styles.phoneNumberInput}
                autoFocus={true}
                isCountryCodeError={!!error["countryCode"]}
                isPhoneNumberError={
                  !!(error["phoneNumber"] || phoneNumberErrorId)
                }
                value={values.phoneNumberValue}
                onChange={this.handlePhoneNumberChange}
                focusField={
                  this.toPhoneNumberInputFields(focusField) ||
                  (phoneNumberErrorId ? "phoneNumber" : undefined)
                }
              />
              <ErrorField messageId={phoneNumberErrorMessage} />
              <FormattedInput
                type="email"
                autoComplete="email"
                placeholderId="signup.email"
                isError={!!emailErrorMessage}
                value={values.email}
                onChange={this.handleEmailChange}
                // Note(jasonkit): https://github.com/facebook/react/issues/6368
                onKeyDown={this.handleEmailKeyDown}
                shouldFocus={focusField === "email"}
                autoSubmit={this.doSubmit}
              />
              <ErrorField messageId={emailErrorMessage} />
              <p className={styles.optional}>
                <FormattedMessage id="signup.optional" />
              </p>

              <div
                className={styles.acceptingContainer}
                onClick={this.onAcceptingClick}
              >
                <div
                  className={classnames(styles.checkbox, {
                    [styles.checked]: this.state.values.isAcceptedContact,
                  })}
                />
                <div className={styles.accepting}>
                  <FormattedMessage id="signup.accepting" />
                </div>
              </div>

              <p className={styles.disclaimer}>
                <FormattedMessage
                  id="signup.disclaimer"
                  values={{
                    termsAndConditions: (
                      <button
                        className={styles.link}
                        onClick={this.openTermsAndConditionsPage}
                      >
                        <FormattedMessage id="common.terms_and_conditions" />
                      </button>
                    ),
                    privacyPolicy: (
                      <button
                        className={styles.link}
                        onClick={this.openPrivacyPage}
                      >
                        <FormattedMessage id="common.privacy_policy" />
                      </button>
                    ),
                  }}
                />
              </p>

              <PrimaryButton
                class={styles.signupButton}
                type="submit"
                expand="full"
                disabled={isLoading(remoteSignup)}
              >
                <FormattedMessage id="signup.signup" />
              </PrimaryButton>
              <TertiaryButton
                class={styles.skipButton}
                expand="full"
                onClick={this.props.onSkipClick}
                disabled={isLoading(remoteSignup)}
              >
                <FormattedMessage id="signup.skip" />
              </TertiaryButton>

              <div className={styles.login}>
                <button
                  className={styles.loginButton}
                  onClick={this.handleLoginClick}
                >
                  <FormattedMessage id="signup.login" />
                </button>
              </div>
            </form>
          </div>
        </MPContent>
        <TermAndConditionView
          isOpen={isTermAndConditionOpened}
          onCancel={this.handleTermAndConditionCancel}
          onAccept={this.handleTermAndConditionAccept}
        />
      </IonPage>
    );
  }

  handleLoginClick: React.MouseEventHandler = e => {
    e.preventDefault();
    e.stopPropagation();

    this.props.onLoginClick();
  };

  handleUserTypeChange = (item: ButtonSelectItem<UserType>) => {
    this.setState(({ values }) => {
      return { values: { ...values, userType: item.value } };
    });
  };

  handlePhoneNumberChange = (value: PhoneNumberInputValue) => {
    this.setState(({ values }) => {
      return { values: { ...values, phoneNumberValue: value } };
    });
  };

  handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const email = e.currentTarget.value || "";

    this.setState(({ values }) => {
      return { values: { ...values, email } };
    });
  };

  handleEmailKeyDown: React.KeyboardEventHandler = e => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  handleSubmit: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    this.doSubmit();
  };

  handleTermAndConditionCancel = () => {
    this.setState({ isTermAndConditionOpened: false });
  };

  handleTermAndConditionAccept = () => {
    const { values } = this.state;
    this.setState({
      isTermAndConditionOpened: false,
      isTermAndConditionAccepted: true,
    });

    this.props.onSignup({
      ...values,
      email: values.email.trim(),
    });
  };

  doSubmit = () => {
    this.setState({ focusField: undefined }, () => {
      const { values } = this.state;

      if (!this.validateSignUpData(values)) {
        return;
      }
      if (this.state.isTermAndConditionAccepted) {
        this.props.onSignup({
          ...values,
          email: values.email.trim(),
        });
      } else {
        this.setState({ isTermAndConditionOpened: true });
      }
    });
  };

  validateSignUpData(values: FormValues) {
    const {
      phoneNumberValue: { countryCode, phoneNumber },
      email,
    } = values;

    const validators: Validator<InputFields>[] = [
      {
        field: "countryCode",
        invalidCondition: countryCode.length === 0,
        errorMessageId: "login.country_code.error.required",
      },
      {
        field: "phoneNumber",
        invalidCondition: phoneNumber.length === 0,
        errorMessageId: "login.phone.error.required",
      },
      {
        field: "phoneNumber",
        invalidCondition:
          !!countryCode &&
          !!phoneNumber &&
          !isPhoneNumberFormatValid(countryCode, phoneNumber),
        errorMessageId: "login.phone.error.incorrect_format",
      },
      {
        field: "countryCode",
        invalidCondition:
          !!countryCode &&
          !!phoneNumber &&
          !isPhoneNumberFormatValid(countryCode, phoneNumber),
        errorMessageId: "login.phone.error.incorrect_format",
      },
      {
        field: "email",
        invalidCondition: email ? !EMAIL_REGEX.test(email.trim()) : undefined,
        errorMessageId: "signup.error.email_invalid",
      },
    ];

    const { focusField, error, isValid } = validate(validators);

    this.setState({ error, focusField });

    return isValid;
  }

  openTermsAndConditionsPage = (e: React.MouseEvent<unknown>) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.openStaticPage("terms_and_conditions");
  };

  openPrivacyPage = (e: React.MouseEvent<unknown>) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.openStaticPage("privacy");
  };
}
