import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import React from "react";

import { BorrowerOtherIncomeInfo, OtherIncome } from "../../models";
import { ValidationError } from "../../utils/validate";

import { Option } from "../DropdownPicker";
import { FormattedDropdown } from "../FormattedDropdown";
import ErrorField from "../ErrorField";
import YesNoPicker from "../YesNoPicker";
import { FormInputFields, OtherIncomeForm } from "./OtherIncomeForm";

import styles from "./styles.module.scss";

export type InputFields =
  | "hasOtherIncome"
  | "numberOfOtherIncomes"
  | FormInputFields;

interface Props {
  workingInfoDisabled: boolean;
  error: ValidationError<InputFields>;
  borrowerOtherIncomeInfo: BorrowerOtherIncomeInfo;
  updateExistingOtherIncome: (
    index: number,
    value: Partial<OtherIncome>
  ) => void;
  updateHasOtherIncome: (hasOtherIncome: boolean) => void;
  selectNumberOfOtherIncomes?: (numberOfProperties: number) => void;
  removeExistingOtherIncome?: (index: number) => void;
  addExistingOtherIncome?: () => void;
  focusField?: InputFields;
}

interface State {
  hasOtherIncome: boolean | null;
}

export class BorrowerOtherIncomeInfoView extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasOtherIncome:
        props.borrowerOtherIncomeInfo.numberOfOtherIncomes !== null
          ? props.borrowerOtherIncomeInfo.numberOfOtherIncomes > 0
          : null,
    };
  }
  otherIncomeNumberOptions: Option[] = Array(20)
    .fill(0)
    .map((_, i) => {
      const value = (i + 1).toString();
      return {
        key: value,
        value: value,
        display: value,
      };
    });

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.workingInfoDisabled !== this.props.workingInfoDisabled &&
      this.props.workingInfoDisabled
    ) {
      this.handleUpdateHasOtherIncome(false);
    }
  }

  onSelectOtherIncomeNumber = (value: string) => {
    if (this.props.selectNumberOfOtherIncomes) {
      this.props.selectNumberOfOtherIncomes(parseInt(value, 10));
    }
  };

  handleUpdateHasOtherIncome = (value: boolean) => {
    this.setState({
      hasOtherIncome: value,
    });
    this.props.updateHasOtherIncome(value);
  };

  render() {
    const { focusField, error, addExistingOtherIncome } = this.props;
    const { hasOtherIncome } = this.state;
    const {
      otherIncomes,
      numberOfOtherIncomes,
    } = this.props.borrowerOtherIncomeInfo;
    const showAddButton =
      addExistingOtherIncome &&
      this.state.hasOtherIncome &&
      otherIncomes.length < 20;

    const otherIncomeDisabled = !this.state.hasOtherIncome;

    return (
      <div className={styles.container}>
        <div className={styles.hasOtherIncomeContainer}>
          <YesNoPicker
            labelId="borrower_other_income.hasOtherIncome"
            onClickOption={this.handleUpdateHasOtherIncome}
            value={hasOtherIncome == null ? undefined : hasOtherIncome}
            positiveLabelId="borrower_other_income.hasOtherIncome.yes"
            negativeLabelId="borrower_other_income.hasOtherIncome.no"
            shouldFocus={focusField === "hasOtherIncome"}
            disabled={this.props.workingInfoDisabled}
          />
          <ErrorField
            isShown={error["hasOtherIncome"] !== undefined}
            hiddenType="gone"
            messageId={error["hasOtherIncome"]}
          />
        </div>

        {this.props.selectNumberOfOtherIncomes && (
          <div
            className={classNames(
              styles.fieldContainer,
              styles.numberOfOtherIncomesContainer
            )}
            data-anchor="numberOfOtherIncomes"
          >
            <div
              className={classNames(styles.fieldTitle, {
                [styles.disabled]: otherIncomeDisabled,
              })}
            >
              <FormattedMessage id="borrower_other_income.numberOfOtherIncomes" />
            </div>
            <FormattedDropdown
              disabled={otherIncomeDisabled}
              containerClass={styles.dropdownContainer}
              selectClass={styles.dropdownSelect}
              errorClass={styles.dropdownError}
              emptyClass={styles.dropdownEmpty}
              isError={error["numberOfOtherIncomes"] !== undefined}
              options={this.otherIncomeNumberOptions}
              value={numberOfOtherIncomes || ""}
              onValueChange={this.onSelectOtherIncomeNumber}
              shouldFocus={focusField === "numberOfOtherIncomes"}
              shouldScrollTo={focusField === "numberOfOtherIncomes"}
              scrollAnchor="numberOfOtherIncomes"
              placeholderId={
                "borrower_other_income.numberOfOtherIncomes.placeholder"
              }
            />
            <ErrorField
              isShown={error["numberOfOtherIncomes"] !== undefined}
              hiddenType="gone"
              messageId={error["numberOfOtherIncomes"]}
            />
          </div>
        )}
        {otherIncomes.map((otherIncome, index) => (
          <OtherIncomeForm
            key={index}
            index={index}
            onChange={this.props.updateExistingOtherIncome}
            onRemove={this.props.removeExistingOtherIncome}
            otherIncome={otherIncome}
            error={error}
            focusField={
              (focusField !== "hasOtherIncome" &&
                focusField !== "numberOfOtherIncomes" &&
                focusField) ||
              undefined
            }
          />
        ))}
        {showAddButton && (
          <div className={styles.addButtonContainer}>
            <div onClick={addExistingOtherIncome} className={styles.addButton}>
              <FormattedMessage id="borrower_other_income_form.add" />
            </div>
          </div>
        )}
      </div>
    );
  }
}
