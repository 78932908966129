import { PremiseType, PropertyStatus } from "../models";

export enum RequestType {
  PersonalLoan = "loan",
  Mortgage = "mortgage",
  Exchange = "exchange",
}

export type CreateRequest =
  | CreateForeignExchangeRequest
  | CreateMortgageRequest
  | CreatePersonalLoanRequest;

interface BaseCreateRequest {
  skipProfileFilling?: boolean;
}

export interface CreateForeignExchangeRequest extends BaseCreateRequest {
  type: RequestType.Exchange;
  sellingCurrencyId: string;
  buyingCurrencyId: string;
  sellingAmount: number;
}

export interface CreateMortgageRequest extends BaseCreateRequest {
  type: RequestType.Mortgage;
  flat: string;
  floor: string;
  loanAmount: number;
  mortgageLevel: string;
  marketValue: number;
  purposingOfMortgage: string;
  nameOfBuilding: string;
  streetNameAndNumber: string;
  district: string;
  period: number;
  block: string;
  premiseType: PremiseType | null;
  propertyStatus: PropertyStatus | null;
  numOfOwner: number | null;
  rentIncome: number | null;
  numOfResident: number | null;
  filedForBankruptcy: boolean | null;
  appliedForIva: boolean | null;
  numPersonalLoanForPastTwoWeeks: number | null;
  numOfLoanEnquiriesInOneMonth: number | null;
  hasLatePayment: boolean | null;
  isTaxReturnFormAvailable: boolean | null;
  monthlyIncome?: number;
  currentLoanAmount?: number;
  tuGrade: string | null;
  allowTUCheck: boolean;
}

export interface CreatePersonalLoanRequest extends BaseCreateRequest {
  type: RequestType.PersonalLoan;
  amount: number;
  period: number;
  monthlyIncome: number;
  currentLoanAmount: number;
  purposingOfFinancing: string;
  filedForBankruptcy: boolean | null;
  appliedForIva: boolean | null;
  numPersonalLoanForPastTwoWeeks: number | null;
  numOfLoanEnquiriesInOneMonth: number | null;
  hasLatePayment: boolean | null;
  isTaxReturnFormAvailable: boolean | null;
  tuGrade: string | null;
  allowTUCheck: boolean;
}

export type QuickRequestData =
  | ForeignExchangeQuickRequestData
  | MortgageQuickRequestData
  | PersonalLoanQuickRequestData;

interface ForeignExchangeQuickRequestData {
  type: RequestType.Exchange;
  buyingCurrencyId: string;
  sellingAmount: string;
}

interface MortgageQuickRequestData {
  type: RequestType.Mortgage;
}

interface PersonalLoanQuickRequestData {
  type: RequestType.PersonalLoan;
}
