import React from "react";
import classnames from "classnames";
import numeral from "numeral";
import { FormattedMessage } from "@oursky/react-messageformat";

import {
  Chat,
  PersonalLoanChat,
  ExchangeLoanChat,
  MortgageChat,
  ChatType,
  UserType,
} from "../../models";

import styles from "./styles.module.scss";
import { formatToCurrencyAmount } from "../../utils/formatToCurrencyAmount";

interface BasicInfoViewProps {
  chat: Chat;
  userType: UserType;
}

const BasicInfoView: React.FC<BasicInfoViewProps> = props => {
  const { chat, userType } = props;
  const refNumLabel =
    userType === UserType.borrower
      ? "chatroom.offering_info.agent_id"
      : "chatroom.offering_info.borrower_id";
  const refNum =
    userType === UserType.borrower
      ? chat.offering.agent.company.licenseNo
      : chat.request.borrower.refNum;

  const chatTypeLabelMap = {
    [ChatType.personalLoan]: "chat.request.loan",
    [ChatType.mortgage]: "chat.request.mortgage",
    [ChatType.exchangeLoan]: "chat.request.exchange",
  };

  return (
    <div className={styles.basicInfo}>
      <div>
        <FormattedMessage id={chatTypeLabelMap[chat.type]} />
      </div>
      <div>
        <FormattedMessage id={refNumLabel} />
      </div>
      <div>{refNum ? `#${refNum}` : "-"}</div>
    </div>
  );
};

interface PersonalLoanOfferingInfoViewProps {
  chat: PersonalLoanChat;
  userType: UserType;
  onClickExtraInfo?: () => void;
}

const PersonalLoanOfferingInfoView: React.FC<
  PersonalLoanOfferingInfoViewProps
> = props => {
  const { chat, onClickExtraInfo } = props;
  return (
    <div className={styles.offeringInfo}>
      <div className={styles.leftContainer}>
        <BasicInfoView {...props} />
        <div className={styles.addition}>
          <label>
            <FormattedMessage id="chatroom.offering_info.addition" />
          </label>
          {chat.offering.addition || "NIL"}
        </div>
        <ExtraInfoButton onClick={onClickExtraInfo} />
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.row}>
          <div className={styles.cell}>
            <label className={styles.infoSection}>
              <FormattedMessage id="chatroom.offering_info.request" />
            </label>
            <label>
              <FormattedMessage
                id="chat.request.amount.title"
                values={{ toCurrency: "HKD" }}
              />
            </label>
            {formatToCurrencyAmount(chat.request.amount, true)}
          </div>
          <div className={styles.cell}>
            <label>
              <FormattedMessage id="chat.request.repayment_period.title" />
            </label>
            {chat.request.period > 0 ? (
              <FormattedMessage
                id="chatroom.offering_info.repayment_period"
                values={{
                  month: chat.request.period,
                }}
              />
            ) : (
              "-"
            )}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.cell}>
            <label className={styles.infoSection}>
              <FormattedMessage id="chatroom.offering_info.offering" />
            </label>
            <label>
              <FormattedMessage id="chatroom.offering_info.amount" />
            </label>
            <FormattedMessage
              id="chatroom.offering_info.amounts"
              values={{
                amountMin: numeral(chat.offering.amountMin).format(),
                amountMax: numeral(chat.offering.amountMax).format(),
              }}
            />
          </div>
          <div className={styles.cell}>
            <label>
              <FormattedMessage id="chatroom.offering_info.interest" />
            </label>
            {`${chat.offering.interestRateMin}% - ${
              chat.offering.interestRateMax
            }%`}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.cell}>
            <label>
              <FormattedMessage id="chat.request.repayment_period.title" />
            </label>
            <FormattedMessage
              id="chatroom.offering_info.repayment_periods"
              values={{
                minMonth: chat.offering.periodMin,
                maxMonth: chat.offering.periodMax,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface MortgageOfferingInfoViewProps {
  chat: MortgageChat;
  userType: UserType;
  onClickExtraInfo?: () => void;
}

const MortgageOfferingInfoView: React.FC<
  MortgageOfferingInfoViewProps
> = props => {
  const { chat, onClickExtraInfo } = props;

  return (
    <div className={styles.offeringInfo}>
      <div className={styles.leftContainer}>
        <BasicInfoView {...props} />
        <div className={styles.addition}>
          <label>
            <FormattedMessage id="chatroom.offering_info.addition" />
          </label>
          {chat.offering.addition || "NIL"}
        </div>
        <ExtraInfoButton onClick={onClickExtraInfo} />
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.row}>
          <div className={styles.cell}>
            <label className={styles.infoSection}>
              <FormattedMessage id="chatroom.offering_info.request" />
            </label>
            <label>
              <FormattedMessage id="chatroom.offering_info.market_value" />
            </label>
            {formatToCurrencyAmount(chat.request.buildingValue, true)}
          </div>
          <div className={styles.cell}>
            <label>
              <FormattedMessage id="chatroom.offering_info.amount" />
            </label>
            {formatToCurrencyAmount(chat.request.loanAmount, true)}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.cell}>
            <label className={styles.infoSection}>
              <FormattedMessage id="chatroom.offering_info.offering" />
            </label>
            <label>
              <FormattedMessage id="chatroom.offering_info.loan_amount" />
            </label>
            {numeral(chat.offering.loanAmount).format()}
          </div>
          <div className={styles.cell}>
            <label>
              <FormattedMessage id="chatroom.offering_info.interest" />
            </label>
            {`${chat.offering.interestRateMin}% - ${
              chat.offering.interestRateMax
            }%`}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.cell}>
            <label>
              <FormattedMessage id="chat.request.repayment_period.title" />
            </label>
            <FormattedMessage
              id="chatroom.offering_info.repayment_period_mortgage"
              values={{
                month: chat.offering.paymentPeriodMax,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

interface FXOfferingInfoViewProps {
  chat: ExchangeLoanChat;
  userType: UserType;
  onClickExtraInfo?: () => void;
}

const FXOfferingInfoView: React.FC<FXOfferingInfoViewProps> = props => {
  const { chat, onClickExtraInfo } = props;
  return (
    <div className={classnames(styles.offeringInfo, styles.exchange)}>
      <div className={styles.leftContainer}>
        <BasicInfoView {...props} />
        <ExtraInfoButton onClick={onClickExtraInfo} />
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.row}>
          <div className={styles.cell}>
            <label className={styles.infoSection}>
              <FormattedMessage id="chatroom.offering_info.request" />
            </label>
            <label>
              <FormattedMessage id="chat.request.currency" />
            </label>
            {`${chat.request.sellingCurrency.isoCode} - ${
              chat.request.buyingCurrency.isoCode
            }`}
          </div>
          <div className={styles.cell}>
            <label>
              <FormattedMessage
                id="chat.request.amount.title"
                values={{
                  toCurrency: chat.request.sellingCurrency.isoCode,
                }}
              />
            </label>
            {numeral(chat.request.sellingAmount).format()}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.cell}>
            <label className={styles.infoSection}>
              <FormattedMessage id="chatroom.offering_info.offering" />
            </label>
            <label>
              <FormattedMessage
                id="chat.request.amount.title"
                values={{
                  toCurrency: chat.offering.sellingCurrency.isoCode,
                }}
              />
            </label>
            {numeral(chat.offering.sellingAmount).format()}
          </div>
        </div>
      </div>
    </div>
  );
};

interface OfferingInfoViewProps {
  chat: Chat;
  userType: UserType;
  onClickExtraInfo?: () => void;
}

export const OfferingInfoView: React.FC<OfferingInfoViewProps> = props => {
  const { chat, userType, onClickExtraInfo } = props;
  switch (chat.type) {
    case ChatType.personalLoan:
      return (
        <PersonalLoanOfferingInfoView
          chat={chat}
          userType={userType}
          onClickExtraInfo={onClickExtraInfo}
        />
      );
    case ChatType.mortgage:
      return (
        <MortgageOfferingInfoView
          chat={chat}
          userType={userType}
          onClickExtraInfo={onClickExtraInfo}
        />
      );
    case ChatType.exchangeLoan:
      return (
        <FXOfferingInfoView
          chat={chat}
          userType={userType}
          onClickExtraInfo={onClickExtraInfo}
        />
      );
    default:
      throw new Error("Unexpected chat type");
  }
};

interface ExtraInfoButtonProps {
  onClick?: () => void;
}

const ExtraInfoButton: React.FC<ExtraInfoButtonProps> = props => {
  return (
    <div onClick={props.onClick} className={styles.extraInfoButton}>
      <FormattedMessage id="chatroom.offering_info.extra_info" />
    </div>
  );
};
