import * as yup from "yup";
import {
  OfferingType,
  OfferingForm,
  CreateForeignExchangeForm,
  CreatePersonalLoanForm,
  CreateMortgageForm,
} from "../models/offering";

export const foreignExchangeFormSchema: yup.Schema<
  CreateForeignExchangeForm
> = yup
  .object({
    type: yup
      .mixed()
      .oneOf([OfferingType.exchangeLoan])
      .required(),
    sellingCurrencyId: yup.string().required(),
    buyingCurrencyId: yup.string().required(),
    sellingAmount: yup.number().required(),
    validDays: yup.number().required(),
  })
  .camelCase();

export const personalLoanFormSchema: yup.Schema<CreatePersonalLoanForm> = yup
  .object({
    type: yup
      .mixed()
      .oneOf([OfferingType.personalLoan])
      .required(),
    amountMin: yup.number().required(),
    amountMax: yup.number().required(),
    interestRateMin: yup.string().required(),
    interestRateMax: yup.string().required(),
    periodMin: yup.number().required(),
    periodMax: yup.number().required(),
    isRequiredEmployed: yup.boolean().required(),
    isRequiredProperty: yup.boolean().required(),
    addition: yup.string(),
    validDays: yup.number().required(),
  })
  .camelCase();

export const mortgageFormSchema: yup.Schema<CreateMortgageForm> = yup
  .object({
    type: yup
      .mixed()
      .oneOf([OfferingType.mortgage])
      .required(),
    loanAmount: yup.number().required(),
    paymentPeriodMax: yup.number().required(),
    interestRateMin: yup.string().required(),
    interestRateMax: yup.string().required(),
    isRequiredEmployed: yup.boolean().required(),
    addition: yup.string(),
    validDays: yup.number().required(),
  })
  .camelCase();

export const offeringFormSchema = yup
  .object<OfferingForm>()
  .when(".type", (type: any) => {
    switch (type) {
      case OfferingType.exchangeLoan:
        return foreignExchangeFormSchema;
      case OfferingType.mortgage:
        return mortgageFormSchema;
      case OfferingType.personalLoan:
        return personalLoanFormSchema;
      default:
        throw new Error(`Unexpected type = ${type}`);
    }
  });
