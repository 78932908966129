import React, { PureComponent } from "react";
import classNames from "classnames";
import { FormattedMessage } from "@oursky/react-messageformat";
import { IonHeader, IonToolbar, IonPage } from "@ionic/react";

import styles from "./styles.module.scss";
import { ListView } from "./ListView";

import { MPContent } from "../MPContent";

import { Mode } from "../../screens/BorrowerOfferingListScreen";
import { Page } from "../../utils/pagination";
import { Offering } from "../../models";

interface Props {
  typeId: string;
  modeSelected: Mode;
  handleModeChange: (btn: Mode) => void;
  fetchPersonalLoansPage: (cursor?: string) => Promise<Page<Offering>>;
  fetchMortgagesPage: (cursor?: string) => Promise<Page<Offering>>;
  onApply: (offering: Offering) => void;
  offeringListVersion: number;
}

const images = {
  logo: require("../../images/logo-with-name.svg"),
};

class BorrowerOfferingListView extends PureComponent<Props> {
  handlePersonalLoanClicked = () => {
    this.props.handleModeChange(Mode.PersonalLoan);
  };

  handleMortgageClicked = () => {
    this.props.handleModeChange(Mode.Mortgage);
  };

  render() {
    return (
      <IonPage className={styles.outerContainer}>
        <IonHeader mode="ios">
          <IonToolbar mode="ios">
            <div className={styles.logoContainer}>
              <img src={images.logo} alt="logo" />
            </div>
          </IonToolbar>
          <div className={styles.buttonContainer}>
            <button
              className={classNames(styles.button, {
                [styles.buttonActive]:
                  this.props.modeSelected === Mode.PersonalLoan,
              })}
              onClick={this.handlePersonalLoanClicked}
            >
              <FormattedMessage id="offering.borrower_offering_list.personal_loan" />
            </button>
            <button
              className={classNames(styles.button, {
                [styles.buttonActive]:
                  this.props.modeSelected === Mode.Mortgage,
              })}
              onClick={this.handleMortgageClicked}
            >
              <FormattedMessage id="offering.borrower_offering_list.mortgage" />
            </button>
          </div>
        </IonHeader>
        <MPContent className={styles.list}>
          <ListView
            offeringListVersion={this.props.offeringListVersion}
            fetchPersonalLoansPage={this.props.fetchPersonalLoansPage}
            fetchMortgagesPage={this.props.fetchMortgagesPage}
            mode={this.props.modeSelected}
            onApply={this.props.onApply}
          />
        </MPContent>
      </IonPage>
    );
  }
}

export default BorrowerOfferingListView;
