import { User, UserData } from "../models/user";

export type WhoamiResponseUser = Registered | NotComplete | UserNotFound;

export enum WhoamiResponseType {
  registered = "registered",
  notComplete = "notComplete",
  userNotFound = "userNotFound",
}

export interface Registered {
  type: WhoamiResponseType.registered;
  value: User;
}

export function Registered(user: User): Registered {
  return {
    type: WhoamiResponseType.registered,
    value: user,
  };
}

export function isRegistered(
  response: WhoamiResponseUser
): response is Registered {
  return response.type === WhoamiResponseType.registered;
}

export interface NotComplete {
  type: WhoamiResponseType.notComplete;
  value: UserData;
}

export function NotComplete(userData: UserData): NotComplete {
  return {
    type: WhoamiResponseType.notComplete,
    value: userData,
  };
}

export function isNotComplete(
  response: WhoamiResponseUser
): response is NotComplete {
  return response.type === WhoamiResponseType.notComplete;
}

export interface UserNotFound {
  type: WhoamiResponseType.userNotFound;
  value: undefined;
}

export function UserNotFound(): UserNotFound {
  return { type: WhoamiResponseType.userNotFound, value: undefined };
}
