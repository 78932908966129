import React from "react";

import { RequestApiClient } from "../apiClient";
import { PubSubService, EventHandlerUnsubscriber } from "../services/pubsub";
import {
  CreateForeignExchangeRequest,
  CreateMortgageRequest,
  CreatePersonalLoanRequest,
} from "../types/request";
import { ListType } from "../types/misc";
import {
  ExchangeLoanRequest,
  MortgageRequest,
  PersonalLoanRequest,
  Request,
} from "../models";
import { Page } from "../utils/pagination";
import { Omit } from "../utils/typeutils";

export interface RequestContextValue {
  agentRequestListVersion: number;
  borrowerRequestListVersion: number;

  createForeignExchangeRequest: (
    formValues: CreateForeignExchangeRequest
  ) => Promise<ExchangeLoanRequest>;
  createMortgageRequest: (
    formValues: CreateMortgageRequest
  ) => Promise<MortgageRequest>;
  createPersonalLoanRequest: (
    formValues: CreatePersonalLoanRequest
  ) => Promise<PersonalLoanRequest>;
  listBorrowerRequestsForAgent: (cursor?: string) => Promise<Page<Request>>;
  listBorrowerRequests: (
    cursor?: string,
    type?: ListType
  ) => Promise<Page<Request>>;
  cancelBorrowerRequest: (requestId: string) => Promise<void>;
  refreshAgentRequestList: () => void;
  refreshBorrowerRequestList: () => void;
}

const RequestContext = React.createContext<RequestContextValue>(null as any);
export { RequestContext };

export interface RequestContextProps {
  requestContext: RequestContextValue;
}

interface Props {
  apiClient: RequestApiClient;
  pubsubService: PubSubService;
}

interface State {
  agentRequestListVersion: number;
  borrowerRequestListVersion: number;
}

export class RequestContextProvider extends React.PureComponent<Props, State> {
  private eventHandlerUnsubscribers: EventHandlerUnsubscriber[] = [];

  state: State = {
    agentRequestListVersion: 0,
    borrowerRequestListVersion: 0,
  };

  componentDidMount() {
    this.eventHandlerUnsubscribers = [
      this.props.pubsubService.registerEventHandler(
        "block",
        this.refreshAgentRequestList
      ),
      this.props.pubsubService.registerEventHandler(
        "unblock",
        this.refreshAgentRequestList
      ),
    ];
  }

  componentWillUnmount() {
    for (const unsubscriber of this.eventHandlerUnsubscribers) {
      unsubscriber();
    }
  }

  refreshAgentRequestList = () => {
    this.setState(
      (prevState: State): State => {
        return {
          ...prevState,
          agentRequestListVersion: prevState.agentRequestListVersion + 1,
        };
      }
    );
  };

  refreshBorrowerRequestList = () => {
    this.setState(
      (prevState: State): State => {
        return {
          ...prevState,
          borrowerRequestListVersion: prevState.borrowerRequestListVersion + 1,
        };
      }
    );
  };

  render() {
    const { apiClient, children } = this.props;
    const { agentRequestListVersion, borrowerRequestListVersion } = this.state;

    return (
      <RequestContext.Provider
        value={{
          agentRequestListVersion,
          borrowerRequestListVersion,
          createForeignExchangeRequest: apiClient.createForeignExchangeRequest.bind(
            apiClient
          ),
          createMortgageRequest: apiClient.createMortgageRequest.bind(
            apiClient
          ),
          createPersonalLoanRequest: apiClient.createPersonalLoanRequest.bind(
            apiClient
          ),
          listBorrowerRequestsForAgent: apiClient.listBorrowerRequestsForAgent.bind(
            apiClient
          ),
          listBorrowerRequests: apiClient.listBorrowerRequests.bind(apiClient),
          cancelBorrowerRequest: apiClient.cancelBorrowerRequest.bind(
            apiClient
          ),
          refreshAgentRequestList: this.refreshAgentRequestList,
          refreshBorrowerRequestList: this.refreshBorrowerRequestList,
        }}
      >
        {children}
      </RequestContext.Provider>
    );
  }
}

export function withRequest<P extends RequestContextProps>(
  Component: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof RequestContextProps>> {
  const Wrapped: React.FC<Omit<P, keyof RequestContextProps>> = (
    props: Omit<P, keyof RequestContextProps>
  ) => (
    <RequestContext.Consumer>
      {requestContext => (
        <Component {...props as any} requestContext={requestContext} />
      )}
    </RequestContext.Consumer>
  );

  return Wrapped;
}
