import { DateTime } from "luxon";
import React, { PureComponent } from "react";

import MobileDatePicker, {
  DateConfig as DateConfigType,
} from "react-mobile-datepicker";
import { Context as LocaleContext } from "@oursky/react-messageformat";

import { FormattedInput } from "../FormattedInput";

import styles from "./styles.module.scss";
import classNames from "classnames";

import { isPlatform } from "../../utils/platform";

import { withLocale, LocaleContextProps } from "../../context/LocaleContext";

export interface DatePickerProps extends LocaleContextProps {
  containerClass?: string;
  inputClass?: string;
  placeholderId?: string;
  value?: DateTime | null;
  onChange?: (date?: DateTime) => void;
  shouldFocus?: boolean;
  shouldScrollTo?: boolean;
  scrollAnchor?: string;
  disabled?: boolean;
}

interface State {
  isOpen: boolean;
}

class DatePickerImpl extends PureComponent<DatePickerProps, State> {
  state: State = {
    isOpen: false,
  };

  componentDidMount() {}

  render() {
    const {
      inputClass,
      placeholderId,
      value,
      containerClass,
      shouldFocus,
      shouldScrollTo,
      scrollAnchor,
      disabled,
    } = this.props;

    const { isOpen } = this.state;

    const isIOS = isPlatform("ios");

    return (
      <div className={containerClass}>
        <FormattedInput
          className={classNames(inputClass, styles.field)}
          placeholderId={placeholderId}
          value={value ? value.toISODate() : undefined}
          required //required and aria-required are to show placeholder
          aria-required="true" //with css since this input type not support placeholder by default
          shouldFocus={shouldFocus}
          shouldScrollTo={shouldScrollTo}
          scrollAnchor={scrollAnchor}
          disabled={disabled}
          {...(isIOS
            ? {
                type: "date",
                onChange: this.handleClick,
              }
            : {
                onFocus: this.onFocus,
                onChange: this.noop,
              })}
        />
        {!isIOS && (
          <LocaleContext.Consumer>
            {({ renderToString }) => (
              <MobileDatePicker
                isOpen={isOpen}
                value={value ? value.toJSDate() : undefined}
                dateConfig={this.dateConfig}
                onCancel={this.closePicker}
                onSelect={this.changeDate}
                confirmText={renderToString("common.done")}
                cancelText={renderToString("common.cancel")}
                min={new Date(1911, 0, 1)}
              />
            )}
          </LocaleContext.Consumer>
        )}
      </div>
    );
  }

  get dateConfig(): DateConfigType {
    return {
      year: {
        format: "YYYY",
        step: 1,
      },
      month: {
        format: this.formatMonth,
        step: 1,
      },
      date: {
        format: "D",
        step: 1,
      },
    };
  }

  formatMonth = (date: Date) => {
    return DateTime.fromJSDate(date)
      .setLocale(this.props.localeContext.locale)
      .toFormat("LLL");
  };

  onFocus: React.ChangeEventHandler<HTMLInputElement> = e => {
    e.currentTarget.blur();
    this.setState({ isOpen: true });
  };

  closePicker = () => {
    this.setState({ isOpen: false });
  };

  changeDate = (date: Date) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(DateTime.fromJSDate(date));
    }
    this.closePicker();
  };

  handleClick: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { onChange } = this.props;
    const date: DateTime = DateTime.fromFormat(
      e.currentTarget.value,
      "yyyy-MM-dd"
    );

    if (onChange) {
      onChange(e.currentTarget.value ? date : undefined);
    }
  };

  noop = () => {};
}

export const DatePicker = withLocale(DatePickerImpl);
