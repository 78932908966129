import { RefObject, useCallback, useEffect, useRef } from "react";

export default function useLongPress(
  duration: number,
  onLongPress: () => void,
  elRef: RefObject<HTMLElement>
): void {
  const holdTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const pressDown = useCallback(() => {
    if (holdTimer.current != null) {
      clearTimeout(holdTimer.current);
      holdTimer.current = null;
    }
    holdTimer.current = setTimeout(() => {
      onLongPress();
    }, duration);
  }, [duration, onLongPress]);

  const pressUp = useCallback(() => {
    if (holdTimer.current) {
      clearTimeout(holdTimer.current);
      holdTimer.current = null;
    }
  }, []);

  useEffect(() => {
    const el = elRef.current;
    if (el) {
      el.addEventListener("mousedown", pressDown);
      el.addEventListener("mouseup", pressUp);
      el.addEventListener("mouseleave", pressUp);
      el.addEventListener("touchstart", pressDown);
      el.addEventListener("touchend", pressUp);
      el.addEventListener("touchcancel", pressUp);
    }
    return () => {
      if (holdTimer.current != null) {
        clearTimeout(holdTimer.current);
        holdTimer.current = null;
      }
      if (el) {
        el.removeEventListener("mousedown", pressDown);
        el.removeEventListener("mouseup", pressUp);
        el.removeEventListener("mouseleave", pressUp);
        el.removeEventListener("touchstart", pressDown);
        el.removeEventListener("touchend", pressUp);
        el.removeEventListener("touchcancel", pressUp);
      }
    };
  }, [pressDown, pressUp, elRef]);
}
