import React from "react";
import { RouteComponentProps } from "react-router";

import LoginView from "../components/LoginView";
import {
  PhoneNumberInputValue,
  initialPhoneNumberInputValue,
  InputFields,
} from "../components/PhoneNumberInput";

import { withUser, UserContextProps } from "../context/UserContext";
import {
  withErrorAlert,
  ErrorAlertContextProps,
} from "../context/ErrorAlertContext";
import { withLoading, LoadingContextProps } from "../context/LoadingContext";

import {
  e164PhoneNumber,
  isPhoneNumberFormatValid,
} from "../utils/e164PhoneNumber";
import { Initial, Loading, RemoteData, Success } from "../utils/remoteData";

import { APIError, APIErrorCode } from "../error";

import {
  SMSVerificationLocationState,
  AuthDataType,
} from "./SMSVerificationScreen";
import { ValidationError, Validator, validate } from "../utils/validate";

type Props = LoadingContextProps &
  ErrorAlertContextProps &
  UserContextProps &
  RouteComponentProps;

interface State {
  remoteSendSms: RemoteData<string>;
  phoneNumberValue: PhoneNumberInputValue;
  phoneNumberErrorId?: string;
  error: ValidationError<InputFields>;
  focusField?: InputFields;
}

class LoginScreenImpl extends React.PureComponent<Props, State> {
  state: State = {
    remoteSendSms: Initial(),
    phoneNumberValue: initialPhoneNumberInputValue(),
    error: {},
  };

  onLoginButtonClick = () => {
    this.setState({ focusField: undefined }, () => {
      const { history } = this.props;
      const { countryCode, phoneNumber } = this.state.phoneNumberValue;

      this.setState({ phoneNumberErrorId: undefined });

      if (!this.validateLoginForm()) {
        return;
      }

      const phone = e164PhoneNumber(countryCode, phoneNumber).value;

      this.setState({
        remoteSendSms: Loading(),
      });
      this.props.loadingContext.show();

      this.props.userContext
        .sendLoginSms(phone)
        .then(verificationCodeId => {
          this.setState({
            remoteSendSms: Success(verificationCodeId),
          });

          const locationState: SMSVerificationLocationState = {
            authData: {
              type: AuthDataType.login,
              countryCode,
              phoneNumber,
              verificationCodeId,
            },
          };
          history.push("/login/sms-verification", locationState);
        })
        .catch(error => {
          if (error instanceof APIError) {
            if (error.code === APIErrorCode.userNotFound) {
              this.setState({
                phoneNumberErrorId: "login.error.user_not_found",
                remoteSendSms: Initial(),
              });
            } else {
              this.props.errorAlertContext.show(error);
              this.setState({ remoteSendSms: Initial() });
            }
          }
        })
        .finally(this.props.loadingContext.dismiss);
    });
  };

  validateLoginForm() {
    const { countryCode, phoneNumber } = this.state.phoneNumberValue;

    const validators: Validator<InputFields>[] = [
      {
        field: "countryCode",
        invalidCondition: countryCode.length === 0,
        errorMessageId: "login.country_code.error.required",
      },
      {
        field: "phoneNumber",
        invalidCondition: phoneNumber.length === 0,
        errorMessageId: "login.phone.error.required",
      },
      {
        field: "phoneNumber",
        invalidCondition:
          !!countryCode &&
          !!phoneNumber &&
          !isPhoneNumberFormatValid(countryCode, phoneNumber),
        errorMessageId: "login.phone.error.incorrect_format",
      },
      {
        field: "countryCode",
        invalidCondition:
          !!countryCode &&
          !!phoneNumber &&
          !isPhoneNumberFormatValid(countryCode, phoneNumber),
        errorMessageId: "login.phone.error.incorrect_format",
      },
    ];

    const { focusField, error, isValid } = validate(validators);

    this.setState({ error, focusField });

    return isValid;
  }

  onSignupButtonClick = () => {
    this.props.history.push("/signup");
  };

  onCloseButtonClick = () => {
    this.props.history.push("/close");
  };

  onPhoneNumberValueChange = (value: PhoneNumberInputValue) => {
    this.setState({ phoneNumberValue: value });
  };

  render() {
    const {
      remoteSendSms,
      phoneNumberValue,
      phoneNumberErrorId,
      focusField,
      error,
    } = this.state;

    return (
      <LoginView
        remoteSendSms={remoteSendSms}
        phoneNumberValue={phoneNumberValue}
        phoneNumberErrorId={phoneNumberErrorId}
        onCloseClick={this.onCloseButtonClick}
        onLoginClick={this.onLoginButtonClick}
        onSignupClick={this.onSignupButtonClick}
        onPhoneNumberValueChange={this.onPhoneNumberValueChange}
        focusField={focusField}
        error={error}
      />
    );
  }
}

export const LoginScreen = withLoading(
  withErrorAlert(withUser(LoginScreenImpl))
);

export default LoginScreen;
