import React, { PureComponent } from "react";

import { Request } from "../../models";
import { Page } from "../../utils/pagination";

import { MPContent } from "../MPContent";
import { InfiniteScrollView } from "../InfiniteListView";

import { RequestItem } from "./RequestItem";
import styles from "./styles.module.scss";

interface Props {
  fetchPage: (cursor?: string) => Promise<Page<Request>>;
  onClickRequest: (request: Request) => void;
  listVersion: number;
}

export class AgentRequestListView extends PureComponent<Props> {
  render() {
    return (
      <MPContent>
        <InfiniteScrollView
          key={this.props.listVersion}
          listClassName={styles.list}
          emptyMessageId="agent.request.empty"
          fetchPage={this.props.fetchPage}
          renderItem={this.renderItem}
          threshold={500}
        />
      </MPContent>
    );
  }

  renderItem = (request: Request) => {
    return (
      <RequestItem
        key={request.id}
        request={request}
        onClickRequest={this.props.onClickRequest}
      />
    );
  };
}
