import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router";

import { withOptions, OptionsProps } from "../context/OptionsContext";
import { withLoading, LoadingContextProps } from "../context/LoadingContext";
import {
  withErrorAlert,
  ErrorAlertContextProps,
} from "../context/ErrorAlertContext";
import { withNav, OurNavContextProps } from "../navigation/OurNavContext";
import { withChat, ChatContextProps } from "../context/ChatContext";
import { withRequest, RequestContextProps } from "../context/RequestContext";
import AgentOfferingCreateView from "../components/AgentOfferingCreateView";
import { Option as DPOption } from "../components/DropdownPicker";
import {
  CreateForeignExchangeForm,
  CreateForeignExchangeOffering,
  CreateMortgageForm,
  CreateMortgageOffering,
  CreatePersonalLoanForm,
  CreatePersonalLoanOffering,
  OfferingFromMode,
  Request,
  RequestType,
  Chat,
} from "../models";
import { Initial, Loading, RemoteData, Success } from "../utils/remoteData";

export interface AgentOfferingCreateLocationState {
  request: Request;
}

type Props = OurNavContextProps &
  LoadingContextProps &
  ErrorAlertContextProps &
  OptionsProps &
  ChatContextProps &
  RequestContextProps &
  RouteComponentProps<{}, {}, AgentOfferingCreateLocationState | undefined>;

interface State {
  remoteCreate: RemoteData<void>;
  selectedRequest?: Request;
  selectedRequestType: RequestType;
}

class AgentOfferingCreateScreen extends PureComponent<Props, State> {
  private isCallingAPI = false;

  state: State = {
    remoteCreate: Initial(),
    selectedRequestType: RequestType.personalLoan,
  };

  componentDidMount() {
    this.setRequestIfGetLocationState();
  }

  setRequestIfGetLocationState = () => {
    const { location } = this.props;

    if (location.state && location.state.request) {
      this.setState({
        selectedRequest: location.state.request,
        selectedRequestType: location.state.request.type,
      });
    }
  };

  getCurrencyOptions = (): DPOption[] => {
    return this.props.options.currencyOptions.map(currency => {
      return {
        key: currency.id,
        value: currency.id,
        display: currency.isoCode,
      };
    });
  };

  onSubmitForeignExchange = (
    values: CreateForeignExchangeForm
  ): Promise<boolean> => {
    const { selectedRequest } = this.state;
    if (!selectedRequest || selectedRequest.type !== RequestType.exchangeLoan) {
      throw new Error(`Unexpected request, incorrect type or no selected`);
    }

    const offerValues: CreateForeignExchangeOffering = {
      requestId: selectedRequest.id,
      buyingAmount: selectedRequest.sellingAmount,
      ...values,
    };

    return this.doSubmit(this.props.chatContext.agentCreateFXChat(offerValues));
  };

  onSubmitMortgage = (values: CreateMortgageForm): Promise<boolean> => {
    const { selectedRequest } = this.state;
    if (!selectedRequest || selectedRequest.type !== RequestType.mortgage) {
      throw new Error(`Unexpected request, incorrect type or no selected`);
    }

    const offerValues: CreateMortgageOffering = {
      requestId: selectedRequest.id,
      ...values,
    };

    return this.doSubmit(
      this.props.chatContext.agentCreateMortgageChat(offerValues)
    );
  };

  onSubmitPersonalLoan = (values: CreatePersonalLoanForm): Promise<boolean> => {
    const { selectedRequest } = this.state;
    if (!selectedRequest || selectedRequest.type !== RequestType.personalLoan) {
      throw new Error(`Unexpected request, incorrect type or no selected`);
    }

    const offerValues: CreatePersonalLoanOffering = {
      requestId: selectedRequest.id,
      ...values,
    };

    return this.doSubmit(
      this.props.chatContext.agentCreatePersonalLoanChat(offerValues)
    );
  };

  private doSubmit = (lambdaCall: Promise<Chat>): Promise<boolean> => {
    if (this.isCallingAPI) return Promise.resolve(false);
    this.setState({ remoteCreate: Loading() });
    this.props.loadingContext.show();

    this.isCallingAPI = true;
    return lambdaCall
      .then(this.props.chatContext.enterConversation)
      .then(chat => {
        this.setState({ remoteCreate: Success(undefined) }, () => {
          this.props.navContext.goBack();
          this.props.navContext.navigate("/chat");
          this.props.navContext.navigate("/chat/chatroom", {
            conversationId: chat.conversationId,
          });
          setTimeout(this.props.requestContext.refreshAgentRequestList, 500);
        });
        return true;
      })
      .catch(error => {
        this.props.errorAlertContext.show(error);
        this.setState({ remoteCreate: Initial() });
        return false;
      })
      .finally(() => {
        this.props.loadingContext.dismiss();
        this.isCallingAPI = false;
      });
  };

  onRequestTypeChange = (type: string) => {
    this.setState({ selectedRequestType: type as RequestType });
  };

  render() {
    const { remoteCreate, selectedRequest, selectedRequestType } = this.state;
    return (
      <AgentOfferingCreateView
        mode={OfferingFromMode.newOffering}
        currencyOptions={this.getCurrencyOptions()}
        remoteCreate={remoteCreate}
        selectedRequest={selectedRequest}
        selectedRequestType={selectedRequestType}
        onRequestTypeChange={this.onRequestTypeChange}
        onSubmitForeignExchange={this.onSubmitForeignExchange}
        onSubmitMortgage={this.onSubmitMortgage}
        onSubmitPersonalLoan={this.onSubmitPersonalLoan}
      />
    );
  }
}

export default withNav(
  withLoading(
    withErrorAlert(
      withChat(withOptions(withRequest(AgentOfferingCreateScreen)))
    )
  )
);
