import React, { useCallback, useRef } from "react";
import { IonSpinner } from "@ionic/react";
import { DateTime } from "luxon";
import classnames from "classnames";
import { FormattedMessage } from "@oursky/react-messageformat";
import Linkify from "react-linkify";
import { Plugins } from "@capacitor/core";

import { InfiniteScroll } from "../InfiniteListView/InfiniteScroll";
import RichTextView from "../RichTextView";

import {
  DeepLinkHandlerContextProps,
  deepLinkProtocol,
  withDeepLinkHandler,
} from "../../context/DeepLinkHandlerContext";

import { Message, UserType } from "../../models";
import useLongPress from "../../utils/longPress";
import { EMAIL_REGEX } from "../../utils/validation";
import { generateUniqueId } from "../../utils/uniqueId";

import styles from "./styles.module.scss";

const { App } = Plugins;

type BubblePosition = "left" | "right";

interface TextBubble {
  type: "text";
  position: BubblePosition;
  body: string;
}

interface ImageBubble {
  type: "image";
  position: BubblePosition;
  url: string;
}

interface DocumentBubble {
  type: "document";
  position: BubblePosition;
  url: string;
}

interface DateBubble {
  type: "date";
  date: DateTime;
}

interface StatusBubble {
  type: "status";
  isCompleted: boolean;
}

interface HeaderBubble {
  type: "header";
  headerMessage: string;
}

interface RichTextBubble {
  type: "richText";
  position: BubblePosition;
  body: string;
}

type Bubble =
  | TextBubble
  | ImageBubble
  | DocumentBubble
  | DateBubble
  | StatusBubble
  | HeaderBubble
  | RichTextBubble;

type Props = {
  messages: Message[];
  userType: UserType;
  hasMoreMessage: boolean;
  fetchOldMessages: () => Promise<void>;
  onOpenImage: (url: string) => void;
  onOpenDocument: (url: string) => void;
  chatroomHeaderMessage: string;
  onTextMessageContextMenu: (message: string) => void;
} & DeepLinkHandlerContextProps;

class MessageListImpl extends React.PureComponent<Props> {
  getBubbles(): Bubble[] {
    const { messages, userType, chatroomHeaderMessage } = this.props;

    if (messages.length === 0) {
      return [
        {
          type: "header",
          headerMessage: chatroomHeaderMessage,
        },
      ];
    }

    return messages.reduce<{
      bubbles: Bubble[];
      last?: Message;
    }>(
      (acc: { bubbles: Bubble[]; last?: Message }, cur: Message) => {
        const bubblesToAdd: Bubble[] = [];

        let bubble: Bubble;

        if (cur.attachmentUrl && cur.attachmentType) {
          bubble = {
            type: cur.attachmentType as any,
            position: cur.userType === userType ? "right" : "left",
            url: cur.attachmentUrl,
          };
        } else if (
          cur.isCompleted !== undefined &&
          !cur.referralAgreementStatus
        ) {
          bubble = {
            type: "status",
            isCompleted: cur.isCompleted,
          };
        } else if (cur.isRichText) {
          bubble = {
            type: "richText",
            position: cur.userType === userType ? "right" : "left",
            body: cur.body,
          };
        } else {
          bubble = {
            type: "text",
            position: cur.userType === userType ? "right" : "left",
            body: cur.body,
          };
        }

        if (
          !acc.last ||
          !acc.last.timestamp
            .startOf("day")
            .equals(cur.timestamp.startOf("day"))
        ) {
          bubblesToAdd.push({
            type: "date",
            date: cur.timestamp,
          });

          if (!acc.last) {
            bubblesToAdd.push({
              type: "header",
              headerMessage: chatroomHeaderMessage,
            });
          }
        }

        bubblesToAdd.push(bubble);

        return {
          bubbles: acc.bubbles.concat(bubblesToAdd),
          last: cur,
        };
      },
      {
        bubbles: [] as Bubble[],
      }
    ).bubbles;
  }

  handleInfinteScroll = async (complete: () => void) => {
    await this.props.fetchOldMessages();
    complete();
  };

  handleLinkClick = (link: string) => {
    if (EMAIL_REGEX.exec(link)) {
      App.openUrl({ url: `mailto:${link}` });
    } else {
      this.props.deepLinkHandlerContext.handleUrl(link);
    }
  };

  renderBubbleView(bubble: Bubble, index: number) {
    switch (bubble.type) {
      case "image":
        return (
          <ImageBubbleView
            key={index}
            bubble={bubble}
            onOpenImage={this.props.onOpenImage}
          />
        );
      case "document":
        return (
          <DocumentBubbleView
            key={index}
            bubble={bubble}
            onOpenDocument={this.props.onOpenDocument}
          />
        );
      case "text":
        return (
          <TextBubbleView
            key={index}
            bubble={bubble}
            onLinkClick={this.handleLinkClick}
            onContextMenu={this.props.onTextMessageContextMenu}
          />
        );
      case "richText":
        return (
          <RichTextBubbleView
            key={index}
            bubble={bubble}
            onContextMenu={this.props.onTextMessageContextMenu}
          />
        );
      case "date":
        return <DateBubbleView key={index} bubble={bubble} />;
      case "status":
        return <StatusBubbleView key={index} bubble={bubble} />;
      case "header":
        return <HeaderBubbleView key={index} bubble={bubble} />;
      default:
        return null;
    }
  }

  render() {
    return (
      <div className={styles.messageList}>
        <InfiniteScroll
          threshold={400}
          fetch={this.handleInfinteScroll}
          disabled={!this.props.hasMoreMessage}
          position="top"
        >
          <div className={styles.loadingContainer}>
            <IonSpinner />
          </div>
        </InfiniteScroll>
        {this.getBubbles().map((bubble, index) =>
          this.renderBubbleView(bubble, index)
        )}
      </div>
    );
  }
}

export const MessageList = withDeepLinkHandler(MessageListImpl);

export const ImageBubbleView: React.FC<{
  bubble: ImageBubble;
  onOpenImage: (url: string) => void;
}> = props => {
  const { bubble, onOpenImage } = props;

  const onImageClick = React.useCallback(() => {
    onOpenImage(bubble.url);
  }, [bubble, onOpenImage]);

  return (
    <div
      className={classnames(styles.bubble, {
        [styles.left]: bubble.position === "left",
        [styles.right]: bubble.position === "right",
      })}
    >
      <div className={styles.image}>
        <img src={bubble.url} alt="attachment" onClick={onImageClick} />
      </div>
    </div>
  );
};

export const DocumentBubbleView: React.FC<{
  bubble: DocumentBubble;
  onOpenDocument: (url: string) => void;
}> = props => {
  const { bubble, onOpenDocument } = props;

  const onDocumentClick = React.useCallback(() => {
    onOpenDocument(bubble.url);
  }, [bubble, onOpenDocument]);

  const documentPathParts = bubble.url.split("?")[0].split("/");
  const documentName = decodeURIComponent(
    documentPathParts[documentPathParts.length - 1].substr(37)
  );

  return (
    <div
      className={classnames(styles.bubble, {
        [styles.left]: bubble.position === "left",
        [styles.right]: bubble.position === "right",
      })}
    >
      <div className={styles.document} onClick={onDocumentClick}>
        <h1>{documentName}</h1>
      </div>
    </div>
  );
};

export const TextBubbleView: React.FC<{
  bubble: TextBubble;
  onLinkClick: (link: string) => void;
  onContextMenu: (string: string) => void;
}> = props => {
  const { bubble, onLinkClick, onContextMenu } = props;

  const textAreaRef = useRef<HTMLDivElement>(null);

  const handleLongPress = useCallback(() => {
    onContextMenu(bubble.body);
  }, [onContextMenu, bubble]);

  useLongPress(500, handleLongPress, textAreaRef);

  const renderTextBubbleLink = useCallback(
    (href, text, key) => (
      <TextBubbleLink
        link={reviveDeepLinkProtocol(href)}
        text={reviveDeepLinkProtocol(text)}
        key={key}
        onClick={onLinkClick}
      />
    ),
    [onLinkClick]
  );

  const body = React.useMemo(() => {
    // https://blog.miniasp.com/post/2019/01/02/Common-Regex-patterns-for-Unicode-characters
    const chineseSplittingRegExp = new RegExp("([\u4E00-\u9FFF]+)");
    return replaceDeepLinkProtocol(bubble.body)
      .split("\n")
      .map((line, index) => {
        return (
          <div key={index}>
            {line.split(chineseSplittingRegExp).map((token, i) => (
              <Linkify key={i} componentDecorator={renderTextBubbleLink}>
                {token}
              </Linkify>
            ))}
          </div>
        );
      });
  }, [bubble, renderTextBubbleLink]);

  return (
    <div
      className={classnames(styles.bubble, {
        [styles.left]: bubble.position === "left",
        [styles.right]: bubble.position === "right",
      })}
    >
      <div className={styles.text} ref={textAreaRef}>
        {body}
      </div>
    </div>
  );
};

export const RichTextBubbleView: React.FC<{
  bubble: RichTextBubble;
  onContextMenu: (string: string) => void;
}> = props => {
  const { bubble, onContextMenu } = props;

  const textAreaRef = useRef<HTMLDivElement>(null);

  const handleLongPress = useCallback(() => {
    if (textAreaRef.current) {
      onContextMenu(textAreaRef.current.textContent || "");
    }
  }, [onContextMenu]);

  useLongPress(500, handleLongPress, textAreaRef);

  return (
    <div
      className={classnames(styles.bubble, {
        [styles.left]: bubble.position === "left",
        [styles.right]: bubble.position === "right",
      })}
    >
      <RichTextView
        className={styles.text}
        ref={textAreaRef}
        text={bubble.body}
      />
    </div>
  );
};

export const DateBubbleView: React.FC<{ bubble: DateBubble }> = props => {
  const { bubble } = props;
  const isToday = bubble.date
    .startOf("day")
    .equals(DateTime.local().startOf("day"));

  return (
    <div className={classnames(styles.bubble, styles.date)}>
      <div>
        {isToday ? (
          <FormattedMessage id="chatroom.messsage_list.today" />
        ) : (
          bubble.date.toFormat("dd/LL/yy")
        )}
      </div>
    </div>
  );
};

export const HeaderBubbleView: React.FC<{ bubble: HeaderBubble }> = props => {
  const { bubble } = props;

  return (
    <RichTextView
      className={classnames(styles.bubble, styles.headerMessage)}
      text={bubble.headerMessage}
    />
  );
};

export const StatusBubbleView: React.FC<{ bubble: StatusBubble }> = props => {
  const { bubble } = props;
  return (
    <div className={styles.bubble}>
      <div className={styles.status}>
        {bubble.isCompleted ? (
          <FormattedMessage id="chatroom.message_list.status_changed_to_completed" />
        ) : (
          <FormattedMessage id="chatroom.message_list.status_changed_to_in_progress" />
        )}
      </div>
    </div>
  );
};

interface TextBubbleLinkProps {
  link: string;
  text: string;
  onClick: (link: string) => void;
}

const TextBubbleLink: React.FC<TextBubbleLinkProps> = props => {
  const { link, text, onClick } = props;

  const handleClick = useCallback(
    (e: React.MouseEvent<unknown>) => {
      e.preventDefault();
      e.stopPropagation();
      onClick(link);
    },
    [onClick, link]
  );

  return (
    <a href={link} onClick={handleClick}>
      {text}
    </a>
  );
};

// Replace the deep link protocol to https to make Linkify happy

const deepLinkProtocolReplacedString = generateUniqueId();

function replaceDeepLinkProtocol(text: string) {
  return text.replace(
    `${deepLinkProtocol}//`,
    `https://${deepLinkProtocolReplacedString}.`
  );
}

function reviveDeepLinkProtocol(text: string) {
  return text.replace(
    `https://${deepLinkProtocolReplacedString}.`,
    `${deepLinkProtocol}//`
  );
}
