import React from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import imageCompression from "browser-image-compression";
import { PermissionResult, PermissionType, Plugins } from "@capacitor/core";
import { OpenNativeSettings } from "@ionic-native/open-native-settings";

import { UserType } from "../models";
import { withChat, ChatContextProps } from "../context/ChatContext";
import { withLoading, LoadingContextProps } from "../context/LoadingContext";
import { withTemplate, TemplateContextProps } from "../context/TemplateContext";
import { withUser, UserContextProps } from "../context/UserContext";
import {
  withErrorAlert,
  ErrorAlertContextProps,
} from "../context/ErrorAlertContext";

import { ChatroomView } from "../components/Chatroom/ChatroomView";

import { BorrowerPersonalInfoScreenLocationState } from "./BorrowerPersonalInfoScreen";

import { getScrollElement } from "../utils/ionic-patch";
import { hybridOnly, isPlatform } from "../utils/platform";
import { downloadImageToAlbum, downloadImage } from "../utils/download";
import { spendAtLeast } from "../utils/misc";

import { AttachmentType } from "../types/misc";

const { App, Browser, Permissions } = Plugins;

export interface ChatroomScreenLocationState {
  conversationId?: string;
}

type Props = ChatContextProps &
  UserContextProps &
  ErrorAlertContextProps &
  LoadingContextProps &
  TemplateContextProps &
  RouteComponentProps<
    {},
    StaticContext,
    ChatroomScreenLocationState | undefined
  >;

interface State {
  conversationId?: string;
  userType?: UserType;
}

class ChatroomScreenImpl extends React.PureComponent<Props, State> {
  contentElement?: HTMLIonContentElement;
  scrollElement?: HTMLElement;
  lastScrollHeight = 0;

  private subscribedConversationEventListener: {
    remove: () => void;
  } | null = null;

  constructor(props: Props) {
    super(props);
    const locationState = this.props.location.state;
    const conversationId = locationState && locationState.conversationId;
    const { user } = this.props.userContext;

    if (conversationId && user) {
      this.state = {
        conversationId: conversationId,
        userType: user.type,
      };
      if (!this.props.chatContext.chatMessagesByConversation[conversationId]) {
        this.props.history.goBack();
      }
    } else {
      this.state = {};
      this.props.history.goBack();
    }
  }

  setContentRef = (element: HTMLIonContentElement) => {
    this.contentElement = element;
    getScrollElement(element).then(element => {
      this.scrollElement = element;
      this.lastScrollHeight = this.scrollElement.scrollHeight;
    });
  };

  componentDidMount() {
    hybridOnly(() => {
      window.addEventListener(
        "keyboardWillShow",
        this.adjustScrollPositionForShowingKeyboard
      );
      window.addEventListener(
        "keyboardWillHide",
        this.adjustScrollPositionForHidingKeyboard
      );
    });

    setTimeout(() => {
      // NOTE (jasonkit): need a delayed scroll to scroll
      // completely
      if (this.contentElement) {
        this.contentElement.scrollToBottom();
      }
    }, 200);

    this.subscribedConversationEventListener = this.props.chatContext.subscribeConversationEventEmitter(
      e => {
        if (
          e.type === "delete" &&
          e.conversation.id === this.state.conversationId
        ) {
          this.props.history.goBack();
        }
      }
    );
  }

  componentDidUpdate(prevProps: Props) {
    this.adjustSrollPositionForNewMessage(prevProps);
  }

  componentWillUnmount() {
    const { conversationId } = this.state;
    if (conversationId) {
      this.props.chatContext.leaveConversation(conversationId);
    }

    hybridOnly(() => {
      window.removeEventListener(
        "keyboardWillShow",
        this.adjustScrollPositionForShowingKeyboard
      );
      window.removeEventListener(
        "keyboardWillHide",
        this.adjustScrollPositionForHidingKeyboard
      );
    });

    if (this.subscribedConversationEventListener) {
      this.subscribedConversationEventListener.remove();
    }
  }

  private savedScrollTopForKeyboard = 0;
  adjustScrollPositionForShowingKeyboard = (e: any) => {
    if (this.contentElement && this.scrollElement) {
      const currentBottom =
        this.scrollElement.scrollTop + this.scrollElement.offsetHeight;

      this.savedScrollTopForKeyboard = this.scrollElement.scrollTop;

      if (
        Math.abs(
          this.scrollElement.scrollHeight - currentBottom - e.keyboardHeight
        ) < 50
      ) {
        this.contentElement.scrollByPoint(0, e.keyboardHeight, 0);
      } else {
        this.contentElement.scrollByPoint(0, e.keyboardHeight - 40, 0);
      }
    }
  };

  adjustScrollPositionForHidingKeyboard = () => {
    if (this.scrollElement) {
      this.scrollElement.scrollTop = this.savedScrollTopForKeyboard;
    }
  };

  adjustSrollPositionForNewMessage(prevProps: Props) {
    const { conversationId } = this.state;

    if (conversationId === undefined) {
      return;
    }

    const prevChatMessages =
      prevProps.chatContext.chatMessagesByConversation[conversationId];
    const curChatMessages = this.props.chatContext.chatMessagesByConversation[
      conversationId
    ];

    if (!prevChatMessages || !curChatMessages) {
      return;
    }

    const prevMessages = prevChatMessages.messages;
    const curMessages = curChatMessages.messages;

    if (prevMessages.length > 0 && curMessages.length > 0) {
      const prevFirstMessage = prevMessages[0];
      const curFirstMessage = curMessages[0];
      const prevLastMessage = prevMessages[prevMessages.length - 1];
      const curLastMessage = curMessages[curMessages.length - 1];

      if (curLastMessage.timestamp > prevLastMessage.timestamp) {
        this.scrollToBottomIfCloseToBottom(curLastMessage.userType);
      } else if (prevFirstMessage.id !== curFirstMessage.id) {
        this.scrollAfterPrependingMessages();
      }
    } else if (curMessages.length > 0) {
      if (this.scrollElement && this.contentElement) {
        this.contentElement.scrollToBottom();
        this.lastScrollHeight = this.scrollElement.scrollHeight;
      }
    }
  }

  private scrollToBottomIfCloseToBottom(incomingMessageUserType: UserType) {
    if (this.scrollElement && this.contentElement) {
      const currentBottom =
        this.scrollElement.scrollTop + this.scrollElement.offsetHeight;

      if (
        Math.abs(this.lastScrollHeight - currentBottom) < 100 ||
        incomingMessageUserType === this.state.userType
      ) {
        this.contentElement.scrollToBottom();
      }

      this.lastScrollHeight = this.scrollElement.scrollHeight;
    }
  }

  private scrollAfterPrependingMessages() {
    if (this.contentElement && this.scrollElement) {
      // Note:(jasonkit) setting overflow-scrolling to auto for
      // resetting scroll momentum, if not resetting it, the stopped
      // scrolling will resume and the behavior is very odd.
      this.scrollElement.style.setProperty(
        "-webkit-overflow-scrolling",
        "auto"
      );
      this.scrollElement.scrollTop +=
        this.scrollElement.scrollHeight - this.lastScrollHeight;

      this.scrollElement.style.setProperty(
        "-webkit-overflow-scrolling",
        "touch"
      );

      this.lastScrollHeight = this.scrollElement.scrollHeight;
    }
  }

  onSendMessage = (message: string) => {
    const { conversationId, userType } = this.state;
    if (conversationId && userType) {
      return this.props.chatContext
        .sendTextMessage(conversationId, message, userType)
        .catch(this.props.errorAlertContext.show);
    }
    return Promise.resolve(undefined);
  };

  onSendFile = (file: File, attachmentType: AttachmentType) => {
    const sendAttachment: { [key: string]: (file: File) => void } = {
      image: this.onSendImage,
      document: this.onSendDocument,
    };

    sendAttachment[attachmentType](file);
  };

  onSendImage = (file: File) => {
    const { conversationId, userType } = this.state;
    this.props.loadingContext.show(undefined, true);
    if (conversationId && userType) {
      imageCompression(file, {
        initialQuality: 0.9,
        maxWidthOrHeight: 1000,
      })
        .then(file => {
          return this.props.chatContext.sendFile(
            conversationId,
            file,
            userType,
            "image"
          );
        })
        .catch(this.props.errorAlertContext.show)
        .finally(this.props.loadingContext.dismiss);
    }
  };

  onSendDocument = (file: File) => {
    const { conversationId, userType } = this.state;
    if (conversationId && userType) {
      this.props.loadingContext.show(undefined, true);
      this.props.chatContext
        .sendFile(conversationId, file, userType, "document")
        .catch(this.props.errorAlertContext.show)
        .finally(this.props.loadingContext.dismiss);
    }
  };

  onStatusChange = (isCompleted: boolean, isReferralRequested: boolean) => {
    const { conversationId } = this.state;
    if (!conversationId) {
      return Promise.resolve(null as any);
    }

    let requestMessage;
    if (isReferralRequested) {
      const { chatContext, templateContext } = this.props;
      const chat = chatContext.chatMessagesByConversation[conversationId].chat;
      const companyTemplates = templateContext.getLocalizedTemplates(
        chat.offering.agent.company.templates
      );
      requestMessage = templateContext.getChatroomPopupMessage(
        companyTemplates,
        chat.offering
      );
    }

    return this.props.chatContext
      .changeChatStatus(
        conversationId,
        isCompleted,
        isReferralRequested,
        requestMessage,
        true
      )
      .catch(this.props.errorAlertContext.show);
  };

  onReferralStatusChange = (isAccepted: boolean, message: string) => {
    const { conversationId, userType } = this.state;
    if (!conversationId) {
      return Promise.resolve();
    }
    // Only accept borrower to update referral status
    if (!userType || userType === UserType.agent) {
      return Promise.resolve();
    }
    return this.props.chatContext
      .changeReferralStatus(conversationId, isAccepted, userType, message)
      .catch(this.props.errorAlertContext.show);
  };

  fetchOldMessages = () => {
    const { conversationId } = this.state;
    if (!conversationId) {
      return Promise.resolve(null as any);
    }

    return this.props.chatContext
      .fetchOldMessages(conversationId)
      .catch(this.props.errorAlertContext.show);
  };

  downloadImage = async (url: string) => {
    if (isPlatform("hybrid")) {
      this.props.loadingContext.show(undefined, true);
      const result = await spendAtLeast(() => downloadImageToAlbum(url), 500);
      this.props.loadingContext.dismiss();

      if (!result) {
        this.props.errorAlertContext.show(
          "chatroom.image_modal.error.cannot_save_image"
        );
      }
    } else {
      downloadImage(url);
    }
  };

  openDocument = async (url: string) => {
    if (isPlatform("hybrid")) {
      this.props.loadingContext.show();
      await App.openUrl({ url });
      this.props.loadingContext.dismiss();
    } else {
      window.open(url, "_blank");
    }
  };

  onBlockUser = (userId: string) => {
    this.props.loadingContext.show();
    return this.props.userContext
      .block(userId)
      .catch(this.props.errorAlertContext.show)
      .finally(this.props.loadingContext.dismiss);
  };

  onUnblockUser = (userId: string) => {
    this.props.loadingContext.show();
    return this.props.userContext
      .unblock(userId)
      .catch(this.props.errorAlertContext.show)
      .finally(this.props.loadingContext.dismiss);
  };

  onReportUser = async (userId: string, chatId: string): Promise<boolean> => {
    this.props.loadingContext.show();
    try {
      await this.props.userContext.report(userId, chatId);
      return true;
    } catch (e) {
      this.props.errorAlertContext.show(e);
      return false;
    } finally {
      this.props.loadingContext.dismiss();
    }
  };

  hasPermission = async (feature: "camera" | "photoAlbum") => {
    let permission: PermissionResult | undefined;
    switch (feature) {
      case "camera":
        permission = await Permissions.query({
          name: PermissionType.Camera,
        });
        break;
      case "photoAlbum":
        permission = await Permissions.query({
          name: PermissionType.Photos,
        });
        break;
      default:
        throw new Error("Unexpected feature given on checking permission");
    }
    return permission.state !== "denied";
  };

  openPersonalInfo = () => {
    const { history } = this.props;
    const { conversationId } = this.state;
    const locationState: BorrowerPersonalInfoScreenLocationState = {
      conversationId,
    };
    history.push("personal-info", locationState);
  };

  openDirectApplyLink = async (url: string) => {
    const { chatContext } = this.props;
    chatContext.hideDirectApplyPopover();
    await Browser.open({ url });
  };

  openSettingPage = async () => {
    await OpenNativeSettings.open("application_details");
  };

  render() {
    const { conversationId, userType } = this.state;
    const { templateContext, chatContext } = this.props;

    if (conversationId && userType) {
      const chatMessages = this.props.chatContext.chatMessagesByConversation[
        conversationId
      ];
      if (chatMessages) {
        return (
          <ChatroomView
            userType={userType}
            chat={chatMessages.chat}
            messages={chatMessages.messages}
            onSendFile={this.onSendFile}
            onSendMessage={this.onSendMessage}
            onStatusChange={this.onStatusChange}
            onBlockUser={this.onBlockUser}
            onUnblockUser={this.onUnblockUser}
            onReportUser={this.onReportUser}
            setContentRef={this.setContentRef}
            fetchOldMessages={this.fetchOldMessages}
            hasMoreMessage={chatMessages.hasMore}
            downloadImage={this.downloadImage}
            openDocument={this.openDocument}
            onClickExtraInfo={this.openPersonalInfo}
            onReferralStatusChange={this.onReferralStatusChange}
            openDirectApplyLink={this.openDirectApplyLink}
            hasPermission={this.hasPermission}
            openSettingPage={this.openSettingPage}
            chatContext={chatContext}
            templateContext={templateContext}
          />
        );
      }
    }
    return null;
  }
}

export const ChatroomScreen = withErrorAlert(
  withChat(withUser(withLoading(withTemplate(ChatroomScreenImpl))))
);
