import { BaseApiClient, ApiClientConstructor } from "./client";

import { Industry } from "../models";
import {
  currencyOptionResponseSchema,
  industryOptionResponseSchema,
  salaryOptionResponseSchema,
} from "../types/response";
import { CurrencyOption, SalaryOption } from "../types/option";

export interface OptionApiClient {
  fetchCurrencyOption: () => Promise<CurrencyOption[]>;
  fetchSalaryOption: () => Promise<SalaryOption[]>;
  fetchIndustryOption: () => Promise<Industry[]>;
}

export function withOptionApi<
  TBase extends ApiClientConstructor<BaseApiClient>
>(Base: TBase) {
  return class extends Base {
    async fetchCurrencyOption(): Promise<CurrencyOption[]> {
      const resp = await this.requestLambda(
        "option:fetch-currency",
        {},
        currencyOptionResponseSchema
      );

      return resp.options;
    }

    async fetchSalaryOption(): Promise<SalaryOption[]> {
      const resp = await this.requestLambda(
        "option:fetch-salary",
        {},
        salaryOptionResponseSchema
      );

      return resp.options;
    }

    async fetchIndustryOption(): Promise<Industry[]> {
      const resp = await this.requestLambda(
        "option:fetch-industry",
        {},
        industryOptionResponseSchema
      );

      return resp.options;
    }
  };
}
