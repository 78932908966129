import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router";
import { IonPage, IonAlert } from "@ionic/react";
import { Context as LocaleContext } from "@oursky/react-messageformat";

import { AgentOfferingCreateLocationState } from "./AgentOfferingCreateScreen";
import { withLoading, LoadingContextProps } from "../context/LoadingContext";
import {
  withErrorAlert,
  ErrorAlertContextProps,
} from "../context/ErrorAlertContext";
import { withUser, UserContextProps } from "../context/UserContext";
import { withRequest, RequestContextProps } from "../context/RequestContext";
import { withChat, ChatContextProps } from "../context/ChatContext";
import { AgentHeader } from "../components/AgentHeader";
import { AgentRequestListView } from "../components/AgentRequestListView";
import AgentQuickOfferPopupView from "../components/AgentQuickOfferPopupView";
import {
  Agent,
  CreateForeignExchangeForm,
  CreateForeignExchangeOffering,
  CreateMortgageForm,
  CreateMortgageOffering,
  CreatePersonalLoanForm,
  CreatePersonalLoanOffering,
  OfferingForm,
  OfferingType,
  Request,
  RequestType,
  UserType,
  Chat,
} from "../models";

type Props = ErrorAlertContextProps &
  LoadingContextProps &
  RouteComponentProps &
  RequestContextProps &
  ChatContextProps &
  UserContextProps;

interface State {
  isShowQuickOffer: boolean;
  selectedRequest?: Request;
  isAlertOpen: boolean;
}

class AgentOfferingScreen extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const { user } = this.props.userContext;
    this.state = {
      isShowQuickOffer: false,
      isAlertOpen: user
        ? user.type === UserType.agent && (user as Agent).isValidated === false
        : false,
    };
  }

  private isCallingAPI = false;

  fetchPage = (cursor?: string) => {
    return this.props.requestContext.listBorrowerRequestsForAgent(cursor);
  };

  onClickRequest = (request: Request) => {
    const {
      userContext: { user },
    } = this.props;

    if (!user || user.type !== UserType.agent) {
      throw new Error(`Unexpected user type: ${user}`);
    }

    if (this.isAgentHasQuickOffer(user as Agent, request)) {
      this.onShowQuickOffer(request);
    } else {
      this.navigateToCreateOffer(request);
    }
  };

  onCreateOfferManually = (request: Request) => {
    this.navigateToCreateOffer(request);
  };

  navigateToCreateOffer = (request: Request) => {
    const locationState: AgentOfferingCreateLocationState = {
      request,
    };
    this.onCloseDialog();
    this.props.history.push("/offering/create", locationState);
  };

  isAgentHasQuickOffer(agent: Agent, request: Request): boolean {
    const offeringType = this.getOfferingType(request);
    return agent.offeringTemplate.some(offer => offer.type === offeringType);
  }

  getOfferingType(request: Request): OfferingType {
    switch (request.type) {
      case RequestType.exchangeLoan:
        return OfferingType.exchangeLoan;
      case RequestType.mortgage:
        return OfferingType.mortgage;
      case RequestType.personalLoan:
        return OfferingType.personalLoan;
      default:
        throw new Error(`Unexpected request type: ${request}`);
    }
  }

  createOffer = (request: Request, template: OfferingForm): Promise<Chat> => {
    switch (request.type) {
      case RequestType.exchangeLoan: {
        const offerValues: CreateForeignExchangeOffering = {
          requestId: request.id,
          buyingAmount: request.sellingAmount,
          ...(template as CreateForeignExchangeForm),
        };

        return this.props.chatContext.agentCreateFXChat(offerValues);
      }
      case RequestType.mortgage: {
        const offerValues: CreateMortgageOffering = {
          requestId: request.id,
          ...(template as CreateMortgageForm),
        };

        return this.props.chatContext.agentCreateMortgageChat(offerValues);
      }
      case RequestType.personalLoan: {
        const offerValues: CreatePersonalLoanOffering = {
          requestId: request.id,
          ...(template as CreatePersonalLoanForm),
        };

        return this.props.chatContext.agentCreatePersonalLoanChat(offerValues);
      }
      default:
        throw new Error(`Unexpected request type: ${request}`);
    }
  };

  onClickCreateQuickOffer = (request: Request, template: OfferingForm) => {
    if (this.isCallingAPI) return;

    this.props.loadingContext.show();

    this.isCallingAPI = true;
    return this.createOffer(request, template)
      .then(this.props.chatContext.enterConversation)
      .then(chat => {
        this.onCloseDialog();
        this.props.history.push("/chat");
        this.props.history.push("/chat/chatroom", {
          conversationId: chat.conversationId,
        });
        setTimeout(this.props.requestContext.refreshAgentRequestList, 500);
      })
      .catch(error => {
        this.props.errorAlertContext.show(error);
      })
      .finally(() => {
        this.props.loadingContext.dismiss();
        this.isCallingAPI = false;
      });
  };

  onCloseDialog = () => {
    this.setState({
      isShowQuickOffer: false,
      selectedRequest: undefined,
    });
  };

  onShowQuickOffer = (request: Request) => {
    this.setState({
      isShowQuickOffer: true,
      selectedRequest: request,
    });
  };

  closeAlert = () => {
    this.setState({ isAlertOpen: false });
  };

  render() {
    const { isShowQuickOffer, selectedRequest } = this.state;
    const { agentRequestListVersion } = this.props.requestContext;
    return (
      <IonPage>
        <AgentHeader />
        <AgentRequestListView
          listVersion={agentRequestListVersion}
          fetchPage={this.fetchPage}
          onClickRequest={this.onClickRequest}
        />
        <AgentQuickOfferPopupView
          isShowPopover={isShowQuickOffer}
          request={selectedRequest}
          onClose={this.onCloseDialog}
          onCreateQuickOffer={this.onClickCreateQuickOffer}
          onCreateOfferManually={this.onCreateOfferManually}
        />
        <LocaleContext.Consumer>
          {({ renderToString }) => (
            <IonAlert
              isOpen={this.state.isAlertOpen}
              onDidDismiss={this.closeAlert}
              message={renderToString("agent_offering.alert.message")}
              buttons={[
                {
                  text: renderToString("agent_offering.alert.ok"),
                  handler: this.closeAlert,
                },
              ]}
            />
          )}
        </LocaleContext.Consumer>
      </IonPage>
    );
  }
}

export default withLoading(
  withErrorAlert(withRequest(withChat(withUser(AgentOfferingScreen))))
);
