import React from "react";

import { OfferingApiClient } from "../apiClient";
import { PubSubService, EventHandlerUnsubscriber } from "../services/pubsub";
import { Offering } from "../models";
import { ListType } from "../types/misc";
import { Page } from "../utils/pagination";
import { Omit } from "../utils/typeutils";

export interface OfferingContextValue {
  listAllFeaturedOfferingsForBorrower: (
    type: ListType,
    cursor?: string
  ) => Promise<Page<Offering>>;
  listAllFeaturedOfferingsForGuest: (
    type: ListType,
    cursor?: string
  ) => Promise<Page<Offering>>;

  refreshOfferingList: () => void;
  offeringListVersion: number;
}

const OfferingContext = React.createContext(null as any);
export { OfferingContext };

export interface OfferingContextProps {
  offeringContext: OfferingContextValue;
}

interface Props {
  apiClient: OfferingApiClient;
  pubsubService: PubSubService;
}

interface State {
  offeringListVersion: number;
}

export class OfferingContextProvider extends React.PureComponent<Props, State> {
  private eventHandlerUnsubscribers: EventHandlerUnsubscriber[] = [];

  state: State = {
    offeringListVersion: 0,
  };

  componentDidMount() {
    this.eventHandlerUnsubscribers = [
      this.props.pubsubService.registerEventHandler(
        "block",
        this.refreshOfferingList
      ),
      this.props.pubsubService.registerEventHandler(
        "unblock",
        this.refreshOfferingList
      ),
    ];
  }

  componentWillUnmount() {
    for (const unsubscriber of this.eventHandlerUnsubscribers) {
      unsubscriber();
    }
  }

  render() {
    const { apiClient, children } = this.props;
    return (
      <OfferingContext.Provider
        value={{
          listAllFeaturedOfferingsForBorrower: apiClient.listAllFeaturedOfferingsForBorrower.bind(
            apiClient
          ),
          listAllFeaturedOfferingsForGuest: apiClient.listAllFeaturedOfferingsForGuest.bind(
            apiClient
          ),
          refreshOfferingList: this.refreshOfferingList,
          offeringListVersion: this.state.offeringListVersion,
        }}
      >
        {children}
      </OfferingContext.Provider>
    );
  }

  refreshOfferingList = () => {
    this.setState(
      (prevState: State): State => {
        return {
          ...prevState,
          offeringListVersion: prevState.offeringListVersion + 1,
        };
      }
    );
  };
}

export function withOffering<P extends OfferingContextProps>(
  Component: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof OfferingContextProps>> {
  const Wrapped: React.FC<Omit<P, keyof OfferingContextProps>> = (
    props: Omit<P, keyof OfferingContextProps>
  ) => (
    <OfferingContext.Consumer>
      {offeringContext => (
        <Component {...props as any} offeringContext={offeringContext} />
      )}
    </OfferingContext.Consumer>
  );

  return Wrapped;
}
