import React, { PureComponent } from "react";
import { IonButton } from "@ionic/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import { formatNumber, parseNumber } from "libphonenumber-js";

import { UserContext } from "../../context/UserContext";
import ProfileItem from "./ProfileItem";
import { AgentHeader } from "../AgentHeader";
import { MPContent } from "../MPContent";
import AccountItem from "../AccountView/AccountItem";
import CheckedOptionItem from "./CheckedOptionItem";
import { Agent } from "../../models";
import { StaticPageType } from "../../types/misc";
import { getStaticPageOptions } from "../../utils/misc";

import styles from "./styles.module.scss";

export type Props = AgentAccountViewProps;

interface AgentAccountViewProps {
  agent: Agent;
  openStaticPage: (type: StaticPageType) => void;

  onSwitchAgentClick: () => void;

  onLogOutButtonClick: () => void;
}

class AgentAccountView extends PureComponent<AgentAccountViewProps> {
  static contextType = UserContext;
  context!: React.ContextType<typeof UserContext>;

  handleLogOutButtonClick = () => {
    this.props.onLogOutButtonClick();
  };

  handleSwitchAgentClick = () => {
    this.props.onSwitchAgentClick();
  };

  getPhoneNumber(agent: Agent) {
    return formatNumber(parseNumber(agent.username, "HK"), "INTERNATIONAL");
  }

  get canSwtichAgentImpersonation() {
    return this.context.isImpersonatingAgent;
  }

  renderOptions = () => {
    const { agent } = this.props;
    const options = getStaticPageOptions();
    return (
      <div>
        <CheckedOptionItem
          checked={agent.isValidated}
          isYellow={false}
          value={"account.option.validate"}
        />
        <CheckedOptionItem
          checked={agent.isPremier}
          isYellow={true}
          value={"account.option.premier"}
        />
        {options.map((option, index) => {
          const onClick = () => {
            this.props.openStaticPage(option.type);
          };
          return (
            <button
              className={styles.normalOption}
              key={index}
              onClick={onClick}
            >
              <FormattedMessage id={option.labelId} />
            </button>
          );
        })}
        <button
          className={styles.logoutOption}
          onClick={this.handleLogOutButtonClick}
        >
          <FormattedMessage id="account.option.logout" />
        </button>
      </div>
    );
  };

  render() {
    const { agent } = this.props;
    return (
      <>
        <AgentHeader
          buttons={
            this.canSwtichAgentImpersonation ? (
              <IonButton onClick={this.handleSwitchAgentClick}>
                <FormattedMessage id="account.button.switch_agent" />
              </IonButton>
            ) : (
              <></>
            )
          }
        />
        <MPContent>
          <div className={styles.accountContainer}>
            <div className={styles.userInfoContainer}>
              <ProfileItem
                name={agent.name}
                company={agent.company.name}
                logo={agent.company.logo}
              />
              <AccountItem
                labelId="account.userInfo.mobile"
                value={this.getPhoneNumber(agent)}
              />
              <AccountItem
                labelId="account.userInfo.email"
                value={agent.email || undefined}
              />
            </div>
            {this.renderOptions()}
          </div>
        </MPContent>
      </>
    );
  }
}

export default AgentAccountView;
