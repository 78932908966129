import {
  BorrowerBasicInfoField,
  BorrowerListField,
  FieldType,
} from "../../context/CreateRequestContext";
import {
  BorrowerBasicInfo,
  BorrowerPropertyInfo,
  PropertyStatus,
} from "../../models";
import { getAge } from "../../utils/misc";
import {
  ValidationError,
  Validator,
  filterValidatorByDisplayMap,
} from "../../utils/validate";
import { validateHKID } from "../../utils/validateHKID";
import { EMAIL_REGEX } from "../../utils/validation";
import { InputFields as BorrowerPropertyInfoViewInputFields } from "../BorrowerPropertyInfoView";
import { FormValues as PropertyInfoFormValues } from "../BorrowerPropertyInfoView/PropertyInfoForm";

export type InputFields =
  | "name"
  | "surname"
  | "gender"
  | "passportNumber"
  | "email"
  | "birthday"
  | "education"
  | "heardFrom"
  | "roomFloor"
  | "tower"
  | "nameOfBuilding"
  | "streetNameAndNumber"
  | "district"
  | "premiseType"
  | "propertyStatus"
  | "numOfOwner"
  | "monthlyRent"
  | BorrowerPropertyInfoViewInputFields;

export interface BorrowerBasicInfoProps {
  error: ValidationError<InputFields>;
  borrowerBasicInfo: BorrowerBasicInfo;
  updateBorrowerBasicInfo: (value: Partial<BorrowerBasicInfo>) => void;

  borrowerPropertyInfo: BorrowerPropertyInfo;
  onUpdateExistingPropertyInfo: (
    index: number,
    value: Partial<PropertyInfoFormValues>
  ) => void;
  onUpdateHasProperty: (hasProperty: boolean) => void;
  onSelectNumberOfProperties: (numberOfProperties: number) => void;
  onRemoveExistingProperty?: (index: number) => void;
  onAddExistingProperty?: () => void;

  emailErrorId?: string;
  focusField?: InputFields;
}

type ValidationFields = Exclude<BorrowerBasicInfoField, BorrowerListField>;
export function getBorrowerBasicInfoFormValidators(
  borrowerBasicInfo: BorrowerBasicInfo,
  borrowerPropertyInfo: BorrowerPropertyInfo,
  formDisplayMap?: { [key in ValidationFields]?: FieldType }
): Validator<InputFields>[] {
  const {
    name,
    surname,
    passportNumber,
    email,
    birthday,
    education,
    heardFrom,
    district,
    premiseType,
    propertyStatus,
    numOfOwner,
    monthlyRent,
  } = borrowerBasicInfo;
  const { numberOfProperties } = borrowerPropertyInfo;
  const validators: Validator<InputFields>[] = [
    {
      field: "name",
      invalidCondition: name === null || name.trim().length === 0,
      errorMessageId: "borrower_basic_info.name.error.required",
      isExistenceCheck: true,
    },
    {
      field: "surname",
      invalidCondition: surname === null || surname.trim().length === 0,
      errorMessageId: "borrower_basic_info.surname.error.required",
      isExistenceCheck: true,
    },
    {
      field: "passportNumber",
      invalidCondition:
        passportNumber !== null &&
        passportNumber.trim().length !== 0 &&
        !validateHKID(passportNumber),
      errorMessageId: "borrower_basic_info.passportNumber.error.invalid",
    },
    {
      field: "email",
      invalidCondition: email ? !EMAIL_REGEX.test(email.trim()) : undefined,
      errorMessageId: "signup.error.email_invalid",
    },
    {
      field: "birthday",
      invalidCondition: !birthday,
      errorMessageId: "borrower_basic_info.birthday.error.required",
      isExistenceCheck: true,
    },
    {
      field: "birthday",
      invalidCondition: birthday && !birthday.isValid,
      errorMessageId: "borrower_basic_info.birthday.error.incorrect",
    },
    {
      field: "birthday",
      invalidCondition: birthday && getAge(birthday) < 18,
      errorMessageId: "borrower_basic_info.birthday.error.too_young",
    },
    {
      field: "birthday",
      invalidCondition: birthday && getAge(birthday) > 100,
      errorMessageId: "borrower_basic_info.birthday.error.too_old",
    },
    {
      field: "education",
      invalidCondition: education === null,
      errorMessageId: "borrower_basic_info.education.error.required",
      isExistenceCheck: true,
    },
    {
      field: "heardFrom",
      invalidCondition: heardFrom === null || heardFrom.trim().length === 0,
      errorMessageId: "borrower_basic_info.heardFrom.error.required",
      isExistenceCheck: true,
    },
    {
      field: "district",
      invalidCondition: district === null,
      errorMessageId: "borrower_basic_info.district.error.required",
      isExistenceCheck: true,
    },
    {
      field: "premiseType",
      invalidCondition: premiseType === null,
      errorMessageId: "borrower_basic_info.premiseType.error.required",
      isExistenceCheck: true,
    },
    {
      field: "propertyStatus",
      invalidCondition: propertyStatus === null,
      errorMessageId: "borrower_basic_info.propertyStatus.error.required",
      isExistenceCheck: true,
    },
    {
      field: "hasProperty",
      invalidCondition: numberOfProperties === null,
      errorMessageId: "borrower_property_info.has_property.error.required",
      isExistenceCheck: true,
    },
    {
      field: "numOfOwner",
      invalidCondition:
        propertyStatus === PropertyStatus.jointlyOwned && numOfOwner === null,
      errorMessageId: "borrower_basic_info.numberOfOwner.error.required",
      isExistenceCheck: true,
    },
    {
      field: "monthlyRent",
      invalidCondition:
        propertyStatus === PropertyStatus.leased &&
        monthlyRent !== null &&
        monthlyRent <= 0,
      errorMessageId: "borrower_basic_info.monthlyRent.error.negative_or_zero",
    },
    {
      field: "monthlyRent",
      invalidCondition:
        propertyStatus === PropertyStatus.leased && monthlyRent === null,
      errorMessageId: "borrower_basic_info.monthlyRent.error.required",
    },
  ];

  return filterValidatorByDisplayMap<InputFields, ValidationFields>(
    validators,
    formDisplayMap,
    {
      hasProperty: "numberOfProperties",
    }
  );
}
