import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import React, { ChangeEvent, FormEventHandler, PureComponent } from "react";

import {
  BooleanString,
  CreatePersonalLoanForm,
  OfferingType,
} from "../../models";

import {
  currencyFormatToNumber,
  formatToCurrencyAmount,
} from "../../utils/formatToCurrencyAmount";
import { formattedRate } from "../../utils/numeric";
import { isLoading, RemoteData } from "../../utils/remoteData";

import { PrimaryButton } from "../Button";
import ErrorField from "../ErrorField";
import {
  FormattedDropdown,
  Option as FormattedOption,
} from "../FormattedDropdown";
import { FormattedInput } from "../FormattedInput";
import { MPInput } from "../input";
import {
  MonthPeriodDropdown,
  ValidDaysDropdown,
  ValidDaysOptions,
} from "../SpecifyDropdown";

import styles from "./styles.module.scss";
import { ValidationError, Validator, validate } from "../../utils/validate";

export type InputFields =
  | "amountMin"
  | "amountMax"
  | "interestRateMin"
  | "interestRateMax"
  | "validDays"
  | "periodMin"
  | "periodMax";

interface Props {
  remoteCreate: RemoteData<void>;
  onSubmit: (values: CreatePersonalLoanForm) => Promise<boolean>;
}

interface FormValues {
  amountMin: string;
  amountMax: string;
  paymentPeriodMin: string;
  paymentPeriodMax: string;
  interestRateMin: string;
  interestRateMax: string;
  validDays: string;
  isRequiredEmployed: BooleanString;
  isRequiredProperty: BooleanString;
  addition: string;
}

interface State {
  values: FormValues;
  error: ValidationError<InputFields>;
  focusField?: InputFields;
  nextField?: InputFields;
}

const defaultFormValues = {
  amountMin: "",
  amountMax: "",
  paymentPeriodMin: "",
  paymentPeriodMax: "",
  interestRateMin: "",
  interestRateMax: "",
  validDays: ValidDaysOptions[0].value,
  isRequiredEmployed: BooleanString.true,
  isRequiredProperty: BooleanString.true,
  addition: "",
};

class OfferPersonalLoanForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      values: { ...defaultFormValues },
      error: {},
    };
  }

  componentDidMount() {
    this.resetState();
  }

  resetState = () => {
    this.setState({
      values: { ...defaultFormValues },
      error: {},
    });
  };

  componentDidUpdate() {
    this.resetNextField();
  }

  private resetNextField() {
    if (this.state.nextField) {
      this.setState({ nextField: undefined });
    }
  }

  focusInterestRateMaxField = () => {
    this.setState({ nextField: "interestRateMax" });
  };

  focusValidDaysField = () => {
    this.setState({ nextField: "validDays" });
  };

  renderAmountSection = () => {
    const { error, values, focusField } = this.state;

    return (
      <div className={styles.row}>
        <div className={styles.col}>
          <label className={styles.labelText} htmlFor="amount_min">
            <FormattedMessage id="create_offering.personal_loan.amount_min.label" />
          </label>
          <MPInput
            id="amount_min"
            placeholder="HKD"
            type="tel"
            maxLength={11}
            value={values.amountMin}
            isError={!!error.amountMin}
            onBlur={this.handleAmountChange}
            onChange={this.handleAmountMinChange}
            shouldFocus={focusField === "amountMin"}
          />
          <ErrorField messageId={error.amountMin} />
        </div>
        <div className={styles.colRight}>
          <label className={styles.labelText} htmlFor="amount_max">
            <FormattedMessage id="create_offering.personal_loan.amount_max.label" />
          </label>
          <MPInput
            id="amount_max"
            placeholder="HKD"
            type="tel"
            maxLength={11}
            value={values.amountMax}
            isError={!!error.amountMax}
            onBlur={this.handleAmountChange}
            onChange={this.handleAmountMaxChange}
            shouldFocus={focusField === "amountMax"}
          />
          <ErrorField messageId={error.amountMax} />
        </div>
      </div>
    );
  };

  renderPeriodSection = () => {
    const { values, error, focusField } = this.state;

    return (
      <div className={styles.row} data-anchor="period">
        <div className={styles.col}>
          <label className={styles.labelText} htmlFor="period_min">
            <FormattedMessage id="create_offering.personal_loan.period_min.label" />
          </label>
          <MonthPeriodDropdown
            id="period_min"
            containerClass={styles.dropdownContainer}
            selectClass={styles.dropdownSelect}
            errorClass={styles.dropdownError}
            emptyClass={styles.dropdownEmpty}
            placeholderId="create_offering.please_select.placeholder"
            value={values.paymentPeriodMin || ""}
            isError={false}
            onValueChange={this.handlePeriodMinChange}
            shouldScrollTo={focusField === "periodMin"}
            scrollAnchor="period"
          />
          <ErrorField messageId={error.periodMin} />
        </div>
        <div className={styles.colRight}>
          <label className={styles.labelText} htmlFor="period_max">
            <FormattedMessage id="create_offering.personal_loan.period_max.label" />
          </label>
          <MonthPeriodDropdown
            id="period_max"
            containerClass={styles.dropdownContainer}
            selectClass={styles.dropdownSelect}
            errorClass={styles.dropdownError}
            emptyClass={styles.dropdownEmpty}
            placeholderId="create_offering.please_select.placeholder"
            value={values.paymentPeriodMax || ""}
            isError={false}
            onValueChange={this.handlePeriodMaxChange}
            shouldScrollTo={focusField === "periodMax"}
            scrollAnchor="period"
          />
          <ErrorField messageId={error.periodMax} />
        </div>
      </div>
    );
  };

  renderInterestRateSection = () => {
    const { error, values, focusField, nextField } = this.state;
    return (
      <div className={styles.row}>
        <div className={styles.col}>
          <label className={styles.labelText} htmlFor="interest_rate_min">
            <FormattedMessage id="create_offering.personal_loan.interest_rate_min.label" />
          </label>
          <div className={styles.rateInputContainer}>
            <MPInput
              id="interest_rate_min"
              placeholder="0-99"
              type="number"
              maxLength={5}
              value={values.interestRateMin}
              isError={!!error.interestRateMin}
              onBlur={this.handleInterestRateChange}
              onChange={this.handleRateMinChange}
              shouldFocus={focusField === "interestRateMin"}
              autoSubmit={this.focusInterestRateMaxField}
            />
            <span>%</span>
          </div>
          <ErrorField messageId={error.interestRateMin} />
        </div>
        <div className={styles.colRight}>
          <label className={styles.labelText} htmlFor="interest_rate_max">
            <FormattedMessage id="create_offering.personal_loan.interest_rate_max.label" />
          </label>
          <div className={styles.rateInputContainer}>
            <MPInput
              id="interest_rate_max"
              placeholder="0-99"
              type="number"
              maxLength={5}
              value={values.interestRateMax}
              isError={!!error.interestRateMax}
              onBlur={this.handleInterestRateChange}
              onChange={this.handleRateMaxChange}
              shouldFocus={
                focusField === "interestRateMax" ||
                nextField === "interestRateMax"
              }
              autoSubmit={this.focusValidDaysField}
            />
            <span>%</span>
          </div>
          <ErrorField messageId={error.interestRateMax} />
        </div>
      </div>
    );
  };

  renderValidDaysSection = () => {
    const { values, nextField } = this.state;

    return (
      <div className={styles.col} data-anchor="validDays">
        <label className={styles.labelText}>
          <FormattedMessage id="create_offering.exchange.valid_days.label" />
        </label>
        <ValidDaysDropdown
          containerClass={styles.dropdownContainer}
          selectClass={styles.dropdownSelect}
          errorClass={styles.dropdownError}
          emptyClass={styles.dropdownEmpty}
          isError={false}
          value={values.validDays}
          onValueChange={this.handleValidDaysChange}
          shouldScrollTo={nextField === "validDays"}
          scrollAnchor="validDays"
        />
      </div>
    );
  };

  renderRequiredEmployedDropdown = () => {
    const { values } = this.state;

    const EmployedOptions: FormattedOption[] = [
      {
        key: "true",
        value: BooleanString.true,
        labelId: "create_offering.personal_loan.required_employed.yes",
      },
      {
        key: "false",
        value: BooleanString.false,
        labelId: "create_offering.personal_loan.required_employed.no",
      },
    ];
    return (
      <>
        <label className={styles.labelText} htmlFor="required_employed">
          <FormattedMessage id="create_offering.personal_loan.required_employed.label" />
        </label>
        <FormattedDropdown
          id="required_employed"
          containerClass={classNames(
            styles.dropdownContainer,
            styles.requirementText
          )}
          selectClass={styles.dropdownSelect}
          errorClass={styles.dropdownError}
          emptyClass={styles.dropdownEmpty}
          isError={false}
          options={EmployedOptions}
          value={values.isRequiredEmployed}
          onValueChange={this.handleRequiredEmployedChange}
        />
      </>
    );
  };

  renderRequiredPropertyDropdown = () => {
    const { values } = this.state;

    const PropertyOptions: FormattedOption[] = [
      {
        key: "true",
        value: BooleanString.true,
        labelId: "create_offering.personal_loan.required_property.yes",
      },
      {
        key: "false",
        value: BooleanString.false,
        labelId: "create_offering.personal_loan.required_property.no",
      },
    ];
    return (
      <>
        <label className={styles.labelText} htmlFor="required_property">
          <FormattedMessage id="create_offering.personal_loan.required_property.label" />
        </label>
        <FormattedDropdown
          id="required_property"
          containerClass={classNames(
            styles.dropdownContainer,
            styles.requirementText
          )}
          selectClass={styles.dropdownSelect}
          errorClass={styles.dropdownError}
          emptyClass={styles.dropdownEmpty}
          isError={false}
          options={PropertyOptions}
          value={values.isRequiredProperty}
          onValueChange={this.handleRequiredPropertyChange}
        />
      </>
    );
  };

  renderRequirementSection = () => {
    const { values } = this.state;

    return (
      <div className={styles.col}>
        <h3>
          <FormattedMessage id="create_offering.personal_loan.requirement.title" />
        </h3>
        {this.renderRequiredEmployedDropdown()}
        {this.renderRequiredPropertyDropdown()}
        <div className={styles.col}>
          <label className={styles.labelText} htmlFor="addition">
            <FormattedMessage id="create_offering.personal_loan.addition.label" />
          </label>
          <FormattedInput
            className={styles.requirementText}
            id="addition"
            maxLength={150}
            placeholderId="create_offering.personal_loan.addition.placeholder"
            value={values.addition}
            onChange={this.handleAdditionChange}
            autoSubmit={this.doSubmit}
          />
        </div>
      </div>
    );
  };

  render() {
    const { remoteCreate } = this.props;
    return (
      <form noValidate={true} onSubmit={this.handleSubmit}>
        <div className={styles.formContainer}>
          {this.renderAmountSection()}
          {this.renderPeriodSection()}
          {this.renderInterestRateSection()}
          {this.renderValidDaysSection()}
          {this.renderRequirementSection()}
        </div>

        <PrimaryButton
          class={styles.submitButton}
          type="submit"
          expand="full"
          disabled={isLoading(remoteCreate)}
        >
          <FormattedMessage id="create_offering.submit" />
        </PrimaryButton>
      </form>
    );
  }

  handleAmountMinChange = (e: ChangeEvent<HTMLInputElement>) => {
    const amount: string = e.target.value || "";
    const formattedAmount = formatToCurrencyAmount(amount) || "";
    this.setState(({ values }) => {
      return {
        values: { ...values, amountMin: formattedAmount },
      };
    });
  };

  handleAmountMaxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const amount: string = e.target.value || "";
    const formattedAmount = formatToCurrencyAmount(amount) || "";
    this.setState(({ values }) => {
      return {
        values: { ...values, amountMax: formattedAmount },
      };
    });
  };

  handleAmountChange = () => {
    this.setState(({ values }) => {
      const min = currencyFormatToNumber(values.amountMin);
      const max = currencyFormatToNumber(values.amountMax);
      if (values.amountMin !== "" && values.amountMax !== "" && min > max) {
        return {
          values: {
            ...values,
            amountMax: values.amountMin,
            amountMin: values.amountMax,
          },
        };
      }
      return { values };
    });
  };

  handlePeriodMinChange = (period: string) => {
    this.handlePeriodChange(period, null);
  };

  handlePeriodMaxChange = (period: string) => {
    this.handlePeriodChange(null, period);
  };

  handlePeriodChange = (min: string | null, max: string | null) => {
    this.setState(({ values }) => {
      const periodMin = min || values.paymentPeriodMin;
      const periodMax = max || values.paymentPeriodMax;
      if (parseInt(periodMin, 10) > parseInt(periodMax, 10)) {
        return {
          values: {
            ...values,
            paymentPeriodMin: periodMax,
            paymentPeriodMax: periodMin,
          },
        };
      }
      return {
        values: {
          ...values,
          paymentPeriodMin: periodMin,
          paymentPeriodMax: periodMax,
        },
      };
    });
  };

  handleRateMinChange = (e: ChangeEvent<HTMLInputElement>) => {
    const interestRate: string = e.target.value || "";
    const rate = formattedRate(interestRate);
    this.setState(({ values }) => {
      return {
        values: {
          ...values,
          interestRateMin: rate === null ? values.interestRateMin : rate,
        },
      };
    });
  };

  handleRateMaxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const interestRate: string = e.target.value || "";
    const rate = formattedRate(interestRate);
    this.setState(({ values }) => {
      return {
        values: {
          ...values,
          interestRateMax: rate === null ? values.interestRateMax : rate,
        },
      };
    });
  };

  handleInterestRateChange = () => {
    this.setState(({ values }) => {
      const min = parseFloat(values.interestRateMin);
      const max = parseFloat(values.interestRateMax);
      if (
        values.interestRateMin !== "" &&
        values.interestRateMax !== "" &&
        min > max
      ) {
        return {
          values: {
            ...values,
            interestRateMax: values.interestRateMin,
            interestRateMin: values.interestRateMax,
          },
        };
      }
      return { values };
    });
  };

  handleRequiredEmployedChange = (isRequired: string) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, isRequiredEmployed: isRequired as BooleanString },
      };
    });
  };

  handleRequiredPropertyChange = (isRequired: string) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, isRequiredProperty: isRequired as BooleanString },
      };
    });
  };

  handleAdditionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const addition: string = e.target.value || "";
    this.setState(({ values }) => {
      return {
        values: { ...values, addition },
      };
    });
  };

  handleValidDaysChange = (validDays: string) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, validDays },
      };
    });
  };

  handleSubmit: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    this.doSubmit();
  };

  doSubmit = () => {
    const { values } = this.state;

    this.setState({ focusField: undefined }, () => {
      if (!this.validateOfferData(values)) return;

      const formattedFormValues: CreatePersonalLoanForm = {
        type: OfferingType.personalLoan,
        amountMin: currencyFormatToNumber(values.amountMin),
        amountMax: currencyFormatToNumber(values.amountMax),
        interestRateMin: values.interestRateMin,
        interestRateMax: values.interestRateMax,
        periodMin: parseInt(values.paymentPeriodMin, 10),
        periodMax: parseInt(values.paymentPeriodMax, 10),
        isRequiredEmployed: values.isRequiredEmployed === BooleanString.true,
        isRequiredProperty: values.isRequiredProperty === BooleanString.true,
        addition: values.addition,
        validDays: parseInt(values.validDays, 10),
      };

      this.props.onSubmit(formattedFormValues).then(isSubmitted => {
        if (isSubmitted) {
          this.resetState();
        }
      });
    });
  };

  validateOfferData(values: FormValues) {
    const {
      amountMin,
      amountMax,
      interestRateMin,
      interestRateMax,
      paymentPeriodMin,
      paymentPeriodMax,
    } = values;

    const validators: Validator<InputFields>[] = [
      {
        field: "amountMin",
        invalidCondition: !amountMin,
        errorMessageId: "create_offering.personal_loan.error.required.amount",
      },
      {
        field: "amountMin",
        invalidCondition:
          amountMin !== "" && currencyFormatToNumber(amountMin) <= 0,
        errorMessageId:
          "create_offering.personal_loan.error.negative_or_zero.amount",
      },
      {
        field: "amountMax",
        invalidCondition: !amountMax,
        errorMessageId: "create_offering.personal_loan.error.required.amount",
      },
      {
        field: "periodMin",
        invalidCondition: !paymentPeriodMin,
        errorMessageId:
          "create_offering.personal_loan.error.required.period_min",
      },
      {
        field: "periodMax",
        invalidCondition: !paymentPeriodMax,
        errorMessageId:
          "create_offering.personal_loan.error.required.period_max",
      },
      {
        field: "amountMax",
        invalidCondition:
          amountMax !== "" && currencyFormatToNumber(amountMax) <= 0,
        errorMessageId:
          "create_offering.personal_loan.error.negative_or_zero.amount",
      },
      {
        field: "interestRateMin",
        invalidCondition: !interestRateMin,
        errorMessageId:
          "create_offering.personal_loan.error.required.interest_rate",
      },
      {
        field: "interestRateMin",
        invalidCondition:
          interestRateMin !== "" &&
          (parseFloat(interestRateMin) >= 100 ||
            parseFloat(interestRateMin) < 0),
        errorMessageId:
          "create_offering.personal_loan.error.invalid.interest_rate",
      },
      {
        field: "interestRateMax",
        invalidCondition: !interestRateMax,
        errorMessageId:
          "create_offering.personal_loan.error.required.interest_rate",
      },
      {
        field: "interestRateMax",
        invalidCondition:
          interestRateMax !== "" &&
          (parseFloat(interestRateMax) >= 100 ||
            parseFloat(interestRateMax) < 0),
        errorMessageId:
          "create_offering.personal_loan.error.invalid.interest_rate",
      },
    ];

    const { focusField, error, isValid } = validate(validators);

    this.setState({ error, focusField });

    return isValid;
  }
}

export default OfferPersonalLoanForm;
