import skygear from "skygear";

export interface Payload {
  event: string;
}

export type EventHandler = (payload: Payload) => void;
export type EventHandlerUnsubscriber = () => void;
export type ChannelUnsubscriber = () => void;

export interface PubSubService {
  subscribe: (channel: string) => ChannelUnsubscriber;

  registerEventHandler: (
    channel: string,
    handler: EventHandler
  ) => EventHandlerUnsubscriber;
}

export class SkygearPubSubService implements PubSubService {
  eventHandlers: {
    [key: string]: EventHandler[];
  } = {};

  subscribe = (channel: string) => {
    const handler = this.dispatchEvent;
    skygear.pubsub.on(channel, handler);

    return () => {
      skygear.pubsub.off(channel, handler);
    };
  };

  registerEventHandler = (event: string, handler: EventHandler) => {
    if (!(event in this.eventHandlers)) {
      this.eventHandlers[event] = [];
    }

    this.eventHandlers[event].push(handler);

    return () => {
      const index = this.eventHandlers[event].indexOf(handler);
      this.eventHandlers[event].splice(index, 1);
    };
  };

  dispatchEvent = (payload: Payload) => {
    const { event } = payload;

    const handlers = this.eventHandlers[event] || [];
    for (const handler of handlers) {
      handler(payload);
    }
  };
}

export function makeSkygearPubSubService() {
  return new SkygearPubSubService();
}
