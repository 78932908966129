import { Plugins } from "@capacitor/core";
import React from "react";
import { UserType } from "../models";
import { OurNavContextProps, withNav } from "../navigation";
import { makeDeepLinkParser, DeepLinkParser } from "../utils/deepLinkParser";
import { Omit } from "../utils/typeutils";
import { UserContextProps, withUser } from "./UserContext";

const { Browser } = Plugins;

type DeepLinkRouterKey = "edit-profile";

const deepLinkParser: DeepLinkParser<DeepLinkRouterKey> = makeDeepLinkParser<
  DeepLinkRouterKey
>([{ key: "edit-profile", route: "//edit-profile" }]);

interface DeepLinkHandlerContextValue {
  handleUrl: (
    url: string,
    fallback?: (url: string) => Promise<void>
  ) => Promise<void>;
}

export const DeepLinkHandlerContext = React.createContext<
  DeepLinkHandlerContextValue
>(null as any);

export interface DeepLinkHandlerContextProps {
  deepLinkHandlerContext: DeepLinkHandlerContextValue;
}

function defaultFallback(url: string) {
  return Browser.open({ url });
}

type Props = OurNavContextProps & UserContextProps;

const DeepLinkHandlerProviderImpl: React.FC<Props> = props => {
  const { navContext, userContext } = props;

  const handleUrl = React.useCallback(
    (
      url: string,
      fallback: (url: string) => Promise<void> = defaultFallback
    ) => {
      if (!isDeepLinkProtocol(url)) {
        return fallback(url);
      }
      const deepLinkRoute = deepLinkParser.match(url);
      if (deepLinkRoute == null) {
        return fallback(url);
      }
      switch (deepLinkRoute.routeKey) {
        case "edit-profile":
          if (userContext.user && userContext.user.type === UserType.borrower) {
            // Navigate to account tab first to prevent weird navigation state
            navContext.navigate("/account");
            setTimeout(() => {
              navContext.navigate("/account/edit-data");
            }, 200);
          }
          break;
        default:
          break;
      }
      return Promise.resolve();
    },
    [navContext, userContext]
  );

  return (
    <DeepLinkHandlerContext.Provider
      value={{
        handleUrl,
      }}
    >
      {props.children}
    </DeepLinkHandlerContext.Provider>
  );
};

export const DeepLinkHandlerProvider = withNav(
  withUser(DeepLinkHandlerProviderImpl)
);

export function withDeepLinkHandler<P extends DeepLinkHandlerContextProps>(
  Component: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof DeepLinkHandlerContextProps>> {
  const Wrapped: React.FC<Omit<P, keyof DeepLinkHandlerContextProps>> = (
    props: Omit<P, keyof DeepLinkHandlerContextProps>
  ) => (
    // Cast ...props as any due to typescript bug.
    // See https://github.com/microsoft/TypeScript/issues/28938
    <DeepLinkHandlerContext.Consumer>
      {context => (
        <Component {...props as any} deepLinkHandlerContext={context} />
      )}
    </DeepLinkHandlerContext.Consumer>
  );

  return Wrapped;
}

export const deepLinkProtocol = "money-plaza:";

function isDeepLinkProtocol(url: string): boolean {
  return !!new RegExp(`^${deepLinkProtocol}`).exec(url);
}
