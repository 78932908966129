import React from "react";
import autosize from "autosize";
import {
  FormattedMessage,
  Context as LocaleContext,
} from "@oursky/react-messageformat";
import classnames from "classnames";

import { KeyboardScrollHandler } from "../../utils/keyboard";
import { AttachmentButton } from "./AttachmentButton";

import { AttachmentType } from "../../types/misc";

import styles from "./styles.module.scss";

interface Props {
  disabled: boolean;
  isCompleted: boolean;
  onSendFile: (file: File, type: AttachmentType) => void;
  onSendMessage: (message: string) => Promise<void>;
  hasPermission: (feature: "camera" | "photoAlbum") => Promise<boolean>;
  openSettingPage: () => Promise<void>;
}

interface State {
  message: string;
  isSendButtonDisabled: boolean;
  isSendingMessage: boolean;
}

export class InputBox extends React.PureComponent<Props, State> {
  containerRef = React.createRef<HTMLDivElement>();
  textareaRef = React.createRef<HTMLTextAreaElement>();
  keyboardScrollHandler?: KeyboardScrollHandler;

  state: State = {
    message: "",
    isSendButtonDisabled: true,
    isSendingMessage: false,
  };

  componentDidMount() {
    if (this.containerRef.current) {
      this.keyboardScrollHandler = new KeyboardScrollHandler(
        this.containerRef.current
      );
    }

    if (this.textareaRef.current) {
      autosize(this.textareaRef.current);
    }
  }

  componentWillUnmount() {
    if (this.keyboardScrollHandler) {
      this.keyboardScrollHandler.destroy();
      this.keyboardScrollHandler = undefined;
    }
  }

  onInputChange: React.ChangeEventHandler<HTMLTextAreaElement> = e => {
    this.setState({
      message: e.currentTarget.value,
      isSendButtonDisabled: e.currentTarget.value.trim() === "",
    });
  };

  onSendClick = async (e: React.MouseEvent<unknown>) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.disabled) {
      return;
    }
    const trimmedMessage = this.state.message.trim();
    if (trimmedMessage !== "") {
      this.setState({ isSendButtonDisabled: true, isSendingMessage: true });
      await this.props.onSendMessage(trimmedMessage);

      this.setState(
        {
          message: "",
          isSendingMessage: false,
        },
        () => {
          if (this.textareaRef.current) {
            autosize.update(this.textareaRef.current);
          }
        }
      );
    }
  };

  render() {
    const { disabled, isCompleted } = this.props;
    const { message, isSendButtonDisabled, isSendingMessage } = this.state;
    return (
      <LocaleContext.Consumer>
        {({ renderToString }) => (
          <div ref={this.containerRef} className={styles.inputBox}>
            <textarea
              ref={this.textareaRef}
              placeholder={
                disabled
                  ? isCompleted
                    ? renderToString("chatroom.input_box.placeholder.completed")
                    : renderToString("chatroom.input_box.placeholder.blocked")
                  : renderToString("chatroom.input_box.placeholder")
              }
              value={message}
              onChange={this.onInputChange}
              disabled={disabled || isSendingMessage}
            />
            <AttachmentButton
              onSendFile={this.props.onSendFile}
              disabled={disabled || isSendingMessage}
              renderToString={renderToString}
              hasPermission={this.props.hasPermission}
              openSettingPage={this.props.openSettingPage}
            />
            <button
              className={classnames(styles.sendButton, {
                [styles.disabled]: disabled || isSendButtonDisabled,
              })}
              onClick={this.onSendClick}
            >
              <FormattedMessage id="chatroom.input_box.send" />
            </button>
          </div>
        )}
      </LocaleContext.Consumer>
    );
  }
}
