import { DateTime } from "luxon";

import { Industry } from "../models";
import { Omit } from "../utils/typeutils";

export interface BorrowerBriefData {
  id: string;
  userId: string;
  refNum: number;
  name: string | null;
}

export interface BorrowerData
  extends BorrowerBriefData,
    BorrowerExtraInfo,
    BorrowerLoanInfo,
    BorrowerBasicInfo,
    BorrowerPropertyInfo,
    BorrowerWorkInfo,
    BorrowerOtherIncomeInfo {
  email: string | null;
  birthday: DateTime | null;
}
// TODO(luihsuan): Remove BorrowerExtraInfo after the basic-info and work-info are intriduced
export interface BorrowerExtraInfo {
  education: Education | null;
  heardFrom: string | null;
  district: string | null;
  ownership: Ownership | null;
  premiseType: PremiseType | null;
  numOfOwner: number | null;
  isHired: boolean | null;
  industry: Industry | null;
  position: string | null;
  employmentType: string | null;
  salary: string | null;
  onBoardDate: string | null;
  incomeProofType: string | null;
  hasMortgage: boolean | null;
  monthlyRepayment: string | null;
}

export interface BorrowerPersonalInfo {
  // Personal Info
  salary: string | null;
  birthday: DateTime | null;
  industry: Industry | null;
  position: string | null;
  employmentType: string | null;
  onBoardDate: string | null;
  incomeProofType: string | null;

  // Living Info
  district: string | null;
  ownership: Ownership | null;
  premiseType: PremiseType | null;
  numOfOwner: number | null;

  // Loan Info
  existingLoans: ExistingLoan[];
}

export interface BorrowerLoanInfo {
  hasMortgage: boolean | null;
  existingLoans: ExistingLoan[];
}

export interface BorrowerBasicInfo {
  name: string | null;
  surname: string | null;
  gender: Gender | null;
  passportNumber: string | null;
  email: string | null;
  birthday: DateTime | null;
  education: Education | null;
  roomFloor: string | null;
  tower: string | null;
  nameOfBuilding: string | null;
  streetNameAndNumber: string | null;
  district: string | null;
  propertyStatus: PropertyStatus | null;
  monthlyRent: number | null;
  heardFrom: string | null;
  premiseType: PremiseType | null;
  numOfOwner: number | null;
}

export interface BorrowerPropertyInfo {
  numberOfProperties: number | null;
  propertyInfos: PropertyInfo[];
}

export interface BorrowerWorkInfo {
  isHired: boolean | null;
  industry: Industry | null;
  workingCompanyNames: string | null;
  position: string | null;
  employmentType: string | null;
  salary: string | null;
  onBoardDate: string | null;
  incomeProofType: string | null;
  paymentMethod: PaymentMethod | null;
}

export interface BorrowerOtherIncomeInfo {
  numberOfOtherIncomes: number | null;
  otherIncomes: OtherIncome[];
}

export interface ExistingLoan {
  id: string | null;
  lender: string | null;
  amount: number | null;
  tenor: number | null;
  remainingTenor: number | null;
  monthlyRepayment: number | null;
}

export interface PropertyInfo {
  id: string | null;
  roomFloor: string | null;
  tower: string | null;
  nameOfBuilding: string | null;
  streetNameAndNumber: string | null;
  district: string | null;
}

export interface OtherIncome {
  id: string | null;
  workingCompanyNames: string | null;
  position: string | null;
  salary: number | null;
  onBoardDate: string | null;
  incomeProofType: string | null;
  paymentMethod: PaymentMethod | null;
}

export interface District {
  id: string;
  value: string;
  nameId: string;
}

export type BorrowerExtraInfoWithoutEducation = Omit<
  BorrowerExtraInfo,
  "education"
>;
export type BorrowerBriefDataWithExtraInfo = BorrowerBriefData &
  BorrowerExtraInfo;

export type BorrowerDataWithoutID = Omit<
  BorrowerData,
  "id" | "refNum" | "userId"
>;

export enum Gender {
  male = "male",
  female = "female",
  other = "other",
  preferNotToSay = "prefer_not_to_say",
}

export enum Education {
  diploma = "diploma",
  higherDiploma = "higher_diploma",
  bachelor = "bachelor",
  postGraduate = "post_graduate",
}

export enum PropertyStatus {
  familyOwned = "family_owned",
  whollyOwned = "wholly_owned",
  jointlyOwned = "jointly_owned",
  leased = "leased",
  ownedByTheCompany = "owned_by_the_company",
}

export enum Ownership {
  selfOwned = "self_owned",
  familyOwned = "family_owned",
  rent = "rent",
}

export enum PremiseType {
  privateEstate = "private_estate",
  publicHousing = "public_housing",
  homeOwnershipSchemeCourts = "home_ownership_scheme_courts",
  villageHouse = "village_house",
  ntSmallHouse = "nt_small_house",
}

export interface Industry {
  id: string;
  type: string;
  nameEn: string;
  nameZhHant: string;
  nameZhHans: string;
}

export enum EmploymentType {
  fullTime = "full_time",
  partTime = "part_time",
}

export enum IncomeProofType {
  mpf = "mpf",
  bankStatement = "bank_statement",
  letter = "letter",
}

export enum PaymentMethod {
  automaticTransfer = "automatic_transfer",
  cheque = "cheque",
  cash = "cash",
}
