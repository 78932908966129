import React from "react";
import { RouteComponentProps } from "react-router";

import { BorrowerPersonalInfoView } from "../components/BorrowerPersonalInfoView";

import { withUser, UserContextProps } from "../context/UserContext";
import {
  withErrorAlert,
  ErrorAlertContextProps,
} from "../context/ErrorAlertContext";
import { withLoading, LoadingContextProps } from "../context/LoadingContext";
import { withChat, ChatContextProps } from "../context/ChatContext";

import { ChatWithConversation, BorrowerData } from "../models";

type Props = LoadingContextProps &
  ErrorAlertContextProps &
  UserContextProps &
  ChatContextProps &
  RouteComponentProps;

interface State {
  chat?: ChatWithConversation;
  borrower?: BorrowerData;
}

export interface BorrowerPersonalInfoScreenLocationState {
  conversationId?: string;
}

class BorrowerPersonalInfoScreenImpl extends React.PureComponent<Props, State> {
  state: State = {};

  constructor(props: Props) {
    super(props);
    const locationState = this.props.location.state;
    const conversationId = locationState && locationState.conversationId;
    const chat =
      props.chatContext.chatMessagesByConversation[conversationId] &&
      props.chatContext.chatMessagesByConversation[conversationId].chat;

    if (chat) {
      this.state = {
        chat: chat,
      };
    } else {
      this.state = {};
      this.props.history.goBack();
    }
  }

  componentDidMount() {
    const { chat } = this.state;
    if (chat) {
      this.props.loadingContext.show();
      this.props.userContext
        .getBorrowerByChatId(chat.id)
        .then(borrower => {
          this.setState({ borrower });
        })
        .catch(this.props.errorAlertContext.show)
        .finally(this.props.loadingContext.dismiss);
    }
  }

  render() {
    const { chat, borrower } = this.state;

    if (!chat) {
      return <div />;
    }

    return <BorrowerPersonalInfoView chat={chat} borrower={borrower} />;
  }
}

export const BorrowerPersonalInfoScreen = withLoading(
  withErrorAlert(withChat(withUser(BorrowerPersonalInfoScreenImpl)))
);

export default BorrowerPersonalInfoScreen;
