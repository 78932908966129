import React, { ReactNode } from "react";

import {
  NavDirection,
  RouterOutletOptions,
  isPlatform,
  RouterDirection,
} from "@ionic/core";
import {
  Action as HistoryAction,
  Location as HistoryLocation,
  UnregisterCallback,
} from "history";
import {
  match,
  matchPath,
  Redirect,
  RouteComponentProps,
  RouteProps,
  Switch,
  withRouter,
} from "react-router-dom";
import { iosPresentationAnimation, iosTransitionAnimation } from "./animations";
import {
  OurNavContext,
  OurNavContextState,
  OurNavDirection,
  ViewItem,
  ViewStack,
  ViewStacks,
} from "./OurNavContext";
import { generateUniqueId } from "./utils";

type OurNavManagerProps = RouteComponentProps;
type OurNavManagerState = OurNavContextState;

interface OurRouteData {
  match: match<{ tab: string }> | null;
  childProps: RouteProps & {
    preventGoBack?: boolean;
  };
}

function isNavDirection(x: OurNavDirection): x is NavDirection {
  return x !== "present" && x !== "dismiss";
}

interface LocationChange {
  location: HistoryLocation;
  action: HistoryAction;
}

class OurNavManagerImpl extends React.Component<
  OurNavManagerProps,
  OurNavManagerState
> {
  listenUnregisterCallback?: UnregisterCallback;
  activeViewId?: string;
  prevViewId?: string;

  isGoingBackBySwipe = false;
  pathToRevertForSwipeBack?: string;
  isRevertingSwipeBack = false;

  shouldPendLocationChange = false;
  pendedLocationChange?: LocationChange;

  constructor(props: OurNavManagerProps) {
    super(props);
    this.state = {
      viewStacks: {},
      hideView: this.hideView.bind(this),
      setupIonRouter: this.setupIonRouter.bind(this),
      removeViewStack: this.removeViewStack.bind(this),
      renderChild: this.renderChild.bind(this),
      navigate: this.navigate.bind(this),
      goBack: this.goBack.bind(this),
      transitionView: this.transitionView.bind(this),
      getActivePathForTab: this.getActivePathForTab.bind(this),
      getActiveTab: this.getActiveTab.bind(this),
    };
  }

  componentWillMount() {
    this.listenUnregisterCallback = this.props.history.listen(
      this.historyChange.bind(this)
    );
  }

  hideView(viewId: string) {
    const viewStacks = Object.assign({}, this.state.viewStacks);
    const { view } = this.findViewInfoById(viewId, viewStacks);
    this.shouldPendLocationChange = true;
    if (view) {
      view.show = false;
      view.key = generateUniqueId();
      this.setState(
        {
          viewStacks,
        },
        this.resumePendedLocationChange
      );
    }
  }

  historyChange(location: HistoryLocation, action: HistoryAction) {
    if (this.shouldPendLocationChange) {
      this.pendedLocationChange = { location, action };
    } else {
      this.setActiveView(location, action);
    }
  }

  findViewInfoByLocation(
    location: HistoryLocation,
    viewStacks: ViewStacks
  ): {
    view: ViewItem<OurRouteData> | undefined;
    viewStack: ViewStack | undefined;
    match: OurRouteData["match"] | null;
  } {
    let view: ViewItem<OurRouteData> | undefined;
    let match: OurRouteData["match"] | null = null;
    let viewStack: ViewStack | undefined;
    const keys = Object.keys(viewStacks);
    keys.some(key => {
      const vs = viewStacks[key];
      return vs.views.some(x => {
        match = matchPath(location.pathname, x.routeData.childProps);
        if (match) {
          view = x;
          viewStack = vs;
          return true;
        }
        return false;
      });
    });
    const result = { view, viewStack, match };
    return result;
  }

  findViewInfoById(
    id: string | undefined,
    viewStacks: ViewStacks
  ): {
    view: ViewItem<OurRouteData> | undefined;
    viewStack: ViewStack | undefined;
  } {
    let view: ViewItem<OurRouteData> | undefined;
    let viewStack: ViewStack | undefined;
    const keys = Object.keys(viewStacks);
    keys.some(key => {
      const vs = viewStacks[key];
      view = vs.views.find(x => x.id === id);
      if (view) {
        viewStack = vs;
        return true;
      }

      return false;
    });
    return { view, viewStack };
  }

  // NOTE:(jasonkit)
  // Keep the original structure as much as possible,
  // so that upgrading to future ionic-react release will be easier.
  // we disable the complexity checking.
  /* eslint-disable complexity */
  setActiveView(location: HistoryLocation, action: HistoryAction) {
    const viewStacks = Object.assign({}, this.state.viewStacks);

    const {
      view: enteringView,
      viewStack: enteringViewStack,
      match,
    } = this.findViewInfoByLocation(location, viewStacks);
    let direction: OurNavDirection = location.state && location.state.direction;

    if (!enteringViewStack) {
      return;
    }

    const {
      view: leavingView,
      viewStack: leavingViewStack,
    } = this.findViewInfoById(this.activeViewId, viewStacks);

    if (
      leavingView &&
      leavingView.routeData.match &&
      leavingView.routeData.match.url === location.pathname
    ) {
      return;
    }

    const isPresent = this.isMatchClosePath(leavingView, leavingViewStack);
    const isDismiss = this.isMatchClosePath(enteringView, enteringViewStack);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const { isGoingBackBySwipe, isRevertingSwipeBack } = this;

    if (enteringView) {
      /**
       * If the page is being pushed into the stack by another view,
       * record the view that originally directed to the new view for back button purposes.
       */
      if (!enteringView.show && action === "PUSH") {
        enteringView.prevId = leavingView && leavingView.id;
      }

      enteringView.show = true;
      enteringView.mount = true;
      enteringView.routeData.match = match;
      enteringView.preventHide = this.isGoingBackBySwipe;

      enteringViewStack.activeId = enteringView.id;
      this.activeViewId = enteringView.id;

      if (
        enteringView.routeData.match &&
        enteringView.routeData.match.url !== enteringViewStack.closePath
      ) {
        enteringViewStack.isActive = true;
      }

      if (leavingView) {
        this.prevViewId = leavingView.id;
        if (
          leavingView.routeData.match &&
          enteringView.routeData.match &&
          leavingView.routeData.match.params.tab ===
            enteringView.routeData.match.params.tab
        ) {
          if (action === "PUSH") {
            direction = direction || "forward";
          } else {
            direction = direction || "back";
            if (!(isDismiss || isGoingBackBySwipe || isRevertingSwipeBack)) {
              leavingView.mount = false;
            }
          }
        }
        /**
         * Attempt to determine if the leaving view is a route redirect.
         * If it is, take it out of the rendering phase.
         * We assume Routes with render props are redirects, because of this users should not use
         * the render prop for non redirects, and instead provide a component in its place.
         */
        if (
          leavingView.element.type === Redirect ||
          leavingView.element.props.render
        ) {
          leavingView.mount = false;
          leavingView.show = false;
        }

        if (isDismiss) {
          direction = "dismiss";
        } else if (isPresent) {
          direction = "present";
        } else if (this.isParentView(enteringView, leavingView, viewStacks)) {
          this.unmountViewsBetween(leavingView, enteringView, viewStacks);
          direction = "back";
        }
      }

      if (isDismiss || isGoingBackBySwipe || isRevertingSwipeBack) {
        const enteringEl =
          enteringView.ref && enteringView.ref.current
            ? enteringView.ref.current
            : undefined;

        const leavingEl =
          leavingView && leavingView.ref && leavingView.ref.current
            ? leavingView.ref.current
            : undefined;

        if (enteringEl) {
          this.transitionView(
            enteringEl,
            leavingEl,
            enteringViewStack.routerOutlet,
            direction,
            () => {
              // NOTE(jasonkit)
              // Here we use the lastest this.isRevertiingSwipeBack
              // as this will update at onEnd
              if (
                !this.isRevertingSwipeBack &&
                isGoingBackBySwipe &&
                leavingView
              ) {
                leavingView.mount = false;
                leavingView.show = false;
              }

              if (leavingViewStack && isDismiss) {
                leavingViewStack.isActive = false;
                this.unmountViewsOnStacks(leavingViewStack);
              }

              this.setState({
                viewStacks,
                activeViewStackId: enteringViewStack.stackId,
              });

              if (isRevertingSwipeBack) {
                this.isRevertingSwipeBack = false;
              }
            }
          );
        }
      } else {
        this.setState(
          {
            viewStacks,
            activeViewStackId: enteringViewStack.stackId,
          },
          () => {
            const enteringEl =
              enteringView.ref && enteringView.ref.current
                ? enteringView.ref.current
                : undefined;
            const leavingEl =
              leavingView && leavingView.ref && leavingView.ref.current
                ? leavingView.ref.current
                : undefined;

            if (enteringEl) {
              this.transitionView(
                enteringEl,
                leavingEl,
                enteringViewStack.routerOutlet,
                direction
              );
            }
          }
        );
      }
    }
  }
  /* eslint-enable complexity */

  private isParentView(
    enteringView: ViewItem<OurRouteData>,
    leavingView: ViewItem<OurRouteData>,
    viewStacks: ViewStacks
  ): boolean {
    if (
      (enteringView.routeData.match &&
        enteringView.routeData.match.params.tab) !==
      (leavingView.routeData.match && leavingView.routeData.match.params.tab)
    ) {
      return false;
    }

    if (leavingView.prevId === enteringView.id) {
      return true;
    } else if (leavingView.prevId) {
      const { view: prevView } = this.findViewInfoById(
        leavingView.prevId,
        viewStacks
      );
      if (prevView && prevView.mount && prevView.show) {
        return this.isParentView(enteringView, prevView, viewStacks);
      }
    }
    return false;
  }

  private isMatchClosePath(
    view?: ViewItem<OurRouteData>,
    viewStack?: ViewStack
  ) {
    return (
      view &&
      viewStack &&
      view.routeData.match &&
      view.routeData.match.url === viewStack.closePath
    );
  }

  private unmountViewsOnStacks(viewStack?: ViewStack) {
    if (!viewStack) return;

    for (const view of viewStack.views) {
      if (!this.isMatchClosePath(view, viewStack)) {
        view.mount = false;
        view.show = false;
      }
    }
  }

  private unmountViewsBetween(
    startView: ViewItem<OurRouteData>,
    endView: ViewItem<OurRouteData>,
    viewStacks: ViewStacks
  ) {
    let curView = startView;
    while (true) {
      curView.mount = false;
      curView.show = curView.id === startView.id;

      const { view } = this.findViewInfoById(curView.prevId, viewStacks);
      if (!view || view.id === endView.id) {
        return;
      }
      curView = view;
    }
  }

  componentWillUnmount() {
    if (this.listenUnregisterCallback) {
      this.listenUnregisterCallback();
    }
  }

  setupIonRouter(
    id: string,
    children: ReactNode,
    routerOutlet: HTMLIonRouterOutletElement,
    isPresentation: boolean,
    closePath: string | undefined
  ) {
    const views: ViewItem[] = [];
    let activeId: string | undefined;

    const addView = (child: React.ReactElement<any>) => {
      const location = this.props.history.location;
      const viewId = generateUniqueId();
      const key = generateUniqueId();
      const element = child;
      const match: OurRouteData["match"] | null = matchPath(
        location.pathname,
        child.props
      );
      const view: ViewItem<OurRouteData> = {
        id: viewId,
        key,
        routeData: {
          match,
          childProps: child.props,
        },
        element,
        mount: true,
        show: !!match,
        ref: React.createRef(),
        preventHide: false,
      };
      if (match) {
        activeId = viewId;
      }
      views.push(view);
      return activeId;
    };

    React.Children.forEach(children as any, (child: React.ReactElement) => {
      if (child.type === Switch) {
        /**
         * If the first child is a Switch, loop through its children to build the viewStack
         */
        React.Children.forEach(
          child.props.children,
          (grandChild: React.ReactElement) => {
            addView.call(this, grandChild);
          }
        );
      } else {
        addView.call(this, child);
      }
    });

    if (activeId) {
      this.registerViewStack(
        id,
        activeId,
        views,
        routerOutlet,
        this.props.location,
        isPresentation,
        closePath
      );
    }
  }

  navigate(path: string, state?: any, direction?: RouterDirection) {
    this.props.history.push(path, {
      ...(state ? state : {}),
      direction,
    });
  }

  resumePendedLocationChange = () => {
    if (this.pendedLocationChange) {
      const { location, action } = this.pendedLocationChange;
      this.pendedLocationChange = undefined;
      this.setActiveView(location, action);
    }
    this.shouldPendLocationChange = false;
  };

  registerViewStack(
    stack: string,
    activeId: string,
    stackItems: ViewItem[],
    routerOutlet: HTMLIonRouterOutletElement,
    location: HistoryLocation,
    isPresentation: boolean,
    closePath: string | undefined
  ) {
    if (isPlatform(window, "ios")) {
      routerOutlet.swipeHandler = this;
    }

    this.shouldPendLocationChange = true;
    this.setState(
      prevState => {
        const prevViewStacks = Object.assign({}, prevState.viewStacks);
        prevViewStacks[stack] = {
          stackId: stack,
          activeId: activeId,
          views: stackItems,
          routerOutlet,
          isPresentation,
          closePath,
          isActive: false,
        };

        return {
          viewStacks: prevViewStacks,
          activeViewStackId:
            prevState.activeViewStackId || (isPresentation ? undefined : stack),
        };
      },
      () => {
        const { view: activeView } = this.findViewInfoById(
          activeId,
          this.state.viewStacks
        );

        if (activeView) {
          this.prevViewId = this.activeViewId;
          this.activeViewId = activeView.id;
          const direction = location.state && location.state.direction;
          const { view: prevView } = this.findViewInfoById(
            this.prevViewId,
            this.state.viewStacks
          );
          if (activeView.ref && activeView.ref.current) {
            this.transitionView(
              activeView.ref.current,
              (prevView && prevView.ref && prevView.ref.current) || undefined,
              routerOutlet,
              direction
            );
          }
        }
        this.resumePendedLocationChange();
      }
    );
  }

  removeViewStack(stack: string) {
    this.shouldPendLocationChange = true;
    this.setState(state => {
      const viewStacks = Object.assign({}, state.viewStacks);
      delete viewStacks[stack];
      return {
        viewStacks,
      };
    }, this.resumePendedLocationChange);
  }

  renderChild(item: ViewItem<OurRouteData>) {
    const component = React.cloneElement(item.element, {
      location: this.props.location,
      computedMatch: item.routeData.match,
    });
    return component;
  }

  findActiveView(views: ViewItem[]) {
    let view: ViewItem<OurRouteData> | undefined;
    views.some(x => {
      const match = matchPath(
        this.props.location.pathname,
        x.routeData.childProps
      );
      if (match) {
        view = x;
        return true;
      }
      return false;
    });
    return view;
  }

  goBack = (defaultHref?: string) => {
    const {
      view: leavingView,
      viewStack: leavingViewStack,
    } = this.findViewInfoByLocation(this.props.location, this.state.viewStacks);

    let href = defaultHref || "/";

    if (leavingView) {
      if (leavingView.routeData.childProps.preventGoBack) {
        return;
      }

      if (leavingViewStack && leavingViewStack.isPresentation) {
        href = defaultHref || leavingViewStack.closePath || "/close";
      }

      const { view: enteringView } = this.findViewInfoById(
        leavingView.prevId,
        this.state.viewStacks
      );
      if (enteringView && enteringView.routeData.match) {
        href = enteringView.routeData.match.url;
      }
    }

    this.props.history.replace(href, { direction: "back" });
  };

  transitionView(
    enteringEl: HTMLElement,
    leavingEl: HTMLElement | undefined,
    ionRouterOuter: HTMLIonRouterOutletElement,
    direction: OurNavDirection,
    callback?: () => void
  ) {
    /**
     * Super hacky workaround to make sure ionRouterOutlet is available
     * since transitionView might be called before IonRouterOutlet is fully mounted
     */
    if (ionRouterOuter && ionRouterOuter.componentOnReady) {
      this.commitView(
        enteringEl,
        leavingEl,
        ionRouterOuter,
        direction,
        callback
      );
    } else {
      setTimeout(() => {
        this.transitionView(
          enteringEl,
          leavingEl,
          ionRouterOuter,
          direction,
          callback
        );
      }, 10);
    }
  }

  private async commitView(
    enteringEl: HTMLElement,
    leavingEl: HTMLElement | undefined,
    ionRouterOuter: HTMLIonRouterOutletElement,
    direction: OurNavDirection,
    callback?: () => void
  ) {
    await ionRouterOuter.componentOnReady();
    let options: RouterOutletOptions = {};
    let leavingElInUse = leavingEl;

    if (leavingEl && enteringEl !== leavingEl) {
      leavingEl.classList.add("disable-user-interaction");
    }

    if (isNavDirection(direction)) {
      leavingElInUse = this.isRevertingSwipeBack ? undefined : leavingEl;
      options = {
        deepWait: true,
        duration: direction === undefined ? 0 : undefined,
        direction: direction,
        showGoBack: direction === "forward",
        progressAnimation: this.isGoingBackBySwipe,
        animationBuilder: isPlatform(window, "ios")
          ? iosTransitionAnimation
          : undefined,
      };
    } else {
      options = {
        deepWait: true,
        duration: undefined,
        direction: direction === "present" ? "forward" : "back",
        showGoBack: false,
        progressAnimation: false,
        animationBuilder: isPlatform(window, "ios")
          ? iosPresentationAnimation
          : undefined,
      };
    }

    /*
     * NOTE(jasonkit):
     * This hack is for workaround macOS safari rendering bug
     * Ref: https://github.com/oursky/money-plaza/issues/741
     * */
    const tabBar = document.querySelector("ion-tab-bar");
    if (tabBar) {
      tabBar.style.opacity = "0.999";
    }
    await ionRouterOuter
      .commit(enteringEl, leavingElInUse, options)
      .then(() => {
        if (tabBar) {
          tabBar.style.opacity = "1";
        }
        if (callback) {
          callback();
        }
      });

    if (leavingEl && enteringEl !== leavingEl) {
      /**
       *  add hidden attributes
       */
      leavingEl.classList.remove("disable-user-interaction");
      leavingEl.classList.add("ion-page-hidden");
      leavingEl.setAttribute("aria-hidden", "true");
    }
  }

  render() {
    return (
      <OurNavContext.Provider value={this.state}>
        {this.props.children}
      </OurNavContext.Provider>
    );
  }

  getGoBackPath = () => {
    const { view: leavingView } = this.findViewInfoByLocation(
      this.props.location,
      this.state.viewStacks
    );
    if (leavingView) {
      const { view: enteringView } = this.findViewInfoById(
        leavingView.prevId,
        this.state.viewStacks
      );

      if (
        enteringView &&
        enteringView.routeData.match &&
        leavingView.routeData.match &&
        !leavingView.routeData.childProps.preventGoBack
      ) {
        if (
          leavingView.routeData.match.params.tab ===
          enteringView.routeData.match.params.tab
        ) {
          return enteringView.routeData.match.url;
        }
      }
    }
    return null;
  };

  getActiveStack = (): ViewStack | null => {
    for (const stackId of Object.keys(this.state.viewStacks)) {
      const stack = this.state.viewStacks[stackId];
      if (stack.isActive) {
        return stack;
      }
    }
    return null;
  };

  getActivePathForTab(tab: string): string | null {
    const stack = this.getActiveStack();
    if (!stack) {
      return null;
    }

    const views: ViewItem<OurRouteData>[] = [];
    let prevId: string | undefined;
    for (const view of stack.views) {
      if (
        view.mount &&
        view.routeData.match &&
        view.routeData.match.params.tab === tab
      ) {
        views.push(view);

        if (view.routeData.match.url === `/${tab}`) {
          prevId = view.prevId;
        }
      }
    }
    const mostActiveView = this.getMostActiveView(views, prevId);
    if (mostActiveView) {
      return (
        mostActiveView.routeData.match && mostActiveView.routeData.match.url
      );
    }

    return null;
  }

  getActiveTab(): string | null {
    const stack = this.getActiveStack();
    if (!stack) {
      return null;
    }

    if (stack.activeId) {
      const { view } = this.findViewInfoById(
        stack.activeId,
        this.state.viewStacks
      );
      return (
        (view && view.routeData.match && view.routeData.match.params.tab) ||
        null
      );
    }
    return null;
  }

  private getMostActiveView(
    views: ViewItem<OurRouteData>[],
    prevId: string | undefined
  ) {
    let mostActiveView: ViewItem<OurRouteData> | undefined;
    let rest: ViewItem<OurRouteData>[] = [];
    let prevLength = views.length;
    while (views.length) {
      for (const view of views) {
        if (view.prevId === (mostActiveView ? mostActiveView.id : prevId)) {
          mostActiveView = view;
        } else {
          rest.push(view);
        }
      }

      views = rest;
      rest = [];

      // Safe guard, ensure no forever loop happpend.
      if (views.length === prevLength) {
        return null;
      }

      prevLength = views.length;
    }

    return mostActiveView;
  }

  // For swipeHandler
  canStart = (): boolean => {
    const path = this.getGoBackPath();
    return path != null;
  };

  onStart = () => {
    const path = this.getGoBackPath();
    if (path) {
      this.isGoingBackBySwipe = true;
      this.pathToRevertForSwipeBack = this.props.location.pathname;
      this.goBack();
    }
  };

  onEnd = (shouldComplete: boolean) => {
    this.isGoingBackBySwipe = false;
    if (!shouldComplete && this.pathToRevertForSwipeBack) {
      this.isRevertingSwipeBack = true;
      this.props.history.push(this.pathToRevertForSwipeBack);
    }

    this.pathToRevertForSwipeBack = undefined;
  };
}

export const OurNavManager = withRouter(OurNavManagerImpl);
OurNavManager.displayName = "OurNavManager";
