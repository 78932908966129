import { FormattedMessage } from "@oursky/react-messageformat";
import classNames from "classnames";
import React, { ChangeEvent, FormEventHandler, PureComponent } from "react";

import { BooleanString, CreateMortgageForm, OfferingType } from "../../models";
import { formattedRate } from "../../utils/numeric";
import { isLoading, RemoteData } from "../../utils/remoteData";

import { PrimaryButton } from "../Button";
import ErrorField from "../ErrorField";
import {
  FormattedDropdown,
  Option as FormattedOption,
} from "../FormattedDropdown";
import { FormattedInput } from "../FormattedInput";
import { MPInput } from "../input";
import {
  ValidDaysDropdown,
  ValidDaysOptions,
  RepaymentPeriodDropdown,
  YearPeriodOptions,
} from "../SpecifyDropdown";

import styles from "./styles.module.scss";
import { ValidationError, Validator, validate } from "../../utils/validate";
import {
  currencyFormatToNumber,
  formatToCurrencyAmount,
} from "../../utils/formatToCurrencyAmount";

export type InputFields =
  | "loanAmount"
  | "interestRateMin"
  | "interestRateMax"
  | "validDays";

interface Props {
  remoteCreate: RemoteData<void>;
  onSubmit: (values: CreateMortgageForm) => Promise<boolean>;
}

interface FormValues {
  loanAmount: string;
  paymentPeriodMax: string;
  interestRateMin: string;
  interestRateMax: string;
  validDays: string;
  isRequiredIncome: BooleanString;
  addition: string;
}

interface State {
  values: FormValues;
  error: ValidationError<InputFields>;
  focusField?: InputFields;
  nextField?: InputFields;
}

const defaultFormValues = {
  loanAmount: "",
  paymentPeriodMax: YearPeriodOptions[0].value,
  interestRateMin: "",
  interestRateMax: "",
  validDays: ValidDaysOptions[0].value,
  isRequiredIncome: BooleanString.true,
  addition: "",
};

class OfferMortgageForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      values: { ...defaultFormValues },
      error: {},
    };
  }

  componentDidMount() {
    this.resetState();
  }

  resetState = () => {
    this.setState({
      values: { ...defaultFormValues },
      error: {},
    });
  };

  componentDidUpdate() {
    this.resetNextField();
  }

  private resetNextField() {
    if (this.state.nextField) {
      this.setState({ nextField: undefined });
    }
  }

  focusInterestRateMaxField = () => {
    this.setState({ nextField: "interestRateMax" });
  };

  focusValidDaysField = () => {
    this.setState({ nextField: "validDays" });
  };

  renderRatioPeriodRow = () => {
    const { error, values, focusField } = this.state;

    return (
      <div className={styles.row}>
        <div className={styles.col}>
          <label className={styles.labelText} htmlFor="ratio">
            <FormattedMessage id="create_offering.mortgage.ratio.label" />
          </label>
          <div className={styles.rateInputContainer}>
            <MPInput
              id="ratio"
              value={values.loanAmount}
              onChange={this.handleLoanAmountChange}
              placeholder="HKD"
              maxLength={11}
              isError={!!error.loanAmount}
              type="tel"
              shouldFocus={focusField === "loanAmount"}
            />
          </div>
          <ErrorField messageId={error.loanAmount} />
        </div>
        <div className={styles.colRight}>
          <label className={styles.labelText} htmlFor="repayment_period">
            <FormattedMessage id="create_offering.mortgage.repayment_period_max.label" />
          </label>
          <RepaymentPeriodDropdown
            id="repayment_period"
            containerClass={styles.dropdownContainer}
            selectClass={styles.dropdownSelect}
            errorClass={styles.dropdownError}
            emptyClass={styles.dropdownEmpty}
            isError={false}
            value={values.paymentPeriodMax}
            onValueChange={this.handleMortgagePeriodChange}
          />
        </div>
      </div>
    );
  };

  renderInterestRateSection = () => {
    const { error, values, focusField, nextField } = this.state;

    return (
      <div className={styles.row}>
        <div className={styles.col}>
          <label className={styles.labelText} htmlFor="interest_rate_min">
            <FormattedMessage id="create_offering.mortgage.interest_rate_min.label" />
          </label>
          <div className={styles.rateInputContainer}>
            <MPInput
              id="interest_rate_min"
              placeholder="0-99"
              type="number"
              maxLength={5}
              value={values.interestRateMin}
              isError={!!error.interestRateMin}
              onBlur={this.handleInterestRateChange}
              onChange={this.handleRateMinChange}
              shouldFocus={focusField === "interestRateMin"}
              autoSubmit={this.focusInterestRateMaxField}
            />
            <span>%</span>
          </div>
          <ErrorField messageId={error.interestRateMin} />
        </div>
        <div className={styles.colRight}>
          <label className={styles.labelText} htmlFor="interest_rate_max">
            <FormattedMessage id="create_offering.mortgage.interest_rate_max.label" />
          </label>
          <div className={styles.rateInputContainer}>
            <MPInput
              id="interest_rate_max"
              placeholder="0-99"
              type="number"
              maxLength={5}
              value={values.interestRateMax}
              isError={!!error.interestRateMax}
              onBlur={this.handleInterestRateChange}
              onChange={this.handleRateMaxChange}
              shouldFocus={
                focusField === "interestRateMax" ||
                nextField === "interestRateMax"
              }
              autoSubmit={this.focusValidDaysField}
            />
            <span>%</span>
          </div>
          <ErrorField messageId={error.interestRateMax} />
        </div>
      </div>
    );
  };

  renderValidDaysSection = () => {
    const { values, nextField } = this.state;

    return (
      <div className={styles.col} data-anchor="validDays">
        <label className={styles.labelText}>
          <FormattedMessage id="create_offering.exchange.valid_days.label" />
        </label>
        <ValidDaysDropdown
          containerClass={styles.dropdownContainer}
          selectClass={styles.dropdownSelect}
          errorClass={styles.dropdownError}
          emptyClass={styles.dropdownEmpty}
          isError={false}
          value={values.validDays}
          onValueChange={this.handleValidDaysChange}
          shouldScrollTo={nextField === "validDays"}
          scrollAnchor="validDays"
        />
      </div>
    );
  };

  renderRequiredIncomeDropdown = () => {
    const { values } = this.state;

    const EmployedOptions: FormattedOption[] = [
      {
        key: "true",
        value: BooleanString.true,
        labelId: "create_offering.mortgage.required_income.yes",
      },
      {
        key: "false",
        value: BooleanString.false,
        labelId: "create_offering.mortgage.required_income.no",
      },
    ];
    return (
      <>
        <label className={styles.labelText} htmlFor="required_income">
          <FormattedMessage id="create_offering.mortgage.required_income.label" />
        </label>
        <FormattedDropdown
          id="required_income"
          containerClass={classNames(
            styles.dropdownContainer,
            styles.requirementText
          )}
          selectClass={styles.dropdownSelect}
          errorClass={styles.dropdownError}
          emptyClass={styles.dropdownEmpty}
          isError={false}
          options={EmployedOptions}
          value={values.isRequiredIncome}
          onValueChange={this.handleRequiredIncomeChange}
        />
      </>
    );
  };

  renderRequirementSection = () => {
    const { values } = this.state;

    return (
      <div className={styles.col}>
        <h3>
          <FormattedMessage id="create_offering.mortgage.requirement.title" />
        </h3>
        {this.renderRequiredIncomeDropdown()}
        <div className={styles.col}>
          <label className={styles.labelText} htmlFor="addition">
            <FormattedMessage id="create_offering.mortgage.addition.label" />
          </label>
          <FormattedInput
            className={styles.requirementText}
            id="addition"
            maxLength={150}
            placeholderId="create_offering.mortgage.addition.placeholder"
            value={values.addition}
            onChange={this.handleAdditionChange}
            autoSubmit={this.doSubmit}
          />
        </div>
      </div>
    );
  };

  render() {
    const { remoteCreate } = this.props;
    return (
      <form noValidate={true} onSubmit={this.handleSubmit}>
        <div className={styles.formContainer}>
          {this.renderRatioPeriodRow()}
          {this.renderInterestRateSection()}
          {this.renderValidDaysSection()}
          {this.renderRequirementSection()}
        </div>

        <PrimaryButton
          class={styles.submitButton}
          type="submit"
          expand="full"
          disabled={isLoading(remoteCreate)}
        >
          <FormattedMessage id="create_offering.submit" />
        </PrimaryButton>
      </form>
    );
  }

  handleLoanAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    const amount: string = e.target.value || "";
    const formattedAmount = formatToCurrencyAmount(amount) || "";
    this.setState(({ values }) => {
      return {
        values: { ...values, loanAmount: formattedAmount },
      };
    });
  };

  handleMortgagePeriodChange = (paymentPeriodMax: string) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, paymentPeriodMax },
      };
    });
  };

  isValidRate(rateString: string): boolean {
    if (rateString === "") {
      return true;
    }

    const rate = parseFloat(rateString);
    if (isNaN(rate) || rate > 100 || rate < 0) {
      return false;
    }
    return true;
  }

  handleRateMinChange = (e: ChangeEvent<HTMLInputElement>) => {
    const interestRate: string = e.target.value || "";
    const rate = formattedRate(interestRate);
    this.setState(({ values }) => {
      return {
        values: {
          ...values,
          interestRateMin: rate === null ? values.interestRateMin : rate,
        },
      };
    });
  };

  handleRateMaxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const interestRate: string = e.target.value || "";
    const rate = formattedRate(interestRate);
    this.setState(({ values }) => {
      return {
        values: {
          ...values,
          interestRateMax: rate === null ? values.interestRateMax : rate,
        },
      };
    });
  };

  handleInterestRateChange = () => {
    this.setState(({ values }) => {
      const min = parseFloat(values.interestRateMin);
      const max = parseFloat(values.interestRateMax);
      if (
        values.interestRateMin !== "" &&
        values.interestRateMax !== "" &&
        min > max
      ) {
        return {
          values: {
            ...values,
            interestRateMax: values.interestRateMin,
            interestRateMin: values.interestRateMax,
          },
        };
      }
      return { values };
    });
  };

  handleValidDaysChange = (validDays: string) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, validDays },
      };
    });
  };

  handleRequiredIncomeChange = (isRequired: string) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, isRequiredIncome: isRequired as BooleanString },
      };
    });
  };

  handleAdditionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const addition: string = e.target.value || "";
    this.setState(({ values }) => {
      return {
        values: { ...values, addition },
      };
    });
  };

  handleSubmit: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    this.doSubmit();
  };

  doSubmit = () => {
    this.setState({ focusField: undefined }, () => {
      const { values } = this.state;

      if (!this.validateOfferData(values)) return;

      const formattedFormValues: CreateMortgageForm = {
        type: OfferingType.mortgage,
        loanAmount: currencyFormatToNumber(values.loanAmount),
        paymentPeriodMax: parseInt(values.paymentPeriodMax, 10),
        interestRateMin: values.interestRateMin,
        interestRateMax: values.interestRateMax,
        isRequiredEmployed: values.isRequiredIncome === BooleanString.true,
        addition: values.addition,
        validDays: parseInt(values.validDays, 10),
      };

      this.props.onSubmit(formattedFormValues).then(isSubmitted => {
        if (isSubmitted) {
          this.resetState();
        }
      });
    });
  };

  validateOfferData(values: FormValues) {
    const { interestRateMin, interestRateMax, loanAmount } = values;

    const validators: Validator<InputFields>[] = [
      {
        field: "loanAmount",
        invalidCondition: !loanAmount,
        errorMessageId: "create_offering.mortgage.error.required.loan_amount",
      },
      {
        field: "loanAmount",
        invalidCondition:
          loanAmount !== "" && currencyFormatToNumber(loanAmount) < 0,
        errorMessageId: "create_offering.mortgage.error.invalid.loan_amount",
      },
      {
        field: "interestRateMin",
        invalidCondition: !interestRateMin,
        errorMessageId: "create_offering.mortgage.error.required.interest_rate",
      },
      {
        field: "interestRateMin",
        invalidCondition:
          interestRateMin !== "" &&
          (parseFloat(interestRateMin) >= 100 ||
            parseFloat(interestRateMin) < 0),
        errorMessageId:
          "create_offering.personal_loan.error.invalid.interest_rate",
      },
      {
        field: "interestRateMax",
        invalidCondition: !interestRateMax,
        errorMessageId: "create_offering.mortgage.error.required.interest_rate",
      },
      {
        field: "interestRateMax",
        invalidCondition:
          interestRateMax !== "" &&
          (parseFloat(interestRateMax) >= 100 ||
            parseFloat(interestRateMax) < 0),
        errorMessageId:
          "create_offering.personal_loan.error.invalid.interest_rate",
      },
    ];

    const { focusField, error, isValid } = validate(validators);

    this.setState({ error, focusField });

    return isValid;
  }
}

export default OfferMortgageForm;
