import React from "react";

import { OptionApiClient } from "../apiClient/option";
import { District, Industry } from "../models";
import { CurrencyOption } from "../types/option";
import { Omit } from "../utils/typeutils";

const DefaultCurrencyOption: CurrencyOption[] = [
  { id: "1", isoCode: "USD" },
  { id: "2", isoCode: "HKD" },
  { id: "3", isoCode: "CNY" },
  { id: "4", isoCode: "JPY" },
  { id: "5", isoCode: "GBP" },
  { id: "6", isoCode: "EUR" },
  { id: "7", isoCode: "CHF" },
  { id: "8", isoCode: "AUD" },
  { id: "9", isoCode: "CAD" },
  { id: "10", isoCode: "KRW" },
  { id: "11", isoCode: "THB" },
];

export const StaticDistrictOption: District[] = [
  {
    id: "1",
    value: "central_and_western",
    nameId: "option.district.centralAndWestern",
  },
  { id: "2", value: "eastern", nameId: "option.district.eastern" },
  { id: "3", value: "southern", nameId: "option.district.southern" },
  { id: "4", value: "wan_chai", nameId: "option.district.wanChai" },
  { id: "5", value: "sham_shui_po", nameId: "option.district.shamShuiPo" },
  { id: "6", value: "kowloon_city", nameId: "option.district.kowloonCity" },
  { id: "7", value: "kwun_tong", nameId: "option.district.kwunTong" },
  { id: "8", value: "wong_tai_sin", nameId: "option.district.wongTaiSin" },
  { id: "9", value: "yau_tsim_mong", nameId: "option.district.yauTsimMong" },
  { id: "10", value: "islands", nameId: "option.district.islands" },
  { id: "11", value: "kwai_tsing", nameId: "option.district.kwaiTsing" },
  { id: "12", value: "north", nameId: "option.district.north" },
  { id: "13", value: "sai_kung", nameId: "option.district.saiKung" },
  { id: "14", value: "sha_tin", nameId: "option.district.shaTin" },
  { id: "15", value: "tai_po", nameId: "option.district.taiPo" },
  { id: "16", value: "tsuen_wan", nameId: "option.district.tsuenWan" },
  { id: "17", value: "tuen_mun", nameId: "option.district.tuenMun" },
  { id: "18", value: "yuen_long", nameId: "option.district.yuenLong" },
];

export interface OptionsContextValue {
  currencyOptions: CurrencyOption[];
  districtOptions: District[];
  industryOptions: Industry[];
  fetch: () => Promise<void>;
}

const OptionsContext = React.createContext(null as any);
export { OptionsContext };

export interface OptionsProps {
  options: OptionsContextValue;
}

interface Props {
  apiClient: OptionApiClient;
}

type State = Omit<OptionsContextValue, "fetch">;

export class OptionsContextProvider extends React.PureComponent<Props, State> {
  state: State = {
    currencyOptions: DefaultCurrencyOption,
    districtOptions: StaticDistrictOption,
    industryOptions: [],
  };

  fetch = async (): Promise<void> => {
    const { apiClient } = this.props;
    this.setState({
      currencyOptions: await apiClient.fetchCurrencyOption(),
      industryOptions: await apiClient.fetchIndustryOption(),
    });
  };

  render() {
    return (
      <OptionsContext.Provider
        value={{
          currencyOptions: this.state.currencyOptions,
          districtOptions: this.state.districtOptions,
          industryOptions: this.state.industryOptions,
          fetch: this.fetch,
        }}
      >
        {this.props.children}
      </OptionsContext.Provider>
    );
  }
}

export function withOptions<P extends OptionsProps>(
  Component: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof OptionsProps>> {
  const Wrapped: React.FC<Omit<P, keyof OptionsProps>> = (
    props: Omit<P, keyof OptionsProps>
  ) => (
    // Cast ...props as any due to typescript bug.
    // See https://github.com/microsoft/TypeScript/issues/28938
    <OptionsContext.Consumer>
      {options => <Component {...props as any} options={options} />}
    </OptionsContext.Consumer>
  );

  return Wrapped;
}
