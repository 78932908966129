import * as yup from "yup";

export enum APIErrorCode {
  agentIsBlocked = 1002,
  userNotFound = 2001,
  phoneNumberTaken = 2002,
  smsRateLimited = 2003,
  smsNotSent = 2004,
  invalidVerificationCode = 2005,
  emailTaken = 2006,
  agentNotValidated = 2007,
  requestNotFound = 3001,
  alreadyOfferedByColleague = 4003,
  chatConversationNotFound = 6001,
  chatInvalidKinds = 6002,
  skygearUnknwonError = 10000,
  networkUnavailable = 20000,
  unexpectedLambdaResponse = 20001,
}

export function parseError(e: any): Error {
  if (e instanceof Error) {
    return e;
  }

  const maybeAPIError = parseMaybeAPIError("error" in e ? e.error : e);
  if (maybeAPIError) {
    return maybeAPIError;
  }

  // Regarding unknown error as network error
  return new APIError(APIErrorCode.networkUnavailable, "Network unavailable");
}

export function getAPIErrorMessageId(error: APIError): string {
  switch (error.code) {
    case APIErrorCode.agentIsBlocked:
      return "error.api.agent_is_blocked";
    case APIErrorCode.userNotFound:
      return "error.api.user_not_found";
    case APIErrorCode.smsRateLimited:
      return "error.api.sms_rate_limited";
    case APIErrorCode.phoneNumberTaken:
      return "error.api.phone_number_taken";
    case APIErrorCode.emailTaken:
      return "error.api.email_taken";
    case APIErrorCode.agentNotValidated:
      return "error.api.agent_not_validated";
    case APIErrorCode.requestNotFound:
      return "error.api.request_not_found";
    case APIErrorCode.alreadyOfferedByColleague:
      return "error.api.already_offered_by_colleague";
    case APIErrorCode.networkUnavailable:
      return "error.network_unavailable";
    default:
      return "error.unknown_server_error";
  }
}

export function getErrorMessageIdFromUnknwonError(error: any): string {
  if (typeof error === "object") {
    if (error.code === 10000 && error.name === "UnexpectedError") {
      if (error.message === "AGENT_IS_BLOCKED") {
        return "error.api.agent_is_blocked";
      }
    }
  }

  return "error.unknown_server_error";
}

function parseMaybeAPIError(e: any): APIError | undefined {
  try {
    const { code, message, reason, info } = apiErrorSchema.validateSync(e);

    return new APIError(code, message, reason, info);
  } catch (e) {
    if (e instanceof yup.ValidationError) {
      return undefined;
    }

    throw e;
  }
}

const apiErrorSchema: yup.Schema<APIErrorObject> = yup.object({
  code: yup.number().required(),
  message: yup.string().required(),
  reason: yup.string(),
  info: yup.mixed(),
});

interface APIErrorObject {
  code: number;
  message: string;
  reason?: string;
  info?: any;
}

// Error resulted from lambda failure
export class APIError extends Error {
  code: number;
  message: string;
  reason?: string;
  info?: any;

  constructor(
    code: number,
    message: string,
    reason?: string,
    info?: any,
    ...params: any[]
  ) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError);
    }

    this.name = "APIError";
    this.code = code;
    this.message = message;
    this.reason = reason;
    this.info = info;
  }
}
