import React, { PureComponent } from "react";
import { IonAlert } from "@ionic/react";
import { Context as LocaleContext } from "@oursky/react-messageformat";

import { AppConfigApiClient } from "../apiClient";
import { isPlatform } from "../utils/platform";
import { Omit } from "../utils/typeutils";
import {
  ANDROID_NATIVE_MARKET_DEEP_LINK,
  APP_VERSION,
  IOS_NATIVE_MARKET_DEEP_LINK,
} from "../config";

export interface CheckAppVersionContextValue {
  checkAppVersion: () => Promise<boolean>;
}

interface CheckAppVersionContextState {
  showForceUpdateAlert: boolean;
}

export const CheckAppVersionContext = React.createContext<
  CheckAppVersionContextValue
>(null as any);

interface ProviderProps {
  apiClient: AppConfigApiClient;
  children?: React.ReactNode;
}

type ProviderState = CheckAppVersionContextState;

function shouldForceUpdate(current: string, target: string) {
  const currentVersion = current.split(".").map(Number);
  const targetVersion = target.split(".").map(Number);
  for (let i = 0; ; i++) {
    if (i >= targetVersion.length) {
      return false;
    }
    if (i >= currentVersion.length) {
      return true;
    }
    const diff = currentVersion[i] - targetVersion[i];
    if (diff) {
      return diff > 0 ? false : true;
    }
  }
}

export class CheckAppVersionContextProvider extends PureComponent<
  ProviderProps,
  ProviderState
> {
  state: ProviderState = {
    showForceUpdateAlert: false,
  };

  closeForceUpdateAlert = () => {
    this.setState({ showForceUpdateAlert: false });
  };

  openForceUpdateAlert = () => {
    this.setState({ showForceUpdateAlert: true });
  };

  checkAppVersion = async () => {
    const minAppVersion = await this.props.apiClient.fetchMinAppVersion();
    const _shouldForceUpdate = shouldForceUpdate(APP_VERSION, minAppVersion);
    if (_shouldForceUpdate) {
      this.openForceUpdateAlert();
      return true;
    }
    return false;
  };

  handleForceUpdateNavigation() {
    window.open(
      isPlatform("ios")
        ? IOS_NATIVE_MARKET_DEEP_LINK
        : ANDROID_NATIVE_MARKET_DEEP_LINK
    );
    return false;
  }

  render() {
    const { apiClient, children } = this.props;
    const { showForceUpdateAlert } = this.state;
    return (
      <CheckAppVersionContext.Provider
        value={{
          checkAppVersion: this.checkAppVersion.bind(apiClient),
        }}
      >
        <LocaleContext.Consumer>
          {({ renderToString }) => (
            <IonAlert
              backdropDismiss={false}
              isOpen={showForceUpdateAlert}
              onDidDismiss={this.closeForceUpdateAlert}
              message={renderToString(
                isPlatform("ios")
                  ? "app.force_update.alert.message.ios"
                  : "app.force_update.alert.message.android"
              )}
              buttons={[
                {
                  text: renderToString("app.force_update.alert.install"),
                  handler: this.handleForceUpdateNavigation,
                },
              ]}
            />
          )}
        </LocaleContext.Consumer>
        {children}
      </CheckAppVersionContext.Provider>
    );
  }
}

export interface CheckAppVersionContextProps {
  checkAppVersionContext: CheckAppVersionContextValue;
}

export function withCheckAppVersion<P extends CheckAppVersionContextProps>(
  Component: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof CheckAppVersionContextProps>> {
  const Wrapped: React.FC<Omit<P, keyof CheckAppVersionContextProps>> = (
    props: Omit<P, keyof CheckAppVersionContextProps>
  ) => (
    <>
      <CheckAppVersionContext.Consumer>
        {context => (
          <Component {...props as any} checkAppVersionContext={context} />
        )}
      </CheckAppVersionContext.Consumer>
    </>
  );
  return Wrapped;
}
