import { ReferralAgreementStatus } from "../models";

export function getChatroomStatusMessageId(
  isCompleted: boolean,
  referralAgreement: ReferralAgreementStatus | null
): string {
  if (isCompleted) {
    return "chat.completed";
  }
  switch (referralAgreement) {
    case ReferralAgreementStatus.requested:
      return "chat.ready_referral";
    case ReferralAgreementStatus.accepted:
      return "chat.accepted_referral";
    default:
      return "chat.in_progress";
  }
}
