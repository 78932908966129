import React from "react";
import numeral from "numeral";
import { DateTime } from "luxon";
import { FormattedMessage } from "@oursky/react-messageformat";

import styles from "./styles.module.scss";

import {
  ExchangeLoanOffering,
  MortgageOffering,
  PersonalLoanOffering,
  Offering,
  OfferingType,
} from "../../models";
import {
  withTemplate,
  TemplateContextProps,
  TemplateContextValue,
} from "../../context/TemplateContext";
import RichTextView from "../RichTextView";
import { formatToCurrencyAmount } from "../../utils/formatToCurrencyAmount";

export const images = {
  reply: require("../../images/icon-reply-arrow.svg"),
};

export const renderCreatedTime = (createdAt: DateTime) => {
  const dateNow = DateTime.utc();
  const diff = dateNow.diff(createdAt, ["minutes", "hours", "days"]).toObject();

  if (diff.minutes && diff.days === 0 && diff.hours === 0) {
    return (
      <FormattedMessage
        id="offering.offers_and_offer_requests.minutes_ago"
        values={{
          minutes: Math.round(diff.minutes),
          unit: Math.round(diff.minutes),
        }}
      />
    );
  } else if (diff.hours && diff.days === 0) {
    return (
      <FormattedMessage
        id="offering.offers_and_offer_requests.hours_ago"
        values={{ hours: Math.round(diff.hours), unit: Math.round(diff.hours) }}
      />
    );
  } else if (diff.days) {
    return (
      <FormattedMessage
        id="offering.offers_and_offer_requests.days_ago"
        values={{ days: Math.round(diff.days), unit: Math.round(diff.days) }}
      />
    );
  }
  return (
    <FormattedMessage
      id="offering.offers_and_offer_requests.minutes_ago"
      values={{ minutes: 0, unit: 0 }}
    />
  );
};

export const renderMortgageLevel = (mortgageLevel: string) => {
  switch (mortgageLevel) {
    case "1":
      return (
        <FormattedMessage id="offering.offers_and_offer_requests.mortgage_level_one" />
      );
    case "2":
      return (
        <FormattedMessage id="offering.offers_and_offer_requests.mortgage_level_two" />
      );
    case "3":
      return (
        <FormattedMessage id="offering.offers_and_offer_requests.mortgage_level_three" />
      );
    case "4+":
      return (
        <FormattedMessage id="offering.offers_and_offer_requests.mortgage_level_four" />
      );
    default:
      throw new Error(`Unexpected Mortgage level ${mortgageLevel}`);
  }
};

interface PublicOfferListExchangeCardViewProps {
  offering: ExchangeLoanOffering;
  onApply: (offering: Offering) => void;
  templateContext: TemplateContextValue;
}

const PublicOfferListExchangeCardView: React.FC<
  PublicOfferListExchangeCardViewProps
> = props => {
  const { offering, onApply, templateContext } = props;

  const onApplyClick = React.useCallback(() => {
    onApply(offering);
  }, [onApply, offering]);

  return (
    <div className={styles.cardContainer}>
      <div className={styles.containerFlexRow}>
        <div className={styles.type}>
          <FormattedMessage id="offering.latest_offering.exchange" />
        </div>
        <div className={styles.createdTime}>
          {renderCreatedTime(offering.createdAt)}
        </div>
      </div>

      <div className={styles.containerFlexRow}>
        <div className={styles.amountContainer}>
          <FormattedMessage
            id="offering.offers_and_offer_requests.amount"
            values={{ toCurrency: offering.sellingCurrency.isoCode }}
          />
          <div className={styles.figures}>
            {numeral(offering.sellingAmount).format()}
          </div>
        </div>
        <div className={styles.currencyContainer}>
          <FormattedMessage id="offering.offers_and_offer_requests.currency" />
          <div className={styles.figures}>
            {`${offering.buyingCurrency.isoCode} - ${
              offering.sellingCurrency.isoCode
            }`}
          </div>
        </div>
      </div>

      <div className={styles.containerFlexRow}>
        <div className={styles.containerFlexCol}>
          <RichTextView
            className={styles.referenceOnly}
            text={templateContext.getReferenceOnlyMessage(
              templateContext.getLocalizedTemplates(
                offering.agent.company.templates
              ),
              offering
            )}
          />
          <RichTextView
            className={styles.licenseMessage}
            text={templateContext.getLicenseMessage(
              offering,
              templateContext.getLocalizedTemplates(
                offering.agent.company.templates
              )
            )}
          />
        </div>
        <div className={styles.replyButtonContainer}>
          <button className={styles.replyButton} onClick={onApplyClick}>
            <img src={images.reply} alt="reply" className={styles.replyIcon} />
            <div className={styles.reply}>
              <FormattedMessage id="offering.offers_and_offer_requests.reply_btn" />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

interface PublicOfferListMortgageCardViewProps {
  offering: MortgageOffering;
  onApply: (offering: Offering) => void;
  templateContext: TemplateContextValue;
}

const PublicOfferListMortgageCardView: React.FC<
  PublicOfferListMortgageCardViewProps
> = props => {
  const { offering, onApply, templateContext } = props;

  const onApplyClick = React.useCallback(() => {
    onApply(offering);
  }, [onApply, offering]);

  return (
    <div className={styles.cardContainer}>
      <div className={styles.containerFlexRow}>
        <div className={styles.type}>
          <FormattedMessage id="offering.latest_offering.mortgage" />
        </div>
        <div className={styles.createdTime}>
          {renderCreatedTime(offering.createdAt)}
        </div>
      </div>

      <div className={styles.containerFlexRow}>
        <div className={styles.loanAmountContainer}>
          <FormattedMessage id="offering.offers_and_offer_requests.mortgage.loan_amount" />
          <div className={styles.figures}>
            {numeral(offering.loanAmount).format()}
          </div>
        </div>
        <div className={styles.levelContainer}>
          <FormattedMessage id="offering.offers_and_offer_requests.mortgage.level" />
          <div className={styles.figures}>
            {renderMortgageLevel(offering.mortgageLevel)}
          </div>
        </div>
        <div className={styles.interestContainer}>
          <FormattedMessage id="offering.offers_and_offer_requests.mortgage.interest" />
          <div className={styles.figures}>
            <FormattedMessage
              id="offering.offers_and_offer_requests.mortgage.interest_rate_range"
              values={{
                interestRateMin: offering.interestRateMin,
                interestRateMax: offering.interestRateMax,
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.containerFlexRow}>
        <div className={styles.marketValueContainer}>
          <FormattedMessage
            id="offering.offers_and_offer_requests.mortgage.market_value"
            values={{ currency: "HKD" }}
          />
          <div className={styles.figures}>
            {formatToCurrencyAmount(offering.buildingValue, true)}
          </div>
        </div>
        <div className={styles.repaymentPeriodContainer}>
          <FormattedMessage id="offering.offers_and_offer_requests.mortgage.repayment_period" />
          <div className={styles.figures}>
            <FormattedMessage
              id="offering.offers_and_offer_requests.mortgage.repayment_period_value"
              values={{ paymentPeriodMax: offering.paymentPeriodMax }}
            />
          </div>
        </div>
        <div className={styles.emptyFlexColumnContainer} />
      </div>

      <div className={styles.containerFlexRow}>
        <div className={styles.containerFlexCol}>
          <RichTextView
            className={styles.referenceOnly}
            text={templateContext.getReferenceOnlyMessage(
              templateContext.getLocalizedTemplates(
                offering.agent.company.templates
              ),
              offering
            )}
          />
          <RichTextView
            className={styles.licenseMessage}
            text={templateContext.getLicenseMessage(
              offering,
              templateContext.getLocalizedTemplates(
                offering.agent.company.templates
              )
            )}
          />
          <div className={styles.extraInformation}>{offering.addition}</div>
        </div>
        <div className={styles.replyButtonContainer}>
          <button className={styles.replyButton} onClick={onApplyClick}>
            <img src={images.reply} alt="reply" className={styles.replyIcon} />
            <div className={styles.reply}>
              <FormattedMessage
                id={
                  offering.directApplyLink
                    ? "offering.offers_and_offer_requests.direct_apply.reply_btn"
                    : "offering.offers_and_offer_requests.reply_btn"
                }
              />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

interface PublicOfferListLoanCardViewProps {
  offering: PersonalLoanOffering;
  onApply: (offering: Offering) => void;
  templateContext: TemplateContextValue;
}

const PublicOfferListLoanCardView: React.FC<
  PublicOfferListLoanCardViewProps
> = props => {
  const { offering, onApply, templateContext } = props;

  const onApplyClick = React.useCallback(() => {
    onApply(offering);
  }, [onApply, offering]);

  return (
    <div className={styles.cardContainer}>
      <div className={styles.containerFlexRow}>
        <div className={styles.type}>
          <FormattedMessage id="offering.latest_offering.loan" />
        </div>
        <div className={styles.createdTime}>
          {renderCreatedTime(offering.createdAt)}
        </div>
      </div>

      <div className={styles.containerFlexRow}>
        <div className={styles.amountContainer}>
          <FormattedMessage
            id="offering.offers_and_offer_requests.amount"
            values={{ toCurrency: "HKD" }}
          />
          <div className={styles.figures}>
            {`${numeral(offering.amountMin).format()} - ${numeral(
              offering.amountMax
            ).format()}`}
          </div>
        </div>
        <div className={styles.repaymentPeriodContainer}>
          <FormattedMessage id="offering.offers_and_offer_requests.repayment_period" />
          <div className={styles.figures}>
            <FormattedMessage
              id="offering.offers_and_offer_requests.months"
              values={{
                minMonth: numeral(offering.periodMin).format(),
                maxMonth: numeral(offering.periodMax).format(),
                unit: offering.periodMax,
              }}
            />
          </div>
        </div>
        <div className={styles.interestContainer}>
          <FormattedMessage id="offering.offers_and_offer_requests.interest" />
          <div className={styles.figures}>
            {`${offering.interestRateMin}% - ${offering.interestRateMax}%`}
          </div>
        </div>
      </div>

      <div className={styles.containerFlexRow}>
        <div className={styles.containerFlexCol}>
          <RichTextView
            className={styles.referenceOnly}
            text={templateContext.getReferenceOnlyMessage(
              templateContext.getLocalizedTemplates(
                offering.agent.company.templates
              ),
              offering
            )}
          />
          <RichTextView
            className={styles.licenseMessage}
            text={templateContext.getLicenseMessage(
              offering,
              templateContext.getLocalizedTemplates(
                offering.agent.company.templates
              )
            )}
          />
          <div className={styles.extraInformation}>{offering.addition}</div>
        </div>
        <div className={styles.replyButtonContainer}>
          <button className={styles.replyButton} onClick={onApplyClick}>
            <img src={images.reply} alt="reply" className={styles.replyIcon} />
            <div className={styles.reply}>
              <FormattedMessage
                id={
                  offering.directApplyLink
                    ? "offering.offers_and_offer_requests.direct_apply.reply_btn"
                    : "offering.offers_and_offer_requests.reply_btn"
                }
              />
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export interface PublicOfferListCardViewProps extends TemplateContextProps {
  offering: Offering;
  onApply: (offering: Offering) => void;
}

const PublicOfferListCardViewImpl: React.FC<
  PublicOfferListCardViewProps
> = props => {
  const { offering, onApply, templateContext } = props;
  switch (offering.type) {
    case OfferingType.exchangeLoan:
      return (
        <PublicOfferListExchangeCardView
          offering={offering}
          onApply={onApply}
          templateContext={templateContext}
        />
      );
    case OfferingType.mortgage:
      return (
        <PublicOfferListMortgageCardView
          offering={offering}
          onApply={onApply}
          templateContext={templateContext}
        />
      );
    case OfferingType.personalLoan:
      return (
        <PublicOfferListLoanCardView
          offering={offering}
          onApply={onApply}
          templateContext={templateContext}
        />
      );
    default:
      throw new Error(`Unexpected offering type: ${OfferingType}`);
  }
};

export const PublicOfferListCardView = withTemplate(
  PublicOfferListCardViewImpl
);
