function normalize(id: string): string {
  return id
    .toUpperCase()
    .replace(/\s/g, "")
    .replace(/\(([A0-9])\)$/, "$1");
}

function isFormatValid(id: string) {
  return /^[A-Z]{1,2}[0-9]{6}[0-9A]$/.test(id);
}

function getCharacterValue(char: string): number {
  return char === " " ? 36 : parseInt(char, 36);
}

function isChecksumValid(id: string) {
  const hkid = id.length === 8 ? " " + id : id;
  let sum = 0;
  for (let i = 9; i > 0; i--) {
    sum += i * getCharacterValue(hkid[9 - i]);
  }
  return sum % 11 === 0;
}

export function validateHKID(id: string) {
  const normalizedId = normalize(id);
  return isFormatValid(normalizedId) && isChecksumValid(normalizedId);
}
