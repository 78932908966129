import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { withRequest, RequestContextProps } from "../context/RequestContext";
import { withChat, ChatContextProps } from "../context/ChatContext";

import { ChatOfferingListScreenLocationState } from "./ChatOfferingListScreen";
import { ChatView } from "../components/ChatView";
import { InfiniteScrollView } from "../components/InfiniteListView";

import { Request } from "../models";
import { updateRequestListForNewMessage } from "../utils/updateItems";

type Props = RouteComponentProps & ChatContextProps & RequestContextProps;

export class ChatScreen extends React.PureComponent<Props> {
  private infiniteScrollViewRef = React.createRef<
    InfiniteScrollView<Request>
  >();
  private subscribedChatEventListener: { remove: () => void } | null = null;
  private subscribedConversationEventListener: {
    remove: () => void;
  } | null = null;

  componentDidMount() {
    this.subscribedChatEventListener = this.props.chatContext.subscribeEventEmitter(
      e => {
        if (e.type === "create") {
          if (this.infiniteScrollViewRef.current) {
            this.infiniteScrollViewRef.current.updateItems(
              updateRequestListForNewMessage(e)
            );
          }
        }
      }
    );

    this.subscribedConversationEventListener = this.props.chatContext.subscribeConversationEventEmitter(
      e => {
        if (e.type === "delete") {
          this.props.chatContext.updateChatListVersion(() => {});
        }
      }
    );
  }

  componentWillUnmount() {
    if (this.subscribedChatEventListener) {
      this.subscribedChatEventListener.remove();
    }

    if (this.subscribedConversationEventListener) {
      this.subscribedConversationEventListener.remove();
    }
  }

  onClickOfferCount = (requestId: string, requestRefNum: number) => {
    const { history } = this.props;
    const locationState: ChatOfferingListScreenLocationState = {
      requestId: requestId,
      requestRefNum: requestRefNum,
    };
    history.push("chat/offering-list", locationState);
  };

  fetchRequests = (cursor?: string) => {
    return this.props.requestContext.listBorrowerRequests(cursor);
  };

  render() {
    return (
      <ChatView
        listVersion={this.props.chatContext.chatListVersion}
        onClickOfferCount={this.onClickOfferCount}
        fetchRequests={this.fetchRequests}
        infiniteScrollViewRef={this.infiniteScrollViewRef}
      />
    );
  }
}

export default withRouter(withRequest(withChat(ChatScreen)));
