import { IonButton } from "@ionic/react";
import classNames from "classnames";
import React from "react";

import styles from "./styles.module.scss";

export type PrimaryButtonProps = React.ComponentPropsWithRef<typeof IonButton>;

export const PrimaryButton: React.FC<PrimaryButtonProps> = props => {
  const { class: className, ...rest } = props;

  return (
    <IonButton
      mode="ios"
      class={classNames(styles.primaryButton, className)}
      {...rest}
    />
  );
};
