import React from "react";
import { Route } from "react-router";
import { RouteConfig } from "../types/routes";

export function getValidPathPattern(
  routeConfigs: RouteConfig[],
  isPublic?: boolean
) {
  const routes =
    isPublic === undefined
      ? routeConfigs.map(x => x.path)
      : routeConfigs.filter(x => !!x.isPublic === isPublic).map(x => x.path);

  return `/(${routes.join("|")})`;
}

export function renderRoutesFromConfigs(routeConfigs: RouteConfig[]) {
  return routeConfigs.map(route => {
    const paths = route.path.split("/");
    const tab = paths.shift();
    const rest = paths.join("/");
    const path = `/:tab(${tab})` + (rest && `/${rest}`);
    return React.createElement(Route, {
      key: path,
      path: path,
      component: route.component,
      exact: true,
    });
  });
}
