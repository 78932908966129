import React from "react";

import {
  FormattedDropdown,
  FormattedDropdownProps,
  Option as FormattedOption,
} from "../FormattedDropdown";

import { Omit } from "../../utils/typeutils";

export interface YearPeriodDropdownProps
  extends Omit<FormattedDropdownProps, "options"> {
  abbreviated?: boolean;
}

function buildYearPeriodOptions(isAbbreviated: boolean) {
  return [1, 5, 10, 15, 20, 25]
    .map(period => ({
      key: period.toString(),
      value: period.toString(),
      labelId: isAbbreviated
        ? "option.period.unit.years.abbr"
        : "option.period.unit.years",
      labelValues: { years: period },
    }))
    .concat([
      {
        key: "26",
        value: "26",
        labelId: isAbbreviated
          ? "option.period.unit.25_years_plus.abbr"
          : "option.period.unit.25_years_plus",
        labelValues: { years: 26 },
      },
    ]);
}

export const YearPeriodOptions: FormattedOption[] = buildYearPeriodOptions(
  false
);
export const AbbreviatedYearPeriodOptions: FormattedOption[] = buildYearPeriodOptions(
  true
);

export const YearPeriodDropdown: React.FC<YearPeriodDropdownProps> = props => {
  const { abbreviated, ...rest } = props;
  return abbreviated ? (
    <FormattedDropdown
      options={AbbreviatedYearPeriodOptions}
      selectClass="arrowTouchEdge"
      {...rest as any}
    />
  ) : (
    <FormattedDropdown options={YearPeriodOptions} {...rest as any} />
  );
};
