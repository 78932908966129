import { Plugins } from "@capacitor/core";

import { hybridOnly } from "./platform";
const { Keyboard } = Plugins;

export const scrollToElement = (
  element: HTMLElement | null,
  scrollAnchor?: string
) => {
  if (element === null) {
    return;
  }

  hybridOnly(() => {
    Keyboard.hide();
  });

  if (!scrollAnchor) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    return;
  }

  let targetElement = element;
  while (targetElement.parentElement) {
    targetElement = targetElement.parentElement;
    if (
      scrollAnchor &&
      targetElement.getAttribute("data-anchor") === scrollAnchor
    ) {
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      return;
    }
  }
};
