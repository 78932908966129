import React from "react";
import "./styles.css";

export const OurTabs: React.FC = props => {
  return <div className={"ourTabs ion-page"}>{props.children}</div>;
};

interface HideTabBarProps {
  className?: string;
}

export const HideTabBar: React.FC<HideTabBarProps> = props => {
  const className =
    "ion-page hideTabBar" + (props.className && ` ${props.className}`);

  return <div className={className}>{props.children}</div>;
};
