import React from "react";
import { FormattedMessage } from "@oursky/react-messageformat";

import { ReferralAgreementStatus } from "../../models";

import { StatusLightBox } from "./StatusLightBox";

import styles from "./styles.module.scss";

interface Props {
  isCompleted: boolean;
  referralAgreement: ReferralAgreementStatus | null;
}

const StatusRow: React.FC<Props> = props => {
  const { isCompleted, referralAgreement } = props;
  return (
    <div className={styles.statusRow}>
      <div className={styles.statusRowLabel}>
        <FormattedMessage id="chatroom.status_row.label" />
      </div>
      <StatusLightBox
        isCompleted={isCompleted}
        referralAgreement={referralAgreement}
      />
    </div>
  );
};

export default StatusRow;
