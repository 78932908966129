import { DateTime } from "luxon";
import React from "react";

import { OfferingForm, OfferingType } from "../../models";

import ForeignExchangeQuickOfferItem from "./ForeignExchangeQuickOfferItem";
import { MortgageQuickOfferItem } from "./MortgageQuickOfferItem";
import { PersonalLoanQuickOfferItem } from "./PersonalLoanQuickOfferItem";

export interface AgentQuickOfferItemProps {
  offering: OfferingForm;
}

export const AgentQuickOfferItem: React.FC<
  AgentQuickOfferItemProps
> = props => {
  const { offering } = props;
  switch (offering.type) {
    case OfferingType.exchangeLoan:
      return <ForeignExchangeQuickOfferItem offering={offering} />;
    case OfferingType.mortgage:
      return <MortgageQuickOfferItem offering={offering} />;
    case OfferingType.personalLoan:
      return <PersonalLoanQuickOfferItem offering={offering} />;
    default:
      throw new Error(`Unexpected offering: ${offering}`);
  }
};

export function renderExpiredDate(validDays: number) {
  return DateTime.local()
    .plus({ days: validDays })
    .toFormat("dd/LL/yy");
}
