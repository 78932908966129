import React, { PureComponent } from "react";
import { RouteTrigger } from "../RouteTrigger";
import { ValueWatcher } from "../ValueWatcher";

interface Props {
  listVersion: number;
  routePrefix: string;
}

interface State {
  listVersion: number;
  isShowing: boolean;
  shouldShowList: boolean;
}

export class ListRefreshControl extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      listVersion: this.props.listVersion,
      isShowing: true,
      shouldShowList: true,
    };
  }

  onLeaveTimer?: number;
  onReenterTimer?: number;

  onReenter = (previousPathname: string) => {
    const { routePrefix } = this.props;

    this.onReenterTimer = window.setTimeout(
      () => {
        this.onReenterTimer = undefined;
        this.setState({ shouldShowList: true, isShowing: true });
      },
      previousPathname.startsWith(routePrefix) ? 300 : 0
    );
  };

  onLeave = (nextPathname: string) => {
    const { routePrefix } = this.props;
    this.onLeaveTimer = window.setTimeout(
      () => {
        this.onLeaveTimer = undefined;
        this.setState(
          (prevState: State): State => {
            const { listVersion } = this.props;
            return {
              ...prevState,
              isShowing: false,
              shouldShowList: prevState.listVersion === listVersion,
              listVersion,
            };
          }
        );
      },
      nextPathname.startsWith(routePrefix) ? 500 : 0
    );
  };

  onListVersionChanged = (listVersion: number) => {
    this.setState(
      (prevState: State): State => {
        if (prevState.isShowing) {
          return prevState;
        }

        return {
          ...prevState,
          shouldShowList:
            prevState.shouldShowList && prevState.listVersion === listVersion,
          listVersion: listVersion,
        };
      }
    );
  };

  componentWillUnmount() {
    if (this.onReenterTimer) {
      window.clearTimeout(this.onReenterTimer);
    }

    if (this.onLeaveTimer) {
      window.clearTimeout(this.onLeaveTimer);
    }
  }

  render() {
    return (
      <>
        <RouteTrigger onEnter={this.onReenter} onLeave={this.onLeave} />
        <ValueWatcher
          value={this.props.listVersion}
          trigger={this.onListVersionChanged}
        />
        {this.state.shouldShowList && this.props.children}
      </>
    );
  }
}
