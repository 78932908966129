import React from "react";
import { RouteComponentProps } from "react-router";

import { OptionsProps, withOptions } from "../context/OptionsContext";
import { RemoteData, Initial, Loading, Success } from "../utils/remoteData";

import { withNav, OurNavContextProps } from "../navigation/OurNavContext";
import {
  withErrorAlert,
  ErrorAlertContextProps,
} from "../context/ErrorAlertContext";
import { withLoading, LoadingContextProps } from "../context/LoadingContext";
import { UserContextProps, withUser } from "../context/UserContext";

import {
  getBorrowerLoanRecordFormValidators,
  InputFields as BorrowerLoanRecordInputFields,
} from "../components/BorrowerLoanRecordForm";
import {
  getBorrowerBasicInfoFormValidators,
  InputFields as BorrwerBasicInfoInputFields,
} from "../components/BorrowerBasicInfoForm/common";
import {
  getBorrowerWorkInfoFormValidators,
  InputFields as BorrwerWorkInfoInputFields,
} from "../components/BorrowerWorkInfoView/BorrowerWorkInfoForm";
import EditDataProfileView from "../components/EditBorrowerProfileView";

import {
  BorrowerBasicInfo,
  BorrowerPropertyInfo,
  BorrowerWorkInfo,
  BorrowerOtherIncomeInfo,
  Borrower,
  OtherIncome,
  BorrowerLoanInfo,
  ExistingLoan,
} from "../models";
import { Validator, ValidationError, validate } from "../utils/validate";
import { APIErrorCode, getAPIErrorMessageId } from "../error";
import { ensureBorrower } from "../utils/user";
import {
  defaultPropertyInfoValues,
  FormValues as PropertyInfoFormValues,
  validatePropertyInfoForm,
} from "../components/BorrowerPropertyInfoView/PropertyInfoForm";
import {
  defaultOtherIncomeValues,
  validateOtherIncomeForm,
} from "../components/BorrowerOtherIncomeView/OtherIncomeForm";
import {
  defaultLoanInfoFormValues,
  validatLoanInfoForm,
} from "../components/BorrowerLoanRecordForm/LoanInfoForm";

type Props = OptionsProps &
  RouteComponentProps &
  UserContextProps &
  LoadingContextProps &
  ErrorAlertContextProps &
  OurNavContextProps;

export interface EditableBorrowerData {
  basicInfo: BorrowerBasicInfo;
  propertyInfo: BorrowerPropertyInfo;
  workInfo: BorrowerWorkInfo;
  otherIncomeInfo: BorrowerOtherIncomeInfo;
  loanInfo: BorrowerLoanInfo;
}

export type InputFields =
  | BorrwerBasicInfoInputFields
  | BorrwerWorkInfoInputFields
  | BorrowerLoanRecordInputFields;

interface State {
  remoteUpdate: RemoteData<void>;
  borrowerData: EditableBorrowerData;
  error: ValidationError<InputFields>;
  hasIncomeProof: boolean | null;
  numberOfLoan: number | null;
  focusField?: InputFields;
  nextField?: InputFields;
  emailErrorId?: string;
}

class EditDataProfileScreenImpl extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const borrower = ensureBorrower(this.props.userContext.user);
    if (borrower === undefined) {
      throw new Error("Unexpected borrower is undefined");
    }
    const editableBorrowerData = this.getEditableBorrowerData(borrower);
    this.state = {
      remoteUpdate: Initial(),
      borrowerData: editableBorrowerData,
      hasIncomeProof: borrower.incomeProofType !== null,
      numberOfLoan:
        borrower.existingLoans.length > 0
          ? borrower.existingLoans.length
          : null,
      error: {},
    };
  }

  componentDidUpdate(_prevProps: Props, prevState: State) {
    if (
      prevState.borrowerData.workInfo.isHired !==
      this.state.borrowerData.workInfo.isHired
    ) {
      this.onUpdateIsHired(this.state.borrowerData.workInfo.isHired);
    }
    if (
      prevState.borrowerData.propertyInfo.propertyInfos.length !==
      this.state.borrowerData.propertyInfo.propertyInfos.length
    ) {
      if (this.state.borrowerData.propertyInfo.propertyInfos.length === 0) {
        this.onUpdateEditableBorrowerData({
          propertyInfo: {
            propertyInfos: [],
            numberOfProperties: 0,
          },
        });
      }
    }
    if (
      prevState.borrowerData.otherIncomeInfo.otherIncomes.length !==
      this.state.borrowerData.otherIncomeInfo.otherIncomes.length
    ) {
      if (this.state.borrowerData.otherIncomeInfo.otherIncomes.length === 0) {
        this.onUpdateEditableBorrowerData({
          otherIncomeInfo: {
            otherIncomes: [],
            numberOfOtherIncomes: 0,
          },
        });
      }
    }
    if (
      prevState.borrowerData.loanInfo.existingLoans.length !==
      this.state.borrowerData.loanInfo.existingLoans.length
    ) {
      if (this.state.borrowerData.loanInfo.existingLoans.length === 0) {
        this.setState({
          numberOfLoan: 0,
        });
      }
    }
  }

  getEditableBorrowerData(borrower: Borrower): EditableBorrowerData {
    return {
      basicInfo: {
        name: borrower.name === "-" ? null : borrower.name,
        surname: borrower.surname,
        gender: borrower.gender,
        passportNumber: borrower.passportNumber,
        email: borrower.email,
        birthday: borrower.birthday,
        education: borrower.education,
        heardFrom: borrower.heardFrom,
        roomFloor: borrower.roomFloor,
        tower: borrower.tower,
        nameOfBuilding: borrower.nameOfBuilding,
        streetNameAndNumber: borrower.streetNameAndNumber,
        district: borrower.district,
        premiseType: borrower.premiseType,
        propertyStatus: borrower.propertyStatus,
        numOfOwner: borrower.numOfOwner,
        monthlyRent: borrower.monthlyRent,
      },
      propertyInfo: {
        propertyInfos: borrower.propertyInfos,
        numberOfProperties: borrower.numberOfProperties,
      },
      workInfo: {
        isHired: borrower.isHired,
        industry: borrower.industry,
        workingCompanyNames: borrower.workingCompanyNames,
        position: borrower.position,
        employmentType: borrower.employmentType,
        salary: borrower.salary,
        onBoardDate: borrower.onBoardDate,
        incomeProofType: borrower.incomeProofType,
        paymentMethod: borrower.paymentMethod,
      },
      otherIncomeInfo: {
        numberOfOtherIncomes: borrower.numberOfOtherIncomes,
        otherIncomes: borrower.otherIncomes,
      },
      loanInfo: {
        hasMortgage: borrower.hasMortgage,
        existingLoans: borrower.existingLoans,
      },
    };
  }

  onUpdateErrorState = (error: Partial<ValidationError<InputFields>>) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        ...error,
      },
    }));
  };

  onUpdateEditableBorrowerData = (value: Partial<EditableBorrowerData>) => {
    this.setState(prevState => ({
      ...prevState,
      borrowerData: {
        ...prevState.borrowerData,
        ...value,
      },
    }));
    this.onUpdateErrorState(
      Object.keys(value).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );
  };

  onUpdateIsHired = (isHired: boolean | null) => {
    if (!isHired) {
      this.onUpdateEditableBorrowerData({
        workInfo: {
          isHired: isHired,
          industry: null,
          workingCompanyNames: null,
          position: null,
          employmentType: null,
          salary: null,
          onBoardDate: null,
          incomeProofType: null,
          paymentMethod: null,
        },
      });
    }
    this.onUpdateErrorState({
      isHired: undefined,
      industry: undefined,
      workingCompanyNames: undefined,
      position: undefined,
      salary: undefined,
      employmentType: undefined,
      onBoardDate: undefined,
      hasIncomeProof: undefined,
      incomeProofType: undefined,
      paymentMethod: undefined,
    });
  };

  onUpdateHasIncomeProof = (hasIncomeProof: boolean) => {
    this.setState({ hasIncomeProof });
    if (!hasIncomeProof) {
      this.setState(prevState => ({
        ...prevState,
        borrowerData: {
          ...prevState.borrowerData,
          workInfo: {
            ...prevState.borrowerData.workInfo,
            incomeProofType: null,
          },
        },
      }));
    }
  };

  onUpdateHasProperty = (hasProperty: boolean) => {
    if (!hasProperty) {
      this.onUpdateEditableBorrowerData({
        propertyInfo: { propertyInfos: [], numberOfProperties: 0 },
      });
    } else {
      this.onUpdateEditableBorrowerData({
        propertyInfo: {
          propertyInfos: [
            {
              id: null,
              roomFloor: null,
              tower: null,
              nameOfBuilding: null,
              streetNameAndNumber: null,
              district: null,
            },
          ],
          numberOfProperties: 1,
        },
      });
    }
    this.onUpdateErrorState({
      hasProperty: undefined,
      numberOfProperties: undefined,
    });
  };

  onSelectNumberOfProperties = (numberOfProperties: number) => {
    this.setState(prevState => ({
      ...prevState,
      borrowerData: {
        ...prevState.borrowerData,
        propertyInfo: {
          ...prevState.borrowerData.propertyInfo,
          propertyInfos:
            prevState.borrowerData.propertyInfo.propertyInfos.length >=
            numberOfProperties
              ? prevState.borrowerData.propertyInfo.propertyInfos.slice(
                  0,
                  numberOfProperties
                )
              : prevState.borrowerData.propertyInfo.propertyInfos.concat(
                  Array(
                    numberOfProperties -
                      prevState.borrowerData.propertyInfo.propertyInfos.length
                  ).fill(defaultPropertyInfoValues)
                ),
          numberOfProperties: numberOfProperties,
        },
      },
    }));
    this.onUpdateErrorState({ numberOfProperties: undefined });
  };

  onUpdateExistingPropertyInfo = (
    index: number,
    value: Partial<PropertyInfoFormValues>
  ) => {
    this.setState(prevState => ({
      ...prevState,
      borrowerData: {
        ...prevState.borrowerData,
        propertyInfo: {
          ...prevState.borrowerData.propertyInfo,
          propertyInfos: prevState.borrowerData.propertyInfo.propertyInfos.map(
            (v, i) =>
              i === index
                ? {
                    ...v,
                    ...{
                      ...value,
                      district:
                        value.district != null
                          ? value.district.value
                          : v.district,
                    },
                  }
                : v
          ),
        },
      },
    }));
  };

  onAddExistingProperty = () => {
    this.setState(prevState => ({
      ...prevState,
      borrowerData: {
        ...prevState.borrowerData,
        propertyInfo: {
          ...prevState.borrowerData.propertyInfo,
          numberOfProperties:
            prevState.borrowerData.propertyInfo.propertyInfos.length < 20
              ? prevState.borrowerData.propertyInfo.propertyInfos.length + 1
              : prevState.borrowerData.propertyInfo.propertyInfos.length,
          propertyInfos:
            prevState.borrowerData.propertyInfo.propertyInfos.length < 20
              ? prevState.borrowerData.propertyInfo.propertyInfos.concat([
                  defaultPropertyInfoValues,
                ])
              : prevState.borrowerData.propertyInfo.propertyInfos,
        },
      },
    }));
  };

  onRemoveExistingProperty = (index: number) => {
    this.setState(prevState => ({
      ...prevState,
      borrowerData: {
        ...prevState.borrowerData,
        propertyInfo: {
          ...prevState.borrowerData.propertyInfo,
          numberOfProperties:
            prevState.borrowerData.propertyInfo.propertyInfos.length > 0
              ? prevState.borrowerData.propertyInfo.propertyInfos.length - 1
              : null,
          propertyInfos: prevState.borrowerData.propertyInfo.propertyInfos.filter(
            (_, i) => i !== index
          ),
        },
      },
    }));
  };

  onUpdateHasOtherIncome = (hasOtherIncome: boolean) => {
    if (!hasOtherIncome) {
      this.onUpdateEditableBorrowerData({
        otherIncomeInfo: {
          otherIncomes: [],
          numberOfOtherIncomes: 0,
        },
      });
    } else {
      this.onUpdateEditableBorrowerData({
        otherIncomeInfo: {
          otherIncomes: [
            {
              id: null,
              workingCompanyNames: null,
              position: null,
              salary: null,
              onBoardDate: null,
              incomeProofType: null,
              paymentMethod: null,
            },
          ],
          numberOfOtherIncomes: 1,
        },
      });
    }
    this.onUpdateErrorState({
      hasOtherIncome: undefined,
      numberOfOtherIncomes: undefined,
    });
  };

  onSelectNumberOfOtherIncomes = (numberOfOtherIncomes: number) => {
    this.setState(prevState => ({
      ...prevState,
      borrowerData: {
        ...prevState.borrowerData,
        otherIncomeInfo: {
          ...prevState.borrowerData.otherIncomeInfo,
          otherIncomes:
            prevState.borrowerData.otherIncomeInfo.otherIncomes.length >=
            numberOfOtherIncomes
              ? prevState.borrowerData.otherIncomeInfo.otherIncomes.slice(
                  0,
                  numberOfOtherIncomes
                )
              : prevState.borrowerData.otherIncomeInfo.otherIncomes.concat(
                  Array(
                    numberOfOtherIncomes -
                      prevState.borrowerData.otherIncomeInfo.otherIncomes.length
                  ).fill(defaultOtherIncomeValues)
                ),
          numberOfOtherIncomes,
        },
      },
    }));
    this.onUpdateErrorState({ numberOfOtherIncomes: undefined });
  };

  onUpdateExistingOtherIncome = (
    index: number,
    value: Partial<OtherIncome>
  ) => {
    this.setState(prevState => ({
      ...prevState,
      borrowerData: {
        ...prevState.borrowerData,
        otherIncomeInfo: {
          ...prevState.borrowerData.otherIncomeInfo,
          otherIncomes: prevState.borrowerData.otherIncomeInfo.otherIncomes.map(
            (v, i) =>
              i === index
                ? {
                    ...v,
                    ...value,
                  }
                : v
          ),
        },
      },
    }));
    this.onUpdateErrorState(
      Object.keys(value).reduce(
        (acc, cur) => ({ ...acc, [`${cur}${index}`]: undefined }),
        {}
      )
    );
  };

  onAddExistingOtherIncome = () => {
    this.setState(prevState => ({
      ...prevState,
      borrowerData: {
        ...prevState.borrowerData,
        otherIncomeInfo: {
          ...prevState.borrowerData.otherIncomeInfo,
          numberOfOtherIncomes:
            prevState.borrowerData.otherIncomeInfo.otherIncomes.length < 20
              ? prevState.borrowerData.otherIncomeInfo.otherIncomes.length + 1
              : prevState.borrowerData.otherIncomeInfo.otherIncomes.length,
          otherIncomes:
            prevState.borrowerData.otherIncomeInfo.otherIncomes.length < 20
              ? prevState.borrowerData.otherIncomeInfo.otherIncomes.concat([
                  defaultOtherIncomeValues,
                ])
              : prevState.borrowerData.otherIncomeInfo.otherIncomes,
        },
      },
    }));
  };

  onRemoveExistingOtherIncome = (index: number) => {
    this.setState(prevState => ({
      ...prevState,
      borrowerData: {
        ...prevState.borrowerData,
        otherIncomeInfo: {
          ...prevState.borrowerData.otherIncomeInfo,
          numberOfOtherIncomes:
            prevState.borrowerData.otherIncomeInfo.otherIncomes.length > 0
              ? prevState.borrowerData.otherIncomeInfo.otherIncomes.length - 1
              : null,
          otherIncomes: prevState.borrowerData.otherIncomeInfo.otherIncomes.filter(
            (_, i) => i !== index
          ),
        },
      },
    }));
  };

  onSelectMortgageStatus = (hasMortgage: boolean) => {
    if (!hasMortgage) {
      this.onUpdateEditableBorrowerData({
        loanInfo: {
          hasMortgage: false,
          existingLoans: [],
        },
      });
      this.setState({ numberOfLoan: null });
    } else {
      this.onUpdateEditableBorrowerData({
        loanInfo: {
          hasMortgage: true,
          existingLoans: [
            {
              id: null,
              lender: null,
              amount: null,
              tenor: null,
              remainingTenor: null,
              monthlyRepayment: null,
            },
          ],
        },
      });
      this.setState({ numberOfLoan: 1 });
    }
    this.onUpdateErrorState({
      hasMortgage: undefined,
      numberOfLoan: undefined,
    });
  };

  onSelectNumberOfLoan = (numberOfLoan: number) => {
    this.setState(prevState => ({
      ...prevState,
      borrowerData: {
        ...prevState.borrowerData,
        loanInfo: {
          ...prevState.borrowerData.loanInfo,
          existingLoans:
            prevState.borrowerData.loanInfo.existingLoans.length >= numberOfLoan
              ? prevState.borrowerData.loanInfo.existingLoans.slice(
                  0,
                  numberOfLoan
                )
              : prevState.borrowerData.loanInfo.existingLoans.concat(
                  Array(
                    numberOfLoan -
                      prevState.borrowerData.loanInfo.existingLoans.length
                  ).fill(defaultLoanInfoFormValues)
                ),
        },
      },
      numberOfLoan: numberOfLoan,
    }));
    this.onUpdateErrorState({ numberOfLoan: undefined });
  };

  onUpdateExistingLoans = (index: number, value: Partial<ExistingLoan>) => {
    this.setState(prevState => ({
      ...prevState,
      borrowerData: {
        ...prevState.borrowerData,
        loanInfo: {
          ...prevState.borrowerData.loanInfo,
          existingLoans: prevState.borrowerData.loanInfo.existingLoans.map(
            (v, i) => (i === index ? { ...v, ...value } : v)
          ),
        },
      },
    }));
  };

  onAddExistingLoan = () => {
    this.setState(prevState => ({
      ...prevState,
      borrowerData: {
        ...prevState.borrowerData,
        loanInfo: {
          ...prevState.borrowerData.loanInfo,
          existingLoans:
            prevState.borrowerData.loanInfo.existingLoans.length < 20
              ? prevState.borrowerData.loanInfo.existingLoans.concat([
                  defaultLoanInfoFormValues,
                ])
              : prevState.borrowerData.loanInfo.existingLoans,
        },
      },
      numberOfLoan:
        prevState.borrowerData.loanInfo.existingLoans.length < 20
          ? prevState.borrowerData.loanInfo.existingLoans.length + 1
          : prevState.borrowerData.loanInfo.existingLoans.length,
    }));
  };

  onRemoveExistingLoan = (index: number) => {
    this.setState(prevState => ({
      ...prevState,
      borrowerData: {
        ...prevState.borrowerData,
        loanInfo: {
          ...prevState.borrowerData.loanInfo,
          existingLoans: prevState.borrowerData.loanInfo.existingLoans.filter(
            (_, i) => i !== index
          ),
        },
      },
      numberOfLoan:
        prevState.borrowerData.loanInfo.existingLoans.length > 0
          ? prevState.borrowerData.loanInfo.existingLoans.length - 1
          : null,
    }));
  };

  onSubmit = () => {
    this.setState({ focusField: undefined }, async () => {
      const {
        basicInfo,
        propertyInfo,
        workInfo,
        otherIncomeInfo,
        loanInfo,
      } = this.state.borrowerData;

      if (!this.validateBorrowerData()) {
        return;
      }

      this.setState({ remoteUpdate: Loading() });
      this.props.loadingContext.show();
      try {
        await this.props.userContext.updateBorrower({
          ...basicInfo,
          ...propertyInfo,
          ...workInfo,
          ...otherIncomeInfo,
          ...loanInfo,
        });
        this.setState({ remoteUpdate: Success(undefined) });
        this.props.navContext.goBack();
      } catch (error) {
        switch (error.code) {
          case APIErrorCode.emailTaken:
            this.setState({
              emailErrorId: getAPIErrorMessageId(error),
              remoteUpdate: Initial(),
            });
            break;
          default:
            this.props.errorAlertContext.show(error);
            this.setState({ remoteUpdate: Initial() });
        }
      } finally {
        this.props.loadingContext.dismiss();
      }
    });
  };

  validateBorrowerData() {
    const {
      basicInfo,
      propertyInfo,
      workInfo,
      otherIncomeInfo,
      loanInfo,
    } = this.state.borrowerData;

    const validators: Validator<InputFields>[] = [
      ...getBorrowerBasicInfoFormValidators(basicInfo, propertyInfo),
      ...getBorrowerWorkInfoFormValidators(
        workInfo,
        otherIncomeInfo,
        this.state.hasIncomeProof
      ),
      ...getBorrowerLoanRecordFormValidators(loanInfo, this.state.numberOfLoan),
    ];

    const validatorsForEditingProfile = validators.reduce<
      Validator<InputFields>[]
    >((acc, it) => {
      if (it.field === "name" || it.field === "surname") {
        acc.push(it);
      } else if (!it.isExistenceCheck) {
        acc.push(it);
      }
      return acc;
    }, []);

    const { focusField, error, isValid } = validate(
      validatorsForEditingProfile
    );
    const propertyInfoFormValidateResult = validatePropertyInfoForm(
      propertyInfo.propertyInfos
    );
    const otherIncomeFormValidateResult = validateOtherIncomeForm(
      otherIncomeInfo.otherIncomes
    );
    const loanInfoFormValidateResult = validatLoanInfoForm(
      loanInfo.existingLoans
    );
    this.setState({
      error: {
        ...error,
        ...propertyInfoFormValidateResult.error,
        ...otherIncomeFormValidateResult.error,
        ...loanInfoFormValidateResult.error,
      },
      focusField:
        focusField ||
        propertyInfoFormValidateResult.focusField ||
        otherIncomeFormValidateResult.focusField ||
        loanInfoFormValidateResult.focusField,
    });

    return (
      isValid &&
      propertyInfoFormValidateResult.isValid &&
      otherIncomeFormValidateResult.isValid &&
      loanInfoFormValidateResult.isValid
    );
  }

  onUpdateBasicInfoBorrowerData = (value: Partial<BorrowerBasicInfo>) => {
    this.setState(prevState => ({
      ...prevState,
      borrowerData: {
        ...prevState.borrowerData,
        basicInfo: {
          ...prevState.borrowerData.basicInfo,
          ...value,
        },
      },
    }));
    this.onUpdateErrorState(
      Object.keys(value).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );
  };

  onUpdateWorkInfoBorrowerData = (value: Partial<BorrowerWorkInfo>) => {
    this.setState(prevState => ({
      ...prevState,
      borrowerData: {
        ...prevState.borrowerData,
        workInfo: {
          ...prevState.borrowerData.workInfo,
          ...value,
        },
      },
    }));
    this.onUpdateErrorState(
      Object.keys(value).reduce(
        (acc, cur) => ({ ...acc, [cur]: undefined }),
        {}
      )
    );
  };

  render() {
    const {
      remoteUpdate,
      borrowerData,
      error,
      focusField,
      hasIncomeProof,
      numberOfLoan,
    } = this.state;

    return (
      <EditDataProfileView
        remoteUpdate={remoteUpdate}
        borrowerData={borrowerData}
        error={error}
        focusField={focusField}
        onSubmit={this.onSubmit}
        updateBorrowerBasicInfoData={this.onUpdateBasicInfoBorrowerData}
        updateBorrowerWorkInfoData={this.onUpdateWorkInfoBorrowerData}
        onUpdateExistingPropertyInfo={this.onUpdateExistingPropertyInfo}
        onUpdateHasProperty={this.onUpdateHasProperty}
        onSelectNumberOfProperties={this.onSelectNumberOfProperties}
        onRemoveExistingProperty={this.onRemoveExistingProperty}
        onAddExistingProperty={this.onAddExistingProperty}
        hasIncomeProof={hasIncomeProof}
        updateHasIncomeProof={this.onUpdateHasIncomeProof}
        onUpdateExistingOtherIncome={this.onUpdateExistingOtherIncome}
        onUpdateHasOtherIncome={this.onUpdateHasOtherIncome}
        onSelectNumberOfOtherIncomes={this.onSelectNumberOfOtherIncomes}
        onAddExistingOtherIncome={this.onAddExistingOtherIncome}
        onRemoveExistingOtherIncome={this.onRemoveExistingOtherIncome}
        numberOfLoan={numberOfLoan}
        onSelectMortgageStatus={this.onSelectMortgageStatus}
        onSelectNumberOfLoan={this.onSelectNumberOfLoan}
        onUpdateExistingLoans={this.onUpdateExistingLoans}
        onRemoveExistingLoan={this.onRemoveExistingLoan}
        onAddExistingLoan={this.onAddExistingLoan}
      />
    );
  }
}

const EditDataProfileScreen = withLoading(
  withErrorAlert(withOptions(withUser(withNav(EditDataProfileScreenImpl))))
);
export default EditDataProfileScreen;
