import React from "react";

import {
  FormattedDropdown,
  FormattedDropdownProps,
  Option as FormattedOption,
} from "../FormattedDropdown";

import { Omit } from "../../utils/typeutils";

export type RepaymentPeriodDropdownProps = Omit<
  FormattedDropdownProps,
  "options"
>;

export const RepaymentPeriodOptions: FormattedOption[] = Array.from(
  Array(26).keys()
).map(index => ({
  key: (index + 5).toString(),
  value: (index + 5).toString(),
  labelId: "option.period.unit.repayments",
  labelValues: { years: index + 5 },
}));

export const RepaymentPeriodDropdown: React.FC<
  RepaymentPeriodDropdownProps
> = props => {
  return (
    <FormattedDropdown options={RepaymentPeriodOptions} {...props as any} />
  );
};
