import { DateTime } from "luxon";
import * as yup from "yup";

class DateTimeSchemaImpl extends yup.mixed {
  constructor() {
    super();

    this.withMutation(() => {
      this.transform((value, _originalvalue) => {
        if (value instanceof DateTime) {
          return value;
        }

        if (typeof value !== "string") {
          return value;
        }

        return DateTime.fromISO(value);
      });
    });
  }

  _typeCheck(value: any) {
    return value instanceof DateTime && value.isValid;
  }
}

export interface DateTimeSchemaConstructor {
  new (): yup.Schema<DateTime>;
}

// @ts-ignore: yup type definition is wrong on custom schema type
// tslint:disable-next-line variable-name
export const DateTimeSchema: yup.Schema<DateTime> &
  DateTimeSchemaConstructor = DateTimeSchemaImpl;
