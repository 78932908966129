import React, { PureComponent } from "react";
import classNames from "classnames";

import styles from "./styles.module.scss";

import { InfiniteScrollView } from "../InfiniteListView";

import { Page } from "../../utils/pagination";
import { Offering } from "../../models";
import { PublicOfferListCardView } from "../PublicOfferListAndOfferRequestsCardView/PublicOfferListCardView";
import { Mode } from "../../screens/BorrowerOfferingListScreen";

interface Props {
  fetchPersonalLoansPage: (cursor?: string) => Promise<Page<Offering>>;
  fetchMortgagesPage: (cursor?: string) => Promise<Page<Offering>>;
  mode: Mode;
  onApply: (offering: Offering) => void;
  offeringListVersion: number;
}

export class ListView extends PureComponent<Props> {
  render() {
    const {
      fetchPersonalLoansPage,
      fetchMortgagesPage,
      mode,
      offeringListVersion,
    } = this.props;

    const shouldShowPersonalLoanList = mode === Mode.PersonalLoan;
    const shouldShowMortgageList = mode === Mode.Mortgage;
    return (
      <>
        <InfiniteScrollView
          key={`personal_loan-${offeringListVersion}`}
          listClassName={classNames(styles.list, {
            [styles.hide]: !shouldShowPersonalLoanList,
          })}
          emptyMessageId="common.empty_list"
          fetchPage={fetchPersonalLoansPage}
          renderItem={this.renderOfferingItem}
          threshold={400}
          disabled={!shouldShowPersonalLoanList}
        />
        <InfiniteScrollView //TODO
          key={`mortgage-${offeringListVersion}`}
          listClassName={classNames(styles.list, {
            [styles.hide]: !shouldShowMortgageList,
          })}
          emptyMessageId="common.empty_list"
          fetchPage={fetchMortgagesPage}
          renderItem={this.renderOfferingItem}
          threshold={400}
          disabled={!shouldShowMortgageList}
        />
      </>
    );
  }

  renderOfferingItem = (offering: Offering) => {
    const { onApply } = this.props;
    return (
      <PublicOfferListCardView
        key={offering.id}
        offering={offering}
        onApply={onApply}
      />
    );
  };
}
