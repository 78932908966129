import { IonButton } from "@ionic/react";
import classNames from "classnames";
import React from "react";

import styles from "./styles.module.scss";

export type TertiaryButtonProps = React.ComponentPropsWithRef<typeof IonButton>;

export const TertiaryButton: React.FC<TertiaryButtonProps> = props => {
  const { class: className, ...rest } = props;

  return (
    <IonButton
      mode="ios"
      class={classNames(styles.tertiaryButton, className)}
      {...rest}
    />
  );
};
