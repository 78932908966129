import classNames from "classnames";
import React from "react";
import {
  FormattedMessage,
  Context as LocaleContext,
} from "@oursky/react-messageformat";
import YesNoPicker from "../YesNoPicker";
import { DateTime } from "luxon";

import ErrorField from "../ErrorField";
import { ClearableInput } from "../input";
import {
  BorrowerOtherIncomeInfo,
  BorrowerWorkInfo,
  Industry,
  OtherIncome,
  PaymentMethod,
} from "../../models";
import {
  ValidationError,
  Validator,
  filterValidatorByDisplayMap,
} from "../../utils/validate";
import { formatToCurrencyAmount } from "../../utils/formatToCurrencyAmount";
import { validateFormattedAmount } from "../../utils/validation";
import {
  EmploymentTypeDropdown,
  IncomeProofTypeDropdown,
} from "../SpecifyDropdown";
import { IndustryPicker } from "../IndustryPicker";
import { DatePicker } from "../DatePicker";
import { PaymentMethodDropdown } from "../SpecifyDropdown/PaymentMethodDropdown";
import {
  BorrowerOtherIncomeInfoView,
  InputFields as BorrowerOtherIncomeInfoViewInputFields,
} from "../BorrowerOtherIncomeView";

import styles from "./BorrowerWorkInfoForm.module.scss";
import { WorkInfoField } from "../ConditionalDisplayField/ConditionalDisplayField";
import {
  BorrowerListField,
  BorrowerWorkInfoField,
  FieldType,
} from "../../context/CreateRequestContext";

export type InputFields =
  | "isHired"
  | "industry"
  | "workingCompanyNames"
  | "position"
  | "salary"
  | "employmentType"
  | "onBoardDate"
  | "hasIncomeProof"
  | "incomeProofType"
  | "paymentMethod"
  | BorrowerOtherIncomeInfoViewInputFields;

interface Props {
  borrowerWorkInfo: BorrowerWorkInfo;
  updateBorrowerWorkInfo: (value: Partial<BorrowerWorkInfo>) => void;
  hasIncomeProof: boolean | null;
  updateHasIncomeProof: (hasIncomeProof: boolean) => void;

  borrowerOtherIncomeInfo: BorrowerOtherIncomeInfo;
  onUpdateExistingOtherIncome: (
    index: number,
    value: Partial<OtherIncome>
  ) => void;
  onSelectNumberOfOtherIncomes: (numberOfOtherIncomes: number) => void;
  onUpdateHasOtherIncome: (hasOtherIncome: boolean) => void;
  onRemoveExistingOtherIncome?: (index: number) => void;
  onAddExistingOtherIncome?: () => void;

  error: ValidationError<InputFields>;
  focusField?: InputFields;
}

interface State {
  nextField?: InputFields;
}

class BorrowerWorkInfoForm extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      nextField: undefined,
    };
  }

  componentDidUpdate() {
    if (this.state.nextField) {
      this.setState({ nextField: undefined });
    }
  }

  focusOnBoardDateField = () => {
    this.setState({ nextField: "onBoardDate" });
  };

  renderWorkingInfoSection = (
    renderToString: (messageId: string) => string
  ) => {
    const {
      borrowerWorkInfo,
      hasIncomeProof,
      borrowerOtherIncomeInfo,
      onSelectNumberOfOtherIncomes,
      onUpdateHasOtherIncome,
      onUpdateExistingOtherIncome,
      onRemoveExistingOtherIncome,
      onAddExistingOtherIncome,
      focusField,
      error,
    } = this.props;
    const {
      isHired,
      industry,
      workingCompanyNames,
      position,
      salary,
      employmentType,
      onBoardDate,
      incomeProofType,
      paymentMethod,
    } = borrowerWorkInfo;
    const workingInfoDisabled = isHired !== true;

    return (
      <>
        <WorkInfoField field="isHired">
          <div className={styles.yesNoFieldContainer}>
            <YesNoPicker
              labelId="borrower_work_info.isHired"
              onClickOption={this.handleIsHiredChanged}
              value={isHired === null ? undefined : isHired}
              shouldFocus={focusField === "isHired"}
            />
            <ErrorField
              isShown={error["isHired"] !== undefined}
              hiddenType="gone"
              messageId={error["isHired"]}
            />
          </div>
        </WorkInfoField>

        <WorkInfoField field="industry">
          <div className={styles.fieldContainer} data-anchor="industry">
            <div
              className={classNames(styles.fieldTitle, {
                [styles.disabled]: workingInfoDisabled,
              })}
            >
              <FormattedMessage id="borrower_work_info.industry" />
            </div>
            <IndustryPicker
              disabled={workingInfoDisabled}
              containerClass={styles.dropdownContainer}
              isError={error["industry"] !== undefined}
              value={industry}
              shouldFocus={focusField === "industry"}
              shouldScrollTo={focusField === "industry"}
              scrollAnchor="industry"
              onChange={this.handleIndustrySelected}
            />
            <ErrorField
              isShown={error["industry"] !== undefined}
              hiddenType="gone"
              messageId={error["industry"]}
            />
          </div>
        </WorkInfoField>

        <WorkInfoField field="workingCompanyNames">
          <div
            className={styles.fieldContainer}
            data-anchor="workingCompanyNames"
          >
            <div
              className={classNames(styles.fieldTitle, {
                [styles.disabled]: workingInfoDisabled,
              })}
            >
              <FormattedMessage id="borrower_work_info.workingCompanyNames" />
            </div>
            <ClearableInput
              disabled={workingInfoDisabled}
              isError={error["workingCompanyNames"] !== undefined}
              placeholder={renderToString("borrower_work_info.pleaseFillIn")}
              value={workingCompanyNames || ""}
              onChange={this.handleWorkingCompanyNamesChanged}
              shouldFocus={focusField === "workingCompanyNames"}
              shouldScrollTo={focusField === "workingCompanyNames"}
              scrollAnchor="workingCompanyNames"
              maxLength={200}
            />
            <ErrorField
              isShown={error["workingCompanyNames"] !== undefined}
              hiddenType="gone"
              messageId={error["workingCompanyNames"]}
            />
          </div>
        </WorkInfoField>

        <WorkInfoField field="position">
          <div className={styles.fieldContainer} data-anchor="position">
            <div
              className={classNames(styles.fieldTitle, {
                [styles.disabled]: workingInfoDisabled,
              })}
            >
              <FormattedMessage id="borrower_work_info.position" />
            </div>
            <ClearableInput
              disabled={workingInfoDisabled}
              isError={error["position"] !== undefined}
              placeholder={renderToString("borrower_work_info.pleaseFillIn")}
              value={position || ""}
              onChange={this.handlePositionChanged}
              shouldFocus={focusField === "position"}
              shouldScrollTo={focusField === "position"}
              scrollAnchor="position"
              maxLength={200}
            />
            <ErrorField
              isShown={error["position"] !== undefined}
              hiddenType="gone"
              messageId={error["position"]}
            />
          </div>
        </WorkInfoField>

        <WorkInfoField field="salary">
          <div className={styles.fieldContainer} data-anchor="salary">
            <div
              className={classNames(styles.fieldTitle, {
                [styles.disabled]: workingInfoDisabled,
              })}
            >
              <FormattedMessage id="borrower_work_info.salary" />
            </div>
            <ClearableInput
              type="tel"
              disabled={workingInfoDisabled}
              isError={error["salary"] !== undefined}
              placeholder={renderToString("borrower_work_info.pleaseFillIn")}
              value={(salary && formatToCurrencyAmount(salary)) || ""}
              onChange={this.handleSalaryChanged}
              maxLength={13}
              shouldFocus={focusField === "salary"}
              shouldScrollTo={focusField === "salary"}
              scrollAnchor="salary"
            />
            <ErrorField
              isShown={error["salary"] !== undefined}
              hiddenType="gone"
              messageId={error["salary"]}
            />
          </div>
        </WorkInfoField>

        <WorkInfoField field="employmentType">
          <div
            className={classNames(
              styles.fieldContainer,
              styles.borderBottomContainer
            )}
            data-anchor="employmentType"
          >
            <div
              className={classNames(styles.fieldTitle, {
                [styles.disabled]: workingInfoDisabled,
              })}
            >
              <FormattedMessage id="borrower_work_info.employmentType" />
            </div>
            <EmploymentTypeDropdown
              disabled={workingInfoDisabled}
              value={employmentType || ""}
              containerClass={styles.dropdownContainer}
              selectClass={styles.dropdownSelect}
              errorClass={styles.dropdownError}
              emptyClass={styles.dropdownEmpty}
              onValueChange={this.handleEmploymentTypeSelected}
              placeholderId="borrower_work_info.pleaseFillIn"
              isError={error["employmentType"] !== undefined}
              shouldFocus={focusField === "employmentType"}
              shouldScrollTo={focusField === "employmentType"}
              scrollAnchor="employmentType"
            />
            <ErrorField
              isShown={error["employmentType"] !== undefined}
              hiddenType="gone"
              messageId={error["employmentType"]}
            />
          </div>
        </WorkInfoField>

        <WorkInfoField field="onBoardDate">
          <div className={styles.fieldContainer} data-anchor="onBoardDate">
            <div
              className={classNames(styles.fieldTitle, {
                [styles.disabled]: workingInfoDisabled,
              })}
            >
              <FormattedMessage id="borrower_work_info.onBoardDate" />
            </div>
            <DatePicker
              disabled={workingInfoDisabled}
              inputClass={classNames(styles.input, {
                [styles.errored]: !!error["onBoardDate"],
                [styles.disabled]: workingInfoDisabled,
              })}
              placeholderId={"borrower_work_info.pleaseFillIn"}
              value={
                onBoardDate !== null &&
                DateTime.fromFormat(onBoardDate, "yyyy-MM-dd").isValid
                  ? DateTime.fromFormat(onBoardDate, "yyyy-MM-dd")
                  : undefined
              }
              onChange={this.handleOnBoardDateChanged}
              shouldFocus={this.state.nextField === "onBoardDate"}
              shouldScrollTo={focusField === "onBoardDate"}
              scrollAnchor={"onBoardDate"}
            />
            <ErrorField
              isShown={error["onBoardDate"] !== undefined}
              hiddenType="gone"
              messageId={error["onBoardDate"]}
            />
          </div>
        </WorkInfoField>

        <WorkInfoField field="paymentMethod">
          <div className={styles.fieldContainer} data-anchor="paymentMethod">
            <div
              className={classNames(styles.fieldTitle, {
                [styles.disabled]: workingInfoDisabled,
              })}
            >
              <FormattedMessage id="borrower_work_info.paymentMethod" />
            </div>
            <PaymentMethodDropdown
              containerClass={styles.dropdownContainer}
              selectClass={styles.dropdownSelect}
              errorClass={styles.dropdownError}
              emptyClass={styles.dropdownEmpty}
              disabled={workingInfoDisabled}
              isError={error["paymentMethod"] !== undefined}
              value={paymentMethod || ""}
              onValueChange={this.handlePaymentMethodSelected}
              shouldFocus={focusField === "paymentMethod"}
              shouldScrollTo={focusField === "paymentMethod"}
              scrollAnchor="paymentMethod"
              placeholderId={"paymentMethod.placeholder"}
            />
            <ErrorField
              isShown={error["paymentMethod"] !== undefined}
              hiddenType="gone"
              messageId={error["paymentMethod"]}
            />
          </div>
        </WorkInfoField>

        <WorkInfoField field="incomeProofType">
          <div className={styles.yesNoFieldContainer}>
            <YesNoPicker
              labelId="borrower_work_info.hasIncomeProof"
              onClickOption={this.handleHasIncomeProofChanged}
              value={hasIncomeProof == null ? undefined : hasIncomeProof}
              shouldFocus={focusField === "hasIncomeProof"}
              disabled={workingInfoDisabled}
            />
            <ErrorField
              isShown={error["hasIncomeProof"] !== undefined}
              hiddenType="gone"
              messageId={error["hasIncomeProof"]}
            />
          </div>
        </WorkInfoField>

        <WorkInfoField field="incomeProofType">
          <div className={styles.fieldContainer} data-anchor="incomeProofType">
            <div
              className={classNames(styles.fieldTitle, {
                [styles.disabled]: hasIncomeProof !== true,
              })}
            >
              <FormattedMessage id="borrower_work_info.incomeProofType" />
            </div>
            <IncomeProofTypeDropdown
              containerClass={styles.dropdownContainer}
              selectClass={styles.dropdownSelect}
              errorClass={styles.dropdownError}
              emptyClass={styles.dropdownEmpty}
              disabled={hasIncomeProof !== true}
              isError={error["incomeProofType"] !== undefined}
              value={incomeProofType || ""}
              onValueChange={this.handleIncomeProofTypeSelected}
              shouldFocus={focusField === "incomeProofType"}
              shouldScrollTo={focusField === "incomeProofType"}
              scrollAnchor="incomeProofType"
              placeholderId="option.incomeProofType.placeholder"
            />
            <ErrorField
              isShown={error["incomeProofType"] !== undefined}
              hiddenType="gone"
              messageId={error["incomeProofType"]}
            />
          </div>
        </WorkInfoField>

        <WorkInfoField field="numberOfOtherIncomes">
          <BorrowerOtherIncomeInfoView
            workingInfoDisabled={workingInfoDisabled}
            borrowerOtherIncomeInfo={borrowerOtherIncomeInfo}
            selectNumberOfOtherIncomes={onSelectNumberOfOtherIncomes}
            updateHasOtherIncome={onUpdateHasOtherIncome}
            updateExistingOtherIncome={onUpdateExistingOtherIncome}
            addExistingOtherIncome={onAddExistingOtherIncome}
            removeExistingOtherIncome={onRemoveExistingOtherIncome}
            error={error}
            focusField={focusField as BorrowerOtherIncomeInfoViewInputFields}
          />
        </WorkInfoField>
      </>
    );
  };

  handleIsHiredChanged = (isHired: boolean) => {
    this.props.updateBorrowerWorkInfo({
      isHired,
    });
  };

  handleIndustrySelected = (industry: Industry) => {
    this.props.updateBorrowerWorkInfo({
      industry,
    });
  };

  handleWorkingCompanyNamesChanged = (workingCompanyNames: string) => {
    this.props.updateBorrowerWorkInfo({
      workingCompanyNames,
    });
  };

  handlePositionChanged = (position: string) => {
    this.props.updateBorrowerWorkInfo({
      position,
    });
  };

  handleSalaryChanged = (salary: string) => {
    const formattedSalary = formatToCurrencyAmount(salary || "");

    if (formattedSalary !== null && validateFormattedAmount(formattedSalary)) {
      this.props.updateBorrowerWorkInfo({
        salary: formattedSalary || null,
      });
    }
  };

  handleEmploymentTypeSelected = (employmentType: string | null) => {
    this.props.updateBorrowerWorkInfo({
      employmentType,
    });
    this.focusOnBoardDateField();
  };

  handleOnBoardDateChanged = (dateTime?: DateTime) => {
    this.props.updateBorrowerWorkInfo({
      onBoardDate: dateTime ? dateTime.toFormat("yyyy-MM-dd") : null,
    });
  };

  handleHasIncomeProofChanged = (hasIncomeProof: boolean) => {
    this.props.updateHasIncomeProof(hasIncomeProof);
  };

  handleIncomeProofTypeSelected = (incomeProofType: string | null) => {
    this.props.updateBorrowerWorkInfo({
      incomeProofType,
    });
  };

  handlePaymentMethodSelected = (paymentMethod: string) => {
    this.props.updateBorrowerWorkInfo({
      paymentMethod: paymentMethod as PaymentMethod,
    });
  };

  render() {
    return (
      <div className={styles.container}>
        <LocaleContext.Consumer>
          {({ renderToString }) =>
            this.renderWorkingInfoSection(renderToString)
          }
        </LocaleContext.Consumer>
      </div>
    );
  }
}

type ValidationFields = Exclude<BorrowerWorkInfoField, BorrowerListField>;
export function getBorrowerWorkInfoFormValidators(
  borrowerWorkInfo: BorrowerWorkInfo,
  borrowerOtherIncomeInfo: BorrowerOtherIncomeInfo,
  hasIncomeProof: boolean | null,
  formDisplayMap?: { [key in ValidationFields]?: FieldType }
): Validator<InputFields>[] {
  const {
    isHired,
    industry,
    position,
    salary,
    employmentType,
    onBoardDate,
    incomeProofType,
    paymentMethod,
  } = borrowerWorkInfo;
  const { numberOfOtherIncomes } = borrowerOtherIncomeInfo;
  const validators: Validator<InputFields>[] = [
    {
      field: "isHired",
      invalidCondition: isHired === null,
      errorMessageId: "borrower_work_info.isHired.error.required",
      isExistenceCheck: true,
    },
    {
      field: "industry",
      invalidCondition: isHired && industry === null,
      errorMessageId: "borrower_work_info.industry.error.required",
      isExistenceCheck: true,
    },
    {
      field: "position",
      invalidCondition:
        isHired && (position === null || position.trim().length === 0),
      errorMessageId: "borrower_work_info.position.error.required",
      isExistenceCheck: true,
    },
    {
      field: "salary",
      invalidCondition: isHired && salary === null,
      errorMessageId: "borrower_work_info.salary.error.required",
      isExistenceCheck: true,
    },
    {
      field: "salary",
      invalidCondition: isHired && salary !== null && parseInt(salary, 10) <= 0,
      errorMessageId: "borrower_work_info.salary.error.negative_or_zero",
    },
    {
      field: "onBoardDate",
      invalidCondition: isHired && onBoardDate === null,
      errorMessageId: "borrower_work_info.onBoardDate.error.required",
      isExistenceCheck: true,
    },
    {
      field: "onBoardDate",
      invalidCondition:
        isHired &&
        onBoardDate !== null &&
        !DateTime.fromFormat(onBoardDate, "yyyy-MM-dd").isValid,
      errorMessageId: "borrower_work_info.onBoardDate.error.incorrect",
    },
    {
      field: "employmentType",
      invalidCondition: isHired && employmentType === null,
      errorMessageId: "borrower_work_info.employmentType.error.required",
      isExistenceCheck: true,
    },
    {
      field: "paymentMethod",
      invalidCondition: isHired && paymentMethod === null,
      errorMessageId: "borrower_work_info.paymentMethod.error.required",
      isExistenceCheck: true,
    },
    {
      field: "hasIncomeProof",
      invalidCondition: isHired && hasIncomeProof === null,
      errorMessageId: "borrower_work_info.hasIncomeProof.error.required",
      isExistenceCheck: true,
      shouldDoExistenceOnOptionalField: true,
    },
    {
      field: "incomeProofType",
      invalidCondition: isHired && hasIncomeProof && incomeProofType === null,
      errorMessageId: "borrower_work_info.incomeProofType.error.required",
      isExistenceCheck: true,
      shouldDoExistenceOnOptionalField: true,
    },
    {
      field: "hasOtherIncome",
      invalidCondition: isHired && numberOfOtherIncomes === null,
      errorMessageId: "borrower_work_info.hasOtherIncome.error.required",
      isExistenceCheck: true,
    },
  ];

  return filterValidatorByDisplayMap<InputFields, ValidationFields>(
    validators,
    formDisplayMap,
    {
      hasIncomeProof: "incomeProofType",
      hasOtherIncome: "numberOfOtherIncomes",
    }
  );
}

export default BorrowerWorkInfoForm;
