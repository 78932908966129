import React from "react";
import { SwiperOptions } from "swiper";
import {
  FormattedMessage,
  Context as LocaleContext,
  Values,
} from "@oursky/react-messageformat";
import { MPContent } from "../MPContent";
import { IonLabel, IonTabButton, IonSlides, IonSlide } from "@ionic/react";
import { AgentHeader } from "../AgentHeader";
import { RouteComponentProps, withRouter } from "react-router";
import AgentCalculatorScreen from "../../screens/AgentCalculatorScreen";
import AgentQuickOfferScreen from "../../screens/AgentQuickOfferScreen";

import styles from "./styles.module.scss";

class AgentManageViewImpl extends React.PureComponent<RouteComponentProps> {
  slideOptsLocale = (
    renderToString: (id: string, values?: Values | undefined) => string
  ): SwiperOptions => {
    const slideOpts = {
      mode: "ios",
      speed: 400,
      zoom: false,

      pagination: {
        el: `.${styles.swiper_pagination}`,
        bulletClass: `${styles.swiper_pagination_bullet}`,
        bulletActiveClass: `${styles.swiper_pagination_bullet_active}`,
        clickable: true,
        renderBullet: function(index: number, className: string) {
          if (index === 0) {
            return `<span class=${className}> ${renderToString(
              "agent.manage.tab.provide_quote"
            )} </span>`;
          } else if (index === 1) {
            return `<span class=${className}> ${renderToString(
              "agent.manage.tab.commission_calculator"
            )} </span>`;
          }
          return "";
        },
      },
    };
    return slideOpts;
  };

  renderTabButton(tabName: string, labelId: string) {
    const pathName = this.props.location.pathname;
    const tabPath = `${tabName}`;

    return (
      <IonTabButton
        tab={tabName}
        class={pathName.startsWith(tabPath) ? styles.active : styles.inactive}
        href={tabName}
      >
        <IonLabel>
          <FormattedMessage id={labelId} />
        </IonLabel>
      </IonTabButton>
    );
  }
  render() {
    return (
      <LocaleContext.Consumer>
        {({ renderToString }) => (
          <>
            <AgentHeader>
              <div className={styles.swiper_pagination} />
            </AgentHeader>
            <MPContent forceOverscroll={false} overflowScrolling="auto">
              <div>
                <IonSlides
                  pager={false}
                  scrollbar={false}
                  options={this.slideOptsLocale(renderToString)}
                  class={styles.swiper_slides}
                >
                  <IonSlide class={styles.swiper_slide}>
                    <AgentQuickOfferScreen />
                  </IonSlide>
                  <IonSlide class={styles.swiper_slide}>
                    <AgentCalculatorScreen />
                  </IonSlide>
                </IonSlides>
              </div>
            </MPContent>
          </>
        )}
      </LocaleContext.Consumer>
    );
  }
}

const AgentManageView = withRouter(AgentManageViewImpl);
export default AgentManageView;
