import React, { PureComponent } from "react";
import { CalculatorType } from "../../types/calculator";
import styles from "./styles.module.scss";

import { FormattedMessage } from "@oursky/react-messageformat";
import { handleNumberKeyDown } from "../../utils/keyboard";

interface Props {
  value: string;
  isLockedCalculator: boolean;
  calculatorType: CalculatorType;
  onValueChanged: (e: React.ChangeEvent<HTMLInputElement>) => void;
  lockClicked: () => void;
}

export default class CalculatorItem extends PureComponent<Props> {
  private renderInput() {
    const {
      value,
      onValueChanged,
      calculatorType,
      isLockedCalculator,
    } = this.props;
    const displayNA = isLockedCalculator && value === "";
    switch (calculatorType) {
      case CalculatorType.Loan:
        return (
          <>
            {displayNA ? <span className={styles.NAPrefix}>N/A</span> : null}
            <input
              className={styles.calculatorInput}
              onChange={onValueChanged}
              type="tel"
              value={value}
              disabled={isLockedCalculator}
            />
          </>
        );
      case CalculatorType.InterestRate:
        return (
          <>
            {displayNA ? <span className={styles.NAPrefix}>N/A</span> : null}
            <input
              className={styles.calculatorInputUnit}
              onKeyDown={handleNumberKeyDown}
              onChange={onValueChanged}
              type="number"
              value={value}
              disabled={isLockedCalculator}
            />
            <div className={styles.calculatorInputLabel}>%</div>
          </>
        );
      case CalculatorType.Period:
        return (
          <>
            {displayNA ? <span className={styles.NAPrefix}>N/A</span> : null}
            <input
              className={styles.calculatorInputUnit}
              onChange={onValueChanged}
              type="tel"
              value={value}
              disabled={isLockedCalculator}
            />
            <div className={styles.calculatorInputLabel}>
              <FormattedMessage id="calculator.period.month" />
            </div>
          </>
        );
      case CalculatorType.Repayment:
        return (
          <>
            {displayNA ? <span className={styles.NAPrefix}>N/A</span> : null}
            <input
              className={styles.calculatorInputUnit}
              onChange={onValueChanged}
              type="tel"
              value={value}
              disabled={isLockedCalculator}
            />
            <div className={styles.calculatorInputLabel}>
              <FormattedMessage id="calculator.repayment.month" />
            </div>
          </>
        );
      default:
        return <></>;
    }
  }

  render() {
    const { isLockedCalculator, calculatorType, lockClicked } = this.props;
    return (
      <div className={styles.calculatorContent}>
        <div className={styles.calculatorLabel}>
          <FormattedMessage id={`calculator.${calculatorType}`} />
        </div>
        <div>
          <button
            className={styles.calculatorIconLockItem}
            onClick={lockClicked}
          >
            <span
              className={isLockedCalculator ? styles.fixed : styles.notFix}
            />
          </button>
          <div className={styles.inputField}>{this.renderInput()}</div>
        </div>
      </div>
    );
  }
}
