import React from "react";
import { IonHeader, IonToolbar } from "@ionic/react";
import {
  FormattedMessage,
  Context as LocaleContext,
  Values,
} from "@oursky/react-messageformat";
import { DateTime } from "luxon";

import {
  ChatWithConversation,
  EmploymentType,
  Industry,
  PremiseType,
  IncomeProofType,
  BorrowerData,
  Education,
  Gender,
  PropertyStatus,
  PaymentMethod,
} from "../../models";

import { Locale } from "../../types/misc";
import { HideTabBar } from "../../navigation/OurTabs";
import { LocaleContextProps, withLocale } from "../../context/LocaleContext";
import { MPContent } from "../MPContent";
import { BackButton } from "../BackButton";

import styles from "./styles.module.scss";

type Props = {
  chat: ChatWithConversation;
  borrower?: BorrowerData;
} & LocaleContextProps;

interface ListItem {
  titleId: string;
  value: string;
}

class BorrowerPersonalInfoViewImpl extends React.PureComponent<Props> {
  private notAvailable = "-";

  private renderSalary(salaryInString: string) {
    // Thousand separator in salary string value
    const salary = parseInt(salaryInString.replace(/,/g, ""), 10);
    if (isNaN(salary)) {
      return this.notAvailable;
    }

    const prefix = "HKD ";
    return `${prefix} ${new Intl.NumberFormat().format(salary)}`;
  }

  private renderBirthday(birthday: DateTime) {
    return birthday.toFormat("yyyy-LL-dd");
  }

  private renderMoney(amount: number) {
    const prefix = "HKD ";
    return `${prefix} ${new Intl.NumberFormat().format(amount)}`;
  }

  private renderBoolean(
    value: boolean,
    renderToString: (id: string, values?: Values | undefined) => string
  ) {
    return value ? renderToString("common.yes") : renderToString("common.no");
  }

  private renderTypeOfPremise(
    premiseType: PremiseType | null,
    renderToString: (id: string, values?: Values | undefined) => string
  ) {
    switch (premiseType) {
      case PremiseType.privateEstate:
        return renderToString(
          "borrower_personal_info.type_of_premise.privateEstate"
        );
      case PremiseType.publicHousing:
        return renderToString(
          "borrower_personal_info.type_of_premise.publicHousing"
        );
      case PremiseType.homeOwnershipSchemeCourts:
        return renderToString(
          "borrower_personal_info.type_of_premise.homeOwnershipSchemeCourts"
        );
      case PremiseType.villageHouse:
        return renderToString(
          "borrower_personal_info.type_of_premise.villageHouse"
        );
      case PremiseType.ntSmallHouse:
        return renderToString(
          "borrower_personal_info.type_of_premise.ntSmallHouse"
        );
      default:
        return this.notAvailable;
    }
  }

  private renderEmploymentType(
    employmentType: string | null,
    renderToString: (id: string, values?: Values | undefined) => string
  ) {
    if (employmentType === EmploymentType.fullTime) {
      return renderToString("borrower_personal_info.employment_type.fullTime");
    }
    if (employmentType === EmploymentType.partTime) {
      return renderToString("borrower_personal_info.employment_type.partTime");
    }
    return this.notAvailable;
  }

  private renderIncomeProofType(
    incomeProofType: string,
    renderToString: (id: string, values?: Values | undefined) => string
  ) {
    if (incomeProofType === IncomeProofType.mpf) {
      return renderToString("borrower_personal_info.income_proof_type.mpf");
    }
    if (incomeProofType === IncomeProofType.bankStatement) {
      return renderToString(
        "borrower_personal_info.income_proof_type.bankStatement"
      );
    }
    if (incomeProofType === IncomeProofType.letter) {
      return renderToString("borrower_personal_info.income_proof_type.letter");
    }
    return this.notAvailable;
  }

  private renderIndustry(industry: Industry) {
    const { locale } = this.props.localeContext;
    if (locale === Locale.en) {
      return industry.nameEn;
    }
    if (locale === Locale.zhHans) {
      return industry.nameZhHans;
    }
    if (locale === Locale.zhHant) {
      return industry.nameZhHant;
    }
    return this.notAvailable;
  }

  private renderDistrict(
    district: string,
    renderToString: (id: string, values?: Values | undefined) => string
  ) {
    const [firstToken, ...restTokens] = district.split("_");
    const capFirstRestTokens = restTokens.map(t => {
      const firstChar = t.slice(0, 1);
      return `${firstChar.toUpperCase()}${t.slice(1)}`;
    });
    const id = [firstToken, ...capFirstRestTokens].join("");
    return renderToString(`option.district.${id}`);
  }

  private renderGender(
    gender: Gender | null,
    renderToString: (id: string, values?: Values | undefined) => string
  ) {
    switch (gender) {
      case Gender.male:
        return renderToString("gender.male");
      case Gender.female:
        return renderToString("gender.female");
      case Gender.other:
        return renderToString("gender.other");
      case Gender.preferNotToSay:
        return renderToString("gender.prefer_not_to_say");
      default:
        return this.notAvailable;
    }
  }

  private renderEducation(
    education: Education | null,
    renderToString: (id: string, values?: Values | undefined) => string
  ) {
    switch (education) {
      case Education.diploma:
        return renderToString("education.diploma");
      case Education.higherDiploma:
        return renderToString("education.higher_diploma");
      case Education.bachelor:
        return renderToString("education.bachelor");
      case Education.postGraduate:
        return renderToString("education.post_graduate");
      default:
        return this.notAvailable;
    }
  }

  private renderPropertyStatus(
    propertyStatus: PropertyStatus | null,
    renderToString: (id: string, values?: Values | undefined) => string
  ) {
    switch (propertyStatus) {
      case PropertyStatus.familyOwned:
        return renderToString("propertyStatus.family_owned");
      case PropertyStatus.whollyOwned:
        return renderToString("propertyStatus.wholly_owned");
      case PropertyStatus.jointlyOwned:
        return renderToString("propertyStatus.jointly_owned");
      case PropertyStatus.leased:
        return renderToString("propertyStatus.leased");
      case PropertyStatus.ownedByTheCompany:
        return renderToString("propertyStatus.owned_by_the_company");
      default:
        return this.notAvailable;
    }
  }

  private renderPaymentMethod(
    paymentMethod: PaymentMethod | null,
    renderToString: (id: string, values?: Values | undefined) => string
  ) {
    switch (paymentMethod) {
      case PaymentMethod.automaticTransfer:
        return renderToString("paymentMethod.automaticTransfer");
      case PaymentMethod.cash:
        return renderToString("paymentMethod.cash");
      case PaymentMethod.cheque:
        return renderToString("paymentMethod.cheque");
      default:
        return this.notAvailable;
    }
  }

  renderBasicInfoSection(
    borrower: BorrowerData,
    renderToString: (id: string, values?: Values | undefined) => string
  ) {
    const listItems: ListItem[] = [
      {
        titleId: "borrower_personal_info.name",
        value: borrower.name || this.notAvailable,
      },
      {
        titleId: "borrower_personal_info.surname",
        value: borrower.surname || this.notAvailable,
      },
      {
        titleId: "borrower_personal_info.gender",
        value: this.renderGender(borrower.gender, renderToString),
      },
      {
        titleId: "borrower_personal_info.passportNumber",
        value: borrower.passportNumber || this.notAvailable,
      },
      {
        titleId: "borrower_personal_info.email",
        value: borrower.email || this.notAvailable,
      },
      {
        titleId: "borrower_personal_info.birthday",
        value:
          borrower.birthday !== null
            ? this.renderBirthday(borrower.birthday)
            : this.notAvailable,
      },
      {
        titleId: "borrower_personal_info.education",
        value: this.renderEducation(borrower.education, renderToString),
      },
      {
        titleId: "borrower_personal_info.roomFloor",
        value: borrower.roomFloor || this.notAvailable,
      },
      {
        titleId: "borrower_personal_info.tower",
        value: borrower.tower || this.notAvailable,
      },
      {
        titleId: "borrower_personal_info.nameOfBuilding",
        value: borrower.nameOfBuilding || this.notAvailable,
      },
      {
        titleId: "borrower_personal_info.streetNameAndNumber",
        value: borrower.streetNameAndNumber || this.notAvailable,
      },
      {
        titleId: "borrower_personal_info.district",
        value:
          borrower.district !== null
            ? this.renderDistrict(borrower.district, renderToString)
            : this.notAvailable,
      },
      {
        titleId: "borrower_personal_info.propertyStatus",
        value: this.renderPropertyStatus(
          borrower.propertyStatus,
          renderToString
        ),
      },
      {
        titleId: "borrower_personal_info.heardFrom",
        value: borrower.heardFrom || this.notAvailable,
      },
      {
        titleId: "borrower_personal_info.premiseType",
        value: this.renderTypeOfPremise(borrower.premiseType, renderToString),
      },
      {
        titleId: "borrower_personal_info.numOfOwner",
        value: `${borrower.numOfOwner || this.notAvailable}`,
      },
      {
        titleId: "borrower_personal_info.monthlyRent",
        value:
          borrower.monthlyRent !== null
            ? this.renderMoney(borrower.monthlyRent)
            : this.notAvailable,
      },
    ];

    return listItems.map((it, i) => {
      return (
        <div key={i} className={styles.item}>
          <label>
            <FormattedMessage id={it.titleId} />
          </label>
          {it.value}
        </div>
      );
    });
  }

  renderPropertyInfoSection(
    borrower: BorrowerData,
    renderToString: (id: string, values?: Values | undefined) => string
  ) {
    const { propertyInfos } = borrower;
    const listOfListItems: ListItem[][] = propertyInfos.map(it => {
      return [
        {
          titleId: "borrower_personal_info.roomFloor",
          value: it.roomFloor || this.notAvailable,
        },
        {
          titleId: "borrower_personal_info.tower",
          value: it.tower || this.notAvailable,
        },
        {
          titleId: "borrower_personal_info.nameOfBuilding",
          value: it.nameOfBuilding || this.notAvailable,
        },
        {
          titleId: "borrower_personal_info.streetNameAndNumber",
          value: it.streetNameAndNumber || this.notAvailable,
        },
        {
          titleId: "borrower_personal_info.district",
          value:
            it.district !== null
              ? this.renderDistrict(it.district, renderToString)
              : this.notAvailable,
        },
      ];
    });
    return listOfListItems.map((listItems, i) => {
      return (
        <div key={i}>
          <h2>
            <FormattedMessage
              id="borrower_personal_info.property"
              values={{ n: i + 1 }}
            />
          </h2>
          <div className={styles.list}>
            {listItems.map((it, j) => {
              return (
                <div key={j} className={styles.item}>
                  <label>
                    <FormattedMessage id={it.titleId} />
                  </label>
                  {it.value}
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  }

  renderWorkInfoSection(
    borrower: BorrowerData,
    renderToString: (id: string, values?: Values | undefined) => string
  ) {
    const listItems: ListItem[] = [
      {
        titleId: "borrower_personal_info.isHired",
        value:
          borrower.isHired !== null
            ? this.renderBoolean(borrower.isHired, renderToString)
            : this.notAvailable,
      },
      {
        titleId: "borrower_personal_info.industry",
        value:
          borrower.industry !== null
            ? this.renderIndustry(borrower.industry)
            : this.notAvailable,
      },
      {
        titleId: "borrower_personal_info.workingCompanyNames",
        value: borrower.workingCompanyNames || this.notAvailable,
      },
      {
        titleId: "borrower_personal_info.position",
        value: borrower.position || this.notAvailable,
      },
      {
        titleId: "borrower_personal_info.employmentType",
        value: this.renderEmploymentType(
          borrower.employmentType,
          renderToString
        ),
      },
      {
        titleId: "borrower_personal_info.salary",
        value:
          borrower.salary !== null
            ? this.renderSalary(borrower.salary)
            : this.notAvailable,
      },
      {
        titleId: "borrower_personal_info.onBoardDate",
        value: borrower.onBoardDate || this.notAvailable,
      },
      {
        titleId: "borrower_personal_info.incomeProofType",
        value:
          borrower.incomeProofType !== null
            ? this.renderIncomeProofType(
                borrower.incomeProofType,
                renderToString
              )
            : this.notAvailable,
      },
      {
        titleId: "borrower_personal_info.paymentMethod",
        value: this.renderPaymentMethod(borrower.paymentMethod, renderToString),
      },
    ];

    return listItems.map((it, i) => {
      return (
        <div key={i} className={styles.item}>
          <label>
            <FormattedMessage id={it.titleId} />
          </label>
          {it.value}
        </div>
      );
    });
  }

  renderOtherIncomeSection(
    borrower: BorrowerData,
    renderToString: (id: string, values?: Values | undefined) => string
  ) {
    const { otherIncomes } = borrower;
    const listOfListItems: ListItem[][] = otherIncomes.map(it => {
      return [
        {
          titleId: "borrower_personal_info.workingCompanyNames",
          value: it.workingCompanyNames || this.notAvailable,
        },
        {
          titleId: "borrower_personal_info.position",
          value: it.position || this.notAvailable,
        },
        {
          titleId: "borrower_personal_info.salary",
          value:
            it.salary !== null
              ? this.renderSalary(it.salary.toFixed(0))
              : this.notAvailable,
        },
        {
          titleId: "borrower_personal_info.onBoardDate",
          value: it.onBoardDate || this.notAvailable,
        },
        {
          titleId: "borrower_personal_info.incomeProofType",
          value:
            it.incomeProofType !== null
              ? this.renderIncomeProofType(it.incomeProofType, renderToString)
              : this.notAvailable,
        },
        {
          titleId: "borrower_personal_info.paymentMethod",
          value: this.renderPaymentMethod(it.paymentMethod, renderToString),
        },
      ];
    });
    return listOfListItems.map((listItems, i) => {
      return (
        <div key={i}>
          <h2>
            <FormattedMessage
              id="borrower_personal_info.other_income"
              values={{ n: i + 1 }}
            />
          </h2>
          <div className={styles.list}>
            {listItems.map((it, j) => {
              return (
                <div key={j} className={styles.item}>
                  <label>
                    <FormattedMessage id={it.titleId} />
                  </label>
                  {it.value}
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  }

  renderExistingLoanSection(borrower: BorrowerData) {
    const { existingLoans } = borrower;
    const listOfListItems: ListItem[][] = existingLoans.map(it => {
      return [
        {
          titleId: "borrower_personal_info.loan.lender",
          value: it.lender || this.notAvailable,
        },
        {
          titleId: "borrower_personal_info.loan.amount",
          value:
            it.amount !== null
              ? this.renderMoney(it.amount)
              : this.notAvailable,
        },
        {
          titleId: "borrower_personal_info.loan.tenor",
          value: `${it.tenor || this.notAvailable}`,
        },
        {
          titleId: "borrower_personal_info.loan.remaining_tenor",
          value: `${it.remainingTenor || this.notAvailable}`,
        },
        {
          titleId: "borrower_personal_info.loan.monthly_repayment",
          value:
            it.monthlyRepayment !== null
              ? this.renderMoney(it.monthlyRepayment)
              : this.notAvailable,
        },
      ];
    });
    return listOfListItems.map((listItems, i) => {
      return (
        <div key={i}>
          <h2>
            <FormattedMessage
              id="borrower_personal_info.loan"
              values={{ n: i + 1 }}
            />
          </h2>
          <div className={styles.list}>
            {listItems.map((it, j) => {
              return (
                <div key={j} className={styles.item}>
                  <label>
                    <FormattedMessage id={it.titleId} />
                  </label>
                  {it.value}
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  }

  render() {
    const { chat, borrower } = this.props;

    return (
      <HideTabBar className={styles.outerContainer}>
        <IonHeader mode="ios" className={styles.header}>
          <IonToolbar mode="ios">
            <div className={styles.back}>
              <BackButton />
            </div>
            <div className={styles.title}>
              {chat.offering.agent.company.name}
            </div>
            <div className={styles.refNum}>{`#${chat.refNum}`}</div>
          </IonToolbar>
        </IonHeader>
        <MPContent className={styles.container} noKeyboardScrollHandler={true}>
          {borrower && (
            <LocaleContext.Consumer>
              {({ renderToString }) => (
                <>
                  <h1>
                    <FormattedMessage id={"borrower_personal_info.title"} />
                  </h1>
                  <div className={styles.list}>
                    {this.renderBasicInfoSection(borrower, renderToString)}
                  </div>
                  <div className={styles.list}>
                    <div className={styles.item}>
                      <label>
                        <FormattedMessage id="borrower_personal_info.do_you_have_any_property" />
                      </label>
                      {this.renderBoolean(
                        borrower.propertyInfos.length > 0,
                        renderToString
                      )}
                    </div>
                  </div>
                  {borrower.propertyInfos.length > 0
                    ? this.renderPropertyInfoSection(borrower, renderToString)
                    : null}
                  <h1>
                    <FormattedMessage id="borrower_personal_info.work_info" />
                  </h1>
                  <div className={styles.list}>
                    {this.renderWorkInfoSection(borrower, renderToString)}
                  </div>
                  <div className={styles.list}>
                    <div className={styles.item}>
                      <label>
                        <FormattedMessage id="borrower_personal_info.do_you_have_any_other_income" />
                      </label>
                      {this.renderBoolean(
                        borrower.otherIncomes.length > 0,
                        renderToString
                      )}
                    </div>
                  </div>
                  {borrower.otherIncomes.length > 0
                    ? this.renderOtherIncomeSection(borrower, renderToString)
                    : null}
                  <h1>
                    <FormattedMessage id="borrower_personal_info.loan_info_title" />
                  </h1>
                  <div className={styles.list}>
                    <div className={styles.item}>
                      <label>
                        <FormattedMessage id="borrower_personal_info.do_you_have_any_loan" />
                      </label>
                      {this.renderBoolean(
                        borrower.existingLoans.length > 0,
                        renderToString
                      )}
                    </div>
                  </div>
                  {borrower.existingLoans.length > 0 &&
                    this.renderExistingLoanSection(borrower)}
                </>
              )}
            </LocaleContext.Consumer>
          )}
        </MPContent>
      </HideTabBar>
    );
  }
}

export const BorrowerPersonalInfoView = withLocale(
  BorrowerPersonalInfoViewImpl
);
