import { IonHeader, IonImg, IonToolbar } from "@ionic/react";
import { FormattedMessage } from "@oursky/react-messageformat";

import React, { PureComponent } from "react";

import { PrimaryButton } from "../Button";
import { MPContent } from "../MPContent";

import { BorrowerBasicInfo, BorrowerPropertyInfo } from "../../models";
import { isLoading, RemoteData } from "../../utils/remoteData";
import { ValidationError } from "../../utils/validate";

import { FormValues as PropertyInfoFormValues } from "../BorrowerPropertyInfoView/PropertyInfoForm";
import { BackButton } from "../BackButton";

import styles from "./styles.module.scss";
import { Profile as images } from "../../images";
import { BorrowerBasicInfoForm } from "../BorrowerBasicInfoForm/BorrowerBasicInfoForm";
import { InputFields } from "../BorrowerBasicInfoForm/common";
import SuggestionDialogue from "../SuggestionDialogue/SuggestionDialogue";

interface Props {
  remoteSave: RemoteData<void>;
  error: ValidationError<InputFields>;
  borrowerBasicInfo: BorrowerBasicInfo;
  updateBorrowerBasicInfo: (value: Partial<BorrowerBasicInfo>) => void;

  borrowerPropertyInfo: BorrowerPropertyInfo;
  onUpdateExistingPropertyInfo: (
    index: number,
    value: Partial<PropertyInfoFormValues>
  ) => void;
  onUpdateHasProperty: (hasProperty: boolean) => void;
  onSelectNumberOfProperties: (numberOfProperties: number) => void;

  emailErrorId?: string;
  focusField?: InputFields;
  onSubmit?: () => void;
  onSkipToContact: () => void;
}

interface State {
  isSkipToContactViewShown: boolean;
}
export class BorrowerBasicInfoView extends PureComponent<Props, State> {
  state: State = {
    isSkipToContactViewShown: true,
  };

  hideSkipToContactView = () => {
    this.setState({ isSkipToContactViewShown: false });
  };

  skipToContact = () => {
    this.setState({ isSkipToContactViewShown: false });
    this.props.onSkipToContact();
  };

  render() {
    const {
      remoteSave,
      borrowerBasicInfo,
      borrowerPropertyInfo,
      error,
      updateBorrowerBasicInfo,
      onUpdateExistingPropertyInfo,
      onUpdateHasProperty,
      onSelectNumberOfProperties,
      emailErrorId,
      focusField,
      onSubmit,
    } = this.props;

    return (
      <>
        <IonHeader class={styles.toolbarContainer} mode="ios">
          <IonToolbar mode="ios">
            <div className={styles.back}>
              <BackButton />
            </div>
          </IonToolbar>
        </IonHeader>
        <MPContent class={styles.content} overflowScrolling="auto">
          <IonImg class={styles.icon} src={images.icon} />
          <p>
            <FormattedMessage id="borrower_basic_info.description" />
          </p>
          <BorrowerBasicInfoForm
            error={error}
            borrowerBasicInfo={borrowerBasicInfo}
            updateBorrowerBasicInfo={updateBorrowerBasicInfo}
            borrowerPropertyInfo={borrowerPropertyInfo}
            onUpdateExistingPropertyInfo={onUpdateExistingPropertyInfo}
            onUpdateHasProperty={onUpdateHasProperty}
            onSelectNumberOfProperties={onSelectNumberOfProperties}
            emailErrorId={emailErrorId}
            focusField={focusField}
          />
          {this.state.isSkipToContactViewShown && (
            <div className={styles.dialogueWrapper}>
              <SuggestionDialogue
                titleId={"request.skip_to_contact.title"}
                confirmButtonTextId={"request.skip_to_contact.button.confirm"}
                cancelButtonTextId={"request.skip_to_contact.button.cancel"}
                onConfirm={this.skipToContact}
                onCancel={this.hideSkipToContactView}
              />
            </div>
          )}
          <PrimaryButton
            class={styles.next}
            expand="full"
            onClick={onSubmit}
            disabled={isLoading(remoteSave)}
          >
            <FormattedMessage id={"borrower_basic_info.next"} />
          </PrimaryButton>
        </MPContent>
      </>
    );
  }
}
