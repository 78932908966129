import React from "react";
import styles from "./styles.module.scss";

export const LoadingLabel: React.FC = () => {
  return (
    <div className={styles.loading}>
      <label>Loading...</label>
    </div>
  );
};
