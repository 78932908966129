import React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import { withNav, OurNavContextProps } from "../../navigation";

import { common as images } from "../../images";

import styles from "./styles.module.scss";

export interface BackButtonProps
  extends RouteComponentProps,
    OurNavContextProps {
  defaultHref?: string;
}

class BackButtonImpl extends React.PureComponent<BackButtonProps> {
  onClick = () => {
    const { history, defaultHref } = this.props;

    if (defaultHref) {
      history.push(defaultHref);
    } else {
      this.props.navContext.goBack();
    }
  };

  render() {
    return (
      <img
        onClick={this.onClick}
        className={styles.backButton}
        src={images.backButton}
        alt="Back"
      />
    );
  }
}

export const BackButton = withRouter(withNav(BackButtonImpl));
