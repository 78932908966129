export interface Template {
  id: string;
  type: TemplateType;
  contentEn: string;
  contentZhHant: string;
  contentZhHans: string;
  companyId: string | null;
}

export enum TemplateType {
  chatroomHeader = "chatroom_header",
  chatroomPopup = "chatroom_popup",
  directApplyPopup = "direct_apply_popup",
  licenceInfo = "licence_info",
  referenceOnly = "reference_only",
}

export type LocalizedTemplates = { [key in TemplateType]?: string };
