import classNames from "classnames";
import React from "react";
import { IonHeader, IonToolbar, IonPage } from "@ionic/react";
import { FormattedMessage } from "@oursky/react-messageformat";

import styles from "./styles.module.scss";

import { MPContent } from "../MPContent";
import { BackButton } from "../BackButton";
import { PrimaryButton } from "../Button";

import { RemoteData, isLoading } from "../../utils/remoteData";
import {
  BorrowerBasicInfo,
  BorrowerWorkInfo,
  ExistingLoan,
  OtherIncome,
} from "../../models";
import {
  EditableBorrowerData,
  InputFields,
} from "../../screens/EditBorrowerProfileScreen";
import { ValidationError } from "../../utils/validate";
import { FormValues as PropertyInfoFormValues } from "../BorrowerPropertyInfoView/PropertyInfoForm";
import { InputFields as BorrwerBasicInfoInputFields } from "../BorrowerBasicInfoForm/common";
import BorrowerWorkInfoForm, {
  InputFields as BorrwerWorkInfoInputFields,
} from "../BorrowerWorkInfoView/BorrowerWorkInfoForm";
import { EditBorrowerBasicInfoForm } from "../BorrowerBasicInfoForm/EditBorrowerBasicInfoForm";
import {
  BorrowerLoanRecordForm,
  InputFields as BorrowerLoanRecordInputFields,
} from "../BorrowerLoanRecordForm";

interface Props {
  remoteUpdate: RemoteData<void>;
  borrowerData: EditableBorrowerData;
  error: ValidationError<InputFields>;
  focusField?: InputFields;
  nextField?: InputFields;
  emailErrorId?: string;

  updateBorrowerBasicInfoData: (value: Partial<BorrowerBasicInfo>) => void;
  onUpdateExistingPropertyInfo: (
    index: number,
    value: Partial<PropertyInfoFormValues>
  ) => void;
  onUpdateHasProperty: (hasProperty: boolean) => void;
  onSelectNumberOfProperties: (numberOfProperties: number) => void;
  onRemoveExistingProperty?: (index: number) => void;
  onAddExistingProperty?: () => void;

  updateBorrowerWorkInfoData: (value: Partial<BorrowerWorkInfo>) => void;
  hasIncomeProof: boolean | null;
  updateHasIncomeProof: (hasIncomeProof: boolean) => void;
  onUpdateExistingOtherIncome: (
    index: number,
    value: Partial<OtherIncome>
  ) => void;
  onSelectNumberOfOtherIncomes: (numberOfOtherIncomes: number) => void;
  onUpdateHasOtherIncome: (hasOtherIncome: boolean) => void;
  onRemoveExistingOtherIncome?: (index: number) => void;
  onAddExistingOtherIncome?: () => void;

  numberOfLoan: number | null;
  onSelectMortgageStatus: (isEmployed: boolean) => void;
  onSelectNumberOfLoan: (numberOfLoan: number) => void;
  onUpdateExistingLoans: (index: number, value: Partial<ExistingLoan>) => void;
  onRemoveExistingLoan?: (index: number) => void;
  onAddExistingLoan?: () => void;

  onSubmit: () => void;
}

// eslint-disable-next-line react/prefer-stateless-function
class EditDataProfileView extends React.PureComponent<Props> {
  render() {
    const {
      remoteUpdate,
      focusField,
      error,
      borrowerData,
      emailErrorId,
      updateBorrowerBasicInfoData,
      onUpdateExistingPropertyInfo,
      onUpdateHasProperty,
      onSelectNumberOfProperties,
      updateBorrowerWorkInfoData,
      onRemoveExistingProperty,
      onAddExistingProperty,
      hasIncomeProof,
      updateHasIncomeProof,
      onUpdateExistingOtherIncome,
      onSelectNumberOfOtherIncomes,
      onUpdateHasOtherIncome,
      onAddExistingOtherIncome,
      onRemoveExistingOtherIncome,
      numberOfLoan,
      onSelectMortgageStatus,
      onSelectNumberOfLoan,
      onUpdateExistingLoans,
      onRemoveExistingLoan,
      onAddExistingLoan,
    } = this.props;

    return (
      <IonPage className={styles.outerContainer}>
        <IonHeader mode="ios">
          <IonToolbar mode="ios">
            <div className={styles.back}>
              <BackButton />
            </div>
            <div className={styles.title}>
              <FormattedMessage id="edit_data.title" />
            </div>
          </IonToolbar>
        </IonHeader>
        <MPContent
          className={styles.content}
          forceOverscroll={false}
          overflowScrolling="auto"
        >
          <div>
            <div
              className={classNames(
                styles.infoSectionLebel,
                styles.basicInfoLabel
              )}
            >
              <FormattedMessage id="edit_data.basic_informaiton" />
            </div>
            <EditBorrowerBasicInfoForm
              borrowerBasicInfo={borrowerData.basicInfo}
              updateBorrowerBasicInfo={updateBorrowerBasicInfoData}
              borrowerPropertyInfo={borrowerData.propertyInfo}
              onUpdateExistingPropertyInfo={onUpdateExistingPropertyInfo}
              onUpdateHasProperty={onUpdateHasProperty}
              onSelectNumberOfProperties={onSelectNumberOfProperties}
              onAddExistingProperty={onAddExistingProperty}
              onRemoveExistingProperty={onRemoveExistingProperty}
              emailErrorId={emailErrorId}
              focusField={focusField as BorrwerBasicInfoInputFields}
              error={error}
            />
          </div>

          <div>
            <div
              className={classNames(
                styles.infoSectionLebel,
                styles.workingInfoLabel
              )}
            >
              <FormattedMessage id="edit_data.workingInfo.label" />
            </div>
            <BorrowerWorkInfoForm
              borrowerWorkInfo={borrowerData.workInfo}
              hasIncomeProof={hasIncomeProof}
              updateHasIncomeProof={updateHasIncomeProof}
              updateBorrowerWorkInfo={updateBorrowerWorkInfoData}
              borrowerOtherIncomeInfo={borrowerData.otherIncomeInfo}
              onUpdateExistingOtherIncome={onUpdateExistingOtherIncome}
              onUpdateHasOtherIncome={onUpdateHasOtherIncome}
              onSelectNumberOfOtherIncomes={onSelectNumberOfOtherIncomes}
              onAddExistingOtherIncome={onAddExistingOtherIncome}
              onRemoveExistingOtherIncome={onRemoveExistingOtherIncome}
              error={error}
              focusField={focusField as BorrwerWorkInfoInputFields}
            />
          </div>
          <div>
            <div
              className={classNames(
                styles.infoSectionLebel,
                styles.loanInfoLabel
              )}
            >
              <FormattedMessage id="edit_data.loanInfo.label" />
            </div>
            <BorrowerLoanRecordForm
              borrower={borrowerData.loanInfo}
              numberOfLoan={numberOfLoan}
              selectMortgageStatus={onSelectMortgageStatus}
              updateExistingLoans={onUpdateExistingLoans}
              selectNumberOfLoan={onSelectNumberOfLoan}
              removeExistingLoan={onRemoveExistingLoan}
              addExistingLoan={onAddExistingLoan}
              error={error}
              focusField={focusField as BorrowerLoanRecordInputFields}
            />
          </div>
          <div className={styles.buttonContainer}>
            <PrimaryButton
              class={styles.submitButton}
              expand="full"
              onClick={this.props.onSubmit}
              disabled={isLoading(remoteUpdate)}
            >
              <FormattedMessage id="edit_data.button.complete" />
            </PrimaryButton>
          </div>
        </MPContent>
      </IonPage>
    );
  }
}

export default EditDataProfileView;
