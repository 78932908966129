import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router";

import { Chat } from "../models";

import { withChat, ChatContextProps } from "../context/ChatContext";
import { withLoading, LoadingContextProps } from "../context/LoadingContext";
import {
  withErrorAlert,
  ErrorAlertContextProps,
} from "../context/ErrorAlertContext";

import { OfferingListView } from "../components/OfferingListView";

export interface GetRequestIdLocationState {
  requestId?: string;
}

type Props = RouteComponentProps<
  {},
  {},
  GetRequestIdLocationState | undefined
> &
  ChatContextProps &
  LoadingContextProps &
  ErrorAlertContextProps;

interface State {
  requestId?: string;
}

class OfferingListScreenImpl extends PureComponent<Props, State> {
  private isCallingAPI = false;
  private subscribedConversationEventListener: {
    remove: () => void;
  } | null = null;

  constructor(props: Props) {
    super(props);

    const stateFromLocationState = this.getStateFromLocationState();

    if (!stateFromLocationState) {
      this.state = {};
      props.history.push("/offering");
    }

    this.state = {
      ...stateFromLocationState,
    };
  }

  componentDidMount() {
    this.subscribedConversationEventListener = this.props.chatContext.subscribeConversationEventEmitter(
      e => {
        if (e.type === "delete") {
          this.props.chatContext.updateChatListVersion(() => {});
        }
      }
    );
  }

  componentWillUnmount() {
    if (this.subscribedConversationEventListener) {
      this.subscribedConversationEventListener.remove();
    }
  }

  getStateFromLocationState = () => {
    const { location } = this.props;
    if (location.state && location.state.requestId) {
      return {
        requestId: location.state.requestId,
      };
    }

    return undefined;
  };

  fetchPage = (cursor?: string) => {
    if (this.state.requestId === undefined) {
      return Promise.resolve({
        nextCursor: null,
        results: [],
      });
    }
    return this.props.chatContext.borrowerListChatByRequestId(
      this.state.requestId,
      cursor
    );
  };

  openChatroom = (chat: Chat) => {
    const { history } = this.props;

    if (this.isCallingAPI) {
      return;
    }

    this.isCallingAPI = true;
    this.props.loadingContext.show();
    this.props.chatContext
      .enterConversation(chat)
      .then(chatWithConversation => {
        history.push("chatroom", {
          conversationId: chatWithConversation.conversationId,
        });
      })
      .catch(this.props.errorAlertContext.show)
      .finally(() => {
        this.props.loadingContext.dismiss();
        this.isCallingAPI = false;
      });
  };

  render() {
    return (
      <OfferingListView
        listVersion={this.props.chatContext.chatListVersion}
        fetchPage={this.fetchPage}
        openChatroom={this.openChatroom}
      />
    );
  }
}

export const OfferingListScreen = withChat(
  withLoading(withErrorAlert(OfferingListScreenImpl))
);

export default OfferingListScreen;
