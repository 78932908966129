import React from "react";
import {
  FormattedMessage,
  Context as LocaleContext,
} from "@oursky/react-messageformat";

import { ChatStatus, ReferralAgreementStatus } from "../../models";

import { StatusLightBox } from "./StatusLightBox";
import styles from "./styles.module.scss";

interface Props {
  disabled: boolean;
  isCompleted: boolean;
  referralAgreement: ReferralAgreementStatus | null;
  onStatusChange: (isCompleted: boolean, isReferralRequested: boolean) => void;
}

export class StatusSelector extends React.PureComponent<Props> {
  selectRef = React.createRef<HTMLSelectElement>();

  onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = e.target.value;

    if (selected === ChatStatus.acceptedReferral) {
      // Cannot select accepted referral by agent
      return;
    }

    const isCompleted = selected === ChatStatus.completed;
    const isReferralRequested = selected === ChatStatus.requestReferral;
    this.props.onStatusChange(isCompleted, isReferralRequested);
  };

  onStatusClick = () => {
    if (this.selectRef.current) {
      this.selectRef.current.click();
    }
  };

  selectedValue = () => {
    const { isCompleted, referralAgreement } = this.props;
    if (isCompleted) {
      return ChatStatus.completed;
    }
    switch (referralAgreement) {
      case ReferralAgreementStatus.requested:
        return ChatStatus.requestReferral;
      case ReferralAgreementStatus.accepted:
        return ChatStatus.acceptedReferral;
      default:
        return ChatStatus.inProgress;
    }
  };

  render() {
    const { isCompleted, disabled, referralAgreement } = this.props;

    const selectedValue = this.selectedValue();
    return (
      <div className={styles.statusSelector}>
        <div className={styles.statusSelectorLabel}>
          <FormattedMessage id="chatroom.status_selector.label" />
        </div>
        <LocaleContext.Consumer>
          {({ renderToString }) => (
            <select
              disabled={disabled}
              ref={this.selectRef}
              value={selectedValue}
              onChange={this.onSelectChange}
            >
              <option value={ChatStatus.inProgress}>
                {renderToString("chat.in_progress")}
              </option>
              <option value={ChatStatus.requestReferral}>
                {renderToString("chat.ready_referral")}
              </option>
              {selectedValue === ChatStatus.acceptedReferral && (
                <option value={ChatStatus.acceptedReferral} disabled={true}>
                  {renderToString("chat.accepted_referral")}
                </option>
              )}
              <option value={ChatStatus.completed}>
                {renderToString("chat.completed")}
              </option>
            </select>
          )}
        </LocaleContext.Consumer>
        <StatusLightBox
          isCompleted={isCompleted}
          referralAgreement={referralAgreement}
          onClick={this.onStatusClick}
        />
      </div>
    );
  }
}
