import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { UserContext } from "../context/UserContext";
import { UserType } from "../models";

import { RouteConfig } from "../types/routes";
import { getValidPathPattern } from "../utils/routes";

import CalculatorScreen from "../screens/CalculatorScreen";
import ChatScreen from "../screens/ChatScreen";
import { ChatroomScreen } from "../screens/ChatroomScreen";
import { BorrowerPersonalInfoScreen } from "../screens/BorrowerPersonalInfoScreen";
import { NotFoundView } from "../components/NotFoundView";

import { BaseTabs } from "../components/BaseTabs";
import { TabBar } from "../components/TabBar";

import { AgentRouteDefaultPath } from "./AgentRoutes";
import EditBorrowerProfileScreen from "../screens/EditBorrowerProfileScreen";
import AccountScreen from "../screens/AccountScreen";
import OfferingListScreen from "../screens/OfferingListScreen";
import ChatOfferingListScreen from "../screens/ChatOfferingListScreen";
import BorrowerOfferingListScreen from "../screens/BorrowerOfferingListScreen";

const Images = {
  accountIcon: {
    active: require("../images/icon-account-active.svg"),
    inactive: require("../images/icon-account-inactive.svg"),
  },
  calculatorIcon: {
    active: require("../images/icon-calculator-active.svg"),
    inactive: require("../images/icon-calculator-inactive.svg"),
  },
  chatIcon: {
    active: require("../images/icon-chat-active.svg"),
    inactive: require("../images/icon-chat-inactive.svg"),
  },
  getOfferingIcon: {
    active: require("../images/icon-get-offering.svg"),
    inactive: require("../images/icon-get-offering.svg"),
  },
  offeringIcon: {
    active: require("../images/icon-offering-active.svg"),
    inactive: require("../images/icon-offering-inactive.svg"),
  },
};

export const BorrowerRouteDefaultPath = "/offering";

const RoutesConfigs: RouteConfig[] = [
  {
    path: "offering",
    component: BorrowerOfferingListScreen,
    isPublic: true,
    tab: {
      icon: Images.offeringIcon,
    },
  },
  {
    path: "chat",
    component: ChatScreen,
    tab: {
      icon: Images.chatIcon,
    },
  },
  {
    path: "chat/offering-list",
    component: ChatOfferingListScreen,
  },
  {
    path: "chat/chatroom",
    component: ChatroomScreen,
  },
  {
    path: "chat/personal-info",
    component: BorrowerPersonalInfoScreen,
  },
  {
    path: "calculator",
    component: CalculatorScreen,
    isPublic: true,
    tab: {
      icon: Images.calculatorIcon,
    },
  },
  {
    path: "account",
    component: AccountScreen,
    tab: {
      icon: Images.accountIcon,
    },
  },
  {
    path: "account/edit-data",
    component: EditBorrowerProfileScreen,
  },
  // {
  //   path: "offering/request-and-offering-list",
  //   component: OfferingAndRequestListScreen,
  //   isPublic: true,
  // },
  {
    path: "offering/offering-list",
    component: OfferingListScreen,
  },
  {
    path: "offering/chatroom",
    component: ChatroomScreen,
  },
  {
    path: "offering/personal-info",
    component: BorrowerPersonalInfoScreen,
  },
];

export class BorrowerRoutes extends React.PureComponent {
  renderRedirect() {
    return <Redirect to={BorrowerRouteDefaultPath} />;
  }

  render() {
    const { user } = this.context;
    if (user && user.type === UserType.agent) {
      return <Redirect to={AgentRouteDefaultPath} />;
    }

    return (
      <Switch>
        {user && (
          <Route
            path={getValidPathPattern(RoutesConfigs)}
            exact={true}
            component={Tabs}
          />
        )}
        {!user && (
          <Route
            path={getValidPathPattern(RoutesConfigs, false)}
            render={this.renderRedirect}
            exact={true}
          />
        )}
        {!user && (
          <Route
            path={getValidPathPattern(RoutesConfigs, true)}
            exact={true}
            component={Tabs}
          />
        )}
        <Route component={NotFoundView} />
      </Switch>
    );
  }
}
BorrowerRoutes.contextType = UserContext;

const Tabs: React.FC = () => {
  return (
    <BaseTabs outletId="borrower-tabs" routeConfigs={RoutesConfigs}>
      <TabBar routeConfigs={RoutesConfigs} />
    </BaseTabs>
  );
};
