import * as yup from "yup";

import {
  allTUGrades,
  ExchangeLoanRequest,
  MortgageRequest,
  PersonalLoanRequest,
  Request,
  RequestType,
  TUGrade,
} from "../models";

import {
  borrowerBriefDataSchema,
  nullablePremiseTypeSchema,
  nullablePropertyStatusSchema,
} from "./user";

import { DateTimeSchema } from "./luxon";

const nullableTUGradeSchema = yup
  .string()
  .oneOf([...allTUGrades, null]) as yup.Schema<TUGrade>;

export const currencySchema = yup
  .object({
    id: yup.string().required(),
    isoCode: yup.string().required(),
  })
  .camelCase();

function requestTypeSchema(requestType: RequestType) {
  return yup.mixed().oneOf([requestType], "Unexpected Request Type");
}

const baseRequestSchema = {
  id: yup.string().required(),
  borrower: borrowerBriefDataSchema.required(),
  refNum: yup.number().required(),
  cancelledAt: new DateTimeSchema().nullable(),
  createdAt: new DateTimeSchema().required(),
  numOffering: yup.number().nullable(),
  unreadCount: yup.number().nullable(),
  conversationIds: yup.array(yup.string()),
};

export const exchangeLoanRequestSchema: yup.ObjectSchema<
  ExchangeLoanRequest
> = yup
  .object({
    type: requestTypeSchema(RequestType.exchangeLoan),
    sellingCurrency: currencySchema.required(),
    buyingCurrency: currencySchema.required(),
    sellingAmount: yup.number().required(),
  })
  .shape(baseRequestSchema)
  .camelCase();

export const mortgageRequestSchema: yup.Schema<MortgageRequest> = yup
  .object({
    type: requestTypeSchema(RequestType.mortgage),
    mortgageLevel: yup.string().required(),
    buildingValue: yup.number().required(),
    loanAmount: yup.number().required(),

    monthlyIncome: yup.number().nullable(),
    currentLoanAmount: yup.number().nullable(),
    purposingOfMortgage: yup.string().nullable(),
    nameOfBuilding: yup.string().nullable(),
    streetNameAndNumber: yup.string().nullable(),
    district: yup.string().nullable(),
    period: yup.number().nullable(),
    block: yup.string().nullable(),
    premiseType: nullablePremiseTypeSchema.nullable(),
    propertyStatus: nullablePropertyStatusSchema.nullable(),
    numOfOwner: yup.number().nullable(),
    rentIncome: yup.number().nullable(),
    numOfResident: yup.number().nullable(),
    expectedMarketValue: yup.number().nullable(),
    filedForBankruptcy: yup.boolean().nullable(),
    appliedForIva: yup.boolean().nullable(),
    numPersonalLoanForPastTwoWeeks: yup.number().nullable(),
    numOfLoanEnquiriesInOneMonth: yup.number().nullable(),
    hasLatePayment: yup.boolean().nullable(),
    outstandingLoan: yup.number().nullable(),
    isTaxReturnFormAvailable: yup.boolean().nullable(),
    tuGrade: nullableTUGradeSchema.nullable(),
  })
  .shape(baseRequestSchema)
  .camelCase();

export const personalLoanRequestSchema: yup.ObjectSchema<
  PersonalLoanRequest
> = yup
  .object({
    type: requestTypeSchema(RequestType.personalLoan),
    amount: yup.number().required(),
    period: yup.number().required(),
    monthlyIncome: yup.number().nullable(),
    currentLoanAmount: yup.number().nullable(),
    purposingOfFinancing: yup.string().nullable(),
    filedForBankruptcy: yup.boolean().nullable(),
    appliedForIva: yup.boolean().nullable(),
    numPersonalLoanForPastTwoWeeks: yup.number().nullable(),
    numOfLoanEnquiriesInOneMonth: yup.number().nullable(),
    hasLatePayment: yup.boolean().nullable(),
    isTaxReturnFormAvailable: yup.boolean().nullable(),
    tuGrade: nullableTUGradeSchema.nullable(),
    collateral: yup.string().nullable(),
    collateralValue: yup.number().nullable(),
  })
  .shape(baseRequestSchema)
  .camelCase();

export const requestSchema = yup
  .object<Request>()
  .when(".type", (type: any) => {
    switch (type) {
      case RequestType.exchangeLoan:
        return exchangeLoanRequestSchema;
      case RequestType.mortgage:
        return mortgageRequestSchema;
      case RequestType.personalLoan:
        return personalLoanRequestSchema;
      default:
        throw new Error(`Unexpected type = ${type}`);
    }
  });

export const requestListSchema: yup.Schema<Request[]> = yup.array(
  requestSchema
);
