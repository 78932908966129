import React from "react";
import { Switch, Route, Redirect } from "react-router";
import { PresentationRoutes, PresentationContext } from "../navigation";

import { UserContext } from "../context/UserContext";
import { WalkthroughContext } from "../context/WalkthroughContext";

import { AgentRoutes, AgentRouteDefaultPath } from "./AgentRoutes";
import { BorrowerRoutes, BorrowerRouteDefaultPath } from "./BorrowerRoutes";
import {
  AdminImpersonateAgentRoutes,
  LoginRoutes,
  ProfileRoutes,
  SignUpRoutes,
} from "./AuthRoutes";

import Walkthrough from "../screens/Walkthrough";
import { NotFoundView } from "../components/NotFoundView";

import { UserType } from "../models";
import { WalkthroughStage } from "../types/walkthrough";
import { isPlatform } from "../utils/platform";
import { LoadingContext } from "../context/LoadingContext";

const LandingRoute: React.FC = () => {
  const { user } = React.useContext(UserContext);
  const { walkthroughStage } = React.useContext(WalkthroughContext);

  const shouldShowWalkthrough =
    isPlatform("hybrid") && walkthroughStage === WalkthroughStage.Unseen;

  if (user && user.type === UserType.agent) {
    return <Redirect to={AgentRouteDefaultPath} />;
  } else if (user && user.type === UserType.borrower) {
    return <Redirect to={BorrowerRouteDefaultPath} />;
  } else if (shouldShowWalkthrough) {
    return <Redirect to="/walkthrough" />;
  }

  return <Redirect to={BorrowerRouteDefaultPath} />;
};

const MainRoute: React.FC = () => {
  const { user, canImpersonateAgent, logout } = React.useContext(UserContext);
  const { present } = React.useContext(PresentationContext);
  const { show: showLoading, dismiss: dismissLoading } = React.useContext(
    LoadingContext
  );

  React.useEffect(() => {
    if (canImpersonateAgent) {
      showLoading();
      setTimeout(() => {
        present("/impersonate-agent", { onCancel: () => logout() });
        dismissLoading();
      }, 1000);
    }
  }, [canImpersonateAgent, present, logout, showLoading, dismissLoading]);

  return (
    <Switch>
      <Route path="/" exact={true} component={LandingRoute} />
      <Route exact={true} path="/walkthrough" component={Walkthrough} />
      {user && user.type === UserType.agent ? (
        <Route path="/" component={AgentRoutes} />
      ) : (
        <Route path="/" component={BorrowerRoutes} />
      )}
      <Route component={NotFoundView} />
    </Switch>
  );
};

export const AppRoute: React.FC = () => {
  return (
    <>
      <PresentationRoutes>
        {SignUpRoutes}
        {LoginRoutes}
        {ProfileRoutes}
        {AdminImpersonateAgentRoutes}
      </PresentationRoutes>
      <MainRoute />
    </>
  );
};
