import React, { PureComponent } from "react";

import styles from "./styles.module.scss";

import { InfiniteScrollView } from "../InfiniteListView";
import { ChatOfferingListCardView } from "../ChatOfferingListCardView";

import { Page } from "../../utils/pagination";
import { Chat } from "../../models";
import { ChatContextProps, withChat } from "../../context/ChatContext";

type Props = {
  fetchPage: (cursor?: string) => Promise<Page<Chat>>;
  openChatroom: (chat: Chat) => void;
  infiniteScrollViewRef: React.RefObject<InfiniteScrollView<Chat>>;
} & ChatContextProps;

class ListViewImpl extends PureComponent<Props> {
  render() {
    const { fetchPage, infiniteScrollViewRef } = this.props;

    return (
      <>
        <InfiniteScrollView
          ref={infiniteScrollViewRef}
          listClassName={styles.list}
          emptyMessageId="common.empty_list"
          fetchPage={fetchPage}
          renderItem={this.renderItem}
          threshold={400}
        />
      </>
    );
  }

  renderItem = (chat: Chat) => {
    const { openChatroom } = this.props;
    return (
      <ChatOfferingListCardView
        chat={chat}
        key={chat.id}
        openChatroom={openChatroom}
      />
    );
  };
}

export const ListView = withChat(ListViewImpl);
