import { DateTime } from "luxon";
import { BorrowerBriefData, PremiseType, PropertyStatus } from "./borrower";

export type Request =
  | ExchangeLoanRequest
  | MortgageRequest
  | PersonalLoanRequest;

export enum RequestType {
  exchangeLoan = "exchange_loan",
  mortgage = "mortgage",
  personalLoan = "personal_loan",
}

export interface BaseRequest {
  id: string;
  borrower: BorrowerBriefData;
  refNum: number;
  cancelledAt: DateTime | null;
  createdAt: DateTime;
  numOffering: number | null;
  unreadCount: number | null;
  conversationIds: string[];
}

export interface ExchangeLoanRequest extends BaseRequest {
  type: RequestType.exchangeLoan;
  sellingCurrency: Currency;
  buyingCurrency: Currency;
  sellingAmount: number;
}

export interface MortgageRequest extends BaseRequest {
  type: RequestType.mortgage;
  loanAmount: number;
  mortgageLevel: string;
  buildingValue: number;

  monthlyIncome: number | null;
  currentLoanAmount: number | null;
  purposingOfMortgage: string | null;
  nameOfBuilding: string | null;
  streetNameAndNumber: string | null;
  district: string | null;
  period: number | null;
  block: string | null;
  premiseType: PremiseType | null;
  propertyStatus: PropertyStatus | null;
  numOfOwner: number | null;
  rentIncome: number | null;
  numOfResident: number | null;
  filedForBankruptcy: boolean | null;
  appliedForIva: boolean | null;
  numPersonalLoanForPastTwoWeeks: number | null;
  numOfLoanEnquiriesInOneMonth: number | null;
  hasLatePayment: boolean | null;
  isTaxReturnFormAvailable: boolean | null;
  tuGrade: TUGrade | null;
}

export interface PersonalLoanRequest extends BaseRequest {
  type: RequestType.personalLoan;
  amount: number;
  period: number;
  monthlyIncome: number | null;
  currentLoanAmount: number | null;
  purposingOfFinancing: string | null;
  filedForBankruptcy: boolean | null;
  appliedForIva: boolean | null;
  numPersonalLoanForPastTwoWeeks: number | null;
  numOfLoanEnquiriesInOneMonth: number | null;
  hasLatePayment: boolean | null;
  isTaxReturnFormAvailable: boolean | null;
  tuGrade: TUGrade | null;
  collateral: string | null;
  collateralValue: number | null;
}

export interface Currency {
  id: string;
  isoCode: string;
}

export enum TUGrade {
  a = "A",
  b = "B",
  c = "C",
  d = "D",
  e = "E",
  f = "F",
  g = "G",
  h = "H",
  i = "I",
  j = "J",
}

export const allTUGrades: TUGrade[] = [
  TUGrade.a,
  TUGrade.b,
  TUGrade.c,
  TUGrade.d,
  TUGrade.e,
  TUGrade.f,
  TUGrade.g,
  TUGrade.h,
  TUGrade.i,
  TUGrade.j,
];
