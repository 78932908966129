import { AppTrackingTransparencyStatus } from "capacitor-app-tracking-transparency";
import React from "react";
import skygear from "skygear";
import { Plugins, StatusBarStyle } from "@capacitor/core";
import { IonApp } from "@ionic/react";

import { makeApiClient } from "./apiClient";
import { makeSkygearChatService } from "./services/chat";
import { makeSkygearPubSubService } from "./services/pubsub";
import { HashRouter, BrowserRouter } from "react-router-dom";
import { withRouter, RouteComponentProps } from "react-router";

import { ContextProviders } from "./context";
import { withPresentation, PresentationContextProps } from "./navigation";
import { OptionsProps, withOptions } from "./context/OptionsContext";
import { TemplateContextProps, withTemplate } from "./context/TemplateContext";
import {
  WalkthroughContextProps,
  withWalkthrough,
} from "./context/WalkthroughContext";
import { UserContextProps, withUser } from "./context/UserContext";
import { ChatContextProps, withChat } from "./context/ChatContext";
import { LoadingContextProps, withLoading } from "./context/LoadingContext";
import {
  ErrorAlertContextProps,
  withErrorAlert,
} from "./context/ErrorAlertContext";

import SentryBoundary from "./components/SentryBoundary";
import { AppRoute } from "./routes/AppRoute";
import { BorrowerRouteDefaultPath } from "./routes/BorrowerRoutes";
import { AgentRouteDefaultPath } from "./routes/AgentRoutes";

import { Locale } from "./types/misc";
import { UserType } from "./models";
import { chatSchema } from "./schemas/chat";

import { getLocale } from "./utils/locale";
import { hybridOnly, iOSHybridOnly, isPlatform } from "./utils/platform";
import {
  isNotComplete,
  isRegistered,
  WhoamiResponseUser,
} from "./utils/whoamiResponseUser";

import { SPLASH_SCREEN_DURATION_IN_MS } from "./constants/misc";
import {
  BASENAME,
  ROUTER_IMPL,
  SKYGEAR_API_KEY,
  SKYGEAR_ENDPOINT,
  BUILD,
} from "./config";

import { LoadingLabel } from "./components/LoadingLabel";

import "@ionic/core/css/core.css";
import "@ionic/core/css/ionic.bundle.css";
import {
  CheckAppVersionContextProps,
  withCheckAppVersion,
} from "./context/CheckAppVersionContext";

const {
  SplashScreen,
  App: AppHandle,
  PushNotifications,
  StatusBar,
  CapacitorAppTrackingTransparency,
} = Plugins;

const START_TIME = new Date().getTime();

type Props = UserContextProps &
  ErrorAlertContextProps &
  OptionsProps &
  TemplateContextProps &
  WalkthroughContextProps &
  RouteComponentProps &
  ChatContextProps &
  LoadingContextProps &
  PresentationContextProps &
  CheckAppVersionContextProps;
interface State {
  isReady: boolean;
}

const PresentationPathsToIgnoreBackButton = [
  "/login/welcome/agent",
  "/signup/welcome/agent",
];

class AppImpl extends React.PureComponent<Props, State> {
  state: State = {
    isReady: false,
  };

  hideSplashScreen() {
    const elapseTime = new Date().getTime() - START_TIME;
    const hideSplashScreenAfter = SPLASH_SCREEN_DURATION_IN_MS - elapseTime;
    if (hideSplashScreenAfter > 0) {
      setTimeout(() => {
        SplashScreen.hide();
      }, hideSplashScreenAfter);
    } else {
      SplashScreen.hide();
    }
  }

  onAppReady() {
    this.setState({ isReady: true });
    hybridOnly(() => {
      this.hideSplashScreen();
      this.setupBackButtonHandler();
      this.setupPushNotificationHandler();
      this.setupStatusBarStyleHandler();
    });
    iOSHybridOnly(() => {
      this.requestAppTrackingTransparencyIfNeeded();
    });
    this.props.chatContext.subscribeMessageEvent();
  }

  onBackButton = () => {
    const { pathname } = this.props.location;
    const { user } = this.props.userContext;
    const homePath =
      user && user.type === UserType.agent
        ? AgentRouteDefaultPath
        : BorrowerRouteDefaultPath;

    if (this.props.presentationContext.isPresenting) {
      const path = this.props.presentationContext.getPresentationPath() || "";
      if (PresentationPathsToIgnoreBackButton.indexOf(path) === -1) {
        this.props.history.goBack();
      }
    } else {
      if (pathname === homePath) {
        AppHandle.exitApp();
      } else {
        this.props.history.goBack();
      }
    }
  };

  setupBackButtonHandler() {
    AppHandle.addListener("backButton", this.onBackButton);
  }

  onDeviceRegistered = (token: { value: string }) => {
    const appID =
      BUILD === "production"
        ? "hk.com.moneyplaza.app"
        : `com.oursky.moneyplaza.${BUILD}`;

    skygear.push
      .registerDevice(token.value, isPlatform("ios") ? "ios" : "android", appID)
      .catch(() => {});
  };

  onPushNotificationActionPerformed = (notificationWithAction: {
    actionId: string;
    notification: any;
  }) => {
    const payload = notificationWithAction.notification.data;
    if (payload["from_skygear"] !== "1") {
      return;
    }

    switch (payload["event"]) {
      case "new_message":
      case "new_conversation":
        this.openChatroom(JSON.parse(payload["chat"]));
        break;
      default:
        break;
    }
  };

  onPushNotificationReceived = async (notification: any) => {
    const payload = notification.data;
    if (payload["from_skygear"] !== "1") {
      return;
    }

    switch (payload["event"]) {
      case "new_conversation":
        await this.props.chatContext.updateChatListVersion({});
        break;
      default:
        break;
    }
  };

  openChatroom = (chatData: any) => {
    chatSchema
      .validate(chatData)
      .then(chat => {
        const paths = this.props.location.pathname.split("/");
        if (
          paths[paths.length - 1] === "chatroom" &&
          this.props.chatContext.currentChatId === chat.id
        ) {
          return;
        }

        this.props.history.push("/chat");
        this.props.loadingContext.show();
        const openChatroomAfterLeaving = () => {
          if (this.props.chatContext.currentChatId !== undefined) {
            setTimeout(openChatroomAfterLeaving, 500);
            return;
          }

          this.props.chatContext
            .enterConversation(chat)
            .then(chat => {
              this.props.history.push("/chat/chatroom", {
                conversationId: chat.conversationId,
              });
            })
            .catch(this.props.errorAlertContext.show)
            .finally(this.props.loadingContext.dismiss);
        };
        openChatroomAfterLeaving();
      })
      .catch(() => {});
  };

  setupPushNotificationHandler() {
    PushNotifications.addListener("registration", this.onDeviceRegistered);
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      this.onPushNotificationActionPerformed
    );
    PushNotifications.addListener(
      "pushNotificationReceived",
      this.onPushNotificationReceived
    );
  }

  setupStatusBarStyleHandler() {
    StatusBar.setStyle({ style: StatusBarStyle.Light }).then(
      () => {},
      () => {}
    );
  }

  async requestAppTrackingTransparencyIfNeeded() {
    // Wrong type definition
    const status: any = await CapacitorAppTrackingTransparency.getStatus();
    const { status: currentStatus } = status;
    if (currentStatus === AppTrackingTransparencyStatus.unrequested) {
      await CapacitorAppTrackingTransparency.requestPermission();
    }
  }

  async componentDidMount() {
    try {
      await skygear.config({
        endPoint: SKYGEAR_ENDPOINT,
        apiKey: SKYGEAR_API_KEY,
      });
      if (isPlatform("hybrid")) {
        if (await this.props.checkAppVersionContext.checkAppVersion()) {
          //Note: Hide splash screen here in order to show the alert
          this.hideSplashScreen();
          return;
        }
      }
      await this.props.walkthroughContext.load();
      await this.props.userContext.load();
      const user = await this.props.userContext.autoLogin();
      if (isRegistered(user)) {
        this.props.userContext.registerPushNotification();
      }
      this.presentSignUpFlowForIncomleteUser(user);
      await this.props.options.fetch();
      await this.props.templateContext.fetch();
    } catch (error) {
      this.props.errorAlertContext.show(error);
    }
    this.onAppReady();
  }

  presentSignUpFlowForIncomleteUser(user: WhoamiResponseUser) {
    const { present } = this.props.presentationContext;
    if (isNotComplete(user) && user.value.type === UserType.agent) {
      present("/login/welcome/agent");
    }
  }

  render() {
    const { isReady } = this.state;
    if (!isReady) {
      return <LoadingLabel />;
    }

    return (
      <IonApp>
        <AppRoute />
      </IonApp>
    );
  }
}

const App = withPresentation(
  withChat(
    withLoading(
      withRouter(
        withErrorAlert(
          withUser(
            withOptions(
              withTemplate(withCheckAppVersion(withWalkthrough(AppImpl)))
            )
          )
        )
      )
    )
  )
);

interface RootState {
  locale?: Locale;
}
class Root extends React.PureComponent {
  state: RootState = {};
  apiClient = makeApiClient();
  chatService = makeSkygearChatService();
  pubsubService = makeSkygearPubSubService();

  async componentDidMount() {
    const locale = await getLocale();
    this.setState({ locale: locale });
  }

  render() {
    const { locale } = this.state;
    if (!locale) {
      return <>Loading...</>;
    }
    let Router = BrowserRouter;
    if (ROUTER_IMPL === "HashRouter") {
      Router = HashRouter;
    }
    return (
      <SentryBoundary>
        <Router basename={isPlatform("hybrid") ? "/" : BASENAME}>
          <ContextProviders
            locale={locale}
            apiClient={this.apiClient}
            chatService={this.chatService}
            pubsubService={this.pubsubService}
          >
            <App />
          </ContextProviders>
        </Router>
      </SentryBoundary>
    );
  }
}
export default Root;
