import { FormattedMessage } from "@oursky/react-messageformat";
import React, { FormEventHandler, PureComponent } from "react";

import {
  currencyFormatToNumber,
  formatToCurrencyAmount,
} from "../../utils/formatToCurrencyAmount";
import { isLoading, RemoteData } from "../../utils/remoteData";
import {
  CreateForeignExchangeForm,
  ExchangeLoanRequest,
  OfferingFromMode,
  OfferingType,
} from "../../models";

import { PrimaryButton } from "../Button";
import { DropdownPicker, Option as DPOption } from "../DropdownPicker";
import ErrorField from "../ErrorField";
import { MPInput } from "../input";
import { ValidDaysDropdown, ValidDaysOptions } from "../SpecifyDropdown";

import styles from "./styles.module.scss";
import { ValidationError, validate, Validator } from "../../utils/validate";

export type InputFields = "toCurrencyId" | "toAmount";

interface Props {
  mode: OfferingFromMode;
  currencyOptions: DPOption[];
  remoteCreate: RemoteData<void>;
  selectedRequest?: ExchangeLoanRequest;
  onSubmit: (values: CreateForeignExchangeForm) => Promise<boolean>;
}

interface FormValues {
  fromCurrencyId: string;
  toCurrencyId: string;
  toAmount: string;
  validDays: string;
}

interface State {
  values: FormValues;
  error: ValidationError<InputFields>;
  focusField?: InputFields;
}

class OfferForeignExchangeForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const isNewOfferingMode = props.mode === OfferingFromMode.newOffering;
    this.state = {
      values: {
        fromCurrencyId:
          isNewOfferingMode && props.selectedRequest
            ? props.selectedRequest.sellingCurrency.id
            : props.currencyOptions[0].value,
        toCurrencyId:
          isNewOfferingMode && props.selectedRequest
            ? props.selectedRequest.buyingCurrency.id
            : props.currencyOptions[1].value,
        toAmount: "",
        validDays: ValidDaysOptions[0].value,
      },
      error: {},
    };
  }

  componentDidMount() {
    this.resetState();
  }

  resetState = () => {
    const { currencyOptions, mode, selectedRequest } = this.props;
    const isNewOfferingMode = mode === OfferingFromMode.newOffering;
    this.setState({
      values: {
        fromCurrencyId:
          isNewOfferingMode && selectedRequest
            ? selectedRequest.sellingCurrency.id
            : currencyOptions[0].value,
        toCurrencyId:
          isNewOfferingMode && selectedRequest
            ? selectedRequest.buyingCurrency.id
            : currencyOptions[1].value,
        toAmount: "",
        validDays: ValidDaysOptions[0].value,
      },
      error: {},
    });
  };

  renderCurrencySection = () => {
    const { currencyOptions, mode } = this.props;
    const { error, values, focusField } = this.state;
    return (
      <div className={styles.row}>
        <div className={styles.col}>
          <label className={styles.sectionLabel}>
            <FormattedMessage id="create_offering.exchange.currency_from.label" />
          </label>
          <DropdownPicker
            disabled={mode === OfferingFromMode.newOffering}
            containerClass={styles.dropdownContainer}
            selectClass={styles.dropdownSelect}
            errorClass={styles.dropdownError}
            emptyClass={styles.dropdownEmpty}
            isError={false}
            value={values.fromCurrencyId}
            options={currencyOptions}
            onValueChange={this.handleFromCurrencyChange}
          />
        </div>
        <div className={styles.colRight} data-anchor="toCurrencyId">
          <label className={styles.sectionLabel}>
            <FormattedMessage id="create_offering.exchange.currency_to.label" />
          </label>
          <DropdownPicker
            disabled={mode === OfferingFromMode.newOffering}
            containerClass={styles.dropdownContainer}
            selectClass={styles.dropdownSelect}
            errorClass={styles.dropdownError}
            emptyClass={styles.dropdownEmpty}
            isError={!!error.toCurrencyId}
            value={values.toCurrencyId}
            options={currencyOptions}
            onValueChange={this.handleToCurrencyChange}
            shouldScrollTo={focusField === "toCurrencyId"}
            scrollAnchor="toCurrencyId"
          />
          <ErrorField messageId={error.toCurrencyId} />
        </div>
      </div>
    );
  };

  renderAmountSection = () => {
    const { error, values, focusField } = this.state;
    const toCurrency = this.getCurrency(values.toCurrencyId);

    return (
      <div className={styles.col}>
        <label className={styles.sectionLabel}>
          <FormattedMessage id="create_offering.exchange.amount.label" />
        </label>
        <MPInput
          className={styles.input}
          value={values.toAmount}
          onChange={this.handleToAmountChange}
          placeholder={toCurrency.display}
          maxLength={11}
          inputMode="tel"
          type="tel"
          isError={!!error.toAmount}
          shouldFocus={focusField === "toAmount"}
        />
        <ErrorField messageId={error.toAmount} />
      </div>
    );
  };

  renderValidDaysSection = () => {
    const { values } = this.state;

    return (
      <div className={styles.col}>
        <label className={styles.sectionLabel}>
          <FormattedMessage id="create_offering.exchange.valid_days.label" />
        </label>
        <ValidDaysDropdown
          containerClass={styles.dropdownContainer}
          selectClass={styles.dropdownSelect}
          errorClass={styles.dropdownError}
          emptyClass={styles.dropdownEmpty}
          isError={false}
          value={values.validDays}
          onValueChange={this.handleValidDaysChange}
        />
      </div>
    );
  };

  render() {
    const { remoteCreate } = this.props;
    return (
      <form noValidate={true} onSubmit={this.handleSubmit}>
        <div className={styles.formContainer}>
          {this.renderCurrencySection()}
          {this.renderAmountSection()}
          {this.renderValidDaysSection()}
        </div>

        <PrimaryButton
          class={styles.submitButton}
          type="submit"
          expand="full"
          disabled={isLoading(remoteCreate)}
        >
          <FormattedMessage id="create_offering.submit" />
        </PrimaryButton>
      </form>
    );
  }

  handleValidDaysChange = (validDays: string) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, validDays },
      };
    });
  };

  handleFromCurrencyChange = (currencyId: string) => {
    this.setState(({ values }) => {
      return {
        values: { ...values, fromCurrencyId: currencyId },
      };
    });
  };

  handleToAmountChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const toAmount = e.currentTarget.value || "";
    const formattedAmount = formatToCurrencyAmount(toAmount);

    if (formattedAmount !== null) {
      this.setState(({ values }) => {
        return {
          values: { ...values, toAmount: formattedAmount },
        };
      });
    }
  };

  handleToCurrencyChange = (currencyId: string) => {
    this.setState(({ values }) => {
      return {
        values: {
          ...values,
          toCurrencyId: currencyId,
        },
      };
    });
  };

  handleSubmit: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();

    this.setState({ focusField: undefined }, () => {
      const { values } = this.state;

      if (!this.validateOfferData(values)) return;

      const formattedFromValues: CreateForeignExchangeForm = {
        type: OfferingType.exchangeLoan,
        sellingCurrencyId: values.toCurrencyId,
        buyingCurrencyId: values.fromCurrencyId,
        sellingAmount: currencyFormatToNumber(values.toAmount),
        validDays: parseInt(values.validDays, 10),
      };

      this.props.onSubmit(formattedFromValues).then(isSubmitted => {
        if (isSubmitted) {
          this.resetState();
        }
      });
    });
  };

  validateOfferData(values: FormValues) {
    const { fromCurrencyId, toCurrencyId, toAmount } = values;

    const validators: Validator<InputFields>[] = [
      {
        field: "toCurrencyId",
        invalidCondition: fromCurrencyId === toCurrencyId,
        errorMessageId: "create_offering.exchange.error.same_currency",
      },
      {
        field: "toAmount",
        invalidCondition: !toAmount,
        errorMessageId: "create_offering.exchange.error.required.amount",
      },
      {
        field: "toAmount",
        invalidCondition:
          toAmount !== "" && currencyFormatToNumber(toAmount) <= 0,
        errorMessageId:
          "create_offering.exchange.error.negative_or_zero.amount",
      },
    ];

    const { focusField, error, isValid } = validate(validators);

    this.setState({ error, focusField });

    return isValid;
  }

  getCurrency = (currencyId: string): DPOption => {
    const currency = this.props.currencyOptions.find(
      c => c.value === currencyId
    );

    if (!currency) {
      throw new Error(`Unexpected currency id: ${currencyId}`);
    }
    return currency;
  };
}

export default OfferForeignExchangeForm;
