import { Plugins } from "@capacitor/core";
import { isPlatform } from "./platform";

const { FocusPlugin } = Plugins;

const getBoundingRect = (element: HTMLElement) => {
  const elemRect = element.getBoundingClientRect();
  const bodyRect = document.body.getBoundingClientRect();
  return {
    top: elemRect.top - bodyRect.top - window.pageYOffset,
    left: elemRect.left - bodyRect.left - window.pageXOffset,
    right: elemRect.right - bodyRect.left - window.pageXOffset,
    bottom: elemRect.bottom - bodyRect.top - window.pageYOffset,
  };
};

export const focusElement = (element: HTMLElement | null) => {
  if (element === null) {
    return;
  }

  if (element.tagName === "ION-INPUT") {
    if (isPlatform("android") && isPlatform("hybrid")) {
      FocusPlugin.focus({
        rect: getBoundingRect(element),
      });
    } else {
      (element as HTMLIonInputElement).setFocus();
    }
  } else {
    element.focus();
  }
};
