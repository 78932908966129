import { IonCol, IonGrid, IonRow } from "@ionic/react";
import classNames from "classnames";
import React, { PureComponent } from "react";

import styles from "./styles.module.scss";
import { FormattedMessage } from "@oursky/react-messageformat";
import { focusElement } from "../../utils/focus";

export interface ButtonSelectProps<T> {
  items: ButtonSelectItem<T>[];
  value?: T;
  onChange?: (item: ButtonSelectItem<T>) => void;
  shouldFocus?: boolean;
  disabled?: boolean;
}

export interface ButtonSelectItem<T> {
  labelId: string;
  value: T;
}

export class ButtonSelect<T> extends PureComponent<ButtonSelectProps<T>> {
  buttonRef = React.createRef<HTMLButtonElement>();

  componentDidUpdate(prevProps: ButtonSelectProps<T>) {
    if (
      prevProps.shouldFocus !== this.props.shouldFocus &&
      this.props.shouldFocus
    ) {
      focusElement(this.buttonRef.current);
    }
  }

  render() {
    const { items, value, disabled } = this.props;

    return (
      <IonGrid no-padding>
        <IonRow>
          {items.map((item, i) => {
            const refProps = i
              ? {}
              : {
                  ref: this.buttonRef,
                };

            return (
              <IonCol key={i} no-padding>
                <button
                  {...refProps}
                  className={classNames(styles.button, {
                    [styles.buttonActive]: value === item.value,
                    [styles.buttonLead]: i === 0,
                    [styles.buttonTrail]: i === items.length - 1,
                  })}
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={e => this.handleButtonClick(e, i)}
                  disabled={disabled}
                >
                  <FormattedMessage id={item.labelId} />
                </button>
              </IonCol>
            );
          })}
        </IonRow>
      </IonGrid>
    );
  }

  handleButtonClick = (e: React.MouseEvent<unknown>, index: number) => {
    e.preventDefault();
    e.stopPropagation();

    const { items, value, onChange } = this.props;

    const item = items[index];
    if (item.value === value) {
      return;
    }

    if (onChange) {
      onChange(item);
    }
  };
}
