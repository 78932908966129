import { DateTime } from "luxon";
import { Overwrite } from "utility-types";
import {
  ExchangeLoanOffering,
  MortgageOffering,
  PersonalLoanOffering,
} from "./offering";
import {
  ExchangeLoanRequest,
  MortgageRequest,
  PersonalLoanRequest,
} from "./request";

export type Chat = ExchangeLoanChat | MortgageChat | PersonalLoanChat;

interface ConversationIder {
  conversationId: string;
}

interface BlockingInfo {
  isAgentBlocked: boolean;
}

export type ChatWithConversation = (
  | Overwrite<ExchangeLoanChat, ConversationIder>
  | Overwrite<MortgageChat, ConversationIder>
  | Overwrite<PersonalLoanChat, ConversationIder>) &
  BlockingInfo;

export enum ChatType {
  exchangeLoan = "exchange_loan",
  mortgage = "mortgage",
  personalLoan = "personal_loan",
}

export enum ChatStatus {
  inProgress = "in-progress",
  requestReferral = "request-referral",
  acceptedReferral = "accepted-referral",
  completed = "completed",
}

export enum ReferralAgreementStatus {
  requested = "requested",
  accepted = "accepted",
  // To identify message type when generate message list
  rejected = "rejected",
}

export interface BaseChat {
  id: string;
  refNum: number;
  completedAt: DateTime | null;
  conversationId: string | null;
  unreadCount: number | null;
  referralAgreementStatus: ReferralAgreementStatus | null;
}

export type BaseChatWithBlockingInfo = BaseChat & BlockingInfo;

export interface ExchangeLoanChat extends BaseChat {
  type: ChatType.exchangeLoan;
  request: ExchangeLoanRequest;
  offering: ExchangeLoanOffering;
}

export interface MortgageChat extends BaseChat {
  type: ChatType.mortgage;
  request: MortgageRequest;
  offering: MortgageOffering;
}

export interface PersonalLoanChat extends BaseChat {
  type: ChatType.personalLoan;
  request: PersonalLoanRequest;
  offering: PersonalLoanOffering;
}

export interface Conversation {
  id: string;
  title: string;
  distinctByParticipants: boolean;
  metadata: any;
}
