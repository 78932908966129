export const waitShadowRoot = (element: Element): Promise<ShadowRoot> => {
  return new Promise<ShadowRoot>((resolve, _reject) => {
    setTimeout(() => {
      if (element !== null && element.shadowRoot !== null) {
        if (element.shadowRoot.childNodes.length === 0) {
          new MutationObserver((mutationList, observer) => {
            if (element !== null && element.shadowRoot !== null) {
              for (const mutation of mutationList) {
                if (mutation.type === "childList") {
                  resolve(element.shadowRoot);
                  observer.disconnect();
                  break;
                }
              }
            }
          }).observe(element.shadowRoot, {
            childList: true,
            attributes: false,
            subtree: false,
          });
        } else {
          resolve(element.shadowRoot);
        }
      }
    }, 0);
  });
};
