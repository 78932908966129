import {
  FormattedMessage,
  Context as LocaleContext,
} from "@oursky/react-messageformat";
import classNames from "classnames";
import { DateTime } from "luxon";
import React, { PureComponent } from "react";

import { DatePicker } from "../DatePicker";
import { DistrictPicker } from "../DistrictPicker";
import ErrorField from "../ErrorField";
import { EducationDropdown, PremiseTypeDropdown } from "../SpecifyDropdown";

import {
  District,
  Education,
  Gender,
  PremiseType,
  PropertyStatus,
} from "../../models";
import { capitalizeFirstLetter } from "../../utils/stringPreprocessing";
import { isDesktopSafari } from "../../utils/platform";

import { GenderDropdown } from "../SpecifyDropdown/GenderDropdown";
import { PropertyStatusDropdown } from "../SpecifyDropdown/PropertyStatusDropdown";
import {
  BorrowerPropertyInfoView,
  InputFields as BorrowerPropertyInfoViewInputFields,
} from "../BorrowerPropertyInfoView";
import { StaticDistrictOption } from "../../context/OptionsContext";
import {
  currencyFormatToNumber,
  formatToCurrencyAmount,
} from "../../utils/formatToCurrencyAmount";

import styles from "./EditBorrowerBasicInfoForm.module.scss";
import { ClearableInput } from "../input";
import { BorrowerBasicInfoProps, InputFields } from "./common";

interface State {
  nextField?: InputFields;
}

type Props = BorrowerBasicInfoProps;

export class EditBorrowerBasicInfoForm extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      nextField: undefined,
    };
  }

  componentDidUpdate() {
    if (this.state.nextField) {
      this.setState({ nextField: undefined });
    }
  }

  focusBirthdayField = () => {
    this.setState({ nextField: "birthday" });
  };

  focusDistrictField = () => {
    this.setState({ nextField: "district" });
  };

  renderEditBasicInfoSection = (
    renderToString: (messageId: string) => string
  ) => {
    const { nextField } = this.state;
    const {
      borrowerBasicInfo,
      borrowerPropertyInfo,
      error,
      onUpdateExistingPropertyInfo,
      onUpdateHasProperty,
      onSelectNumberOfProperties,
      onRemoveExistingProperty,
      onAddExistingProperty,
      emailErrorId,
      focusField,
    } = this.props;
    const {
      name,
      surname,
      gender,
      passportNumber,
      email,
      birthday,
      education,
      heardFrom,
      roomFloor,
      tower,
      nameOfBuilding,
      streetNameAndNumber,
      district,
      premiseType,
      propertyStatus,
      numOfOwner,
      monthlyRent,
    } = borrowerBasicInfo;

    const selectedDistrict =
      StaticDistrictOption.find(d => d.value === district) || null;

    const birthdayPlaceholder = isDesktopSafari()
      ? "borrower_basic_info.birthday.safari"
      : "borrower_basic_info.birthday";

    const emailErrorMessage = error["email"] || emailErrorId;

    return (
      <>
        <div className={styles.fieldContainer} data-anchor="name">
          <div className={styles.fieldTitle}>
            <FormattedMessage id="borrower_basic_info.name" />
          </div>
          <ClearableInput
            className={styles.input}
            placeholder={renderToString("borrower_basic_info.pleaseFillIn")}
            maxLength={30}
            autoFocus={true}
            value={name || ""}
            onChange={this.handleNameChange}
            isError={!!error["name"]}
            shouldFocus={focusField === "name"}
            shouldScrollTo={focusField === "name"}
            scrollAnchor={"name"}
          />
          <ErrorField
            isShown={error["name"] !== undefined}
            hiddenType="gone"
            messageId={error["name"]}
          />
        </div>

        <div className={styles.fieldContainer} data-anchor="surname">
          <div className={styles.fieldTitle}>
            <FormattedMessage id="borrower_basic_info.surname" />
          </div>
          <ClearableInput
            className={styles.input}
            placeholder={renderToString("borrower_basic_info.pleaseFillIn")}
            maxLength={30}
            value={surname || ""}
            onChange={this.handleSurnameChange}
            isError={!!error["surname"]}
            shouldFocus={focusField === "surname"}
            shouldScrollTo={focusField === "surname"}
            scrollAnchor={"surname"}
          />
          <ErrorField
            isShown={error["surname"] !== undefined}
            hiddenType="gone"
            messageId={error["surname"]}
          />
        </div>

        <div className={styles.fieldContainer} data-anchor="gender">
          <div className={styles.fieldTitle}>
            <FormattedMessage id="borrower_basic_info.gender" />
          </div>
          <GenderDropdown
            containerClass={styles.dropdownContainer}
            selectClass={styles.dropdownSelect}
            emptyClass={styles.dropdownEmpty}
            errorClass={styles.dropdownError}
            placeholderId="borrower_basic_info.gender"
            isError={!!error["gender"]}
            value={gender || ""}
            onValueChange={this.handleGenderChange}
            shouldFocus={focusField === "gender"}
            shouldScrollTo={focusField === "gender"}
            scrollAnchor={"gender"}
          />
          <ErrorField
            isShown={error["gender"] !== undefined}
            hiddenType="gone"
            messageId={error["gender"]}
          />
        </div>

        <div className={styles.fieldContainer} data-anchor="passportNumber">
          <div className={styles.fieldTitle}>
            <FormattedMessage id="borrower_basic_info.passportNumber" />
          </div>
          <ClearableInput
            className={styles.input}
            placeholder={renderToString("borrower_basic_info.pleaseFillIn")}
            maxLength={30}
            value={passportNumber || ""}
            onChange={this.handlePassportNumberChange}
            isError={!!error["passportNumber"]}
            shouldFocus={focusField === "passportNumber"}
            shouldScrollTo={focusField === "passportNumber"}
            scrollAnchor={"passportNumber"}
          />
          <ErrorField
            isShown={error["passportNumber"] !== undefined}
            hiddenType="gone"
            messageId={error["passportNumber"]}
          />
        </div>

        <div className={styles.fieldContainer} data-anchor="email">
          <div className={styles.fieldTitle}>
            <FormattedMessage id="borrower_basic_info.email" />
          </div>
          <ClearableInput
            type="email"
            autoComplete="email"
            placeholder={renderToString("borrower_basic_info.pleaseFillIn")}
            isError={!!emailErrorMessage}
            value={email || ""}
            onChange={this.handleEmailChange}
            // Note(jasonkit): https://github.com/facebook/react/issues/6368
            onKeyDown={this.handleEmailKeyDown}
            shouldFocus={focusField === "email"}
            shouldScrollTo={focusField === "email"}
            scrollAnchor={"email"}
            autoSubmit={this.focusBirthdayField}
          />
          <ErrorField
            isShown={emailErrorMessage !== undefined}
            hiddenType="gone"
            messageId={emailErrorMessage}
          />
        </div>

        <div className={styles.fieldContainer} data-anchor="birthday">
          <div className={styles.fieldTitle}>
            <FormattedMessage id="borrower_basic_info.birthday" />
          </div>
          <DatePicker
            inputClass={classNames(styles.input, {
              [styles.errored]: !!error["birthday"],
            })}
            placeholderId={birthdayPlaceholder}
            value={birthday || undefined}
            onChange={this.handleBirthdayChange}
            shouldFocus={nextField === "birthday"}
            shouldScrollTo={focusField === "birthday"}
            scrollAnchor={"birthday"}
          />
          <ErrorField
            isShown={error["birthday"] !== undefined}
            hiddenType="gone"
            messageId={error["birthday"]}
          />
        </div>

        <div className={styles.fieldContainer} data-anchor="education">
          <div className={styles.fieldTitle}>
            <FormattedMessage id="borrower_basic_info.education" />
          </div>
          <EducationDropdown
            containerClass={styles.dropdownContainer}
            selectClass={styles.dropdownSelect}
            emptyClass={styles.dropdownEmpty}
            errorClass={styles.dropdownError}
            placeholderId="borrower_basic_info.education"
            isError={!!error["education"]}
            value={education || ""}
            onValueChange={this.handleEducationChange}
            shouldFocus={focusField === "education"}
            shouldScrollTo={focusField === "education"}
            scrollAnchor={"education"}
          />
          <ErrorField
            isShown={error["education"] !== undefined}
            hiddenType="gone"
            messageId={error["education"]}
          />
        </div>

        <div className={styles.fieldContainer} data-anchor="heardFrom">
          <div className={styles.fieldTitle}>
            <FormattedMessage id="borrower_basic_info.heardFrom" />
          </div>
          <ClearableInput
            className={styles.input}
            placeholder={renderToString("borrower_basic_info.pleaseFillIn")}
            value={heardFrom || ""}
            onChange={this.handleHeardFromChange}
            shouldFocus={focusField === "heardFrom"}
            shouldScrollTo={focusField === "heardFrom"}
            scrollAnchor={"heardFrom"}
          />
          <ErrorField
            isShown={error["heardFrom"] !== undefined}
            hiddenType="gone"
            messageId={error["heardFrom"]}
          />
        </div>

        <div className={styles.subtitleLabel}>
          <FormattedMessage id="borrower_basic_info.livingInfo" />
        </div>

        <div className={styles.fieldContainer} data-anchor="roomFloor">
          <div className={styles.fieldTitle}>
            <FormattedMessage id="borrower_basic_info.roomFloor" />
          </div>
          <ClearableInput
            className={styles.input}
            placeholder={renderToString("borrower_basic_info.pleaseFillIn")}
            maxLength={30}
            value={roomFloor || ""}
            onChange={this.handleRoomFloorChange}
            isError={!!error["roomFloor"]}
            shouldFocus={focusField === "roomFloor"}
            shouldScrollTo={focusField === "roomFloor"}
            scrollAnchor={"roomFloor"}
          />
          <ErrorField
            isShown={error["roomFloor"] !== undefined}
            hiddenType="gone"
            messageId={error["roomFloor"]}
          />
        </div>

        <div className={styles.fieldContainer} data-anchor="tower">
          <div className={styles.fieldTitle}>
            <FormattedMessage id="borrower_basic_info.tower" />
          </div>
          <ClearableInput
            className={styles.input}
            placeholder={renderToString("borrower_basic_info.pleaseFillIn")}
            maxLength={30}
            value={tower || ""}
            onChange={this.handleTowerChange}
            isError={!!error["tower"]}
            shouldFocus={focusField === "tower"}
            shouldScrollTo={focusField === "tower"}
            scrollAnchor={"tower"}
          />
          <ErrorField
            isShown={error["tower"] !== undefined}
            hiddenType="gone"
            messageId={error["tower"]}
          />
        </div>

        <div className={styles.fieldContainer} data-anchor="nameOfBuilding">
          <div className={styles.fieldTitle}>
            <FormattedMessage id="borrower_basic_info.nameOfBuilding" />
          </div>
          <ClearableInput
            className={styles.input}
            placeholder={renderToString("borrower_basic_info.pleaseFillIn")}
            maxLength={30}
            value={nameOfBuilding || ""}
            onChange={this.handleNameOfBuildingChange}
            isError={!!error["nameOfBuilding"]}
            shouldFocus={focusField === "nameOfBuilding"}
            shouldScrollTo={focusField === "nameOfBuilding"}
            scrollAnchor={"nameOfBuilding"}
          />
          <ErrorField
            isShown={error["nameOfBuilding"] !== undefined}
            hiddenType="gone"
            messageId={error["nameOfBuilding"]}
          />
        </div>

        <div
          className={styles.fieldContainer}
          data-anchor="streetNameAndNumber"
        >
          <div className={styles.fieldTitle}>
            <FormattedMessage id="borrower_basic_info.streetNameAndNumber" />
          </div>
          <ClearableInput
            className={styles.input}
            placeholder={renderToString("borrower_basic_info.pleaseFillIn")}
            maxLength={30}
            value={streetNameAndNumber || ""}
            onChange={this.handleStreetNameAndNumberChange}
            isError={!!error["streetNameAndNumber"]}
            shouldFocus={focusField === "streetNameAndNumber"}
            shouldScrollTo={focusField === "streetNameAndNumber"}
            scrollAnchor={"streetNameAndNumber"}
            autoSubmit={this.focusDistrictField}
          />
          <ErrorField
            isShown={error["streetNameAndNumber"] !== undefined}
            hiddenType="gone"
            messageId={error["streetNameAndNumber"]}
          />
        </div>

        <div className={styles.fieldContainer} data-anchor="district">
          <div className={styles.fieldTitle}>
            <FormattedMessage id="borrower_basic_info.district" />
          </div>
          <DistrictPicker
            isError={!!error["district"]}
            value={selectedDistrict}
            onChange={this.handleDistrictChange}
            shouldFocus={nextField === "district"}
            shouldScrollTo={focusField === "district"}
            scrollAnchor={"district"}
          />
          <ErrorField
            isShown={error["district"] !== undefined}
            hiddenType="gone"
            messageId={error["district"]}
          />
        </div>

        <div className={styles.fieldContainer} data-anchor="premiseType">
          <div className={styles.fieldTitle}>
            <FormattedMessage id="borrower_basic_info.premiseType" />
          </div>
          <PremiseTypeDropdown
            containerClass={classNames(
              styles.dropdownContainer,
              styles.dropdownMargin
            )}
            selectClass={styles.dropdownSelect}
            emptyClass={styles.dropdownEmpty}
            errorClass={styles.dropdownError}
            placeholderId="borrower_basic_info.premiseType"
            isError={false}
            value={premiseType || ""}
            onValueChange={this.handlePremiseTypeChange}
            shouldFocus={focusField === "premiseType"}
            shouldScrollTo={focusField === "premiseType"}
            scrollAnchor={"premiseType"}
          />
          <ErrorField
            isShown={error["premiseType"] !== undefined}
            hiddenType="gone"
            messageId={error["premiseType"]}
          />
        </div>

        <div className={styles.fieldContainer} data-anchor="propertyStatus">
          <div className={styles.fieldTitle}>
            <FormattedMessage id="borrower_basic_info.propertyStatus" />
          </div>
          <PropertyStatusDropdown
            containerClass={styles.dropdownContainer}
            selectClass={styles.dropdownSelect}
            emptyClass={styles.dropdownEmpty}
            errorClass={styles.dropdownError}
            placeholderId="borrower_basic_info.propertyStatus"
            isError={!!error["propertyStatus"]}
            value={propertyStatus || ""}
            onValueChange={this.handlePropertyStatusChange}
            shouldFocus={focusField === "propertyStatus"}
            shouldScrollTo={focusField === "propertyStatus"}
            scrollAnchor={"propertyStatus"}
          />
          <ErrorField
            isShown={error["propertyStatus"] !== undefined}
            hiddenType="gone"
            messageId={error["propertyStatus"]}
          />
        </div>

        <div className={styles.fieldContainer} data-anchor="numberOfOwner">
          <div className={styles.fieldTitle}>
            <FormattedMessage id="borrower_basic_info.numberOfOwner" />
          </div>
          <ClearableInput
            className={styles.input}
            placeholder={renderToString("borrower_basic_info.pleaseFillIn")}
            type="tel"
            maxLength={4}
            value={`${numOfOwner || ""}`}
            onChange={this.handleNumOfOwnerChange}
            shouldFocus={focusField === "numOfOwner"}
            shouldScrollTo={focusField === "numOfOwner"}
            scrollAnchor={"numOfOwner"}
          />
          <ErrorField
            isShown={error["numOfOwner"] !== undefined}
            hiddenType="gone"
            messageId={error["numOfOwner"]}
          />
        </div>

        <div className={styles.fieldContainer} data-anchor="monthlyRent">
          <div className={styles.fieldTitle}>
            <FormattedMessage id="borrower_basic_info.monthlyRent" />
          </div>
          <ClearableInput
            className={styles.input}
            placeholder={renderToString("borrower_basic_info.pleaseFillIn")}
            type="tel"
            maxLength={15}
            value={formatToCurrencyAmount(monthlyRent) || ""}
            onChange={this.handleMonthlyRentChange}
            shouldFocus={focusField === "monthlyRent"}
            shouldScrollTo={focusField === "monthlyRent"}
            scrollAnchor={"monthlyRent"}
          />
          <ErrorField
            isShown={error["monthlyRent"] !== undefined}
            hiddenType="gone"
            messageId={error["monthlyRent"]}
          />
        </div>

        <BorrowerPropertyInfoView
          borrowerPropertyInfo={borrowerPropertyInfo}
          selectNumberOfProperties={onSelectNumberOfProperties}
          updateHasProperty={onUpdateHasProperty}
          updateExistingPropertyInfo={onUpdateExistingPropertyInfo}
          addExistingProperty={onAddExistingProperty}
          removeExistingProperty={onRemoveExistingProperty}
          error={error}
          focusField={focusField as BorrowerPropertyInfoViewInputFields}
        />
      </>
    );
  };

  handleNameChange = (value: string) => {
    const name = capitalizeFirstLetter(value || "");
    this.props.updateBorrowerBasicInfo({
      name,
    });
  };

  handleSurnameChange = (value: string) => {
    const surname = capitalizeFirstLetter(value || "");
    this.props.updateBorrowerBasicInfo({
      surname,
    });
  };

  handleGenderChange = (gender: string) => {
    this.props.updateBorrowerBasicInfo({
      gender: gender as Gender,
    });
  };

  handlePassportNumberChange = (value: string) => {
    this.props.updateBorrowerBasicInfo({
      passportNumber: value,
    });
  };

  handleEmailChange = (value: string) => {
    this.props.updateBorrowerBasicInfo({
      email: value,
    });
  };

  handleEmailKeyDown: React.KeyboardEventHandler = e => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  handleBirthdayChange = (birthday?: DateTime) => {
    this.props.updateBorrowerBasicInfo({
      birthday,
    });
  };

  handleEducationChange = (education: string) => {
    this.props.updateBorrowerBasicInfo({
      education: education as Education,
    });
  };

  handleRoomFloorChange = (value: string) => {
    this.props.updateBorrowerBasicInfo({
      roomFloor: value,
    });
  };

  handleTowerChange = (value: string) => {
    this.props.updateBorrowerBasicInfo({
      tower: value,
    });
  };

  handleNameOfBuildingChange = (value: string) => {
    this.props.updateBorrowerBasicInfo({
      nameOfBuilding: value,
    });
  };

  handleStreetNameAndNumberChange = (value: string) => {
    this.props.updateBorrowerBasicInfo({
      streetNameAndNumber: value,
    });
  };

  handlePropertyStatusChange = (propertyStatus: string) => {
    this.props.updateBorrowerBasicInfo({
      propertyStatus: propertyStatus as PropertyStatus,
      ...(propertyStatus === PropertyStatus.whollyOwned &&
        !this.props.borrowerBasicInfo.numOfOwner && {
          numOfOwner: 1,
        }),
    });
  };

  handleDistrictChange = (district: District) => {
    this.props.updateBorrowerBasicInfo({
      district: district.value,
    });
  };

  handleHeardFromChange = (value: string) => {
    this.props.updateBorrowerBasicInfo({
      heardFrom: value,
    });
  };

  handlePremiseTypeChange = (premiseType: string) => {
    this.props.updateBorrowerBasicInfo({
      premiseType: premiseType as PremiseType,
    });
  };

  handleNumOfOwnerChange = (value: string) => {
    const numOfOwner = parseInt(value, 10);
    this.props.updateBorrowerBasicInfo({
      numOfOwner: isNaN(numOfOwner) ? null : numOfOwner,
    });
  };

  handleMonthlyRentChange = (value: string) => {
    const monthlyRent = currencyFormatToNumber(value, false);
    this.props.updateBorrowerBasicInfo({
      monthlyRent,
    });
  };

  render() {
    return (
      <div className={styles.container}>
        <LocaleContext.Consumer>
          {({ renderToString }) =>
            this.renderEditBasicInfoSection(renderToString)
          }
        </LocaleContext.Consumer>
      </div>
    );
  }
}
