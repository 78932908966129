import React, { PureComponent } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import AdminImpersonateAgentSelectionView from "../components/AdminImpersonateAgentSelectionView";
import { AgentContextProps, withAgent } from "../context/AgentContext";
import { ChatContextProps, withChat } from "../context/ChatContext";
import {
  ErrorAlertContextProps,
  withErrorAlert,
} from "../context/ErrorAlertContext";
import { LoadingContextProps, withLoading } from "../context/LoadingContext";
import { RequestContextProps, withRequest } from "../context/RequestContext";
import { UserContextProps, withUser } from "../context/UserContext";
import { AgentBriefData } from "../models";

interface RouteState {
  onCancel?: () => Promise<void>;
}

type Props = RouteComponentProps<{}, StaticContext, RouteState | undefined> &
  AgentContextProps &
  UserContextProps &
  RequestContextProps &
  ChatContextProps &
  LoadingContextProps &
  ErrorAlertContextProps;

class AdminImpersonateAgentSelectionScreen extends PureComponent<Props> {
  onClickAgent = async (agent: AgentBriefData) => {
    this.props.loadingContext.show();
    try {
      await this.props.userContext.impersonateAgent(agent.userId);
      this.props.history.push("/close");
      this.props.history.push("/");
      this.props.chatContext.updateChatListVersion(() => {});
    } catch (e) {
      this.props.errorAlertContext.show(e);
    } finally {
      this.props.loadingContext.dismiss();
    }
  };

  onClickCancel = async () => {
    this.props.loadingContext.show();
    try {
      if (this.props.location.state && this.props.location.state.onCancel) {
        await this.props.location.state.onCancel();
      }
      this.props.history.push("/close");
      this.props.history.push("/");
    } finally {
      this.props.loadingContext.dismiss();
    }
  };

  render() {
    return (
      <AdminImpersonateAgentSelectionView
        onClickAgent={this.onClickAgent}
        onClickCancel={this.onClickCancel}
      />
    );
  }
}

export default withLoading(
  withErrorAlert(
    withAgent(
      withUser(withRequest(withChat(AdminImpersonateAgentSelectionScreen)))
    )
  )
);
