import numeral from "numeral";

export function formatToCurrencyAmount(
  value: string | number | null,
  representZeroWithDash: boolean = false
): string | null {
  // "-": negative sign
  if (value === "" || value === "-" || value == null) {
    return "";
  }

  const numeralValue = numeral(value);
  const num: number | null = numeralValue.value();
  if (num == null) {
    return null;
  }
  if (representZeroWithDash && num === 0) {
    return "-";
  }
  return numeralValue.format();
}

export function currencyFormatToNumber(
  value: string,
  fallbackToZeroIfNull: boolean = true
): number {
  // contrary to the type definition, value() would return null if numeral
  // doesn't find a value in the string
  const _value = numeral(value).value();
  if (fallbackToZeroIfNull) {
    return _value || 0;
  }
  return _value;
}
